import {
  Typography,
  Grid,
  Button,
  Modal,
  TextField,
  FormControl,
} from "@mui/material";
import { formatZip, formatState } from "../../utils/services";
import "../../../assets/styles/containers/_inspDetails.scss";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import CloseIcon from "@mui/icons-material/Close";
import { apiCall } from "../agentListView/action";
import CardHeader from "../../common/cardHeader";
import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material/styles";
import HomeIcon from "@mui/icons-material/Home";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import toast from "react-hot-toast";

const PropertyDetails = ({
  CancelledStatus,
  address,
  property,
  setRefresh,
  refresh,
}) => {
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const handleOpen = () => setOpen(true);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [dataIsCorrect, setDataIsCorrect] = useState(false);
  const Address = `${address.address} ${address.location_address_2} ${address.city} ${address.state?.toUpperCase()} ${address.zip}`;
  const [value, setValue] = useState({
    zip: address.zip,
    city: address.city,
    state: address.state,
    address: address.address,
    location_address_2: address.location_address_2,
    type: property.type ? property.type : "",
    bedrooms: property.bedrooms ? property.bedrooms : "",
    bathrooms: property.bathroom ? property.bathroom : "",
    yearBuilt: property.yearbuild ? property.yearbuild : "",
    foundation: property.foundation ? property.foundation : "",
    garage: property.garage ? property.garage : "",
    squareFeet: property.squarefeet ? property.squarefeet : "",
  });
  const handleChange = (event) => {
    setValue({
      ...value,
      [event.target.name]: event.target.value,
    });
    setIsChanged(true);
  };

  const handleZipChange = (event) => {
    const formattedZip = formatZip(event.target.value);
    setValue({
      ...value,
      [event.target.name]: formattedZip,
    });
    setIsChanged(true);
  };

  const handleStateChange = (event) => {
    const formattedState = formatState(event.target.value);
    setValue({
      ...value,
      [event.target.name]: formattedState,
    });
    setIsChanged(true);
  };
  // handle submit function
  const handleformSubmit = (event) => {
    event.preventDefault();
    setErrors(validation(value));
    setDataIsCorrect(true);
  };

  const validation = (values) => {
    let errors = {};
    if (!values.address.trim()) {
      errors.address = "address is required.";
    }
    if (!values.city.trim()) {
      errors.city = "City is required";
    }
    if (!values.state.trim()) {
      errors.state = "State is invalid";
    }
    if (!values.zip.trim()) {
      errors.zip = "Zip is invalid";
    }
    return errors;
  };

  const sendIsgDetails=()=>{
    setLoading(true);
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      inspection_id: address?.inspectionid,
    }
    apiCall(
      (response) => {
        const { success } = response.data;
        if (success) {         
          setLoading(false);
        } else {
          setLoading(false);
        }
      },
      formdata,
      "SEND_ISG_DETAILS"
    );
  }

  const editPropertyDetails = () => {
    setLoading(true);
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      inspection_id: address?.inspectionid,
      location_address: value.address,
      location_address_2: value.location_address_2,
      location_city: value.city,
      location_state: value.state,
      location_zip: value.zip,
      foundation: value.foundation,
      total_area: value.squareFeet,
      total_bedrooms: value.bedrooms,
      total_bathrooms: value.bathrooms,
      year_built: value.yearBuilt,
      type: value.type,
      total_garage: value.garage,
    };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          sendIsgDetails();
          toast.success(message);
          setDataIsCorrect(false);
          setRefresh(!refresh);
          setIsChanged(false);
          setOpen(false);
          setLoading(false);
        } else {
          console.log("else ", message);
          setLoading(false);
        }
      },
      formdata,
      "EDIT_PROPERTY_DETAILS"
    );
  };

  useEffect(() => {
    setValue({
      zip: address.zip,
      city: address.city,
      state: address.state,
      address: address.address,
      location_address_2: address.location_address_2,
      type: property.type ? property.type : "",
      bedrooms: property.bedrooms ? property.bedrooms : "",
      bathrooms: property.bathroom ? property.bathroom : "",
      yearBuilt: property.yearbuild ? property.yearbuild : "",
      garage: property.garage ? property.garage : "",
      foundation: property.foundation ? property.foundation : "",
      squareFeet: property.squarefeet ? property.squarefeet : "",
    });
  }, [address, property]);

  const handleClose = () => {
    setOpen(false);
    setIsChanged(false);
    setErrors({});
    setValue({
      zip: address.zip,
      city: address.city,
      state: address.state,
      address: address.address,
      location_address_2: address.location_address_2,
      type: property.type ? property.type : "",
      bedrooms: property.bedrooms ? property.bedrooms : "",
      bathrooms: property.bathroom ? property.bathroom : "",
      yearBuilt: property.yearbuild ? property.yearbuild : "",
      foundation: property.foundation ? property.foundation : "",
      garage: property.garage ? property.garage : "",
      squareFeet: property.squarefeet ? property.squarefeet : "",
    });
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && dataIsCorrect && isChanged) {
      editPropertyDetails();
    }
    // eslint-disable-next-line
  }, [errors]);
  //Carousel
  const SwipeableTextMobileStepper = () => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = useState(0);
    const handleStepChange = (step) => setActiveStep(step);
    return (
      <Box>
        {property?.images ? (
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {property?.images?.map((step, index) => (
              <div key={step}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    component="img"
                    className="details__image"
                    sx={{ maxHeight: "40px" }}
                    src={step}
                    alt="Property images"
                  />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
        ) : (
          <div
            style={{
              backgroundColor: "#BEBEBE",
              textAlign: "center",
              height: "199px",
              margin: "0 auto",
              width: "100%",
              padding: "60px 0",
            }}
          >
            <HomeIcon
              sx={{ width: "65.83px", height: "55.96px", color: "#ffffff" }}
            />
          </div>
        )}
      </Box>
    );
  };
  //render
  return (
    <>
      <CardHeader
        CancelledStatus={CancelledStatus}
        title={"Property Details"}
        icon={
          <EditIcon
            className="details__icon"
            sx={{ width: "22px !important" }}
            onClick={handleOpen}
          />
        }
        iconDesc={
          <span className="edit_button" onClick={handleOpen}>
            Edit
          </span>
        }
      />
      <div className="details__layout">
        <div className="details__img">
          <SwipeableTextMobileStepper />
        </div>
        <div className="details__address">
          <div style={{ paddingTop: "10px", paddingBottom: "24px" }}>
            <Typography className="heading">Address</Typography>
            <Typography className="description">
              {Address?.replace(/ ,/g, ",")}
            </Typography>
            <div className="gridBox">
              <div className="grid__container">
                <Typography className="heading">Foundation</Typography>
                <Typography className="description">
                  {property.foundation ? property.foundation : "Unspecified"}
                </Typography>
              </div>
              <div className="grid__container">
                <Typography className="heading">Square Feet</Typography>
                <Typography className="description">
                  {property.squarefeet ? property.squarefeet : "Unspecified"}
                </Typography>
              </div>
              <div className="grid__container">
                <Typography className="heading">Bedrooms</Typography>
                <Typography className="description">
                  {property.bedrooms ? property.bedrooms : "Unspecified"}
                </Typography>
              </div>
              <div className="grid__container">
                <Typography className="heading">Year Built</Typography>
                <Typography className="description">
                  {property.yearbuild ? property.yearbuild : "Unspecified"}
                </Typography>
              </div>
              <div className="grid__container">
                <Typography className="heading">Garage</Typography>
                <Typography className="description">
                  {property.garage ? property.garage : "Unspecified"}
                </Typography>
              </div>
              <div className="grid__container">
                <Typography className="heading">Bathrooms</Typography>
                <Typography className="description">
                  {property.bathroom ? property.bathroom : "Unspecified"}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        className={"inspection-filter-modal-main"}
        sx={{ backgroundColor: "#bebebe" }}
      >
        <Box
          className={"inspection-filter-modal"}
          sx={{
            top: {
              xs: "70% !important",
              md: "65% !important",
              lg: "50% !important",
            },
            flexGrow: 1,
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{ p: { xs: "20px", md: "30px", lg: "30px" } }}
          >
            <Grid item xs={8}>
              <Typography
                sx={{
                  height: "30px",
                  width: "350px",
                  fontSize: "22px",
                  letterSpacing: 0,
                  color: "#333333",
                  lineHeight: "30px",
                  fontFamily: "Open Sans",
                }}
              >
                Edit Property Details
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <CloseIcon
                id="editproperty-closeeditproperty"
                onClick={handleClose}
                className={"inspection-filter-modal-close-icon"}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction={"row"}>
                <Grid item xs={4} sx={{ pr: 1 }}>
                  <SwipeableTextMobileStepper />
                </Grid>
                <Grid item xs={8}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControl variant="standard" className="forms-control">
                        <label
                          className="input-form-label"
                          htmlFor="first_name"
                          id="demo-simple-select-label"
                        >
                          Address*
                        </label>
                        <TextField
                          fullWidth
                          id="editproperty-addess"
                          type="text"
                          inputProps={{ maxLength: 30 }}
                          size="small"
                          name="address"
                          value={value.address}
                          onChange={handleChange}
                          error={errors.address}
                          helperText={errors.phone}
                          variant="outlined"
                          placeholder="Address"
                          className="input-textfield"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="standard" className="forms-control">
                        <label
                          className="input-form-label"
                          htmlFor="first_name"
                          id="demo-simple-select-label"
                        >
                          Address line two
                        </label>
                        <TextField
                          fullWidth
                          type="text"
                          id="editproperty-addess2"
                          size="small"
                          inputProps={{ maxLength: 35 }}
                          name="location_address_2"
                          value={value.location_address_2}
                          onChange={handleChange}
                          variant="outlined"
                          placeholder="Address line two"
                          className="input-textfield"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="standard" className="forms-control">
                        <label
                          className="input-form-label"
                          htmlFor="first_name"
                          id="demo-simple-select-label"
                        >
                          City*
                        </label>
                        <TextField
                          fullWidth
                          type="text"
                          id="editproperty-city"
                          inputProps={{ maxLength: 25 }}
                          size="small"
                          name="city"
                          variant="outlined"
                          value={value.city}
                          onChange={handleChange}
                          error={errors.city}
                          helperText={errors.city}
                          placeholder="City"
                          className="input-textfield"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="standard" className="forms-control">
                        <label
                          className="input-form-label"
                          htmlFor="first_name"
                          id="demo-simple-select-label"
                        >
                          State*
                        </label>
                        <TextField
                          fullWidth
                          type="text"
                          id="editproperty-state"
                          size="small"
                          name="state"
                          variant="outlined"
                          value={value.state}
                          onChange={handleStateChange}
                          error={errors.state}
                          helperText={errors.state}
                          placeholder="State"
                          className="input-textfield"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="standard" className="forms-control">
                        <label
                          className="input-form-label"
                          htmlFor="first_name"
                          id="demo-simple-select-label"
                        >
                          Zip*
                        </label>
                        <TextField
                          fullWidth
                          type="text"
                          id="editproperty-zip"
                          size="small"
                          name="zip"
                          variant="outlined"
                          value={value.zip}
                          error={errors.zip}
                          helperText={errors.zip}
                          onChange={handleZipChange}
                          placeholder="zip"
                          className="input-textfield"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="standard" className="forms-control">
                        <label
                          className="input-form-label"
                          htmlFor="first_name"
                          id="demo-simple-select-label"
                        >
                          Foundation
                        </label>
                        <TextField
                          fullWidth
                          type="text"
                          id="editproperty-foundation"
                          inputProps={{ maxLength: 25 }}
                          size="small"
                          name="foundation"
                          variant="outlined"
                          value={value.foundation}
                          onChange={handleChange}
                          placeholder="Unspecified"
                          className="input-textfield"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="standard" className="forms-control">
                        <label
                          className="input-form-label"
                          htmlFor="first_name"
                          id="demo-simple-select-label"
                        >
                          Square feet
                        </label>
                        <TextField
                          fullWidth
                          type="text"
                          id="editproperty-squarefeet"
                          inputProps={{ maxLength: 25 }}
                          size="small"
                          name="squareFeet"
                          variant="outlined"
                          value={value.squareFeet}
                          onChange={handleZipChange}
                          placeholder="Unspecified"
                          className="input-textfield"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="standard" className="forms-control">
                        <label
                          className="input-form-label"
                          htmlFor="first_name"
                          id="demo-simple-select-label"
                        >
                          Bedrooms
                        </label>
                        <TextField
                          fullWidth
                          type="text"
                          id="editproperty-bedrooms"
                          inputProps={{ maxLength: 3 }}
                          size="small"
                          name="bedrooms"
                          variant="outlined"
                          value={value.bedrooms}
                          onChange={handleZipChange}
                          placeholder="Unspecified"
                          className="input-textfield"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="standard" className="forms-control">
                        <label
                          className="input-form-label"
                          htmlFor="first_name"
                          id="demo-simple-select-label"
                        >
                          Year Built
                        </label>
                        <TextField
                          fullWidth
                          type="text"
                          id="editproperty-yearbuilt"
                          size="small"
                          inputProps={{ maxLength: 4 }}
                          name="yearBuilt"
                          variant="outlined"
                          value={value.yearBuilt}
                          onChange={handleZipChange}
                          placeholder="Unspecified"
                          className="input-textfield"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="standard" className="forms-control">
                        <label
                          className="input-form-label"
                          htmlFor="first_name"
                          id="demo-simple-select-label"
                        >
                          Bathrooms
                        </label>
                        <TextField
                          fullWidth
                          type="text"
                          id="editproperty-bathroom"
                          size="small"
                          inputProps={{ maxLength: 3 }}
                          name="bathrooms"
                          variant="outlined"
                          value={value.bathrooms}
                          onChange={handleZipChange}
                          placeholder="Unspecified"
                          className="input-textfield"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="standard" className="forms-control">
                        <label
                          className="input-form-label"
                          htmlFor="first_name"
                          id="demo-simple-select-label"
                        >
                          Type
                        </label>
                        <TextField
                          fullWidth
                          type="text"
                          id="editproperty-type"
                          size="small"
                          inputProps={{ maxLength: 20 }}
                          name="type"
                          variant="outlined"
                          value={value.type}
                          onChange={handleChange}
                          placeholder="Unspecified"
                          className="input-textfield"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant="standard" className="forms-control">
                        <label
                          className="input-form-label"
                          htmlFor="first_name"
                          id="demo-simple-select-label"
                        >
                          Garage
                        </label>
                        <TextField
                          fullWidth
                          type="text"
                          id="editproperty-garage"
                          size="small"
                          inputProps={{ maxLength: 20 }}
                          name="garage"
                          variant="outlined"
                          value={value.garage}
                          onChange={handleChange}
                          placeholder="Unspecified"
                          className="input-textfield"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            className="inspection-filter-modal-buttons"
            sx={{ mt: "-20px !important", pb: 2 }}
          >
            <Button
              variant="outlined"
              id="editproperty-closeeditproperty"
              className="inspection-filter-modal-buttons-cancel"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <LoadingButton
              id="editproperty-confirmeditproperty"
              loading={loading}
              disabled={!isChanged}
              onClick={handleformSubmit}
              variant="contained"
              className="inspection-filter-modal-buttons-apply"
            >
              Done
            </LoadingButton>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default PropertyDetails;
