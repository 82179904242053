import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InputAdornment from "@mui/material/InputAdornment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { onKeyPressed } from "../../utils/services";

const SelectedCharges = (props) => {
  const { selectedServices, addInput, addPrice, editingData, descError, priceError, errors, showCustomAddInput, addCustomDiscount, showDiscountInput, addCustomCharge, setAddedInput, setAddedPrice, rows, deleteService, clearInputField, handleformSubmit, setSubmitButtonId, editService, addChargeOrDiscount } = props;
  return (
    <>
      {selectedServices?.length !== 0 ? (
        <>
          <Grid item xs={12} sm={8} md={8} className="modal-inner-sub-title">
            <Typography>Charges</Typography>
            <TableContainer>
              <Table size="small" sx={{ maxWidth: "349px" }}>
                <TableBody>
                  {rows.map((row, key) => (
                    <TableRow key={key} sx={{ border: "1px solid #C4C4C4" }}>
                      <TableCell key={key} sx={{ border: "1px solid #c4c4c4" }} align="left">
                        {row.type === "discount" || row.type === "charge" ? (
                          <>
                            {row.name}
                            <div className="services-action-wrapper">
                              <span
                                className="edit-service"
                                style={{
                                  pointerEvents: ((showDiscountInput && showCustomAddInput) || showCustomAddInput || showDiscountInput) && "none",
                                  opacity: ((showDiscountInput && showCustomAddInput) || showCustomAddInput || showDiscountInput) && "0.25",
                                }}
                                onClick={() => {
                                  editService(key, row.type);
                                  setAddedInput(row.name);
                                  setAddedPrice(row.amount);
                                }}
                              >
                                <EditIcon />
                                <span>Edit</span>{" "}
                              </span>
                              <span
                                className="delete-service"
                                style={{
                                  pointerEvents: ((showDiscountInput && showCustomAddInput) || showCustomAddInput || showDiscountInput) && "none",
                                  opacity: ((showDiscountInput && showCustomAddInput) || showCustomAddInput || showDiscountInput) && "0.25",
                                }}
                                onClick={() => {
                                  deleteService(key, row.type);
                                }}
                              >
                                <DeleteIcon />
                                <span>Delete</span>
                              </span>
                            </div>
                          </>
                        ) : (
                          row.name
                        )}
                      </TableCell>
                      <TableCell sx={{ border: "1px solid #C4C4C4" }} align="left">
                        {/* {Math.sign(row.rate) !== -1 ?"":"-"}${Math.abs(row.rate)} */}${row.amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={8} md={3} lg={4} className="add-action pl-15">
            <Typography style={{ opacity: !showDiscountInput ? "1.00" : "0.25", pointerEvents: showDiscountInput && "none" }} onClick={addCustomCharge}>
              Add custom charge {showCustomAddInput ? <RemoveCircleIcon style={{ color: "#E30303" }} /> : <AddCircleIcon />}
            </Typography>
          </Grid>
          <Grid item xs={8} md={2} lg={3} className="add-action pl-15">
            <Typography style={{ opacity: !showCustomAddInput ? "1.00" : "0.25", pointerEvents: showCustomAddInput && "none" }} onClick={addCustomDiscount}>
              Add discount {showDiscountInput ? <RemoveCircleIcon style={{ color: "#E30303", textTransform: "none" }} /> : <AddCircleIcon />}
            </Typography>
          </Grid>

          {showDiscountInput || showCustomAddInput ? (
            <>
              <Grid container sx={{ alignItems: "end", marginBottom: "50px" }}>
                <Grid item xs={4} md={4} className="pl-15" style={{ marginRight: "10px" }}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <label className="input-form-label" htmlFor=" Inspection ID" id="demo-simple-select-label">
                      Description
                    </label>
                    <TextField className="input-textfield input-value" size="small" variant="outlined" placeholder="Type here" defaultValue={editingData?.name} type="text" onChange={addInput} error={errors.descError} />
                  </FormControl>
                </Grid>
                <Grid item xs={2} md={2} style={{ marginRight: "10px" }}>
                  <FormControl variant="standard" style={{ width: "100%" }}>
                    <label className="input-form-label" htmlFor=" Inspection ID" id="demo-simple-select-label">
                      {showDiscountInput ? "Discount" : "Charge"}
                    </label>
                    <TextField
                      className="input-textfield input-price"
                      size="small"
                      variant="outlined"
                      placeholder=""
                      onKeyDown={onKeyPressed}
                      type="number"
                      error={errors.priceError}
                      // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                      onChange={addPrice}
                      defaultValue={editingData?.amount}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{
                          // showDiscountInput ? "$ " : 
                        "$ "}</InputAdornment>,
                      }}
                      onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,5)
                    }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={5} md={5} lg={5} style={{ marginBottom: "2px" }}>
                  <Button className="add-btn" sx={{textTransform: "none"}} variant="contained" onClick={(event) => handleformSubmit(event, "ADD")}>
                    <Typography sx={{textTransform: "none !important"}}>
                      {editingData?.length !== 0 ? (showDiscountInput ? `Update discount` : "Update price") : showDiscountInput ? "Add discount" : "Add charge"}
                      {/* {showDiscountInput ? "Add discount" : "Add Price"} */}
                    </Typography>
                  </Button>
                  {/* <Button className="clear-btn" variant="contained" color="success" onClick={clearInputField}>
                    <Typography>Clear</Typography>
                  </Button> */}
                </Grid>
              </Grid>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default SelectedCharges;
