import { Button } from "@mui/material";

const ActionButton = ({btnType,text,action, id,disabled}) => {
  return (
    <>
      <Button id={id} className={`pt_temp_editor_cta ${btnType} ${disabled?'pt_action_disabled':''}`} onClick={disabled?null:action} >
        {text}
      </Button>
    </>
  );
};

export default ActionButton;