import { Modal, Box, Grid, Typography, FormControl, TextField, FormControlLabel, Checkbox, InputAdornment, IconButton } from "@mui/material";
import "../../../assets/styles/containers/_unbounceCreatePassword.scss";
import { useState } from "react";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { LoadingButton } from "@mui/lab";
const UnbounceCreatePassword = ({ loading, validationUnbounce, unbouncePasswordError }) => {
  const [password, setPassword] = useState("");
  const [agree, setAgree] = useState(0);
  const [agreeError, setAgreeError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const Login = (password) => {
    if (agree === 0) {
      return setAgreeError(true);
    } else validationUnbounce(password);
  };

  return (
    <Modal
      open
      onClose
      aria-labelledby="modal-modal-title"
      sx={{
        backdropFilter: "blur(5px)",
        //other styles here
      }}
      aria-describedby="modal-modal-description"
      className="unbounce-create-modal-main"
    >
      <Box className="unbounce-modal">
        <Grid className="unbounce-create-modal-grid-main" container spacing={2}>
          <Grid item xs={12}>
            <Grid className="center">
              <Typography className="header" sx={{ mt: "23px", fontSize: { xs: "24px", md: "22px", lg: "22px" } }}>
                Almost done!
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} className="center">
            <div style={{ maxWidth: "211px" }}>
              <Typography className="header" sx={{ textAlign: "center", fontSize: { xs: "16px", md: "16px", lg: "16px" } }}>
                Create a password to finish setting up your account.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid className="center">
              <FormControl variant="standard">
                {/* <InputLabel htmlFor="component-simple" className="input_lable" sx={{ top: "1px", fontSize: { xs: "14px", md: "12px", lg: "12px" } }}>
                    PASSWORD
                  </InputLabel> */}
                <TextField
                  id="password"
                  className="input_field"
                  variant="outlined"
                  error={unbouncePasswordError}
                  placeholder="Create a Password"
                  // size={`${matches ? "small" : "medium"}`}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(event) => {
                    setAgreeError(false);
                    setPassword(event.target.value?.trim());
                  }}
                  // onKeyPress={(e) => {
                  //   if (e.key === "Enter" && !loading) validation(email, e.target.value);
                  // }}
                  helperText={`${agreeError ? "Please check the terms and conditions" : unbouncePasswordError === true ? "Password did not meet the minimum criteria." : ""}`}
                  sx={{ marginTop: "25px", width: { xs: "240px", md: "267px", lg: "267px" } }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton sx={{ mr: "-9px" }} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                    className: "input_props_field",
                    sx: { height: { xs: "55px", md: "40px", lg: "40px" }, fontSize: { xs: "18px", md: "14px", lg: "14px" } },
                  }}
                />
              </FormControl>
            </Grid>
            <Grid className="center">
              <div style={{ maxWidth: "265px" }}>
                <p className="requirement-pw">Min. 8 characters with uppercase, lowercase, a number and a special character required.</p>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12} sx={{ ml: "25px" }}>
            <FormControlLabel
              sx={{ p: 0 }}
              control={
                <Checkbox
                  onChange={(e) => {
                    setAgree(e.target.checked ? 1 : 0);
                    setAgreeError(e.target.checked ? false : true);
                  }}
                  id="agree_terms"
                />
              }
              label={
                <>
                  <p className="unbounce_terms_and_condition">I agree to the Palmtech </p>
                  <p className="unbounce_terms_and_condition_hyperlink" onClick={() => window.open("https://www.palmtech.com/terms", "_blank")}>
                    Terms of Use
                  </p>
                </>
              }
              // labelPlacement="end"
            />
            <div id="agreement-box-error"></div>
          </Grid>
          <Grid item xs={12}>
            <Grid className="center">
              <LoadingButton className="unbounce_button" loading={loading} onClick={() => Login(password)} sx={{ height: { xs: 48, md: 48, lg: 48 }, width: { xs: 266, md: 266, lg: 266 }, fontSize: { xs: 16, md: 16, lg: 16 } }} size="small" variant="contained">
                Start using Palmtech
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default UnbounceCreatePassword;
