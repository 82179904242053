import axios from "axios"; //API calls
import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import CardHeader from "../../common/cardHeader";
import { apiCall } from "../../utils/action";
import { decode } from "js-base64";
import "../../../assets/styles/containers/_inspDetails.scss";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function MapBox(props) {
  const map = useRef(null);
  const [key, setKey] = useState(null);
  const mapContainer = useRef(null);
  const [center, setCenter] = useState([71.23, 23.23]);
  const [zoom, setZoom] = useState(3);
  mapboxgl.accessToken = key;

  // const queryAddress = window.location.hash.split("?")[1];
  const getLocation = () => {
    axios
      .get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${props.address.address},${props.address.city},${props.address.state},${props.address.zip}.json?&limit=1&access_token=${key}`)
      .then((response) => {
        // Request with success code true
        // console.log(response, "data");
        setCenter(response.data.features[0].center);
        localStorage.setItem("marker", JSON.stringify(response.data.features[0].center));
        map.current.flyTo({
          speed: 3,
          center: response.data.features[0].center,
          zoom: 15,
          essential: false,
        });
        const marker = new mapboxgl.Marker({ color: "Red", display: "block" }).setLngLat(response.data.features[0].center).addTo(map.current);
        const marker2 = new mapboxgl.Marker({ color: "black", rotation: 45 }).setLngLat([12.65147, 55.608166]).addTo(map.current);
        setZoom(7);
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("no response", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
      });
  };

  const getKey = () => {
    const formdata = { company_id: sessionStorage.getItem("company_id"), user_id: sessionStorage.getItem("user_id"), role_id: sessionStorage.getItem("role_id") };
    apiCall(
      (response) => {
        const { success, data } = response.data;
        if (success) {
          setKey(decode(data));
        } else {
          console.log("else", response.data.message);
        }
      },
      "",
      "GET_KEY"
    );
  };

  useEffect(() => {
    getKey();
  }, []);

  useEffect(() => {
    // initialize map only once
    // console.log(map.current);
    if (key) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: center,
        attributionControl: false,
        zoom: zoom,
      });
      getLocation();
    }
  }, [props.address, key]);

  return (
    <div>
      <CardHeader
        title={"Map"}
        icon={
          <OpenInNewIcon
          sx={{width:"21px !important"}}
            className="details__icon"
            onClick={() => window.open(`${window.location.origin}/#/mapview`, "_blank")}
            // onClick={() => mapContainer.current.requestFullscreen()}
          >
            {" "}
          </OpenInNewIcon>
        }
        iconDesc={
          <p
            className="details__text"
            onClick={() => window.open(`${window.location.origin}/#/mapview`, "_blank")}
            // onClick={() => mapContainer.current.requestFullscreen()}
          >
            Open in browser
          </p>
        }
      />
      {key ? (
        <div
          ref={mapContainer}
          className="map-container"
          style={{
            height: "250px",
            width: "100%",
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
}

// OLD CODE*************************************************************************************8
// import React from "react";
// import CardHeader from "../../common/cardHeader";
// // import Map from "../../common/map/Map";
// // import Marker from "../../common/map/Marker";
// import "../../../assets/styles/containers/_inspDetails.scss";
// import OpenInNewIcon from "@mui/icons-material/OpenInNew";
// import ReactMapboxGl, { Marker } from "react-mapbox-gl";
// import "mapbox-gl/dist/mapbox-gl.css";
// ReactMapboxGl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line

// const Map = ReactMapboxGl({
//   preserveDrawingBuffer: true,
//   accessToken:
//     "pk.eyJ1IjoibWF5b2ppY2giLCJhIjoiY2pla3Q3MzVvMWRoYTJybnVyMndxM2hmdCJ9.nWZlYcpKaMqz6m7xTsnJGA",
// });

// const MapBox = () => {
//   const markerUrl =
//     "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png";

//   return (
//     <div>
//       <CardHeader
//         title={"Map"}
//         icon={<OpenInNewIcon className="details__icon"> </OpenInNewIcon>}
//         iconDesc={"Openss in browser"}
//       />
//       <div className="map-container">
//         <Map
//           style="mapbox://styles/mapbox/streets-v11"
//           zoom={[14.6]}
//           containerStyle={{
//             height: "219px",
//             width: "100%",
//           }}
//         >
//           <Marker coordinates={[-0.2416815, 51.5285582]} anchor="bottom">
//             <img
//               style={{
//                 width: "35px",
//               }}
//               src={
//                 "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png"
//               }
//             />
//           </Marker>
//           <Marker coordinates={[-60.84781, -28.9651]} anchor="bottom">
//             <img
//               style={{
//                 width: "35px",
//               }}
//               src={
//                 "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png"
//               }
//             />
//           </Marker>
//           <Marker coordinates={[80.05818, 23.23519]} anchor="bottom">
//             <img
//               style={{
//                 width: "35px",
//               }}
//               src={
//                 "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png"
//               }
//             />
//           </Marker>
//         </Map>
//       </div>
//     </div>
//   );
// };

// export default MapBox;
