import Box from "@mui/material/Box";
import Header from "../../../common/header/Header";
import Breadcrumb from "../../../common/header/Breadcrumb";
import ISGSettings from "../../../components/InspectorServiceGroup/ISGSettings";

const InspectorServiceGroup = () => {
  return (
    <>
      {window.scrollTo(0, 0)}
      <Box className="page-background">
        <Header />
        <Breadcrumb />
        <ISGSettings />
      </Box>
    </>
  );
};

export default InspectorServiceGroup;
