import { Button, Menu, MenuItem, ListItemText, ListSubheader } from '@mui/material';
import { Grid } from '@mui/material';
import SvgIcon from "@material-ui/core/SvgIcon";
import { useRef, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import { AddInputIcon, CheckBoxIcon, SignatureIcon } from '../../../../../assets/svgIcons/Svg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import uuid from "react-uuid";
import moment from 'moment';
import { tableElArray } from './TableElementStaticData';
import { timestamp } from '../../../../utils/services';

const AddElementButton = ({elemParentType,catuuid,templateState,setTemplateState,updatePtTempateData,elemuuid,catType,setActive= () => {}, parentType}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuWidth, setMenuWidth] = useState(null);
    const buttonRef = useRef(null)
    const handleClick = (event) => {
      if(elemParentType === "expandingCheckBox" || elemParentType === "group" || parentType==="expandingCheckBox" || parentType==="group") setActive(true)
      event.stopPropagation()
      setAnchorEl(event.currentTarget);
      if (buttonRef.current) {
        setMenuWidth(buttonRef.current.offsetWidth);
      }      
    };
    const createNewElemObject=(elemType)=>{
      let elemObj;
      if(elemType === "Layout/Table"){
        elemObj ={
          type: "Layout/Table",
          uuid: uuid(),
          label: "Opening Protection Level Chart",
          order: 1,
          merge_id:"table_v1",
          value: "Place an X in each row to identify all forms of protection in use for each opening type. Check only one answer below (A thru X), based on the weakest form of protection (lowest row) for any of the Glazed openings and indicate the weakest form of protection (lowest row) for Non-Glazed openings.",
          elements:tableElArray,
          "updated_at": timestamp()
        }
      }
      else if(elemType=== "Radio"){
        elemObj ={
          type: elemType,
          uuid:  uuid(), 
          label: "",
          order: null, 
          value: null,
          merge_id: (elemType === "Layout/Group" ||elemType === "InformationText/Plain" ||elemType === "Layout/Table" || elemType === "Divider") ? "static_merge_id" : null,
          elements: ['Choice 1','Choice 2'],
          updated_at: timestamp(), 
        }
      }
      else{
        elemObj ={
          type: elemType,
          uuid:  uuid(), 
          label: "",
          order: null, 
          value: null,
          merge_id: (elemType === "Layout/Group" ||elemType === "InformationText/Plain" ||elemType === "Layout/Table" || elemType === "Divider") ? "static_merge_id" : null,
          elements: [],
          updated_at: timestamp(), 
        }
      }
      
      return elemObj
    }
  
    function addElementByUUID(jsonObj, targetUUID, elemType, newElement) {
      function findTarget(jsonObj) {
        if (Array.isArray(jsonObj)) {
          for (const item of jsonObj) {
            if (item.uuid === targetUUID) {
              if (!item.elements) {
                item.elements = [];
              }
              newElement.type = elemType;
              newElement.order = item.elements.length + 1;
              item.elements.push(newElement);
              return;
            }
            findTarget(item);
          }
        } else if (typeof jsonObj === "object" && jsonObj !== null) {
          for (const key in jsonObj) {
            if (jsonObj.hasOwnProperty(key)) {
              findTarget(jsonObj[key]);
            }
          }
        }
      }    
      findTarget(jsonObj);
    }

    const handleClose = (event) => {
      setAnchorEl(null);
      const clickedItemText = event.target.textContent;
      let newElement; 
      if(clickedItemText !== ''){    
        if(clickedItemText === 'Input field') {
          newElement= createNewElemObject("Input/Text")
          addElementByUUID(templateState?.templateDataCopy.data , elemParentType === 'category'?catuuid:elemuuid, "Input/Text", newElement)
        }else if(clickedItemText === 'Informational Text'){
          newElement= createNewElemObject("InformationText/Plain")
          addElementByUUID(templateState?.templateDataCopy.data , elemParentType === 'category'?catuuid:elemuuid, "InformationText/Plain", newElement)
        }else if(clickedItemText === 'Checkbox'){
          newElement= createNewElemObject("CheckBox")
          addElementByUUID(templateState?.templateDataCopy.data , elemParentType === 'category'?catuuid:elemuuid, "CheckBox", newElement)
        }else if(clickedItemText === 'Radio'){
          newElement= createNewElemObject("Radio")
          addElementByUUID(templateState?.templateDataCopy.data , elemParentType === 'category'?catuuid:elemuuid, "Radio", newElement)
        }else if(clickedItemText === 'Signature'){
          newElement= createNewElemObject("Signature/Any")
          addElementByUUID(templateState?.templateDataCopy.data , elemParentType === 'category'?catuuid:elemuuid, "Signature/Any", newElement)
        }else if(clickedItemText === 'Media uploader'){
          newElement= createNewElemObject("MediaUploader")
          addElementByUUID(templateState?.templateDataCopy.data , elemParentType === 'category'?catuuid:elemuuid, "MediaUploader", newElement)
        }else if(clickedItemText === 'Group'){
          newElement= createNewElemObject("Layout/Group")
          addElementByUUID(templateState?.templateDataCopy.data , elemParentType === 'category'?catuuid:elemuuid, "Layout/Group", newElement)
        }else if(clickedItemText === 'Expanding Checkbox'){
          newElement= createNewElemObject("Layout/ExpandingCheckBox")
          addElementByUUID(templateState?.templateDataCopy.data , elemParentType === 'category'?catuuid:elemuuid, "Layout/ExpandingCheckBox", newElement)
        }else if(clickedItemText === 'FL Wind Mit Table'){
          newElement= createNewElemObject("Layout/Table")
          addElementByUUID(templateState?.templateDataCopy.data , elemParentType === 'category'?catuuid:elemuuid, "Layout/Table", newElement)
        }else if(clickedItemText === 'Divider'){
          newElement= createNewElemObject("Divider")
          addElementByUUID(templateState?.templateDataCopy.data , elemParentType === 'category'?catuuid:elemuuid, "Divider", newElement)
        }    
      let prevData = JSON.parse(JSON.stringify(templateState?.templateDataCopy));
      let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
      let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
      let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
      newUpdatedSpecificData.data.template.pdf_categories = editedCategory
      setTemplateState(prev=>({...prev,templateData:prevData}))
      updatePtTempateData(newUpdatedSpecificData?.data?.template)     
      // }
    }     
    };

    const getMenuPosition = () => {
        if (buttonRef.current) {
          const buttonRect = buttonRef.current.getBoundingClientRect();
          return {
            top: buttonRect.bottom,
            left: buttonRect.left,
          };
        }
        return null;
      };
      
  return (
    <Grid className={`pt_add_elem_btn ${anchorEl !== null? 'el_btn_active':''} ${templateState.roleId !== 2 && templateState.roleId !== 3?'pt_elem_disabled':''}`}>
      <Button aria-controls="grouped-menu" variant="outlined" aria-haspopup="true"
       disableRipple disabled={templateState?.updatingTemplate || templateState.roleId !== 2 && templateState.roleId !== 3} startIcon={<AddCircleOutlineIcon />} onClick={handleClick}
       ref={buttonRef}
       >
       Add form element <ArrowDropDownIcon className={`${anchorEl !== null?'pt_caret caret_active':'pt_caret'}`}/>
      </Button>
      <Menu
        id="grouped-menu"
        anchorEl={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={getMenuPosition()}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className='pt_add_elem_btn'
        PaperProps={{
            style: {
              width: menuWidth, // Set the menu width dynamically
            },
          }}
          onMouseDown={() => setActive(true)}
      >        
          <div>
          
            {
              catType === 1?
              <>
              <ListSubheader>SIMPLE FIELDS</ListSubheader> 
              <MenuItem key={'Informational Text'} onClick={handleClose}>
              <span className='pt_add_elem_btn_li_item'><DescriptionOutlinedIcon/><ListItemText primary='Informational Text' /></span>
            </MenuItem>
            <MenuItem key={'Input field'} onClick={handleClose}>
              <span className='pt_add_elem_btn_li_item'>
              <SvgIcon viewBox="0 0 12 12" style={{ height: "12px", width: "12px" }}>
                {AddInputIcon}
              </SvgIcon>
                {/* <div className="pt_is_flex_align_center gap5">
                      <span className="pt_element_span pt_all_center">A</span>
                </div> */}
                <ListItemText primary='Input field' /></span>
            </MenuItem>
            <MenuItem key={'Checkbox'} onClick={handleClose}>
            <span className='pt_add_elem_btn_li_item'>
            <SvgIcon viewBox="0 0 12 12" style={{ height: "12px", width: "12px" }}>
                {CheckBoxIcon}
              </SvgIcon>
              <ListItemText primary='Checkbox' /></span>
            </MenuItem>
            <MenuItem key={'Radio'} onClick={handleClose}>
               <span className='pt_add_elem_btn_li_item'><RadioButtonCheckedIcon/><ListItemText primary='Radio' /></span>
            </MenuItem>
            <MenuItem key={'Signature'} onClick={handleClose}>
               <span className='pt_add_elem_btn_li_item'>
               <SvgIcon viewBox="0 0 12 12" style={{ height: "12px", width: "12px" }}>
                {SignatureIcon}
              </SvgIcon>
              <ListItemText primary='Signature' /></span>
            </MenuItem>
            <MenuItem key={'Media uploader'} onClick={handleClose}>
               <span className='pt_add_elem_btn_li_item'><CollectionsOutlinedIcon/><ListItemText primary='Media uploader' /></span>
            </MenuItem>
            <ListSubheader>LAYOUT</ListSubheader>            
            <MenuItem key={'Group'} onClick={handleClose}>
               <span className='pt_add_elem_btn_li_item'><LibraryAddCheckOutlinedIcon/><ListItemText primary='Group' /></span>
            </MenuItem>
            <MenuItem key={'Expanding Checkbox'} onClick={handleClose}>
               <span className='pt_add_elem_btn_li_item'><LibraryAddCheckOutlinedIcon/><ListItemText primary='Expanding Checkbox' /></span>
            </MenuItem>
            <MenuItem key={'Table'} onClick={handleClose}>
               <span className='pt_add_elem_btn_li_item'><TableChartOutlinedIcon/><ListItemText primary='FL Wind Mit Table' /></span>
            </MenuItem>
            <MenuItem key={'Divider'} onClick={handleClose}>
               <span className='pt_add_elem_btn_li_item'><HorizontalRuleOutlinedIcon/><ListItemText primary='Divider' /></span>
            </MenuItem>
            </>
              :catType === 2?
              <>
              <ListSubheader>SIMPLE FIELDS</ListSubheader> 
              <MenuItem key={'Informational Text'} onClick={handleClose}>
              <span className='pt_add_elem_btn_li_item'><DescriptionOutlinedIcon/><ListItemText primary='Informational Text' /></span>
            </MenuItem>
            <MenuItem key={'Input field'} onClick={handleClose}>
              <span className='pt_add_elem_btn_li_item'>
              <SvgIcon viewBox="0 0 12 12" style={{ height: "12px", width: "12px" }}>
                {AddInputIcon}
              </SvgIcon>               
                <ListItemText primary='Input field' /></span>
            </MenuItem>
            <MenuItem key={'Checkbox'} onClick={handleClose}>
            <span className='pt_add_elem_btn_li_item'>
            <SvgIcon viewBox="0 0 12 12" style={{ height: "12px", width: "12px" }}>
                {CheckBoxIcon}
              </SvgIcon>
              <ListItemText primary='Checkbox' /></span>
            </MenuItem>
            <MenuItem key={'Radio'} onClick={handleClose}>
               <span className='pt_add_elem_btn_li_item'><RadioButtonCheckedIcon/><ListItemText primary='Radio' /></span>
            </MenuItem>
            <MenuItem key={'Signature'} onClick={handleClose}>
               <span className='pt_add_elem_btn_li_item'>
               <SvgIcon viewBox="0 0 12 12" style={{ height: "12px", width: "12px" }}>
                {SignatureIcon}
              </SvgIcon>
              <ListItemText primary='Signature' /></span>
            </MenuItem>           
            <ListSubheader>LAYOUT</ListSubheader>            
            <MenuItem key={'Group'} onClick={handleClose}>
               <span className='pt_add_elem_btn_li_item'><LibraryAddCheckOutlinedIcon/><ListItemText primary='Group' /></span>
            </MenuItem>           
            <MenuItem key={'Divider'} onClick={handleClose}>
               <span className='pt_add_elem_btn_li_item'><HorizontalRuleOutlinedIcon/><ListItemText primary='Divider' /></span>
            </MenuItem>
            </>
              :
              <>
              <ListSubheader>SIMPLE FIELDS</ListSubheader> 
              <MenuItem key={'Informational Text'} onClick={handleClose}>
              <span className='pt_add_elem_btn_li_item'><DescriptionOutlinedIcon/><ListItemText primary='Informational Text' /></span>
              </MenuItem>            
              <ListSubheader>LAYOUT</ListSubheader>            
              <MenuItem key={'Group'} onClick={handleClose}>
                <span className='pt_add_elem_btn_li_item'><LibraryAddCheckOutlinedIcon/><ListItemText primary='Group' /></span>
              </MenuItem>           
              </>
            }           
             
          </div>
       
      </Menu>
    </Grid>
  )
}

export default AddElementButton