import React from "react";
import axios from "axios"; //API calls
import { useState, useEffect } from "react";
import validator from "validator";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import { Grid, Button } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TableHead from "@mui/material/TableHead";
import EditIcon from "@mui/icons-material/Edit";
import { useMediaQuery } from "react-responsive";
import DeleteIcon from "@mui/icons-material/Delete";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableContainer from "@mui/material/TableContainer";
import { GetCurrentResolution } from "../../utils/services";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InputAdornment from "@mui/material/InputAdornment";
import { visuallyHidden } from "@mui/utils";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
// dialog imports
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import DialogContentText from "@mui/material/DialogContentText";
// styles imports
import "../../../assets/styles/containers/_teams.scss";
import "../../../assets/styles/containers/_services.scss";
import Sortswitch from "../../../assets/images/Sortswitch.png";
// common components imports
import { apiCall } from "../../utils/action";
import { formatCost, getComparator, stableSort } from "../../utils/services";
import Preloader from "../../helpers/Preloader";

import { API_CONSTANTS } from "../../config/api-constants";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const servicesTableHead = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "SERVICE",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "DESCRIPTION",
  },
  { id: "amount", numeric: false, disablePadding: false, label: "COST" },
  { id: "duration", numeric: false, disablePadding: false, label: "DURATION" },
  { id: "actions", numeric: false, disablePadding: false, label: "ACTIONS" },
];

//map funtion of table heading
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {servicesTableHead.map((headCell) =>
          !props.permission ? (
            headCell.label !== "ACTIONS" && (
              <td
                key={headCell.id}
                align={headCell.label === "ACTIONS" ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
                className={
                  headCell.label === "ACTIONS"
                    ? "team-table-head action-column"
                    : "team-table-head"
                }
              >
                <TableSortLabel
                  IconComponent={() =>
                    headCell.label !== "ACTIONS" ? (
                      <img
                        src={Sortswitch}
                        alt="sort-icon"
                        className="sort-switch"
                      />
                    ) : (
                      ""
                    )
                  }
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  sx={{
                    marginTop: GetCurrentResolution() === "mobile" && "21px",
                    marginBottom: "10.5px",
                    flexDirection: "row-reverse",
                    fontSize: "12px",
                  }}
                >
                  <span className="head-title">
                    {headCell.label}{" "}
                    {headCell.label === "DURATION" ? (
                      <BootstrapTooltip
                        title={<h1>Duration with 30 minutes intervel</h1>}
                      >
                        <InfoOutlinedIcon />
                      </BootstrapTooltip>
                    ) : (
                      ""
                    )}
                  </span>
                  {orderBy === headCell.id ? (
                    <Box
                      component="span"
                      className="filter-icon"
                      sx={visuallyHidden}
                    >
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </td>
            )
          ) : (
            <td
              key={headCell.id}
              align={headCell.label === "ACTIONS" ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              className={
                headCell.label === "ACTIONS"
                  ? "team-table-head action-column"
                  : "team-table-head"
              }
            >
              <TableSortLabel
                IconComponent={() =>
                  headCell.label !== "ACTIONS" ? (
                    <img
                      src={Sortswitch}
                      alt="sort-icon"
                      className="sort-switch"
                    />
                  ) : (
                    ""
                  )
                }
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  marginTop: GetCurrentResolution() === "mobile" && "21px",
                  marginBottom: "10.5px",
                  flexDirection: "row-reverse",
                  fontSize: "12px",
                }}
              >
                <span className="head-title">
                  {headCell.label}{" "}
                  {headCell.label === "DURATION" ? (
                    <BootstrapTooltip
                      title={<h1>Duration with 30 minutes intervel</h1>}
                    >
                      <InfoOutlinedIcon />
                    </BootstrapTooltip>
                  ) : (
                    ""
                  )}
                </span>
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    className="filter-icon"
                    sx={visuallyHidden}
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </td>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ServicesListView = (props) => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [table_data, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [stateChanged, setStateChanged] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [serviceId, setServiceId] = useState("");
  const [templateList, setTemplateList] = useState([]);
  const [duration, setDuration] = useState("");
  const [templates, setTemplates] = useState([]);
  const [texasTemplates, setTexasTemplates] = useState([]);
  const [cost, setCost] = useState("");
  const [calculationCost, setCalculationCost] = useState();
  const [description, setDescription] = useState("");
  const [showLoader, setShowLoader] = useState();
  const [isFocussed, setIsFocussed] = useState(false);
  const [serviceNameError, setServiceNameError] = useState(false);
  const [serviceNameErrorText, setServiceNameErrorText] = useState("");
  const [durationError, setDurationError] = useState(false);
  const [durationErrorText, setDurationErrorText] = useState("");
  const [templatesError, setTemplatesError] = useState(false);
  const [templatesErrorText, setTemplatesErrorText] = useState("");
  const [agreementsError, setAgreementsError] = useState(false);
  const [agreementsErrorText, setAgreementsErrorText] = useState("");
  const [costError, setCostError] = useState(false);
  const [costErrorText, setCostErrorText] = useState("");
  // const [descriptionError, setDescriptionError] = useState(false);
  // const [descriptionErrorText, setDescriptionErrorText] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const uuId = sessionStorage.getItem("user_id");
  const roleId = sessionStorage.getItem("role_id");
  const companyId = sessionStorage.getItem("company_id");
  const [agreementList, setAgreementList] = useState([]);
  const [agreement, setAgreement] = useState([]);
  let permission = roleId === "7" ? false : true;
  // get templates list api call
  const getTemplatesList = () => {
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          let tempList = data.filter(
            (template) => template.is_active === 1 || template.is_published
          );
          setTemplateList(data);
        } else {
          toast.error(message);
        }
      },
      formdata,
      "GET_TEMPLATE_LIST"
    );
  };
  // get agreement list api call
  const getAgreementList = () => {
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          setAgreementList(data);
        } else {
          toast.error(message);
        }
      },
      formdata,
      "GET_AGREEMENTS_LIST"
    );
  };
  //get services list from api call
  const getServicesList = () => {
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        if (response.data.success) {
          setTableData(response.data.data);
          setShowLoader(false);
        } else {
          setTableData([]);
          setShowLoader(false);
        }
      },
      formdata,
      "GET_SERVICES_LIST"
    );
  };

  // add services api call
  const addServices = () => {
    setShowLoader(true);
    toast.dismiss();
    const formdata = {
      company_id: companyId,
      name: serviceName,
      cost: calculationCost,
      user_id: uuId,
      duration: duration,
      description: description,
      template_id: templates.map((x) => x.id),
      agreement_id: agreement.map((x) => x.id).toString(),
    };
    handleClose();
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          toast.success(message);
          setStateChanged(!stateChanged);
          setShowLoader(false);
        } else {
          setShowLoader(false);
          toast.error(message);
        }
      },
      formdata,
      "ADD_SERVICES"
    );
  };

  // edit service api call
  // get service detail
  const getServiceDetail = (id) => {
    setServiceName("");
    setCost("");
    setDuration("");
    setTemplates([]);
    setDescription("");
    setAgreement([]);
    const formdata = { company_id: companyId, user_id: uuId, service_id: id };
    apiCall(
      (response) => {
        if (response.data.success) {
          setServiceName(response?.data?.data?.service.name);
          let { formatted, calculation } = formatCost(
            response?.data?.data?.service.amount
          );
          setCost(formatted);
          setCalculationCost(calculation);
          setDuration(response?.data?.data?.service.duration);
          setTemplates(
            response?.data?.data?.service_related_templates.map(
              (x) => x.template
            )
          );
          setAgreement(
            response?.data?.data?.service.agreement !== null
              ? [response?.data?.data?.service.agreement]
              : []
          );
          setDescription(response?.data?.data?.service.description);
        } else {
          setShowLoader(false);
          console.log("else", response.data.message);
        }
      },
      formdata,
      "GET_SERVICE_DETAIL"
    );
  };
  // edit data api
  const editService = () => {
    handleClose();
    setShowLoader(true);
    toast.dismiss();

    const formdata = {
      company_id: companyId,
      name: serviceName,
      cost: calculationCost,
      user_id: uuId,
      duration: duration,
      description: description,
      template_id: templates.map((x) => x.id),
      service_id: serviceId,
      agreement_id: agreement.map((x) => x.id).toString(),
    };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setShowLoader(false);
          setServiceId("");
          setStateChanged(!stateChanged);
        } else {
          setShowLoader(false);
          toast.error(message);
        }
      },
      formdata,
      "EDIT_SERVICE_LIST"
    );
  };

  // delete service
  const deleteService = (id) => {
    setShowLoader(true);
    toast.dismiss();
    const formdata = { company_id: companyId, service_id: id, user_id: uuId };
    handleCloseAlert();
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setStateChanged(!stateChanged);
          setShowLoader(false);
        } else {
          setShowLoader(false);
          toast.error(message);
        }
      },
      formdata,
      "DELETE_SERVICE"
    );
  };

  const handleRequestSort = (event, property) => {
    console.log("property", property);
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    console.log("type", order, "isasc", isAsc);
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - table_data.length) : 0;

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  //   modal functions
  const handleClickOpen = () => {
    setServiceName("");
    setCost("");
    setCalculationCost();
    setDuration("");
    setTemplates([]);
    setDescription("");
    getTemplatesList();
    setOpen(true);
    setEdit(false);
  };
  const handleClickEditOpen = (id) => {
    getTemplatesList();
    setEdit(true);
    setServiceId(id);
    getServiceDetail(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setAgreement([]);
  };

  //alert modal functions
  const handleClickOpenAlert = (id) => {
    setDeleteId(id);
    setOpenAlert(true);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
    setAgreement([]);
  };
  const validation = () => {
    if (validator.isEmpty(serviceName)) {
      setServiceNameError(true);
      setServiceNameErrorText("Service name is required");
    } else setServiceNameError(false);
    if (cost === "") {
      setCostError(true);
      setCostErrorText("Cost is required");
    } else setCostError(false);
    if (duration === "") {
      setDurationError(true);
      setDurationErrorText("Duration is required");
    } else if (duration % 0.5 !== 0) {
      setDurationError(true);
      setDurationErrorText("Duration should be multiple of 0.5");
    } else setDurationError(false);
    // if (templates.length === 0) {
    //   setTemplatesError(true);
    //   setTemplatesErrorText("Template is required");
    // } else setTemplatesError(false);
    // if (agreement.length === 0) {
    //   setAgreementsError(true);
    //   setAgreementsErrorText("Agreement is required");
    // } else setAgreementsError(false);
    // if (validator.isEmpty(description)) {
    //   setDescriptionError(true);
    //   setDescriptionErrorText("Description is required");
    // } else setDescriptionError(false);
    if (
      !validator.isEmpty(serviceName) &&
      cost !== "" &&
      duration !== "" &&
      duration % 0.5 === 0
      // && templates.length !== 0
      // &&
      // agreement.length !==0
      // &&
      // !validator.isEmpty(description)
    )
      edit ? editService() : addServices();
  };

  const handleCostChange = (e) => {
    const { formatted, calculation } = formatCost(e.target.value);
    setCost(formatted);
    setCalculationCost(calculation);
  };

  function onKeyPressed(evt) {
    if (evt.key === "e" || evt.key === "E") {
      evt.preventDefault();
    }
  }

  const HandleServiceName = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 200) {
      setServiceName(inputValue);
    } else {
      setServiceName(inputValue.slice(0, 200));
    }
  };

  const iconAdornment =
    isFocussed || cost !== ""
      ? {
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }
      : {};

  useEffect(() => {
    getServicesList();
    getAgreementList();
  }, [stateChanged]);

  const HandleTemplate = (value) => {
    if (
      value?.includes(value?.find((el) => el.is_texas === 1)) &&
      templates?.includes(templates?.find((el) => el.is_texas === 0))
    ) {
      return toast.error(
        "Texas Template cannot be combined with other templates"
      );
    } else if (
      value?.includes(value?.find((el) => el.is_texas === 0)) &&
      templates?.includes(templates?.find((el) => el.is_texas === 1))
    ) {
      return toast.error(
        "Texas Template cannot be combined with other templates"
      );
    } else setTemplates(value);
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className="page-background">
        <Preloader showPreloader={showLoader} />
        {/* -----------------add & edit service dialog box-------------------  */}
        <Dialog
          fullWidth={true}
          className="dialog-container"
          open={open}
          onClose={handleClose}
          sx={{ margin: "-13px" }}
        >
          <div className="close-wrapper">
            <CloseIcon onClick={handleClose} />
          </div>
          <DialogTitle>{edit ? "Edit Service" : "Add Service"}</DialogTitle>
          <DialogContent className="dialog-content">
            <Grid container className="input-controll">
              <Grid item xs={12} sm={12} md={6}>
                <FormControl variant="standard" className="forms-control">
                  <label className="input-form-label" htmlFor="serviceName">
                    Service Name
                  </label>
                  <TextField
                    id="serviceName"
                    className="input-textfield"
                    size="small"
                    variant="outlined"
                    placeholder="Service name"
                    onChange={HandleServiceName}
                    error={serviceNameError}
                    helperText={serviceNameError ? serviceNameErrorText : ""}
                    value={serviceName}
                    type="text"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={5} sm={6} md={3}>
                <FormControl variant="standard" className="forms-control">
                  <label className="input-form-label" htmlFor="cost">
                    Cost
                  </label>
                  <TextField
                    id="cost"
                    className="input-textfield"
                    size="small"
                    variant="outlined"
                    placeholder="Cost"
                    onKeyDown={onKeyPressed}
                    onFocus={(e) => setIsFocussed(true)}
                    onBlur={(e) => setIsFocussed(false)}
                    onChange={(e) => handleCostChange(e)}
                    // onChange={(e) => setCost(e.target.value)}
                    InputProps={{ iconAdornment }}
                    inputProps={{ maxLength: 6 }}
                    error={costError}
                    helperText={costError ? costErrorText : ""}
                    value={cost}
                    type="string"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={7} sm={6} md={3}>
                <FormControl
                  variant="standard"
                  className="forms-control field-reset spin-icon-input"
                >
                  <label className="input-form-label" htmlFor="duration">
                    Duration (HRS)
                    <BootstrapTooltip
                      title={
                        <h1 className="font-13">
                          Duration with 30 minutes intervel
                        </h1>
                      }
                    >
                      <InfoOutlinedIcon className="info-icon" />
                    </BootstrapTooltip>
                  </label>
                  <TextField
                    id="duration"
                    className="input-textfield "
                    size="small"
                    variant="outlined"
                    placeholder="Duration"
                    onKeyDown={onKeyPressed}
                    onChange={(e) => setDuration(e.target.value)}
                    value={duration}
                    inputProps={{
                      min: 0,
                      step: "0.5",
                      max: 9999,
                    }}
                    // onInput={(e) => {
                    //   e.target.value = Math.max(0, parseInt(e.target.value))
                    //     .toString()
                    //     .slice(0, 4);
                    // }}
                    error={durationError}
                    helperText={durationError ? durationErrorText : ""}
                    type="number"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl
                  variant="standard"
                  className="forms-control-service"
                >
                  <label
                    className="input-form-label-select pl-15"
                    htmlFor="Template"
                  >
                    Templates Associated With Service
                    <BootstrapTooltip
                      title={
                        <h1 className="font-13">
                          What templates do you typically use with this service?{" "}
                        </h1>
                      }
                    >
                      <InfoOutlinedIcon className="info-icon" />
                    </BootstrapTooltip>
                  </label>
                  <Autocomplete
                    className="forms-control custom-color"
                    multiple
                    size="small"
                    id="Template"
                    options={templateList}
                    getOptionLabel={(option) => option?.name}
                    value={templates}
                    onChange={(e, value) => HandleTemplate(value)}
                    filterSelectedOptions
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option?.is_pdf_template === 1 ? (
                          <PictureAsPdfOutlinedIcon
                            sx={{
                              marginRight: "6px",
                              color: "#3b3b3b",
                              opacity: "0.8",
                            }}
                          />
                        ) : null}
                        {option.name}
                      </Box>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          icon={
                            option?.is_pdf_template === 1 ? (
                              <PictureAsPdfOutlinedIcon
                                sx={{
                                  marginRight: "6px",
                                  color: "#3b3b3b",
                                  opacity: "0.8",
                                }}
                              />
                            ) : null
                          }
                          variant="outlined"
                          deleteIcon={<CloseIcon />}
                          label={option?.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // onChange={(e) => setServices(e.target.value)}
                        placeholder="Select"
                        error={templatesError}
                        helperText={templatesError ? templatesErrorText : ""}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl
                  variant="standard"
                  className="forms-control-service"
                >
                  <label
                    className="input-form-label-select pl-15"
                    htmlFor="Agreements"
                  >
                    Agreements Associated With Service
                    <BootstrapTooltip
                      title={
                        <h1 className="font-13">
                          What agreements do you typically use with this
                          service?{" "}
                        </h1>
                      }
                    >
                      <InfoOutlinedIcon className="info-icon" />
                    </BootstrapTooltip>
                  </label>
                  <Autocomplete
                    className="forms-control custom-color"
                    multiple
                    size="small"
                    id="Agreements"
                    options={agreement.length === 1 ? [] : agreementList}
                    getOptionLabel={(option) => option.name}
                    value={agreement?.map((x) => x) || {}}
                    onChange={(e, value) => setAgreement(value)}
                    filterSelectedOptions
                    renderTags={(value, getTagProps) =>
                      value?.map((option, index) => (
                        <Chip
                          variant="outlined"
                          deleteIcon={<CloseIcon />}
                          label={option?.name}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // onChange={(e) => setServices(e.target.value)}
                        placeholder="Select"
                        error={agreementsError}
                        helperText={agreementsError ? agreementsErrorText : ""}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl
                  variant="standard"
                  className="forms-control field-reset"
                >
                  <label className="input-form-label" htmlFor="description">
                    Description
                  </label>
                  <TextareaAutosize
                    className="textArea"
                    id="description"
                    aria-label="minimum height"
                    minRows={4}
                    placeholder="Description"
                    style={{ width: "100%", resize: "none" }}
                    onChange={(e) => setDescription(e.target.value)}
                    // error={descriptionError}
                    // helperText={descriptionError ? descriptionErrorText : ""}
                    value={description}
                    InputProps={{
                      inputComponent: TextareaAutosize,
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className="dialog-action">
            <Button className="back-btn" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              className="default-btn"
              sx={{ ":hover": { backgroundColor: "#1E8970 !important" } }}
              onClick={() => validation()}
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
        {/* --------------------delete service dialog box----------------------- */}
        <Dialog
          open={openAlert}
          onClose={handleCloseAlert}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="delete-alert-modal"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete service?"}
            <CloseIcon className="close-icon" onClick={handleCloseAlert} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              className="delete-modal-cancel-btn"
              sx={{ ":hover": { backgroundColor: "transparent" } }}
              onClick={handleCloseAlert}
            >
              Cancel
            </Button>
            <Button
              className="default-btn"
              variant="contained"
              color="error"
              onClick={() => {
                deleteService(deleteId);
              }}
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* ------------------Services content--------------------- */}
        {showLoader ? (
          ""
        ) : (
          <Box
            sx={{
              width: "100%",
              px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },
            }}
            className="teams-container"
          >
            <Grid item xs={12} className="teams-title-wrapper">
              <div>
                <h3 className="teams-title">Services</h3>
                <p className="teams-subtitle">
                  All services your company offers.{" "}
                </p>
              </div>

              {permission && (
                <Button
                  className={
                    !permission
                      ? "disabled-permision-btn"
                      : "inspections-new-button"
                  }
                  sx={{ textTransform: "none" }}
                  onClick={handleClickOpen}
                  id="add_new_service"
                  name="add_new_service"
                >
                  Add new &nbsp;&nbsp;+{" "}
                </Button>
              )}
            </Grid>

            <Paper
              sx={{ width: "100%", sm: 8, lg: 2, mt: "18px" }}
              className="teams-table-wrapper"
            >
              <TableContainer
                sx={{ overflowX: { sm: "auto", lg: "unset", md: "scroll" } }}
                className={isMobile ? "mobileScroller" : ""}
              >
                <Table
                  aria-labelledby="tableTitle"
                  size={"small"}
                  style={{
                    transform: isMobile && "rotate(180deg)",
                    overflowX: "unset",
                    width: "100",
                  }}
                  className="teams-table-main"
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    //   onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={table_data.length}
                    permission={permission}
                  />
                  {table_data.length > 0 ? (
                    <tbody>
                      {/* if you don't need to support IE11, you can replace the `stableSort` call with: 
                    rows.slice().sort(getComparator(order, orderBy)) */}
                      {stableSort(table_data, getComparator(order, orderBy))
                        //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.name);
                          const labelId = `enhanced-table-checkbox-${index}`;
                          let { formatted } = formatCost(row.amount);
                          return (
                            <tr
                              onClick={(event) => handleClick(event, row.name)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={`${row.name}_${index}`}
                              className="teams-data-table"
                            >
                              <td
                                className="first-name"
                                component="th"
                                id={labelId}
                                padding="none"
                              >
                                <span>{row.name}</span>
                              </td>
                              <td className="insp-status" align="left">
                                <span>{row.description}</span>
                              </td>

                              <td align="left" className="teams-email-data">
                                <span>${formatted}</span>
                              </td>

                              <td align="left">
                                <span className="phone-data">
                                  {row.duration} HRs
                                </span>
                              </td>

                              {permission && row.is_default === 0 ? (
                                <td align="right">
                                  <span className="table-actions">
                                    <span>
                                      <BootstrapTooltip
                                        title={
                                          <h1 className="font-13">
                                            Edit details
                                          </h1>
                                        }
                                      >
                                        <EditIcon
                                          className="edit-icon"
                                          id={`edit_service_${index}`}
                                          onClick={() =>
                                            handleClickEditOpen(row.service_id)
                                          }
                                        />
                                      </BootstrapTooltip>
                                      <BootstrapTooltip
                                        title={
                                          <h1 className="font-13">
                                            Delete record
                                          </h1>
                                        }
                                      >
                                        <DeleteIcon
                                          className="delete-icon"
                                          sx={{
                                            fontSize: 18,
                                          }}
                                          id={`delete_service_${index}`}
                                          onClick={() =>
                                            handleClickOpenAlert(row.service_id)
                                          }
                                        />
                                      </BootstrapTooltip>
                                    </span>
                                  </span>
                                </td>
                              ) : (
                                <td align="right">
                                  <span className="table-actions">
                                    <span>
                                      {/* <BootstrapTooltip title={<h1 className="font-13">You cannot edit this record</h1>}>
                                        <EditIcon sx={{ opacity: "0.25" }} id={`edit_service_${index}`} />
                                      </BootstrapTooltip> */}
                                      <BootstrapTooltip
                                        title={
                                          <h1 className="font-13">
                                            Edit details
                                          </h1>
                                        }
                                      >
                                        <EditIcon
                                          className="edit-icon"
                                          id={`edit_service_${index}`}
                                          onClick={() =>
                                            handleClickEditOpen(row.service_id)
                                          }
                                        />
                                      </BootstrapTooltip>
                                      <BootstrapTooltip
                                        title={
                                          <h1 className="font-13">
                                            You cannot delete this record
                                          </h1>
                                        }
                                      >
                                        <DeleteIcon
                                          sx={{
                                            color: "red",
                                            fontSize: 18,
                                            opacity: "0.25",
                                          }}
                                          id={`delete_service_${index}`}
                                        />
                                      </BootstrapTooltip>
                                    </span>
                                  </span>
                                </td>
                              )}
                            </tr>
                          );
                        })}
                      {emptyRows > 0 && (
                        <tr
                          style={{
                            height: 33 * emptyRows,
                          }}
                        >
                          <td colSpan={6} />
                        </tr>
                      )}
                    </tbody>
                  ) : (
                    "No data found"
                  )}
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        )}
      </Box>
    </>
  );
};

export default ServicesListView;