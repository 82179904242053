import useMediaQuery from '@mui/material/useMediaQuery';
import ActionButton from './Elements/ActionButton';
import { useState } from 'react';
import PtDiscardDraftModal from './Modals/PtDiscardDraftModal';
import { useLocation } from 'react-router-dom';
import moment from "moment";
import { isMergeIdNull } from './PtTemplateHelperFunctions';
import { Button, Typography } from '@mui/material';
import PtPublishTemplateModal from './Modals/PtPublishTemplateModal';

const PdfFormFillActions = ({setTemplateState,templateState,updatePtTempateData}) => {
  const [openDraftModal,setOpenDraftModal] = useState(false);
  const [openPublishTemplateModal,setOpenPublishTemplateModal] = useState(false);
  const isMobile = useMediaQuery('(max-width: 900px)');
  const handleCloseDraftModal=()=>setOpenDraftModal(false);
  const handleClosePublishModal=()=>setOpenPublishTemplateModal(false);
  const location = useLocation();
  const discardDraft=()=>{
    setOpenDraftModal(true)
  }
  const publishPtChangesmodal=()=>{
    setOpenPublishTemplateModal(true)
  }
  const savePtTeChanges=()=>updatePtTempateData(templateState?.templateData?.data?.template,"loader") 

  const publishPtChanges = () =>{
  //   const isMergeIdNullInDataObj = isMergeIdNull(templateState?.templateData?.data)
  //   if(isMergeIdNullInDataObj) setTemplateState(prev=>({...prev,allMergeIdFilled:false}))
  //   else{
  //   let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
  //   prevData.data.template.is_published = 1;
  //   setTemplateState(prev=>({...prev,templateData:prevData,allMergeIdFilled:true}))
  //   updatePtTempateData(prevData.data.template,"loader","publish")
  // }   
  let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
  prevData.data.template.is_published = 1;
  setTemplateState(prev=>({...prev,templateData:prevData,allMergeIdFilled:true}))
  updatePtTempateData(prevData.data.template,"loader","publish")
  }

  const publishToTestCompany = () =>{
  let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
  prevData.data.template.publish_to_preview = 1;
  setTemplateState(prev=>({...prev,templateData:prevData,allMergeIdFilled:true}))
  updatePtTempateData(prevData.data.template,"loader","publish")
  }
  return (
    <>
    <PtDiscardDraftModal openDraftModal={openDraftModal} handleCloseDraftModal={handleCloseDraftModal} selectedTempGuid={location?.state?.guid} isPdfTemplate={location?.state?.isPdfTemplate} templateName={location?.state?.name}/>
    <PtPublishTemplateModal openPublishTemplateModal={openPublishTemplateModal} handleClosePublishModal={handleClosePublishModal} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData}/>
    <div className={`pt_publish_action ${!isMobile?'pt__sticky':'pt__normal_actions_container'}`} >
      <div className='pt_status_section'>
        <div>
          <span className='pt_status_title'>Status:</span><span className={`pt_status_content ${templateState?.templateData?.data?.template?.is_published === 1?'pt_published':'pt_unpublished'}`}>{templateState?.templateData?.data?.template?.is_published === 1 ? "Published":"unpublished changes"}</span>
          <p>This template contains unpublished changes. Until changes are published, they will not appear in new inspections. </p>
        </div>
      </div>
      <div className='pt_status_action_section'>
      {/* <ActionButton id="pt_te_saveandpublishbutton" btnType="cta_normal" text="Discard draft" action={PublishTemplate}/> */}
      <ActionButton id="pt_te_saveandpublishbutton" btnType="pt_cta_normal" text="Discard draft" action={discardDraft} disabled={templateState.roleId !== 2 && templateState.roleId !== 3}/>
      <ActionButton id="pt_te_saveandpublishbutton" btnType="pt_cta_normal" text="Save draft" action={savePtTeChanges} disabled={templateState.roleId !== 2 && templateState.roleId !== 3}/>
      {/* <ActionButton id="pt_te_saveandpublishbutton" btnType="pt_cta_filled" text="Save and publish to library" disabled={templateState.roleId !== 2 && templateState.roleId !== 3} action={publishPtChanges} /> */}
      <ActionButton id="pt_te_saveandpublishbutton" btnType="pt_cta_filled" text="Save and publish" disabled={templateState.roleId !== 2 && templateState.roleId !== 3} action={publishPtChangesmodal} />
      {/* {!templateState?.allMergeIdFilled && <p style={{color:'red',textAlign:'center'}}>merge ID's are missing</p>} */}
      {(templateState.roleId === 2 || templateState.roleId === 3) &&<Button variant="text" className="pt_cta_filled_blue" disableRipple onClick={publishToTestCompany} >
              <Typography>Publish to test companies</Typography>
      </Button>}
      {/* <ActionButton id="pt_te_saveandpublishbutton" btnType="pt_cta_filled_blue" text=" Publish to test companies" disabled={templateState.roleId !== 2 && templateState.roleId !== 3} action={publishToTestCompany} /> */}
      </div>
      <div className='pt_status_notify_section'>
       <p>Last autosave: {moment(templateState?.templateData?.data?.template?.updated_at).calendar()}</p>
       <p>Changes to these settings only affect future reports.</p>
      </div>
      
    </div>
    </>
  )
}

export default PdfFormFillActions