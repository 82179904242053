//File import
import moment from "moment";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { Bar } from "react-chartjs-2";
import { visuallyHidden } from "@mui/utils";
import { apiCall } from "../../utils/action";
import Preloader from "../../helpers/Preloader";
import Header from "../../common/header/Header";
import { useMediaQuery } from "react-responsive";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect, useRef } from "react";
import Breadcrumb from "../../common/header/Breadcrumb";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { GetCurrentResolution } from "../../utils/services";
import Sortswitch from "../../../assets/images/Sortswitch.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Filler, BarElement, Title, Tooltip, Legend, ArcElement } from "chart.js";
import { Grid, Paper, TableRow, Alert, TableContainer, TablePagination, Table, TableHead, TableSortLabel, TextField, FormControl, Typography, Modal } from "@mui/material";
//Mertics graph register
ChartJS.register(CategoryScale, LinearScale, BarElement, Filler, LineElement, PointElement, Title, Tooltip, Legend, ArcElement);
//descending comparator
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
//table row heading
const tableHead = [
  { id: "created_at", numeric: false, disablePadding: true, label: "#" },
  { id: "description", numeric: false, disablePadding: true, label: "ADDRESS" },
  { id: "amount", numeric: false, disablePadding: true, label: "DATE/TIME" },
  { id: "card_used", numeric: false, disablePadding: true, label: "AGENT" },
  { id: "client", numeric: false, disablePadding: false, label: "CLIENT" },
  { id: "insp", numeric: false, disablePadding: false, label: "INSPECTOR" },
  { id: "fee", numeric: false, disablePadding: false, label: "FEE" },
  { id: "amount", numeric: false, disablePadding: false, label: "AMOUNT RECEIVED" },
];
// acsc / desc function
function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
//sort function
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
//map funtion of table heading
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {tableHead.map((headCell) => (
          <td key={headCell.id} align={"left"} padding={headCell.disablePadding ? "none" : "normal"} className="activity-table-head">
            <TableSortLabel IconComponent={headCell.id === "action" || headCell.id === "Actions" ? "" : () => <img alt="icon" src={Sortswitch} style={{ marginRight: "6px" }} className="sort-switch" />} active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)} sx={{ marginTop: GetCurrentResolution() === "mobile" && "21px", marginBottom: "10.5px", flexDirection: "row-reverse", fontSize: "12px" }}>
              {headCell.label} {/* table heading label */}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </td>
        ))}
      </TableRow>
    </TableHead>
  );
}
//prop types
EnhancedTableHead.propTypes = {
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
};
function Metrics() {
  const labels = [];
  const topAgentCount = [];
  const topAgentLable = [];
  const chartRef = useRef();
  const topAgentRevenue = [];
  const averageRevenueData = [];
  const inspectionActivity = [];
  const totalInspectionData = [];
  const topAgentRevenueLabel = [];
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [table_data, setTableData] = useState([]);
  const [inspection, setInspection] = useState([]);
  const [toDate, setToDate] = useState(new Date());
  const [inspActivity, setInspActivity] = useState();
  const [agentRevenue, setAgentRevenue] = useState();
  const [orderBy, setOrderBy] = useState("Calories");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showLoader, setShowLoader] = useState(false);
  const [agentCountWise, setAgentCountWise] = useState();
  const handleToDate = (newValue) => setToDate(newValue);
  const handleFromDate = (newValue) => setFromDate(newValue);
  const [revenueGraphData, setRevenueGraphData] = useState();
  const [amountReceivable, setAmountReceivable] = useState(0);
  const [defaultFromDate] = useState(new Date().setMonth(0, 0));
  const [defaultToDate] = useState(new Date().setMonth(11, 31));
  const [fromDate, setFromDate] = useState(new Date().setMonth(0, 1));
  const [totalRevenueGraphData, setTotalRevenueGraphData] = useState();
  const [feesData, setFeesData] = useState({ OwedAmount: 0, PaidAmount: 0 });
  const handleClose = () => {
    setPage(0);
    setOpen(false);
    setTableData([]);
  };
  const openModal = (evt, element) => {
    if (element.length > 0) {
      var index = element[0].index;
      // console.log(totalRevenueGraphData[index].inspection_ids);
      const formdata = {
        role_id: sessionStorage.getItem("role_id"),
        user_id: sessionStorage.getItem("user_id"),
        inspection_ids: totalRevenueGraphData[index].inspection_ids,
        company_id: sessionStorage.getItem("company_id"),
      };
      apiCall(
        (response) => {
          const { success, data, message } = response.data;
          if (success) {
            setInspection(data);
            setTableData(data);
          } else {
            toast.error(message);
          }
        },
        formdata,
        "GET_METRIC_DATA"
      );
      setOpen(true);
      // you can also get dataset of your selected element
    }
  };
  const openModalRev = (evt, element) => {
    if (element.length > 0) {
      var index = element[0].index;
      // console.log(revenueGraphData[index].inspection_ids);
      const formdata = {
        role_id: sessionStorage.getItem("role_id"),
        user_id: sessionStorage.getItem("user_id"),
        inspection_ids: revenueGraphData[index].inspection_ids,
        company_id: sessionStorage.getItem("company_id"),
      };
      apiCall(
        (response) => {
          const { success, data, message } = response.data;
          if (success) {
            // console.log(data);
            setInspection(data);
            setTableData(data);
          } else {
            toast.error(message);
          }
        },
        formdata,
        "GET_METRIC_DATA"
      );
      setOpen(true);
      // you can also get dataset of your selected element
    }
  };
  const openModalEvent = (evt, element) => {
    if (element.length > 0) {
      var index = element[0].index;
      // console.log(inspActivity[index].inspection_ids);
      const formdata = {
        role_id: sessionStorage.getItem("role_id"),
        user_id: sessionStorage.getItem("user_id"),
        inspection_ids: inspActivity[index].inspection_ids,
        company_id: sessionStorage.getItem("company_id"),
      };
      apiCall(
        (response) => {
          const { success, data, message } = response.data;
          if (success) {
            // console.log(data);
            setInspection(data);
            setTableData(data);
          } else {
            toast.error(message);
          }
        },
        formdata,
        "GET_METRIC_DATA"
      );
      setOpen(true);
      // you can also get dataset of your selected element
    }
  };

  // Month Init
  const onChangeDate = () => {
    setShowLoader(true);
    if (new Date(fromDate).getTime() >= new Date(toDate).getTime()) {
      setShowLoader(false);
      return toast.error("Invalid time stamp");
    }
    const formdata = {
      role_id: sessionStorage.getItem("role_id"),
      user_id: sessionStorage.getItem("user_id"),
      to_date: moment(toDate).format("YYYY-MM-DD"),
      company_id: sessionStorage.getItem("company_id"),
      from_date: moment(fromDate).format("YYYY-MM-DD"),
    };
    apiCall(
      (response) => {
        const { success, data, message } = response.data;
        if (success) {
          // console.log(data);
          setShowLoader(false);
          setInspActivity(data.inspectionGraphData);
          setTotalRevenueGraphData(data.revenueGraphData);
          setRevenueGraphData(data.averagerevenueGraphData);
          setAgentRevenue(data.top_five_agents_revenue_wise);
          setAgentCountWise(data.top_five_agents_inspection_count_wise);
          setFeesData({ OwedAmount: data.owed_amount, PaidAmount: data.paid_amount });
          setAmountReceivable(!data.yearly_revenue_amount ? 0 : data.yearly_revenue_amount);
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      formdata,
      "GET_GRAPH_DATA"
    );
  };
  const GetGraphData = () => {
    setShowLoader(true);
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
    };
    apiCall(
      (response) => {
        const { success, data, message } = response.data;
        if (success) {
          setShowLoader(false);
          setInspActivity(data.inspectionGraphData);
          setTotalRevenueGraphData(data.revenueGraphData);
          setRevenueGraphData(data.averagerevenueGraphData);
          setAgentRevenue(data.top_five_agents_revenue_wise);
          setAgentCountWise(data.top_five_agents_inspection_count_wise);
          setFeesData({ OwedAmount: data.owed_amount, PaidAmount: data.paid_amount });
          setAmountReceivable(!data.yearly_revenue_amount ? 0 : data.yearly_revenue_amount);
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      formdata,
      "GET_GRAPH_DATA"
    );
  };
  //init
  useEffect(() => {
    GetGraphData();
  }, []);

  //months label init
  revenueGraphData?.forEach((value) => {
    labels.push(value?.month_name);
  });

  //Bar Graph
  //data init
  totalRevenueGraphData?.forEach((value) => {
    totalInspectionData.push(value?.revenue);
  });
  revenueGraphData?.forEach((value) => {
    averageRevenueData.push(value?.revenue);
  });
  inspActivity?.forEach((value) => {
    inspectionActivity.push(value?.inspection_count);
  });
  agentCountWise?.forEach((value) => {
    topAgentCount?.push(value?.total);
  });
  agentCountWise?.forEach((value) => {
    topAgentLable?.push(`${value?.agent?.first_name} ${value?.agent?.last_name ? value?.agent?.last_name : ""}`);
  });
  agentRevenue?.forEach((value) => {
    topAgentRevenue?.push(value?.revenue);
  });
  agentRevenue?.forEach((value) => {
    topAgentRevenueLabel?.push(`${value?.agent?.first_name} ${value?.agent?.last_name ? value?.agent?.last_name : ""}`);
  });
  const totalInspGraphOption = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: false, text: "Total inspection revenue per month (in dollars)" },
    },
    onClick: openModal,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      yAxes: {
        ticks: {
          callback: function (value, index, values) {
            return "$" + value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
      },
    },
  };
  const totalInspGraphData = {
    labels,
    datasets: [{ label: "Total revenue", data: totalInspectionData, borderColor: "rgb(53, 162, 235)", backgroundColor: "#2bab8c" }],
  };

  //Bar Graph

  const InspActivityGraphOption = {
    responsive: true,
    onClick: openModalEvent,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: { legend: { display: false }, title: { display: false, text: "Inspection events per month" } },
  };
  const InspActivityGraphData = {
    labels,
    datasets: [{ label: "Total activity", data: inspectionActivity, borderColor: "rgb(53, 162, 235)", backgroundColor: "rgba(53, 162, 235, 0.5)" }],
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };
  //Line Graph
  //data init
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table_data.map((n) => n.triggered_by);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  //Total Inspection Line Graph
  const AverageRevenueGraphOption = {
    responsive: true,
    onClick: openModalRev,
    autoSkip: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          min: 0,
          max: 100,
          // forces step size to be 5 units
          stepSize: 50, // <----- This prop sets the stepSize
        },
      },
    },

    plugins: { legend: { display: false }, title: { display: false, text: "Average inspection revenue per month (in dollars)" } },
  };
  const AverageRevenueGraphData = {
    labels,
    datasets: [{ label: "Average revenue", data: averageRevenueData, backgroundColor: "#2bab8c", borderColor: "#2bab8c" }],
  };
  const Feesdata = {
    labels: ["Owed Amount", "Paid Amount"],
    datasets: [
      {
        label: "# of Votes",
        data: [feesData.OwedAmount, feesData.PaidAmount],
        backgroundColor: ["rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)"],
        borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
        borderWidth: 1,
      },
    ],
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //Bar Graph
  const topAgentGraphOption = {
    indexAxis: "y",
    responsive: true,
    Legend: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: { legend: { display: false }, title: { display: false, text: "Top agents by inspections" } },
  };
  const topAgentRevenueGraphOption = {
    indexAxis: "y",
    responsive: true,
    Legend: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value, index, values) {
            return "$" + value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
      },
    },
    plugins: { legend: { display: false }, title: { display: false, text: "Top agents by revenue" } },
  };
  const topAgentGraphData = {
    labels: topAgentLable,
    datasets: [
      { label: "By inspections", data: topAgentCount, borderColor: "rgb(53, 162, 235)", backgroundColor: "rgba(53, 162, 235, 0.5)" },
      // { label: "By inspection count", data: topAgentRevenue, borderColor: "rgb(53, 162, 235)", backgroundColor: "#2bab8c" },
    ],
  };
  const topAgentRevenueGraphData = {
    labels: topAgentRevenueLabel,
    datasets: [{ label: "By revenue", data: topAgentRevenue, borderColor: "rgb(53, 162, 235)", backgroundColor: "#2bab8c" }],
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - table_data.length) : 0;
  //is mobile trigger
  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });
  //render
  return (
    <Box sx={{ flexGrow: 1 }} className="page-background">
      <Header />
      <Breadcrumb />
      {showLoader ? <Preloader showPreloader={true}></Preloader> : ""}
      <Box className="inspections-main" sx={{ width: "100%", px: { xs: "0", sm: "20px", md: "80px", lg: "80px" } }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <div className="inspection-header">
              <Grid sx={{ width: "50%" }}>
                <h3 className="inspection-title" style={{ marginLeft: "-10px", marginBottom: "-10px" }}>
                  Metrics
                </h3>
                {/* <p className="inspections-subheader"> (Under construction)</p> */}
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12} lg={4}>
            {/* {console.log(totalInspectionData)} */}
            <Paper elevation={0} sx={{ p: 2 }}>
              <Grid container>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Grid xs={12} sx={{ mb: 1 }}>
                    <h1 style={{ fontSize: "14px", marginBottom: "5px" }}>Filter by month</h1>
                  </Grid>
                  <Grid item xs={5.7} sm={5.7} md={5.7} lg={5.7}>
                    <MobileDatePicker
                      value={fromDate}
                      onAccept={onChangeDate}
                      inputFormat="MM/dd/yyyy"
                      onChange={handleFromDate}
                      shouldDisableDate={(date) => date < defaultFromDate}
                      shouldDisableMonth={(date) => date < defaultFromDate}
                      shouldDisableYear={(date) => date < defaultFromDate}
                      renderInput={(params) => (
                        <FormControl variant="standard" className="forms-control">
                          <label className="input-form-label" id="demo-simple-select-label">
                            <CalendarMonthIcon className="calendar_start_icon" /> FROM
                          </label>
                          <TextField {...params} size="small" />
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item xs={0.6} sm={0.6} md={0.6} lg={0.6} className="calender_icon_center">
                    <ArrowRightAltIcon className="calendar_allright_icon" sx={{ mr: 0.5 }} />
                  </Grid>
                  <Grid item xs={5.7} sm={5.7} md={5.7} lg={5.7}>
                    <MobileDatePicker
                      value={toDate}
                      inputFormat="MM/dd/yyyy"
                      onChange={handleToDate}
                      onAccept={onChangeDate}
                      shouldDisableDate={(date) => date > defaultToDate}
                      shouldDisableMonth={(date) => date > defaultToDate}
                      shouldDisableYear={(date) => date > defaultToDate}
                      renderInput={(params) => (
                        <FormControl variant="standard" className="forms-control">
                          <label className="input-form-label" id="demo-simple-select-label">
                            <CalendarMonthIcon className="calendar_start_icon" /> TO
                          </label>
                          <TextField {...params} size="small" />
                        </FormControl>
                      )}
                    />
                  </Grid>
                </LocalizationProvider>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Paper elevation={0} sx={{ p: 2, height: "100%" }}>
              <h1 style={{ fontSize: "14px", marginBottom: "5px" }}>Account receivable</h1>
              <h3 style={{ fontSize: "30px", marginBottom: "5px", color: "#3d454d" }} className="dashboard__summary__box__total">
                ${amountReceivable?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </h3>
            </Paper>
          </Grid>
          {/* <Grid item xs={12} md={4}>
            <Paper elevation={0} sx={{ p: 2, height: "100%" }}>
              <h1 style={{ fontSize: "14px", marginBottom: "5px" }} className="inspections-subheader">
                Amount (in dollars)
              </h1>
              <Doughnut data={Feesdata} options={{ plugins: { legend: { display: false }, title: { display: false, text: "Amount (in dollars)" } } }} />
            </Paper>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Paper elevation={0} sx={{ p: 2, textAlign: "center" }}>
              <h1 style={{ fontSize: "14px", marginBottom: "5px" }}>Total inspection revenue per month (in dollars)</h1>
              <Bar ref={chartRef} options={totalInspGraphOption} data={totalInspGraphData} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={0} sx={{ p: 2, textAlign: "center" }}>
              <h1 style={{ fontSize: "14px", marginBottom: "5px" }}>Inspections per month</h1>
              <Bar ref={chartRef} options={InspActivityGraphOption} data={InspActivityGraphData} />
            </Paper>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Paper elevation={0} sx={{ p: 2 }}>
              <h1 style={{ fontSize: "14px", marginBottom: "5px" }} className="inspections-subheader">
                Average inspection revenue per month (in dollars)
              </h1>
              <Bar ref={chartRef} options={AverageRevenueGraphOption} data={AverageRevenueGraphData} />
            </Paper>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Paper elevation={0} sx={{ p: 2, textAlign: "center" }}>
              <h1 style={{ fontSize: "14px", marginBottom: "5px" }}>Top agents by inspections</h1>
              <Bar ref={chartRef} options={topAgentGraphOption} data={topAgentGraphData} />
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={0} sx={{ p: 2, textAlign: "center" }}>
              <h1 style={{ fontSize: "14px", marginBottom: "5px" }}>Top agents by revenue</h1>
              <Bar ref={chartRef} options={topAgentRevenueGraphOption} data={topAgentRevenueGraphData} />
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Modal open={open} onClose={handleClose} className={"inspection-filter-modal-main"}>
        <Box className={"calendar_edit_modal"}>
          <Grid sx={{ p: { xs: "20px", md: "30px", lg: "30px" } }} container spacing={2}>
            <Grid item xs={8}>
              <Typography sx={{ height: "30px", width: "350px", fontSize: "22px", letterSpacing: 0, color: "#333333", lineHeight: "30px", fontFamily: "Open Sans" }}>Inspections</Typography>
            </Grid>

            <Grid item xs={4}>
              <CloseIcon onClick={handleClose} className={"inspection-filter-modal-close-icon"} />
              <ExitToAppIcon sx={{ float: "right", color: "#71A1E9", fontSize: "24px" }} />
            </Grid>
            <Grid item xs={12}>
              {/* {inspection.map((value) => {
                return (
                  <h1>
                    {value.location_address}
                    {value.location_city}
                    {value.location_state}
                  </h1>
                );
              })} */}
              <>
                {showLoader ? (
                  <Preloader showPreloader={true} />
                ) : (
                  <Box className="inspections-main" sx={{ width: "100%", padding: "0px !important" }}>
                    {table_data.length !== 0 ? (
                      <>
                        <Paper sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 0, border: "1px solid #E7E7E9" }} className={"inspection-table-main"} elevation={0}>
                          <TableContainer className={isMobile && "mobileScroller"} sx={{ overflowX: { sm: "auto", lg: "unset", md: "scroll" } }}>
                            <Table aria-labelledby="tableTitle" size={"small"} style={{ transform: isMobile && "rotate(180deg)", overflowX: "scroll", width: "100" }}>
                              <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={table_data.length} />
                              <tbody>
                                {/* if you don't need to support IE11, you can replace the `stableSort` call with: rows.slice().sort(getComparator(order, orderBy)) */}
                                {stableSort(table_data, getComparator(order, orderBy))
                                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                  .map((row, index) => {
                                    const isItemSelected = isSelected(row.name);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    const address = `${row.location_address}, ${row.location_city}, ${row.location_state}, ${row.location_zip}`;
                                    return (
                                      <tr onClick={(event) => handleClick(event, row.name)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.name} className="inspections-data-table">
                                        <td align="left" style={{ paddingLeft: "35px", paddingRight: "0px", paddingTop: "10px", paddingBottom: "10px" }} id={labelId}>
                                          <div style={{ textAlign: "left" }}>
                                            <span className="date-placeholder">{index + 1}</span>
                                          </div>
                                        </td>
                                        <td align="left" style={{ paddingLeft: "15px", paddingRight: "0px", paddingTop: "10px", paddingBottom: "10px", overflow: "hidden" }}>
                                          <span className="agent-row-data-font">
                                            <span style={{ fontWeight: "normal", fontSize: "14px" }}>{address?.length > 25 ? `${address?.substring(10, 0)}...` : address}</span>
                                          </span>
                                        </td>
                                        <td align="left" style={{ paddingLeft: "15px", paddingRight: "10px", paddingTop: "10px", paddingBottom: "10px" }} id={labelId}>
                                          <div style={{ textAlign: "left" }}>
                                            <span className="date-placeholder">
                                              {moment(row.created_at).format("M/DD/YY")}
                                              <br />
                                              {moment(row.created_at).format("LT")}
                                            </span>
                                          </div>
                                        </td>
                                        <td align="left" style={{ paddingLeft: "15px", paddingRight: "0px", paddingTop: "10px", paddingBottom: "10px" }} id={labelId}>
                                          <div style={{ textAlign: "left" }}>
                                            {/* <span className="date-placeholder">{row.card_used}</span> */}
                                            {row.orderagent?.map((value) => {
                                              return (
                                                <span className="date-placeholder">
                                                  {value?.first_name} {value?.last_name}
                                                </span>
                                              );
                                            })}
                                          </div>
                                        </td>
                                        <td align="left" style={{ paddingLeft: "4px", paddingRight: "0px", paddingTop: "10px", paddingBottom: "10px" }} id={labelId}>
                                          {/* <div style={{ textAlign: "left" }}>
                                            <Button className="billing-history-download-button" startIcon={<DownloadIcon className="billing-history-download-button-icon" />} onClick={() => window.open(row.public_url)}>
                                              Download
                                            </Button>
                                          </div> */}
                                          {row.orderclient?.map((value) => {
                                            return (
                                              <span className="date-placeholder">
                                                {value?.first_name} {value?.last_name}
                                              </span>
                                            );
                                          })}
                                        </td>
                                        <td align="left" style={{ paddingLeft: "4px", paddingRight: "0px", paddingTop: "10px", paddingBottom: "10px" }} id={labelId}>
                                          {/* <div style={{ textAlign: "left" }}>
                                            <Button className="billing-history-download-button" startIcon={<DownloadIcon className="billing-history-download-button-icon" />} onClick={() => window.open(row.public_url)}>
                                              Download
                                            </Button>
                                          </div> */}
                                          {row.orderinspector?.map((value) => {
                                            return (
                                              <span className="date-placeholder">
                                                {value?.first_name} {value?.last_name}
                                              </span>
                                            );
                                          })}
                                        </td>
                                        <td align="left" style={{ paddingLeft: "4px", paddingRight: "0px", paddingTop: "10px", paddingBottom: "10px" }} id={labelId}>
                                          <div style={{ textAlign: "center" }}>
                                            <span>${row.fee}</span>
                                          </div>
                                        </td>
                                        <td align="left" style={{ paddingLeft: "4px", paddingRight: "0px", paddingTop: "10px", paddingBottom: "10px" }} id={labelId}>
                                          <div style={{ textAlign: "center" }}>
                                            <span>${row.total_payment_received}</span>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                {emptyRows > 0 && (
                                  <tr style={{ height: 33 * emptyRows }}>
                                    <td colSpan={6} />
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </TableContainer>
                          {/* table pagination component***************************************************** */}
                          <br />
                          <TablePagination rowsPerPageOptions={[10, 20]} component="div" count={table_data.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
                        </Paper>
                      </>
                    ) : (
                      <Paper sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 5 }} elevation={0} className={"inspection-table-main"}>
                        <Alert severity="info">Loading</Alert>
                      </Paper>
                    )}
                  </Box>
                )}
              </>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
}

export default Metrics;
