import React from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Typography from "@mui/material/Typography";
import TableLayout from "../../../common/table/TableLayout";

const UpcommingSection = (props) => {
  const { upcommingCount, navigate, isMobile } = props;
  return (
    <Paper sx={{mt : isMobile && '48px !important'}} className="route">
      <Box sx={{ mb: "38px" }} className="route__header">
        <Typography className="route__header__title">
          Upcoming Inspections
        </Typography>
        <div
          id="dashboard-seeallinspection"
          onClick={() => navigate(`/inspections`)}
          className="route__header__link"
        >
          <OpenInNewIcon sx={{ color: "#4282e2", width: "16px", mr: "12px" }} />
          <span>See all inspections</span>
        </div>
      </Box>
      <TableLayout InspectionList={upcommingCount} type={"dashboard"} />
    </Paper>
  );
};

export default UpcommingSection;
