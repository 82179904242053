import { API_CONSTANTS, API_METHODS } from '../../config/api-constants';
import { fetchCall } from '../../utils/ajax';

export const GetInspectionList = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_INSPECTION_LIST}${payload.query}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload.local,
    );
  };

  export const GetFilteredInspections = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_FILTERED_INSPECTIONS}${payload.query}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload.local
    );
  };

  export const GetSearchedInspections = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_INSPECTIONS_SEARCH_DATA}${payload.query}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload.local
    );
  };