/* eslint-disable */
import moment from "moment";
import * as XLSX from "xlsx";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import * as FileSaver from "file-saver";
import { visuallyHidden } from "@mui/utils";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Preloader from "../../helpers/Preloader";
import { useMediaQuery } from "react-responsive";
import { apiCall } from "../../utils/action";
import EditTemplateList from "./EditTemplateList";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../../assets/styles/containers/_agent.scss";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import AlertImage from "../../../assets/images/alert.png";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { GetCurrentResolution } from "../../utils/services";
import Sortswitch from "../../../assets/images/Sortswitch.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import config_settings from "../../config/config";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { EditTemplateReq, GetExistingTemplates, RemoveTemplate, RequestTemplateList, UpdateTemplateReq } from "./action";
import { Alert, Grid, Modal, Table, Button, Box, Paper, styled, TableRow, TablePagination, InputBase, IconButton, TableHead, TableSortLabel, TableContainer, Typography } from "@mui/material";
import AddTemplateOptionDialog from "./AddTemplateOptionDialog";
import CommonOptionDialog from "./CommonOptionDialog";
import TemplateLibrary from "./TemplateLibrary";
// origin
let ENV;
let origin = window.location.origin.toLowerCase();
if (origin.includes("https://")) origin = origin.replace("https://", "");
else origin = origin.replace("http://", "");
ENV = config_settings[origin].ENV;

//Export to .xlsx
var currentdate = new Date();
const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, `TemplateList ${currentdate.toDateString()} ` + fileExtension);
};
//Styled component for action column
const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
// desc
function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === "number" && typeof b[orderBy] === "number") {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
  } else {
    if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
      return -1;
    }
    if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
      return 1;
    }
  }
  return 0;
}
//table row heading
const Templatetablehead = [
  { id: "name", numeric: false, disablePadding: false, label: "TEMPLATE NAME" },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "DESCRIPTION",
  },
  {
    id: "created_at",
    numeric: true,
    disablePadding: false,
    label: "LAST EDITED",
  },
  {
    id: "last_used",
    numeric: false,
    disablePadding: false,
    label: "LAST USED",
  },
  {
    id: "report_count",
    numeric: true,
    disablePadding: false,
    label: "REPORTS",
  },
  { id: "active", numeric: true, disablePadding: false, label: "ACTIVE" },
  { id: "Actions", numeric: true, disablePadding: true, label: "ACTIONS" },
];
// acsc / desc function
function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
//sort function
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//map funtion of table heading
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {Templatetablehead.map((headCell) => (
          <td key={headCell.id} align={"left"} padding={headCell.disablePadding ? "none" : "normal"} style={{ paddingLeft: "17px" }} className="agent-table-head">
            <TableSortLabel
              IconComponent={headCell.id === "avatar" || headCell.id === "Actions" || headCell.id === "active" ? "" : () => <img alt="icon" src={Sortswitch} style={{ marginRight: "6px" }} className="sort-switch" />}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                marginTop: GetCurrentResolution() === "mobile" && "21px",
                marginBottom: "10.5px",
                flexDirection: "row-reverse",
                fontSize: "12px",
                textTransform: "capitalize",
              }}
            >
              {headCell.label} {/* table heading label */}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </td>
        ))}
      </TableRow>
    </TableHead>
  );
}
// props
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
//toolbar
const EnhancedTableToolbar = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });
  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid item xs={12} sx={{ width: { xs: "371.5px", sm: "100%", md: "100%", lg: "100%" } }} className="inspection-toolbar-main">
        <Paper
          component="form"
          className={"inspection-search-field"}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: { xs: "241px", sm: "29.64%", md: "29.64%", lg: "29.64%" },
          }}
        >
          <IconButton sx={{ p: "10px" }} aria-label="search">
            <SearchIcon className="inspection-search-icon" />
          </IconButton>
          <InputBase sx={{ ml: 1, flex: 1, fontSize: "16px" }} placeholder="Search" inputProps={{ "aria-label": "search" }} onChange={props.handleSearchFilter} />
        </Paper>
        {isMobile ? (
          <p className="inspections-export-content" onClick={(e) => exportToCSV(props.excelData, "TemplateList")}>
            <ExitToAppIcon sx={{ fontSize: "38px" }} className="inspections-export-icon" />
            {isMobile ? "" : "Export"}
          </p>
        ) : (
          <p sx={{ width: "7.6%" }} className="inspections-export-content" onClick={(e) => exportToCSV(props.excelData, "TemplateList")}>
            <ExitToAppIcon className="inspections-export-icon" />
            {isMobile ? "" : "Export"}
          </p>
        )}
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};
//render function
export default function AgentListView() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const handleOpen = () => setOpen(true);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState("");
  const handleClose = () => setOpen(false);
  const [refresh, setRefresh] = useState(0);
  const [order, setOrder] = useState("asc");
  const [message, setMessage] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [addNew, setAddNew] = useState(false);
  const [newTempId, setNewTempId] = useState();
  const [selected, setSelected] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const handleCloseNew = () => setAddNew(false);
  const [templateId, setTemplateId] = useState();
  const [showLoader, setShowLoader] = useState();
  const [excelData, setExcelData] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [table_data, setTableData] = useState([]);
  const [loadDetails, setLoadDetails] = useState(0);
  const [defaultData, setDefaultData] = useState([]);
  let result = JSON.parse(JSON.stringify(excelData));
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [addTempLib, setAddTempLib] = useState(false);
  const handleOpenTempLib = () => setAddTempLib(true);
  const handleCloseTempLib = () => setAddTempLib(false);
  const [tempLib, setTempLib] = useState([]);
  const [TemplateName, setTemplateName] = useState("");
  const handleChipReset = () => setDisplayFilters(false);
  const [deleteControl, setDeleteControl] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);
  const [openOptionDialog, setOpenOptionDialog] = useState("");
  // const handleChipClick = () => console.info("You clicked the Chip.");
  // const handleChipDelete = () => console.info("You clicked the delete icon.");
  // xlsx
  result.filter((data) => (delete data.id, delete data.based_on, delete data.block_changes, delete data.company_id, delete data.copyright, delete data.default, delete data.fillable_pdf, delete data.guid, delete data.version, delete data.updated_by, delete data.show, delete data.report_title, delete data.rating_set_id, delete data.rating_data, delete data.other_data, delete data.location_data, delete data.line_level_rating, delete data.last_used, delete data.is_published, delete data.created_by, delete data.deleted, delete data.inspection_id, delete data.inspection_type_id));

  const handleOpenEdits = (id, name) => {
    setTemplateName(name);
    setNewTempId(id);
    setOpenEdit(true);
  };
  const handleCloseEdit = () => {
    setNewTempId();
    setOpenEdit(false);
  };

  const pagination = () => {
    setPage(0);
  };
  //Get template data
  const GetTemplatesLists = () => {
    setShowLoader(true);
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
    };
    RequestTemplateList((response) => {
      if (response?.data?.success) {
        setTableData(response?.data?.data);
        setDefaultData(response?.data?.data);
        setShowLoader(false);
        setExcelData(response?.data?.data);
      } else {
        setMessage(response?.data?.message);
        setShowLoader(false);
      }
    }, formdata);
  };

  const GetTemplateLibrary = () => {
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
    };
    apiCall(
      (response) => {
        // console.log("GET AGENT LIST", response);
        if (response.data.success) {
          // success
          setTempLib(response.data.data);
        } else {
          // console.log("else", response.data.message);
        }
      },
      formdata,
      "TEMPLATE_LIB_LIST"
    );
  };

  const handleClickOpenAlert = (id, name) => {
    // console.log(name, id);
    setDeleteId(id);
    setTemplateName(name);
    setOpen(true);
  };
  // delete template
  const deleteTemplate = (id) => {
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      template_id: id,
    };
    RemoveTemplate((response) => {
      if (response.data.success) {
        toast.success(response.data.message);
        setDeleteControl(!deleteControl);
        setOpen(false);
      } else {
        // console.log("else", response.data.message);
      }
    }, formdata);
  };

  const EditNewTemplate = (id) => {
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      template_id: id,
    };
    EditTemplateReq((response) => {
      if (response.data.success) {
        navigate(`/templates/${response.data.data?.name?.charAt(0) === "#" ||
          response.data.data?.name?.charAt(0) === "/"
          ? response.data.data?.name
            ?.substring(1)
            .replace(
              new RegExp("/", "g"),
              ""
            )
          : response.data.data?.name.replace(
            new RegExp("/", "g"),
            ""
          )
          }`, {
          state: {
            id: response?.data?.data?.id,
            name: response?.data?.data?.name,
            version: response?.data?.data?.version,
            edit: true,
          },
        });
        // debugger;
        // console.log(response.data);
        setOpenEdit(false);
        // fetchEditTemplate(response.data)
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    }, formdata);
  };

  useEffect(() => {
    GetTemplatesLists();
    GetTemplateLibrary();
  }, [deleteControl || refresh]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table_data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // table_data.filter((value) => {
  //   if (value.is_published === 2) {
  //     setTimeout(GetTemplatesLists, 10000);
  //   }
  // });
  const handleOpenTemplateDialog = () => {
    setOpenTemplateDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenTemplateDialog(false);
  };

  const HandleOpenEditor = (type, open, id) => {
    setOpenTemplateDialog(false);
    setAddNew(open);
    setIsEdit(type);
    if (type === "edit") {
      setTemplateId(id);
      setLoadDetails(loadDetails + 1);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchFilter = (event) => {
    setPage(0);
    let searchQuery = event.target.value?.toLowerCase();
    let searchData = table_data.filter((ele) => {
      return ele.name?.toLowerCase().includes(searchQuery);
    });
    if (searchQuery !== "") {
      if (searchData.length !== 0) {
        setTableData(searchData);
      }
    } else {
      setOrderBy("");
      setTableData(defaultData);
    }
  };

  const TogglerSwitch = (id, is_pdf_template) => {
    toast.dismiss();
    setShowLoader(true);
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      template_id: id,
      is_pdf_template: is_pdf_template,
    };
    GetExistingTemplates((response) => {
      const { data, success, message } = response.data;
      if (success) {
        UpdateTemplateReq(
          (response) => {
            if (response.data.success) {
              toast.success(response.data.message);
              setRefresh(refresh + 1);
              setShowLoader(false);
              // setDeleteControl(!deleteControl);   //cmntd bcz it is not updating the template list after activating template
            } else {
              toast.error(response.data.message);
              setShowLoader(false);
            }
          },
          {
            company_id: sessionStorage.getItem("company_id"),
            user_id: sessionStorage.getItem("user_id"),
            role_id: sessionStorage.getItem("role_id"),
            template_id: data?.id,
            name: data?.name,
            description: data?.description,
            inspection_type_id: data?.inspection_type_id,
            default: data?.default,
            is_active: data.is_active === 0 ? 1 : data.is_active === 1 && 0,
            line_level_rating: data?.line_level_rating,
            is_pdf_template: is_pdf_template,
          }
        );
      } else {
        // console.log("else", message);
      }
    }, formdata);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - table_data.length) : 0;

  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });

  const handleOpenOption = (option) => {
    setOpenOptionDialog(option);
  };

  const handlePdfClick = () => {
    toast.success("This template cannot be edited")
  }

  return (
    <>
      <AddTemplateOptionDialog handleCloseDialog={handleCloseDialog} openTemplateDialog={openTemplateDialog} HandleOpenEditor={HandleOpenEditor} handleOpenOption={handleOpenOption} />
      <CommonOptionDialog openOptionDialog={openOptionDialog} handleOpenOption={handleOpenOption} />
      <TemplateLibrary data={tempLib} openModal={addTempLib} setRefresh={setRefresh} refresh={refresh} setShowLoader={setShowLoader} handleClose={handleCloseTempLib} />
      {showLoader ? (
        <Preloader showPreloader={true} />
      ) : (
        <Box
          className="inspections-main"
          sx={{
            width: "100%",
            px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },
          }}
        >
          <div className="inspection-header">
            <Grid sx={{ width: "50%" }}>
              <h3 className="inspection-title">Templates</h3>
              <p className="inspections-subheader">All templates in your library. Select one to see details.</p>
            </Grid>
            {!isMobile ? (
              <Grid container className="to-right">
                {/* {ENV === "PROD" ? (
                  ""
                ) : ( */}
                <button className="add-template-library-button" onClick={handleOpenTempLib}>
                  Add from library
                </button>
                {/* )} */}

                <button
                  className="inspections-new-button"
                  sx={{ textTransform: "none" }}
                  onClick={() => HandleOpenEditor("create", true)}
                // onClick={() => handleOpenTemplateDialog()}
                >
                  Add new &nbsp;&nbsp;+
                </button>
              </Grid>
            ) : (
              <Grid container className="to-right">
                {console.log(ENV)}
                {/* {ENV === 'PROD' ? "" :  */}
                <button className="add-template-library-button" onClick={handleOpenTempLib}>
                  Add from library
                </button>

                {/* } */}

                <button
                  className="add-template-library-button"
                  sx={{ textTransform: "none" }}
                  onClick={() => HandleOpenEditor("create", true)}
                // onClick={() => handleOpenTemplateDialog()}
                >
                  Add new &nbsp;&nbsp;+
                </button>
              </Grid>
            )}
          </div>
          {table_data?.length !== 0 ? (
            <Paper sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 5 }} className={"inspection-table-main"}>
              <EnhancedTableToolbar handleOpen={handleOpen} table_data={table_data} excelData={result} displayFilters={displayFilters} handleChipReset={handleChipReset} handleSearchFilter={handleSearchFilter} />
              <TableContainer className={isMobile && "mobileScroller"} sx={{ overflowX: { sm: "auto", lg: "unset", md: "scroll" } }}>
                <Table
                  aria-labelledby="tableTitle"
                  size={"small"}
                  style={{
                    transform: isMobile && "rotate(180deg)",
                    overflowX: "unset",
                    width: "100",
                  }}
                >
                  <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={table_data.length} />
                  <tbody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with: rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(table_data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        let updtime = moment(moment.utc(row.created_at).local());
                        let lastusedtime = moment(moment.utc(row?.last_used).local());
                        let timeStramp = moment(updtime).fromNow();
                        let lastusedtimeStramp = moment(lastusedtime).fromNow();
                        const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <tr
                            key={row.id}
                            onClick={(event) => {
                              if (row?.is_pdf_template === 0) handleClick(event, row.name);
                            }}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            className="inspections-data-table"
                          >
                            {/* <td style={{paddingLeft:"10px"}}><Avatar src={row.avatar}></Avatar></td> */}
                            <td
                              className="insp-address"
                              component="th"
                              style={{
                                height: "60px",
                                paddingLeft: "30px",
                                borderLeft: row?.default === 1 && "3px solid #239d80",
                              }}
                              id={labelId}
                              padding="none"
                            >
                              <div
                                style={{
                                  width: "250px",
                                  display: "flex",
                                  alignItems: "center",
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                  gap: "8px",
                                }}
                              >
                                {row.is_pdf_template === 1 ? <PictureAsPdfOutlinedIcon /> : ""}
                                {row.is_pdf_template === 1 ? (
                                  <span
                                    style={{
                                      fontWeight: "900",
                                      color: row?.default === 1 && "#239d80",
                                    }}
                                    className="table-body-row"
                                    onClick={() => {
                                      if (row?.is_pdf_template === 1) {
                                        handlePdfClick()
                                      }
                                    }}
                                  >
                                    {row?.name?.length > 50 ? (
                                      <BootstrapTooltip title={<h1 className="font-13">{row?.name}</h1>}>
                                        <p
                                          style={{
                                            color: row?.default === 1 && "#239d80",
                                          }}
                                        >{`${row.name.substring(11, 0)}...`}</p>
                                      </BootstrapTooltip>
                                    ) : (
                                      row.name
                                    )}
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      fontWeight: "900",
                                      color: row?.default === 1 && "#239d80",
                                    }}
                                    className="table-body-row"
                                    onClick={() =>
                                      row?.is_published === 1
                                        ? handleOpenEdits(row.id, row.name)
                                        : row?.is_published === 2
                                          ? handleOpenEdits(row.id, row.name)
                                          : navigate(`/templates/${row.name?.charAt(0) === "#" || row.name?.charAt(0) === "/" ? row.name?.substring(1).replace(new RegExp("/", "g"), "") : row.name.replace(new RegExp("/", "g"), "")}`, {
                                            state: {
                                              id: row.id,
                                              name: row?.name,
                                              version: row.version,
                                              edit: true,
                                            },
                                          })
                                    }
                                  >
                                    {row?.name?.length > 50 ? (
                                      <BootstrapTooltip title={<h1 className="font-13">{row?.name}</h1>}>
                                        <p
                                          style={{
                                            color: row?.default === 1 && "#239d80",
                                          }}
                                        >{`${row.name.substring(11, 0)}...`}</p>
                                      </BootstrapTooltip>
                                    ) : (
                                      row.name
                                    )}
                                  </span>
                                )}
                                {row?.default === 1 && (
                                  <h6
                                    style={{
                                      color: "#9B9B9B",
                                      fontSize: "10px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    &nbsp;(Default)
                                  </h6>
                                )}
                              </div>

                              {row.is_published === 0 && <span className="changes_status">Unpublished changes</span>}
                              {row.is_published === 2 && <span className="changes_status">Publish In-progress</span>}
                            </td>
                            <td className="insp-status" align="left" style={{ paddingLeft: "32px" }}>
                              <div
                                style={{
                                  width: "340px",
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {row?.description?.length > 35 ? (
                                  <BootstrapTooltip title={<h1 className="font-13">{row?.description}</h1>}>
                                    <p>{`${row.description.substring(30, 0)}...`}</p>
                                  </BootstrapTooltip>
                                ) : row.description && row.description !== "null" ? (
                                  row.description
                                ) : (
                                  ""
                                )}
                              </div>
                            </td>
                            <td align="left" style={{ paddingLeft: "32px" }}>
                              <div style={{ textAlign: "left" }}>
                                <span className="date-placeholder">{moment(row.created_at).format("MM/DD/yy")}</span>
                                <span className="time-modified-placeholder" style={{ textTransform: "initial" }}>
                                  {timeStramp}
                                </span>
                              </div>
                            </td>
                            <td align="left" style={{ paddingLeft: "32px" }}>
                              <div style={{ textAlign: "left" }}>
                                <span className="date-placeholder">{row.last_used === null ? " " : moment(row.last_used).format("MM/DD/yy")}</span>
                                <span className="time-modified-placeholder" style={{ textTransform: "initial" }}>
                                  {row.last_used === null ? "" : lastusedtimeStramp}
                                </span>
                              </div>
                            </td>
                            <td
                              align="left"
                              style={{
                                paddingLeft: "32px",
                                paddingRight: "10px",
                              }}
                            >
                              <span className="agent-row-data-email">{row.report_count ?? 0}</span>
                            </td>
                            <td onClick={() => TogglerSwitch(row.id, row.is_pdf_template)} align="left" style={{ paddingLeft: "32px" }}>
                              {row.is_active === 0 ? <ToggleOffIcon id={`toggleOff${index + 1}`} style={{ fontSize: "32px", color: "#BEBEBE", cursor: "pointer" }} /> : <ToggleOnIcon id={`toggleOn${index + 1}`} style={{ fontSize: "32px", color: "#4282E2", cursor: "pointer" }} />}
                            </td>
                            <td className="insp-actions" align="left" style={{ paddingLeft: "32px" }}>
                              {row?.is_pdf_template === 1 ? (
                                <span>
                                  <BootstrapTooltip title={<h1 className="font-13">Cannot Edit this template</h1>}>
                                    <EditIcon className="edit-icon" sx={{ opacity: "0.25" }} id={`edit${index + 1}`} />
                                  </BootstrapTooltip>
                                  <BootstrapTooltip title={<h1>Cannot delete this template</h1>}>
                                    <DeleteIcon
                                      className="delete-icon-TE"
                                      sx={{
                                        opacity: "0.25", ":hover": {
                                          color: "#3b3b3b",
                                          opacity: "0.25",
                                        }
                                      }}
                                      id={`delete${index + 1}`}
                                    //  onClick={() => handleClickOpenAlert(row.id, row.name)}
                                    />
                                  </BootstrapTooltip>
                                </span>
                              ) : (
                                <span>
                                  <BootstrapTooltip title={<h1 className="font-13">Edit</h1>}>
                                    <EditIcon
                                      className="edit-icon"
                                      id={`edit${index + 1}`}
                                      onClick={() =>
                                        row?.is_published === 1
                                          ? handleOpenEdits(row.id, row.name)
                                          : row?.is_published === 2
                                            ? handleOpenEdits(row.id, row.name)
                                            : navigate(`/templates/${row.name?.charAt(0) === "#" || row.name?.charAt(0) === "/" ? row.name?.substring(1).replace(new RegExp("/", "g"), "") : row.name.replace(new RegExp("/", "g"), "")}`, {
                                              state: {
                                                id: row.id,
                                                name: row?.name,
                                                version: row.version,
                                                edit: true,
                                              },
                                            })
                                      }
                                    />
                                  </BootstrapTooltip>
                                  <BootstrapTooltip title={<h1>Delete</h1>}>
                                    <DeleteIcon className="delete-icon-TE" id={`delete${index + 1}`} onClick={() => handleClickOpenAlert(row.id, row.name)} />
                                  </BootstrapTooltip>
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    {emptyRows > 0 && (
                      <tr style={{ height: 33 * emptyRows }}>
                        <td colSpan={6} />
                      </tr>
                    )}
                  </tbody>
                </Table>
              </TableContainer>
              {/* table pagination component */}
              <TablePagination rowsPerPageOptions={[10, 25]} component="div" count={table_data.length} rowsPerPage={rowsPerPage} page={emptyRows === rowsPerPage ? pagination : page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </Paper>
          ) : (
            <Paper sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 10 }} elevation={0} className={"inspection-table-main"}>
              <Alert severity="error">{message}</Alert>
            </Paper>
          )}
        </Box>
      )}
      {/* delete screen */}
      <EditTemplateList open={addNew} deleteControl={deleteControl} isEdit={isEdit} templateId={templateId} refresh={loadDetails} load={() => setRefresh(refresh + 1)} onClose={handleCloseNew} detailList={table_data} />
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "655px !important", top: "40%" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={handleClose} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", mt: "90px" }}>
              <img src={AlertImage} alt="alert" style={{ width: "51px", height: "49px" }} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "22px",
                  mt: "15px",
                  letterSpacing: 0,
                  wordWrap: "break-word",
                }}
              >
                {`Delete template  `}
                <b style={{ fontSize: "22px", wordWrap: "break-word" }}>{TemplateName}</b>?
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "14px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                {table_data.length !== 1 ? (
                  <>
                    Deleting a template will permanently remove it from all
                    locations.
                    <br />
                    Previous reports will not be affected. This cannot be
                    undone.
                  </>
                ) : (
                  <>
                    You must have at least one template. Please add another
                    before removing.
                  </>
                )}
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "right",
                mt: 8,
                mr: "34px",
                pb: "25px",
                ":hover": { backgroundColor: "transparent !important" },
              }}
            >
              <Button variant="outlined" className="inspection-filter-modal-buttons-cancel" sx={{ width: "119px" }} onClick={handleClose}>
                Cancel
              </Button>
              {table_data?.length !== 1 && (
                <Button
                  variant="contained"
                  className="inspection-filter-modal-buttons-apply"
                  sx={{ width: "119px", ml: 3 }}
                  onClick={() => deleteTemplate(deleteId)}
                >
                  Ok
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* modal for edit template  */}
      <Modal open={openEdit} onClose={handleCloseEdit} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "655px !important", top: "40%" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={handleCloseEdit} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", mt: "60px" }}>
              <img src={AlertImage} alt="alert" style={{ width: "51px", height: "49px" }} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "22px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                {`The template: `}
                <span
                  style={{
                    fontSize: "22px",
                    wordWrap: "break-word",
                    fontWeight: 900,
                  }}
                >
                  {TemplateName}
                </span>{" "}
                has been published
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "14px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                You are creating a draft copy to work in. When you have finished editing, choose "Save and Publish" to make it available for your inspections.
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "right",
                mt: 8,
                mr: "34px",
                pb: "25px",
              }}
            >
              <Button variant="outlined" className="inspection-filter-modal-buttons-cancel" sx={{ width: "119px" }} onClick={handleCloseEdit}>
                Cancel
              </Button>
              <Button variant="contained" className="inspection-filter-modal-buttons-apply" sx={{ width: "119px", ml: 3 }} onClick={() => EditNewTemplate(newTempId)}>
                Ok
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
