import React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
// import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate } from "react-router-dom";

const Breadcrumb = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  const path = location.pathname.split("/").filter((x) => x);
  // const isTaborMobile = useMediaQuery('(min-width:900px)');
  const roleId = sessionStorage.getItem("role_id");
  let PTadminFlag =
    roleId === "2" || roleId === "3" || roleId === "4" ? true : false;
  let PTCustomerSupport = roleId === "10" || roleId === "11";

  const breadcrumbs = [
    <Link
      underline={PTCustomerSupport ? "none" : "hover"}
      onClick={() =>
        path[0] === "import"
          ? navigate("/import")
          : PTadminFlag
          ? navigate("/company")
          : PTCustomerSupport
          ? null
          : navigate("/dashboard")
      }
      className="home-breadcrumb"
      key={1}
    >
      Home
    </Link>,
    path.map((name, index) => {
      const routeTo = `/${path.slice(0, index + 1).join("/")}`;
      const isLast = index === path?.length - 1;
      name = name.includes("-") ? name.replace(/-/g, " ") : name;
      return isLast ? (
        <Typography className="breadcrumb-last" key={name}>
          {name === "inspection"
            ? `inspection #${props.value}`
            : name.includes("%20")
            ? name.replace(/%20/g, " ")
            : name === "teams"
            ? "team"
            : name === "template"
            ? "templates"
            : decodeURIComponent(name)}
        </Typography>
      ) : (
        <Link
          className="breadcrumb-normal"
          onClick={() => navigate(routeTo)}
          key={name}
        >
          {name === "teams" ? "team" : name === "template" ? "templates" : name}
        </Link>
      );
    }),
    // path[0] === "import" && props?.companyName ? (
    //   <Link
    //     className="breadcrumb-normal"
    //     onClick={() => navigate(`/import/${props?.companyName}`)}
    //   >
    //     {props?.companyName}
    //   </Link>
    // ) : null,
  ];
  return (
    <div
      style={{ backgroundColor: path[0] === "templates" && "#f7f9fa" }}
      className={
        props.alertPresent
          ? "breadcrumb-container-with-alert"
          : "breadcrumb-container"
      }
    >
      {PTCustomerSupport ? null : (
        <Stack spacing={2}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
      )}
    </div>
  );
};
export default Breadcrumb;
