//file imports****************************************************************************************
import moment from "moment";
import PropTypes from "prop-types";
import { visuallyHidden } from "@mui/utils";
import { useState , useEffect } from "react";
import Preloader from "../../helpers/Preloader";
import { useMediaQuery } from "react-responsive";
import SearchIcon from "@mui/icons-material/Search";
import "../../../assets/styles/containers/_agent.scss";
import { GetCurrentResolution } from "../../utils/services";
import Sortswitch from "../../../assets/images/Sortswitch.png";
import { apiCall } from "../../pages/settings/Services/action";
import { Alert, Grid, Table, Box, Paper, TableRow, TablePagination, InputBase, IconButton, TableHead, TableSortLabel, TableContainer } from "@mui/material";
//descending comparator funtion************************************************************************
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) { return -1; }
  if (b[orderBy] > a[orderBy]) { return 1;  }
  return 0;
}
//table row heading************************************************************************************
const agenttablehead = [
  { id: "created_at", numeric: false, disablePadding: true, label: "DATE/TIME", },
  { id: "action", numeric: false, disablePadding: false, label: "ACTION", },
];
// acsc / desc function********************************************************************************
function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
//sort function****************************************************************************************
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
//map funtion of table heading*************************************************************************
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => { onRequestSort(event, property); };
  return (
    <TableHead>
      <TableRow>
        {agenttablehead.map((headCell) => (
          <td key={headCell.id} align={"left"} padding={headCell.disablePadding ? "none" : "normal"} className="activity-table-head">
            <TableSortLabel IconComponent={ headCell.id === "avatar" || headCell.id === "Actions" ? "" : () => ( <img alt="icon" src={Sortswitch} style={{marginRight:'6px'}} className="sort-switch" /> )}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ marginTop: GetCurrentResolution() === "mobile" && "21px", marginBottom: "10.5px", flexDirection: "row-reverse", fontSize: "12px" }}>
              {headCell.label} {/* table heading label */}
              {orderBy === headCell.id ? ( <Box component="span" sx={visuallyHidden}> {order === "desc" ? "sorted descending" : "sorted ascending"}</Box> ) : null}
            </TableSortLabel>
          </td>
        ))}
      </TableRow>
    </TableHead>
  );
}
//prop types
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};
//search toolbar 
const EnhancedTableToolbar = (props) => {
  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid item xs={12} sx={{ width: { xs: "100%", sm: "100%", md: "100%", lg: "100%" } }} className="inspection-toolbar-main" >
        <Paper  elevation={0} className={"inspection-search-field"} sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: { xs: "241px", sm: "29.64%", md: "29.64%", lg: "29.64%" } }} >
          <IconButton sx={{ p: "10px" }} aria-label="search"><SearchIcon className="inspection-search-icon"/></IconButton>
          <InputBase sx={{ ml: 1, flex: 1, fontSize: "16px" }} placeholder="Search" inputProps={{ "aria-label": "search" }}  onChange={props.handleSearchFilter}/>
        </Paper>
        </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};
//render function**************************************************************************************
export default function Activity (props) 
{
  const [page, setPage] = useState(0);
  const handleOpen = () => setOpen(true);
  const [open, setOpen] = useState(false);
  const [order, setOrder] = useState("asc");
  const [message, setMessage] = useState("");
  const [selected, setSelected] = useState([]);
  const [showLoader, setShowLoader] = useState();
  const [table_data, setTableData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("Calories");
  const handleChipReset = () => setDisplayFilters(false);
  const [deleteControl, setDeleteControl] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(false);
  const handleChipClick = () => console.info("You clicked the Chip."); 
  const handleChipDelete = () =>  console.info("You clicked the delete icon."); 
  //Get inspection activity api call*************************************************************
  const GetInspectionActivity = () => {
    setShowLoader(true);
    const formdata = { company_id: sessionStorage.getItem("company_id"), user_id: sessionStorage.getItem("user_id"), role_id: sessionStorage.getItem("role_id") , inspection_id: props.inspectionId };
    apiCall((response) => {
      // console.log("GET_INSOECTION_ACTIVITY", response);
      if (response.data.success) {
        setTableData(response.data.data);
        setShowLoader(false);
      } else {
        // console.log("else", response.data.message);
        setMessage(response.data.message);
        setShowLoader(false);
      }
    }, formdata,"GET_INSPECTION_ACTIVITY");
  };
  //Intialization
  useEffect(() => {
    GetInspectionActivity();
  }, [deleteControl]);
  //sort
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  //select
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table_data.map((n) => n.triggered_by);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  //handle click
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex),selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };
  //page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  //row function
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //search function
  const handleSearchFilter = (event) => {
    let searchQuery = event.target.value.toLowerCase();
    let searchData = table_data.filter((ele) => {
      return ele.description.toLowerCase().startsWith(searchQuery);
    });
    if (searchQuery !== "") {
      if (searchData.length !== 0) {
        setTableData(searchData);
      }
    } else {
      setTableData(prev => prev);
      setDeleteControl(!deleteControl);
    }
  };
  //select
  const isSelected = (name) => selected.indexOf(name) !== -1;
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - table_data.length) : 0;
  //is mobile trigger
  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });
  //render function
  return (
    <>
      {showLoader ? <Preloader showPreloader={true}/> :
      <Box className="inspections-main" sx={{ width: "100%", padding: "0px !important" }} >
       {table_data?.length!== 0?  <Paper sx={{ width: "100%", sm: 8, mb: 4, lg: 2 , mt: 5 , border: '1px solid #E7E7E9' }} className={"inspection-table-main"} elevation={0}>
          <EnhancedTableToolbar handleOpen={handleOpen} displayFilters={displayFilters} handleChipReset={handleChipReset} handleChipClick={handleChipClick} handleChipDelete={handleChipDelete} handleSearchFilter={handleSearchFilter} />
          <TableContainer className={isMobile && "mobileScroller"} sx={{ overflowX: { sm: "auto", lg: "unset", md: "scroll" } }}>
            <Table aria-labelledby="tableTitle" size={"small"} style={{ transform: isMobile && "rotate(180deg)", overflowX: "scroll", width: "100", }}>
              <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={table_data.length}/>
              <tbody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with: rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(table_data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <tr onClick={(event) => handleClick(event, row.name)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.name} style={{lineHeight:"20px !important"}} className="inspections-data-table">
                      <td align="left"style={{paddingLeft:"35px", paddingRight:"0px", paddingTop:"10px" , paddingBottom:"10px",lineHeight:"20px "}} id={labelId}><div style={{ textAlign: "left" }} ><span className="date-placeholder">{moment(row.updated_at).format('M/DD/YY')}</span><span className="time-modified-placeholder" style={{textTransform: "uppercase", fontSize:"12px"}}>{moment(row.updated_at).format('LT')} </span></div>{/* date column */}</td>
                      <td align="left"style={{paddingLeft:"15px", paddingRight:"0px", paddingTop:"10px" , paddingBottom:"10px", overflow:"hidden",lineHeight:"20px"}}><span className="agent-row-data-font" ><p style={{fontWeight: "600", fontSize:"14px", color: "#333333", letterSpacing:0, lineHeight:"20px !important"}}>{row.description}<br/><span style={{fontWeight: "normal" , fontSize:"14px"}}>Triggered by: {row.triggered_by}. Email: {row.email}</span></p>{/* phone number */}</span></td>
                    </tr>
                    );
                  })}
                { emptyRows > 0 && ( <tr style={{ height: 33 * emptyRows }}><td colSpan={6}/></tr> )}
              </tbody>
            </Table>
          </TableContainer>
          {/* table pagination component***************************************************** */}
          <TablePagination rowsPerPageOptions={[10, 20]} component="div" count={table_data.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage}/>
        </Paper>: <Paper sx={{ width: "100%", sm: 8, mb: 4, lg: 2 , mt: 5  }} elevation={0} className={"inspection-table-main"}><Alert severity="error">{message}</Alert></Paper>}
      </Box>}
    </>
  );
}
