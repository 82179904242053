import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment-timezone";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TableSortLabel from "@mui/material/TableSortLabel";
import EnhancedTableToolbar from "../../../common/table/EnhancedTableToolbar";
import { exportTempList } from "../../../config/app-constants";
import ExportTemplateDialog from "./ExportTemplateDialog";
import Sortswitch from "../../../../assets/images/Sortswitch.png";
import Preloader from "../../../helpers/Preloader";
import { apiCallPaginated } from "../../../utils/action";
import { customSort } from "../../../utils/services";

function ExportTemplateList(props) {
  const { showTemplateList } = props;
  const [state, setState] = useState({
    showLoader: false,
    openDialog: null,
    rowsPerPage: 2,
    page: 0,
    totalListCount: 0,
    templateList: null,
    selectedCompany: null,
    excelData: null,
    apiPageCount: 0,
    order: "asc",
    orderBy: "company_key",
  });

  const {
    showLoader,
    searchText,
    rowsPerPage,
    page,
    totalListCount,
    fileteredList,
    excelData,
    apiPageCount,
    order,
    orderBy,
    openDialog,
    templateList,
  } = state;

  let result = JSON.parse(JSON.stringify(excelData));

  const getTemplateList = (searchText = null) => {
    const formData = {
      parent_app_id: showTemplateList,
      search: searchText,
    };
    apiCallPaginated(
      (response) => {
        if (response.data.success) {
          setState((prevState) => ({
            ...prevState,
            showLoader: false,
            page: response?.data?.data?.current_page - 1,
            totalListCount: response?.data?.data?.total,
            rowsPerPage: response?.data?.data?.per_page,
            templateList: response?.data?.data?.data,
            fileteredList: response?.data?.data?.data,
            excelData: response?.data?.data?.data,
          }));
        } else {
          if (response.data.status_code === 404)
            setState((prevState) => ({
              ...prevState,
              templateList: [],
              fileteredList: [],
              showLoader: false,
            }));
        }
      },
      formData,
      "GET_PUBLISHED_TEMPLATE_LIST",
      apiPageCount + 1
    );
  };

  useEffect(() => {
    async function callApi() {
      setState((prevState) => ({ ...prevState, showLoader: true }));
      await getTemplateList();
    }
    if (showTemplateList) callApi();
  }, [apiPageCount, rowsPerPage]);

  const handleOpenDialog = (obj) => {
    setState((prevState) => ({ ...prevState, openDialog: obj?.id }));
  };
  const handleCloseDialog = () => {
    setState((prevState) => ({ ...prevState, openDialog: null }));
  };
  // const handleSearchEnter = (event) => {
  //   let searchQuery = event.target.value.toLowerCase();
  //   let searchData = templateList?.filter(
  //     (ele) =>
  //       ele.name?.toLowerCase()?.includes(searchQuery) ||
  //       ele.active?.toLowerCase()?.includes(searchQuery)
  //   );
  //   if (searchQuery !== "") {
  //     if (searchData?.length !== 0) {
  //       setState((prevState) => ({
  //         ...prevState,
  //         fileteredList: searchData,
  //         totalListCount: 0,
  //       }));
  //     } else setState((prevState) => ({ ...prevState, fileteredList: [] }));
  //   } else
  //     setState((prevState) => ({
  //       ...prevState,
  //       fileteredList: templateList,
  //     }));
  // };

  const handleSearchFilter = (event) => {
    getTemplateList(event.target.value);
    setState((prevState) => ({
      ...prevState,
      searchText: event.target.value,
    }));
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setState((prevState) => ({
      ...prevState,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    }));
  };

  const handleChangePage = (event, newPage) => {
    setState((prevState) => ({
      ...prevState,
      page: newPage,
      apiPageCount: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setState((prevState) => ({
      ...prevState,
      page: 0,
      apiPageCount: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    }));
  };

  return (
    <Box px={10} pt={3} pb={10}>
      <Preloader showLoader={showLoader} />
      <ExportTemplateDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        showTemplateList={showTemplateList}
      />
      <Grid container className="headingGrid">
        <Typography className="importHeading">Import</Typography>
      </Grid>
      <Paper
        sx={{ width: "100%", sm: 8, lg: 2, mt: "18px" }}
        className="teams-table-wrapper"
      >
        <EnhancedTableToolbar
          displayFilters={false}
          searchIcon={true}
          search={searchText}
          isMobile={false}
          handleSearchFilter={handleSearchFilter}
          // handleSearchEnter={handleSearchEnter}
          excelData={result}
          excelName={"Import Company List"}
        />
        {!fileteredList ? null : fileteredList?.length ? (
          <>
            <TableContainer className="ptCompanyListTable">
              <Table>
                <TableHead>
                  <TableRow>
                    {exportTempList.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        className="ptHeadingTitle"
                        align={headCell.id === "export" ? "center" : ""}
                      >
                        <TableSortLabel
                          IconComponent={() =>
                            headCell.name !== "Actions" && (
                              <img
                                src={Sortswitch}
                                alt="sort-icon"
                                className="sort-switch"
                              />
                            )
                          }
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={() => handleRequestSort(headCell.id)}
                          sx={{
                            marginBottom: "10.5px",
                            fontSize: "12px",
                            flexDirection: "row-reverse",
                          }}
                        >
                          {headCell.name}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customSort(fileteredList, order, orderBy).map(
                    (obj, index) => (
                      <TableRow key={index} className="ptRowColor">
                        <TableCell className="ptCell">
                          <Typography className="ptCompanyDetails">
                            {obj?.name}
                          </Typography>
                        </TableCell>
                        <TableCell className="ptCell">
                          <Typography className="ptCompanyDetails">
                            {obj?.description}
                          </Typography>
                        </TableCell>
                        <TableCell className="ptCell">
                          {obj?.updated_at ? (
                            <Grid container>
                              <Grid item xs={12}>
                                <Typography className="ptCompanyDetails">
                                  {moment(new Date(obj?.updated_at)).format(
                                    "MM/DD/YY"
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  fontSize={10}
                                  className="dateTimeColor"
                                >
                                  {moment(new Date(obj?.updated_at)).fromNow()}
                                </Typography>
                              </Grid>
                            </Grid>
                          ) : null}
                        </TableCell>
                        <TableCell className="ptCell">
                          <Button
                            id={`exportTemplateBtn${index}`}
                            onClick={() => handleOpenDialog(obj)}
                            className="ptBeginImportButton"
                          >
                            Export Template
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="company-pagination"
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={totalListCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : (
          <Paper
            sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 10 }}
            elevation={0}
            className={"inspection-table-main"}
          >
            <Alert severity="error">No datas found</Alert>
          </Paper>
        )}
      </Paper>
    </Box>
  );
}

export default ExportTemplateList;
