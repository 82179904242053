import { useState } from "react";
// dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { apiCall } from "../../utils/action";
import { userAgreement } from "../../components/AgreementsDetails/DefaultTemplate";
import { toast } from "react-hot-toast";
import { LoadingButton } from "@mui/lab";

const CreateOwnAgreementModal = ({showCreateOwnAgrmntModal,setShowCreateOwnAgrmntModal,isAgreementPageModal=false,setStateChanged,isSettingPageModal=false}) => {
    const uuId = sessionStorage.getItem("user_id");
    const companyId = sessionStorage.getItem("company_id");
    const roleId = sessionStorage.getItem("role_id");
    const [showLoader,setShowLoader] = useState(false)
    const [showLoaderNewAgreementEdit,setShowLoaderNewAgreementEdit] = useState(false)
    const navigate = useNavigate();

    const handleCloseCreateOwnAgremntModal =()=>{
        setShowCreateOwnAgrmntModal(false);
    }
    const createOwnAgreementHandle=()=>{
        setShowLoader(true);
        const formdata = {
          company_id: companyId,user_id:uuId, role_id:roleId,
          name: 'User Agreement',
          agreement_body: userAgreement,
        };
        apiCall(
          (response) => {
            const { message, success } = response.data;
            if (success) {
              toast.success(message);
              setShowLoader(false);
              if(!isAgreementPageModal && !isSettingPageModal) navigate("/schedule-inspection")              
              else if(isSettingPageModal) navigate("/settings/agreements")
              else {
                // setStateChanged(prev=>!prev)
                setShowCreateOwnAgrmntModal(false);
              }
            } else {
              toast.error(message);
              setShowLoader(false);
            }
          },
          formdata,
          "ADD_AGREEMENT"
        );
    }

    const createOwnAgreementAndEdit=()=>{
        setShowLoaderNewAgreementEdit(true);
        const formdata = {
          company_id: companyId,user_id:uuId, role_id:roleId,
          name: 'User Agreement',
          agreement_body: userAgreement,
        };
        apiCall(
          (response) => {
            const { message, success } = response.data;
            if (success) {
              toast.success(message);
              setShowLoaderNewAgreementEdit(false);
              if(!isAgreementPageModal) navigate("/settings/agreements")
              else {
                // setStateChanged(prev=>!prev)
                setShowCreateOwnAgrmntModal(false);
              }
              
            } else {
              toast.error(message);
              setShowLoaderNewAgreementEdit(false);
            }
          },
          formdata,
          "ADD_AGREEMENT"
        );
    }
  return (
    <Dialog
    open={showCreateOwnAgrmntModal}
    onClose={handleCloseCreateOwnAgremntModal}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    onBackdropClick="false"
    className="agreement-terms-modal create-own-agreement-terms-modal"
  >
    <DialogTitle id="alert-dialog-title">
      {"Would you like to create your own agreement now? "}
      {/* <CloseIcon className="close-icon" onClick={handleCloseCreateOwnAgremntModal} /> */}
    </DialogTitle>
    <DialogContent>
      <DialogContentText className="agreement-terms-text" id="alert-dialog-description">
     <p>If you want to do this later, Palmtech will put placeholder info in your agreement. You can edit the agreement later from the <Link className="link_signup">agreements</Link>  page in your account management section. </p>


      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <LoadingButton
      loading={showLoader}
        className="default-btn-agreement-terms"
        variant="contained"
        onClick={createOwnAgreementHandle}
      >
       I will do this later
      </LoadingButton>
      <LoadingButton
      loading={showLoaderNewAgreementEdit}
        className="default-btn-agreement-terms ml-17"
        variant="contained"
        color="error"
        onClick={createOwnAgreementAndEdit}
      >
        Edit agreement now
      </LoadingButton>
    </DialogActions>
  </Dialog>
  )
}

export default CreateOwnAgreementModal