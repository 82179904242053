import { Box, Grid, Paper, FormControl, TextField } from "@mui/material";
import Heading from "../../../common/cardHeading/Heading";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
const BillingInformation = (props) => {
  const { data } = props;
  const openUrl = () => {
    window.open(props.billingURL, "_blank");
  };
  const styles = {
    height: { xs: "40px", md: "40px", lg: "40px" },
    fontSize: { xs: "14px", md: "14px", lg: "14px" },
    background: "rgba(239, 239, 239, 0.8)",
    color: "text.primary !important",
    userSelect: "none",
  };
  let is_from_us = sessionStorage.getItem("is_from_us");
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Paper elevation={0} className="billing-info-card">
          <Heading CancelledStatus={false} title="Billing Information" icon={<OpenInNewIcon onClick={openUrl} className="edit-card-button" />} iconDesc={<p onClick={openUrl}>Edit billing information</p>} />
          <div className="billing-info-card-container">
            <Grid container>
              <Grid item xs={6}>
                <FormControl variant="standard" className="forms-control field-reset">
                  <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                    FIRST NAME
                  </label>
                  <TextField type="name" size="small" variant="outlined" placeholder="First Name" value={data?.first_name} className="input-textfield" InputProps={{ className: "input_props_field", sx: styles }} />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" className="forms-control field-reset">
                  <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                    LAST NAME
                  </label>
                  <TextField type="name" size="small" variant="outlined" placeholder="Last name" value={data?.last_name} className="input-textfield" InputProps={{ className: "input_props_field", sx: styles }} />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" className="forms-control field-reset">
                  <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                    EMAIL ADDRESS
                  </label>
                  <TextField type="email" size="small" variant="outlined" placeholder="Email" value={data?.email} className="input-textfield" InputProps={{ className: "input_props_field", sx: styles }} />
                  <p className="billingnews">We will send billing related news to this email.</p>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl variant="standard" className="forms-control field-reset">
                  <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                    PHONE
                  </label>
                  <TextField type="phone" size="small" variant="outlined" placeholder="Phone" value={data?.phone} className="input-textfield" InputProps={{ className: "input_props_field", sx: styles }} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sx={{ marginTop: -1 }}>
                <FormControl variant="standard" className="forms-control field-reset">
                  <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                    HOME ADDRESS
                  </label>
                  <TextField type="address" size="small" variant="outlined" placeholder="Address" value={data?.address} className="input-textfield" InputProps={{ className: "input_props_field", sx: styles }} />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="standard" className="forms-control field-reset">
                  <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                    CITY
                  </label>
                  <TextField type="name" size="small" variant="outlined" placeholder="City" value={data?.city} className="input-textfield" InputProps={{ className: "input_props_field", sx: styles }} />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="standard" className="forms-control field-reset">
                  {Number(is_from_us) === 1 ? (
                    <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                      STATE
                    </label>
                  ) : (
                    <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                      PROVINCE
                    </label>
                  )}
                  <TextField type="name" size="small" variant="outlined" placeholder={Number(is_from_us) === 1 ? "State" : "Province"} value={data?.state} className="input-textfield" InputProps={{ className: "input_props_field", sx: styles }} />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl variant="standard" className="forms-control field-reset">
                  {Number(is_from_us) === 1 ? (
                    <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                      ZIP CODE
                    </label>
                  ) : (
                    <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                      POSTAL CODE
                    </label>
                  )}
                  <TextField type="number" size="small" variant="outlined" placeholder={Number(is_from_us) === 1 ? "Zip" : "Postal"} value={data?.zip} className="input-textfield" InputProps={{ className: "input_props_field", sx: styles }} />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
    </Box>
  );
};

export default BillingInformation;
