import toast from "react-hot-toast";
import { AppContext } from "../../App";
import { LoadingButton } from "@mui/lab";
import { useState, useContext } from "react";
import { apiCall } from "../../utils/action";
import { useNavigate } from "react-router-dom";
import Preloader from "../../helpers/Preloader";
import { formatZip } from "../../utils/services";
import logo from "../../../assets/images/logo.png";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import {
  Paper,
  Grid,
  Alert,
  TextField,
  Typography,
  useMediaQuery,
  FormControl,
  InputLabel,
} from "@mui/material";

const OTPLogin = () => {
  const [otp, setOtp] = useState("");
  const { setReload } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery("(min-width:900px)");
  const [autoLoginLoading, setAutoLoginLoading] = useState(false);

  let navigate = useNavigate();
  const routeChange = (roleId) => {
    roleId === 2 || roleId === 3 || roleId === 4
      ? navigate(`/company`)
      : roleId === 10 || roleId === 11
      ? navigate(`/import`)
      : navigate(`/dashboard`);
    setReload((prev) => !prev);
  };

  const handleOTPChange = (e) => {
    const formattedOtp = formatZip(e.target.value);
    setOtp(formattedOtp);
  };

  const homePage = () => {
    navigate("/");
  };

  const getCompanyInfo = (company_id, user_id, role_id, route, role) => {
    const formdata = {
      company_id: company_id,
      user_id: user_id,
      role_id: role_id,
    };
    apiCall(
      (response) => {
        console.log("GET_COMPANY_INFO", response);
        if (response.data.success) {
          sessionStorage.setItem(
            "company_name",
            response.data.data.company_details.name
          );
          sessionStorage.setItem(
            "show_banner",
            response.data.data.company_details.show_banner
          );
          sessionStorage.setItem("is_trial", response.data.data.is_trial);
          routeChange(route);
          toast.success(`Logged in as ${role}`);
        } else {
          console.log("else", response.data.message);
        }
      },
      formdata,
      "GET_COMPANY_INFO"
    );
  };

  const OTPLogin = () => {
    setLoading(true);
    const formdata = { user_id: sessionStorage.getItem("user_id"), otp: otp };
    apiCall(
      (response) => {
        const { success, data, message } = response.data;
        if (success) {
          setOtp("");
          setLoading(false);
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("user_id", data.user_id);
          sessionStorage.setItem("user_name", data.user_name);
          sessionStorage.setItem("last_name", data.last_name);
          sessionStorage.setItem("first_name", data.first_name);
          sessionStorage.setItem("company_id", data.company_id);
          sessionStorage.setItem("is_from_us", data.is_from_us);
          sessionStorage.setItem("role_id", data.role_data.role_id);
          sessionStorage.setItem("total_insp", data.total_inspections);
          sessionStorage.setItem("is_trial_company", data.is_trial_company);
          getCompanyInfo(
            data.company_id,
            data.user_id,
            data.role_data.role_id,
            data.role_data.role_id,
            data.role_data.role_display_name
          );
        } else {
          toast.error(message);
          setOtp("");
          setLoading(false);
          console.log("else", message);
        }
      },
      formdata,
      "OTP_LOGIN"
    );
  };

  const resendOTP = () => {
    setAutoLoginLoading(true);
    const formdata = { user_id: sessionStorage.getItem("user_id") };
    apiCall(
      (response) => {
        const { message } = response.data;
        if (response.data.success) {
          toast.success(message);
          setOtp("");
          setAutoLoginLoading(false);
        } else {
          toast.error(message);
          setOtp("");
          setAutoLoginLoading(false);
          console.log("else", response.data.message);
        }
      },
      formdata,
      "RESEND_OTP"
    );
  };

  return (
    <>
      {sessionStorage.getItem("token") ? (
        navigate("/company")
      ) : !sessionStorage.getItem("user_id") ? (
        navigate("/")
      ) : (
        <>
          <Preloader showPreloader={autoLoginLoading} />
          <div className={`${matches ? "background" : "background-mobile"}`}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              {/* palm tech logo */}
              <img
                className={`${matches ? "logo" : "logo-mobile"}`}
                src={logo}
                alt="logo"
              ></img>
              <Paper
                className="paper-normal"
                sx={{
                  minWidth: { xs: 370, md: 390, lg: 390 },
                  minHeight: { xs: 300, md: 300, lg: 320 },
                  boxShadow: "0 30px 30px 0 rgba(40, 40, 40, 0.4)",
                }}
              >
                <ArrowBackOutlinedIcon
                  className="back_signup_button"
                  onClick={homePage}
                />
                <Grid className="center">
                  <Typography
                    className="header"
                    sx={{
                      mt: "30px",
                      fontSize: { xs: "24px", md: "22px", lg: "22px" },
                    }}
                  >
                    TWO-FACTOR AUTHENTICATION
                  </Typography>
                </Grid>
                <Grid className="center" sx={{ p: 3, maxWidth: 390 }}>
                  <Alert severity="info">
                    Please enter the 6 digit code sent to your registered email
                  </Alert>
                </Grid>
                <Grid className="center">
                  <FormControl variant="standard">
                    <InputLabel
                      htmlFor="component-simple"
                      className="input_lable"
                      sx={{
                        top: -10,
                        fontSize: { xs: "14px", md: "12px", lg: "12px" },
                      }}
                    >
                      Verification code
                    </InputLabel>
                    <TextField
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && !loading && otp?.length === 6)
                          OTPLogin();
                      }}
                      value={otp}
                      type="text"
                      id="otp_number"
                      variant="outlined"
                      className="input_field"
                      onChange={handleOTPChange}
                      placeholder="Enter verification code"
                      sx={{
                        marginTop: "35px",
                        width: { xs: "340px", md: "317px", lg: "317px" },
                      }}
                      InputProps={{
                        className: "input_props_field",
                        sx: {
                          height: { xs: "55px", md: "40px", lg: "40px" },
                          fontSize: { xs: "18px", md: "14px", lg: "14px" },
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid className="center">
                  <LoadingButton
                    disabled={otp?.length === 6 ? false : true}
                    className="login_button"
                    sx={{
                      mt: "20px",
                      mb: 3,
                      height: { xs: 42, md: 34, lg: 34 },
                      width: { xs: 180, md: 119, lg: 119 },
                      fontSize: { xs: 16, md: 13, lg: 13 },
                    }}
                    size="small"
                    variant="contained"
                    loading={loading}
                    onClick={() => OTPLogin()}
                  >
                    Validate
                  </LoadingButton>
                </Grid>
                <Grid className="center">
                  <Typography
                    onClick={() => resendOTP()}
                    className="link_signup"
                    fontSize={{
                      xs: "16px",
                      md: "12px",
                      lg: "12px",
                      xl: "12px",
                    }}
                  >
                    Resend email
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          </div>
        </>
      )}
    </>
  );
};

export default OTPLogin;
