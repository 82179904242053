import {  Button, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AlertImage from "../../../../../assets/images/alert.png";
// dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import toast from "react-hot-toast";
import { apiCall } from "../../../../utils/action";
import { useNavigate } from "react-router-dom";

const PtEditTemplateModal = ({
  openEditModal,
  handleCloseEditModal,
  selectedTemplateName,
  selectedTemplate
}) => {
  const navigate = useNavigate();
  const editTemplate=(template)=>{
    const formdata = { template_guid:template.guid , is_pdf_template: template.is_pdf_template };
    apiCall(
    (response) => {
      const {  data,message, success } = response.data;
      if (success) {
        handleCloseEditModal(true)
        navigate(`/template/${encodeURIComponent(data.name)}`, {
          state: {
            guid: data.guid,
            name: data.name,
            version: data.version,
            isPdfTemplate:data.is_pdf_template,
            edit: true,
          },})
      } else {
        handleCloseEditModal(true)
        toast.error(message);
      }
    },
    formdata,
    "PT_EDIT_TEMPLATE"
  );
  }
  return (
    <Dialog
      open={openEditModal}
      onClose={handleCloseEditModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="pt-delete-template-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <CloseIcon className="close-icon" onClick={handleCloseEditModal} />
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} sx={{ textAlign: "center",mt:'20px' }}>
          <img
            src={AlertImage}
            alt="alert"
            style={{ width: "51px", height: "49px" }}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography className="template_delete_modal_text">
            {`The template: `}
            <b style={{ fontSize: "22px", wordWrap: "break-word" }}>
              {selectedTemplateName}
            </b>
            {` has been published`}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              color: "#333333",
              fontFamily: "open sans",
              fontSize: "14px",
              mt: "15px",
              letterSpacing: 0,
            }}
          >
            You are creating a draft copy to work in. When you have finished editing, choose "Save and 
           Publish" to make it available for your inspections.
          </Typography>
        </Grid>
        <DialogContentText id="alert-dialog-description">
          {/* This action cannot be undone. */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ ":hover": { backgroundColor: "transparent !important" } }}
          className="template-delete-modal-cancel-btn"
          onClick={handleCloseEditModal}
        >
          Cancel
        </Button>
        <Button
          sx={{ ":hover": { backgroundColor: "#1E8970 !important" } }}
          className="default-btn-template-delete-modal"
          variant="contained"
          color="error"
            onClick={() => {editTemplate(selectedTemplate)}}
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PtEditTemplateModal;
