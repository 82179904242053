import toast from "react-hot-toast";
import PlanDetails from "./PlanDetails";
import UpgradePlan from "./UpgradePlan";
import PaymentMethod from "./PaymentMethod";
import { useEffect, useState } from "react";
import { apiCall } from "../Services/action";
import BillingHistory from "./BillingHistory";
import CloseIcon from "@mui/icons-material/Close";
import Preloader from "../../../helpers/Preloader";
import BillingInformation from "./BillingInformation";
import "../../../../assets/styles/containers/_billingplan.scss";
import { useLocation, useSearchParams } from "react-router-dom";
import Checkout from "./Checkout";
import { Box, Tabs, Tab, Typography, Button, Modal, Grid, Radio } from "@mui/material";
import Licenses from "./Licenses";

const BillingPlanLayout = (props) => {
  const JungleGreen = "#239D80";
  const location = useLocation();
  const { tabValue, setTabValue } = props;
  const selectedColor = "2px solid #1f9c7b";
  const [additionalLicense, setAdditionalLicense] = useState(0);
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);
  const closeUpgradeModal = () => setUpgradePlanModal(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showLoader, setShowLoader] = useState(false);
  const [open, setOpen] = useState(location.state ? location.state.fromInspectionSubscribe : false);
  const [selectedPlan, setSelectedPlan] = useState({ defaultSelected: "", newSelected: "", publicURL: "", currentPlan: false });
  const [details, setDetails] = useState({
    subscriptionTitle: "",
    subscriptionPrice: "",
    estimatedTaxes: "",
    discountPrice: 0,
    subscriptionID: "",
    additionalLicensePrice: "",
  });
  const handlePlanChange = (title, url, value) => {
    if (value?.disable !== 1) {
      setSelectedPlan({ ...selectedPlan, newSelected: title, publicURL: url, currentPlan: value?.current_plan === 1 ? true : false, selectedPlanID: value?.product_id });
      setDetails({ ...details, subscriptionTitle: title, subscriptionPrice: value?.price, subscriptionID: value?.product_id, estimatedTaxes: value?.taxes, discountPrice: value?.coupon_code_discount_price ? value?.coupon_code_discount_price : 0, additionalLicensePrice: value?.additional_license_price });
    }
  };
  //data vars
  const [allPlans, setAllPlans] = useState();
  const [refresh, setRefresh] = useState(true);
  const [billingURL, setBillingURL] = useState();
  const [billingInfo, setBillingInfo] = useState();
  const [planDetails, setPlanDetails] = useState();
  const [paymentMethod, setPaymentMethod] = useState();
  const [licenseDetails, setLicenseDetails] = useState();
  const [billingHistory, setBillingHistory] = useState([]);
  const [proratedCheckoutAmount, setProratedCheckoutAmount] = useState();
  const [isTrailCompany, setIsTrailCompany] = useState({ isTrail: 0, remainingInspectionCount: 0 });

  const previewSubscription = () => {
    const formdata = { company_id: sessionStorage.getItem("company_id"), user_id: sessionStorage.getItem("user_id"), role_id: sessionStorage.getItem("role_id"), product_id: details?.subscriptionID };
    apiCall(
      (response) => {
        const { message, success, data } = response.data;
        if (success) {
          setProratedCheckoutAmount(data?.price);
        } else {
          console.log("else ", message);
        }
      },
      formdata,
      "PREVIEW_SUBSCRIPTION"
    );
  };

  const openUpgradeModal = () => {
    previewSubscription();
    if (isTrailCompany?.isTrail === 1) {
      window.open(selectedPlan?.publicURL, "_blank");
    } else {
      setUpgradePlanModal(true);
      setOpen(false);
    }
  };
  const GetBillingInfo = () => {
    setShowLoader(true);
    const formdata = { user_id: sessionStorage.getItem("user_id"), role_id: sessionStorage.getItem("role_id"), company_id: sessionStorage.getItem("company_id"), subscription_id: searchParams.get("id") };
    if (searchParams.has("id")) {
      searchParams.delete("id");
      setSearchParams(searchParams);
    }
    apiCall(
      (response) => {
        const { success, data, message } = response.data;
        if (success) {
          setShowLoader(false);
          setAllPlans(data.plans);
          setLicenseDetails(data?.license);
          setPlanDetails(data.plan_details);
          setAdditionalLicense(data.plan_details?.additional_license_count);
          setPaymentMethod(data.payment_method);
          setBillingURL(data.billing_portal_link);
          setBillingHistory(data.billing_history);
          setBillingInfo(data.billing_information);
          setIsTrailCompany({ isTrail: data?.is_trial_company, remainingInspectionCount: data?.remaining_inspection_count });
          setSelectedPlan({ defaultSelected: data?.plan_details?.title, defaultPlanID: data?.plan_details?.product_id, newSelected: data?.plan_details?.title, currentPlan: true, selectedPlanID: data?.plan_details?.product_id });
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      formdata,
      "BILLING_INFO"
    );
  };

  useEffect(() => {
    GetBillingInfo();
    // eslint-disable-next-line
  }, [refresh]);

  return (
    <>
      <Preloader showPreloader={showLoader} />
      <div className="detail-card">
        <div className="layout">
          <Typography className="layout__header" component="div">
            <Box className="layout__title">Billing Plan</Box>
            <Box className="layout__description">Manage your Palmtech subscription and payment details. </Box>
          </Typography>
          <Box className="layout__tabs">
            <Tabs className="layout__tabs__select" TabIndicatorProps={{ style: { backgroundColor: JungleGreen } }} value={tabValue}>
              <Tab className="layout__tabs__selected" label="Overview" sx={{ opacity: tabValue !== 0 && 0.6 }} onClick={() => setTabValue(0)} />
              <Tab className="layout__tabs__selected" sx={{ opacity: tabValue !== 1 && 0.6 }} label="Billing History" onClick={() => setTabValue(1)} />
            </Tabs>
          </Box>
          {tabValue === 0 ? (
            <>
              <PlanDetails planState={paymentMethod?.subscription_state} data={planDetails} setRefresh={setRefresh} refresh={refresh} setPlanModal={setOpen} setShowLoader={setShowLoader} trailDetails={isTrailCompany} />
              {isTrailCompany.isTrail === 1 ? (
                ""
              ) : (
                <>
                  <Licenses data={planDetails} licenseDetails={licenseDetails} refresh={refresh} setRefresh={setRefresh} planState={paymentMethod?.subscription_state} />
                  <PaymentMethod data={paymentMethod} billingURL={billingURL} />
                  <BillingInformation data={billingInfo} billingURL={billingURL} />
                </>
              )}
            </>
          ) : (
            <BillingHistory data={billingHistory} />
          )}
        </div>
        <div className="layout2">
          <UpgradePlan allPlans={allPlans} planDetails={planDetails} trailDetails={isTrailCompany} setOpen={setOpen} refresh={refresh} setRefresh={setRefresh} />
        </div>
      </div>
      {/* see all plans */}
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "720px !important", minHeight: "435px !important", top: "30% !important" }}>
          <Grid container>
            <Grid item xs={6}>
              <p className="upgrade-modal-title">All plans</p>
            </Grid>
            <Grid item xs={6}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => setOpen(false)} />
            </Grid>
            <Grid item xs={12}>
              <div className="upgrade-modal-container">
                <Grid container spacing={1}>
                  {allPlans?.map((value) => {
                    return (
                      <Grid item xs={12} md={6} lg={6} sx={{ opacity: value?.disable === 1 ? "50%" : "100%" }}>
                        <div className="selected-plan-container" onClick={() => handlePlanChange(value?.title, value?.public_signup_page_url, value)} style={{ border: selectedPlan.selectedPlanID === value?.product_id ? selectedColor : "" }}>
                          <Radio disableTouchRipple className="selected-radio" disabled={{ userSelect: value?.disable === 1 ? true : false }} checked={selectedPlan.selectedPlanID === value?.product_id} onChange={() => handlePlanChange(value?.title, value?.public_signup_page_url, value)} />
                          <Grid container sx={{ pl: 3, pr: 2 }}>
                            <Grid item xs={12}>
                              {value?.current_plan === 1 ? <Button className="current-plan-button">Current plan</Button> : ""}
                            </Grid>
                            <Grid item xs={12}>
                              <p className="monthly-plan">{value?.title}</p>
                            </Grid>
                            <Grid item xs={12} sx={{ pr: 2 }}>
                              <p className="no-hidden-fees-free">
                                {value?.description}
                                {value?.plan_details_url ? (
                                  <a className="see-details" href={value?.plan_details_url} target="_blank" rel="noreferrer">
                                    See details
                                  </a>
                                ) : (
                                  ""
                                )}
                              </p>
                            </Grid>
                            <Grid item xs={12} sx={{ pr: 1 }}>
                              {value?.coupon_code_discount_price ? (
                                <span className="discount_value_allplan_container">
                                  <span className="discount_value_allplan_text">${value?.price}</span>
                                </span>
                              ) : (
                                ""
                              )}
                              <div style={{ display: "flex", marginTop: value?.coupon_code_discount_price ? "-15px" : "-7px" }}>
                                <p className="amount">${value?.coupon_code_discount_price ? parseInt(value?.price) - parseInt(value?.coupon_code_discount_price) : value?.price}</p>
                                <p className="a-user">{value?.price_description}</p>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    );
                  })}
                  <Grid item xs={12}>
                    <p className="upgrade-plan-button-info">All pricing models include 100% of Palmtech's software features.</p>
                  </Grid>
                  <Grid item xs={12}>
                    <Button disabled={selectedPlan?.currentPlan} className="upgrade-plans-button-checkout" onClick={openUpgradeModal}>
                      Go to checkout
                    </Button>
                    <Button className="upgrade-plans-button-cancel" onClick={() => setOpen(false)}>
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Checkout proratedCheckoutAmount={proratedCheckoutAmount} upgradePlanModal={upgradePlanModal} closeUpgradeModal={closeUpgradeModal} details={details} setRefresh={setRefresh} refresh={refresh} additionalLicense={additionalLicense} setUpgradePlanModal={setUpgradePlanModal} />
    </>
  );
};

export default BillingPlanLayout;