import { useState } from "react";
import { apiCall } from "../utils/action";
import { useIdleTimer } from "react-idle-timer";
import { Navigate, useNavigate } from "react-router-dom";

export const ProtectedRoutes = (props) => {
  const navigate = useNavigate();
  const [count, setCount] = useState(0);

  const logOutSession = () => {
    const formdata = { token: sessionStorage.getItem("token") };
    apiCall(
      (response) => {
        if (response.data.success) {
          sessionStorage.clear();
          navigate("/", { state: { message: "The account has been logged out due to inactivity.Please login again." } });
        } else {
          // console.log("else", response.data.message);
        }
      },
      formdata,
      "LOGOUT"
    );
  };

  const onIdle = () => {
    logOutSession();
  };

  const onMessage = () => {
    setCount(count + 1);
  };

  useIdleTimer({
    onIdle,
    onMessage,
    timeout: 5400000,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  return sessionStorage.getItem("token") ? props.route : <Navigate replace to={"/"} />;
};
