import React, { useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import MapBox from "../../../components/InspectionDetails/MapBox";
import DateSelector from "../../../components/dateSelector/DateSelector";
import Maps from "../../../common/mapbox/Maps"
import moment from "moment";
import { GetTabWidth } from "../../../utils/services";

const DailyRoute = (props) => {
  const { isMobile, dateWiseInspections, GetInspectionDateWise} = props;
  const mapContainer = useRef(null);
  const map = useRef(null);
  const inspections = dateWiseInspections?.Inspections_details;
  const companyAddress = dateWiseInspections?.company_details;
  const [value, setValue] = React.useState(0);
  const [userAddress, setUserAddress] = React.useState([]);
  const [reCheck, setRecheck] = React.useState(0)

  const handleChange = (event, newValue) => {
    inspections.find((ele, i) =>
      ele.first_name === event.target.innerText
        ? setUserAddress(ele.inspections)
        : ""
    );
    setValue(newValue);
    // sessionStorage.setItem("cords", [])
  };

  const address = {
    inspectionid: 112,
    address: "101 BANK ST",
    city: "Milltown",
    state: "Wi",
    zip: "54858",
  };

  const fetchAddress = (address, city, state, zip) => {
    console.log(address, city, state, zip)
  }

  const dashboardLocation=()=>{
    mapContainer.current.requestFullscreen()
  }

  // console.log(inspections)

  inspections?.length > 0 && inspections[value]?.inspections?.length > 0 && inspections[value]?.inspections.sort(function(a,b){

    return new Date(b.start_time) - new Date(a.start_time);
  });
  return (
    <Box sx={{ mt: isMobile && "48px !important" }} className="route">
      <Box className="route__header">
        <Typography
          sx={{ flexDirection: isMobile ? "column" : "row" }}
          className="route__header__title"
        >
          <div>Daily route</div>
          <DateSelector isMobile={isMobile} handleCheck = {() => setRecheck(reCheck+1)} reCheck={reCheck} GetInspectionDateWise={GetInspectionDateWise} />
        </Typography>
        <div id="dashboard-seefullmap" className="route__header__link"  ref={mapContainer}>
          <OpenInNewIcon sx={{ color: "#4282e2", width: "16px", mr: "12px" }}  onClick={() => dashboardLocation()} />
          <span  onClick={() => dashboardLocation()} >See full map</span>
        </div>
      </Box>

      <Box sx={{ mt: "40px" }}>
        {inspections?.length > 0 && (
          <Tabs
            value={value}
            onChange={handleChange}
            // onLoad={ handleChange}
            className="tabs"
            allowScrollButtonsMobile
            scrollButtons={inspections?.length > 4 ? true : false}
            variant="scrollable"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#239d80",
              },
            }}
            // scrollButtons={false}
            aria-label="scrollable prevent tabs example"
          >
            {inspections?.map((label, index) => {
              return (
                <Tab
                  className="tabs__list"
                  name={`tabs_name${index}`}
                  id={`tabs_id${index}`}
                  sx={{
                    opacity: value !== index && 0.6,
                    width: GetTabWidth(inspections?.length),
                    maxWidth: "unset",
                    alignItems: inspections?.length <= 2 && 'flex-start'
                  }}
                  label={label.first_name?.length>25 ? `${label.first_name?.substring(25, 0)}... `:label.first_name}
                />
              );
            })}
          </Tabs>
        )}
      </Box>
      <Grid
        sx={{ flexDirection: isMobile && "column", pl: isMobile && "0px" }}
        className="address"
      >
        <div className="address__details">
          <grid className="address__details__list">
            {inspections?.length > 0 && inspections[value]?.inspections?.length > 0 ? (
              inspections[value]?.inspections?.map((address, i) => {
                return (
                  <div style={{cursor: "pointer"}} onClick = {() => fetchAddress(address.location_address,address.location_city,address.location_state,address.location_zip)}>
                    <div>
                      <span
                        style={{
                          fontSize: "24px",
                          position: "relative",
                          top: "3px",
                        }}
                      >
                        •
                      </span>
                      {moment(moment.utc(address?.start_time).local()).format("h:mm a")} <span 
                      style={{backgroundColor:"#FFECD2",color:"#F35F01",  margin:" 0px 8px", fontSize: '12px', fontWeight: 'bold', textTransform: 'uppercase'}}
                      >{address?.status==="Draft"? address?.status: ""}</span>
                    </div>
                    <p style={{width:'250px'}}>
                      {address.location_address}{" "}
                      {address.location_city !== null && address.location_city}{' '}
                      {address.location_state !== null &&
                        address.location_state}{" "}
                      {address.location_zip !== null && address.location_zip}
                    </p>
                  </div>
                );
              })
            ) : (
              <p>No inspections scheduled</p>
            )}
          </grid>
        </div>
        <div
          style={{ width: isMobile ? "100%" : "741px", height: "252px" }}
          className="address__maps"
        >
          {/* <MapBox type={"dashboard"} address={address} /> */}
          <Maps
          mapContainer={mapContainer}
          companyAddress={companyAddress}
          inspections={inspections?.length > 0 && inspections[value]}
          locations={inspections?.length > 0 && inspections[value]?.inspections}
          />
        </div>
      </Grid>
    </Box>
  );
};

export default DailyRoute;
