//file imports
import Navbar from "./Navbar";
import toast from "react-hot-toast";
import { AppContext } from "../../App";
import { apiCall } from "../../utils/action";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Preloader from "../../helpers/Preloader";
import MailIcon from "@mui/icons-material/Mail";
import Settings from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import BugReportIcon from "@mui/icons-material/BugReport";
import SearchIcon from "@mui/icons-material/Search";
import { BootstrapTooltip } from "../../utils/services";
import "../../../assets/styles/containers/_header.scss";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LogoVertical from "../../../assets/images/logo.png";
import Logo from "../../../assets/images/logo-horizontal.png";
import AccountCircle from "@mui/icons-material/AccountCircle";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  AppBar,
  Box,
  MenuList,
  styled,
  Toolbar,
  Alert,
  Button,
  Avatar,
  IconButton,
  useMediaQuery,
  Divider,
  ListItemIcon,
  Typography,
  InputBase,
  Badge,
  MenuItem,
  Menu,
} from "@mui/material";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
}));

const Header = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { setReload } = useContext(AppContext);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  // const isTaborMobile = useMediaQuery('(min-width:900px)');
  const isQueryBreakPoint = useMediaQuery("(max-width:750px");
  const [showLoader, setShowLoader] = useState();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  let navigate = useNavigate();

  const routeChange = (roleId) => {
    roleId === 2 || roleId === 3 || roleId === 4
      ? navigate(`/company`)
      : roleId === 10 || roleId === 11
      ? navigate(`/import`)
      : navigate(`/dashboard`);
    setReload((prev) => !prev);
  };
  // authentication
  const roleId = sessionStorage.getItem("role_id");
  let permission = roleId === "7" ? false : true;
  let ptPermission = roleId === "4" ? false : true;

  let PTadminFlag =
    roleId === "2" || roleId === "3" || roleId === "4" ? true : false;

  let customerPermission =
    roleId === "2" || roleId === "3" || roleId === "10" || roleId === "11"
      ? true
      : false;
  // let userName = sessionStorage.getItem("user_name")?.split(" ");
  // let firstName = sessionStorage.getItem("first_name")?.split(" ");
  // let last_name = sessionStorage.getItem("last_name")?.split(" ");
  const impersinated = sessionStorage.getItem("impersinated");
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const signOut = () => {
    setShowLoader(true);
    handleMenuClose();
    const formdata = { token: sessionStorage.getItem("token") };
    apiCall(
      (response) => {
        console.log("LOGOUT", response);
        if (response.data.success) {
          toast.success(response.data.message);
          sessionStorage.clear();
          navigate("/");
          setShowLoader(false);
        } else {
          console.log("else", response.data.message);
          // nedd to handle the unauthorised condition here fro now the response toast is commented
          // toast.error(response.data.message);
          sessionStorage.clear();
          navigate("/");
          setShowLoader(false);
        }
      },
      formdata,
      "LOGOUT"
    );
  };

  const stopImpersonation = () => {
    setShowLoader(true);
    const formdata = {
      persona_id: sessionStorage.getItem("user_id"),
      impersonator_id: sessionStorage.getItem("impersonator_id"),
    };
    apiCall(
      (response) => {
        const { message, success, data } = response.data;
        if (success) {
          toast.success(message);
          sessionStorage.clear();
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("user_id", data.user_id);
          sessionStorage.setItem("token_id", data.tokenId);
          sessionStorage.setItem("user_name", data.user_name);
          sessionStorage.setItem("last_name", data.last_name);
          sessionStorage.setItem("first_name", data.first_name);
          sessionStorage.setItem("company_id", data.company_id);
          sessionStorage.setItem("is_from_us", data.is_from_us);
          sessionStorage.setItem("role_id", data.role_data.role_id);
          sessionStorage.setItem("total_insp", data.total_inspections);
          sessionStorage.setItem("is_trial_company", data.is_trial_company);
          getCompanyInfo(
            data.company_id,
            data.user_id,
            data.role_data.role_id,
            data.role_data.role_id,
            data.role_data.role_display_name
          );
          console.log(data, "data");
          setShowLoader(false);
        } else {
          console.log("else", response.data.message);
          setShowLoader(false);
        }
      },
      formdata,
      "STOP_IMPERSONATE"
    );
  };

  const getCompanyInfo = (company_id, user_id, role_id, route, role) => {
    const formdata = {
      company_id: company_id,
      user_id: user_id,
      role_id: role_id,
    };
    apiCall(
      (response) => {
        console.log("GET_COMPANY_INFO", response);
        if (response.data.success) {
          sessionStorage.setItem(
            "company_name",
            response.data.data.company_details.name
          );
          sessionStorage.setItem(
            "show_banner",
            response.data.data.company_details.show_banner
          );
          sessionStorage.setItem("is_trial", response.data.data.is_trial);
          routeChange(route);
          toast.success(`Logged in as ${role}`);
        } else {
          console.log("else", response.data.message);
        }
      },
      formdata,
      "GET_COMPANY_INFO"
    );
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      className="profile-menuitem"
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: -1,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 34,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <MenuList style={{ display: "flex", flexDirection: "column" }}>
        {!PTadminFlag && !customerPermission && (
          <MenuItem
            id="settings"
            name="settings"
            onClick={() => {
              handleMenuClose();
              navigate("/settings");
            }}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
        )}
        {!permission && (
          <MenuItem
            id="my__pt_profile"
            name="my_pt_profile"
            onClick={() => {
              handleMenuClose();
              navigate("/my-profile");
            }}
          >
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            My Profile
          </MenuItem>
        )}
        {roleId === "2" || roleId === "3" ? (
          <MenuItem
            id="my_profile"
            name="my_profile"
            onClick={() => {
              handleMenuClose();
              navigate("/error-logs");
            }}
          >
            <ListItemIcon>
              <BugReportIcon fontSize="small" />
            </ListItemIcon>
            Error logs
          </MenuItem>
        ) : (
          ""
        )}
        {!ptPermission && (
          <MenuItem
            id="my_profile"
            name="my_profile"
            onClick={() => {
              handleMenuClose();
              navigate("/profile");
            }}
          >
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            My Profile
          </MenuItem>
        )}
        {/* {permission && (
                <MenuItem
                    onClick={() => {
                        handleMenuClose();
                        navigate('/settings/team');
                    }}>
                    <ListItemIcon>
                        <ReceiptIcon fontSize="small" />
                    </ListItemIcon>
                    Team
                </MenuItem>
            )} */}
        {/* <MenuItem id="contact_us" name="contact_us"
            // onClick={() => { handleMenuClose();}}
            >
                <ListItemIcon>
                    <ForumIcon fontSize="small" />
                </ListItemIcon>
                Contact us
            </MenuItem> */}
        <Divider />
        <MenuItem onClick={signOut} id="sign_out" name="sign_out">
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </MenuList>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuList style={{ display: "flex", flexDirection: "column" }}>
        <MenuItem>
          <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <Badge badgeContent={4} color="error">
              <MailIcon />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
        {/* <MenuItem>
                <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
                    <Badge badgeContent={17} color="error">
                        <NotificationsIcon />
                    </Badge>
                </IconButton>
                <p>Notifications</p>
            </MenuItem> */}
        <MenuItem onClick={handleProfileMenuOpen}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <KeyboardArrowDownIcon />
          <p>Profile</p>
        </MenuItem>
      </MenuList>
    </Menu>
  );
  return (
    <div className="header-fixed">
      <Preloader showPreloader={showLoader} />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" className="navbar">
          <Toolbar>
            <IconButton
              id="pt_logo"
              name="pt_logo"
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              className="main-logo"
              onClick={() => {
                PTadminFlag ? navigate("/company") : navigate("/dashboard");
              }}
              disableRipple
            >
              <img
                src={!isQueryBreakPoint ? Logo : LogoVertical}
                className={
                  !isQueryBreakPoint ? "horizontal-logo" : "verical-logo"
                }
                alt="logo"
              />
            </IconButton>
            <Box sx={{ flexGrow: 1 }} />
            {/* Searchbar for large Screen and hidden in small screen */}
            <Box className="header-search-wrapper">
              <Search className="header-search-bar">
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                />
              </Search>
            </Box>
            <Box>
              {/* <IconButton id="notification" name="notification" size="large" aria-label="show 17 new notifications" color="inherit" className="notification-icon" disableRipple>
                                <Badge badgeContent={17} color="error" className="notification-badge">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton> */}
              <IconButton
                id="user_settings"
                name="user_settings"
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                className="profile-icon-wrapper"
                disableRipple
              >
                <Avatar sx={{ bgcolor: "white" }}>
                  <PersonIcon className="navbar-profile-icon" />
                </Avatar>
                {/* <Typography className={!isQueryBreakPoint ? 'user-name global-font' : 'user-name-light global-font'}>{userName[0]}</Typography> */}
                <BootstrapTooltip
                  title={
                    <h1 className="font-13">
                      {sessionStorage.getItem("user_name")}
                    </h1>
                  }
                >
                  <span style={{ whiteSpace: "pre-line" }}>
                    <Typography
                      className={
                        !isQueryBreakPoint
                          ? "user-name global-font"
                          : "user-name-light global-font"
                      }
                    >
                      {sessionStorage.getItem("user_name") === "null"
                        ? " "
                        : sessionStorage.getItem("user_name")?.length > 15
                        ? `${sessionStorage
                            .getItem("user_name")
                            ?.substring(15, 0)}...`
                        : `${sessionStorage.getItem("user_name")}`}
                    </Typography>
                  </span>
                </BootstrapTooltip>
                <KeyboardArrowDownIcon
                  sx={{ fontSize: "18px", marginTop: "2px" }}
                />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
      {impersinated ? (
        <Alert
          action={
            <Button
              onClick={stopImpersonation}
              sx={{ fontWeight: 700, fontSize: 11, mr: 2 }}
              size="small"
            >
              STOP IMPERSONATION
            </Button>
          }
          severity="info"
        >
          Impersonation started
        </Alert>
      ) : (
        ""
      )}
      <Navbar
        permission={permission}
        PTadminFlag={PTadminFlag}
        roleId={roleId}
        ptPermission={ptPermission}
        customerPermission={customerPermission}
      />
    </div>
  );
};

export default Header;
