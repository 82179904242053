import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import validator from "validator";
// material ui imports
import { Grid, Button} from "@mui/material";
import Box from "@mui/material/Box";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import FormControl from "@mui/material/FormControl";
import EditIcon from "@mui/icons-material/Edit";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
// component imports
import Breadcrumb from "../../../common/header/Breadcrumb";
import Header from "../../../common/header/Header";
import Preloader from "../../../helpers/Preloader";
import { onKeyPressed,formatPhoneNumberDashes,formatZip } from "../../../utils/services";
import { apiCall } from "../../../utils/action";

const PtSupportProfile = () => {
  const [role, setRole] = useState("");
  const [userData, setUserData] = useState({});
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState({email:'',edit: false});
  const [tempMail,setTempMail] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState({phone:'',edit:false});
  const [tempPhone,setTempPhone] = useState('')
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [homeAddress, setHomeAddress] = useState("");
  const [showLoader, setShowLoader] = useState();
  const [image, setImage] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorText, setFirstNameErrorText] = useState("");
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorText, setLastNameErrorText] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [cityError, setCityError] = useState(false);
  const [cityErrorText, setCityErrorText] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [phoneErrorText, setPhoneErrorText] = useState("");
  const [stateError, setStateError] = useState(false);
  const [stateErrorText, setStateErrorText] = useState("");
  const [zipError, setZipError] = useState(false);
  const [zipErrorText, setZipErrorText] = useState("");
  const [homeAddressError, setHomeAddressError] = useState(false);
  const [homeAddressErrorText, setHomeAddressErrorText] = useState("");
  const navigate = useNavigate();
  const fileInput = React.useRef();
  const uuId = sessionStorage.getItem("user_id");
  const companyId = sessionStorage.getItem("company_id");
  const role_nameId = sessionStorage.getItem("role_id");
  let PTadminFlag = (role_nameId === '2' || role_nameId === '3' ||role_nameId === '4')?true :false

//   get support profile datas API call
  const getPtSupportProfileData =()=>{
    setShowLoader(true);
    toast.dismiss();
    const formdata = { company_id: companyId ,user_id:uuId, role_id:role_nameId };
    apiCall(
      (response) => {
        console.log("GET_PT_SUPPORT_PROFILE", response);
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          setUserData(data);
          setFirstName(data.first_name);
          setLastName(data.last_name);
          setState(data.state);
          setZip(data.zip);
          setEmail({email:data.email,edit:false});
          setTempMail(data.email);
          setCity(data.city);
          setPhone({phone:data.phone,edit:false});
          setTempPhone(data.phone);
          setHomeAddress(data.address_1);
          setImage(data.avatar);
          setShowLoader(false);
        } else {
          console.log("else ", message);
        }
      },
      formdata,
      "GET_PT_SUPPORT_PROFILE"
    );
  }

//   update support profile api call
  const updatePtSupportProfile = ()=>{
    setShowLoader(true);
    toast.dismiss();
    let formData = new FormData();
    let params;
    // conditions to pass mail and phone params only if they are edited
    if(phone.edit && phone.phone !== tempPhone){
         params = {
          company_id: companyId,
          user_id: uuId,
          role_id: role_nameId,
          first_name: firstName,
          last_name: lastName,
          phone:phone.phone=== null? '':phone.phone,
          member_role_id: role,
          address_1: homeAddress ===null?'': homeAddress,
          city:city === null?'':city,
          state:state===null?'':state,
          zip: zip===null?'':zip,
          avatar: typeof image === "string" || image === null ? "" : image,
        }
    }else{
      params = {
        company_id: companyId,
        user_id: uuId,
        role_id: role_nameId,
        first_name: firstName,
        last_name: lastName,
        member_role_id: role,
        address_1: homeAddress ===null?'': homeAddress,
        city:city === null?'':city,
        state:state===null?'':state,
        zip: zip===null?'':zip,
        avatar: typeof image === "string" || image === null ? "" : image,
      };
    }
    
   
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    apiCall(
      (response) => {
        console.log("EDIT MEMBER", response);
        const { message, success } = response.data?response.data:'';
        if (success) {
          toast.success(message);
          setShowLoader(false);
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      formData,
      'UPDATE_PT_SUPPORT_PROFILE'
    );
  }

  const validation = (edit) => {
    if (firstName === '' || firstName === null) {
      setFirstNameError(true);
      setFirstNameErrorText("First name is required");
    } else if (!validator.isLength(firstName, { min: 3, max: undefined })) {
      setFirstNameError(true);
      setFirstNameErrorText("Minimum 3 characters required");
    } else setFirstNameError(false);   
    if(email.email === '' || email.email === null){
      setEmailError(true);
      setEmailErrorText("Email is required");
    }
    else if (!validator.isEmail(email.email)) {
      setEmailError(true);
      setEmailErrorText("Enter correct email format");
    } else setEmailError(false);
   
    
    if (firstName !== '' && firstName !== null &&
    // lastName !== '' && lastName !== null && 
    validator.isLength(firstName, { min: 3, max: undefined }) &&
    email.email !== '' && email.email !== null &&
    validator.isEmail(email.email) 
    ) 
    updatePtSupportProfile()
  };


  useEffect(() => {
   getPtSupportProfileData()
  }, []);

  
  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumberDashes(e.target.value);
    setPhone({phone:formattedPhoneNumber,edit:true});
  };
  const handleZipChange=(e)=>{
    const formattedZip = formatZip(e.target.value);
    setZip(formattedZip);
  }

  // card components
  const card = (
    <>
      <CardHeader
        action={<EditIcon onClick={() => fileInput.current.click()} />}
        title="Profile"
        className="team-profile-header"
      />
      <input
        ref={fileInput}
        type="file"
        accept="image/*"
        onChange={(e) => setImage(e.target.files[0])}
        style={{ display: "none" }}
      />
      {image && (
        <div className="prof-img-wrapper">
          <img
            className="team-prof-img"
            style={{ maxHeight: "320px", maxWidth: "100%" }}
            src={typeof image === "string" ? image : URL.createObjectURL(image)}
            alt="Thumb"
          />
          {/* <span onClick={()=>setImage()} style={{ cursor: "pointer", padding: 5, background: "red", color: "white", border: "none" }}>
            Remove Image
          </span> */}
        </div>
      )}
      {!image && (
        <CardMedia
          component="img"
          className="team-prof-img"
          alt="/broken-image.jpg"
          src={
            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
          }
        />
      )}
    </>
  );
  const permissionCard = (
    <>
      <Grid container className="team-permission-card">
        <Grid item xs={12} sm={2}>
          <VerifiedUserIcon />
        </Grid>
        <Grid item xs={12} sm={10}>
          Permission
        </Grid>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={10} sx={{ marginTop: "7px" }}>
          <span className="team-permision-text">
            User can change settings and reschedule etc. Lorem ipsum dolor sit
            amet.
          </span>{" "}
        </Grid>
      </Grid>
    </>
  );
  const detailsCard = (
    <>
      <Grid container className="team-details-card">
        <Grid item xs={12} className="team-details-cardTitle">
          <span>Contact Info</span>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                First Name*
              </label>
              <TextField
                id="PropertyAddressId"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={firstNameError}
                helperText={`${firstNameError ? firstNameErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                Last Name
              </label>
              <TextField
                id="PropertyAddressId"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={lastNameError}
                helperText={`${lastNameError ? lastNameErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="standard"
              className="forms-control field-reset"
            >
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                Email
              </label>
              <TextField
              disabled
                id="PropertyAddressId"
                style={{background:"#f2f2f2"}}
                className="input-textfield "
                size="small"
                variant="outlined"
                placeholder="Email"
                value={email.email}
                onChange={(e) => setEmail({email:e.target.value,edit:true})}
                error={emailError}
                helperText={`${emailError ? emailErrorText : ""}`}
                type="email"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                Phone
              </label>
              <TextField
                id="PropertyAddressId"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Phone"
                value={phone.phone}
                onChange={(e)=>handlePhoneChange(e)}
                // onChange={(e) => setPhone({phone:e.target.value,edit:true})}
                error={phoneError}
                helperText={`${phoneError ? phoneErrorText : ""}`}
                type="tel"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                Home Address
              </label>
              <TextField
                id="PropertyAddressId"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Home address"
                value={homeAddress}
                onChange={(e) => setHomeAddress(e.target.value)}
                error={homeAddressError}
                helperText={`${homeAddressError ? homeAddressErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                City
              </label>
              <TextField
                id="PropertyAddressId"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="City"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                error={cityError}
                helperText={`${cityError ? cityErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                State
              </label>
              <TextField
                id="PropertyAddressId"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
                error={stateError}
                helperText={`${stateError ? stateErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} className="zip-field">
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                Zip
              </label>
              <TextField
                id="PropertyAddressId"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Zip"
                value={zip}
                onKeyDown={onKeyPressed}
                // onChange={(e) => setZip(e.target.value)}
                onChange={(e)=>handleZipChange(e)}
                error={zipError}
                helperText={`${zipError ? zipErrorText : ""}`}
                type="number"
              />
            </FormControl>
          </Grid>  
          <Grid item xs={12} sm={7} md={6} style={{ marginTop: "6px" }}>
            <label
              className="input-form-label pl-15 mb-5"
              htmlFor="Role"
              id="demo-simple-select-label"
            >
              Role
            </label>
             <FormControl fullWidth className="forms-control field-reset">
            <TextField
            disabled
            style={{background:"#f2f2f2"}}
            id="disabledRole"
            className="input-textfield"
            size="small"
            variant="outlined"
            placeholder=""
            value={"Palmtech Support"}
            type="text"
          />
          </FormControl>
          </Grid>       
          <Grid item xs={12} sm={5} md={6}></Grid>
         
           <Grid item xs={12} className="team-detail-cta">
           <Button
             className="back-btn"
             onClick={() =>{PTadminFlag?navigate("/company"): navigate("/settings/team")}}
           >
             Cancel
           </Button>
           <Button
           onClick={validation}
             className="default-btn teams-detail-btn"
           >
             Update
           </Button>
         </Grid>
        </Grid>
      </Grid>
    </>
  );
  return (
    <>
    <Header />
    <Box className="page-background">
      <Breadcrumb />
      <Preloader showPreloader={showLoader} />
      {/* ----------------------------profile section-------------------------------- */}
      <Grid container className="teams-container">
        <Grid item xs={12} className="details-title">
          <span className="team-user-name">{userData?.first_name}</span>
          <br />        
        </Grid>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={3}>
              <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
                <Card variant="outlined">{card}</Card>
              </Box>
              {/* <Box sx={{ minWidth: 145 }}>
                <Card variant="outlined">{permissionCard}</Card>
              </Box> */}
            </Grid>
            <Grid item xs={12} sm={9}>
              <Card variant="outlined">{detailsCard}</Card>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Box>
  </>
  )
}

export default PtSupportProfile