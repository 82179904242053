import React, { useState } from "react";
import toast from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@mui/icons-material/Close";
import { apiCall } from "../../../utils/action";

function ExportTemplateDialog(props) {
  const { openDialog, handleCloseDialog, showTemplateList } = props;
  const [state, setState] = useState({
    companyId: null,
    error: false,
  });

  const { companyId, error } = state;

  const handleConfirm = () => {
    if (!companyId || companyId === "") {
      setState((prevState) => ({ ...prevState, error: true }));
    } else {
      const params = {
        template_id: openDialog,
        parent_app_id: showTemplateList,
        company_key: companyId,
      };
      apiCall(
        (response) => {
          if (response.data.success) {
            toast.success(response.data.message);
            handleClose();
          } else {
            toast.error(response.data.message);
          }
        },
        params,
        "COPY_TEMPLATE"
      );
    }
  };

  const handleClose = () => {
    setState({ ...(prevState) => ({ ...prevState, companyId: null }) });
    handleCloseDialog();
  };

  return (
    <Dialog open={openDialog !== null} onClose={() => handleClose()}>
      <DialogTitle>
        <Grid container spacing={2} display={"flex"}>
          <Grid item xs={10} sm={11} display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography fontSize={18} fontWeight={500}>
              {"Export Template"}
            </Typography>
          </Grid>
          <Grid item xs={1} display={"flex"} alignItems={"center"}>
            <IconButton onClick={() => handleClose()} className="closeButton">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container display={"flex"} my={1} px={2}>
          <Grid item xs={12} mb={2}>
            <Typography fontSize={14}>{`Export this template to -`}</Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" className="forms-control">
              <label className="input-form-label" htmlFor="company">
                Company
              </label>
              <TextField
                id="companyId"
                type="text"
                error={error}
                helperText={error && "Please enter a Company Key / ID"}
                value={companyId}
                size="small"
                variant="outlined"
                placeholder="Company Key / ID"
                className="input-textfield"
                onChange={(event) =>
                  setState((prevState) => ({
                    ...prevState,
                    error: false,
                    companyId: event.target.value,
                  }))
                }
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container display={"flex"} gap={2} justifyContent={"center"} my={1}>
          <Button variant={"outlined"} onClick={() => handleClose()} sx={{ padding: "6px 28px 6px 28px" }}>
            Cancel
          </Button>
          <Button variant={"contained"} onClick={() => handleConfirm()} sx={{ padding: "6px 28px 6px 28px" }}>
            Confirm
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default ExportTemplateDialog;
