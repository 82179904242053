export const tableElArray = [
  {
    "col": 0,
    "row": 0,
    "type": 0,
    "value": false
  },
  {
    "col": 1,
    "row": 0,
    "type": 2,
    "title": "Glazed Openings",
    "value": false
  },
  {
    "col": 2,
    "row": 0,
    "type": 2,
    "title": "Glazed Openings",
    "value": false
  },
  {
    "col": 3,
    "row": 0,
    "type": 2,
    "title": "Glazed Openings",
    "value": false
  },
  {
    "col": 4,
    "row": 0,
    "type": 2,
    "title": "Glazed Openings",
    "value": false
  },
  {
    "col": 5,
    "row": 0,
    "type": 2,
    "title": "Non-Glazed Openings",
    "value": false
  },
  {
    "col": 6,
    "row": 0,
    "type": 2,
    "title": "Non-Glazed Openings",
    "value": false
  },
  {
    "col": 0,
    "row": 1,
    "type": 0,
    "value": false
  },
  {
    "col": 1,
    "row": 1,
    "type": 3,
    "title": "Windows or Entry Doors",
    "value": false
  },
  {
    "col": 2,
    "row": 1,
    "type": 3,
    "title": "Garage Doors",
    "value": false
  },
  {
    "col": 3,
    "row": 1,
    "type": 3,
    "title": "Skylights",
    "value": false
  },
  {
    "col": 4,
    "row": 1,
    "type": 3,
    "title": "Glass Block",
    "value": false
  },
  {
    "col": 5,
    "row": 1,
    "type": 3,
    "title": "Entry Doors",
    "value": false
  },
  {
    "col": 6,
    "row": 1,
    "type": 3,
    "title": "Garage Doors",
    "value": false
  },
  {
    "col": 0,
    "row": 2,
    "type": 1,
    "title": "N/A",
    "value": false,
    "onHove": "Not Applicable- there are no openings of this type on the structure"
  },
  {
    "col": 1,
    "row": 2,
    "type": 4,
    "value": false,
    "merge_id": "N/A Windows or Entry Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 2,
    "row": 2,
    "type": 4,
    "value": false,
    "merge_id": "N/A Garage Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 3,
    "row": 2,
    "type": 4,
    "value": false,
    "merge_id": "N/A Skylights Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 4,
    "row": 2,
    "type": 4,
    "value": false,
    "merge_id": "N/A Glass Block Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 5,
    "row": 2,
    "type": 4,
    "value": false,
    "merge_id": "N/A Entry Doors Non Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 6,
    "row": 2,
    "type": 4,
    "value": false,
    "merge_id": "N/A Garage Doors Non-Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 0,
    "row": 3,
    "type": 1,
    "title": "A",
    "value": false,
    "onHove": "Verified cyclic pressure & large missile (9-lb for windows doors/4.5 lb for skylights)"
  },
  {
    "col": 1,
    "row": 3,
    "type": 4,
    "value": false,
    "merge_id": "A Windows or Entry Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 2,
    "row": 3,
    "type": 4,
    "value": false,
    "merge_id": "A Garage Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 3,
    "row": 3,
    "type": 4,
    "value": false,
    "merge_id": "A Skylights Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 4,
    "row": 3,
    "type": 4,
    "value": false,
    "merge_id": "A Glass Block Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 5,
    "row": 3,
    "type": 4,
    "value": false,
    "merge_id": "A Entry Doors Non-Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 6,
    "row": 3,
    "type": 4,
    "value": false,
    "merge_id": "A Garage Doors Non-Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 0,
    "row": 4,
    "type": 1,
    "title": "B",
    "value": false,
    "onHove": "Verified cyclic pressure & large missile (4-8 lb for windows doors/2 lb for skylights)"
  },
  {
    "col": 1,
    "row": 4,
    "type": 4,
    "value": false,
    "merge_id": "B Windows or Entry Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 2,
    "row": 4,
    "type": 4,
    "value": false,
    "merge_id": "B Garage Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 3,
    "row": 4,
    "type": 4,
    "value": false,
    "merge_id": "B Skylights Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 4,
    "row": 4,
    "type": 4,
    "value": false,
    "merge_id": "B Glass Block Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 5,
    "row": 4,
    "type": 4,
    "value": false,
    "merge_id": "B Entry Doors Non-Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 6,
    "row": 4,
    "type": 4,
    "value": false,
    "merge_id": "B Garage Doors Non-Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 0,
    "row": 5,
    "type": 1,
    "title": "C",
    "value": false,
    "onHove": "Verified plywood/OSB meeting Table 1609.1.2 of the FBC 2007"
  },
  {
    "col": 1,
    "row": 5,
    "type": 4,
    "value": false,
    "merge_id": "C Windows or Entry Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 2,
    "row": 5,
    "type": 4,
    "value": false,
    "merge_id": "C Garage Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 3,
    "row": 5,
    "type": 4,
    "value": false,
    "merge_id": "C Skylights Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 4,
    "row": 5,
    "type": 4,
    "value": false,
    "merge_id": "C Glass Block Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 5,
    "row": 5,
    "type": 4,
    "value": false,
    "merge_id": "C Entry Doors Non-Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 6,
    "row": 5,
    "type": 4,
    "value": false,
    "merge_id": "C Garage Doors Non-Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 0,
    "row": 6,
    "type": 1,
    "title": "D",
    "value": false,
    "onHove": "Verified Non-Glazed Entry or Garage doors indicating compliance with ASTM E 330, ANSI/DASMA 108, or PA/TAS 202 for wind pressure resistance"
  },
  {
    "col": 1,
    "row": 6,
    "type": 4,
    "value": false,
    "merge_id": "D Windows or Entry Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 2,
    "row": 6,
    "type": 4,
    "value": false,
    "merge_id": "D Garage Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 3,
    "row": 6,
    "type": 4,
    "value": false,
    "merge_id": "D Skylights Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 4,
    "row": 6,
    "type": 4,
    "value": false,
    "merge_id": "D Glass Block Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 5,
    "row": 6,
    "type": 4,
    "value": false,
    "merge_id": "D Entry Doors Non-Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 6,
    "row": 6,
    "type": 4,
    "value": false,
    "merge_id": "D Garage Doors Non-Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 0,
    "row": 7,
    "type": 1,
    "title": "N",
    "value": false,
    "onHove": "Opening Protection products that appear to be A or B but are not verified"
  },
  {
    "col": 1,
    "row": 7,
    "type": 4,
    "value": false,
    "merge_id": "E Windows or Entry Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 2,
    "row": 7,
    "type": 4,
    "value": false,
    "merge_id": "E Garage Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 3,
    "row": 7,
    "type": 4,
    "value": false,
    "merge_id": "E Skylights Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 4,
    "row": 7,
    "type": 4,
    "value": false,
    "merge_id": "E Glass Block Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 5,
    "row": 7,
    "type": 4,
    "value": false,
    "merge_id": "E Entry Doors Non-Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 6,
    "row": 7,
    "type": 4,
    "value": false,
    "merge_id": "E Garage Doors Non-Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 0,
    "row": 8,
    "type": 1,
    "title": "N",
    "value": false,
    "onHove": "Other protective coverings that cannot be identified as A, B, or C"
  },
  {
    "col": 1,
    "row": 8,
    "type": 4,
    "value": false,
    "merge_id": "F Windows or Entry Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 2,
    "row": 8,
    "type": 4,
    "value": false,
    "merge_id": "F Garage Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 3,
    "row": 8,
    "type": 4,
    "value": false,
    "merge_id": "F Skylights Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 4,
    "row": 8,
    "type": 4,
    "value": false,
    "merge_id": "F Glass Block Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 5,
    "row": 8,
    "type": 4,
    "value": false,
    "merge_id": "F Entry Doors Non-Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 6,
    "row": 8,
    "type": 4,
    "value": false,
    "merge_id": "F Garage Doors Non-Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 0,
    "row": 9,
    "type": 1,
    "title": "X",
    "value": false,
    "onHove": "No Windborne Debris Protection"
  },
  {
    "col": 1,
    "row": 9,
    "type": 4,
    "value": false,
    "merge_id": "G Window or Entry Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 2,
    "row": 9,
    "type": 4,
    "value": false,
    "merge_id": "G Garage Doors Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 3,
    "row": 9,
    "type": 4,
    "value": false,
    "merge_id": "G Skylights Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 4,
    "row": 9,
    "type": 4,
    "value": false,
    "merge_id": "G Glass Block Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 5,
    "row": 9,
    "type": 4,
    "value": false,
    "merge_id": "G Entry Doors Non-Glazed",
    "input_type": "CheckBox"
  },
  {
    "col": 6,
    "row": 9,
    "type": 4,
    "value": false,
    "merge_id": "G Garage Doors Non-Glazed",
    "input_type": "CheckBox"
  }
]