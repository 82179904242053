import { FormControl, Grid, TextField,Menu, MenuItem } from "@mui/material"
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import Fade from '@mui/material/Fade';
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { deleteElement } from "../PtTemplateHelperFunctions";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const TableElement = ({catuuid,templateState,setTemplateState,updatePtTempateData,label,uuid,parentType,isDragging,setDragDisable,nestingLevel}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [active,setActive] = useState(false);
    const handleClickMore = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    const activateDragIn=()=>{
        setDragDisable(true);
        if(nestingLevel > 0){
           sessionStorage.setItem("firstLevelDrag", true);
        }
      }
      const activateDragOut=()=>{
        setDragDisable(false);
        if(nestingLevel > 0){
           sessionStorage.setItem("firstLevelDrag", false);
        }
      }
    return (
        <ClickAwayListener  mouseEvent="onMouseDown"
                        touchEvent="onTouchStart" onClickAway={() => active && setActive(false)}>
      <Grid container className="pt_card_border pt_inputfield_small" onClick={()=>setActive(true)}
       onMouseEnter={()=>parentType === "expandingCheckBox" || parentType==="group"?activateDragIn():null} onMouseLeave={()=>parentType === "expandingCheckBox" || parentType==="group"?activateDragOut():null} 
       >
          <div className={`${isDragging || active?'pt_ip_drag_section pt_elem_dragging':"pt_ip_drag_section"} `}><DragIndicatorIcon className="pt_icon_color"/></div>
          <div className="pt_ip_content">
              <div className="pt_ip_data">
                  <div className="pt_ip_data_row1 pt_is_flex_align_center pt_space_between">
                      <div className="pt_is_flex_align_center gap5">
                          <TableChartOutlinedIcon/>
                          <span className="pt_element_name">FL Wind Mit Table</span>
                      </div>                   
                     
                  </div>
                  <Grid container>
                  <Grid item xs={12}>
                       <span className="pt_table_box">Opening Protection Level Chart</span>
                      </Grid>
                  </Grid>
              </div>
              <div className={`${templateState.roleId !== 2 && templateState.roleId !== 3?'pt_ip_action pt_ip_disabled':'pt_ip_action pt_ip_enabled'}`} ><MoreHorizIcon className="pt_icon_color" 
            onClick={templateState.roleId !== 2 && templateState.roleId !== 3? null : handleClickMore}/>
              <Menu
              id="fade-menu"
              MenuListProps={{'aria-labelledby': 'fade-button',}}
              anchorOrigin={{vertical: 'top',horizontal: 'right',}}
              transformOrigin={{vertical: 'top',horizontal: 'right',}}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              className="pt_more_menu"
              >
                  <span><CloseIcon onClick={handleClose}/></span>
              <MenuItem onClick={()=>deleteElement(templateState, setTemplateState, parentType, uuid, catuuid, updatePtTempateData,handleClose)}><DeleteIcon/>Delete</MenuItem>
              </Menu>
              </div>
          </div>
      </Grid>
      </ClickAwayListener>
    )
}

export default TableElement