//file imports-------------------------------------------------------------------------------------------------------------------/
import validator from 'validator';
import toast from 'react-hot-toast';
import { LoadingButton } from '@mui/lab';
import { useState, useEffect } from 'react';
import { apiCall } from '../Services/action';
import Visibility from '@mui/icons-material/Visibility';
import { useNavigate, useLocation } from 'react-router-dom';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import '../../../../assets/styles/containers/_changePassword.scss';
import { Grid, Paper, TextField, Button, Typography, InputLabel, IconButton, FormControl, useMediaQuery, InputAdornment } from '@mui/material';
//render function-----------------------------------------------------------------------------------------------------------------/
const ChangePassword = () => {
    //to navigate to login after password change
    let navigate = useNavigate();
    //to passing the inspector email address
    const location = useLocation();
    //responsive
    const matches = useMediaQuery('(min-width:900px)');
    //view or hide password toggle
    const [visibilityToggle, setVisibilityToggle] = useState({
        newPasswordToggle: false,
        currentPasswordToggle: false,
        confirmNewPasswordToggle: false,
    });
    //loading toggle
    const [loading, setLoading] = useState(false);
    //for validation
    const [errors, setErrors] = useState({});
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    //password values
    const [passwordValues, setPasswordValues] = useState({
        email: location.state?.email,
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });
    // handle change function
    const handleChange = (event) => {
        setPasswordValues({
            ...passwordValues,
            [event.target.name]: event.target.value?.trim(),
        });
    };
    // handle submit function
    const handleformSubmit = (event) => {
        event.preventDefault();
        setErrors(validation(passwordValues));
        setDataIsCorrect(true);
    };
    // validation function
    const validation = (values) => {
        let errors = {};
        if (!values.currentPassword) {
            errors.currentPassword = 'Current password is required.';
        }
        if (!values.newPassword) {
            errors.newPassword = 'New password is required.';
        } else if (!validator.isStrongPassword(values.newPassword)) {
            errors.newPassword = 'Password did not meet the minimum criteria. min 8 characters with uppercase, lowercase, a number and a special character required';
        }
        if (!values.confirmNewPassword) {
            errors.confirmNewPassword = 'New password is required.';
        } else if (!validator.isStrongPassword(values.confirmNewPassword)) {
            errors.confirmNewPassword = 'Password did not meet the minimum criteria. min 8 characters with uppercase, lowercase, a number and a special character required';
        }
        return errors;
    };
    //API call
    const changePassword = () => {
        setLoading(true);
        const formdata = {
            email: passwordValues.email,
            password: passwordValues.newPassword,
            token: sessionStorage.getItem('token'),
            current_password: passwordValues.currentPassword,
            password_confirmation: passwordValues.confirmNewPassword,
        };
        apiCall(
            (response) => {
                console.log('CHANGE_INSPECTOR_PASSWORD', response);
                const { message, success } = response.data;
                if (success) {
                    toast.success(message);
                    setLoading(false);
                    navigate('/dashboard');
                } else {
                    toast.error(message);
                    setLoading(false);
                    console.log('else ', message);
                }
            },
            formdata,
            'CHANGE_INSPECTOR_PASSWORD',
        );
    };
    //validation check
    useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect) {
            changePassword();
        }
    }, [errors]);
    //render function
    return (
        <>
            <div className="change_password_background">
                <Grid container direction="column" alignItems="center" justifyContent="center">
                    <Grid item xs={3}>
                        <Paper className="change_password_card" sx={{ minWidth: { xs: 370, md: 390, lg: 516 }, minHeight: { xs: 522, md: 522, lg: 522 } }}>
                            <Grid className="change_password_grid">
                                <Typography className="change_password_header" sx={{ fontSize: { xs: '24px', md: '22px', lg: '22px' } }}>
                                    Change password
                                </Typography>
                            </Grid>
                            {/* {input field for new password} */}
                            <Grid className="center">
                                <FormControl variant="standard">
                                    <InputLabel className="input_lable" sx={{ top: { xs: '18px', md: '17px', lg: '17px' }, fontSize: { xs: '14px', md: '12px', lg: '12px' } }}>
                                        CURRENT PASSWORD
                                    </InputLabel>
                                    <TextField
                                        variant="outlined"
                                        name="currentPassword"
                                        placeholder="Password"
                                        className="input_field"
                                        onChange={handleChange}
                                        autoComplete="off !important"
                                        error={errors.currentPassword}
                                        value={passwordValues.currentPassword}
                                        size={`${matches ? 'small' : 'medium'}`}
                                        helperText={errors.currentPassword}
                                        type={visibilityToggle.currentPasswordToggle ? 'text' : 'password'}
                                        sx={{ marginTop: '63px', width: { xs: '340px', md: '317px', lg: '449px' } }}
                                        InputProps={{
                                            className: 'input_props_field',
                                            sx: { height: { xs: '55px', md: '40px', lg: '40px' }, fontSize: { xs: '18px', md: '14px', lg: '14px' }, background: 'rgba(239, 239, 239, 0.8) !important', color: 'text.primary !important' },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton sx={{ mr: '-9px' }} onClick={() => setVisibilityToggle({ ...visibilityToggle, currentPasswordToggle: !visibilityToggle.currentPasswordToggle })} onMouseDown={() => setVisibilityToggle({ ...visibilityToggle, currentPasswordToggle: !visibilityToggle.currentPasswordToggle })}>
                                                        {visibilityToggle.currentPasswordToggle ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            {/* {input field for new password} */}
                            <Grid className="center">
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="component-simple" className="input_lable" sx={{ top: '4px', fontSize: { xs: '14px', md: '12px', lg: '12px' } }}>
                                        NEW PASSWORD
                                    </InputLabel>
                                    <TextField
                                        name="newPassword"
                                        variant="outlined"
                                        placeholder="Password"
                                        onChange={handleChange}
                                        className="input_field"
                                        error={errors.newPassword}
                                        value={passwordValues.newPassword}
                                        size={`${matches ? 'small' : 'medium'}`}
                                        helperText={errors.newPassword}
                                        type={visibilityToggle.newPasswordToggle ? 'text' : 'password'}
                                        sx={{ marginTop: '47px', width: { xs: '340px', md: '317px', lg: '449px' } }}
                                        InputProps={{
                                            className: 'input_props_field',
                                            sx: { height: { xs: '55px', md: '40px', lg: '40px' }, fontSize: { xs: '18px', md: '14px', lg: '14px' } },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton sx={{ mr: '-9px' }} onClick={() => setVisibilityToggle({ ...visibilityToggle, newPasswordToggle: !visibilityToggle.newPasswordToggle })} onMouseDown={() => setVisibilityToggle({ ...visibilityToggle, newPasswordToggle: !visibilityToggle.newPasswordToggle })}>
                                                        {visibilityToggle.newPasswordToggle ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            {/* {input field for confirm password} */}
                            <Grid className="center">
                                <FormControl variant="standard">
                                    <InputLabel htmlFor="component-simple" className="input_lable" sx={{ top: '4px', fontSize: { xs: '14px', md: '12px', lg: '12px' } }}>
                                        CONFIRM NEW PASSWORD
                                    </InputLabel>
                                    <TextField
                                        variant="outlined"
                                        id="outlined-basic"
                                        placeholder="Password"
                                        onChange={handleChange}
                                        className="input_field"
                                        name="confirmNewPassword"
                                        error={errors.confirmNewPassword}
                                        size={`${matches ? 'small' : 'medium'}`}
                                        value={passwordValues.confirmNewPassword}
                                        helperText={errors.confirmNewPassword}
                                        type={visibilityToggle.confirmNewPasswordToggle ? 'text' : 'password'}
                                        sx={{ marginTop: '47px', width: { xs: '340px', md: '317px', lg: '449px' } }}
                                        InputProps={{
                                            className: 'input_props_field',
                                            sx: { height: { xs: '55px', md: '40px', lg: '40px' }, fontSize: { xs: '18px', md: '14px', lg: '14px' } },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton sx={{ mr: '-9px' }} onClick={() => setVisibilityToggle({ ...visibilityToggle, confirmNewPasswordToggle: !visibilityToggle.confirmNewPasswordToggle })} onMouseDown={() => setVisibilityToggle({ ...visibilityToggle, confirmNewPasswordToggle: !visibilityToggle.confirmNewPasswordToggle })}>
                                                        {visibilityToggle.confirmNewPasswordToggle ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                            {/* buttons */}
                            <Grid item xs={12} className="team-detail-cta" sx={{ pr: 4, pt: 13, pb: 2 }}>
                                <Button className="back-btn" sx={{ fontWeight: '600 !important' }} onClick={() => navigate('/my-profile')}>
                                    Cancel
                                </Button>
                                <LoadingButton className="change_password_confirm_button" sx={{ width: '168px !important' }} loading={loading} onClick={handleformSubmit}>
                                    Change password
                                </LoadingButton>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

// export component
export default ChangePassword;
