import {  Button, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AlertImage from "../../../../../assets/images/alert.png";
import {useNavigate} from 'react-router-dom'
// dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { apiCall } from "../../../../utils/action";
import { toast } from "react-hot-toast";

const PtDeleteTemplateModal = ({openDeleteModal, handleCloseDeleteModal, selectedTemplateName,isPdfTemplateDelete,
  deleteTemplateGuid,
  setRefresh,
  setNoloader,editTemplate
}) => {
  const navigate = useNavigate()
  const deleteTeamplate =(isPdfTemplateDelete,deleteTemplateGuid,getPtTemplateList)=>{
    handleCloseDeleteModal(true)
    setNoloader(true)
    const formdata = { template_guid:deleteTemplateGuid , is_pdf_template: isPdfTemplateDelete  };
    apiCall(
      (response) => {
        const {  message, success } = response.data;
        if (success) {
          toast.success(message);           
          setRefresh(prev=>!prev) 
          if(editTemplate){
            navigate(`/template/`)
          }        
          // setShowLoader(false);
        } else {
          handleCloseDeleteModal(true)
           toast.error(message);
        }
      },
      formdata,
      "PT_DELETE_TEMPLATE"
    );
  }
  return (
    <Dialog
      open={openDeleteModal}
      onClose={handleCloseDeleteModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="pt-delete-template-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <CloseIcon className="close-icon" onClick={handleCloseDeleteModal} />
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} sx={{ textAlign: "center",mt:'20px' }}>
          <img
            src={AlertImage}
            alt="alert"
            style={{ width: "51px", height: "49px" }}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography className="template_delete_modal_text">
            {`Delete template  `}
            <b style={{ fontSize: "22px", wordWrap: "break-word" }}>
              {selectedTemplateName}
            </b>
            ?
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              color: "#333333",
              fontFamily: "open sans",
              fontSize: "14px",
              mt: "15px",
              letterSpacing: 0,
            }}
          >
            Deleting a template will permanently remove it from all locations.
            <br /> Previous reports will not be affected. This cannot be undone.
          </Typography>
        </Grid>
        <DialogContentText id="alert-dialog-description">
          {/* This action cannot be undone. */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ ":hover": { backgroundColor: "transparent !important" } }}
          className="template-delete-modal-cancel-btn"
          onClick={handleCloseDeleteModal}
        >
          Cancel
        </Button>
        <Button
          sx={{ ":hover": { backgroundColor: "#1E8970 !important" } }}
          className="default-btn-template-delete-modal"
          variant="contained"
          color="error"
            onClick={() => {deleteTeamplate(isPdfTemplateDelete,deleteTemplateGuid)}}
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PtDeleteTemplateModal;
