import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import uuid from "react-uuid";
import moment from "moment";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import S3 from "react-aws-s3";
import { apiCall } from "../../utils/action";
import toast from "react-hot-toast";
import Preloader from "../../helpers/Preloader";
import { getImageDimensions, resizeImage, timestamp } from "../../utils/services";
window.Buffer = window.Buffer || require("buffer").Buffer;

// import s3FileUpload from "react-s3";

const AddImageButton = (props) => {
  const { AllCategoryList, categoryId,isPlaceHolder, tempMedia, item, lineId,templateGuid, lineInputControlId, commentId, updateTemplate, from } = props;

  const fileInput = React.useRef();
  const [showLoader,setShowLoader] = React.useState(false);
  const [name, setName] = React.useState()
  const unique_id = uuid();
  const small_id = unique_id;
  const companyId = sessionStorage.getItem("company_id");

  const Fetchfile = () => fileInput.current.click();
  const handleUploadImage = (data) => {
    let UrlName = data.image_url[0].path;
    let thumbUrl = data?.video_thumbnail[0]?.path
    const payload = {
      id: small_id,
      url: UrlName,
      newUrl: UrlName,
      localUrl: UrlName,
      OriginlocalUrl: UrlName,
      name: name,
      type: "image/png",
      edited: false,
      online: "online",
      filetype: "image",
      OriginUrl: UrlName,
      onlineUrl: UrlName,
      is_deleted:0,
      orginName: name,
      description: null,
      thumbnail:thumbUrl,
      OriginThumbnail:thumbUrl,
      editCount:0,
      isOriginUploaded:true,
      updated_at : timestamp(),
    };


    let lineIndex = null;
    let locationIndex = null;
    const catList = AllCategoryList?.template?.categories;
    const categoriesDataIndex = catList.findIndex((cat) => cat.category_id === categoryId);
    lineIndex = catList[categoriesDataIndex].lines.findIndex((line) => line.line_id === lineId);
    if(isPlaceHolder){
       item.data.push(payload)
       item.updated_at = timestamp();
      catList[categoriesDataIndex].lines[lineIndex].line_input_controls.push(item)
    updateTemplate("noToast");

    } else {
      locationIndex = catList[categoriesDataIndex].lines[lineIndex].line_input_controls.findIndex((info) => info.line_input_control_id === lineInputControlId);
      let selectd = catList[categoriesDataIndex].lines[lineIndex].line_input_controls[locationIndex].data;
      catList[categoriesDataIndex].lines[lineIndex].line_input_controls[locationIndex].updated_at = timestamp();
      selectd.push(payload);
      updateTemplate("noToast");
    }


  };

  const handleCommentImg = (data) => {
    let UrlName = data.image_url[0].path;
    let thumbUrl = data?.video_thumbnail[0]?.path
    const payload = {
      id: small_id,
      url: UrlName,
      newUrl: UrlName,
      localUrl: UrlName,
      OriginlocalUrl: UrlName,
      name: name,
      type: "image/png",
      edited: false,
      online: "online",
      filetype: "image",
      OriginUrl: UrlName,
      onlineUrl: UrlName,
      is_deleted:0,
      orginName: name,
      description: null,
      thumbnail:thumbUrl,
      OriginThumbnail:thumbUrl,
      editCount:0,
      isOriginUploaded:true,
      updated_at : timestamp(),
    };

    let lineIndex = null;
    // let locationIndex = null;
    let commentIndex = null;

    const catList = AllCategoryList?.template?.categories;
    const categoriesDataIndex = catList.findIndex((cat) => cat.category_id === categoryId);
    lineIndex = catList[categoriesDataIndex].lines.findIndex((line) => line.line_id === lineId);
    // locationIndex = catList[categoriesDataIndex].lines[lineIndex].line_input_controls.findIndex((info) => info.line_input_control_id === lineInputControlId);
    commentIndex = catList[categoriesDataIndex].lines[lineIndex].comments.findIndex((comment) => comment.comment_id === commentId);
    let selectd = catList[categoriesDataIndex].lines[lineIndex].comments[commentIndex].comment_input_controls;
    const dds= selectd.some((item) => item.type === "Media")
    if(!dds){
      tempMedia.data.push(payload)
      tempMedia.updated_at = timestamp();
      selectd.push(tempMedia)
      updateTemplate("noToast");

    } else if (dds) {
      selectd?.forEach((med) => {
        if (med.type === "Media") {
          med.updated_at = timestamp();
          med.data.push(payload);
        }
      });
      updateTemplate("noToast");
    }

  };


  const handleClick = async(event) => {
    let file = event.target.files[0];
    let thumbImage;
    const { imgWidth, imgHeight } = await getImageDimensions(file);
    const maxWidth = 320;
    const maxHeight = 320;
    const quality = 0.8;
    let newWidth, newHeight;
    if (imgWidth > imgHeight) {
      // Landscape orientation
      newHeight = maxHeight;
      newWidth = (maxHeight / imgHeight) * imgWidth;
    } else {
      // Portrait orientation
      newWidth = maxWidth;
      newHeight = (maxWidth / imgWidth) * imgHeight;
    }
    let thumbnailImage = await resizeImage(file, newWidth, newHeight, quality);
    thumbImage = new File([thumbnailImage], `${thumbnailImage.name}`, { type: 'image/jpeg' });
    const data = new FormData();
    const fileType = file.type.toLowerCase();
    if(fileType === 'image/gif' || file?.name?.toLowerCase()?.endsWith(".jfif")){
      toast.error("invalid file format");
    } else {
       setShowLoader(true)
      setName(file.name)
      data.append('image[0]', file);
      data.append('company_id', companyId);
      data.append('user_id', sessionStorage.getItem("user_id"))
      data.append('template_guid',templateGuid)
      data.append('video_thumbnail[0]',thumbImage)
        apiCall(
          (response) => {
              if (response.data.success) {
                setShowLoader(false)
                  // toast.success(response.data.message);
                  if (from === "comments") {
                    handleCommentImg(response.data.data);
                  } else {
                    handleUploadImage(response.data.data);
                  }
              } else {
                setShowLoader(false)
                console.error('else', response.data.message);
              }
          },
          data,
          'UPLOAD_MEDIA',
      );

    }

  };

  return (
    <>
    <Preloader showPreloader={showLoader} />
    <Stack className="img_btn" direction="row" spacing={2}>
      <Button variant="outlined" startIcon={<AddAPhotoIcon />} onClick={() => Fetchfile()}>
        Informational photo
        <input ref={fileInput} type="file" onChange={handleClick} accept="image/*" hidden />
      </Button>
    </Stack>
    </>
  );
};

export default AddImageButton;
