//file imports
import moment from "moment";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { visuallyHidden } from "@mui/utils";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Preloader from "../../helpers/Preloader";
import { useMediaQuery } from "react-responsive";
import CloseIcon from "@mui/icons-material/Close";
import "../../../assets/styles/containers/_agent.scss";
import { apiCall } from "../../pages/settings/Services/action";
import Sortswitch from "../../../assets/images/Sortswitch.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import EnhancedTableToolbar from "../../common/toolbar/EnhancedTableToolbar";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { descendingComparator, GetCurrentResolution } from "../../utils/services";
import { Alert, Grid, Modal, Table, FormControl, TextField, Button, Box, Paper, styled, TableRow, TablePagination, TableHead, TableSortLabel, TableContainer, Typography } from "@mui/material";
//Styled component for action column
const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
//table row heading
const agenttablehead = [
  { id: "id", numeric: true, disablePadding: false, label: "ID" },
  { id: "module_name", numeric: false, disablePadding: false, label: "MODULE NAME" },
  { id: "message", numeric: false, disablePadding: false, label: "MESSAGE" },
  { id: "api_payload", numeric: true, disablePadding: false, label: "API PAYLOAD" },
  { id: "error_platform", numeric: false, disablePadding: false, label: "ERROR PLATFORM" },
  { id: "company", numeric: false, disablePadding: false, label: "COMPANY" },
  { id: "created_at", numeric: true, disablePadding: false, label: "CREATED AT" },
];
// acsc / desc function
function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
//sort function
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
//map funtion of table heading*
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {agenttablehead.map((headCell) => (
          <td key={headCell.id} align={"left"} padding={headCell.disablePadding ? "none" : "normal"} className="agent-table-head" sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel IconComponent={headCell.id === "avatar" || headCell.id === "Actions" ? "" : () => <img alt="icon" src={Sortswitch} style={{ marginRight: "6px" }} className="sort-switch" />} active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)} sx={{ marginTop: GetCurrentResolution() === "mobile" && "21px", marginBottom: "10.5px", flexDirection: "row-reverse", fontSize: "12px" }}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </td>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function ErrorListView() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const handleOpen = () => setOpen(true);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [order, setOrder] = useState("asc");
  const [message, setMessage] = useState("");
  const [selected, setSelected] = useState([]);
  const [showLoader, setShowLoader] = useState();
  const [excelData, setExcelData] = useState([]);
  const [table_data, setTableData] = useState([]);
  const [toDate, setToDate] = useState(new Date());
  const [defaultData, setDefaultData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("first_name");
  const handleToDate = (newValue) => setToDate(newValue);
  const handleChipReset = () => setDisplayFilters(false);
  const handleFromDate = (newValue) => setFromDate(newValue);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [defaultFromDate] = useState(new Date().setMonth(0, 0));
  const [defaultToDate] = useState(new Date().setMonth(11, 31));
  const [fromDate, setFromDate] = useState(new Date().setMonth(0, 1));
  const handleChipClick = () => console.info("You clicked the Chip.");
  const handleChipDelete = () => console.info("You clicked the delete icon.");
  let result = JSON.parse(JSON.stringify(excelData));
  // eslint-disable-next-line
  result.filter((data) => (delete data.profile_pic, delete data.avatar, delete data.company_id, delete data.id));
  //Get agent Api call
  const GetErrorLists = () => {
    setShowLoader(true);
    const formdata = { user_id: sessionStorage.getItem("user_id"), role_id: sessionStorage.getItem("role_id") };
    apiCall(
      (response) => {
        const { success, data, message } = response.data;
        if (success) {
          setTableData(data ? data : []);
          setDefaultData(data ? data : []);
          setExcelData(data ? data : []);
          setMessage(message);
          setShowLoader(false);
        } else {
          console.log(message);
          setMessage(message);
          setShowLoader(false);
        }
      },
      formdata,
      "GET_ERROR_LIST"
    );
  };

  const onChangeDate = () => {
    setShowLoader(true);
    if (new Date(fromDate).getTime() >= new Date(toDate).getTime()) {
      setShowLoader(false);
      return toast.error("Invalid time stamp");
    }
    const formdata = { role_id: sessionStorage.getItem("role_id"), user_id: sessionStorage.getItem("user_id"), to_date: moment(toDate).format("YYYY-MM-DD"), from_date: moment(fromDate).format("YYYY-MM-DD") };
    apiCall(
      (response) => {
        const { success, data, message } = response.data;
        if (success) {
          setOpen(false);
          setTableData(data ? data : []);
          setDefaultData(data ? data : []);
          setExcelData(data ? data : []);
          setShowLoader(false);
        } else {
          setMessage(message);
          setShowLoader(false);
        }
      },
      formdata,
      "GET_ERROR_LIST"
    );
  };

  useEffect(() => {
    GetErrorLists();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table_data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchFilter = (event) => {
    setPage(0);
    let searchQuery = event.target.value.toLowerCase();
    let searchData = table_data.filter((obj) =>
      Object.values(obj)
        ?.flat()
        ?.some((v) => `${v}`?.toLowerCase()?.includes(searchQuery))
    );
    if (searchQuery !== "") {
      if (searchData.length !== 0) {
        setTableData(searchData);
      }
    }
    if (searchQuery === "") {
      setTableData(defaultData);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - table_data.length) : 0;

  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });

  return (
    <>
      {showLoader ? (
        <Preloader showPreloader={true} />
      ) : (
        <Box className="inspections-main" sx={{ width: "100%", px: { xs: "0", sm: "20px", md: "80px", lg: "80px" } }}>
          <div className="inspection-header">
            <Grid sx={{ width: "50%" }}>
              <h3 className="inspection-title">Error logs</h3>
              <p className="inspections-subheader">Error logs in your history. Select one to see details.</p>
            </Grid>
          </div>
          {table_data?.length !== 0 ? (
            <Paper sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 5 }} className={"inspection-table-main"}>
              <EnhancedTableToolbar handleOpen={handleOpen} searchIcon displayFilters={displayFilters} handleChipReset={handleChipReset} handleChipClick={handleChipClick} handleChipDelete={handleChipDelete} handleSearchFilter={handleSearchFilter} excelinspectionData={table_data} excelName="Error logs" table_data={table_data} excelData={result} />
              <TableContainer className={isMobile && "mobileScroller"} sx={{ overflowX: { sm: "auto", lg: "unset", md: "scroll" } }}>
                <Table aria-labelledby="tableTitle" size={"small"} style={{ transform: isMobile && "rotate(180deg)", overflowX: "unset", width: "100" }}>
                  <EnhancedTableHead numSelected={selected?.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={table_data?.length} />
                  <tbody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with: rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(table_data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.first_name);
                        return (
                          <tr onClick={(event) => handleClick(event, row.name)} role="checkbox" aria-checked={isItemSelected} key={row.name} className="inspections-data-table">
                            <td onClick={() => navigate("/error-logs/details", { state: { id: row.id } })} className="insp-status" align="left" style={{ paddingLeft: "5px", cursor: "pointer" }}>
                              {row.id?.length > 10 ? (
                                <BootstrapTooltip title={<h1 className="font-13">{row?.id}</h1>}>
                                  <p>{`${row.id?.substring(10, 0)}...`}</p>
                                </BootstrapTooltip>
                              ) : (
                                row.id
                              )}
                            </td>
                            <td onClick={() => navigate("/error-logs/details", { state: { id: row.id } })} className="agent-row-data-font" align="left" style={{ paddingLeft: "15px", cursor: "pointer" }}>
                              <span>
                                {row.module_name?.length > 20 ? (
                                  <BootstrapTooltip title={<h1 className="font-13">{row.module_name}</h1>}>
                                    <p>{`${row.module_name.substring(20, 0)}...`}</p>
                                  </BootstrapTooltip>
                                ) : (
                                  row.module_name
                                )}
                              </span>
                            </td>
                            <td className="agent-row-data-font" align="left" style={{ paddingLeft: "15px" }}>
                              <span>
                                {row.message?.length > 30 ? (
                                  <BootstrapTooltip title={<h1 className="font-13">{row?.message?.substring(60, 0)}</h1>}>
                                    <p>{`${row.message.substring(30, 0)}...`}</p>
                                  </BootstrapTooltip>
                                ) : (
                                  row.message
                                )}
                              </span>
                            </td>
                            <td className="agent-row-data-font" align="left" style={{ paddingLeft: "15px" }}>
                              <span>
                                {row.api_payload?.length > 30 ? (
                                  <BootstrapTooltip title={<h1 className="font-13">{row.api_payload?.substring(60, 0)}</h1>}>
                                    <p>{`${row.api_payload.substring(30, 0)}...`}</p>
                                  </BootstrapTooltip>
                                ) : (
                                  row.api_payload
                                )}
                              </span>
                            </td>
                            <td align="left">
                              <span className="agent-row-data-font" style={{ paddingLeft: "15px" }}>
                                {row.error_platform}
                              </span>
                            </td>
                            <td align="left">
                              <span className="agent-row-data-font" style={{ paddingLeft: "15px" }}>
                                {row.company}
                              </span>
                            </td>
                            <td align="left" style={{ paddingLeft: "17px" }}>
                              <div style={{ textAlign: "left" }}>
                                <span className="date-placeholder">{moment(moment.utc(row?.created_at).local()).format("L")}</span>
                                <span className="time-modified-placeholder" style={{ textTransform: "uppercase" }}>
                                  {moment(moment.utc(row?.created_at).local()).format("LT")}
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    {emptyRows > 0 && (
                      <tr style={{ height: 33 * emptyRows }}>
                        <td colSpan={6} />
                      </tr>
                    )}
                  </tbody>
                </Table>
              </TableContainer>
              {/* table pagination component*/}
              <TablePagination rowsPerPageOptions={[10, 25]} component="div" count={table_data.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </Paper>
          ) : (
            <Paper sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 10 }} elevation={0} className={"inspection-table-main"}>
              <EnhancedTableToolbar handleOpen={handleOpen} searchIcon displayFilters={displayFilters} handleChipReset={handleChipReset} handleChipClick={handleChipClick} handleChipDelete={handleChipDelete} handleSearchFilter={handleSearchFilter} excelinspectionData={table_data} excelName="Error logs" table_data={table_data} excelData={result} />
              <Alert severity="error">{message}</Alert>
            </Paper>
          )}
        </Box>
      )}
      {/* edit screen */}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "600px !important", minHeight: "231px !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={handleClose} />
            </Grid>
            <Grid item xs={12}>
              <Paper elevation={0} sx={{ p: 2 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography sx={{ color: "#333333", fontFamily: "open sans", fontSize: "22px", ml: 2, mb: 5, letterSpacing: 0 }}>Filter by Month</Typography>
                  </Grid>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid item xs={5.7} sm={5.7} md={5.7} lg={5.7}>
                      <MobileDatePicker
                        value={fromDate}
                        inputFormat="MM/dd/yyyy"
                        onChange={handleFromDate}
                        shouldDisableDate={(date) => date < defaultFromDate}
                        shouldDisableMonth={(date) => date < defaultFromDate}
                        shouldDisableYear={(date) => date < defaultFromDate}
                        renderInput={(params) => (
                          <FormControl variant="standard" className="forms-control">
                            <label className="input-form-label" id="demo-simple-select-label">
                              <CalendarMonthIcon className="calendar_start_icon" /> FROM
                            </label>
                            <TextField {...params} size="small" />
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={0.6} sm={0.6} md={0.6} lg={0.6} className="calender_icon_center">
                      <ArrowRightAltIcon className="calendar_allright_icon" sx={{ mr: 0.5 }} />
                    </Grid>
                    <Grid item xs={5.7} sm={5.7} md={5.7} lg={5.7}>
                      <MobileDatePicker
                        value={toDate}
                        inputFormat="MM/dd/yyyy"
                        onChange={handleToDate}
                        shouldDisableDate={(date) => date > defaultToDate}
                        shouldDisableMonth={(date) => date > defaultToDate}
                        shouldDisableYear={(date) => date > defaultToDate}
                        renderInput={(params) => (
                          <FormControl variant="standard" className="forms-control">
                            <label className="input-form-label" id="demo-simple-select-label">
                              <CalendarMonthIcon className="calendar_start_icon" /> TO
                            </label>
                            <TextField {...params} size="small" />
                          </FormControl>
                        )}
                      />
                    </Grid>
                  </LocalizationProvider>
                </Grid>
              </Paper>
            </Grid>
            <Grid container sx={{ display: "flex", justifyContent: "flex-end", mt: 3, p: 3 }}>
              <Button variant="outlined" className="inspection-filter-modal-buttons-cancel" sx={{ width: "119px", ":hover": { backgroundColor: "transparent !important" } }} onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton loading={showLoader} variant="contained" className="inspection-filter-modal-buttons-apply" sx={{ width: "119px", ml: 3, ":hover": { backgroundColor: "#1E8970 !important" } }} onClick={() => onChangeDate()}>
                Apply
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}
