import Paper from "@mui/material/Paper";
import { useMediaQuery } from "react-responsive";
import SearchIcon from "@mui/icons-material/Search";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Grid, InputBase, IconButton } from "@mui/material";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const EnhancedTableToolbar = (props) => {
  var currentdate = new Date();
  // let result = JSON.parse(JSON.stringify(props?.excelData));
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToCSV = (csvData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `CompanyList ${currentdate.toDateString()}` + fileExtension
    );
  };
  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });
  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{ width: { xs: "371.5px", sm: "100%", md: "100%", lg: "100%" } }}
        className="inspection-toolbar-main"
      >
        <Paper
          className={"inspection-search-field"}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: { xs: "241px", sm: "29.64%", md: "29.64%", lg: "29.64%" },
          }}
        >
          <IconButton sx={{ p: "10px" }}>
            <SearchIcon className="inspection-search-icon" />
          </IconButton>
          {props?.search ? (
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: "16px" }}
              placeholder="Search"
              // inputProps={{ "aria-label": "search" }}
              onChange={props.handleSearchFilter}
              value={props?.search}
              onKeyPress={(e) => {
                if (e.key === "Enter") props.handleSearchEnter(e);
              }}
            />
          ) : (
            <InputBase
              sx={{ ml: 1, flex: 1, fontSize: "16px" }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              onChange={props.handleSearchFilter}
            />
          )}
        </Paper>
        {isMobile ? (
          <p
            className="inspections-export-content"
            // onClick={() => window.print()}
            onClick={(e) => exportToCSV(props.excelData, "CompanyList")}
          >
            <ExitToAppIcon
              sx={{ fontSize: "38px" }}
              className="inspections-export-icon"
            />{" "}
            {isMobile ? "" : "Export"}
          </p>
        ) : (
          <p
            sx={{ width: "7.6%" }}
            className="inspections-export-content"
            // onClick={() => window.print()}
            onClick={(e) => exportToCSV(props.excelData, "CompanyList")}
          >
            <ExitToAppIcon className="inspections-export-icon" />
            {isMobile ? "" : "Export"}
          </p>
        )}
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};

export default EnhancedTableToolbar;
