import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { Grid, Button } from "@mui/material";
import Box from "@mui/material/Box";
import { apiCall } from "../../utils/action";
// import { Userpilot } from "userpilot";
import { defaultTemplateValue } from "./DefaultTemplate";
import Placeholder from "../../common/placeholder/Placeholder";
// editor components for draft js module
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import Preloader from "../../helpers/Preloader";
import InlineEdit from "../../common/inlineEdit/InlineEdit";
import { checkForInvalidImages } from "../../utils/services";

const AgreementEditor = () => {
  const location = useLocation();
  let agreementId = location.state?.id;
  const editor = useRef(); // editor ref
  const [storedText, setStoredText] = useState("");
  const [showLoader, setShowLoader] = useState();
  const [isDefaultAgreement, setIsDefaultAgreement] = useState(0);
  // const dataFiltered = filterData(searchQuery, placeholderValues);
  let navigate = useNavigate();
  // suneditor===============================================================================
  // const [editorContent,setEditorContent] = useState(location?.state?.edit?'':defaultTemplateValue)
  const [editorContent, setEditorContent] = useState("");
  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };
  const handleChange = (content) => {
    setEditorContent(content);
  };
  // isDefaultAgreement === 1 && editor.current.disable()

  // suneditor===============================================================================
  // session datas
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  const uuId = sessionStorage.getItem("user_id");
  let permission = roleId === "7" ? false : true;
  // reset the agreement
  const resetAgreement = (id) => {
    if (location.state?.edit) {
      GetAgreementDetails();
    } else setEditorContent("");
    if (location.state?.edit && isDefaultAgreement) {
      editAgreement(id, "default");
    }
  };

  const removeHtmlTags = (htmlString) => {
    return htmlString.replace(/<\/?[^>]+(>|$)/g, "");
  };
  const validation = (id, action) => {
    const isValid =
      /^[\s;]*((&nbsp;)|(<p>(\s|&nbsp;|<br>)*<\/p>)|(<br>))*[\s;]*$/.test(
        editorContent
      );
    const rem = removeHtmlTags(editorContent);
    // if(isValid){
    //   setEditorContent("");
    // }
    // console.log(rem.length)
    // if(rem.length === 0 || rem.length === 1){
    //   setEditorContent("");
    // }
   
    const {hasUnsupportedImages,hasCloudImages} = checkForInvalidImages(editorContent)
    let errorSpanEl = document.getElementById("agreement-error-message");
    errorSpanEl.innerHTML = "";
    if (isValid || editorContent.length === 0 || editorContent.length === 1) {
      errorSpanEl.innerHTML = "Agreement content is required";
    } else if (hasUnsupportedImages) {
      errorSpanEl.innerHTML = "Agreement content contains unsupported image formats. Only JPEG, JPG and PNG formats are supported.";
    } else if(hasCloudImages){
      errorSpanEl.innerHTML = "Agreement content cannot contain images copied from online sources.";
    }
    else {      
      if (action === "edit") editAgreement(id);
      else addAgreement();
    }
  };

  //get agreement details
  const GetAgreementDetails = () => {
    setShowLoader(true);
    // same api for getting agreement list and agreement Details... only difference in passed parameters
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      agreement_id: agreementId,
    };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setEditorContent(data?.agreement_body);
          setIsDefaultAgreement(data?.is_default);
          setStoredText(data?.name);
          setShowLoader(false);
        } else {
          toast.error(message);
        }
      },
      formdata,
      "GET_AGREEMENTS_LIST"
    );
  };

  const getNewAgreementName = () => {
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          // setStoredText(data.name);
          setStoredText("Untitled");
          setShowLoader(false);
        } else {
          toast.error(message);
          setShowLoader(false);
          console.log("else ", response.data.message);
        }
      },
      formdata,
      "GET_NEW_AGREEMENT_NAME"
    );
  };
  // add agreement api call
  const addAgreement = () => {
    toast.dismiss();
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      name: storedText,
      agreement_body: editorContent,
    };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setShowLoader(false);
          // PALM 10151
          // Userpilot.track("Add agreement");
          navigate(`/settings/agreements`);
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      formdata,
      "ADD_AGREEMENT"
    );
  };
  // edit agreement
  const editAgreement = (id, action) => {
    toast.dismiss();
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      agreement_id: id,
      name: storedText,
      agreement_body:
        action == "default" ? defaultTemplateValue : editorContent,
    };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setShowLoader(false);
          navigate(`/settings/agreements`);
        } else {
          toast.error(message);
          setShowLoader(false);
          console.log("else ", response.data.message);
        }
      },
      formdata,
      "EDIT_AGREEMENT"
    );
  };
  useEffect(() => {
    // !location.state?.edit && getNewAgreementName();
    !location.state?.edit && setStoredText("Untitled");
    location.state?.edit && GetAgreementDetails();
  }, []);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },
        }}
        className="teams-container"
      >
        <Preloader showPreloader={showLoader} />
        <Grid container>
          <Grid item xs={12} sm={9} className="editor-title-wrapper">
            <div>
              <h3 className="editor-title">
                {
                  // isDefaultAgreement === 1 ||
                  !permission ? (
                    storedText
                  ) : (
                    <InlineEdit
                      text={storedText}
                      onSetText={(text) => setStoredText(text)}
                    />
                  )
                }
              </h3>
              <p className="editor_details_subtitle">
                {location.state?.edit ? (
                  <span className="agreement_editor_title_text">
                    Edit the template using the placeholders on the right.
                    Agreements are automatically sent to your customer after the
                    appointment is scheduled. Go to{" "}
                    <span
                      className="navigate_text"
                      onClick={() => navigate("/settings/services")}
                    >
                      Services
                    </span>{" "}
                    to assign agreements to services. Change what is
                    automatically sent in{" "}
                    <span
                      className="navigate_text"
                      onClick={() => navigate("/notifications")}
                    >
                      Notification settings
                    </span>
                    .{" "}
                  </span>
                ) : (
                  <span className="agreement_editor_title_text">
                    Add new template using the placeholders on the right.
                    Agreements are automatically sent to your customer after the
                    appointment is scheduled. Go to{" "}
                    <span
                      className="navigate_text"
                      onClick={() => navigate("/settings/services")}
                    >
                      Services
                    </span>{" "}
                    to assign agreements to services. Change what is
                    automatically sent in{" "}
                    <span
                      className="navigate_text"
                      onClick={() => navigate("/notifications")}
                    >
                      Notification settings
                    </span>
                    .{" "}
                  </span>
                )}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
        </Grid>
        {/* commented due to design change */}
        {/* <Grid item xs={12} className="agreement_consent">
          <FormControlLabel
            control={
              <Checkbox
                disabled={isDefaultAgreement === 1 || !permission}
                id="agreement_checkbox"
                defaultChecked
                className="agreement_checkbox"
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={<CheckCircleIcon />}
              />
            }
            label="This agreement is automatically sent to your customer after the appointment is created and all needed information is added. "
          />
          <p style={{position:'relative',zIndex:'10'}}><NavLink
            to={{ pathname: `/notifications` }}
          >
            <span>Change notification settings.</span>
          </NavLink></p>
          
        </Grid> */}
        <Grid container spacing={4}>
          <Grid className="editor-class" item xs={12} sm={9}>
            <SunEditor
              setDefaultStyle="font-size: 14px;font-family: 'Open Sans', sans-serif;"                
              height="100%"
              setOptions={{
                buttonList: [
                  // default
                  [
                    "bold",
                    "underline",
                    "italic",
                    "font",
                    "fontSize",
                    "fontColor",
                  ],
                  ["align", "list"],
                  ["fullScreen", "preview"],
                  // (min-width: 992)
                  [
                    "%992",
                    [
                      [
                        "bold",
                        "underline",
                        "italic",
                        "font",
                        "fontSize",
                        "fontColor",
                      ],
                      ["align", "list"],
                    ],
                  ],
                  // (min-width: 767)
                  [
                    "%767",
                    [
                      [
                        "bold",
                        "underline",
                        "italic",
                        "font",
                        "fontSize",
                        "fontColor",
                      ],
                      ["align", "list"],
                    ],
                  ],
                  // (min-width: 480)
                  [
                    "%480",
                    [
                      [
                        "bold",
                        "underline",
                        "italic",
                        "font",
                        "fontSize",
                        "fontColor",
                      ],
                      ["align", "list"],
                    ],
                  ],
                ],
              }}
              onChange={handleChange}
              defaultValue={editorContent}
              setContents={editorContent}
              getSunEditorInstance={getSunEditorInstance}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Placeholder
              setShowLoader={setShowLoader}
              editor={editor}
              isDefaultAgreement={isDefaultAgreement}
              isAgreementPlaceholder={true}
            />
          </Grid>
          <Grid
            item
            xs={12}
            className="agreement-action"
            style={{ paddingTop: "10px" }}
          >
            <span id="agreement-error-message"> </span>
          </Grid>
          {permission && (
            <Grid item xs={12} className="agreement-action">
              <Button
                className="agreement-btn"
                sx={{
                  textTransform: "none !important",
                  ":hover": { backgroundColor: "#1E8970 !important" },
                }}
                onClick={
                  location?.state?.edit
                    ? () => validation(location.state.id, "edit")
                    : // : addAgreement
                      validation
                }
              >
                Save agreement
              </Button>
              <span
                className="navlink"
                id="reset_to_default_agreement"
                onClick={() => resetAgreement(location.state.id)}
              >
                <span>Reset to default</span>
              </span>
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default AgreementEditor;
