import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "../../../assets/styles/containers/_inspDetails.scss";
import axios from "axios"; //API calls
import { RequestCompanyDetails, RequestMapBoxDetails } from "../../pages/dashboard/actions";
import { apiCall } from "../../utils/action";
import { decode } from "js-base64";

const Maps = (props) => {
  const { locations, companyAddress, inspections, mapContainer } = props;
  const map = useRef(null);
  const [key, setKey] = useState();
  const [center, setCenter] = useState([-96.672715, 32.84463]);
  const [zoom, setZoom] = useState(3);
  const [compData, setCompData] = useState([]);
  const [coordinates, setCoordinates] = useState({});
  const uuId = sessionStorage.getItem("user_id");
  const roleId = sessionStorage.getItem("role_id");
  const companyId = sessionStorage.getItem("company_id");
  const CovertedArray = (objects) => {
    return Array.prototype.map
      .call(objects, function (item) {
        return item;
      })
      .join(";");
  };

  mapboxgl.accessToken = key;
  var locators = [];

  const getKey = () => {
    // const formdata = { company_id: sessionStorage.getItem("company_id"), user_id: sessionStorage.getItem("user_id"), role_id: sessionStorage.getItem("role_id") };
    apiCall(
      (response) => {
        const { success, data } = response.data;
        if (success) {
          setKey(decode(data));
        } else {
          console.log("else", response.data.message);
        }
      },
      "",
      "GET_KEY"
    );
  };

  const GetCompAddress = () => {
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };

    RequestCompanyDetails((response) => {
      const { data, message, success } = response.data;
      if (success) {
        setCompData(data?.company_details);
      } else {
        console.log("else ", message);
      }
    }, formdata);
  };
  useEffect(() => {
    getKey();
    GetCompAddress();
  }, []);
  useEffect(() => {
    if (key) {
      // initialize map only once
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: center,
        attributionControl: false,
        zoom: zoom,
      });

      if (locations && locations.length > 0) {
        const payload =
          inspections.inspector_address_1 !== null && inspections.inspector_state !== null && inspections.inspector_city !== null
            ? {
                address: inspections.inspector_address_1,
                city: inspections.inspector_city,
                state: inspections.inspector_state,
                zip: inspections.inspector_zip,
                token: mapboxgl.accessToken,
              }
            : {
                address: companyAddress?.company_address_1,
                city: companyAddress?.company_city,
                state: companyAddress?.company_state,
                zip: companyAddress?.company_zip,
                token: mapboxgl.accessToken,
              };

        RequestMapBoxDetails((response) => {
          if (response.status === 200) {
            locators = [...locators, response.data.features[0].center];
            const AllLocations = CovertedArray(locators);
            axios
              .get(`https://api.mapbox.com/directions/v5/mapbox/driving/${AllLocations}?geometries=geojson&overview=full&steps=false&access_token=${mapboxgl.accessToken}`)
              .then((response) => {
                sessionStorage.setItem("cords", JSON.stringify(response?.data?.routes[0]?.geometry.coordinates));
                const datass = { ...coordinates, ...response?.data?.routes[0]?.geometry.coordinates };
                setCoordinates(datass);
              })
              .catch(function (error) {
                if (error.response) {
                  console.log(error.response.headers);
                }
              });
            setCenter(response.data.features[0].center);
            map.current.flyTo({
              center: response.data.features[0].center,
              zoom: 4,
            });
            const popup = new mapboxgl.Popup({ closeOnMove: true, closeOnClick: true, closeButton: false }).setHTML(`${inspections.inspector_address_1 === null && inspections.inspector_state === null && inspections.inspector_city === null ? `<h1>${companyAddress?.company_address_1},</h1><p style="text-align:center">${companyAddress?.company_city},${companyAddress?.company_state},${companyAddress?.company_zip}</p>` : `<h1>${inspections.inspector_address_1},</h1><p style="text-align:center">${inspections.inspector_city},${inspections.inspector_state},${inspections.inspector_zip}</p>`}`);
            new mapboxgl.Marker({ color: `${inspections.inspector_address_1 === null && inspections.inspector_state === null && inspections.inspector_city === null ? "blue" : "black"}`, display: "block" }).setLngLat(response.data.features[0].center).setPopup(popup).addTo(map.current);
            setZoom(response.data.features.length === 0 ? null : 7);
          } else {
            console.log("else ", response.data.message);
          }
        }, payload);
      }

      locations &&
        locations.length > 0 &&
        locations?.map((loc) => {
          const payload =
            locations && locations.length > 0
              ? {
                  address: loc.location_address,
                  city: loc.location_city,
                  state: loc.location_state,
                  zip: loc.location_zip,
                  token: mapboxgl.accessToken,
                }
              : {
                  address: compData?.address_1,
                  city: compData?.city,
                  state: compData?.state,
                  zip: compData?.zip,
                  token: mapboxgl.accessToken,
                };
          return RequestMapBoxDetails((response) => {
            if (response.status === 200) {
              console.log(locators);

              locators = [...locators, response.data.features[0].center];
              const AllLocations = CovertedArray(locators);
              axios
                .get(`https://api.mapbox.com/directions/v5/mapbox/driving/${AllLocations}?geometries=geojson&overview=full&steps=false&access_token=${mapboxgl.accessToken}`)
                .then((response) => {
                  sessionStorage.setItem("cords", JSON.stringify(response?.data?.routes[0]?.geometry.coordinates));
                })
                .catch(function (error) {
                  if (error.response) {
                    console.log(error.response.headers);
                  }
                });
              setCenter(response.data.features[0].center);
              map.current.flyTo({
                center: response.data.features[0].center,
                zoom: 4,
              });
              const popup = new mapboxgl.Popup({ closeOnMove: true, closeOnClick: true, closeButton: false }).setHTML(`<h1>${loc.location_address},</h1><p style="text-align:center">${loc.location_city},${loc.location_state},${loc.location_zip}</p>`);
              new mapboxgl.Marker({ color: "Red", display: "block" }).setLngLat(response.data.features[0].center).setPopup(popup).addTo(map.current);
              setZoom(response.data.features.length === 0 ? null : 7);
            } else {
              console.log("else ", response.data.message);
            }
          }, payload);
        });
      map.current.on("load", () => {
        map.current.addSource("route", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: JSON.parse(sessionStorage.getItem("cords")),
            },
          },
        });
        map.current.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#ff3b3b",
            "line-width": 4,
          },
        });
      });
      GetCompAddress();
      map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");

      return () => map.current.remove();
    }
  }, [locations, key]);

  return (
    <>
      {key ? (
        <div
          className="map-container"
          ref={mapContainer}
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Maps;
