import React from 'react';
import '../../../assets/styles/containers/_scheduleInspection.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const ScheduleMessage = (props) => {
    const { activeStep, ScheduleInspectionSteps, navigate, location } = props;
    return (
        <Grid>
            <div className="inspection-message">
                <Typography>
                    {' '}
                    <CheckCircleIcon />
                </Typography>
                <Typography>{activeStep === ScheduleInspectionSteps.length ? 'Your inspection has been scheduled!' : 'Your inspection has been saved as a draft.'}</Typography>
                <Typography>{activeStep === ScheduleInspectionSteps.length ? 'This will trigger any notifications you have set to go out such as a confirmation email, request for payment or agreement signature request... For a history of emails sent to your client, check under “notifications” on the inspection details page.' : 'When you are ready to schedule the inspection, go to the inspection details page and edit or add the missing fields. Once all necessary details are completed, a confirmation email will be sent to the client. '}</Typography>
            </div>
            <Typography className="inspection-message-action">
                <Button sx={{":hover": { backgroundColor: "#1E8970 !important", }}} onClick={() => (location?.state?.calendar ? navigate('/calendar') : navigate('/dashboard'))} className="default-btn" varient="contained">
                    Ok
                </Button>
            </Typography>
        </Grid>
    );
};

export default ScheduleMessage;
