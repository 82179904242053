import { Box } from "@mui/material"
import Header from "../../../common/header/Header"
import Breadcrumb from "../../../common/header/Breadcrumb"
import PtTemplateEditor from "./PtTemplateEditor"
import { useEffect } from "react"
import { apiCall } from "../../../utils/action"
import toast from "react-hot-toast"
import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Preloader from "../../../helpers/Preloader"
import { findMergeIds } from "./PtTemplateHelperFunctions"
const PtTemplateDetails = () => {
  const [templateState,setTemplateState] = useState({
    templateData:[],
    templateDataCopy:[],
    updatingTemplate:false,
    mergeIdArray:[],
    allMergeIdFilled:true,
    roleId:Number(sessionStorage.getItem("role_id")),
    // roleId:Number(2),
  })
  const [showLoader,setShowLoader] = useState(false)
  // let inactivityTimer;
  const location = useLocation()
  const navigate = useNavigate()
  const getTemplateData=(guid)=>{
    setShowLoader(true)
    const formdata = { template_guid:guid , is_pdf_template: 1  };
    apiCall(
    (response) => {
      const {  message, success } = response.data;
      if (success) {
        let mergeIds = findMergeIds(response.data)
        setTemplateState(prev=>({...prev,templateData:response.data,templateDataCopy:response.data,mergeIdArray:mergeIds}))        
        setShowLoader(false) 
      } else {
        setShowLoader(false)
        toast.error(message);
      }
    },
    formdata,
    "PT_GET_TEMPLATE_DATA"
  );
  }

  const updatePtTempateData=(updatedObj,type,action)=>{
    type === "loader" && setShowLoader(true)
    toast.dismiss(); 
    const formdata = updatedObj;
    apiCall(
    (response) => {
      const { message, success } = response.data;
      if (success) {
        let mergeIds = findMergeIds(response.data)
        setTemplateState(prev=>({...prev,templateData:response.data,templateDataCopy:response.data,updatingTemplate:false,mergeIdArray:mergeIds}))
        if(action === "publish") navigate(`/template/`)
        toast.success(message); 
        setShowLoader(false) 
      } else {
        setShowLoader(false)
        setTemplateState(prev=>({...prev,updatingTemplate:false}))
        toast.error(message);
        getTemplateData(location.state.guid)
      }
    },
    formdata,
    "PT_UPDATE_TEMPLATE_DATA"
  );    
  }

  // const saveDataToServer = () => {
  //  let result = deepCompare(templateState.templateData, templateState.templateDataCopy)
  //  if(!result) updatePtTempateData(templateState?.templateDataCopy?.data?.template,'no toast')
  // };

  // useEffect(() => {
  //   // Function to start the inactivity timer
  //   const startInactivityTimer = () => {
  //     // Clear the existing timer, if any
  //     clearTimeout(inactivityTimer);      
  //     // Set a new timer to save data after 2 seconds of inactivity
  //     inactivityTimer = setTimeout(() => {
  //       saveDataToServer();
  //     }, 3000);
  //   };
  //   // Start the inactivity timer when the component mounts
  //   startInactivityTimer();
  //   // Attach event listeners to monitor user activity
  //   window.addEventListener('mousemove', startInactivityTimer);
  //   window.addEventListener('keydown', startInactivityTimer);
  //   // Clear the timer and remove event listeners when the component unmounts
  //   return () => {
  //     clearTimeout(inactivityTimer);
  //     window.removeEventListener('mousemove', startInactivityTimer);
  //     window.removeEventListener('keydown', startInactivityTimer);
  //   };
  // }, [templateState]);

  useEffect(() => {    
    getTemplateData(location.state.guid)
  }, [location])

  return (
    <>
    {/* {window.scrollTo(0, 0)} */}
    <Box sx={{ flexGrow: 1, backgroundColor: "#f7f9fa", minHeight: "100vh" }}>
      <Preloader showPreloader={showLoader}/>
      <Header />
      <Breadcrumb />
      <div style={{backgroundColor:'#f7f9fa'}} className="inspection-details">
      <PtTemplateEditor templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} getTemplateData={getTemplateData}/>
      </div>
    </Box>
  </>
    
  )
}

export default PtTemplateDetails