import { FormControl, Grid, Menu, MenuItem, Select, TextField } from "@mui/material"
import TextareaAutosize from "@mui/material/TextareaAutosize";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useState ,useEffect} from "react";
import Fade from '@mui/material/Fade';
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SunEditor from "suneditor-react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import InformationTypeChangeModal from "../Modals/InformationTypeChangeModal";
import { deleteElement, updateElementJsonByUUID, updateJSON } from "../PtTemplateHelperFunctions";

const InformationalText = ({catuuid,templateState,setTemplateState,updatePtTempateData,label,uuid,type,value,parentType,isDragging,setDragDisable,nestingLevel}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [editorContent,setEditorContent] = useState('')
  const [textAreaContent,setTextAreaContent] = useState('')
  const [inputChanged , setInputChanged] = useState(false)
  const [infoType,setInfoType] = useState('InformationText/Html')
  const [openInfoModal,setOpenInfoModal] = useState(false);
  const [active,setActive] = useState(false);
  const open = Boolean(anchorEl);
  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeType = (e) => {
    setInputChanged(false)
    setTextAreaContent('')
    if( e.target.value === "InformationText/Plain")
      setOpenInfoModal(true) 
    else {      
      setInfoType(e.target.value)    
      let prevData = JSON.parse(JSON.stringify(templateState?.templateDataCopy));
      setTemplateState(prev=>({...prev,updatingTemplate:true}))
      const updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, e.target.value,'infoType')
      if (updatedData) {
        prevData.data = updatedData;
        setTemplateState(prev=>({...prev,templateData:prevData}))
        let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
        let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
        let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
        newUpdatedSpecificData.data.template.pdf_categories = editedCategory
        updatePtTempateData(newUpdatedSpecificData?.data?.template)
      }   
    }  
   
  };

  const clickAwaySaveptData=()=>{
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
    updateJSON(prevData, uuid, type === "InformationText/Plain"?textAreaContent:editorContent,"value");
    let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
    let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
    newUpdatedSpecificData.data.template.pdf_categories = editedCategory
    setTemplateState(prev=>({...prev,templateData:prevData}))
    updatePtTempateData(newUpdatedSpecificData?.data?.template)       
    setInputChanged(false)
  }

  const handleCloseInfoModal=()=>{
    setOpenInfoModal(false)
  }

  const handleChange=(content)=>{
    setInputChanged(true)
    setEditorContent(content)
  }

  const handleChangeInfotext=(e)=>{
    setInputChanged(true)
    setTextAreaContent(e.target.value)
  }
  const activateDragIn=()=>{
    setDragDisable(true);
    if(nestingLevel > 0){
       sessionStorage.setItem("firstLevelDrag", true);
    }
  }
  const activateDragOut=()=>{
    setDragDisable(false);
    if(nestingLevel > 0){
       sessionStorage.setItem("firstLevelDrag", false);
    }
  }
  useEffect(() => {
    setInputChanged(false)
    if(type === "InformationText/Plain") setTextAreaContent(value)
    else setEditorContent(value)
    setInfoType(type)
  }, [templateState])
  
  return (
    <>
    <InformationTypeChangeModal openInfoModal={openInfoModal} handleCloseInfoModal={handleCloseInfoModal} setInfoType={setInfoType}
    templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} catuuid={catuuid} uuid={uuid} setEditorContent={setEditorContent}/>
   <ClickAwayListener  mouseEvent="onMouseDown"
                        touchEvent="onTouchStart" onClickAway={() => active && setActive(false)}>
    <Grid container className="pt_card_border pt_inputfield" key={uuid} style={{flexWrap:'nowrap'}} onClick={()=>setActive(true)}
     onMouseEnter={()=>parentType === "expandingCheckBox" || parentType==="group"?activateDragIn():null} onMouseLeave={()=>parentType === "expandingCheckBox" || parentType==="group"?activateDragOut():null} 
     >
        <div className={`${isDragging || active?'pt_ip_drag_section pt_elem_dragging':"pt_ip_drag_section"} `}><DragIndicatorIcon className="pt_icon_color"/></div>
        <div className="pt_ip_content">
            <div className="pt_ip_data">
                <div className="pt_ip_data_row1 pt_is_flex_align_center pt_space_between">
                    <div className="pt_is_flex_align_center gap5">
                        <DescriptionOutlinedIcon/>
                        <span className="pt_element_name">Informational Text</span>
                    </div>
                    <FormControl fullWidth >
                    <Select                    
                    labelId="input_type"
                    id="input_type"
                    size="small"
                    value={infoType}
                    disabled={templateState.roleId !== 2 && templateState.roleId !== 3}
                    onChange={(e)=>handleChangeType(e)}
                    onMouseDown={() => setActive(true)}
                    // error={roleError}
                    >
                                       
                        <MenuItem  value="InformationText/Plain">
                            Plain text
                        </MenuItem>
                        <MenuItem  value="InformationText/Html">
                            HTML
                        </MenuItem>
                    </Select>               
                  {/* <FormHelperText style={{ color: "#d32f2f" }}>
                    Role is required
                  </FormHelperText> */}
                    </FormControl>
                   
                </div>
                <Grid container>
                <Grid item xs={12} className="pt_info_editor">
                <span className="pt_editor_type_title">{type !=="InformationText/Html"?"Informational text":"Default Text"}</span>
                      {infoType !=="InformationText/Html"? 
                       <FormControl variant="standard" className="pt_w_100">
                       {/* <label className="input-form-label" htmlFor="statement" id="demo-simple-select-label">
                         Our company statement
                       </label> */}
                        <ClickAwayListener  mouseEvent="onMouseDown"
                          touchEvent="onTouchStart" onClickAway={() => inputChanged && clickAwaySaveptData()}>
                       <TextareaAutosize
                         className="textArea"
                         id="infor_text"
                         aria-label="minimum height"
                         minRows={3}
                         placeholder="Add informational text"
                         style={{ width: "100%", resize: "none",padding:"15px" }}
                         onChange={(e)=>handleChangeInfotext(e)}
                         disabled={templateState.roleId !== 2 && templateState.roleId !== 3}
                         // error={statementError}
                         // helperText={statementError ? statementErrorText : ""}
                         value={textAreaContent}
                         InputProps={{
                           inputComponent: TextareaAutosize,
                         }}
                       />
                       </ClickAwayListener>
                     </FormControl>                     
                      :
                      <ClickAwayListener  mouseEvent="onMouseDown"
                          touchEvent="onTouchStart" onClickAway={() => inputChanged && clickAwaySaveptData()}>
                      <div>
                      <SunEditor
                        setDefaultStyle="font-size: 12px"
                        contenteditable={true}                        
                        setOptions={{
                        buttonList: [["bold", "italic", "underline", "list", "fontColor", "fontSize"]],
                        }}
                        disable={templateState.roleId !== 2 && templateState.roleId !== 3}
                        placeholder="Add informational text"
                        onChange={handleChange}
                        // defaultValue={editorContent}
                        setContents={editorContent}
                        />
                        </div>
                          </ClickAwayListener>
                        }
                </Grid>
                </Grid>
            </div>
            <div className={`${templateState.roleId !== 2 && templateState.roleId !== 3?'pt_ip_action pt_ip_disabled':'pt_ip_action pt_ip_enabled'}`} ><MoreHorizIcon className="pt_icon_color" 
            onClick={templateState.roleId !== 2 && templateState.roleId !== 3? null : handleClickMore}/>
            <Menu
              id="fade-menu"
              MenuListProps={{'aria-labelledby': 'fade-button',}}
              anchorOrigin={{vertical: 'top',horizontal: 'right',}}
              transformOrigin={{vertical: 'top',horizontal: 'right',}}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              className="pt_more_menu"
              >
                <span><CloseIcon onClick={handleClose}/></span>
        <MenuItem onClick={()=>deleteElement(templateState, setTemplateState, parentType, uuid, catuuid, updatePtTempateData,handleClose)}><DeleteIcon/>Delete</MenuItem>
            </Menu>
      </div>
        </div>      
    </Grid>
    </ClickAwayListener>
    </>
  )
}

export default InformationalText