//FILE IMPORTS
import moment from "moment";
import { Box } from "@mui/system";
import { apiCall } from "../../utils/action";
import { useLocation } from "react-router-dom";
import Header from "../../common/header/Header";
import Preloader from "../../helpers/Preloader";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../common/header/Breadcrumb";
import "../../../assets/styles/containers/_inspDetails.scss";
import Layout from "../../components/InspectionDetails/Layout";
import { Contacts } from "../../components/InspectionDetails/Contacts";
import { Inspector } from "../../components/InspectionDetails/Inspector";
import { Agreements } from "../../components/InspectionDetails/Agreements";
import { Servicepage } from "../../components/InspectionDetails/Servicepage";

const InspectionDetails = () => {
  const location = useLocation();
  const [tabvalue, settabvalue] = useState(0);
  const [showpreloader, setShowpreloader] = useState(false);

  const [refresh, setRefresh] = useState(false);
  // eslint-disable-next-line
  const [totalPrice, setTotalPrice] = useState(0);
  const [companyPaymentStatus, setCompanyPaymentStatus] = useState(0);
  //property address
  const [values, setValues] = useState({
    inspectionid: location.state?.id,
    address: "",
    location_address_2: "",
    city: "",
    state: "",
    zip: "",
  });
  //property details
  const [propertyvalue, setPropertyvalue] = useState({
    foundation: "",
    squarefeet: "",
    garage: "",
    type: "",
    bedrooms: "",
    yearbuild: "",
    bathroom: "",
    images: [],
  });
  //schedule status
  const [statusvalue, setstatusValue] = useState({
    paid: "",
    agreementsigned: "",
    status: "",
    agreementLength:0,
    startTime: "2022-05-11T08:07:18.000000Z",
  });
  //inspector component value(s)
  const [orderInspector, setOrderInspector] = useState({
    bucketurl: "",
    orderinspector: [],
  });
  //report details
  const [reportDetails, setReportDetails] = useState([]);
  //agreement component value(s)
  const [agreements, setAgreements] = useState([]);
  const [servicePaymentStatus, setServicePaymentStatus] = useState();
  //guid
  const [guid, setGuid] = useState("");
  //contact component value(s)
  const [contacts, setContacts] = useState({
    allclient: [],
    selectedclient: [],
    buyerAgent: [],
  });
  // eslint-disable-next-line
  const [rescheduleInspector, setRescheduleinspector] = useState([]);
  let reschInspector = [];
  orderInspector?.orderinspector?.forEach((insp) =>
    reschInspector.push({
      id: insp?.inspector_id,
      name: insp?.inspector?.last_name + " " + insp?.inspector?.first_name,
    })
  );
  //selected client array
  let selectedclientArray = [];
  contacts.selectedclient?.forEach((client) =>
    selectedclientArray.push(client?.client?.id)
  );
  //selected agent array
  let selectedagentArray = [];
  contacts.buyerAgent?.forEach((agent) =>
    selectedagentArray.push({
      id: agent?.agent?.id,
      agentType: agent?.agent_type,
    })
  );

  //all client value
  const [allclients, setallclients] = useState([]);
  //all agent value
  const [allagents, setallagents] = useState([]);
  //client array
  let clientArray = [];
  allclients?.forEach((value) =>
    clientArray.push({
      id: value.id,
      first_name: value.first_name,
      last_name: value.last_name,
      email: value.email,
      phone: value.phone,
    })
  );
  let agentArray = [];
  allagents?.forEach((value) =>
    agentArray.push({
      id: value.id,
      first_name: value.first_name,
      last_name: value.first_name,
    })
  );
  //default client array
  let defaultclientArray = [];
  allclients?.forEach((values, index) =>
    selectedclientArray.includes(values.id)
      ? defaultclientArray.push(index)
      : ``
  );
  //selected agent value
  let selectedclientValue = [];
  defaultclientArray?.forEach((value) =>
    selectedclientValue.push(clientArray[value])
  );
  //default agent array
  let defaultagentArray = [];
  allagents?.forEach((values, index) =>
    selectedagentArray.find((val) => val.id === values.id)
      ? defaultagentArray.push({
          agentIndex: index,
          agentType: selectedagentArray.find((val) => val.id === values.id)
            .agentType,
        })
      : ``
  );
  let selectedagentValue = [];
  defaultagentArray?.forEach((value) =>
    selectedagentValue.push({
      id: agentArray[value.agentIndex].id,
      first_name: agentArray[value.agentIndex].first_name,
      last_name: agentArray[value.agentIndex].last_name,
      agent_type: value?.agentType,
    })
  );
  //service component value(s)
  const [services, setServices] = useState([]);
  const [allservices, setallServices] = useState([]);
  const [srcKey, setSrcKey] = useState();
  const [orderCharge, setOrderCharge] = useState([]);
  const [inspGuid, setInspGuid] = useState("");
  const [orderDiscount, setOrderDiscount] = useState([]);
  const [agreementSigned, setAgreementSigned] = useState(false);
  const [totalDue, setTotalDue] = useState();
  //service array
  let serviceArray = [];
  allservices?.forEach((values) =>
    serviceArray.push({
      id: values.id,
      name: values.name,
      minutes: values.minutes,
      amount: values.amount,
    })
  );
  let customCharge = [];
  orderCharge?.forEach((value) =>
    customCharge.push({
      id: value.order_id,
      name: value.description,
      amount: value.price,
      type: "charge",
    })
  );
  let customDiscount = [];
  orderDiscount?.forEach((value) =>
    customDiscount.push({
      id: value.order_id,
      name: value.description,
      amount: value.discount * -1,
      type: "discount",
    })
  );
  //payment status
  const paymentStatus = location?.state?.paymentStatus;
  const in_trial = location?.state?.in_trial;
  //selected services
  let selectedServices = [];
  services?.forEach((values) => selectedServices.push(values.service?.id));
  //default services
  let defaultserviceArrary = [];
  allservices?.forEach((values, index) =>
    selectedServices.includes(values.id) ? defaultserviceArrary.push(index) : ``
  );
  //selected services values
  let selectedServicesValue = [];
  // below one line commented for edit service feature for scheduld inspection
  // defaultserviceArrary?.forEach((value) => selectedServicesValue.push(serviceArray[value]));
  // added below lines for the edit service feature for scheduled inspections
  services.forEach((ser) =>
    allservices.map((ser1) => {
      if (ser.service.id === ser1.id) {
        return selectedServicesValue.push({
          id: ser1.id,
          minutes: ser1.minutes,
          amount: ser.service?.amount,
          name: ser.service.name,
        });
      }
    })
  );
  //get inspection details api call
  const getInspDetails = () => {
    setShowpreloader(true);
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      inspection_id: location.state?.id,
    };
    apiCall(
      (response) => {
        if (response.data.success) {
          setShowpreloader(false);
          let agreementsCount;
          if(response?.data?.data?.agreement_status !== 1){
            agreementsCount = null;
          }else{
            agreementsCount = response?.data?.data?.total_agreements;
          }
          setRescheduleinspector(reschInspector);
          setServices(response.data.data?.orderservice);
          setInspGuid(response.data.data.guid);
          setOrderCharge(response.data.data?.ordercharge);
          setSrcKey(response.data.data.secret_key);
          setGuid(response.data.data.inspectiondetails.guid);
          setOrderDiscount(response.data.data?.orderdiscount);
          setCompanyPaymentStatus(response.data.data.company);
          setReportDetails(response.data.data.inspectiondetails);
          setAgreementSigned(
            response.data.data.is_agreement_signed === 1 ? true : false
          );
          setAgreements(
            response.data.data.is_agreement_signed === 1
              ? response.data.data.agreements.signed_agreements
              : response.data.data.agreements.unsigned_agreements
          );
          setServicePaymentStatus(
            response.data.data?.inspection_fees.payment_status
          );
          setContacts({
            ...contacts,
            selectedclient: response.data.data?.orderclient,
            buyerAgent: response.data.data?.orderagent,
          });
          setOrderInspector({
            ...setOrderInspector,
            bucketurl: response.data.data.bucket_url,
            orderinspector: response.data.data.orderinspector,
          });
          setstatusValue({
            ...statusvalue,
            paid: response.data.data.paid,
            agreementsigned: response.data.data.is_agreement_signed,
            status: response.data.data.status,
            agreementLength:agreementsCount,
            startTime: moment(
              moment.utc(response?.data?.data?.start_time).local()
            ),
          });
          setValues({
            ...values,
            address: !response.data.data.location_address
              ? ""
              : response.data.data.location_address,
            location_address_2: !response.data.data.location_address_2
              ? ""
              : response.data.data.location_address_2,
            city: !response.data.data.location_city
              ? ""
              : response.data.data.location_city,
            state: !response.data.data.location_state
              ? ""
              : response.data.data.location_state,
            zip: !response.data.data.location_zip
              ? ""
              : response.data.data.location_zip,
          });
          setTotalDue(response.data.data?.inspection_fees.amount_due);
          try {
            setPropertyvalue({
              ...propertyvalue,
              type: response.data.data?.propertydetails?.type,
              garage: response.data.data?.propertydetails?.total_garage,
              foundation: response.data.data?.propertydetails?.foundation,
              squarefeet: response.data.data?.propertydetails?.total_area,
              bedrooms: response.data.data?.propertydetails?.total_bedrooms,
              bathroom: response.data.data?.propertydetails?.total_bathrooms,
              yearbuild: response.data.data?.propertydetails?.year_built,
              images: response.data.data?.propertydetails?.other_image_urls,
            });
          } catch (err) {
            setPropertyvalue({
              ...propertyvalue,
              type: "",
              garage: "",
              foundation: "",
              squarefeet: "",
              bedrooms: "",
              bathroom: "",
              yearbuild: "",
              images: null,
            });
          }
        } else {
          // console.log("else", response.data.message);
        }
      },
      formdata,
      "GET_INSPECTION_DETAILS"
    );
  };
  //get all details api call
  const getalldetails = () => {
    setShowpreloader(true);
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
    };
    apiCall(
      (response) => {
        if (response.data.success) {
          setallagents(response.data.data.agents);
          setallclients(response.data.data.clients);
          setallServices(response.data.data.serviceswithtemplates);
        } else {
          // console.log("else", response.data.message);
        }
      },
      formdata,
      "NEW_INSPECTION_DETAILS"
    );
  };

  //first initialize
  useEffect(() => {
    getInspDetails();
    getalldetails();
    window.scroll(0, 0);
    // eslint-disable-next-line
  }, [refresh]);

  const handleTotalPrice = (price) => {
    setTotalPrice(price);
  };
  const CancelledStatus = statusvalue.status === "Cancelled";
  const CompleteStatus = statusvalue.status === "Complete";
  const PublishedStatus = statusvalue.status === "Published";

  //render value
  return (
    <>
      {/* {window.scrollTo(0, 0)} */}
      <Preloader showPreloader={showpreloader} />
      <Box sx={{ flexGrow: 1 }} className="page-background">
        <Header />
        {/* {statusvalue.status === 'Draft' ? <AlertMessage message={message} type={'error'} icon={<ErrorOutlineIcon sx={{ color: '#F44336', mr: '12px', width: '22px', height: '22px' }}></ErrorOutlineIcon>} /> : ''} */}
        <Breadcrumb value={location.state?.id} />
        <div className="inspection-details">
          <Layout
            srcKey={srcKey}
            inspGuid={guid}
            companyPaymentStatus={companyPaymentStatus}
            address={values}
            property={propertyvalue}
            status={statusvalue}
            tabvalue={tabvalue}
            settabvalue={settabvalue}
            guid={guid}
            statusValue={statusvalue}
            reportData={reportDetails}
            orderId={location.state?.id}
            refresh={refresh}
            setRefresh={setRefresh}
            CancelledStatus={CancelledStatus}
            CompleteStatus={CompleteStatus}
            PublishedStatus={PublishedStatus}
            orderInspector={orderInspector}
            reschInspector={reschInspector}
            in_trial={in_trial}
          />
          <br />
          {tabvalue === 0 ? (
            <>
              <Contacts
                CancelledStatus={CancelledStatus}
                inspectionId={location.state?.id}
                contacts={contacts}
                allclient={clientArray}
                allagents={allagents}
                refresh={refresh}
                setRefresh={setRefresh}
                selectedclientArray={selectedclientArray}
                selectedagentArray={selectedagentArray}
                defaultagentArray={defaultagentArray}
                clientArray={clientArray}
                defaultArray={defaultclientArray}
                selectedclientValue={selectedclientValue}
                selectedagentValue={selectedagentValue}
              />
              <br />
              <Inspector
                CancelledStatus={CancelledStatus}
                inspector={orderInspector}
              />
              <br />
              <Agreements
                agreementSigned={agreementSigned}
                status={statusvalue}
                agreements={agreements}
                CancelledStatus={CancelledStatus}
                inspectionId={location.state?.id}
              />
              <br />
              <Servicepage
                inspectionId={location.state?.id}
                services={services}
                CancelledStatus={CancelledStatus}
                allservices={allservices}
                totalDue={totalDue}
                orderCharge={orderCharge}
                servicePaymentStatus={servicePaymentStatus}
                customDiscount={customDiscount}
                customCharge={customCharge}
                orderDiscount={orderDiscount}
                refresh={refresh}
                status={statusvalue}
                setRefresh={setRefresh}
                handleTotalPrice={(price) => handleTotalPrice(price)}
                selectedServices={selectedServices}
                serviceArray={serviceArray}
                defaultserviceArrary={defaultserviceArrary}
                selectedServicesValue={selectedServicesValue}
                paymentStatus={paymentStatus}
              />
            </>
          ) : (
            ""
          )}
        </div>
      </Box>
    </>
  );
};

export default InspectionDetails;