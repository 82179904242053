import { Chip } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { BootstrapTooltip, getComparator, InspStatusBg, InspStatusColor, stableSort } from "../../utils/services";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const TableBody = (props) => {
  const { paymentStatus, agreementStatus,inspListApiResponseMessage } = props;
  let navigate = useNavigate();
  const { table_data, order, orderBy, page, rowsPerPage, type } = props;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - table_data.length) : 0;
  return (
    <tbody className="insp-list-tbody">
      {table_data.length !== 0
        ? stableSort(table_data, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              //variable for shorting
              let mainInspectorName = !row.Inspector[1] ? row.Inspector : row.Inspector[0] + "\n" + row.Inspector[1];
              let mainAgentName = row.Agent.join(",");
              // let mainAgentName =  row.Agent[0] + "\n" + row.Agent[1]+row.Agent[2]+row.Agent[3];
              let mainClientName = !row.Client[1] ? row.Client : row.Client[0] + "\n" + row.Client[1];
              let updArray = row.Updated.split("-");
              let uptArray = row["Date/Time"].split("-");
              let listAddressArray = row.Address.split("-");
              let paid_unpaid = row["$"];
              let fileIcon = row.fileIcon;
              // let inspe=row.Inspector
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <tr role="checkbox" tabIndex={-1} key={`${row.client}_${index}`} className="inspections-data-table">
                  <td className="insp-address" component="th" id={labelId} padding="none" onClick={() => navigate(`/inspections/inspection`, { state: { id: row.id, paymentStatus, in_trial: props.in_trial } })}>
                    {row.Image}
                  </td>

                  <td className="insp-address" component="th" id={labelId} padding="none" onClick={() => navigate(`/inspections/inspection`, { state: { id: row.id, paymentStatus, in_trial: props.in_trial } })}>
                    <div className="table-body-row">
                      <span>
                        {/* { listAddressArray[1]} */}
                        {listAddressArray[0] !== null && listAddressArray[0]?.length > 15 ? `${listAddressArray[0].substring(15, 0)}...` : listAddressArray[0]}

                        <br />

                        {/* { listAddressArray[1]} */}
                        {listAddressArray[1] !== null && listAddressArray[1]?.length > 10 ? `${listAddressArray[1].substring(10, 0)}...` : listAddressArray[1]}
                        <br />
                        {/* { listAddressArray[1]} */}
                        {listAddressArray[2] !== null && listAddressArray[2]?.length > 10 ? `${listAddressArray[2].substring(10, 0)}...` : listAddressArray[2]}
                      </span>
                    </div>
                    {/* {row.Address} */}
                  </td>

                  <td
                    className="insp-status"
                    // style={{ textAlign: type !== "dashboard" ? "left" : "left" }}
                    align="left"
                  >
                    {/* {row.Status} */}
                    <Chip
                      sx={{
                        backgroundColor: InspStatusBg(row.Status),
                        color: InspStatusColor(row.Status),
                      }}
                      label={
                        <h3
                          style={{
                            fontSize: "9px",
                            fontWeight: "bold",
                            textTransform: "uppercase",
                            minWidth: "65px",
                            textAlign: "center",
                          }}
                        >
                          {row.Status}
                        </h3>
                      }
                    />
                  </td>

                  <td className="insp-date_time" align="left">
                    {/* {row["Date/Time"]} */}
                    <span>
                      <span className="date-placeholder">{uptArray[0]}</span>
                      <span className="time-placeholder">{uptArray[1]}</span>
                    </span>
                  </td>

                  <td className="insp-client insp-details" align="left">
                    <span>
                      <p>
                        <BootstrapTooltip title={mainClientName?.length > 15 ? <h1 className="font-13">{mainClientName}</h1> : ""}>
                          <span>{mainClientName?.length > 15 ? `${mainClientName.substring(15, 0)}... ` : mainClientName}</span>
                        </BootstrapTooltip>
                      </p>
                      {/* {row.Client} */}
                    </span>
                  </td>

                  <td className="insp-id" align="left">
                    <span> {row["Id#"]}</span>
                  </td>

                  <td className="insp-agent insp-details" align="left">
                    <span>
                      <p>
                        <BootstrapTooltip title={mainAgentName?.length > 15 ? <h1 className="font-13">{mainAgentName}</h1> : ""}>
                          <span>{mainAgentName?.length > 15 ? `${mainAgentName.substring(15, 0)}... ` : mainAgentName}</span>
                        </BootstrapTooltip>
                      </p>
                      {/* {row.Agent} */}
                    </span>
                  </td>

                  <td className="insp-inspector insp-details" align="left">
                    <span>
                      <p>
                        <BootstrapTooltip title={mainInspectorName?.length > 15 ? <h1 className="font-13">{mainInspectorName}</h1> : ""}>
                          <span>{mainInspectorName?.length > 15 ? `${mainInspectorName.substring(15, 0)}... ` : mainInspectorName}</span>
                        </BootstrapTooltip>
                      </p>

                      {/* {row.Inspector} */}
                    </span>
                  </td>
                  {type !== "dashboard" && (
                    <>
                      <td className="insp-fee" align="left">
                        {"$" + row.Fee}
                      </td>
                      <td className="insp-dollar" align="left">
                        {/* shorting for the paid and unpaid (anil) */}
                        <div>
                          {paymentStatus === 2 ? (
                            paid_unpaid === 1 ? (
                              <BootstrapTooltip title={<h1 className="font-13">Paid</h1>}>
                                <CheckCircleIcon sx={{ color: "#4282E2", fontSize: 18 }} />
                              </BootstrapTooltip>
                            ) : paid_unpaid === 2 ? (
                              <BootstrapTooltip title={<h1 className="font-13">Unpaid (waived)</h1>}>
                                <CancelIcon sx={{ color: "#E30303", fontSize: 18 }} />
                              </BootstrapTooltip>
                            ) : (
                              <BootstrapTooltip title={<h1 className="font-13">Unpaid</h1>}>
                                <CancelIcon sx={{ color: "#E30303", fontSize: 18 }} />
                              </BootstrapTooltip>
                            )
                          ) : paid_unpaid === 0 ? (
                            <CancelIcon sx={{ color: "#d3d3d3", fontSize: 18 }} />
                          ) : (
                            <CheckCircleIcon sx={{ color: "#d3d3d3", fontSize: 18 }} />
                            // <CancelIcon sx={{ color: "#d3d3d3", fontSize: 18 }} />
                            // <BootstrapTooltip title={<h1 className="font-13">Payment not enabled</h1>}>
                            //   <CancelIcon sx={{ color: "#d3d3d3", fontSize: 18 }} />
                            // </BootstrapTooltip>
                            // <BootstrapTooltip title={<h1 className="font-13">Payment not enabled</h1>}>
                            //   <CheckCircleIcon sx={{ color: "#d3d3d3", fontSize: 18 }} />
                            // </BootstrapTooltip>
                          )}
                        </div>
                        {/* {row["$"]} */}
                      </td>
                      <td className="insp-file-icon" align="left">
                        <div>
                          {/* shorting of fileIcon (anil) */}
                          {agreementStatus === 1 ? (
                            fileIcon === 1 ? (
                              <BootstrapTooltip title={<h1 className="font-13">Signed</h1>}>
                                <CheckCircleIcon sx={{ color: "#4282E2", fontSize: 18 }} />
                              </BootstrapTooltip>
                            ) : fileIcon === 2 ? (
                              <BootstrapTooltip title={<h1 className="font-13">Unsigned (waived)</h1>}>
                                <CancelIcon sx={{ color: "#E30303", fontSize: 18 }} />
                              </BootstrapTooltip>
                            ) : (
                              <BootstrapTooltip title={<h1 className="font-13">Unsigned</h1>}>
                                <CancelIcon sx={{ color: "#E30303", fontSize: 18 }} />
                              </BootstrapTooltip>
                            )
                          ) : fileIcon === 0 ? (
                            <CancelIcon sx={{ color: "#d3d3d3", fontSize: 18 }} />
                          ) : (
                            <CheckCircleIcon sx={{ color: "#d3d3d3", fontSize: 18 }} />
                            // <BootstrapTooltip title={<h1 className="font-13">Agreements not enabled</h1>}>
                            //   <CancelIcon sx={{ color: "#d3d3d3", fontSize: 18 }} />
                            // </BootstrapTooltip>
                            // <BootstrapTooltip title={<h1 className="font-13">Agreements not enabled</h1>}>
                            //   <CheckCircleIcon sx={{ color: "#d3d3d3", fontSize: 18 }} />
                            // </BootstrapTooltip>
                          )}
                        </div>
                        {/* {row.fileIcon} */}
                      </td>
                      <td className="insp-updated" align="left">
                        {/* {row.Updated} */}
                        <div style={{ textAlign: "left" }}>
                          <span className="date-placeholder">{updArray[1]}</span>
                          <span className="time-modified-placeholder">{updArray[0]}</span>
                        </div>
                      </td>
                      <td className="insp-actions" align="left">
                        {row.Actions}
                      </td>
                      {/* cancel button (anil) */}
                      <td className="insp-actions" align="left">
                        {row.Cancel}
                      </td>
                    </>
                  )}
                </tr>
              );
            })
        : inspListApiResponseMessage !== undefined?inspListApiResponseMessage :''}
      {emptyRows > 0 && (
        <tr
          style={{
            height: 33 * emptyRows,
          }}
        >
          <td colSpan={6} />
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
