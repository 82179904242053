import React, { useEffect, useState } from "react";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import DeleteIcon from "@mui/icons-material/Delete";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Grid } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import moment from "moment";
import uuid from "react-uuid";
import { timestamp } from "../../../utils/services";

const ChecksEdit = (props) => {
  const {
    values,
    AllCategoryList,
    lineId,
    selectedID,
    setDescArr,
    categoryId,
    from,
    item,
    isPlaceHolder,
  } = props;
  const unique_id = uuid();

  let DescriptionsArray = [];
  if (values !== undefined) {
    DescriptionsArray = values?.map((el, index) => {
      return {
        id: index + 1,
        uuid: el.uuid,
        value: el.value,
        is_selected: el.is_selected,
        is_deleted: el.is_deleted,
        created_at: el.created_at,
        updated_at: el.updated_at,
        order: el.order,
      };
    });
  }

  const [descValue, setDescValue] = useState(DescriptionsArray);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      descValue,
      result.source.index,
      result.destination.index
    );
    items.forEach((element, i) => { 
      element.order = i + 1; 
      element.updated_at = timestamp();
      return element
    });

    setDescValue(items);
  };

  const addNewTextHandle = (descValue) => {
    const checkBox = [...descValue];
    checkBox.push({
      id: checkBox.length + 1,
      uuid: unique_id,
      value: `check ${checkBox.length + 1}`,
      is_selected: false,
      created_at: timestamp(),
      updated_at: timestamp(),
      order:checkBox.length + 1,
    });
    setDescValue(checkBox);
  };

  const deleteLocationHandle = (id) => {
    let deleteTempArray = [...descValue];
    let deleteObject = deleteTempArray.find((e) => e.uuid === id);
    deleteObject.is_deleted = 1;
    deleteObject.updated_at = timestamp();
    setDescValue(deleteTempArray);
  };
  
  const locationEdithandle = (value, id) => {
    let editTempArray = [...descValue];
    let editObj = editTempArray.find((e) => e.uuid === id);
    editObj.value = value;
    editObj.updated_at = timestamp();
    setDescValue(editTempArray);
  };

  const handlePreventSpaces = (e) => {
    var firstChar = e.target.value;
    if (e.keyCode == 32 && firstChar == "") {
      return e.preventDefault(e);
    }
  };

  setDescArr(descValue);

  return (
    <>
      <DragDropContext
        onDragEnd={(result) => {
          onDragEnd(result);
        }}
      >
        <Droppable droppableId="droppable" direction="vertical">
          {(provided) => (
            <Grid
              container
              display={"flex"}
              className="section__checks"
              ref={provided.innerRef}
            >
              {descValue.map((val, index) => (
                val.is_deleted !== 1 &&
                <Draggable
                  key={val.id}
                  draggableId={String(val.id)}
                  index={index}
                >
                  {(provided) => (
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      md={12}
                      lg={4}
                      mb={2}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      key={index}
                      className="edit-loc-wrapper"
                    >
                      <DragHandleIcon className="drag-handle-icon" />
                      <input
                        type="text"
                        value={val.value}
                        onKeyDown={handlePreventSpaces}
                        maxLength="100"
                        onChange={(e) =>
                          locationEdithandle(e.target.value, val.uuid)
                        }
                      />
                      <DeleteIcon
                        className="loc-delete-icon"
                        onClick={() => deleteLocationHandle(val.uuid)}
                      />
                    </Grid>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Grid>
          )}
        </Droppable>
      </DragDropContext>
      <div className="isFlex add-cta">
        <span onClick={() => addNewTextHandle(descValue)}>
          <AddCircleOutlineIcon /> Add choice
        </span>
      </div>
    </>
  );
};

export default ChecksEdit;
