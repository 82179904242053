import { useState,useEffect } from "react"
import { Box,Card,Grid, SvgIcon} from "@mui/material"
import Preloader from "../../helpers/Preloader"
import "../../../assets/styles/containers/_payments.scss";
import { ptHorizontalSvg } from "../../../assets/svgIcons/Svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AntSwitch from "../../common/antSwitch/AntSwitch";
import { apiCall } from "../../utils/action";
import toast from "react-hot-toast";
import Logo from '../../../assets/images/logo-horizontal.png'
import { useNavigate } from "react-router-dom";

const ImportSettings = () => {
    const navigate = useNavigate()
  return (
    <Box sx={{ flexGrow: 1 }} className="page-background">   
    <Box
          sx={{
            width: "100%",
            px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },
          }}
          className="teams-container"
        >
            <Grid item xs={12} className="guardian-title-wrapper">
            <div className="p-8">
              <h3 className="guardian-title">Import</h3>
              <p className="payments-subtitle">
              Bring your templates, inspection points, descriptions and notes into Palmtech without having to recreate your inspection templates and comments.
              </p>
            </div>
          </Grid>
          <Grid container>
              <Grid item xs={12} sm={7} md={5.5}>
                <Card className="settings-card guardian mh-170" id={`guardian_settings`} name={`guardian_settings`} variant="outlined" 
                // onClick={()=>{permission && navigate(`${navigatePath}`)}} style={{cursor:`${!permission && 'not-allowed'}`}}
                >     
                <Grid container className="details-cards">
                    <Grid className="guardian__svg__header">
                    <div className="import__svg"><SvgIcon viewBox="" >{ptHorizontalSvg}</SvgIcon></div>
                    {/* <div className="paltech_logo___svg"><img src={Logo}/></div> */}
                    {/* {paymentSetupStatus!==0 && paymentStatus !==0 &&
                    <span>{paymentDescription} {paymentStatus === 2 && <CheckCircleIcon/>}</span>
                        } */}
                    </Grid>
                    <Grid className='name-wrapper'>
                    <span className='guardian__desc mt-0'>Import your templates and library from previous versions of Palmtech. 
                   </span> 
                    </Grid>
                    <Grid className='guardian__action'>
                        <span>                      
                        <a
                        className="navlink guardian"
                        style={{cursor:'pointer'}}
                        // target="_blank"
                        // href="#"
                        onClick={()=>navigate('/settings/import-data')}
                      >
                        <span> Set up now</span>
                      </a>                        
                      
                       
                        </span> <span className="arrow"><KeyboardArrowRightIcon/></span></Grid>       
                </Grid>
                </Card>
            </Grid>
          </Grid>          
    </Box>
    </Box>
  )
}

export default ImportSettings