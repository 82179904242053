//file imports-------------------------------------------------------------------------------------------------------------------/
import axios from "axios"; //API calls
import validator from "validator"; 
import { useState } from "react";
import { LoadingButton } from "@mui/lab"; 
import toast  from 'react-hot-toast';
import { useNavigate,Link } from "react-router-dom";
import { API_CONSTANTS } from "../../config/api-constants";
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined'; //email send icon
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'; //back button
import logo from "../../../assets/images/logo.png"; //Palm-tech logo
import "../../../assets/styles/containers/_signup.scss"; //SCSS import
import { Grid, Paper, TextField, Typography, InputLabel, FormControl, useMediaQuery } from "@mui/material"; //mui imports
//render function-----------------------------------------------------------------------------------------------------------------/
export const ForgotPassword = () => {
  let navigate = useNavigate(); //intialising navigator
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success , setSuccess] = useState(false);
  const matches = useMediaQuery("(min-width:900px)");
  const [emailerror, setemailError] = useState(false);

  // forgot password api call
  const forgotPassword =()=>{
    // setLoading(true);
    // const formdata = { email };
    // apiCall((response) => {
    //   console.log("FORGOT PASSWORD", response);
    //   const { success, status_code,message } = response.data;
    //   if (success) {
    //     // toast.success(message);
    //     setSuccess(true);
    //     setLoading(false);
    //   } else {
    //     toast.error(message);
    //     setLoading(false);
    //     console.log("else ", status_code);
    //   }
    // }, formdata,"FORGOT_PASSWORD");

    //--------------------------------------------------------------
    setLoading(true);
    toast.dismiss();
    const formdata = { email };
    axios
      .post(`${API_CONSTANTS.FORGOT_PASSWORD}`, formdata)
      .then((response) => {
        // Request with success code true
        setSuccess(true);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          if (error.response.data.success === false) {
            toast.error(error.response.data.message);           
            setLoading(false);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("no response", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setLoading(false);
      });
    //--------------------------------------------------------------
  };
  
  //vaidation function starts from here
  const validation = (email) => {
    if (validator.isEmail(email)) { 
      setemailError(false);
    } else {
      setemailError(true);
    }
    if (validator.isEmail(email)) forgotPassword();
  };
  // return function starts from here *******************************************/
  return (
    <> 
      {sessionStorage.getItem('token') !== null ? navigate('/dashboard') : 
      <>
      {/* background div for mobile and desktop******************************** */}
      <div className={`${matches ? "background" : "background-mobile"}`}>
        <Grid container direction="column" alignItems="center" justifyContent="center">
          {/* palm tech logo*************************************************** */}
          <img className={`${matches ? "logo" : "logo-mobile"}`} src={logo} alt="logo"></img>
          {/* login component starts from here********************************* */}
          <Grid item xs={3}>
           {!success ? 
           <Paper className="paper-normal" sx={{ minWidth: { xs: 370, md: 500, lg: 500 }, minHeight: { xs: 441, md: 372, lg: 372 }, boxShadow: "0 30px 30px 0 rgba(40, 40, 40, 0.4)" }}>
                {/* Back button***************************************** */}
                <ArrowBackOutlinedIcon className="back_arrow_icon" onClick={()=>navigate('/')}/>     
              <Grid className="center">
                {/* Heading********************************************* */}
                <Typography className="header" sx={{ mt:"9px", fontSize: { xs: "24px", md: "22px", lg: "22px" }}}>
                    Forgot your password?
                </Typography>
              </Grid>
              <Grid className="center">
                <Typography className="header" sx={{ mt: "27px", fontSize: { xs: "14px", md: "14px", lg: "14px" }, width: "351px", textAlign: "center" }}>
                    Enter the email associated with your account and we’ll send you instructions to reset your password.
                </Typography>
              </Grid>
              {/* Input field for email address ********************************* */}
              <Grid className="center">
                <FormControl variant="standard">
                  <InputLabel htmlFor="component-simple" className="input_lable" sx={{ top: { xs: "18px", md: "17px", lg: "17px" }, fontSize: { xs: "14px", md: "12px", lg: "12px" }}}>
                    EMAIL ADDRESS
                  </InputLabel>
                  <TextField type="email" className="input_field"  onKeyPress={(e) => { if (e.key === 'Enter') validation(e.target.value) }} variant="outlined" error={emailerror} placeholder="Enter email" size={`${matches ? "small" : "medium"}`}
                    helperText={`${emailerror === true ? "Please enter a valid email address." : ""}`}
                    onChange={(event) => setEmail(event.target.value)}
                    id="emal_id"
                    sx={{ marginTop: "60px", width: { xs: "340px", md: "317px", lg: "317px" }}}
                    InputProps={{ className:"input_props_field", sx: { height: { xs: "55px", md: "40px", lg: "40px" }, fontSize: { xs: "18px", md: "14px", lg: "14px" }}}}/>
                </FormControl>
              </Grid>
             {/* send instruction button ************************************************* */}
              <Grid className="center">
                <LoadingButton className="login_button" size="small" variant="contained" loading={loading} onClick={() => validation(email)}
                  sx={{ height: { xs: 42, md: 34, lg: 34 }, width: { xs: 180, md: 176, lg: 176 }, fontSize: { xs: 16, md: 13, lg: 13 }}}>
                  Send instructions
                </LoadingButton>
              </Grid>
              <Grid className="center" sx={{mt: { xs:8, md: 5, lg:5 }}}>
                <Link to="/">
                  <Typography className="link_signup" fontSize={{ xs: "16px", md: "12px", lg: "12px", xl: "12px" }}>
                    Return to login screen.
                  </Typography>
                </Link>
              </Grid>
            </Paper> : <>
            {/* Start of success message screen ************************************************************************ */}
            <Paper className="paper-normal" sx={{ minWidth: { xs: 370, md: 500, lg: 500 }, minHeight: { xs: 441, md: 348, lg: 348 }, boxShadow: "0 30px 30px 0 rgba(40, 40, 40, 0.4)" }}>
                 {/* back button ************************************* */}
                 <ArrowBackOutlinedIcon className="back_arrow_icon" onClick={()=>navigate('/')}/>  
              {/* Heading********************************************* */}
              <Grid className="center">
              <Typography className="header" sx={{ mt: "5px", fontSize: { xs: "24px", md: "22px", lg: "22px" }}}>
                  Check your email
              </Typography>
              </Grid>
              {/* email sent icon from mui**************************** */}
              <Grid className="center" sx={{ mt:3 }}>
                <DraftsOutlinedIcon sx={{width:"72px" , height:"78px" , color:"#4282e2"}}/>
              </Grid>
               {/*Disclaimer*****************************************  */}
              <Grid className="center">
                <Typography className="header" sx={{ fontSize: { xs: "16px", md: "14px", lg: "14px" }, width: "271px", textAlign: "center" }}>
                  If there’s an account associated with your email you’ll receive instructions.
                </Typography>
              </Grid>
              {/* Goto login page button****************************** */}
              <Grid className="center">
                <LoadingButton className="login_button"
                  sx={{ height: { xs: 42, md: 34, lg: 34 }, width: { xs: 180, md: 119, lg: 143 }, fontSize: { xs: 16, md: 13, lg: 13 }}}
                  size="small" variant="contained" loading={false} 
                  onClick={()=>navigate('/')}>
                  Back to login
                </LoadingButton>
              </Grid> 
              <Grid className="center" sx={{ textAlign:"center" , mt:{ xs:8, lg:3, md:3 }}}>
                <Typography className="link_nologin" fontSize={{ xs: "12px", md: "12px", lg: "12px", xl: "12px" }}>
                  Didn’t receive the email? Check your spam folder,<br/>or <span className="terms_and_condition_hyperlink" onClick={()=>setSuccess(false)} >try another email address.</span>
                </Typography>
              </Grid>
              </Paper>
              </>
              }
            {/* Footer starts from here****************************************************************************************************************** */}
            <Grid className="center" sx={{ marginTop: { xs: "123px", md: "41px", lg: "41px" }, marginBottom: { xs: "0px", md: "25px", lg: "25px" } }}>
              <div className="footer"><p>© 2023 Palmtech&nbsp;</p><a href="https://www.palm-tech.com/" rel="noreferrer" target="_blank" className="footer">www.palm-tech.com</a></div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      </>
    }
    </>
  );
};

export default ForgotPassword;