import { FormControl, Grid, Menu, MenuItem, Select, TextField } from "@mui/material"
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useState } from "react";
import Fade from '@mui/material/Fade';
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { debounce } from "lodash";
import { useEffect } from "react";
import { deleteElement,updateElementJsonByUUID, updateJSON } from "../PtTemplateHelperFunctions";

const InputField = (props) => {
  let {catuuid,templateState,setTemplateState,updatePtTempateData,label,uuid,type,parentType,merge_id,isDragging,index,setDragDisable,nestingLevel} = props
  const [inputFieldName,setInputFieldName] = useState('');
  const [inputFieldTag,setInputFieldTag] = useState('');
  const [editedInpDatas,setEditedInpDatas] = useState(false);
  const [editedInpMerge,setEditedInpMerge] = useState(false);
  const [active,setActive] = useState(false);
  const [mergeIdError,setMergeIdError] = useState(false);
  const [mergeIdErrorText,setMergeIdErrorText] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputType,setInputType] = useState('Input/Text')
  const open = Boolean(anchorEl);
  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleChangeInputType=(e)=>{
    let prevData = JSON.parse(JSON.stringify(templateState?.templateDataCopy));
    setInputType(e.target.value)
    setTemplateState(prev=>({...prev,updatingTemplate:true}))
    const updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, e.target.value,'type')
    if (updatedData) {
      prevData.data = updatedData;
      setTemplateState(prev=>({...prev,templateData:prevData}))
      let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
      let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
      let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
      newUpdatedSpecificData.data.template.pdf_categories = editedCategory
      updatePtTempateData(newUpdatedSpecificData?.data?.template)
    }
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

const clickAwaySaveptData=()=>{
  let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
  let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
  updateJSON(prevData, uuid, inputFieldName,"label");
  let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
  let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
  newUpdatedSpecificData.data.template.pdf_categories = editedCategory
  setTemplateState(prev=>({...prev,templateData:prevData}))
  updatePtTempateData(newUpdatedSpecificData?.data?.template)       
  setEditedInpDatas(false)
}
const clickAwaySaveptMergeData=()=>{ 
  if(mergeIdError) { 
    setInputFieldTag('')
    let updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, "",'tag');
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    prevData.data = updatedData;
    setTemplateState(prev=>({...prev,templateData:prevData}))
    setMergeIdError(false)
    setMergeIdErrorText('')
    setEditedInpMerge(false)
  }
  else{
  let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
  let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
  updateJSON(prevData, uuid, inputFieldTag,"tag");
  let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
  let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
  newUpdatedSpecificData.data.template.pdf_categories = editedCategory
  setTemplateState(prev=>({...prev,templateData:prevData}))
  updatePtTempateData(newUpdatedSpecificData?.data?.template)       
  setEditedInpMerge(false)
  }  
}

  // Create a debounced function to update the state
  const debouncedUpdateData = debounce((prevData) => { 
    setTemplateState(prev=>({...prev,templateData:prevData}))
  }, 2000); // Delay state update by 2 seconds
  const handleInputFieldNameChange = (e,changeType) => {
    e.persist();
    let updValue= e.target.value
    let updatedData;    
    let prevData = JSON.parse(JSON.stringify(templateState?.templateDataCopy));
    if(changeType === "label"){
      setEditedInpDatas(true)
      setInputFieldName(updValue);
      updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue,'label');
    }
    else if(changeType === 'tag'){
      setInputFieldTag(updValue)
      setEditedInpMerge(true);
      if(templateState?.mergeIdArray.includes(updValue)) {       
        setMergeIdError(true);
        setMergeIdErrorText('This merge ID is in use.')
        updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue,'tag');
      }
      else{
        setMergeIdError(false);
        setMergeIdErrorText('')
        updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue,'tag');
      }     
    }
    if (updatedData) {
      prevData.data = updatedData;
      debouncedUpdateData(prevData);
    }
  }
  const activateDragIn=()=>{
    setDragDisable(true);
    if(nestingLevel > 0){
       sessionStorage.setItem("firstLevelDrag", true);
    }
  }
  const activateDragOut=()=>{
    setDragDisable(false);
    if(nestingLevel > 0){
       sessionStorage.setItem("firstLevelDrag", false);
    }
  }
  useEffect(() => {
    setInputFieldName(label?.replace(/\\"/g, '"'))
    setInputType(type)
    setInputFieldTag(merge_id)
    if(!templateState?.allMergeIdFilled && merge_id === null) {
        setMergeIdError(true);
        setMergeIdErrorText('Merge ID is required.')
    }
  }, [templateState])
  return (
    <ClickAwayListener  mouseEvent="onMouseDown"
                        touchEvent="onTouchStart" onClickAway={() => active && setActive(false)}>
    <Grid container className="pt_card_border pt_inputfield" 
    onMouseEnter={()=>parentType === "expandingCheckBox" || parentType==="group"?activateDragIn():null} onMouseLeave={()=>parentType === "expandingCheckBox" || parentType==="group"?activateDragOut():null} 
    onClick={()=>setActive(true)} >
        <div className={`${isDragging || active?'pt_ip_drag_section pt_elem_dragging':"pt_ip_drag_section"} `} ><DragIndicatorIcon className="pt_icon_color"/></div>
        <div className="pt_ip_content">
            <div className="pt_ip_data">
                <div className="pt_ip_data_row1 pt_is_flex_align_center pt_space_between" >
                    <div className="pt_is_flex_align_center gap5">
                        <span className="pt_element_span pt_all_center">A</span>
                        <span className="pt_element_name">Input field</span>
                    </div>
                    <FormControl fullWidth >                    
                    <Select labelId="input_type" disabled={templateState.roleId !== 2 && templateState.roleId !== 3} id="input_type" size="small" value={inputType} 
                    onMouseDown={() => setActive(true)}
                        onChange={(e)=>handleChangeInputType(e)}>
                        <MenuItem  value="Input/Text">Text</MenuItem>
                        <MenuItem  value="Input/Number"> Number</MenuItem>
                        <MenuItem  value="Input/Date">Date</MenuItem>
                        <MenuItem  value="Input/Year">Year</MenuItem>
                        <MenuItem  value="Input/Multiline">Multiline</MenuItem>
                    </Select> 
                    </FormControl>
                </div>
                <Grid container>                
                <Grid item xs={12} className="pt_merge_field">
                <label className="pt_form_label" htmlFor="pt_input_label_merge" id="pt_input_label">
                merge field id
                </label>
                <ClickAwayListener  mouseEvent="onMouseDown"
                        touchEvent="onTouchStart" onClickAway={() => editedInpMerge && clickAwaySaveptMergeData()}>
                <TextField
                  style={{width:'50%',display:'block',background:'#fff',borderColor: mergeIdError ? '#d32f2f' : '#ced4da'}}
                  type="text"
                  id="pt_input_label_merge"
                  size="small"
                  name="pt_input_label_merge"
                  variant="outlined"
                  disabled={templateState.roleId !== 2 && templateState.roleId !== 3 }
                  value={inputFieldTag}
                  error={mergeIdError}
                  helperText={`${mergeIdError ? mergeIdErrorText  : ""}`}
                  onChange={(e)=>handleInputFieldNameChange(e,'tag')}
                  placeholder={inputType === "Input/Date"?'ID Tag - MM/DD/YYYY':'ID tag'}
                  className={mergeIdError?`input-textfield pt_focussed`:"input-textfield"}/>
                  </ClickAwayListener>
                </Grid>               
                <Grid item xs={12}>
                    <FormControl variant="standard" className="pt_w_100">
                      <label className="pt_form_label" htmlFor="pt_input_label" id="pt_input_label">
                        Label
                      </label>
                      <ClickAwayListener  mouseEvent="onMouseDown"
                        touchEvent="onTouchStart" onClickAway={() => editedInpDatas && clickAwaySaveptData()}>
                      <TextField
                        fullWidth
                        type="text"
                        id="pt_input_label"
                        size="small"
                        name="pt_input_label"
                        variant="outlined"
                        disabled={templateState.roleId !== 2 && templateState.roleId !== 3 }
                        value={inputFieldName}
                        onChange={(e)=>handleInputFieldNameChange(e,'label')}
                        placeholder="Input title"
                        className="input-textfield"/>
                        </ClickAwayListener>
                    </FormControl>
                  </Grid>
                </Grid>
            </div>
            <div className={`${templateState.roleId !== 2 && templateState.roleId !== 3?'pt_ip_action pt_ip_disabled':'pt_ip_action pt_ip_enabled'}`} ><MoreHorizIcon className="pt_icon_color" 
            onClick={templateState.roleId !== 2 && templateState.roleId !== 3? null : handleClickMore}/>
            <Menu id="fade-menu" MenuListProps={{'aria-labelledby': 'fade-button',}}
                anchorOrigin={{vertical: 'top',horizontal: 'right',}}
                transformOrigin={{vertical: 'top',horizontal: 'right',}}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                className="pt_more_menu">
                <span><CloseIcon onClick={handleClose}/></span>
              <MenuItem onClick={()=>deleteElement(templateState, setTemplateState, parentType, uuid, catuuid, updatePtTempateData,handleClose)}><DeleteIcon/>Delete</MenuItem>
            </Menu>
      </div>
      </div>
    </Grid>
    </ClickAwayListener>
  )
}

export default InputField