import { useState, useEffect, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import EditIcon from "@mui/icons-material/Edit";
import { useMediaQuery } from "react-responsive";
import DeleteIcon from "@mui/icons-material/Delete";
import TableSortLabel from "@mui/material/TableSortLabel";
import CloseIcon from "@mui/icons-material/Close";
import TableContainer from "@mui/material/TableContainer";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { visuallyHidden } from "@mui/utils";
import {
  Grid,
  Button,
  TablePagination,
  Alert,
  Modal,
  Typography,
} from "@mui/material";
import Img from "../../../../assets/images/propertyblank.jpg";
// styles imports
import "../../../../assets/styles/containers/_teams.scss";
import Sortswitch from "../../../../assets/images/Sortswitch.png";
// common components imports
import Preloader from "../../../helpers/Preloader";
import { apiCall, apiCallPaginated } from "../../../utils/action";
import { companyTableHead } from "../../../config/app-constants";
import EnhancedTableToolbar from "../../../common/table/EnhancedTableToolbar";
import {
  BootstrapTooltip,
  descendingComparator,
  getComparator,
  stableSort,
  GetCurrentResolution,
} from "../../../utils/services";

//map funtion of table heading
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {companyTableHead.map((headCell) => (
          <td
            key={headCell.id}
            align={headCell.label === "ACTIONS" ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            className={
              headCell.label === "ACTIONS"
                ? "team-table-head action-column"
                : "team-table-head"
            }
          >
            <TableSortLabel
              IconComponent={() =>
                headCell.label !== "ACTIONS" &&
                headCell.label !== "" && (
                  <img
                    src={Sortswitch}
                    alt="sort-icon"
                    className="sort-switch"
                  />
                )
              }
              // active={orderBy === headCell.id}
              // direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                // marginTop: GetCurrentResolution() === "mobile" && "21px",
                marginBottom: "10.5px",
                flexDirection: "row-reverse",
                fontSize: "12px",
              }}
            >
              <span className="head-title">
                {headCell.label}{" "}
                {headCell.label === "ROLE" ? (
                  <BootstrapTooltip title={<h1>Team roles</h1>}>
                    <InfoOutlinedIcon />
                  </BootstrapTooltip>
                ) : (
                  ""
                )}
              </span>
              {orderBy === headCell.id ? (
                <Box
                  component="span"
                  className="filter-icon"
                  sx={visuallyHidden}
                >
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </td>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const PTCompanyListView = () => {
  const [totalListCount, setTotalListCount] = useState(0);
  const [table_data, setTableData] = useState([]);
  const [curentPaginatedData, setCurrentPaginatedData] = useState([]);
  const [table_dataUnPaginated, setTableDataUnPaginatted] = useState([]);
  const [order, setOrder] = useState("asc");
  const [page, setPage] = useState(0);
  const [apiPageCount, setApiPageCount] = useState(0);
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  // const [roleList, setRoleList] = useState([]);
  const [showLoader, setShowLoader] = useState();
  const [excelData, setExcelData] = useState([]);
  const handleChipReset = () => setDisplayFilters(false);
  // const [deleteControl, setDeleteControl] = useState(false);
  const [displayFilters, setDisplayFilters] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const [deleteopen, setDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [search, setSearch] = useState("");
  const handleClose = () => setDeleteOpen(false);
  const handleClickOpenAlert = (id) => {
    setDeleteId(id);
    setDeleteOpen(true);
  };

  let result = JSON.parse(JSON.stringify(excelData));
  result.filter((data) => delete data?.avatar);

  const [rowsPerPage, setRowsPerPage] = useState(50);
  let navigate = useNavigate();

  const uuId = sessionStorage.getItem("user_id");
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  let permission = roleId === 4 ? false : true;
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setApiPageCount(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setApiPageCount(0);
  };

  const handleSearchEnter = async (event) => {
    let searchQuery = event.target.value.toLowerCase();
    let searchData = await table_dataUnPaginated?.filter(
      (ele) =>
        ele.name?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        ele.id === parseInt(searchQuery) ||
        ele.email?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        ele.active?.toLowerCase()?.includes(searchQuery?.toLowerCase()) ||
        ele.owner_name?.toLowerCase()?.includes(searchQuery?.toLowerCase())
    );
    if (searchQuery !== "") {
      if (searchData.length !== 0) {
        setTableData(searchData);
        //  setCurrentPaginatedData(data?.data)
        setTotalListCount(0);
      } else setTableData([]);
    } else setTableData(curentPaginatedData);
  };

  const handleSearchFilter = (event) => {
    if (event.target.value === "") setTableData(curentPaginatedData);
    setSearch(event.target.value);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - table_data.length) : 0;

  //   API calls
  // delete company in pt-company list (anil)************************************
  const deleteCompany = (deleteId) => {
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      deleted_company_id: deleteId,
    };
    apiCall(
      (response) => {
        console.log("DELETE_PT_COMPANY", response);
        if (response.data.success) {
          toast.success(response.data.message);
          setDeleteOpen(false);
          GetCompanyLists();
        } else {
          console.log("else", response.data.message);
        }
      },
      formdata,
      "DELETE_PT_COMPANY"
    );
  };
  //store total company lists without pagination for search query
  const handleSearchcompany = (totalCount) => {
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      page_count: totalCount,
    };
    apiCallPaginated(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setTableDataUnPaginatted(data?.data);
          setShowLoader(false);
        } else {
          toast.error(message);
        }
      },
      formdata,
      "GET_PT_COMPANY_LIST",
      apiPageCount + 1
    );
  };

  // Load team members data function and API call
  const GetCompanyLists = () => {
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      page_count: rowsPerPage,
    };
    apiCallPaginated(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setTableData(data?.data);
          setCurrentPaginatedData(data?.data);
          setTotalListCount(data?.total);
          setExcelData(data?.data);
          setShowLoader(false);
          apiPageCount === 0 && handleSearchcompany(data?.total);
        } else {
          toast.error(message);
        }
      },
      formdata,
      "GET_PT_COMPANY_LIST",
      apiPageCount + 1
    );
  };

  useEffect(() => {
    GetCompanyLists();
  }, [rowsPerPage, apiPageCount]);

  return (
    <>
      <Box sx={{ flexGrow: 1 }} className="page-background">
        <Preloader showPreloader={showLoader} />
        {showLoader ? (
          ""
        ) : (
          <Box
            sx={{
              width: "100%",
              px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },
            }}
            className="teams-container"
          >
            <Grid item xs={12} className="teams-title-wrapper">
              <div>
                <h3 className="teams-title">Company</h3>
                <p className="teams-subtitle">Your company list.</p>
              </div>
              <BootstrapTooltip
                title={permission ? "" : <h1>You cannot add company</h1>}
              >
                <span className="disable-btn-wrapper">
                  {permission && (
                    <Button
                      disabled={!permission}
                      className={
                        !permission
                          ? "disabled-permision-btn"
                          : "inspections-new-button"
                      }
                      sx={{ textTransform: "none" }}
                    >
                      Add new &nbsp;&nbsp;+{" "}
                    </Button>
                  )}
                </span>
              </BootstrapTooltip>
            </Grid>
            <Paper
              sx={{ width: "100%", sm: 8, lg: 2, mt: "18px" }}
              className="teams-table-wrapper"
            >
              <EnhancedTableToolbar
                handleOpen={handleOpen}
                displayFilters={displayFilters}
                handleChipReset={handleChipReset}
                handleSearchFilter={handleSearchFilter}
                excelData={result}
                search={search}
                handleSearchEnter={handleSearchEnter}
              />
              {table_data?.length !== 0 ? (
                <>
                  <TableContainer
                    sx={{
                      overflowX: { sm: "auto", lg: "unset", md: "scroll" },
                    }}
                    className={isMobile ? "mobileScroller" : ""}
                  >
                    <Table
                      aria-labelledby="tableTitle"
                      size={"small"}
                      style={{
                        transform: isMobile && "rotate(180deg)",
                        overflowX: "unset",
                        width: "100",
                      }}
                      className="teams-table-main"
                    >
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        //   onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={table_data.length}
                      />
                      <tbody className="teams-body">
                        {/* if you don't need to support IE11, you can replace the `stableSort` call with: 
                    rows.slice().sort(getComparator(order, orderBy)) */}
                        {stableSort(table_data, getComparator(order, orderBy))
                          //   .slice(
                          //     page * rowsPerPage,
                          //     page * rowsPerPage + rowsPerPage
                          //   )
                          .map((row, index) => {
                            const isItemSelected = isSelected(row.name);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            let currentUser =
                              row.id === Number(uuId) ? true : false;
                            let company_image =
                              row.avatar === null ? Img : row.avatar;
                            return (
                              <tr
                                onClick={(event) =>
                                  handleClick(event, row.name)
                                }
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={`${row.client}_${index}`}
                                className="teams-data-table"
                              >
                                <td
                                  component="th"
                                  id={labelId}
                                  style={{ paddingTop: "5px" }}
                                >
                                  <span
                                    onClick={() =>
                                      navigate(`/company/${row?.id}`, {
                                        state: { id: row.id, edit: true },
                                      })
                                    }
                                  >
                                    <img
                                      src={company_image}
                                      className="table-body-row-img"
                                      alt="logo"
                                    />
                                  </span>
                                </td>
                                <td className="teams-id-data" align="center">
                                  <span>{row.id}</span>
                                </td>
                                <td className="insp-status" align="left">
                                  <span
                                    onClick={() =>
                                      navigate(`/company/${row?.id}`, {
                                        state: { id: row.id, edit: true },
                                      })
                                    }
                                  >
                                    {row.name}
                                  </span>
                                </td>
                                <td className="teams-owner-data" align="left">
                                  <span>{row.owner_name}</span>
                                </td>
                                <td align="left" className="teams-email-data">
                                  <span>{row.email}</span>
                                </td>
                                <td align="left">
                                  <span className="phone-data">
                                    {row.phone}
                                  </span>
                                </td>
                                <td align="left">
                                  <span className="phone-data">
                                    {row.active}
                                  </span>
                                </td>
                                <td className="teams-date-data" align="left">
                                  <span>
                                    {new Date(
                                      row.created_date
                                    ).toLocaleDateString()}
                                  </span>
                                </td>
                                <td className="action-column" align="right">
                                  <span className="table-actions">
                                    <span>
                                      {permission ? (
                                        roleId === "6" &&
                                        row.role_name === "Owner" ? (
                                          <>
                                            <BootstrapTooltip
                                              title={
                                                <h1 className="font-13">
                                                  You cannot edit this record
                                                </h1>
                                              }
                                            >
                                              <EditIcon
                                                sx={{ opacity: "0.25" }}
                                              />
                                            </BootstrapTooltip>
                                            <BootstrapTooltip
                                              title={
                                                <h1>
                                                  You cannot delete this record
                                                </h1>
                                              }
                                            >
                                              <DeleteIcon
                                                sx={{
                                                  color: "red",
                                                  fontSize: 18,
                                                  opacity: "0.25",
                                                }}
                                              />
                                            </BootstrapTooltip>
                                          </>
                                        ) : (
                                          <>
                                            <BootstrapTooltip
                                              title={
                                                <h1 className="font-13">
                                                  Edit details
                                                </h1>
                                              }
                                            >
                                              <EditIcon
                                                className="edit-icon"
                                                onClick={() =>
                                                  navigate(
                                                    `/company/${row?.id}`,
                                                    {
                                                      state: {
                                                        id: row.id,
                                                        edit: true,
                                                      },
                                                    }
                                                  )
                                                }
                                              />
                                            </BootstrapTooltip>
                                            <BootstrapTooltip
                                              title={
                                                <h1 className="font-13">
                                                  {!currentUser
                                                    ? "Delete record"
                                                    : "You cannot delete yourself"}
                                                </h1>
                                              }
                                            >
                                              <DeleteIcon
                                                onClick={() =>
                                                  handleClickOpenAlert(row?.id)
                                                }
                                                className={
                                                  !currentUser && "delete-icon"
                                                }
                                                sx={{
                                                  color: "red",
                                                  fontSize: 18,
                                                  opacity: "0.25",
                                                }}
                                              />
                                            </BootstrapTooltip>
                                          </>
                                        )
                                      ) : (
                                        <>
                                          <BootstrapTooltip
                                            title={
                                              <h1 className="font-13">
                                                Edit details
                                              </h1>
                                            }
                                          >
                                            <EditIcon
                                              className="edit-icon"
                                              onClick={() =>
                                                navigate(
                                                  `/company/${row?.id}`,
                                                  {
                                                    state: {
                                                      id: row.id,
                                                      edit: true,
                                                    },
                                                  }
                                                )
                                              }
                                            />
                                          </BootstrapTooltip>
                                          <span></span>
                                        </>
                                      )}
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        {/* {emptyRows > 0 && (
                        <tr
                          style={{
                            height: 33 * emptyRows,
                          }}
                        >
                          <td colSpan={6} />
                        </tr>
                      )} */}
                      </tbody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="company-pagination"
                    rowsPerPageOptions={[50, 100, 150, 200]}
                    component="div"
                    count={totalListCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </>
              ) : (
                <Paper
                  sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 10 }}
                  elevation={0}
                  className={"inspection-table-main"}
                >
                  <Alert severity="error">No datas found</Alert>
                </Paper>
              )}
            </Paper>
          </Box>
        )}
        <Modal
          open={deleteopen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className={"inspection-filter-modal-main"}
        >
          <Box
            className={"inspection-filter-modal"}
            sx={{ maxWidth: "480px !important", height: "231px !important" }}
          >
            <Grid container>
              <Grid item xs={12}>
                <CloseIcon
                  sx={{ float: "right", cursor: "pointer" }}
                  onClick={handleClose}
                />
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    color: "#333333",
                    fontFamily: "open sans",
                    fontSize: "22px",
                    mt: "15px",
                    letterSpacing: 0,
                  }}
                >
                  Delete company?
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ textAlign: "center" }}>
                <Typography
                  sx={{
                    color: "#333333",
                    fontFamily: "open sans",
                    fontSize: "14px",
                    mt: "15px",
                    letterSpacing: 0,
                  }}
                >
                  This action cannot be undone.
                </Typography>
              </Grid>
              <Grid
                container
                sx={{ display: "flex", justifyContent: "center", mt: 5 }}
              >
                <Button
                  variant="outlined"
                  className="inspection-filter-modal-buttons-cancel"
                  sx={{ width: "119px" }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className="inspection-filter-modal-buttons-apply"
                  sx={{ width: "119px", ml: 3 }}
                  onClick={() => {
                    deleteCompany(deleteId);
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default PTCompanyListView;
