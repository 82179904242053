import {  Button, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AlertImage from "../../../../../assets/images/alert.png";
// dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

const PtPublishTemplateModal = ({openPublishTemplateModal,handleClosePublishModal,templateState,setTemplateState,updatePtTempateData}) => {
    const publishPtChanges = () =>{
        //   const isMergeIdNullInDataObj = isMergeIdNull(templateState?.templateData?.data)
        //   if(isMergeIdNullInDataObj) setTemplateState(prev=>({...prev,allMergeIdFilled:false}))
        //   else{
        //   let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
        //   prevData.data.template.is_published = 1;
        //   setTemplateState(prev=>({...prev,templateData:prevData,allMergeIdFilled:true}))
        //   updatePtTempateData(prevData.data.template,"loader","publish")
        // }  
        handleClosePublishModal() 
        let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
        prevData.data.template.is_published = 1;
        setTemplateState(prev=>({...prev,templateData:prevData,allMergeIdFilled:true}))
        updatePtTempateData(prevData.data.template,"loader","publish")
        }
  return (
    <Dialog
      open={openPublishTemplateModal}
      onClose={handleClosePublishModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="pt-delete-template-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <CloseIcon className="close-icon" onClick={handleClosePublishModal} />
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} sx={{ textAlign: "center",mt:'20px' }}>
          <img
            src={AlertImage}
            alt="alert"
            style={{ width: "51px", height: "49px" }}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography className="template_delete_modal_text">
            {`Publish and update template? `}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              color: "#333333",
              fontFamily: "open sans",
              fontSize: "14px",
              mt: "15px",
              letterSpacing: 0,
            }}
          >
           Any changes made will be pushed immediately to customers accounts. Do you want to continue?
          </Typography>
        </Grid>
        <DialogContentText id="alert-dialog-description">
          {/* This action cannot be undone. */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ ":hover": { backgroundColor: "transparent !important" } }}
          className="template-delete-modal-cancel-btn"
          onClick={handleClosePublishModal}
        >
          No
        </Button>
        <Button
          sx={{ ":hover": { backgroundColor: "#1E8970 !important" } }}
          className="default-btn-template-delete-modal"
          variant="contained"
          color="error"
            onClick={() => {publishPtChanges()}}
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PtPublishTemplateModal;
