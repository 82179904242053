import * as React from 'react';
import { styled } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

const StepperConnect = (value) => {
    const { status } = value;
    const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));
    return (
        <>
            <div className="stepper">
                <BootstrapTooltip title={<h1 className="font-13">{status === 'Draft' ? 'Draft' : 'Scheduled'}</h1>}>{status === 'Scheduled' || status === 'Draft' ? <CheckCircleIcon className="stepper__status"></CheckCircleIcon> : <CircleOutlinedIcon sx={{ color: '#EAE7E3' }} className="stepper__status"></CircleOutlinedIcon>}</BootstrapTooltip>
                <hr className="stepper__progress"></hr>
                <BootstrapTooltip title={<h1 className="font-13">In-progress</h1>}>{status === 'In-Progress' ? <CheckCircleIcon className="stepper__status"></CheckCircleIcon> : <CircleOutlinedIcon sx={{ color: '#EAE7E3' }} className="stepper__status"></CircleOutlinedIcon>}</BootstrapTooltip>
                <hr className="stepper__progress"></hr>
                <BootstrapTooltip title={<h1 className="font-13">{status === 'Cancelled' ? 'Cancelled' : 'Complete'}</h1>}>{status === 'Complete' || status === 'Cancelled' ? <CheckCircleIcon className="stepper__status"></CheckCircleIcon> : <CircleOutlinedIcon sx={{ color: '#EAE7E3' }} className="stepper__status"></CircleOutlinedIcon>}</BootstrapTooltip>
                <hr className="stepper__progress"></hr>
                <BootstrapTooltip title={<h1 className="font-13">Published</h1>}>{status === 'Published' ? <CheckCircleIcon className="stepper__status"></CheckCircleIcon> : <CircleOutlinedIcon sx={{ color: '#EAE7E3' }} className="stepper__status"></CircleOutlinedIcon>}</BootstrapTooltip>
            </div>
        </>
    );
};

export default StepperConnect;
