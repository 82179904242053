import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import DragHandleIcon from "@mui/icons-material/DragHandle";
import DeleteIcon from "@mui/icons-material/Delete";

const ItemType = "CHOICE";

const DraggableChoice = ({ ptRadioEdithandle, ptDeleteRadio, option, index, moveChoice }) => {
  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveChoice(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div ref={(node) => preview(drop(node))} className="pt_radio_label_input">
      <div className="drag-handle" style={{ cursor: isDragging ? 'grabbing' : 'grab' }} ref={drag}>
        <DragHandleIcon className="drag-handle-icon" />
      </div>
      <div style={{display:'flex',alignItems:'center'}}>
        <input
          type="text"
          value={option}
          onChange={(e) => ptRadioEdithandle(e.target.value, index)}
        />
        <DeleteIcon className="radio_del_icon" onClick={() => ptDeleteRadio(index)} />
      </div>
    </div>
  );
};

export default DraggableChoice;
