import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import { Grid } from "@mui/material";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { AppContext } from "../../App";
import NoInspection from "../../pages/dashboard/NoInspection";
import UseInspectionAgreementModal from "../../common/modal/UseInspectionAgreementModal";
import IncludedAgreementModal from "../../common/modal/IncludedAgreementModal";
import CreateOwnAgreementModal from "../../common/modal/CreateOwnAgreementModal";

const SettingsCard = ({ navigatePath, title, description, icon, permission, id }) => {
  let navigate = useNavigate();
  const { companyInfo } = useContext(AppContext);
  // agreement terms modal states
  const [showInspAgrmntModal, setShowInspAgrmntModal] = useState(false);
  const [showIncludedAgrmntModal, setShowIncludedAgrmntModal] = useState(false);
  const [cantCreateInsp, setCantCreateInsp] = useState(false);
  const [showCreateOwnAgrmntModal, setShowCreateOwnAgrmntModal] = useState(false);
  // const [isAgreementPageModal,setIsAgreementPageModal] = useState(false);
  const [isSettingPageModal, setIsSettingPageModal] = useState(false);
  const [stateChanged, setStateChanged] = useState(false);

  const checkAgreementAcceptStatus = () => {
    // if (sessionStorage.getItem("is_trial") === "1" && sessionStorage.getItem("can_create_inspection") !== "true" && sessionStorage.getItem("can_create_inspection") !== "undefined") {
    //   setCantCreateInsp(true);
    // } else
    if (companyInfo.agreement?.agreement_status === 0 || companyInfo.agreement?.agreed_to_terms_for_agreement === 1 || companyInfo?.agreement?.is_default_agreement_deleted === 1) permission && navigate(navigatePath);
    else if (companyInfo.agreement?.agreed_to_terms_for_agreement === 0) {
      setIsSettingPageModal(true);
      setShowInspAgrmntModal(true);
    }
  };

  const checkBillingStatus = () => {
    // if (sessionStorage.getItem("is_trial") === "1" && sessionStorage.getItem("can_create_inspection") !== "true" && sessionStorage.getItem("can_create_inspection") !== "undefined") {
    //   setCantCreateInsp(true);
    // } else navigate(`${navigatePath}`);
    navigate(`${navigatePath}`);
  };

  const settingsClick = () => {
    if (id === "agreement") {
      checkAgreementAcceptStatus();
    } else if (id === "billing") {
      checkBillingStatus();
    } else permission && navigate(`${navigatePath}`);
  };
  return (
    <>
      <UseInspectionAgreementModal showInspAgrmntModal={showInspAgrmntModal} setShowInspAgrmntModal={setShowInspAgrmntModal} setShowIncludedAgrmntModal={setShowIncludedAgrmntModal} isSettingPageModal={isSettingPageModal} />
      <IncludedAgreementModal showIncludedAgrmntModal={showIncludedAgrmntModal} setShowIncludedAgrmntModal={setShowIncludedAgrmntModal} setShowInspAgrmntModal={setShowInspAgrmntModal} setShowCreateOwnAgrmntModal={setShowCreateOwnAgrmntModal} isSettingPageModal={isSettingPageModal} setStateChanged={setStateChanged} />
      <CreateOwnAgreementModal showCreateOwnAgrmntModal={showCreateOwnAgrmntModal} setShowCreateOwnAgrmntModal={setShowCreateOwnAgrmntModal} isSettingPageModal={isSettingPageModal} setStateChanged={setStateChanged} />
      <NoInspection showNoInspModal={cantCreateInsp} setShowOnInspModal={setCantCreateInsp} />
      <Grid item xs={12} sm={4}>
        <Card className="settings-card" id={`${id}_settings`} name={`${id}_settings`} variant="outlined" onClick={settingsClick} style={{ cursor: `${!permission && "not-allowed"}` }}>
          <Grid container className="details-cards">
            <Grid>
              <div className="icon-container">{icon}</div>
            </Grid>
            <Grid className="name-wrapper">
              <span className="setting-name">{title}</span>
              <span>
                {" "}
                <KeyboardArrowRightIcon />
              </span>
            </Grid>
            <Grid className="setting-name-desc">{description}</Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default SettingsCard;
