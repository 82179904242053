import React, { useState } from "react";
import { Grid, Button } from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
// dialog imports
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AntSwitch from "../../../common/antSwitch/AntSwitch";

const AddRating = (props) => {
  const {
    ratingName,
    definition,
    handleCloseRating,
    openRating,
    rating_colors,
    ratingColor,
    setRatingColor,
    newRatingSummary,
    setNewRatingSummary,
    handleRatingUpdate,
    isDefaultTemplate,
    setIsDefaultTemplate,
    ratingNameError,
  } = props;

  const [tempRatingName, setTempRatingname] = useState(ratingName);
  const [editingName, setEditingName] = useState(false);
  const [tempDefinition, setTempDefinition] = useState(definition);
  const [editingDefinition, setEditingDefinition] = useState(false);
  const spacingRemove = (e) => {
    if (e.which === 32 && !e.target.value.length) {
      e.preventDefault();
    }
  };
  const ratingNameChange = (e) => {
    setEditingName(true);
    setTempRatingname(e.target.value);
  };
  const setDefinitionChange = (e) => {
    setEditingDefinition(true);
    setTempDefinition(e.target.value);
  };
  return (
    <Dialog
      fullWidth={true}
      className="dialog-container"
      open={openRating}
      onClose={handleCloseRating}
    >
      <div className="close-wrapper">
        <CloseIcon onClick={handleCloseRating} id="rating_close_btn" />
      </div>
      <DialogTitle>Ratings</DialogTitle>
      <DialogContent className="dialog-content">
        <Grid container className="input-controll">
          <Grid item xs={12} sm={12} md={7}>
            <Grid container>
              <Grid item xs={12}>
                <FormControl variant="standard" className="forms-control">
                  <label className="input-form-label" htmlFor="RatingName">
                    Name
                  </label>
                  <TextField
                    id="RatingName"
                    className="input-textfield"
                    size="small"
                    variant="outlined"
                    placeholder="Rating Name"
                    onKeyDown={(e) => spacingRemove(e)}
                    // onChange={(e) => setTempRatingname(e.target.value)}
                    onChange={(e) => ratingNameChange(e)}
                    error={ratingNameError}
                    helperText={ratingNameError && "Rating name is required"}
                    value={
                      !editingName
                        ? ratingNameError
                          ? tempRatingName
                          : ratingName
                        : tempRatingName
                    }
                    type="text"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" className="forms-control">
                  <label className="input-form-label" htmlFor="Definition">
                    Definition
                  </label>

                  <TextField
                    id="rating_definition"
                    className="input-textfield textarea"
                    aria-label="minimum height"
                    variant="outlined"
                    placeholder="Rating Definition"
                    // onChange={(e) => setDefinition(e.target.value)}
                    onChange={(e) => setDefinitionChange(e)}
                    minRows={3}
                    //   error={serviceNameError}
                    //   helperText={serviceNameError ? serviceNameErrorText : ""}
                    value={!editingDefinition ? definition : tempDefinition}
                    InputProps={{
                      inputComponent: TextareaAutosize,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {/* <FormControl variant="standard" className="forms-control">
                   <div className='default_rating_switch'>
                        <span className="rating_switch_svg">
                            <AntSwitch colorOne="#177ddc" colorTwo="#4282E2" defaultValue={isDefaultTemplate ==="1"?true:false} defaultSwitchValue={setIsDefaultTemplate} />
                        </span>                        
                        <div className='rating_switch_label'>
                            <span>Default rating</span>
                            <p>This rating will be automatically assigned when you enter a description. Note: Only one rating can be the default.</p>
                        </div>
                   </div> 
                </FormControl> */}
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" className="forms-control">
                  <div className="summary_checkbox">
                    <span className="checkbox_input">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={newRatingSummary === "1" ? true : false}
                              onClick={(e) =>
                                setNewRatingSummary(
                                  e.target.checked === true ? "1" : "0"
                                )
                              }
                              id="rating_checkbox"
                              sx={{
                                color: "#000",
                                "&.Mui-checked": {
                                  color: "#4282E2",
                                },
                              }}
                            />
                          }
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 28,
                              marginLeft: "5px",
                            },
                          }}
                          label="Include in summary"
                        />
                      </FormGroup>
                    </span>
                    <div className="summary_label">
                      <p>
                        Any comment or line with this rating assigned will be
                        included in the report summary.{" "}
                      </p>
                    </div>
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <Grid container>
              <Grid item xs={12}>
                <FormControl
                  variant="standard"
                  className="forms-control"
                  style={{ marginLeft: "10px" }}
                >
                  <label className="input-form-label" htmlFor="ratring_preview">
                    PREVIEW
                  </label>
                  <div className="rating_preview">
                    <div
                      className="preview_color"
                      style={{ background: `${ratingColor}` }}
                    >
                      {newRatingSummary === "1" && <FlagIcon />}
                    </div>
                    <span className="preview_name">
                      {!editingName
                        ? ratingNameError
                          ? tempRatingName
                          : ratingName === ""
                          ? "Rating Name"
                          : ratingName
                        : tempRatingName}
                    </span>
                  </div>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" className="forms-control">
                  <label
                    className="input-form-label"
                    htmlFor="ratring_preview"
                    style={{ marginLeft: "10px" }}
                  >
                    SELECT COLOR
                  </label>

                  <div className="color-pallets">
                    {rating_colors?.map((rating, index) => {
                      return (
                        <div
                          className="pallet-wrapper"
                          style={{
                            border: `${
                              rating.rating_color_code === ratingColor
                                ? "1px solid #979797"
                                : ""
                            }`,
                          }}
                          onClick={() =>
                            setRatingColor(rating.rating_color_code)
                          }
                          id={`rating_color_palette_${index}`}
                          key={index}
                        >
                          <span
                            className="pallet"
                            style={{
                              background: `${rating.rating_color_code}`,
                            }}
                          ></span>
                        </div>
                      );
                    })}
                  </div>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="dialog-action">
        <Button
          className="back-btn"
          onClick={() => {
            handleCloseRating();
            setTempRatingname("");
            setEditingName(false);
            setTempDefinition("");
            setEditingDefinition(false);
          }}
          id="rating_cancel_btn"
        >
          Cancel
        </Button>
        <Button
          className="default-btn"
          onClick={() => {
            handleRatingUpdate(
              editingName ? tempRatingName : ratingName,
              editingDefinition ? tempDefinition : definition
            );
            setTempRatingname("");
            setEditingName(false);
            setTempDefinition("");
            setEditingDefinition(false);
          }}
          id="rating_apply_btn"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddRating;
