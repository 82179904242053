//file imports
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { apiCall } from "../Services/action";
import CloseIcon from "@mui/icons-material/Close";
import { Scrollbars } from "react-custom-scrollbars-2";
import Heading from "../../../common/cardHeading/Heading";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { CancelSubsSvg } from "../../../../assets/svgIcons/Svg";
import { Box, Button, Grid, Paper, Modal, FormControlLabel, TextField, Typography, Checkbox, FormGroup } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const PlanDetails = (props) => {
  const [open, setOpen] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [reactivateModal, setReactivateModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subscriptionReason, setSubscriptionReason] = useState({ value: "", text: "" });
  const { data, planState, setPlanModal, trailDetails, setShowLoader, setRefresh, refresh } = props;
  const cancelSub = () => {
    setOpen(false);
    // setCancelModal(true);
    CancelSubscription();
  };
  // console.log(data, "data");
  //array
  const CancelSubscriptionReason = ["Palmtech is missing features that I need", "I'm closing my business", "I'm placing my business on hold and won't be inspecting for a while", "I decided to use another software program", "I'm using another program now and am too busy to learn the program", "I'm working for another company that uses another software", "Palmtech is too expensive", "Other"];

  // cancel sub. API call**************
  const [selReas, setSelReas] = useState([]);
  const [textRes, setTextRes] = useState("");
  const [isOthers, setIsOthers] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [error, setError] = useState(false);
  const [helperText, setHelperText] = useState("");
  const scrollbarsRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom when the condition is met
    if (error) {
      scrollbarsRef.current.scrollToBottom();
    }
  }, [error]);

  const getCancelData = (ids, checked) => {
    if (ids === 8 && checked) {
      setIsOthers(true);
      setIsOther(true);
    } else {
      setIsOthers(false);
    }
    if (checked && isOthers) {
      const updatedSelReas = [...selReas, ids];
      setSelReas(updatedSelReas);
      setTextRes("");
    } else if (checked && ids !== 8) {
      const updatedSelReas = [...selReas, ids];
      setSelReas(updatedSelReas);
    } else {
      const updatedSelReas = selReas.filter((item) => item !== ids);
      setSelReas(updatedSelReas);
      setTextRes("");
      setIsOther(false);
    }
  };
  const Reasons = textRes !== "" ? [...selReas, textRes].toString() : [...selReas].toString();
  // console.log("Reasons", Reasons);

  const CloseModel = () => {
    setSelReas([]);
    setTextRes("");
    setHelperText("");
    setError(false);
    setIsOthers(false);
    setCancelModal(false);
  };

  const CancelSubscription = () => {
    setShowLoader(true);
    setCancelModal(false);
    const formdata = {
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      company_id: sessionStorage.getItem("company_id"),
      subscription_cancle_reason: Reasons,
    };
    apiCall(
      (response) => {
        const { success, message } = response.data;
        if (success) {
          setRefresh(!refresh);
          setShowLoader(false);
          setCancelModal(false);
          setConfirmCancel(true);
          toast.success(message);
        } else {
          toast.error(message);
          setShowLoader(false);
          setCancelModal(false);
        }
      },
      formdata,
      "SEND_CANCEL_SUBSCRIPTION_EMAIL"
    );
  };

  const ReactivateSubscription = () => {
    setLoading(true);
    const formdata = {
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      company_id: sessionStorage.getItem("company_id"),
    };
    apiCall(
      (response) => {
        const { success, message } = response.data;
        if (success) {
          setReactivateModal(false);
          setRefresh(!refresh);
          setLoading(false);
          toast.success(message);
        } else {
          toast.error(message);
          setLoading(false);
        }
      },
      formdata,
      "REACTIVATE_SUBSCRIPTION"
    );
  };

  const CancelSubscriptionCheck = () => {
    if (isOthers && textRes.length === 0) {
      setError(true);
      setHelperText("Please enter the reason for cancellation");
    } else {
      setError(false);
      setHelperText("");
      CancelSubscription();
    }
  };
  //render
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Paper elevation={0} className="plan-details-card">
            <Heading
              title="Plan Details"
              CancelledStatus={false}
              iconDesc={
                trailDetails.isTrail === 1 || planState === "Canceled" || data?.pending_cancelation ? (
                  ""
                ) : (
                  <p className="cancel-plan-button" onClick={CancelSubscription}>
                    Cancel Plan
                  </p>
                )
              }
            />
            <div className="plan-details-card-container">
              <Grid container>
                <Grid item xs={5}>
                  {trailDetails.isTrail === 1 ? (
                    <>
                      <p className="plan-details-card-monthly">Trial plan</p>
                      <p className="plan-details-card-renews">
                        Questions? Call 888-736-2462 <br />
                        Email: info@palmtech.com
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="plan-details-card-monthly">
                        {data?.title}&nbsp;
                        {data?.pending_cancelation ? <p className="cancelled-title">(pending cancelation)</p> : ""}
                        {planState === "Canceled" ? <p className="cancelled-title">(cancelled)</p> : ""}
                      </p>
                      <p className="plan-details-card-renews">
                        {planState === "Canceled" ? "Expires" : "Renews"} on {moment(data?.next_renewal_date).format("LL")}
                      </p>
                      {planState === "Canceled" ? (
                        <>
                          <p className="plan-details-card-addtional">
                            You can renew your subscription any time <br />
                            before this date.
                          </p>
                          {/* <p className="plan-details-card-out">
                            {data?.licenses_used_count}/{data?.additional_license_count} in use
                          </p> */}
                        </>
                      ) : (
                        ""
                      )}
                      {/* <p className="plan-details-card-manage-additional-container">
                        <OpenInNewIcon className="plan-details-card-manage-additional-icon" />
                        Manage additional licenses
                      </p> */}
                    </>
                  )}
                  {planState === "Canceled" || data?.pending_cancelation ? (
                    <Button className="plan-details-card-see-all-button" sx={{ mt: 3 }} onClick={() => setReactivateModal(true)}>
                      Reactivate plan
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={7}>
                  <div className="plan-details-card-container2">
                    {trailDetails.isTrail === 1 ? (
                      <>
                        <p className="plan-details-card-container2-plansummary">Free Inspections</p>
                        <Grid item xs={12}>
                          <p className="plan-details-card-container2-datas">No matter which plan you choose, get five free inspections to start. You can change your plan at any time.</p>
                          <br />
                          <Button className="inspection-remaining-button">{trailDetails.remainingInspectionCount} remaining inspections</Button>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid container sx={{ opacity: planState === "Canceled" ? "100%" : "100%" }}>
                          <Grid item xs={12}>
                            <p className="plan-details-card-container2-plansummary">Plan Summary</p>
                          </Grid>
                          <Grid item xs={6}>
                            <p className="plan-details-card-container2-datas">Subscription</p>
                          </Grid>
                          <Grid item xs={6}>
                            <p className="plan-details-card-container2-datas-amount">${data?.plan_summary?.subscription_price}</p>
                          </Grid>
                          <Grid item xs={6}>
                            <p className="plan-details-card-container2-datas">
                              {data?.additional_license_count} additional licenses (${data?.plan_summary?.price_per_license}/user)
                            </p>
                          </Grid>
                          <Grid item xs={6}>
                            <p className="plan-details-card-container2-datas-amount">${data?.plan_summary?.addition_license_price}</p>
                          </Grid>
                          <Grid item xs={6}>
                            <p className="plan-details-card-container2-datas-bold">Next payment due:</p>
                          </Grid>
                          <Grid item xs={6}>
                            <p className="plan-details-card-container2-datas-amount-bold">${data?.plan_summary?.next_payment_due}</p>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </div>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
      </Box>
      {/* cancel subscription modal */}
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "655px !important", height: "402px !important", top: "35% !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => setOpen(false)} />
            </Grid>
            <Grid item xs={12}>
              <div className="plan-details-card-icon-container">
                <HelpOutlineIcon className="plan-details-card-icon" />
              </div>
              <div className="plan-details-card-icon-container">
                <p className="plan-details-cancel-title">Want help first?</p>
              </div>
              <div className="plan-details-card-icon-container">
                <p className="plan-details-card-icon-container-desc">Before you cancel, maybe we can help. Schedule a call with a customer service manager to get personal account assistance.</p>
              </div>
              <div className="plan-details-card-icon-container">
                <Button className="plan-details-cancel-callbutton" startIcon={<QueryBuilderIcon fontSize="small" />}>
                  Schedule a call
                </Button>
              </div>
              <div className="plan-details-card-icon-container">
                <p className="plan-details-cancel-button" onClick={cancelSub}>
                  No, cancel subscription
                </p>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* cancel confirm modal */}
      <Modal open={cancelModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "1165px !important", minHeight: "681px !important", top: "30% !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => CloseModel()} />
            </Grid>
            <Grid item xs={12}>
              <Grid container className="cancel-subs-container">
                <Grid item xs={12} md={7}>
                  <p className="were-sorry-to-see-y-copy">We're sorry to see you go.</p>
                  <p className="here-at-palmtech-we">Here at Palmtech we strive to provide the best experience. Please select the reason for your cancellation from the list below. Select all that apply.</p>
                  <div>
                    <Scrollbars style={{ width: "100%", height: 290 }} ref={scrollbarsRef}>
                      <FormGroup>
                        {CancelSubscriptionReason.map((value, index) => {
                          return <FormControlLabel control={<Checkbox value={index} onClick={(event) => getCancelData(index + 1, event.target.checked)} />} label={value} />;
                        })}
                      </FormGroup>
                      <div style={{ padding: "14px", marginRight: "20px", marginBottom: "20px" }}>
                        <TextField fullWidth rows={5} multiline id="outlined-multiline-static" placeholder="Start typing" error={error} value={textRes} helperText={helperText} onChange={(event) => setTextRes(event.target.value)} />
                      </div>
                    </Scrollbars>
                  </div>
                  <div className="rectangle">
                    <p className="cancelling-your-subs">Cancelling your subscription means you will lose the ability to:</p>
                    <Grid container sx={{ p: "15px 30px" }}>
                      <Grid item xs={6}>
                        <p className="schedule-appointment">Schedule appointments or events</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p className="schedule-appointment">Edit templates</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p className="schedule-appointment">Make new inspections</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p className="schedule-appointment">Send emails</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p className="schedule-appointment">Add or remove agents</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p className="schedule-appointment">Other features</p>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Grid item xs={12} md={5} sx={{ pl: 5, display: "flex", justifyItems: "center", alignItems: "center" }}>
                  <CancelSubsSvg />
                </Grid>
                <Grid item xs={12}>
                  <Button className="keep-plan-button" onClick={() => CloseModel()}>
                    Keep plan
                  </Button>
                  <Button className="cancel-subscription-button" onClick={CancelSubscriptionCheck}>
                    Confirm cancellation
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* confirm cancel screen */}
      <Modal open={confirmCancel} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className="inspection-filter-modal" sx={{ maxWidth: "655px !important", minHeight: "435px !important", top: "30% !important" }}>
          <Grid container sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
            <CheckCircleIcon className="ic-check-circle" />
            <Grid item xs={12}>
              <p className="purchase-complete">Thanks for using Palmtech!</p>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
              <p className="your-purchase-of-2-a">Your satisfaction is our top priority, and we're here to help in any way we can. Someone will contact you to help you with your request!</p>{" "}
              {/* <p className="your-purchase-of-2-a">
                Your {data?.title?.replace(/plan/g, "")} subscription is paid until <h1 style={{ display: "inline" }}>{moment(data?.next_renewal_date).format("LL")}</h1>. You can renew your subscription at any time.
              </p>{" "} */}
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
              <Button sx={{ mt: 5 }} className="upgrade-confirm-checkout" onClick={() => setConfirmCancel(false)}>
                Done
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* Reactivate Plan */}
      <Modal open={reactivateModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "480px !important", height: "231px !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => setReactivateModal(false)} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography sx={{ color: "#333333", fontFamily: "open sans", fontSize: "22px", mt: "15px", letterSpacing: 0 }}>Reactivate Plan?</Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography sx={{ color: "#333333", fontFamily: "open sans", fontSize: "14px", mt: "15px", letterSpacing: 0 }}>This action cannot be undone.</Typography>
            </Grid>
            <Grid container sx={{ display: "flex", justifyContent: "center", mt: 5, gap: "25px" }}>
              <LoadingButton loading={loading} className="upgrade-confirm-checkout" onClick={ReactivateSubscription}>
                Confirm
              </LoadingButton>
              <Button className="upgrade-plans-button-cancel" onClick={() => setReactivateModal(false)}>
                Cancel
              </Button>
              {/* <Button
                variant="outlined"
                className="inspection-filter-modal-buttons-cancel"
                sx={{
                  width: "119px",
                  ":hover": {
                    backgroundColor: "transparent !important",
                  },
                }}
                onClick={() => setReactivateModal(false)}
              >
                Cancel
              </Button>
              <LoadingButton
                variant="contained"
                className="inspection-filter-modal-buttons-apply"
                sx={{
                  width: "119px",
                  ml: 3,
                  ":hover": {
                    backgroundColor: "#1E8970 !important",
                  },
                }}
                onClick={() => { 
                  ReactivateSubscription();
                }}
              >
                Reactivate plan
              </LoadingButton> */}
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default PlanDetails;