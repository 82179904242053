import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment-timezone";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableSortLabel from "@mui/material/TableSortLabel";
import Preloader from "../../../helpers/Preloader";
import Header from "../../../common/header/Header";
import Typography from "@mui/material/Typography";
import "../../../../assets/styles/containers/_ptImport.scss";
import AddImportDialog from "./AddImportDialog";
import Sortswitch from "../../../../assets/images/Sortswitch.png";
import EnhancedTableToolbar from "../../../common/table/EnhancedTableToolbar";
import { ptCompanyList } from "../../../config/app-constants";
import { apiCallPaginated } from "../../../utils/action";
import { customSort } from "../../../utils/services";
import { apiCallGet } from "../../../utils/action";
import { toast } from "react-hot-toast";
import Breadcrumb from "../../../common/header/Breadcrumb";
import ExportTemplateList from "./ExportTemplateList";

function PtImport() {
  const [state, setState] = useState({
    showLoader: false,
    importCompanyList: [],
    addDialog: false,
    file: null,
    progressPercent: 0,
    moveToCustomer: false,
    searchText: "",
    rowsPerPage: 10,
    page: 0,
    totalListCount: 0,
    fileteredList: [],
    selectedCompany: null,
    excelData: null,
    apiPageCount: 0,
    order: "asc",
    orderBy: "company_key",
    fileImportError: false,
    apiCallInProgress: false,
    showTemplateList: false,
  });

  const {
    showLoader,
    searchText,
    rowsPerPage,
    page,
    totalListCount,
    fileteredList,
    excelData,
    apiPageCount,
    order,
    orderBy,
    showTemplateList,
  } = state;

  let result = JSON.parse(JSON.stringify(excelData));
  const [inspTypes, setInspTypes] = useState([]);
  const getNewTemplateDetails = () => {
    toast.dismiss();
    apiCallGet(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setInspTypes(data.inspection_types);
        } else {
          toast.error(message);
        }
      },
      "",
      "PT_GET_NEW_TEMPLATE_DETAILS"
    );
  };

  useEffect(() => {
    getNewTemplateDetails();
  }, []);

  useEffect(() => {
    getImportCompanyList();
  }, [apiPageCount, rowsPerPage]);

  const handleOpen = (rowData) => {
    setState((prevState) => ({
      ...prevState,
      addDialog: true,
      selectedCompany: rowData,
      fileImportError: false,
      moveToCustomer: false,
    }));
  };

  const getImportCompanyList = (text = null) => {
    setState((prevState) => ({ ...prevState, showLoader: true }));
    const formdata = {
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      per_page: rowsPerPage,
      search: text !== null ? text : searchText,
    };
    apiCallPaginated(
      (response) => {
        if (response.data.success) {
          setState((prevState) => ({
            ...prevState,
            showLoader: false,
            page: response?.data?.data?.current_page - 1,
            totalListCount: response?.data?.data?.total,
            rowsPerPage: response?.data?.data?.per_page,
            fileteredList: response?.data?.data?.data,
            excelData: response?.data?.data?.data,
          }));
        } else {
          if (response.data.status_code === 404)
            setState((prevState) => ({
              ...prevState,
              importCompanyList: [],
              fileteredList: [],
              showLoader: false,
            }));
        }
      },
      formdata,
      "GET_IMPORT_COMPANY_LIST",
      text !== "" || searchText !== "" ? apiPageCount + 1 : 1
    );
  };

  const handleChangePage = (event, newPage) => {
    setState((prevState) => ({
      ...prevState,
      page: newPage,
      apiPageCount: newPage,
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setState((prevState) => ({
      ...prevState,
      page: 0,
      apiPageCount: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    }));
  };

  const handleSearchEnter = (event) => {
    let searchQuery = event.target.value.toLowerCase();
    getImportCompanyList(searchQuery);
  };

  const handleSearchFilter = (event) => {
    if (event.target.value === "") getImportCompanyList("");
    setState((prevState) => ({
      ...prevState,
      searchText: event.target.value,
    }));
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setState((prevState) => ({
      ...prevState,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    }));
  };

  const handleCompanyClick = (key) => {
    setState((prevState) => ({
      ...prevState,
      showTemplateList: key,
    }));
  };

  return (
    <Box className="page-background">
      <Header />
      {/* <div style={{ height: "140px" }}></div> */}
      <Preloader showPreloader={showLoader} />
      <Breadcrumb />
      <AddImportDialog
        setState={setState}
        state={state}
        inspTypes={inspTypes}
      />
      {showLoader ? null : showTemplateList ? (
        <ExportTemplateList showTemplateList={showTemplateList} />
      ) : (
        <Box px={10} pt={3} pb={10}>
          <Grid container className="headingGrid">
            <Typography className="importHeading">Import / Export</Typography>
          </Grid>
          <Paper
            sx={{ width: "100%", sm: 8, lg: 2, mt: "18px" }}
            className="teams-table-wrapper"
          >
            <EnhancedTableToolbar
              displayFilters={false}
              searchIcon={true}
              search={searchText}
              isMobile={false}
              handleSearchFilter={handleSearchFilter}
              handleSearchEnter={handleSearchEnter}
              excelData={result}
              excelName={"Import Company List"}
            />
            {fileteredList?.length ? (
              <>
                <TableContainer className="ptCompanyListTable">
                  <Table>
                    <TableHead>
                      <TableRow>
                        {ptCompanyList.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            className="ptHeadingTitle"
                            align={headCell.id === "import" ? "center" : ""}
                          >
                            <TableSortLabel
                              IconComponent={() =>
                                headCell.name !== "Import" && (
                                  <img
                                    src={Sortswitch}
                                    alt="sort-icon"
                                    className="sort-switch"
                                  />
                                )
                              }
                              active={orderBy === headCell.id}
                              direction={
                                orderBy === headCell.id ? order : "asc"
                              }
                              onClick={() => handleRequestSort(headCell.id)}
                              sx={{
                                marginBottom: "10.5px",
                                fontSize: "12px",
                                flexDirection: "row-reverse",
                              }}
                            >
                              {headCell.name}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {customSort(fileteredList, order, orderBy).map(
                        (obj, index) => (
                          <TableRow key={index} className="ptRowColor">
                            <TableCell
                              className="ptCell"
                              onClick={() => handleCompanyClick(obj?.id)}
                            >
                              <Typography className="ptCompanyDetails">
                                {obj?.company_name}
                              </Typography>
                            </TableCell>
                            <TableCell className="ptCell">
                              <Typography className="ptCompanyDetails">
                                {obj?.company_key}
                              </Typography>
                            </TableCell>
                            <TableCell className="ptCell">
                              {obj?.last_inspection ? (
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography className="ptCompanyDetails">
                                      {moment(
                                        new Date(obj?.last_inspection)
                                      ).format("MM/DD/YY")}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      fontSize={10}
                                      className="dateTimeColor"
                                    >
                                      {moment(
                                        new Date(obj?.last_inspection)
                                      ).fromNow()}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ) : null}
                            </TableCell>
                            <TableCell className="ptCell">
                              <Typography className="ptCompanyDetails">
                                {obj?.inspection_count || null}
                              </Typography>
                            </TableCell>
                            <TableCell className="ptCell">
                              {obj?.latest_import ? (
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography
                                      fontSize={12}
                                      className="ptCompanyDetails"
                                    >
                                      {moment(
                                        new Date(obj?.latest_import)
                                      ).format("MM/DD/YY")}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      fontSize={10}
                                      className="dateTimeColor"
                                    >
                                      {`${moment(new Date(obj?.latest_import))
                                        .tz("America/New_York")
                                        .format("h:mm A")} EST`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ) : null}
                            </TableCell>
                            <TableCell className="ptCell">
                              <Button
                                id={`beginImportBtn${index}`}
                                onClick={() => handleOpen(obj)}
                                className="ptBeginImportButton"
                              >
                                Begin Import
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  className="company-pagination"
                  // rowsPerPageOptions={[50, 100, 150, 200]}
                  component="div"
                  count={totalListCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <Paper
                sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 10 }}
                elevation={0}
                className={"inspection-table-main"}
              >
                <Alert severity="error">No datas found</Alert>
              </Paper>
            )}
          </Paper>
        </Box>
      )}
    </Box>
  );
}

export default PtImport;
