import moment from "moment";
import { Box } from "@mui/system";
import { Paper, Typography } from "@mui/material";
const ErrorDetailedLayout = ({ data }) => {
  return (
    <div className="inspection-details">
      <Typography className="layout__header" component="div">
        <Box className="layout__title">{data?.module_name}</Box>
        <Box className="layout__description">
          <h1 className="inline">Created at:</h1> {moment(moment.utc(data?.created_at).local()).format("LLLL")}
        </Box>
        <Box className="layout__description">
          <h1 className="inline">Platform:</h1> {data?.error_platform}
        </Box>
        <Box className="layout__description">
          <h1 className="inline">Company:</h1> {data?.company}
        </Box>
        <Box className="layout__description">
          <h1 className="inline">Username:</h1> {data?.username}
        </Box>
      </Typography>
      <br />
      <Paper elevation={0} sx={{ p: 2 }}>
        <div style={{ height: "50vh", overflow: "auto" }}>
          <p>{data?.message}</p>
        </div>
      </Paper>
    </div>
  );
};
export default ErrorDetailedLayout;
