import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Grid, Button } from "@mui/material";
import Box from "@mui/material/Box";
import { apiCall } from "../../utils/action";
import Placeholder from "../placeholder/Placeholder";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Preloader from "../../helpers/Preloader";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";

const EmailTemplate = ({ title, subTitle, templateFor }) => {
  // const [agreementBody, setAgreementBody] = useState('');
  const editor = useRef(); // editor ref
  // const initialState = () =>
  //   EditorState.createWithContent(
  //     ContentState.createFromBlockArray(convertFromHTML(agreementBody))
  //   );
  // const [editorState, setEditorState] = useState(initialState);
  // const [convertedContent, setConvertedContent] = useState(null);
  const [showLoader, setShowLoader] = useState();
  // suneditor===============================================================================
  const [editorContent, setEditorContent] = useState(null);
  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };
  const handleChange = (content) => {
    setEditorContent(content);
  };
  const handleClickEditor = (e) => {};
  const onDropHandle = (e) => {};
  // suneditor===============================================================================
  // session datas
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  const uuId = sessionStorage.getItem("user_id");

  const navigate = useNavigate();

  // merge contents functions
  //  const sendTextToEditor = (text) => {
  //   // setEditorState(insertText(text, editorState));
  // };
  // const insertText = (text, editorValue) => {
  //   const currentContent = editorValue.getCurrentContent();
  //   const currentSelection = editorValue.getSelection();
  //   const newContent = Modifier.replaceText(currentContent,currentSelection,text);
  //   const newEditorState = EditorState.push(editorValue,newContent,"insert-characters");
  //   return EditorState.forceSelection(newEditorState,newContent.getSelectionAfter());
  // };
  // merge content end
  //  const convertContentToHTML = () => {
  //   // let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
  //   // setConvertedContent(currentContentAsHTML);
  // };
  //  const onChange = async (value) => {
  //   // setEditorState(value);
  //   convertContentToHTML();
  // };

  // API CALLS
  // get template API
  const getAppointmentConfirmationTemplate = () => {
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId, template_for: templateFor };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          // setEditorState(() =>
          //   EditorState.createWithContent(ContentState.createFromBlockArray(
          //       convertFromHTML(data[0]?.email_template_body)
          //     ))
          // );
          // setConvertedContent(data[0]?.email_template_body);
          setEditorContent(data[0]?.email_template_body);
          // setAgreementBody(data[0]?.email_template_body);
          setShowLoader(false);
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      formdata,
      "GET_EMAIL_TEMPLATE"
    );
  };

  // save or edit template api
  const saveTemplate = () => {
    // let currentDefaultContentAsHTML = convertToHTML(editorState.getCurrentContent());
    toast.dismiss();
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId, template_for: templateFor, email_body: editorContent };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setShowLoader(false);
          navigate(`/notifications`);
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      formdata,
      "EDIT_EMAIL_TEMPLATE"
    );
  };

  // send test mail
  const sendTestMail = () => {
    toast.dismiss();
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId, template_for: templateFor, email_body: editorContent };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setShowLoader(false);
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      formdata,
      "SEND_TEST_EMAIL"
    );
  };

  // reset template api
  const resetTemplate = () => {
    toast.dismiss();
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId, template_for: templateFor };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          toast.success(message);
          //   setEditorState(() =>
          //   EditorState.createWithContent(ContentState.createFromBlockArray(
          //       convertFromHTML(data?.email_template_body)
          //     ))
          // );
          // setConvertedContent(data?.email_template_body);
          setEditorContent(data?.email_template_body);
          // setAgreementBody(data?.email_template_body);
          setShowLoader(false);
        } else {
          toast.error(message);
          setShowLoader(false);
        }
      },
      formdata,
      "RESET_MAIL_TEMLATE"
    );
  };

  useEffect(() => {
    getAppointmentConfirmationTemplate();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box sx={{ width: "100%", px: { xs: "0", sm: "20px", md: "80px", lg: "80px" } }} className="teams-container">
        <Preloader showPreloader={showLoader} />
        <Grid item xs={12} className="editor-title-wrapper">
          <div>
            <h3 className="editor-title color3B">{title}</h3>
            <p className="editor_details_subtitle">{subTitle} </p>
          </div>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={9}>
            {/* <Editor
              editorState={editorState}
              defaultEditorState={editorState}
              // onEditorStateChange={handleEditorChange}
              wrapperClassName="wrapper-class"
              editorClassName="editor-class"
              toolbarClassName="toolbar-class"
              onEditorStateChange={(value) => {
                onChange(value);
              }}
              toolbar={
                {
                  options: ["inline", "fontSize", "fontFamily","list","textAlign","colorPicker","link","embedded","image", "remove",],
                  inline: {
                    inDropdown: false,
                    options: ["bold", "italic", "underline"],
                  },
                }
              }
            /> */}
            <SunEditor
              className="editor-class"
              setDefaultStyle="font-size: 14px;"
              height="100%"
              onDrop={onDropHandle}
              setOptions={{
                // buttonList:[['bold','italic', 'underline', 'list','fontColor','fontSize']]
                buttonList: [
                  // default
                  ["bold", "underline", "italic", "font", "fontSize", "fontColor"],
                  ["align", "list"],
                  ["link"],
                  ["fullScreen", "preview"],
                  // (min-width: 992)
                  [
                    "%992",
                    [
                      ["bold", "underline", "italic", "font", "fontSize", "fontColor"],
                      ["align", "list"],
                      ["link"],
                      ["fullScreen", "preview"],
                    ],
                  ],
                  // (min-width: 767)
                  [
                    "%767",
                    [
                      ["bold", "underline", "italic", "font", "fontSize", "fontColor"],
                      ["align", "list"],
                      ["link"],
                      ["fullScreen", "preview"],
                    ],
                  ],
                  // (min-width: 480)
                  [
                    "%480",
                    [
                      ["bold", "underline", "italic", "font", "fontSize", "fontColor"],
                      ["align", "list"],
                      ["link"],
                      ["fullScreen", "preview"],
                    ],
                  ],
                ],
              }}
              onChange={handleChange}
              defaultValue={editorContent}
              setContents={editorContent}
              onClick={handleClickEditor}
              getSunEditorInstance={getSunEditorInstance}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <Placeholder editor={editor} setShowLoader={setShowLoader} />
          </Grid>
          <Grid item xs={12} className="agreement-action">
         <Button className="agreement-btn editor-btn" sx={{":hover":{backgroundColor:"#1E8970 !important"}}} onClick={saveTemplate}>
              Save
            </Button>
            <Button sx={{textTransform:"none !important",":hover":{backgroundColor:"#1E8970 !important"}}} className="agreement-btn editor-btn" onClick={sendTestMail}>
              Send test
            </Button>
            <span className="navlink" id="reset_to_default_email" onClick={resetTemplate}>
              <span>Reset to default</span>
            </span>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EmailTemplate;
