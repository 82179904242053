import { FormControl, Grid, Menu, MenuItem, Select, TextField } from "@mui/material"
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useState } from "react";
import Fade from '@mui/material/Fade';
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { SignatureIcon } from "../../../../../assets/svgIcons/Svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { debounce } from "lodash";
import { deleteElement, updateElementJsonByUUID, updateJSON } from "../PtTemplateHelperFunctions";
import { useEffect } from "react";
import { useCallback } from "react";

const Signature = ({catuuid,templateState,setTemplateState,updatePtTempateData,label,uuid,type,parentType,merge_id,isDragging,setDragDisable,nestingLevel}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [signature,setSignature] = useState("");
  const [signatureTag,setSignatureTag] = useState('');
  const [editedInpMerge,setEditedInpMerge] = useState(false);
  const [mergeIdError,setMergeIdError] = useState(false);
  const [active,setActive] = useState(false);
  // const [signatureType,setSignatureType] = useState("Signature/Any");
  const [editedInpData,setEditedInpData] = useState(false);
  const open = Boolean(anchorEl);
  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  // const handleChangeSignatureType=(e)=>{
  //   let prevData = JSON.parse(JSON.stringify(templateState?.templateDataCopy));
  //   setSignatureType(e.target.value)
  //   setTemplateState(prev=>({...prev,updatingTemplate:true}))
  //   const updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, e.target.value,'type')
  //   if (updatedData) {
  //     prevData.data = updatedData;
  //     setTemplateState(prev=>({...prev,templateData:prevData}))
  //     let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
  //     let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
  //     let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
  //     newUpdatedSpecificData.data.template.pdf_categories = editedCategory
  //     updatePtTempateData(newUpdatedSpecificData?.data?.template)
  //   }
  // }

  const clickAwaySaveptData=()=>{
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
    updateJSON(prevData, uuid, signature,"label");
    let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
    let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
    newUpdatedSpecificData.data.template.pdf_categories = editedCategory
    setTemplateState(prev=>({...prev,templateData:prevData}))
    updatePtTempateData(newUpdatedSpecificData?.data?.template)       
    setEditedInpData(false)
  }

  const clickAwaySaveptMergeData=()=>{ 
    if(mergeIdError) { 
      setSignatureTag('')
      let updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, "",'tag');
      let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
      prevData.data = updatedData;
      setTemplateState(prev=>({...prev,templateData:prevData}))
      setMergeIdError(false)
      setEditedInpMerge(false)
    }
    else{
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
    updateJSON(prevData, uuid, signatureTag,"tag");
    let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
    let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
    newUpdatedSpecificData.data.template.pdf_categories = editedCategory
    setTemplateState(prev=>({...prev,templateData:prevData}))
    updatePtTempateData(newUpdatedSpecificData?.data?.template)       
    setEditedInpMerge(false)
    }  
  }

  // Create a debounced function to update the state
  const debouncedUpdateData = debounce((prevData) => {   
    setTemplateState(prev=>({...prev,templateData:prevData}))
  }, 2000); // Delay state update by 2 seconds
  const handleSignatureChange = useCallback((e,changeType) => {
    e.persist();
    let updValue= e.target.value
    let updatedData;
    let prevData = JSON.parse(JSON.stringify(templateState?.templateDataCopy));
    if(changeType === "label"){
      setEditedInpData(true) 
      setSignature(updValue);  
      updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue,'label');
    }
    else if(changeType === 'tag'){
      setSignatureTag(updValue)
      setEditedInpMerge(true);
      if(templateState?.mergeIdArray.includes(updValue)) {       
        setMergeIdError(true);
        updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue,'tag');
      }
      else{
        setMergeIdError(false);
        updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue,'tag');
      }     
    }
    if (updatedData) {
      prevData.data = updatedData;
      debouncedUpdateData(prevData);
    }
  }, []);

  const activateDragIn=()=>{
    setDragDisable(true);
    if(nestingLevel > 0){
       sessionStorage.setItem("firstLevelDrag", true);
    }
  }
  const activateDragOut=()=>{
    setDragDisable(false);
    if(nestingLevel > 0){
       sessionStorage.setItem("firstLevelDrag", false);
    }
  }
  
  useEffect(() => {
    setSignature(label)
    // setSignatureType(type)
    setSignatureTag(merge_id)
  }, [templateState])

  return (
    <ClickAwayListener  mouseEvent="onMouseDown"
                        touchEvent="onTouchStart" onClickAway={() => active && setActive(false)}>
    <Grid container className="pt_card_border pt_inputfield" onClick={()=>setActive(true)}
     onMouseEnter={()=>parentType === "expandingCheckBox" || parentType==="group"?activateDragIn():null} onMouseLeave={()=>parentType === "expandingCheckBox" || parentType==="group"?activateDragOut():null} 
     >
        <div className={`${isDragging || active?'pt_ip_drag_section pt_elem_dragging':"pt_ip_drag_section"} `}><DragIndicatorIcon className="pt_icon_color"/></div>
        <div className="pt_ip_content">
            <div className="pt_ip_data">
                <div className="pt_ip_data_row1 pt_is_flex_align_center pt_space_between">
                <div className="pt_is_flex_align_center gap5 imported_icon">
                    {SignatureIcon}
                        <span className="pt_element_name">Signature</span>
                    </div>  
                    {/* <FormControl fullWidth >
                    <Select                    
                    labelId="input_type"
                    id="input_type"
                    size="small"
                    value={signatureType}
                    onChange={(e)=>handleChangeSignatureType(e)}
                    >                                  
                        <MenuItem  value="Signature/Any">
                            Any
                        </MenuItem>
                        <MenuItem  value="Signature/Inspector">
                            Inspector
                        </MenuItem>
                    </Select>  
                    </FormControl> */}
                </div>
                <Grid container>
                  <Grid item xs={12} className="pt_merge_field">
                  <label className="pt_form_label" htmlFor="pt_signature_label_merge" id="pt_signature_label">
                  merge field id
                  </label>
                  <ClickAwayListener  mouseEvent="onMouseDown"
                        touchEvent="onTouchStart" onClickAway={() => editedInpMerge && clickAwaySaveptMergeData()}>
                  <TextField
                     style={{width:'50%',display:'block',background:'#fff',borderColor: mergeIdError ? '#d32f2f' : '#ced4da'}}
                    type="text"
                    id="pt_signature_label_merge"
                    size="small"
                    name="pt_signature_label_merge"
                    variant="outlined"
                    disabled={templateState.roleId !== 2 && templateState.roleId !== 3}
                    value={signatureTag}
                    error={mergeIdError}
                    helperText={`${mergeIdError ? "This merge ID is in use."  : ""}`}
                    onChange={(e)=>handleSignatureChange(e,'tag')}
                    placeholder="ID tag"
                    className={mergeIdError?`input-textfield pt_focussed`:"input-textfield"}/>
                     </ClickAwayListener>
                  </Grid>
                <Grid item xs={12}>
                      <FormControl variant="standard" className="pt_w_100">
                        <label
                          className="pt_form_label"
                          htmlFor="pt_sgnature"
                          id="demo-simple-select-label"
                        >
                          Label
                        </label>
                        <ClickAwayListener  mouseEvent="onMouseDown"
                          touchEvent="onTouchStart" onClickAway={() => editedInpData && clickAwaySaveptData()}>
                        <TextField
                          fullWidth
                          type="text"
                          id="pt_sgnature"
                          size="small"
                          name="pt_sgnature"
                          variant="outlined"
                          disabled={templateState.roleId !== 2 && templateState.roleId !== 3}
                          value={signature}
                          onChange={(e)=>handleSignatureChange(e,'label')}
                          placeholder="Input title"
                          className="input-textfield"
                        />
                         </ClickAwayListener>
                      </FormControl>
                    </Grid>
                </Grid>
            </div>
            <div className={`${templateState.roleId !== 2 && templateState.roleId !== 3?'pt_ip_action pt_ip_disabled':'pt_ip_action pt_ip_enabled'}`} ><MoreHorizIcon className="pt_icon_color" 
            onClick={templateState.roleId !== 2 && templateState.roleId !== 3? null : handleClickMore}/>
            <Menu
              id="fade-menu"
              MenuListProps={{'aria-labelledby': 'fade-button',}}
              anchorOrigin={{vertical: 'top',horizontal: 'right',}}
              transformOrigin={{vertical: 'top',horizontal: 'right',}}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              className="pt_more_menu"
              >
                <span><CloseIcon onClick={handleClose}/></span>
        <MenuItem onClick={()=>deleteElement(templateState, setTemplateState, parentType, uuid, catuuid, updatePtTempateData,handleClose)}><DeleteIcon/>Delete</MenuItem>
            </Menu>
      </div>
        </div>
    </Grid>
    </ClickAwayListener>
  )
}

export default Signature