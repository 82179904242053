import React, { useState,useEffect } from "react";
import toast from "react-hot-toast";
import NotificationCenterCard from "../../common/NotificationCenterShared/NotificationCenterCard";
import { Grid} from "@mui/material";
import { Box } from "@mui/system";
import { apiCall } from "../../utils/action";
import Preloader from "../../helpers/Preloader";

const NotificationCenter = () => {
  const [showLoader,setShowLoader] = useState(false);
  const [storedSettings,setStoredSettings] = useState([]);
  const [appointmentConfirmation , setAppointmentConfirmation] = useState([]);
  const [rescheduleConfirmation , setRescheduleConfirmation] = useState([]);
  const [agreementToSign , setAgreementToSign] = useState([]);
  const [paymentMade , setPaymentMade] = useState([]);
  const [publishInspection , setPublishInspection] = useState([]);
  const [signatureNeeded , setSignatureNeeded] = useState([]);
  const [agreementSigned , setAgreementSigned] = useState([]);
  const [paymentRequest , setPaymentRequest] = useState([]);
  const [paymentReminder , setPaymentReminder] = useState([]);
  const [paymentSignature , setPaymentSignature] = useState([]);

  // session datas
  const uuId = sessionStorage.getItem("user_id");
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");

const storeDefaultNotificationSettings=()=>{
  setShowLoader(true);
  const formdata = { company_id: companyId, user_id: uuId, role_id: roleId,
     appointment_confirmation:[8],
     inspection_rescheduled:[8,9],
     signature_needed:[8,9],
    signature_needed_reminder:[8,9], 
    agreement_signed_confirmation:[7,8],
    email_inspection_published_confirmation:[7,8,9],
     email_payment_confirmation:[7,8],
    payment_request:[8],
    payment_reminder:[8,9],
    payment_and_signature_request:[8,9]
    };
  apiCall(
    (response) => {
      const {  message, success } = response.data;
      if (success) {
        toast.success(message); 
        getNotificationSettings()
        setShowLoader(false);         
      } else {
         toast.error(message);          
         setShowLoader(false);
      }
    },
    formdata,
    "SAVE_NOTIFICATION_SETTINGS"
  );
}

  // get notification settings from API
 const getNotificationSettings = () => {
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setStoredSettings(data);
          // setAppointmentConfirmation(data.map(elem=>{
          //   return elem.appointment_confirmation
          // }))
          let appConfirmData = data?.find(el=>el.appointment_confirmation)?.appointment_confirmation;
          let resheduleConfirmData = data?.find(el=>el.inspection_rescheduled)?.inspection_rescheduled;
          let agrmtSignData = data?.find(el=>el.signature_needed)?.signature_needed;
          let paymentData = data?.find(el=>el.email_payment_confirmation)?.email_payment_confirmation;
          let inspPublishData = data?.find(el=>el.email_inspection_published_confirmation)?.email_inspection_published_confirmation;          
          let signatureNeededReminder = data?.find(el=>el.signature_needed_reminder)?.signature_needed_reminder;
          let agrmntSigned = data?.find(el=>el.agreement_signed_confirmation)?.agreement_signed_confirmation;
          let paymentReq = data?.find(el=>el.payment_request)?.payment_request;
          let paymntRemind = data?.find(el=>el.payment_reminder)?.payment_reminder;
          let paySignature = data?.find(el=>el.payment_and_signature_request)?.payment_and_signature_request;
          setAppointmentConfirmation(appConfirmData !== undefined ? appConfirmData:[]);
          setRescheduleConfirmation(resheduleConfirmData !== undefined ?resheduleConfirmData:[]);
          setAgreementToSign(agrmtSignData !== undefined ? agrmtSignData:[]);
          setPaymentMade(paymentData);
          setPublishInspection(inspPublishData);
          setSignatureNeeded(signatureNeededReminder !== undefined ? signatureNeededReminder:[])
          setAgreementSigned(agrmntSigned !== undefined ? agrmntSigned:[])
          setPaymentRequest(paymentReq !== undefined ? paymentReq:[])
          setPaymentReminder(paymntRemind !== undefined ? paymntRemind:[])
          setPaymentSignature(paymentSignature !== undefined ? paySignature:[])
          // toast.success(message); 
          setShowLoader(false);
         
        } else {
          //  toast.error(message);
          if(message === "No notification settings data found."){
            storeDefaultNotificationSettings();
          }
           setShowLoader(false);
        }
      },
      formdata,
      "GET_NOTIFICATION_SETTINGS"
    );
  };

  // save notification settings
  const storeNotificationSettings =(array,key)=>{
    toast.dismiss(); 
    let formdata;
    setShowLoader(true);
    switch(key){
      case 'appointment_confirmation':
        formdata = { company_id: companyId, user_id: uuId, role_id: roleId, appointment_confirmation: array };
        break;
      case 'inspection_rescheduled':
        formdata = { company_id: companyId, user_id: uuId, role_id: roleId, inspection_rescheduled: array };
        break;
      case 'signature_needed':
        formdata = { company_id: companyId, user_id: uuId, role_id: roleId, signature_needed: array };
        break;
      case 'email_inspection_published_confirmation':
        formdata = { company_id: companyId, user_id: uuId, role_id: roleId, email_inspection_published_confirmation: array };
        break;
      case 'email_payment_confirmation':
        formdata = { company_id: companyId, user_id: uuId, role_id: roleId, email_payment_confirmation: array };
        break;
        case 'signature_needed_reminder':
        formdata = { company_id: companyId, user_id: uuId, role_id: roleId, signature_needed_reminder: array };
        break;
        case 'payment_request':
        formdata = { company_id: companyId, user_id: uuId, role_id: roleId, payment_request: array };
        break;
        case 'payment_reminder':
        formdata = { company_id: companyId, user_id: uuId, role_id: roleId, payment_reminder: array };
        break;
        case 'agreement_signed_confirmation':
        formdata = { company_id: companyId, user_id: uuId, role_id: roleId, agreement_signed_confirmation: array };
        break;
        case 'payment_and_signature_request':
        formdata = { company_id: companyId, user_id: uuId, role_id: roleId, payment_and_signature_request: array };
        break;
      default:
        formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    }    
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          toast.success(message); 
          getNotificationSettings()
          setShowLoader(false);         
        } else {
           toast.error(message);          
           setShowLoader(false);
        }
      },
      formdata,
      "SAVE_NOTIFICATION_SETTINGS"
    );
  }

 
  useEffect(()=>{
    getNotificationSettings()
  },[])
  
  return (
    <>
      <Box sx={{ width: "100%",px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },}}
        className="teams-container">
          <Preloader showPreloader={showLoader} />
        <Grid item xs={12} className="details-title">
          <span className="team-user-name">Email Notifications</span>
          <p style={{color:"#3B3B3B"}}>
            These emails will automatically send to the checked parties when the event is triggered. 
          </p>
        </Grid>
        <Grid container spacing={3}>
        {/* Appointment Confirmation Card */}
          <NotificationCenterCard title="Appointment Confirmation" 
          label1="Inspection confirmation email sent to buyer(s)."
          label2="Inspection confirmation email sent to buyer’s agent."
          label3='Inspection confirmation email sent to inspector.'
          marginVal='13'
          navigationPath="appointment-confirmation"
          keyVal='appointment_confirmation'
          currentStatus={appointmentConfirmation}
          checkedStatus={setAppointmentConfirmation}
          storeNotificationSettings={storeNotificationSettings}/>     

        {/* Reschedule Confirmation Card*/}
        <NotificationCenterCard title="Updated Details after Rescheduling Inspection" 
          label1="Inspection reschedule confirmation email sent to buyer(s)."
          label2="Inspection reschedule confirmation email sent to buyer’s agent."
          label3='Inspection reschedule confirmation email sent to inspector.'
          marginVal='13'
          navigationPath="reschedule-confirmation"
          keyVal='inspection_rescheduled'
          currentStatus={rescheduleConfirmation}
          checkedStatus={setRescheduleConfirmation}
          storeNotificationSettings={storeNotificationSettings}/>     

        {/*Agreement to sign Card*/}
        <NotificationCenterCard title="Request to Sign Inspection Agreement" 
          label1="Email the agreement to the buyer, once an inspection is created with the buyer’s name and email."
          label2="Email a copy of the agreement to the buyer’s agent, once an inspection is created with the buyer’s name and email."
          label3='Email a copy of the agreement to the inspector, once an inspection is created with the buyer’s name and email.'
          marginVal='13'
          navigationPath="agreement-to-sign"
          keyVal='signature_needed'
          currentStatus={agreementToSign}
          checkedStatus={setAgreementToSign}
          storeNotificationSettings={storeNotificationSettings}/>   

          {/* Signature needed reminder (new)*/}
          <NotificationCenterCard title="Reminder to Sign Agreement" 
          label1="Email the agreement to the buyer, once an inspection is created with the buyer’s name and email."
          label2="Email the agreement to the buyer’s agent, once an inspection is created with the buyer’s name and email."
          label3="Email a copy of the agreement to the inspector, once an inspection is created with the buyer’s name and email."
          marginVal='13'
          navigationPath="signature-needed"
          keyVal='signature_needed_reminder'
          currentStatus={signatureNeeded}
          checkedStatus={setSignatureNeeded}
          storeNotificationSettings={storeNotificationSettings}/> 

           {/* Agreement signed confirmation(new) */}
          <NotificationCenterCard title="Confirmation of Signed Agreement" 
          label1="Agreement Signed confirmation email sent to buyer(s)."
          label2="Agreement Signed confirmation email sent to buyer’s agent."
          label3="Agreement Signed confirmation email sent to inspector."
          marginVal='13'
          navigationPath="agreement-signed-confirmation"
          keyVal='agreement_signed_confirmation'
          currentStatus={agreementSigned}
          checkedStatus={setAgreementSigned}
          storeNotificationSettings={storeNotificationSettings}/>   

        {/*Payment made Card*/}
        <NotificationCenterCard title="Email Payment Confirmation" 
          label1="Payment confirmation email sent to buyer(s)."
          label2="Payment confirmation email sent to buyer’s agent."
          label3="Payment confirmation sent to inspector."
          marginVal='13'
          navigationPath="payment-made"
          keyVal='email_payment_confirmation'
          currentStatus={paymentMade}
          checkedStatus={setPaymentMade}
          storeNotificationSettings={storeNotificationSettings}/> 

          {/*Payment request(new)*/}
        <NotificationCenterCard title="Send Payment Request" 
          label1="Payment request email sent to buyer(s)."
          label2="Payment request email sent to buyer’s agent"
          label3="Payment request email sent to inspector."
          marginVal='13'
          navigationPath="payment-request"
          keyVal='payment_request'
          currentStatus={paymentRequest}
          checkedStatus={setPaymentRequest}
          storeNotificationSettings={storeNotificationSettings}/> 

            {/*Payment reminder(new)*/}
        <NotificationCenterCard title="Send Payment Reminder" 
          label1="Payment reminder email sent to buyer(s)."
          label2="Payment reminder email sent to buyer’s agent."
          label3="Payment reminder email sent to inspector."
          marginVal='13'
          navigationPath="payment-reminder"
          keyVal='payment_reminder' 
          currentStatus={paymentReminder}
          checkedStatus={setPaymentReminder}
          storeNotificationSettings={storeNotificationSettings}/> 

        {/*Inspection has been published Card*/}
        <NotificationCenterCard title="Inspection has been published" 
          label1="Link to published inspection report sent to buyer (if payment is recorded or marked as complete)."
          label2="Link to published inspection report sent to buyer’s agent (if payment is recorded or marked as complete)."
          label3="Link to published inspection report sent to inspector (if payment is recorded or marked as complete)."
          marginVal='13'
          navigationPath="inspection-published"
          keyVal='email_inspection_published_confirmation'
          currentStatus={publishInspection}
          checkedStatus={setPublishInspection}
          storeNotificationSettings={storeNotificationSettings}/>

        {/*payment and signature request Card(new)*/}
        <NotificationCenterCard title="Request for Payment and Inspection Agreement Signature" 
          label1="Inspection confirmation combined email sent to buyer(s)."
          label2="Inspection confirmation combined email sent to buyer’s agent."
          label3="Inspection confirmation combined email sent to inspector."
          marginVal='18'
          navigationPath="payment-signature"
          keyVal='payment_and_signature_request'
          currentStatus={paymentSignature}
          checkedStatus={setPaymentSignature}
          storeNotificationSettings={storeNotificationSettings}/>
        </Grid>

        {/* SMS Notifications */}
        {/* <Grid item xs={12} className="details-title mt-80">
          <span className="team-user-name">SMS Notifications</span>
          <p style={{color:"#3B3B3B"}}>
          These texts will automatically send to the checked parties when the event is triggered. 
          </p>
        </Grid>
        <Grid container spacing={3}>
        Appointment Confirmation Card
          <NotificationCenterCard title="Appointment Confirmation" 
          label1="Inspection confirmation email sent to buyer(s). "
          label2="Inspection confirmation email sent to buyer’s agent."
          label3=''
          marginVal='0'
          navigationPath="toRoute"/>     

        Reschedule Confirmation Card
        <NotificationCenterCard title="Reschedule Confirmation" 
          label1="Inspection reschedule confirmation email sent to buyer(s).  "
          label2="Inspection reschedule confirmation email sent to buyer’s agent."
          label3=''
          marginVal='0'
          navigationPath="toRoute"/>     

        Agreement to sign Card
        <NotificationCenterCard title="Agreement to sign" 
          label1="Email the agreement to the buyer, once an inspection is created with the buyer’s name and email.   "
          label2="Email a copy of the agreement to the buyer’s agent, once an inspection is created with the buyer’s name and email. "
          label3=''
          marginVal='15'
          navigationPath="toRoute"/>     

        Payment made Card
        <NotificationCenterCard title="Payment made" 
          label1="Inspection confirmation email sent to buyer(s). "
          label2="Inspection confirmation email sent to buyer’s agent. "
          label3="Payment confirmation sent to inspector."
          marginVal='0'
          navigationPath="toRoute"/> 

        Inspection has been published Card
        <NotificationCenterCard title="Inspection has been published" 
          label1="Link to published inspection report sent to buyer (if payment is recorded or marked as complete). "
          label2="Link to published inspection report sent to buyer’s agent (if payment is recorded or marked as complete).  "
          label3=""
          marginVal='15'
          navigationPath="toRoute"/>
        </Grid> */}

        {/* App notifications
        <Grid item xs={12} className="details-title mt-80">
          <span className="team-user-name">App Notifications</span>
          <p style={{color:"#3B3B3B"}}>
          These notifications will alert the assigned inspector in the app. 
          </p>
        </Grid>
        <Grid container spacing={3}>
        Appointment Confirmation Card
          <NotificationCenterCard title="" 
          label1="Unpaid inspection coming up. "
          label2="Missing address for inspection. "
          label3=''
          marginVal='0'
          navigationPath="toRoute"/>    
        </Grid> */}
      </Box>
    </>
  );
};

export default NotificationCenter;
