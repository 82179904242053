import { useEffect, useState } from "react";
import Checkout from "./Checkout";
import { Button } from "@mui/material";
import { apiCall } from "../Services/action";
import { MonthlyPlanSvg } from "../../../../assets/svgIcons/Svg";

const UpgradePlan = (props) => {
  const { planDetails, allPlans, setOpen, trailDetails, setRefresh, refresh } = props;
  const [additionalLicense, setAdditionalLicense] = useState(planDetails?.additional_license_count ? planDetails?.additional_license_count : 0);
  // const [loading, setLoading] = useState(false);
  // const [confirmModal, setConfirmModal] = useState(false);
  // const closeConfirmModal = () => setConfirmModal(false);
  const [proratedCheckoutAmount, setProratedCheckoutAmount] = useState();
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);
  const closeUpgradeModal = () => setUpgradePlanModal(false);
  const [details, setDetails] = useState({
    subscriptionTitle: "",
    subscriptionPrice: "",
    estimatedTaxes: "",
    discountPrice: 0,
    subscriptionID: "",
    additionalLicensePrice: "",
  });
  const previewSubscription = (prodID) => {
    const formdata = { company_id: sessionStorage.getItem("company_id"), user_id: sessionStorage.getItem("user_id"), role_id: sessionStorage.getItem("role_id"), product_id: prodID };
    apiCall(
      (response) => {
        const { message, success, data } = response.data;
        if (success) {
          setProratedCheckoutAmount(data?.price);
        } else {
          console.log("else ", message);
        }
      },
      formdata,
      "PREVIEW_SUBSCRIPTION"
    );
  };
  useEffect(() => {
    setAdditionalLicense(planDetails?.additional_license_count);
  }, [planDetails?.additional_license_count]);
  // var discountPercentage = (parseInt(details?.discountPrice) / parseInt(details?.subscriptionPrice)) * 100;
  // var Total = (parseFloat(details?.subscriptionPrice) + parseFloat(details?.additionalLicensePrice) * parseFloat(additionalLicense) + parseFloat(details?.estimatedTaxes) - parseFloat(details?.discountPrice)).toFixed(2);
  const openUpgradeModal = () => {
    if (trailDetails.isTrail === 1) {
      const details = allPlans?.filter((e) => e?.title === "Monthly plan");
      window.open(details[0].public_signup_page_url, "_blank");
    }
    if (trailDetails.isTrail !== 1) {
      setUpgradePlanModal(true);
      if (trailDetails.isTrail === 1) {
        const details = allPlans?.filter((e) => e?.title === "Monthly plan");
        setDetails({ ...details, subscriptionTitle: "Monthly", subscriptionPrice: details[0].price, additionalLicensePrice: details[0].additional_license_price, estimatedTaxes: details[0].taxes, discountPrice: details[0].coupon_code_discount_price ? details[0].coupon_code_discount_price : 0, subscriptionID: details[0].product_id });
        previewSubscription(details[0].product_id);
        // window.open(url[0].public_signup_page_url, "_blank");
      }
      if (planDetails?.title === "Monthly plan") {
        const details = allPlans?.filter((e) => e?.title === "Yearly plan");
        setDetails({ ...details, subscriptionTitle: "Yearly", subscriptionPrice: details[0].price, additionalLicensePrice: details[0].additional_license_price, estimatedTaxes: details[0].taxes, discountPrice: details[0].coupon_code_discount_price ? details[0].coupon_code_discount_price : 0, subscriptionID: details[0].product_id });
        previewSubscription(details[0].product_id);
        // window.open(url[0].public_signup_page_url, "_blank");
      }
      if (planDetails?.title === "Yearly plan" || planDetails?.title === "Annual plan" || planDetails?.title === "Annual plan + Website Hosting & Setup") {
        const details = allPlans?.filter((e) => e?.title === "Yearly plan");
        setDetails({ ...details, subscriptionTitle: "Yearly plan", subscriptionPrice: details[0].price, additionalLicensePrice: details[0].additional_license_price, estimatedTaxes: details[0].taxes, discountPrice: details[0].coupon_code_discount_price ? details[0].coupon_code_discount_price : 0, subscriptionID: details[0].product_id });
        previewSubscription(details[0].product_id);
        // window.open(url[0].public_signup_page_url, "_blank");
      }
    }
  };
  //add license api
  // const upgradePlan = () => {
  //   setLoading(true);
  //   const formdata = { company_id: sessionStorage.getItem("company_id"), user_id: sessionStorage.getItem("user_id"), role_id: sessionStorage.getItem("role_id"), product_id: details?.subscriptionID };
  //   apiCall(
  //     (response) => {
  //       const { message, success } = response.data;
  //       if (success) {
  //         setLoading(false);
  //         setRefresh(!refresh);
  //         setUpgradePlanModal(false);
  //         toast.success(message);
  //         setConfirmModal(true);
  //       } else {
  //         setLoading(false);
  //         toast.error(message);
  //         console.log("else ", message);
  //       }
  //     },
  //     formdata,
  //     "UPGRADE_PLAN"
  //   );
  // };
  return (
    <>
      <div className="status-card">
        <MonthlyPlanSvg />
        {trailDetails.isTrail === 1 ? (
          <>
            <p className="upgrade-text1">
              <span className="upgrade-100">$50</span> a user per month
            </p>
            <p className="upgrade-text2">No hidden fees. Free tech support. Never pay for updates. Cancel anytime. </p>
          </>
        ) : (
          <>
            <p className="upgrade-text1">
              Save an extra <span className="upgrade-100">$100</span> per user each year
            </p>
            <p className="upgrade-text2">by switching to our annual plan.</p>
          </>
        )}
        <div className="upgrade-plan-button-container">
          {planDetails?.title === "Software + Website" || planDetails?.title === "Yearly plan" ? (
            ""
          ) : (
            <Button className="upgrade-plan-button" variant="none" onClick={openUpgradeModal}>
              {trailDetails.isTrail === 1 ? "Subscribe now" : "Upgrade Plan"}
            </Button>
          )}
          <p className="see-all-plans-button" onClick={() => setOpen(true)}>
            See all plans
          </p>
        </div>
      </div>
      <Checkout proratedCheckoutAmount={proratedCheckoutAmount} upgradePlanModal={upgradePlanModal} closeUpgradeModal={closeUpgradeModal} details={details} setRefresh={setRefresh} refresh={refresh} additionalLicense={additionalLicense} setUpgradePlanModal={setUpgradePlanModal} />
    </>
  );
};

export default UpgradePlan;