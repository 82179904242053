import { useState, useEffect } from "react";
import { Box, Card, Grid, SvgIcon, Typography } from "@mui/material";
import Preloader from "../../helpers/Preloader";
import "../../../assets/styles/containers/_payments.scss";
import { guardianSvg } from "../../../assets/svgIcons/Svg";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AntSwitch from "../../common/antSwitch/AntSwitch";
// import { Userpilot } from "userpilot";
import { apiCall } from "../../utils/action";
import toast from "react-hot-toast";
const PaymentSettings = () => {
  const uuId = sessionStorage.getItem("user_id");
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  // states
  const [showLoader, setShowLoader] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(0);
  const [paymentDescription, setPaymentDescription] = useState("");
  const [paymentSetupStatus, setPaymentSetupStatus] = useState(0);
  const [statusChanged, setStatusChanged] = useState(false);
  // api calls
  const getPaymentInfo = () => {
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          setPaymentStatus(data.payment?.payment_status);
          setPaymentDescription(data.payment?.payment_status_description);
          setPaymentSetupStatus(data.payment?.payment_setup_status);
          sessionStorage.setItem("is_trial", data.is_trial);
          setShowLoader(false);
        } else {
          setShowLoader(false);
          toast.error(message);
        }
      },
      formdata,
      "GET_COMPANY_INFO"
    );
  };
  const updatePayment = (status) => {
    toast.dismiss();
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      payment_status: status,
    };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          // PALM 10151
          // Userpilot.track("Payment Toggle");
          setStatusChanged((prev) => !prev);
          setShowLoader(false);
        } else {
          setShowLoader(false);
          toast.error(message);
        }
      },
      formdata,
      "UPDATE_PAYMENT_INFO"
    );
  };
  const defaultSwitchValue = (toggleStatus) => {
    updatePayment(toggleStatus);
    // PALM 10151
    // Userpilot.track("Payment Toggle");
  };
  useEffect(() => {
    getPaymentInfo();
  }, [statusChanged]);

  return (
    <Box sx={{ flexGrow: 1 }} className="page-background">
      <Preloader showPreloader={showLoader} />
      {showLoader ? null : (
        <Box
          sx={{
            width: "100%",
            px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },
          }}
          className="teams-container"
        >
          <Grid item xs={12} className="guardian-title-wrapper">
            <div className="p-8">
              <h3 className="guardian-title">Payments</h3>
              <p className="payments-subtitle">{`Set up how you get paid.`}</p>
            </div>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={7} md={5.5}>
              {paymentSetupStatus ? (
                <div className="default_toggler mb-23 p-8 guardian__toggle">
                  <div className="payment__toggle">
                    <AntSwitch
                      defaultValue={paymentStatus === 2 ? true : false}
                      id="payment_switch_btn"
                      defaultSwitchValue={defaultSwitchValue}
                    />
                    <span className="payment__toggle__status">
                      Payments are{" "}
                      {paymentDescription === "Disabled"
                        ? "disabled"
                        : "enabled"}
                      .{" "}
                    </span>
                  </div>
                </div>
              ) : null}
              <Card
                className="settings-card guardian"
                id={`guardian_settings`}
                name={`guardian_settings`}
                variant="outlined"
              >
                <Grid container m={2} display={"flex"} py={1} pr={2} pl={1}>
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    width={"100%"}
                    className="guardian__svg__header"
                  >
                    <Grid container display={"flex"} width={"100%"}>
                      <Grid item xs={6} className="guardian__svg">
                        {guardianSvg}
                      </Grid>
                      {paymentStatus === 2 ? (
                        <Grid
                          item
                          xs={6}
                          display={"flex"}
                          alignItems={"center"}
                          justifyContent={"flex-end"}
                          gap={1}
                          pr={3}
                        >
                          <Typography className="payment_status">
                            {paymentDescription}
                          </Typography>
                          <CheckCircleIcon className="payment_status_icon" />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid item className="name-wrapper" my={1} mr={1}>
                    <Typography className="payment_desc">
                      {`Use Guardian Inspection Payments to collect your inspection fees and get paid faster. One 20 minute appointment and you won't have to think about credit card payments again! `}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className="guardian__action">
                    <a
                      className="navlink guardian"
                      target="_blank"
                      href={`https://calendly.com/palmtech-f410/palmtech-payment-meeting`}
                    >
                      {`Get Started`}
                    </a>
                    <KeyboardArrowRightIcon className="arrow" />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};

export default PaymentSettings;
