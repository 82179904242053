import { Box } from "@mui/system";
import Header from "../../../common/header/Header";
import Breadcrumb from "../../../common/header/Breadcrumb";
import PtTemplateListView from "../../../components/PTadmin/PtTemplate/PtTemplateListView";

const PtTemplates = () => {
  return (
    <>
      {window.scrollTo(0, 0)}
      <Box sx={{ flexGrow: 1, backgroundColor: "#f7f9fa", minHeight: "100vh" }}>
        <Header />
        <Breadcrumb />
        <PtTemplateListView />
      </Box>
    </>
  );
};

export default PtTemplates;
