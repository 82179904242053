import React, { useState } from "react";
import { Grid } from "@mui/material";
import HtmlCommentEditor from "./HtmlCommentEditor";
import { timestamp } from "../../utils/services";
import uuid from "react-uuid";

const InformationSection = (props) => {
  const { informationData, categoryId, isPlaceHolder,item, lineId, lineInputControlId, AllCategoryList, updateTemplate } = props;
  const [editorContent, setEditorContent] = useState(informationData);
  let lineIndex = null;
  let infoIndex = null;
  const catList = AllCategoryList?.template?.categories;
  const categoriesDataIndex = catList.findIndex((cat) => cat.category_id === categoryId);
  const UpdateInfoData = () => {
    if (categoriesDataIndex !== -1) {
      lineIndex = catList[categoriesDataIndex].lines.findIndex((line) => line.line_id === lineId);
      infoIndex = catList[categoriesDataIndex].lines[lineIndex].line_input_controls.findIndex((info) => info.line_input_control_id === lineInputControlId);

      if (infoIndex === -1) {
        const inputControlId = uuid();
        infoIndex = 0;

        catList[categoriesDataIndex].lines[lineIndex].line_input_controls.push({
          data: editorContent,
          type: 'Information',
          prompt: 'Information',
          uuid: inputControlId,
          line_input_control_id: inputControlId,
          created_at: timestamp(),
          updated_at: timestamp(),
          values: [{value: editorContent}],
        });
      } else {
        catList[categoriesDataIndex].lines[lineIndex].line_input_controls[infoIndex].values = [{value: editorContent}];
        catList[categoriesDataIndex].lines[lineIndex].line_input_controls[infoIndex].data = editorContent;
        catList[categoriesDataIndex].lines[lineIndex].line_input_controls[infoIndex].updated_at = timestamp();
      }
    }

    updateTemplate("noToast",'',false);
  }


  return (
    <Grid sx={{ marginBottom: "52px", marginTop: "12px" }} >
      {/* <Typography className="line-header">{prompt}</Typography> */}
      <p className="line-sub-head">Informational text</p>
      <HtmlCommentEditor update={UpdateInfoData} editorContent={editorContent} setEditorContent={setEditorContent} />
    </Grid>
  );
};

export default InformationSection;
