//file imports-------------------------------------------------------------------------------------------------------------------/
import { useState } from "react";
import axios from "axios"; //API calls
import validator from "validator"; 
import toast  from 'react-hot-toast'; 
import { LoadingButton } from "@mui/lab"; 
import { useNavigate,useLocation,Link  } from "react-router-dom";
import { API_CONSTANTS } from "../../config/api-constants";
// import Checkbox from '@mui/material/Checkbox';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import logo from "../../../assets/images/logo.png"; //Palm-tech logo
import "../../../assets/styles/containers/_login.scss"; //SCSS import
import Visibility from "@mui/icons-material/Visibility"; //Icon
import VisibilityOff from "@mui/icons-material/VisibilityOff"; //Icon
import { Grid, Paper, TextField, Typography, InputLabel, IconButton, FormControl, useMediaQuery, InputAdornment } from "@mui/material";
// import { apiCall } from "../../utils/action";
//render function-----------------------------------------------------------------------------------------------------------------/
export const ResetPassword = () => {
  // const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword,setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const matches = useMediaQuery("(min-width:900px)");
  // const [emailerror, setemailError] = useState(false);
  // const [emailErrorText,setEmailErrorText] = useState('');
  const [success , setSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passworderror, setpasswordError] = useState(false);
  const [passworderrorText,setPassworderrorText] = useState('');
  const [conPassworderror, setConPassworderror] = useState(false);
  const [conPassworderrorText, setConPassworderrorText] = useState('');
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  // get token and email from reset mail url
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  let tokenParam = searchParams.get('token');
  let emailParam = searchParams.get('email');
  // navigator function**********************************************************/
  let navigate = useNavigate();
  // const routeChange = () => {
  //    navigate(`/dashboard`);
  // };
  
  // validation for email and password********************************************/
  const validation = () => {   
    if(password === ''){
      setPassworderrorText('Please enter a password');
      setpasswordError(true);      
    }else if(!validator.isStrongPassword(password)){
      setPassworderrorText('Error: Please make your password stronger. It must be at least 8 characters long, contain at least one special character, a number and have both uppercase and lowercase letters.')
      setpasswordError(true);
    }
    else{
      setPassworderrorText('')
      setpasswordError(false);
    }    
    if(confirmPassword === ''){
      setConPassworderrorText('Please enter a password');
      setConPassworderror(true);
    }else{
      setConPassworderror(false);
    }
    if(password !== '' && validator.isStrongPassword(password) && confirmPassword !== ''){     
      if(password !== confirmPassword){
        setpasswordError(true);
        setConPassworderror(true);
        setPassworderrorText('Passwords must match')
        setConPassworderrorText('Passwords must match')
      }
      else{
        setpasswordError(false)
        setConPassworderror(false);
      }
    }
    if ( password !== '' && validator.isStrongPassword(password) && password === confirmPassword) {
      resetPassword();
    }
  };
  //login user function*************************
  const resetPassword = () => {
    // setLoading(true);
    // const formdata = { email: emailParam, token:tokenParam, password ,password_confirmation:confirmPassword };
    // apiCall((response) => {
    //   console.log("RESET_PASSWORD", response);
    //   const { status_code, message, success } = response.data;
    //   if (success) {
    //     toast.success(message);
    //     setSuccess(true);
    //     setLoading(false);
    //   } else {
    //     console.log("else ", status_code);
    //      toast.error(message);
    //   }
    // }, formdata,"RESET_PASSWORD");
    setLoading(true);
    toast.dismiss();
    const formdata = { email: emailParam, token:tokenParam, password ,password_confirmation:confirmPassword };
    axios
      .post(`${API_CONSTANTS.RESET_PASSWORD}`, formdata)
      .then((response) => {
        // Request with success code true
        setSuccess(true);
        setLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          if (error.response.data.success === false) {
            toast.error(error.response.data.message);           
            setLoading(false);
          }
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("no response", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        setLoading(false);
      });
  };
  // return function starts from here *******************************************/
  return (
    <> 
      {sessionStorage.getItem('token') !== null ? navigate('/dashboard') : 
      <>
      {/* background div for mobile and desktop******************************** */}
      <div className={`${matches ? "background" : "background-mobile"}`}>
        <Grid container direction="column" alignItems="center" justifyContent="center">
          {/* palm tech logo*************************************************** */}
          <img className={`${matches ? "logo" : "logo-mobile"}`} src={logo} alt="logo"></img>
          {/* login component starts from here********************************* */}
          <Grid item xs={3}>
           {!success ? <Paper
              className="paper-normal"
              sx={{ minWidth: { xs: 370, md: 360, lg: 360 }, minHeight: { xs: 441, md: 387, lg: 469 }, boxShadow: "0 30px 30px 0 rgba(40, 40, 40, 0.4)" }}>
              <Grid className="center">
                <Typography className="header" sx={{ mt: "27px", fontSize: { xs: "24px", md: "22px", lg: "22px" }}}>
                  New password
                </Typography>
              </Grid>
              <Grid className="center">
                <Typography
                  className="header"
                  sx={{
                    mt: "27px",
                    fontSize: { xs: "14px", md: "14px", lg: "14px" },
                    width: "271px",
                    textAlign: "center",
                  }}
                >
                  Your new password must be different from previously used passwords
                </Typography>
              </Grid>
              
              {/* input field for password *************************************************************************************************** */}
              <Grid className="center">
                <FormControl variant="standard">
                  <InputLabel
                    htmlFor="component-simple" className="input_lable" sx={{ top: "4px",fontSize: { xs: "14px", md: "12px", lg: "12px" }}}>
                    CREATE A PASSWORD
                  </InputLabel>
                  <TextField className="input_field" variant="outlined" id="outlined-basic" error={passworderror} placeholder="Password" size={`${matches ? "small" : "medium"}`} type={showPassword ? "text" : "password"}
                    onChange={(event) => setPassword(event.target.value?.trim())}
                    onKeyPress={(e) => { if (e.key === 'Enter' && !loading) validation() }}
                    helperText={`${ passworderror === true ? passworderrorText : "min 8 characters with uppercase, lowercase, a number and a special character"}`}   
                    sx={{ marginTop: "47px", width: { xs: "340px", md: "317px", lg: "317px" }}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton sx={{ mr: "-9px" }} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      className: "input_props_field", sx: { height: { xs: "55px", md: "40px", lg: "40px" }, fontSize: { xs: "18px", md: "14px", lg: "14px" }},
                    }}
                  /> 
                </FormControl> 
              </Grid>
               {/* input field for password *************************************************************************************************** */}
               <Grid className="center">
                <FormControl variant="standard">
                  <InputLabel
                    htmlFor="component-simple" className="input_lable" sx={{ top: "4px",fontSize: { xs: "14px", md: "12px", lg: "12px" }}}>
                    CONFIRM PASSWORD
                  </InputLabel>
                  <TextField className="input_field" variant="outlined" id="outlined-basic1" error={conPassworderror} placeholder="Password" size={`${matches ? "small" : "medium"}`} type={showConfirmPassword ? "text" : "password"}
                    onChange={(event) => setConfirmPassword(event.target.value)}
                    onKeyPress={(e) => { if (e.key === 'Enter' && !loading) validation() }}
                    helperText={`${ conPassworderror === true ? conPassworderrorText: "" }`}
                    sx={{ marginTop: "47px", width: { xs: "340px", md: "317px", lg: "317px" }}}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton sx={{ mr: "-9px" }} onClick={()=>{setShowConfirmPassword(!showConfirmPassword)}} onMouseDown={()=>{setShowConfirmPassword(!showConfirmPassword)}}>
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      className: "input_props_field", sx: { height: { xs: "55px", md: "40px", lg: "40px" }, fontSize: { xs: "18px", md: "14px", lg: "14px" }},
                    }}
                  /> 
                </FormControl> 
              </Grid>
             
              {/* login in button ************************************************* */}
              <Grid className="center">
                <LoadingButton
                  className="login_button"
                  sx={{ height: { xs: 42, md: 34, lg: 34 }, width: { xs: 180, md: 119, lg: 164 }, fontSize: { xs: 16, md: 13, lg: 13 }}}
                  size="small" variant="contained" loading={loading} 
                  onClick={validation}>
                  Reset password
                </LoadingButton>
              </Grid>
              
              <Grid className="center" sx={{mt:5}}>
                <Typography className="link_nologin" fontSize={{ xs: "16px", md: "12px", lg: "12px", xl: "12px" }}>
                  Cancel and &nbsp;
                </Typography>
                <Link to="/">
                <Typography className="link_signup" fontSize={{ xs: "16px", md: "12px", lg: "12px", xl: "12px" }}>
                  return to login screen.
                </Typography>
                </Link>
              </Grid>
            </Paper>: <><Paper
              className="paper-normal"
              sx={{ minWidth: { xs: 370, md: 360, lg: 388 }, minHeight: { xs: 441, md: 387, lg: 348 }, boxShadow: "0 30px 30px 0 rgba(40, 40, 40, 0.4)" }}>
                 <Grid className="center">
                <Typography className="header" sx={{ mt: "27px", fontSize: { xs: "24px", md: "22px", lg: "22px" }}}>
                  Password updated
                </Typography>
              </Grid><Grid className="center" sx={{mt:5}}>
                <CheckCircleOutlineIcon sx={{width:"58.33px" , height:"58.33px" , color:"#4282e2"}}/></Grid> <Grid className="center">
                <Typography
                  className="header"
                  sx={{
                    mt: "27px",
                    fontSize: { xs: "18px", md: "14px", lg: "14px" },
                    width: "271px",
                    textAlign: "center",
                  }}
                >
                  Your password has been updated successfully. Click the button below to return to the login screen
                </Typography>
              </Grid><Grid className="center">
                <LoadingButton
                  className="login_button"
                  sx={{ height: { xs: 42, md: 34, lg: 34 }, width: { xs: 180, md: 119, lg: 143 }, fontSize: { xs: 16, md: 13, lg: 13 }}}
                  size="small" variant="contained" loading={loading} 
                  onClick={()=>navigate('/')}>
                  Back to login
                </LoadingButton>
              </Grid></Paper></>}
            {/* Footer starts from here****************************************************************************************************************** */}
            <Grid className="center" sx={{ marginTop: { xs: "123px", md: "41px", lg: "41px" }, marginBottom: { xs: "0px", md: "25px", lg: "25px" } }}>
              <div className="footer"><p>© 2023 Palmtech&nbsp;</p><a href="https://www.palm-tech.com/" rel="noreferrer" target="_blank" className="footer">www.palm-tech.com</a></div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      </>
    }
    </>
  );
};
 export default ResetPassword;
