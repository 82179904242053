import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// mui imports
import { styled, alpha } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import { MenuList } from "@mui/material";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import Divider from "@mui/material/Divider";
import plusIcon from "../../../assets/images/plus_icon.png";

import { NavLink } from "react-router-dom";
import UseInspectionAgreementModal from "../modal/UseInspectionAgreementModal";
import CreateOwnAgreementModal from "../modal/CreateOwnAgreementModal";
import IncludedAgreementModal from "../modal/IncludedAgreementModal";
import { AppContext } from "../../App";
// import { RequestDashboardCount } from "./actions";

import moment from "moment";
import { RequestDashboardCount } from "../../pages/dashboard/actions";
import NoInspection from "../../pages/dashboard/NoInspection";
// custom style imports
// component imports

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("xs")]: {
      width: "0",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const menuItems = ["dashboard", "calendar", "inspections", "agents", "templates", "notifications", "metrics"];
const ptadminMenuItems = [
  { id: "company", name: "Companies" },
  { id: "teams", name: "Team" },
  { id: "template", name: "Templates" },
  { id: "import", name: "Import / Export" },
];

const customerMeunItems = [
  { id: "import", name: "Import" },
  { id: "template", name: "Templates" },
];

const Navbar = (props) => {
  const { companyInfo, setReload, reload } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  // agreement terms modal states
  const [cantCreateInsp, setCantCreateInsp] = useState(false);
  const [showInspAgrmntModal, setShowInspAgrmntModal] = useState(false);
  const [showIncludedAgrmntModal, setShowIncludedAgrmntModal] = useState(false);
  const [showCreateOwnAgrmntModal, setShowCreateOwnAgrmntModal] = useState(false);
  const [summaryCount, setSummaryCount] = useState([]);
  // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null)

  // const [anchorElNav, setAnchorElNav] = React.useState(null)
  // const [anchorElUser, setAnchorElUser] = React.useState(null)
  const pathName = window?.location?.href?.split("#/")[1];
  const rootPath = pathName?.split("/")?.filter((x) => x);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const uuId = sessionStorage.getItem("user_id");
  const roleId = sessionStorage.getItem("role_id");
  const companyId = sessionStorage.getItem("company_id");
  const localDate = new Date();

  const GetSummaryCount = () => {
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      local_date: moment(localDate).format("YYYY-MM-DD HH:mm:ss"),
    };
    RequestDashboardCount((response) => {
      const { data, message, success } = response.data;
      if (success) {
        setSummaryCount(data);
      } else {
        console.log("else ", message);
      }
    }, formdata);
  };

  useEffect(() => {
    GetSummaryCount();
  }, []);

  useEffect(() => {
    if (Object.keys(companyInfo)?.length === 0) {
      setReload(!reload);
    }
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const checkAgreementAcceptStatus = () => {
    if (sessionStorage.getItem("is_trial") === "1" && sessionStorage.getItem("can_create_inspection") !== "true" && sessionStorage.getItem("can_create_inspection") !== "undefined") {
      console.log("here")
      setCantCreateInsp(true);
    } else if (companyInfo.agreement?.agreement_status === 0 || companyInfo.agreement?.agreed_to_terms_for_agreement === 1 || companyInfo?.agreement?.is_default_agreement_deleted === 1) navigate("/schedule-inspection");
    else if (companyInfo.agreement?.agreed_to_terms_for_agreement === 0) {
      setShowInspAgrmntModal(true);
    }
  };
  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null)
  // }

  // Normal user mobile nav items
  const normalMobileMenuNavItems = (
    <Box
      className="display-toggle"
      sx={{
        flexGrow: 1,
        display: { margin: "13px 0px" },
      }}
    >
      <>
        <Button id="basic-button" aria-controls="basic-menu" aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick} className="dropdown-menu">
          <Typography className="nav-inspection-light global-font">{rootPath[0] === "inspections" ? "Inspection" : rootPath[0]}</Typography>
          <KeyboardArrowDownIcon className="nav-down-arrow" />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuList style={{ display: "flex", flexDirection: "column", width: "150px" }}>
            {menuItems.map((menu, index) =>
              menu === "inspection" ? (
                <MenuItem value="" key={index}>
                  <NavLink className="navlink" to={{ pathname: "/inspections" }} style={{ textDecoration: "none" }} onClick={handleClose}>
                    <Typography textAlign="center" className="mobile-menus">
                      {menu}
                    </Typography>
                  </NavLink>
                </MenuItem>
              ) : props.permission ? (
                <MenuItem value="" key={index}>
                  <NavLink className="navlink" to={{ pathname: `/${menu}` }} style={{ textDecoration: "none" }} onClick={handleClose}>
                    <Typography textAlign="center" className="mobile-menus">
                      {menu}
                    </Typography>
                  </NavLink>
                </MenuItem>
              ) : (
                menu !== "agents" &&
                menu !== "templates" && (
                  <MenuItem value="" key={index}>
                    <NavLink className="navlink" to={{ pathname: `/${menu}` }} style={{ textDecoration: "none" }} onClick={handleClose}>
                      <Typography textAlign="center" className="mobile-menus">
                        {menu}
                      </Typography>
                    </NavLink>
                  </MenuItem>
                )
              )
            )}
          </MenuList>
        </Menu>
      </>
    </Box>
  );

  // normal user desktop nav items
  const normalDesktopNavItems = (
    <Box className="horizontal-menus" sx={{ flexGrow: 1, justifyContent: "space-between" }}>
      <div className="is-flex">
        {/* Old button design */}
        {/* <Button
          id="new_inspection"
          name="new_inspection"
          // className="nav-button"
          sx={{ my: 2, color: "white", display: "flex" }}
          // onClick={() => navigate("/schedule-inspection")}
          onClick={checkAgreementAcceptStatus}
        > */}
        {/* <img src={plusIcon} alt="icon" className="navmenu-icon" /> */}
        <Button startIcon={<AddIcon sx={{ height: "18px", width: "18px" }} />} className="new_insp_button" onClick={checkAgreementAcceptStatus}>
          New Inspection
        </Button>
        {/* </Button> */}

        <Divider orientation="vertical" variant="middle" flexItem />
      </div>
      {menuItems.map((menu, index) =>
        menu === "inspection" ? (
          <NavLink className="navlink" key={index} to={{ pathname: `/inspections` }} style={{ textDecoration: "none" }}>
            <Button
              className="nav-button"
              key={menu}
              id={`${menu}_menu`}
              name={`${menu}_menu`}
              // onClick={handleCloseNavMenu}
              sx={{
                my: 2,
                color: "white",
                display: "block",
                margin: "0px",
              }}
            >
              <Typography className={rootPath[0] === `${menu}` || rootPath[0] === "inspections" ? "navlist global-font active-link" : "navlist global-font"}>{menu}</Typography>
            </Button>
          </NavLink>
        ) : props.permission ? (
          <NavLink className="navlink" key={index} to={{ pathname: `/${menu}` }} style={{ textDecoration: "none" }}>
            <Button
              className="nav-button"
              key={menu}
              id={`${menu}_menu`}
              name={`${menu}_menu`}
              // onClick={handleCloseNavMenu}
              sx={{ my: 2, color: "white", display: "block", margin: "0px" }}
            >
              <Typography className={rootPath[0] === `${menu}` ? "navlist global-font active-link" : "navlist global-font"}>{menu}</Typography>
            </Button>
          </NavLink>
        ) : (
          menu !== "agents" &&
          menu !== "templates" && (
            <NavLink className="navlink" key={index} to={{ pathname: `/${menu}` }} style={{ textDecoration: "none" }}>
              <Button
                className="nav-button"
                key={menu}
                id={`${menu}_menu`}
                name={`${menu}_menu`}
                // onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block", margin: "0px" }}
              >
                <Typography className={rootPath[0] === `${menu}` ? "navlist global-font active-link" : "navlist global-font"}>{menu}</Typography>
              </Button>
            </NavLink>
          )
        )
      )}
      <Box className="is-flex revenue" sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
        <Divider orientation="vertical" flexItem variant="middle" />
        <Button className="nav-button" sx={{ my: 2, color: "white", display: "flex" }}>
          <InsertChartIcon className="chart-icon" />
          <Typography className="revenue-content global-font">
            Revenue this&nbsp;month
            <br />
            <span className="revenue-amount">${summaryCount.total_paid}</span>
          </Typography>
        </Button>
      </Box>
    </Box>
  );

  // PT user mobile view nav items
  const PTadminMobileMenuNavItems = (
    <Box
      className="display-toggle"
      sx={{
        flexGrow: 1,
        display: { margin: "13px 0px" },
      }}
    >
      <>
        <Button id="responsive_dropdown" name="responsive_dropdown" aria-controls="basic-menu" aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick} className="dropdown-menu">
          <Typography className="nav-inspection-light global-font"> {rootPath[0] === "inspections" ? "Inspection" : rootPath[0]} </Typography> <KeyboardArrowDownIcon className="nav-down-arrow" />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {ptadminMenuItems.map((menu, index) =>
            props.ptPermission ? (
              <MenuItem value="" key={index}>
                <NavLink className="navlink" to={{ pathname: `/${menu?.id}` }} style={{ textDecoration: "none" }} onClick={handleClose} id={`${menu?.id}_menu`} name={`${menu?.id}_menu`}>
                  <Typography textAlign="center" className="mobile-menus">
                    {menu?.name}
                  </Typography>
                </NavLink>
              </MenuItem>
            ) : (
              menu !== "agents" &&
              menu.id !== "template" && (
                <MenuItem value="" key={index}>
                  <NavLink className="navlink" to={{ pathname: `/${menu?.id}` }} style={{ textDecoration: "none" }} onClick={handleClose} id={`${menu?.id}_menu`} name={`${menu?.id}_menu`}>
                    <Typography textAlign="center" className="mobile-menus">
                      {menu?.name}
                    </Typography>
                  </NavLink>
                </MenuItem>
              )
            )
          )}
        </Menu>
      </>
    </Box>
  );
  const customerNavItems = customerMeunItems?.map((menu, index) => (
    <NavLink className="navlink" key={index} to={{ pathname: `/${menu?.id}` }} style={{ textDecoration: "none", paddingLeft: "60px" }}>
      <Button className="nav-button" key={menu?.id} id={`${menu?.id}_menu`} name={`${menu?.id}_menu`} sx={{ my: 2, color: "white", display: "block", margin: "0px" }}>
        <Typography className={rootPath[0] === `${menu?.id}` ? "navlist global-font active-link" : "navlist global-font"}>
          {/* name changes "teams"->"team" and "company"->"companies" */}
          {menu?.name}
        </Typography>
      </Button>
    </NavLink>
  ));
  // PT user desktop nav items
  const PTadminDesktopNavItems = (
    <Box
      className="horizontal-menus"
      sx={{
        flexGrow: 1,
        justifyContent: "space-between",
      }}
    >
      <div className="is-flex">
        <Button
          className="nav-button"
          id="add_new_company"
          name="add_new_company"
          sx={{ my: 2, color: "white", display: "flex" }}
          // onClick={() => navigate("/schedule-inspection")}
        >
          <img src={plusIcon} alt="icon" className="navmenu-icon" />
          <Typography className="nav-inspection global-font">Add New Company</Typography>
        </Button>

        <Divider orientation="vertical" variant="middle" flexItem style={{ marginRight: "20px" }} />
        {ptadminMenuItems.map((menu, index) =>
          props.ptPermission ? (
            <NavLink className="navlink pt_nav_spacing" key={index} to={{ pathname: `/${menu?.id}` }} style={{ textDecoration: "none" }}>
              <Button className="nav-button" key={menu?.id} id={`${menu?.id}_menu`} name={`${menu?.id}_menu`} sx={{ my: 2, color: "white", display: "block", margin: "0px" }}>
                <Typography className={rootPath[0] === `${menu?.id}` ? "navlist global-font active-link" : "navlist global-font"}>
                  {/* name changes "teams"->"team" and "company"->"companies" */}
                  {menu?.name}
                </Typography>
              </Button>
            </NavLink>
          ) : (
            menu?.id !== "agents" &&
            menu.id !== "template" &&
            menu.id !== "import" && (
              <NavLink className="navlink pt_nav_spacing" key={index} to={{ pathname: `/${menu?.id}` }} style={{ textDecoration: "none" }}>
                <Button
                  className="nav-button"
                  key={menu?.id}
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    margin: "0px",
                  }}
                  id={`${menu?.id}_menu`}
                  name={`${menu?.id}_menu`}
                >
                  <Typography className={rootPath[0] === `${menu?.id}` ? "navlist global-font active-link" : "navlist global-font"}>{menu?.name}</Typography>
                </Button>
              </NavLink>
            )
          )
        )}
      </div>

      <Box className="is-flex revenue" sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
        {/* <Divider orientation="vertical" flexItem variant="middle" /> */}
        {/* <Button
          className="nav-button"
          sx={{ my: 2, color: "white", display: "flex" }}
          id="revenue_btn"
          name="revenue_btn"
        >
          <InsertChartIcon className="chart-icon" />
          <Typography className="revenue-content global-font">
            Revenue this&nbsp;month
            <br />
            <span className="revenue-amount">$2500</span>
          </Typography>
        </Button> */}
      </Box>
    </Box>
  );

  return (
    <div>
      <NoInspection showNoInspModal={cantCreateInsp} setShowOnInspModal={setCantCreateInsp} />
      <UseInspectionAgreementModal showInspAgrmntModal={showInspAgrmntModal} setShowInspAgrmntModal={setShowInspAgrmntModal} setShowIncludedAgrmntModal={setShowIncludedAgrmntModal} />
      <IncludedAgreementModal showIncludedAgrmntModal={showIncludedAgrmntModal} setShowIncludedAgrmntModal={setShowIncludedAgrmntModal} setShowInspAgrmntModal={setShowInspAgrmntModal} setShowCreateOwnAgrmntModal={setShowCreateOwnAgrmntModal} />
      <CreateOwnAgreementModal showCreateOwnAgrmntModal={showCreateOwnAgrmntModal} setShowCreateOwnAgrmntModal={setShowCreateOwnAgrmntModal} />

      <Box sx={{ flexGrow: 1 }}>
        <AppBar className="navitems" position="static">
          <Toolbar>
            {/* menu dropdown for mobile */}
            {props.PTadminFlag ? PTadminMobileMenuNavItems : normalMobileMenuNavItems}
            {/* desktop full nav */}
            {props.PTadminFlag ? PTadminDesktopNavItems : props?.customerPermission ? customerNavItems : normalDesktopNavItems}
            {/* search for mobile */}
            <Box className="display-toggle">
              <Search className="header-search-bar-light">
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase placeholder="Search…" inputProps={{ "aria-label": "search" }} />
              </Search>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};
export default Navbar;
