export const defaultTemplateValue = `
<p style="text-align:center;font-size:16px">Home Inspection Agreement</p>

<p>This is Agreement is made between you, the undersigned Client, and us, {{Company Name}}, pertaining to our inspection of the Property at: {{Property Address}}. Your inspection date and time is: {{Inspection Date}} {{Inspection Time}}</p>

<p>The terms below govern this Agreement.</p>

<p>1. The fee for our inspection is {{Inspection Amount Due}}, payable in full before or at the time of the appointment. The inspection fee is based on a single visit to the property and is required to be paid in full before the commencement of the above Inspection Appointment Date/Time. We accept cash, check, credit cards, PayPal, and bank transfers as methods of payment.</p>

<p>2. Services. Inspector will conduct a visual inspection of the Property only. Unless your jurisdiction has not adopted mandatory standards, the inspection is performed in accordance with your jurisdiction&rsquo;s Standards of Practice (SOP). You understand that the SOP contains limitations, exceptions, and exclusions.</p>

<p>3. Exclusions and Limitations. Client expressly acknowledges and agrees that the following matters are not included in the scope of the inspection and the inspection report and further acknowledges that Inspector makes no representations and offers no opinion as to: <br>
 a. The Property&rsquo;s compliance with municipal, county, state or federal statute codes, rules, ordinance rules, or regulations including without limitation, building, zoning or property maintenance codes.</p>

<p> b. Unless otherwise indicated in writing, we do not conduct geological tests, nor do we test for fungus, including but not limited to mold and mildew, or for pests such as wood destroying organisms, insects, rodents.</p>

<p> c. Unless otherwise indicated in writing, we will not test for compliance with applicable building codes or for the presence of or for any potential dangers arising from the presence of asbestos, lead paint, soil contamination, radon, or other environmental hazards or violations.</p>

<p> d. Inspector will not inspect inaccessible or concealed areas of the Property and will not enter dangerous areas of the Property.</p>

<p> e. The following items are specifically excluded from the inspection: water softening systems, security systems, telephone and cable TV cables, timing systems, swimming pools and spas, underground or concealed pipes, sewer lines, septic systems, electrical lines and circuits, central vacuum systems, central air conditioning when outside temperature is below 65&ordm; F or 18&ordm; C, and any other condition, item, system or component which by the nature of their location are concealed or otherwise difficult to inspect or which the<br>
 Inspector cannot visually examine.</p>

<p> f. Additionally excluded are 1) assurances of a dry basement or crawl space, and 2) assurances that double and triple pane glazing seals in windows are intact. Inspector will not dismantle any component or system; full evaluation of the integrity of a heat exchanger requires dismantling of the furnace and is beyond the scope of a visual inspection.</p>

<p> g. If any structure you want us to inspect is a log structure or includes log construction, you understand that such structures have unique characteristics that may make it impossible for us to inspect and evaluate them. Therefore, the scope of our inspection will not include decay of the interior of logs in log walls, log foundations or roofs, or similar defects.</p>

<p> h. The Inspector does not offer an opinion as to the advisability or inadvisability of the purchase of the property, its value or its potential use.</p>

<p>4. Report. Client will receive an inspection report detailing the Inspector's findings and observations of the accessible features of the Property within 48 hours of the inspection:<br>
 a. Subject to the terms and conditions stated herein, the inspection includes the non-invasive visual examination of the home's exterior including roof and chimney, structure, electrical, heating and cooling systems, insulation, plumbing, and interior including floors, walls, ceiling and windows; it is a reasonable effort to disclose the condition of the house based on a visual inspection. The condition of certain systems, components and equipment will be randomly sampled by the Inspector. Examples include, but may not be restricted to, window/door operation, electrical receptacles, switches and lights, cabinets, mortar, masonry, paint and caulking integrity, roof covering materials, and examination of interior and exterior surfaces for signs of moisture ingress.</p>

<p> b. Unless expressly stated in an addendum to this contract, this contract does not include an inspection of any out-buildings or structures not attached to the dwelling other than a garage or carport. Conditions beyond the scope of the inspection will not be identified.</p>

<p> c. This Inspection Report is based on the condition of the Property existing and apparent as of the time and date of the inspection. Not all conditions may be apparent on the inspection date due to weather conditions, inoperable systems, inaccessibility of areas of the Property, etc. Without dismantling the house or its systems, there are limitations to the inspection. Throughout any inspection, inferences are drawn which cannot be confirmed by direct observation. Clues and symptoms often do not reveal the extent or severity of problems. Therefore, the inspection and subsequent Inspection Report may help reduce the risk of purchasing the property; however, an inspection does not eliminate such risk nor does the Inspector assume such risk. While some of the less important deficiencies are addressed, an all-inclusive list of minor building flaws is not provided. Inspector is neither responsible nor liable for the non-discovery of any latent defects in materials, workmanship, or other conditions of the Property which may occur or may become evident after the inspection time and date. Inspector is neither an insurer nor guarantor against defects in the building and improvements, systems or components inspected.</p>

<p> d. The inspection report is provided solely for the benefit of the Client. Client shall not rely on any other inspection report prepared at any time by the Inspector that is not prepared for or addressed to the Client.</p>

<p>5. Release; Warranty. We are not responsible for use or misinterpretation by third parties, and third parties who rely on it in any way do so at their own risk and release us (including employees and business entities) from any liability whatsoever. If you or any person acting on your behalf provide the report to a third party who then sues you and/or us, you release us from any liability and agree to pay our costs and legal fees in defending any action naming us. Our inspection and report are in no way a guarantee or warranty, express or implied, regarding the future use, operability, habitability or suitability of the home/building or its components. We disclaim all warranties, express or implied, to the fullest extent allowed by law.</p>

<p>6. Other Services. We do not perform engineering, architectural, plumbing, or any other job function requiring an occupational license in the jurisdiction where the property is located. If we hold a valid occupational license, we may inform you of this and you may hire us to perform additional functions. Any agreement for such additional services shall be in a separate writing.</p>

<p>7. Limitation of Liability; Damages. We assume no liability for the cost of repair or replacement of unreported defects, either current or arising in the future, or for the loss of the use of the Property or any consequential damages. If Inspector or any of its employees are found to be liable in a court of competent jurisdiction for any claim or damage due to the alleged negligence or willful misconduct of the Inspector performing the home inspection or in reporting on the condition of the property in the inspection report, Inspector&rsquo;s liability is limited to (1.5x) times the fee you paid us. You acknowledge that the limitation on damages herein (i) reflects the fact that actual damages may be difficult or impractical to ascertain; (ii) allocates risk between us; and (iii) enables us to perform the inspection for the agreed-upon fee.</p>

<p>8. Dispute Resolution. Before Client initiates a lawsuit or other legal proceeding against Inspector relating to this Agreement, Client agree to work in good faith with the Inspector to resolve all disputes and claims arising out of or relating to this Agreement, the Parties' performance under it or a breach of this Agreement. Client must commence any action or claim relating to or arising from this Agreement within one year of delivery of the Inspection Report.</p>

<p>9. Data collection. Inspector may collect data from the inspection, primarily consisting of data relating to the visual inspection conducted, but may also consist of other data relating to the property inspected, client and/or client representative personal and contact information, and demographic data. The inspector may use collected data to perform analysis, improve business processes, improve the inspection experience, and obtain feedback from clients and client representatives. The inspector may also provide collected data to third-party service providers (&quot;TPSP&quot;) in order to offer value-added services to clients, as described in this agreement. The inspector may provide aggregated collected data, but not individual collected data or personal information, to third parties. Other than interaction with TPSPs and aggregated data, the inspector will not sell or rent the collected data to anyone, or share the collected data with any third party except as necessary to fulfill client requests. By executing this agreement, the client expressly consents to the collection and use of data by the inspector as described herein.</p>

<p>10. Miscellaneous. This Agreement and the documents referred to herein constitute the entire Agreement between the parties hereto, and supersede any and all prior representations, discussions, or agreements, whether written or oral. Any modification of this Agreement must be in writing and signed by you and by one of our authorized officers. You agree that the exclusive venue for any litigation arising out of this Agreement shall be in the county where we have our principal place of business. If you fail to prove any claim against us, you agree to pay all our legal costs, expenses and attorney&rsquo;s fees incurred in defending that claim. If a court declares any provision of this Agreement invalid, the remaining provisions remain in effect. You may not assign this Agreement. Past-due fees for your inspection shall accrue interest at 8% per year. You agree to pay all costs and attorney&rsquo;s fees we incur in collecting the fees owed to us. If Client is a corporation, LLC, or similar entity, you personally guarantee payment of the fee.</p>

<p>
BY SIGNING THIS HOME INSPECTION AGREEMENT, I ACKNOWLEDGE THAT I HAVE CAREFULLY READ, UNDERSTAND AND AGREE TO ALL OF THE FOLLOWING PROVISIONS SET FORTH HEREIN THIS CONTRACT.</p>
`

export const userAgreement =`
<p style="font-size: 16px; text-align: center;">User Agreement<br></p><p style="font-size: 15px;">This is a placeholder document that you will use to insert your inspection agreement language. You should replace all of this text in this document with your inspection agreement language.<br></p><p style="font-size: 15px;">Use merge fields like:{{Client Name}} in your text to automatically insert details from the inspection appointment into your agreement. You will see a list of available merge fields when editing the agreement. Just drag and drop them into your text where appropriate.</p><p style="font-size: 15px;"><strong>If you would like help getting your inspection agreement set up, our customer service team is ready to help. Call us at 888-736-2462 or contact us at:<a href="https://support.palmtech.com" alt="https://support.palmtech.com" class="on">https://support.palmtech.com</a></strong></p>
`