import moment from "moment";
import { useState } from "react";
import { Box } from "@mui/system";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import config_settings from "../../config/config";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import Heading from "../../common/cardHeading/Heading";
import FormControlLabel from "@mui/material/FormControlLabel";
import { apiCall } from "../../pages/settings/Services/action";
import { circularProgressClasses } from "@mui/material/CircularProgress";
import { Button, CircularProgress, Grid, Paper, Typography, Modal } from "@mui/material";

let IR_URL;
let origin = window.location.origin.toLowerCase();
if (origin.includes("https://")) origin = origin.replace("https://", "");
else origin = origin.replace("http://", "");
IR_URL = config_settings[origin].IR_URL;
console.log("IR_URL", IR_URL);
// return origin;

const Report = (props) => {
  const handleOpen = () => setOpen(true);
  const [mapaid, setMapaid] = useState();
  const [open, setOpen] = useState(false);
  const [masigned, setMasigned] = useState();
  const [loading, setLoading] = useState(false);
  const [confirmSendReport, setConfirmSendReport] = useState(false);
  const handleChange = (event) => setStatus(event.target.value);
  const handleMAPaid = (event) => setMapaid(event.target.checked);
  const handleMASigned = (event) => setMasigned(event.target.checked);
  const { guid, reportData, statusValue, refresh, setRefresh, companyPaymentStatus } = props;
  const [status, setStatus] = useState(statusValue.status === "Draft" || statusValue.status === "Scheduled" || statusValue.status === "In-Progress" || statusValue.status === "Draft" || statusValue.status === "Cancelled" ? "Incomplete" : "Complete");
  const handleClose = () => {
    setStatus("Incomplete");
    setOpen(false);
  };

  //porch pdf gen api call
  const PorchApiChange = (status) => {
    const formdata = {
      user_id: sessionStorage.getItem("user_id"),
      company_id: sessionStorage.getItem("company_id"),
      status: status,
      guid: guid,
    };
    apiCall(
      (response) => {
        if (response.data.success) {
          console.log(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      },
      formdata,
      "PORCH_PDF_GEN"
    );
  };

  //change status api call
  const changeStatus = () => {
    setLoading(true);
    const formdata = {
      guid: guid,
      mark_as_paid: companyPaymentStatus?.is_payment_enable === 2 ? (statusValue.paid === 1 ? "1" : !mapaid ? "0" : "1") : "0",
      // mark_as_paid: props.companyPaymentStatus?.is_payment_enable === 2 && !mapaid ? "0" : "1",
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      // mark_agreement_signed: masigned ? "1" : "0",
      mark_agreement_signed: statusValue.agreementsigned ? "1" : masigned ? "1" : "0",
      company_id: sessionStorage.getItem("company_id"),
      status: mapaid && masigned ? "Published" : companyPaymentStatus?.is_payment_enable === 2 && companyPaymentStatus?.is_agreement_enable === 1 ? (statusValue.paid === 1 && statusValue.agreementLength === 0 ? "Published" : statusValue.paid === 1 && statusValue.agreementsigned === 1 ? "Published" : mapaid && statusValue.agreementLength === 0 ? "Published" : mapaid && masigned ? "Published" : "Complete") : companyPaymentStatus?.is_payment_enable === 0 && companyPaymentStatus?.is_agreement_enable === 0 ? "Published" : companyPaymentStatus?.is_payment_enable === 2 && statusValue.paid ? "Published" : companyPaymentStatus?.is_payment_enable === 2 && mapaid ? "Published" : companyPaymentStatus?.is_agreement_enable === 1 && statusValue.agreementLength === 0 ? "Published" : companyPaymentStatus?.is_agreement_enable === 1 && statusValue.agreementsigned === 1 ? "Published" : companyPaymentStatus?.is_agreement_enable === 1 && masigned ? "Published" : "Complete",
      status_date: moment.utc(new Date().toUTCString()).format("YYYY-MM-DD HH:mm:ss"),
      // status: mapaid && masigned ? "Published" : statusValue.paid === 1 && statusValue.agreementsigned === 1 ? "Published" : "Complete",
    };
    apiCall(
      (response) => {
        if (response.data.success) {
          setMapaid();
          setMasigned();
          setLoading(false);
          PorchApiChange(status);
          setRefresh(!refresh);
          setOpen(false);
          toast.success(response.data.message);
        } else {
          setLoading(false);
          toast.error(response.data.message);
        }
      },
      formdata,
      "CHANGE_ORDER_STATUS"
    );
  };

  //send report api call
  const sendReport = () => {
    setLoading(true);
    const formdata = {
      guid: guid,
      status: "published",
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      company_id: sessionStorage.getItem("company_id"),
      status_date: moment.utc(new Date().toUTCString()).format("YYYY-MM-DD"),
    };
    apiCall(
      (response) => {
        if (response.data.success) {
          setLoading(false);
          PorchApiChange("published");
          setRefresh(!refresh);
          setConfirmSendReport(false);
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
          setLoading(false);
        }
      },
      formdata,
      "SEND_INSPECTION_REPORT_V1"
    );
  };

  //%
  const percentage = eval(((reportData?.completed_line_input_controls - reportData?.incomplete_line_input_controls) / reportData.completed_line_input_controls) * 100)?.toFixed(2);

  //render
  return (
    <>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "630px !important", minHeight: "231px !important", top: "40% !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={handleClose} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography sx={{ color: "#333333", fontFamily: "open sans", fontSize: "22px", mt: "15px", letterSpacing: 0 }}>Mark this inspection as complete?</Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
              <Typography sx={{ color: "#333333", maxWidth: "512px", fontFamily: "open sans", fontSize: "14px", mt: "15px", letterSpacing: 0 }}>
                {companyPaymentStatus?.is_payment_enable === 2 && companyPaymentStatus?.is_agreement_enable === 1
                  ? statusValue.paid !== 0 && (statusValue.agreementsigned !== 0 || statusValue.agreementLength === 0)
                    ? "This action cannot be undone. This action will send a link containing the report to the client's email based on your"
                    : statusValue.paid === 1 && statusValue.agreementsigned === 0
                    ? "This inspection has an unsigned agreement. Marking the inspection complete will trigger automated communications to your client based on your"
                    : statusValue.paid === 0 && (statusValue.agreementsigned === 1 || statusValue.agreementLength === 0)
                    ? "This inspection is unpaid. Marking the inspection complete will trigger automated communications to your client based on your"
                    : "This inspection is unpaid, and has an unsigned agreement. Marking the inspection complete will trigger automated communications to your client based on your"
                  : companyPaymentStatus?.is_payment_enable !== 2 && companyPaymentStatus?.is_agreement_enable === 1
                  ? statusValue.agreementLength !== 0 && statusValue.agreementsigned === 0
                    ? "This inspection has an unsigned agreement. Marking the inspection complete will trigger automated communications to your client based on your"
                    : statusValue.agreementsigned === 1
                    ? "This action cannot be undone. This action will send a link containing the report to the client's email based on your"
                    : "This action cannot be undone. This action will send a link containing the report to the client's email based on your"
                  : companyPaymentStatus?.is_payment_enable === 2 && companyPaymentStatus?.is_agreement_enable === 0
                  ? statusValue.paid === 0
                    ? "This inspection is unpaid. Marking the inspection complete will trigger automated communications to your client based on your"
                    : statusValue.paid === 1
                    ? "This action cannot be undone. This action will send a link containing the report to the client's email based on your"
                    : ""
                  : "This action cannot be undone. This action will send a link containing the report to the client's email based on your"}
                <a href={`${window.location.origin}/#/notifications`} style={{ color: "#4282e2", fontWeight: 600, textDecoration: "underline", cursor: "pointer" }}>
                  {" "}
                  notification settings.
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mt: "30px" }}>
              <FormGroup sx={{ maxWidth: "425px", paddingLeft: { xs: "0px", md: "30px", lg: "30px" } }}>
                {companyPaymentStatus.is_payment_enable === 2 && statusValue.paid === 0 ? <FormControlLabel control={<Checkbox color="default" value={mapaid} onChange={handleMAPaid} />} label={<Typography sx={{ userSelect: "none", fontSize: "14px", fontWeight: "600", lineHeight: "20px", letterSpacing: "0px", color: "#3b3b3b" }}>Also waive the inspection payment (no more payment reminders will be sent to the client).</Typography>} /> : ""}
                {companyPaymentStatus.is_agreement_enable === 1 && statusValue.agreementLength !== 0 && statusValue.agreementsigned === 0 ? <FormControlLabel control={<Checkbox color="default" value={masigned} onChange={handleMASigned} />} label={<Typography sx={{ userSelect: "none", fontSize: "14px", fontWeight: "600", lineHeight: "20px", letterSpacing: "0px", color: "#3b3b3b", mt: "21px" }}>Also waive the agreement signature (no more agreement reminders will be sent to the client).</Typography>} /> : ""}
              </FormGroup>
            </Grid>
            <Grid container sx={{ display: "flex", justifyContent: "center", mt: 5, paddingRight: "20px" }}>
              <LoadingButton loading={loading} id="mark_as_complete_button" variant="contained" className="inspection-filter-modal-buttons-apply" sx={{ width: "178px", ml: 3, borderRadius: "2px !important", textTransform: "none !important", fontWeight: "600" }} onClick={changeStatus}>
                Mark as complete
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={confirmSendReport} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "630px !important", minHeight: "231px !important", top: "40% !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={() => setConfirmSendReport(false)} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography sx={{ color: "#333333", fontFamily: "open sans", fontSize: "22px", mt: "15px", letterSpacing: 0 }}>Send and publish report?</Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
              <Typography sx={{ color: "#333333", maxWidth: "512px", fontFamily: "open sans", fontSize: "14px", mt: "15px", letterSpacing: 0 }}>
                {companyPaymentStatus?.is_payment_enable === 2 && companyPaymentStatus?.is_agreement_enable === 1
                  ? statusValue.paid !== 0 && statusValue.agreementsigned !== 0
                    ? ""
                    : statusValue.paid === 0 && statusValue.agreementsigned === 0
                    ? "This inspection is unpaid and has an unsigned agreement.  Sending the report will waive the payment and agreement.  No further reminders will be sent to the client."
                    : statusValue.paid === 0 && statusValue.agreementsigned === 1
                    ? "This inspection is unpaid.  Sending the report will waive the payment.  No further reminders will be sent to the client."
                    : statusValue.paid === 1 && statusValue.agreementsigned === 0
                    ? "This inspection has an unsigned agreement.  Sending the report will waive the agreement.  No further reminders will be sent to the client."
                    : statusValue.paid === 2 && statusValue.agreementsigned === 0
                    ? "This inspection has an unsigned agreement.  Sending the report will waive the agreement.  No further reminders will be sent to the client."
                    : statusValue.paid === 0 && statusValue.agreementsigned === 2
                    ? "This inspection is unpaid.  Sending the report will waive the payment.  No further reminders will be sent to the client."
                    : ""
                  : companyPaymentStatus?.is_payment_enable !== 2 && companyPaymentStatus?.is_agreement_enable === 1
                  ? statusValue.agreementsigned === 0 || statusValue.agreementsigned === 2
                    ? "This inspection has an unsigned agreement.  Sending the report will waive the agreement.  No further reminders will be sent to the client."
                    : statusValue.agreementsigned === 1
                    ? ""
                    : ""
                  : companyPaymentStatus?.is_payment_enable === 2 && companyPaymentStatus?.is_agreement_enable === 0
                  ? statusValue.paid === 2 || statusValue.paid === 0
                    ? "This inspection is unpaid.  Sending the report will waive the payment.  No further reminders will be sent to the client."
                    : statusValue.paid === 1
                    ? ""
                    : ""
                  : ""}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mt: "30px" }}>
              <FormGroup sx={{ maxWidth: "425px", paddingLeft: { xs: "0px", md: "30px", lg: "30px" } }}>
                {companyPaymentStatus.is_payment_enable === 2 && statusValue.paid === 0 ? <FormControlLabel control={<Checkbox color="default" value={mapaid} onChange={handleMAPaid} />} label={<Typography sx={{ userSelect: "none", fontSize: "14px", fontWeight: "600", lineHeight: "20px", letterSpacing: "0px", color: "#3b3b3b" }}>Also waive the inspection payment (no more payment reminders will be sent to the client).</Typography>} /> : ""}
                {companyPaymentStatus.is_agreement_enable === 1 && statusValue.agreementsigned === 0 ? <FormControlLabel control={<Checkbox color="default" value={masigned} onChange={handleMASigned} />} label={<Typography sx={{ userSelect: "none", fontSize: "14px", fontWeight: "600", lineHeight: "20px", letterSpacing: "0px", color: "#3b3b3b", mt: "21px" }}>Also waive the agreement signature (no more agreement reminders will be sent to the client).</Typography>} /> : ""}
              </FormGroup>
            </Grid> */}
            <Grid container sx={{ display: "flex", justifyContent: "center", mt: 5, paddingRight: "20px" }}>
              <LoadingButton id={"send_and_publish_button"} loading={loading} variant="contained" className="inspection-filter-modal-buttons-apply" sx={{ width: "178px", ml: 3, borderRadius: "2px !important", textTransform: "none !important", fontWeight: "600" }} onClick={sendReport}>
                Send and publish
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Box sx={{ flexGrow: 1, mt: 3 }}>
        <Grid container>
          <Paper elevation={0} sx={{ minHeight: "169px", border: "1px solid #E7E7E9", width: "100%" }}>
            <Heading title={"Report"} icon={""} iconDesc={""} />
            {/* mapping function for the contact screen (CLIENT)******************** */}
            <Grid container spacing={0} sx={{ p: "25px" }}>
              {/* mapping function for the contact screen (BUYER)******************** */}
              <Grid item xs={4}>
                <CircularProgress
                  size="90px"
                  thickness={3}
                  value={percentage}
                  variant="determinate"
                  sx={{
                    color: (theme) => (theme.palette.mode === "light" ? "#757575" : "#308fe8"),
                    animationDuration: "550ms",
                    left: 0,
                    [`& .${circularProgressClasses.circle}`]: {
                      strokeLinecap: "round",
                    },
                  }}
                />
                <br />
                <Typography sx={{ fontSize: "14px", mt: 1 }}>{isNaN(percentage) ? "Percentage not available." : `${percentage}% filled out`}</Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl sx={{ minWidth: 130, borderRadius: 0 }} size="small">
                  <p style={{ fontSize: "12px", color: "#888888" }}>Completion status</p>
                  <Select disabled={statusValue.status === "Cancelled" || statusValue.status === "Complete" || statusValue.status === "Published" || statusValue.status === "Scheduled" || statusValue.status === "Draft" ? true : false} labelId="demo-select-small" id="demo-select-small" sx={{ mt: 1, borderRadius: 0, fontWeight: "bold" }} value={status} onChange={handleChange}>
                    <MenuItem value={"Incomplete"} style={{ fontWeight: "bold" }}>
                      INCOMPLETE
                    </MenuItem>
                    <MenuItem value={"Complete"} style={{ fontWeight: "bold" }} onClick={handleOpen}>
                      COMPLETE
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  disabled={statusValue.status !== "Complete" && statusValue.status !== "Published"}
                  className="login_button"
                  id="send_reoprt_button"
                  sx={{ height: { xs: 42, md: 34, lg: 34 }, display: props.CancelledStatus && "none", width: { xs: 180, md: 153, lg: 153 }, fontSize: { xs: 16, md: 13, lg: 13 }, mt: "20px !important", color: "#fff" }}
                  onClick={() => {
                    companyPaymentStatus?.is_payment_enable === 0 && companyPaymentStatus?.is_agreement_enable === 0 ? sendReport() : statusValue.status === "Complete" && (statusValue.agreementsigned !== 1 || statusValue.paid !== 1) ? setConfirmSendReport(true) : sendReport();
                  }}
                >
                  Send report
                </LoadingButton>
              </Grid>
              <Grid item xs={12}>
                <Button id="view_reoprt_button" disabled={statusValue.status === "Draft" || statusValue.status === "Scheduled"} className="login_button" onClick={() => window.open(`${IR_URL}?guid=${guid}`, "_blank")} sx={{ height: { xs: 42, md: 34, lg: 34 }, display: props.CancelledStatus && "none", width: { xs: 180, md: 153, lg: 153 }, fontSize: { xs: 16, md: 13, lg: 13 }, mt: "20px !important", color: "#fff" }}>
                  View report
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Box>
    </>
  );
};

export default Report;
