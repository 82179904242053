import { Button, FormControl, Grid, TextField, Typography, useMediaQuery } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from "@mui/icons-material/Close";
import React from 'react'
import { useNavigate } from 'react-router-dom';
import '../../../assets/styles/containers/_importTemplate.scss'
import { useState } from 'react';
import { styled } from '@mui/material/styles';

  import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { apiCall } from '../../utils/action';
import { toast } from 'react-hot-toast';
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 25,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#0EAD7C' : '#308fe8',
    },
  }));

const ImportTemplate = () => {
    const isTaborMobile = useMediaQuery("(min-width:900px)");
    let navigate = useNavigate();
    // session datas
    const companyId = sessionStorage.getItem("company_id");
    const roleId = sessionStorage.getItem("role_id");
    const uuId = sessionStorage.getItem("user_id");

    const [legacyName,setLegacyName] = useState('');
    const [legacyPassword,setLegacyPassword] = useState('');
    const [templateImporting,setTemplateImporting] = useState(false);
    const [templateImported,setTemplateImported] = useState(false);
    const[progresPercent,setProgressPercent] = useState(0)

    const importStatus=()=>{
      const formdata = { company_id: companyId, user_id: uuId, role_id: roleId};
      apiCall(
        (response) => {
          const { data, message, success } = response.data;
          if (success) {
            setProgressPercent(data.progress_count)
            // toast.success(message);
           setTemplateImporting(false)
           setTemplateImported(true)
            // setShowLoader(false);
          } else {
            console.log("else ", message);
          }
        },
        formdata,
        "V9_IMPORT_STATUS"
      );
    }

    const importHandle = () => {
      // setShowLoader(true);
      setTemplateImporting(true)
      const formdata = { company_id: companyId, user_id: uuId, role_id: roleId,user_name:legacyName,user_password:legacyPassword };
      apiCall(
        (response) => {
          const { data, message, success } = response.data;
          if (success) {
            importStatus()
            
            // toast.success(message);
           console.log("imp status",data)
            // setShowLoader(false);
          } else {
            toast.error(message);
            setTemplateImporting(false)
            console.log("else ", message);
          }
        },
        formdata,
        "IMPORT_V9_TEMPLATE"
      );
    };

  return (
    
        <Grid
          container
          className={
            isTaborMobile
              ? "schedule-inspection-container"
              : "schedule-inspection-container-light temp-light"
          }
        >
            <Grid item xs="none" md={3}></Grid>
          <Grid item xs={12} md={6} className="modal-container">
            <Typography align="right" className="close-icon">
              <CloseIcon
                onClick={() => navigate("/settings/import")
                }
              />
            </Typography>

            <div  className={isTaborMobile
              ? "import-template-container"
              : "import-template-container-light"
          }>
            <Typography className='import_template_title'>{templateImporting?"Version 9 import in progress" : templateImported?" Version 9 Import Successful":'Import V9 to Palmtech'}</Typography>
            {templateImporting?
            <div className='import_template_content p-70'>
                {/* <BorderLinearProgress variant="determinate" value={progresPercent} /> */}
                <BorderLinearProgress />
            </div>
            
            :
            templateImported?
            <div className='imported_template_content'>
             
            <div className='imported-icon'>
                <CheckCircleOutlineIcon/>
            </div>
            <Typography className='import_success_message'>
              Your version 9 data has been imported. Your templates, descriptions  and  comments will be now available to use when you start new inspections. 
              </Typography>
            <div className='legacy_modal_action success-modal'>
                <Button variant="contained"
                className="redirect-btn" onClick={()=>navigate("/dashboard")}>
                    Home
                </Button>
                <Button onClick={()=>navigate("/templates")}
                    variant="contained"
                    className="redirect-btn">
                        Edit my templates
                </Button>
            </div>     
            </div>
            :
            <div className='import_template_content'>
            <FormControl variant="standard" className="forms-control">
              <Typography className='subtitle1'>
              You will need to export your data from version 9 before you can do run this operation. 
              </Typography>
              <br/>
              <Typography className='subtitle2'>
                <span>If you have already exported your version 9 data:</span><br/>
                Enter your user name and password from version 9 (shown to you at the end of the export).
              </Typography>
              <Typography className='subtitle3'>
                <span>If you have not exported your templates in version 9:</span>
                <br/>
                <ol>
                  <li>
                  Export your V9 data first. If you need help, click here &nbsp;  
                <a href="https://support.palmtech.com/hc/en-us/articles/12100747044372" rel="noreferrer" target="_blank" >www.palmtech.com</a>.
                <br/>
                  </li>
                  <li>
                  Enter your user name and password from Version 9 (shown to you at the end of the export).
                  </li>
                </ol>
               
              </Typography>
            <label
                className="input-form-label"
                // error
                id="legacy_user"
            >
                User name
            </label>
            <TextField
                id="legacy_user"
                className="input-textfield"
                size="small"
                variant="outlined"                
                type="text"
                onChange={(e)=>setLegacyName(e.target.value)}
                value={legacyName}
                name="legacyUser"
                // {...field}
                error={false}
                helperText={''}
            />
            </FormControl>
            <FormControl variant="standard" className="forms-control">
            <label
                className="input-form-label"
                // error
                id="legacy_password"
            >
                Password
            </label>
            <TextField
                id="legacy_password"
                className="input-textfield"
                size="small"
                variant="outlined"                
                type="password"
                onChange={(e)=>setLegacyPassword(e.target.value)}
                value={legacyPassword}
                name="legacyPassword"
                // {...field}
                error={false}
                helperText={''}
            />
            </FormControl>
            
            <div className='legacy_modal_action'>
                <Button
                variant="contained"
                className="default-btn"
                onClick={importHandle}
                >
                    Import
            </Button>
            </div>            
        </div>
            }
           
           
            </div>
            </Grid>
            <Grid item xs="none" md={3}></Grid>
        </Grid>
   
  )
}

export default ImportTemplate