import React, { useState } from "react";
import { FormControl, Grid, TextField, Typography } from "@mui/material";
import Input from "@mui/material/Input";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import AccountCircle from "@mui/icons-material/AccountCircle";
import uuid from "react-uuid";
import { useMediaQuery } from "react-responsive";

const NewCategory = (props) => {
  const { newList, AddSelectedCat, handleChange, checked } = props;

  const [filteredList, setFilteredList] = useState(newList);
  const [searchTerm, setSearchTerm] = useState("");
  const isSmallScreen = useMediaQuery({ query: "(max-width: 700px)" });
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  // If the search term is not empty, we filter the items based on the search term.
  let filteredItems;
  if(searchTerm !== ""){
    filteredItems = newList.filter((item) =>
    item?.prompt?.toLowerCase()?.includes(searchTerm?.toLowerCase()));
  }
  else filteredItems = newList
 
  

  return (
    <>
      <Input
        sx={{
          m: "25px 5% 25px 5%",
          p: "10px",
          border: "1px solid #E7E7E9",
          width: "90%",
        }}
        spacing={2}
        fullWidth
        disableUnderline
        placeholder="Search"
        // value={searchQuery}
        onChange={handleSearch}
        // id="input-with-icon-adornment"
        startAdornment={
          <InputAdornment position="start">
            <SearchOutlinedIcon sx={{ color: "#BEBEBE" }} />
          </InputAdornment>
        }
      />
      <Grid
        container
        style={{ paddingTop: "34px" }}
        spacing={2}
        className={`${filteredItems?.length>5?'inspection-filter-modal-marginsout':'inspection-filter-modal-marginsout colFlex'} `}
      >
        {filteredItems &&
          filteredItems.length > 0 &&
          filteredItems?.map((category, index) => {
            // const selectedCheck = newCatList.find((sel) => sel.uuid === )
            // console.log(checked)
            return (
              <Box
                key={index}
                sx={{
                  border: category.is_selected
                    ? "1px solid #4282E2 !important"
                    : "1px solid #E7E7E9",
                  backgroundColor: category.is_selected ? "#EAF5FF" : "#FFFFFF",
                }}
                className="add_list"
              >
                <FormGroup className="section__checks__list prompt_width">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={category.is_selected}
                        onChange={() => handleChange(category.uuid)}
                        sx={{ color: "#4282E2 !important" }}
                        className="section__checks__table"
                      />
                    }
                    label={
                      <Typography className="list__count">
                        {category.prompt === ""
                          ? "Blank category"
                          : category.prompt === null? "Untitled":isSmallScreen?category.prompt.length>60?category.prompt.substring(0,60)+"...":category.prompt :category.prompt.length>130?category.prompt.substring(0,130)+"...":category.prompt}
                      </Typography>
                    }
                  />
                </FormGroup>
              </Box>
            );
          })}
        {/* <Typography>6 Lines</Typography> */}
      </Grid>
    </>
  );
};

export default NewCategory;
