import { API_CONSTANTS, API_METHODS } from '../../config/api-constants';
import { fetchCall } from '../../utils/ajax';

export const RequestDashboardCount = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_DASHBOARD_COUNT}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const RequestDateWiseInspections = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_INSPECTIONS_DATEWISE}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const RequestUpcommingInspections = (callback, payload) => {
    const url = `${API_CONSTANTS.VIEW_UPCOMMING_INSPECTIONS}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const RequestCompanyDetails = (callback, payload) => {
    const url = `${API_CONSTANTS.GET_COMPANY_PROFILE}`;
    const method = API_METHODS.POST;
    return fetchCall(
      (response) => {
        callback(response);
      },
      url,
      method,
      payload
    );
  };

  export const RequestMapBoxDetails = (callback, payload) => {
    const address = payload.address ?? ""
    const city = payload.city ?? ""
    const state = payload.state ?? ""
    const zip = payload.state ?? ""
    const token  = address === "" && city === "" && state === "" && zip === "" ? 'booozdshadgsh' : payload.token
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${address},${city},${state},${zip}.json?&limit=1&access_token=${token}`;
    const method = API_METHODS.GET;
    return fetchCall(
      (response) => {

if( address === "" && city === "" && state === "" && zip === ""){
  callback("")
} else {
  callback(response);

}
      },
      url,
      method,
      payload
    );
  };