import { useState } from "react";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { apiCall } from "../Services/action";
import { useNavigate } from "react-router-dom";
import NumericInput from "react-numeric-input";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment-timezone/moment-timezone.js";
import Heading from "../../../common/cardHeading/Heading";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { formatZip } from "../../../utils/services";
import { Box, Button, Grid, Paper, Modal, TextField, Divider, Alert } from "@mui/material";

const Licenses = ({ data, refresh, setRefresh, planState, licenseDetails }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const openAddLicense = () => {
    PreviewAllocation();
    setAddLicenseModal(true);
  };
  const [confirmModal, setConfirmModal] = useState(false);
  const [addLicenseCount, setAddLicenseCount] = useState("0");
  const closeAddLicense = () => {
    setAddLicenseModal(false);
    setAddLicenseCount(0);
  };
  const closeAllLicenseUsed = () => setAllLicenseModal(false);
  const [removeLicenseCount, setRemoveLicenseCount] = useState(0);
  const closeRemoveLicense = () => {
    setRemoveLicenseModal(false);
    setRemoveLicenseCount(0);
  };
  const [addLicenseModal, setAddLicenseModal] = useState(false);
  const [allLicenseModal, setAllLicenseModal] = useState(false);
  const [proratedAmount, setProratedAmount] = useState(0);

  const changeLicenseCount = (event) => {
    const formattedPhoneNumber = formatZip(event.target.value);
    setAddLicenseCount(formattedPhoneNumber);
    // setIsChanged(true);
  };
  const [removeConfirmModal, setRemoveConfirmModal] = useState(false);
  const [removeLicenseModal, setRemoveLicenseModal] = useState(false);
  const changeRemoveLicenseCount = (event) => {
    const formattedCount = formatZip(event.target.value);
    setRemoveLicenseCount(formattedCount);
  };
  const gotoTeams = () => {
    navigate(`/settings/team`);
  };
  const openRemoveLicense = () => {
    if (parseInt(data?.licenses_used_count) === parseInt(data?.additional_license_count)) setAllLicenseModal(true);
    else setRemoveLicenseModal(true);
  };
  const closeConfirmModal = () => {
    setConfirmModal(false);
    setAddLicenseCount(0);
  };
  const closeRemoveConfirmModal = () => {
    setRemoveConfirmModal(false);
    setRemoveLicenseCount(0);
  };
  var discountPercentage = (parseFloat(data?.plan_summary?.price_per_license) * parseFloat(addLicenseCount) * parseFloat(data?.plan_summary?.additional_license_discount_per_license)) / parseInt(100);
  var Total = (parseFloat(data?.plan_summary?.price_per_license) * (isNaN(addLicenseCount) || addLicenseCount?.length === 0 ? parseFloat(0) + parseFloat(data?.plan_summary?.additional_license_tax_per_license) : parseFloat(addLicenseCount) + parseFloat(data?.plan_summary?.additional_license_tax_per_license)) - parseFloat(discountPercentage)).toFixed(2);
  var BaseTotal = parseFloat(data?.plan_summary?.price_per_license) * (isNaN(addLicenseCount) || addLicenseCount?.length === 0 ? parseFloat(0) + parseFloat(data?.additional_license_count) : parseFloat(addLicenseCount) + parseFloat(data?.additional_license_count)) + parseFloat(data?.plan_summary?.subscription_price) + parseFloat(data?.plan_summary?.additional_license_tax_per_license);
  var addingLicenceProrated = Math.abs(parseFloat(addLicenseCount?.length === 0 || addLicenseCount === "0" ? 0 : addLicenseCount) - parseFloat(addLicenseCount === "0" || addLicenseCount?.length === 0 ? 0 : licenseDetails?.pending_license_count));
  var ProratedAmountTotal = proratedAmount * (licenseDetails?.pending_license_count >= parseInt(addLicenseCount) ? 0 : addingLicenceProrated);
  // var June = "2023-09-04T07:16:26-04:00";
  var unusedLicense = parseInt(data?.additional_license_count) - parseInt(data?.licenses_used_count);
  //add license api
  const addLicense = () => {
    setLoading(true);
    const formdata = { quantity: addLicenseCount, company_id: sessionStorage.getItem("company_id"), user_id: sessionStorage.getItem("user_id"), role_id: sessionStorage.getItem("role_id") };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          setLoading(false);
          setRefresh(!refresh);
          setConfirmModal(true);
          toast.success(message);
          setAddLicenseModal(false);
        } else {
          setLoading(false);
          toast.error(message);
          console.log("else ", message);
        }
      },
      formdata,
      "ADD_LICENSES"
    );
  }; //remove license api
  const removeLicense = () => {
    setLoading(true);
    const formdata = { quantity: removeLicenseCount, company_id: sessionStorage.getItem("company_id"), user_id: sessionStorage.getItem("user_id"), role_id: sessionStorage.getItem("role_id") };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          setLoading(false);
          setRemoveConfirmModal(true);
          setRemoveLicenseModal(false);
          toast.success(message);
          setRefresh(!refresh);
        } else {
          setLoading(false);
          toast.error(message);
          console.log("else ", message);
        }
      },
      formdata,
      "REMOVE_LICENSE"
    );
  };

  const PreviewAllocation = () => {
    // setLoading(true);
    const formdata = { quantity: 1, company_id: sessionStorage.getItem("company_id"), user_id: sessionStorage.getItem("user_id"), role_id: sessionStorage.getItem("role_id") };
    apiCall(
      (response) => {
        const { message, success, data } = response.data;
        if (success) {
          console.log(data);
          // setLoading(false);
          // setRefresh(!refresh);
          // setConfirmModal(true);
          // toast.success(message);
          // setAddLicenseModal(false);
          setProratedAmount(data?.price);
        } else {
          // setLoading(false);
          toast.error(message);
          console.log("else ", message);
        }
      },
      formdata,
      "PREVIEW_ALLOCATION"
    );
  };

  return (
    <>
      {/* {console.log(data)} */}
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Paper elevation={0} className="payment-method-card">
            <Heading
              CancelledStatus={false}
              title="Licenses"
              icon={""}
              iconDesc={
                planState === "Canceled" || (parseInt(data?.licenses_used_count) === 0 && parseInt(data?.additional_license_count) === 0) || data?.pending_cancelation ? (
                  ""
                ) : (
                  <p className="cancel-plan-button" onClick={openRemoveLicense}>
                    Remove license(s)
                  </p>
                )
              }
            />
            <div className="license-container">
              <div style={{ flex: 1, opacity: planState === "Canceled" || data?.pending_cancelation ? "30%" : "100%" }}>
                <p className="plan-details-card-container2-datas-bold">{data?.plan_summary?.license_title}</p>
                <p className="plan-details-card-container2-datas">
                  {data?.licenses_used_count} of {data?.additional_license_count} additional licenses in use
                </p>
              </div>
              <div style={{ marginTop: "-30px" }}>
                <Button disabled={planState === "Canceled" || data?.pending_cancelation ? true : false} className="plan-details-card-see-all-button" onClick={openAddLicense}>
                  Add licenses
                </Button>
              </div>
            </div>
            {licenseDetails?.pending_license_count > "0" ? (
              <div style={{ paddingLeft: "15px", paddingRight: "15px", marginBottom: "20px" }}>
                <div style={{ border: "1.2px solid orange", borderRadius: "3px" }}>
                  <Alert severity="warning">
                    <span>
                      <p style={{ display: "inline", fontWeight: 600, fontSize: "14px" }}>Pending cancellation&nbsp;</p>
                      <p style={{ display: "inline", fontSize: "14px" }}>{`(${licenseDetails?.pending_license_count} license(s))`}</p>
                      <p style={{ fontSize: "12px", color: "#888888", lineHeight: "21px" }}>{moment(licenseDetails?.subscription_expiry_date).tz("America/New_York").format("MMM Do, YYYY h:mm a z")}. You will not be charged with your next renewal.</p>
                    </span>
                  </Alert>
                </div>
              </div>
            ) : (
              ""
            )}
          </Paper>
        </Grid>
      </Box>
      {/* add license */}
      <Modal open={addLicenseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className="inspection-filter-modal" sx={{ maxWidth: "788px !important", minHeight: "435px !important", top: "30% !important" }}>
          <Grid container>
            <Grid item xs={6}>
              <p className="upgrade-modal-title">Add Licenses</p>
            </Grid>
            <Grid item xs={6}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={closeAddLicense} />
            </Grid>
            <Grid item xs={12} className="license-modal-container">
              <div className="license-modal-content">
                <div style={{ flex: 5 }}>
                  <p className="licenses-addtional-details">Type of Subscription for Palm Tech Inspector (1 user)</p>
                  <p className="licenses-addtional-details">Price per additional license per {data?.title === "Monthly plan" ? "month" : "year"}</p>
                  {/* <p className="licenses-addtional-details">Additional licenses pending cancellation</p> */}
                  <p className="licenses-addtional-details">Additional licenses</p>
                  <p className="licenses-addtional-details">Discount</p>
                  <p className="licenses-addtional-details">Estimated taxes</p>
                </div>
                <div style={{ flex: 1 }}>
                  <p className="licenses-addtional-details">{data?.plan_summary?.interval}</p>
                  <p className="licenses-addtional-details">${data?.plan_summary?.price_per_license}</p>
                  {/* removed from design */}
                  {/* <p className="licenses-addtional-details">{licenseDetails?.pending_license_count}</p> */}
                  <div style={{ marginTop: "7px" }}>
                    <TextField sx={{ maxWidth: "113px !important" }} inputProps={{ maxLength: 2 }} name="phone" id="agentdetails-phone" size="small" variant="outlined" className="input-textfield" value={addLicenseCount} onChange={changeLicenseCount} />
                    {/* <NumericInput strict style={{ input: { padding: "17px 0px 17px 10px", maxWidth: "113px" } }} type="text" min={0} max={25} value={addLicenseCount} onChange={changeLicenseCount} /> */}
                  </div>
                  <p className="discount_value">{data?.plan_summary?.additional_license_discount_per_license}% OFF</p>
                  <p className="licenses-addtional-details">${data?.plan_summary?.additional_license_tax_per_license}</p>
                </div>
              </div>
              <Divider className="license-modal-divider" />
              <div className="total-container">
                <div style={{ display: "flex", justfyContent: "space-between" }}>
                  <div style={{ flex: 5 }}>
                    <div>
                      <p style={{ display: "inline", margin: "5px 0px 5px 0px" }} className="plan-details-card-container2-datas-bold">
                        Total due today{" "}
                      </p>
                      {/* {console.log(addLicenseCount)} */}
                      <p style={{ display: "inline" }} className="plan-details-card-addtional">
                        {licenseDetails?.pending_license_count !== 0 || (addLicenseCount?.length !== 0 && addLicenseCount !== "0" && addLicenseCount !== 0) ? "( " : ""}
                        {licenseDetails?.pending_license_count !== 0 ? `${licenseDetails?.pending_license_count} license(s) pending cancellation` : ""}
                        {licenseDetails?.pending_license_count !== 0 && addLicenseCount?.length !== 0 && addLicenseCount !== "0" && addLicenseCount !== 0 ? ", " : ""}
                        {addLicenseCount?.length !== 0 && addLicenseCount !== "0" && addLicenseCount !== 0 ? `${addLicenseCount} new license(s) added` : ""} {licenseDetails?.pending_license_count !== 0 || (addLicenseCount?.length !== 0 && addLicenseCount !== "0" && addLicenseCount !== 0) ? ")" : ""}
                      </p>
                    </div>
                    <p className="billingnews" style={{ marginTop: "5px", marginBottom: "10px", color: "#888888" }}>
                      The total charge for additional licenses is prorated based on the current plan's remaining time.
                    </p>
                  </div>
                  <div style={{ flex: 1 }}>
                    {/* {console.log(ProratedAmountTotal)}
                    {console.log(proratedAmount, parseFloat(licenseDetails?.pending_license_count), parseFloat(addLicenseCount))} */}
                    <p className="license-amount">{isNaN(ProratedAmountTotal) ? "" : `$${ProratedAmountTotal?.toFixed(2)}`}</p>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "7px" }}>
                <p style={{ display: "inline" }} className="billingnews">
                  ${data?.plan_summary?.subscription_price} base subscription + ${data?.plan_summary?.price_per_license} x {isNaN(addLicenseCount) || addLicenseCount?.length === 0 ? parseInt(0) + data?.additional_license_count : parseInt(addLicenseCount) + data?.additional_license_count} licenses + ${data?.plan_summary?.additional_license_tax_per_license} taxes = <p style={{ display: "inline", fontWeight: 700 }}>${BaseTotal}</p> will be charged on the next billing period.
                </p>
              </div>
              <Grid item xs={12} sx={{ mt: "30px" }}>
                <LoadingButton disabled={isNaN(Total) || addLicenseCount === "0" || addLicenseCount === 0 ? true : false} loading={loading} className="upgrade-confirm-checkout" onClick={addLicense}>
                  Confirm
                </LoadingButton>
                <Button className="upgrade-plans-button-cancel" onClick={closeAddLicense}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* remove license */}
      <Modal open={removeLicenseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className="inspection-filter-modal" sx={{ maxWidth: "788px !important", minHeight: "435px !important", top: "30% !important" }}>
          <Grid container>
            <Grid item xs={6}>
              <p className="upgrade-modal-title">Remove Licenses</p>
            </Grid>
            <Grid item xs={6}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={closeRemoveLicense} />
            </Grid>
            <Grid item xs={12} className="license-modal-container">
              <div className="license-modal-content">
                <div style={{ flex: 5 }}>
                  <p className="licenses-addtional-details">Type of Subscription for Palm Tech Inspector (1 user)</p>
                  <p className="licenses-addtional-details">Current number of additional licenses</p>
                  <p className="licenses-addtional-details">Current number of unused licenses</p>
                  <p className="licenses-addtional-details">Licenses to remove</p>
                </div>
                <div style={{ flex: 1 }}>
                  <p className="licenses-addtional-details">{data?.plan_summary?.interval}</p>
                  <p className="licenses-addtional-details">{data?.additional_license_count}</p>
                  <p className="licenses-addtional-details">{unusedLicense < 0 ? 0 : unusedLicense}</p>
                  <div style={{ marginTop: "7px" }}>
                    <TextField sx={{ maxWidth: "113px !important" }} inputProps={{ maxLength: 2 }} name="phone" id="agentdetails-phone" size="small" variant="outlined" className="input-textfield" value={removeLicenseCount} onChange={changeRemoveLicenseCount} />
                    {/* <NumericInput strict style={{ input: { padding: "17px 0px 17px 10px", maxWidth: "113px" } }} min={0} max={parseInt(data?.additional_license_count) - parseInt(data?.licenses_used_count)} value={removeLicenseCount} onChange={changeRemoveLicenseCount} /> */}
                  </div>
                </div>
              </div>
              <Divider className="license-modal-divider" />
              <div className="total-container">
                <div style={{ display: "flex", justfyContent: "space-between", userSelect: "none" }}>
                  <div style={{ flex: 5, height: "55px", padding: "13px 0px 19px 14px" }}>
                    <div>
                      <p style={{ display: "inline", margin: "0px 0px 4px 5px" }} className="plan-details-card-container2-datas-bold">
                        You can only remove licenses that are not used by team members.{" "}
                        <p style={{ color: "#4282E2", display: "inline", cursor: "pointer" }} onClick={gotoTeams}>
                          Manage team.
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <Grid item xs={12} sx={{ mt: "30px" }}>
                <LoadingButton disabled={removeLicenseCount === 0 ? true : false} loading={loading} className="upgrade-confirm-checkout" onClick={removeLicense}>
                  Confirm
                </LoadingButton>
                <Button className="upgrade-plans-button-cancel" onClick={closeRemoveLicense}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* add license Confirm Modal */}
      <Modal open={confirmModal} onClose={closeConfirmModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className="inspection-filter-modal" sx={{ maxWidth: "655px !important", minHeight: "435px !important", top: "30% !important" }}>
          <Grid container sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
            <CheckCircleIcon className="ic-check-circle" />
            <Grid item xs={12}>
              <p className="purchase-complete">Purchase complete!</p>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
              <p className="your-purchase-of-2-a">Your purchase of {addLicenseCount} additional license(s) is complete. New team members can be added immediately.</p>{" "}
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
              <Button sx={{ mt: 5 }} className="upgrade-confirm-checkout" onClick={closeConfirmModal}>
                Finish
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* remove license Confirm Modal */}
      <Modal open={removeConfirmModal} onClose={closeRemoveConfirmModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className="inspection-filter-modal" sx={{ maxWidth: "655px !important", minHeight: "435px !important", top: "30% !important" }}>
          <Grid container sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
            <CheckCircleIcon className="ic-check-circle" />
            <Grid item xs={12}>
              <p className="purchase-complete">Licenses updated</p>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
              <p className="your-purchase-of-2-a">Your removal of {removeLicenseCount} additional license(s) is complete. </p>{" "}
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
              <Button sx={{ mt: 5 }} className="upgrade-confirm-checkout" onClick={closeRemoveConfirmModal}>
                Finish
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* all license used modal */}
      <Modal open={allLicenseModal} onClose={closeAllLicenseUsed} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className="inspection-filter-modal" sx={{ maxWidth: "655px !important", minHeight: "435px !important", top: "30% !important" }}>
          <Grid container sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
            <ReportProblemIcon className="ic-delete" />
            <Grid item xs={12}>
              <p className="purchase-complete">All licenses in use</p>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
              <p className="your-purchase-of-2-a">All aditional licenses are currently in use. Please remove a team member from the Team page to continue. </p>{" "}
            </Grid>
            <Grid item xs={12} sx={{ mt: "60px", mr: 5 }}>
              <LoadingButton className="upgrade-confirm-checkout" onClick={gotoTeams}>
                Go to Team
              </LoadingButton>
              <Button className="upgrade-plans-button-cancel" onClick={closeAllLicenseUsed}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default Licenses;