import EmailTemplate from '../../common/NotificationCenterShared/EmailTemplate'

const PaymentSignatureTemplate = () => {
  return (
    <>
    <EmailTemplate title='Payment and Signature request'
    subTitle='Edit the template using the placeholders on the right.'
    templateFor='payment_and_signature_request'
    />
    </>
  )
}

export default PaymentSignatureTemplate