import React, { useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import EnhancedTableHead from "./EnhancedTableHead";
import TableBody from "./TableBody";
import { useMediaQuery } from "react-responsive";
import TablePagination from "@mui/material/TablePagination";
import Img from "../../../assets/images/propertyblank.jpg";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { apiCall } from "../../pages/settings/Services/action";
import toast from "react-hot-toast";

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const TableLayout = (props) => {
  const { InspectionList,in_trial, type, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, totalList, reLoader, paymentStatus, agreementStatus,inspListApiResponseMessage } = props;
  const navigate = useNavigate();

  var tableData = [];
  InspectionList?.map((data, index) => {
    let localDate = moment(moment.utc(data?.start_time).local());
    let updatedDateAndTime = moment(moment.utc(data?.updated_at).local());
    let formattedLocalDate = localDate.format("YYYY-MM-DD HH:mm:ss");
    let inspDate = moment(formattedLocalDate).format("M/D/YY");
    let inspTime = moment(formattedLocalDate).format("LT");
    let uptDate = moment(updatedDateAndTime).format("M/D/YY");
    // let date_availiable = new Date(data.start_time);
    let timeStramp = moment(updatedDateAndTime).fromNow();
    // let date_month = date_availiable.getMonth() + 1;
    // let date_day_without_0 = date_availiable.getDate();
    // let date_day = date_day_without_0.toString().padStart(2, "0");
    // let date_year = date_availiable.getFullYear().toString().substr(-2);
    // var dateTime = data.start_time.split(" ");
    // const dayStramp = moment(data.updated_at, "YYYYMMDD").fromNow();
    // const dateStramp =  moment(data.updated_at).format("DD/MM/YYYY")

    // updated date format preparation
    // let updated_date_availiable = new Date(data.updated_at);
    // let updated_date_month = updated_date_availiable.getMonth() + 1;
    // let updated_date_day_without_0 = updated_date_availiable.getDate();
    // let updated_date_day = updated_date_day_without_0
    //   .toString()
    //   .padStart(2, "0");
    // let updated_date_year = updated_date_availiable
    //   .getFullYear()
    //   .toString()
    //   .substr(-2);

    let property_image = data.propertydetails === null ? Img : data.propertydetails?.main_image_url === null ? Img : data.propertydetails?.main_image_url;
    //update date
    let upd = `${timeStramp}-${uptDate}`;
    //updated time
    let upt = `${inspDate}-${inspTime}`;
    // address
    // adding address line 2 (anil)
    let listAddress = `${data.location_address  ?? "" + " " + data.location_address_2 ?? "" }-${data.location_city ?? ""}-${data.location_state?.toUpperCase() ?? ""}`;
    return tableData.push({
      Image: <img src={property_image} className="table-body-row-img" alt="property-thumbnail" />,
      Address: listAddress === " null--" ? "" : listAddress,
      // <div className="table-body-row">
      //     <p>
      //         <BootstrapTooltip
      //             title={
      //                 data.location_address !== null && data.location_address.length > 15 ||
      //                 (data.location_city !== null && data.location_city.length > 10) ||
      //                 (data.location_state !== null && data.location_state.length > 10) ? (
      //                     <h1 className="font-13">
      //                         {data.location_address !== null ? data.location_address : ''} &nbsp;
      //                         {data.location_city !== null ? data.location_city : ''}, &nbsp;{data.location_state !== null ? data.location_state : ''}&nbsp;{' '}
      //                         {data.location_zip !== null ? data.location_zip : ''}
      //                     </h1>
      //                 ) : (
      //                     ''
      //                 )
      //             }>
      //             <span>
      //                 {' '}
      //                 {data.location_address !== null && data.location_address?.length > 15 ? `${data.location_address.substring(15, 0)}...` : data.location_address}
      //                 <br />
      //                 {data.location_city !== null && data.location_city?.length > 10 ? `${data.location_city !== null && data.location_city.substring(10, 0)}...` : data.location_city}{' '}
      //                 <br />
      //                 {/* {data.location_zip.substring(10,0)} */}
      //                 {data.location_state !== null && data.location_state?.length > 10 ? `${data.location_state.substring(10, 0)}...` : data.location_state}
      //             </span>
      //         </BootstrapTooltip>
      //     </p>
      // </div>
      Status: data.status,
      // <Chip
      //     sx={{
      //         backgroundColor: InspStatusBg(data.status),
      //         color: InspStatusColor(data.status),
      //     }}
      //     label={<h3 style={{ fontSize: '9px', fontWeight: 'bold', textTransform: 'uppercase' }}>{data.status}</h3>}
      // />
      "Date/Time": upt,
      // <span>
      //     <span className="date-placeholder">{`${inspDate}`}</span>
      //     <span className="time-placeholder">{`${inspTime}`}</span>
      // </span>
      Client: data.orderclient.map((item) => {
        let clnt = `${item.client?.first_name ?? ""} ${item.client?.last_name ?? ""}`;
        return clnt;
        // <p>
        //     <BootstrapTooltip title={clnt?.length > 15 ? <h1 className="font-13">{clnt}</h1> : ''}>
        //         <span>{clnt?.length > 15 ? `${clnt.substring(15, 0)}... ` : clnt}</span>
        //     </BootstrapTooltip>
        // </p>
      }),
      "Id#": data.id,

      id: data.id,

      Agent: data.orderagent.map((item) => {
        let agnt = `${item?.agent?.first_name ?? ""} ${item?.agent?.last_name ?? ""}`;
        return agnt;
        // <p>
        //     <BootstrapTooltip title={agnt?.length > 15 ? <h1 className="font-13">{agnt}</h1> : ''}>
        //         <span>{agnt?.length > 15 ? `${agnt.substring(15, 0)}... ` : agnt}</span>
        //     </BootstrapTooltip>
        // </p>
      }),
      Inspector: data.orderinspector.map((item) => {
        let insp = `${item?.inspector?.first_name ?? ""} ${item?.inspector?.last_name ?? ""}`;
        // let inspector_id = `${item.inspector_id}`;
        // return insp

        return insp?.length > 13 ? `${insp.substring(13, 0)}... ` : insp;
        // <p>
        //     <BootstrapTooltip title={insp?.length > 15 ? <h1 className="font-13">{insp}</h1> : ''}>
        //         <span>{insp?.length > 15 ? `${insp.substring(15, 0)}... ` : insp}</span>
        //     </BootstrapTooltip>
        // </p>
      }),

      Fee: data.fee === null ? "$0" :  data.fee?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      $: data.paid,
      // <div>
      //   {data?.paid === 0 ? (
      //     <BootstrapTooltip title={<h1 className="font-13">Unpaid</h1>}>
      //       <CancelIcon sx={{ color: "#E30303", fontSize: 18 }} />
      //     </BootstrapTooltip>
      //   ) : (
      //     <BootstrapTooltip title={<h1 className="font-13">Paid</h1>}>
      //       <CheckCircleIcon sx={{ color: "#4282E2", fontSize: 18 }} />
      //     </BootstrapTooltip>
      //   )}
      // </div>
      fileIcon: data.is_agreement_signed,
      // data.fileIcon
      // <div>
      //   {data?.is_agreement_signed === 0 ? (
      //     <BootstrapTooltip title={<h1 className="font-13">Unsigned</h1>}>
      //       <CancelIcon sx={{ color: "#E30303", fontSize: 18 }} />
      //     </BootstrapTooltip>
      //   ) : (
      //     <BootstrapTooltip title={<h1 className="font-13">Signed</h1>}>
      //       <CheckCircleIcon sx={{ color: "#4282E2", fontSize: 18 }} />
      //     </BootstrapTooltip>
      //   )}
      // </div>
      Updated: upd,

      // <div style={{ textAlign: 'left' }}>
      //     {/* <span className="date-placeholder">{dateStramp}</span> */}
      //     {/* <span className="date-placeholder">{`${updated_date_month}/${updated_date_day}/${updated_date_year}`}</span> */}
      //     <span className="date-placeholder">{`${uptDate}`}</span>
      //     <span className="time-modified-placeholder">{`${timeStramp}`}</span>
      // </div>
      Actions: (
        <span>
          {/* {in_trial?.is_trial_company ===1||data.status === "Complete" ? ( */}
          {data.status === "Complete" ? (
            <>
              <BootstrapTooltip title={<h1 className="font-13">Edit details</h1>}>
                <EditIcon id={`edit${index+1}`} onClick={() => navigate(`/schedule-inspection/?id=${data.id}`,{
                  state:{
                    editInspection:true
                  }
                }
                )} className="edit-icon" />
              </BootstrapTooltip>
              <BootstrapTooltip title={<h1 className="font-13">Delete inspection</h1>}>
                <DeleteIcon id={`delete${index+1}`} className="delete-icon" />
              </BootstrapTooltip>
            </>
          ) : 
          (  data.status === "Cancelled" || data.status === "Published") ?
          (
            <>
              <BootstrapTooltip title={<h1 className="font-13">Edit details</h1>}>
                <EditIcon id={`edit${index+1}`} onClick={() => navigate(`/schedule-inspection/?id=${data.id}`,{
                  state:{
                    editInspection:true
                  }
                })} className="edit-icon" />
              </BootstrapTooltip>
              {in_trial?.is_trial_company ===1 ?
              <BootstrapTooltip title={<h1 className="font-13">You cannot delete this record</h1>}>
              <DeleteIcon className="delete-icon" />
            </BootstrapTooltip>
            :
            <BootstrapTooltip title={<h1 className="font-13">Delete inspection</h1>}>
                <DeleteIcon id={`delete${index+1}`} className="delete-icon" onClick={() => handleClickOpenAlert(data.id)} />
              </BootstrapTooltip>
              }              
            </>
          )
          :
          (            
            <>
              <BootstrapTooltip title={<h1 className="font-13">Edit details</h1>}>
                <EditIcon id={`edit${index+1}`} onClick={() => navigate(`/schedule-inspection/?id=${data.id}`,{
                  state:{
                    editInspection:true
                  }
                })} className="edit-icon" />
              </BootstrapTooltip>
              {in_trial?.is_trial_company ===1 ?
              <BootstrapTooltip title={<h1 className="font-13">You cannot delete this record</h1>}>
              <DeleteIcon id={`delete${index+1}`} className="delete-icon" />
            </BootstrapTooltip>
            :
            <BootstrapTooltip title={<h1 className="font-13">Delete inspection</h1>}>
                <DeleteIcon id={`delete${index+1}`} className="delete-icon" onClick={() => handleClickOpenAlert(data.id)} />
              </BootstrapTooltip>
              }   
            </>
          )}
        </span>
      ),
      // cancel button (anil)
      Cancel: (
        <span>
          {data.status === "Cancelled" ||data.status === "Complete"  ||data.status==="Published" ? (
            <>
              <BootstrapTooltip title={<h1 className="font-13">You cannot cancel this inspection</h1>}>
                <RemoveCircleOutlineIcon
                  // onClick={() => handleCancelClickOpenAlert(data.id)}
                  className="edit-icon"
                  sx={{ color: "#E30303", fontSize: 18 }}
                />
              </BootstrapTooltip>
            </>
          ) : (
            <>
              <BootstrapTooltip title={<h1 className="font-13">Cancel Inspection</h1>}>
                <RemoveCircleOutlineIcon id={`cancel${index+1}`} onClick={() => handleCancelClickOpenAlert(data.id)} className="edit-icon" sx={{ color: "#E30303", fontSize: 18 }} />
              </BootstrapTooltip>
            </>
          )}
        </span>

        //   <BootstrapTooltip title={<h1 className="font-13">Cancel Inspection</h1>}>
        //   <RemoveCircleOutlineIcon
        //     onClick={() => handleCancelClickOpenAlert(data.id)}
        //     className="edit-icon"
        //     sx={{ color: "#E30303", fontSize: 18 }}
        //   />
        // </BootstrapTooltip>
      ),
    });
  });

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [cancelopen, setCancelOpen] = useState(false);
  const handleCancelClose = () => setCancelOpen(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteCancelId, setCancelDeleteId] = useState();
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [pages, setPages] = React.useState(0);
  const [rowsPerPages, setRowsPerPages] = React.useState(10);
  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });

  // const messagesEndRef = React.createRef()
  // const scrollToBottom = () => {

  //   messagesEndRef.current?.scroll({ left: 3000  })

  // }

  // useEffect(() => {
  //   scrollToBottom()

  // }, [isMobile])

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;

  // delete inspection api call (anil)*******************************************
  const deleteInspection = (orderId) => {
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      order_id: orderId,
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      // agent_id: id,
    };
    apiCall(
      (response) => {
        console.log("DELETE_INSPECTION", response);
        if (response.data.success) {
          toast.success(response.data.message);
          reLoader((prev) => prev + 1);
          setOpen(false);
        } else {
          console.log("else", response.data.message);
        }
      },
      formdata,
      "DELETE_INSPECTION"
    );
  };

  // cancel inspection api call (anil)*******************************************
  const cancelInspection = (orderId) => {
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      order_id: orderId,
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      // agent_id: id,
    };
    apiCall(
      (response) => {
        console.log("CANCEL_INSPECTION", response);
        if (response.data.success) {
          toast.success(response.data.message);
          reLoader((prev) => prev + 1);
          setOpen(false);
        } else {
          console.log("else", response.data.message);
        }
      },
      formdata,
      "CANCEL_INSPECTION"
    );
  };

  // handle close of the inspection delete inspection(anil)*************************
  const handleClickOpenAlert = (id) => {
    setDeleteId(id);
    setOpen(true);
  };

  // handle cancel close of the inspection cancel inspection(anil)*************************
  const handleCancelClickOpenAlert = (id) => {
    setCancelDeleteId(id);
    setCancelOpen(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tableData.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePages = (event, newPage) => {
    setPages(newPage);
  };

  const handleChangeRowsPerPages = (event) => {
    setRowsPerPages(parseInt(event.target.value, 10));
    setPages(0);
  };
  return (
    <>
      <TableContainer
        sx={{ overflowX: { sm: "auto", lg: "unset", md: "scroll" } }}
        // ref={isMobile && messagesEndRef}
        className={isMobile && "mobileScroller"}
      >
        <Table
          aria-labelledby="tableTitle"
          size={"small"}
          style={{
            transform: isMobile && "rotate(180deg)",
            overflowX: "unset",
          }}
        >
          <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={tableData.length} type={type} />
          <TableBody table_data={tableData} order={order} orderBy={orderBy} page={type === "dashboard" ? pages : 0} InspectionList={InspectionList} rowsPerPage={type === "dashboard" ? rowsPerPages : rowsPerPage} type={type} paymentStatus={paymentStatus} agreementStatus={agreementStatus} in_trial={in_trial} inspListApiResponseMessage={inspListApiResponseMessage}/>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={type === "dashboard" ? tableData.length : totalList}
        rowsPerPage={type === "dashboard" ? rowsPerPages : rowsPerPage}
        className='pagination-inspection'
        page={type === "dashboard" ? pages : page}
        style={{
          fontFamily: "Open Sans",
          color: "#3b3b3b",
          fontSize: "12px",
        }}
        onPageChange={type === "dashboard" ? handleChangePages : handleChangePage}
        onRowsPerPageChange={type === "dashboard" ? handleChangeRowsPerPages : handleChangeRowsPerPage}
      />

      {/* delete inspection model (anil) starting */}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "480px !important", height: "231px !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={handleClose} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "22px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                Delete inspection?
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "14px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                This action cannot be undone.
              </Typography>
            </Grid>
            <Grid container sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <Button variant="outlined" className="inspection-filter-modal-buttons-cancel" sx={{ width: "119px",":hover":{backgroundColor:"transparent !important"} }} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                className="inspection-filter-modal-buttons-apply"
                sx={{ width: "119px", ml: 3,":hover": {backgroundColor: "#1E8970 !important",} }}
                onClick={() => {
                  deleteInspection(deleteId);
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* delete inspection model (anil) ending */}

      {/* cancel inspection model (anil) starting */}
      <Modal
        // open={open}
        open={cancelopen}
        onClose={handleCancelClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={"inspection-filter-modal-main"}
      >
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "480px !important", height: "231px !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={handleCancelClose} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "22px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                Cancel inspection?
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "14px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                This action cannot be undone.
              </Typography>
            </Grid>
            <Grid container sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <Button variant="outlined" className="inspection-filter-modal-buttons-cancel" sx={{ width: "119px",":hover": {background: "transparent !important",} }} onClick={handleCancelClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                className="inspection-filter-modal-buttons-apply"
                sx={{ width: "119px", ml: 3,":hover": {backgroundColor: "#1E8970 !important",} }}
                onClick={() => {
                  cancelInspection(deleteCancelId);
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* cancel inspection model (anil) ending */}
    </>
  );
};

export default TableLayout;
