import React from "react";
import "../../../assets/styles/containers/_scheduleInspection.scss";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';

const ScheduleButtons = (props) => {
  const {
    ss,
    tt,
    aa,
    isMobile,
    activeStep,
    navigate,
    NextData,
    handleNext,
    Type,
    editValues,
    errors,
    setErrors,
    setDataIsCorrect,
    showCustomAddInput,
    showDiscountInput,
    setActiveStep,
    disableSche,
    disableSchedule,
    disableSchedule2,
    disableSchedule3,
    setServicesValid,
    setTemplateValid,
    setAgreeValid,
    setAddressValid,
    setCityValid,
    setStateValid,
    setZipValid,
    ScheduleInspectionSteps,
    handleBack,
    showTooltip,
    setShowTooltip,
    handleDraft,
    location,
    isSchedule,
    ChargeValid,
    agreementSelected,
    selectedTemplate,
  } = props;


  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props}  arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: 'white',
      marginLeft: "90px !important",
      '&::before': {
        border: '1px solid #E30303 !important',
      },
    },
    [`& .${tooltipClasses.tooltip}`]: !isMobile ? {
      backgroundColor: "white",
      color: "#3B3B3B !important",
      padding:"24px 25px",
      border: "1px solid #E30303",
      position: "relative",
      bottom:  "15px" ,
    } : {
      backgroundColor: "white",
      color: "#3B3B3B !important",
      padding:"24px 25px",
      border: "1px solid #E30303",
      position: "relative",
      right:  "31%" ,
    },
  }));

  const handleSubmit = () => {
    if (showDiscountInput || showCustomAddInput) {
      setDataIsCorrect(true);
      setErrors({})
      setTimeout(() => {
        NextData()
      },1000)
    } else {
    handleNext()

    }

  }

  React.useEffect(() => {

    if(showTooltip){
   
      if(aa){
        setAgreeValid(2)
      
      } else {
        setAgreeValid(1)
      
      }
    }

  }, [ aa && showTooltip ])

  
  React.useEffect(() => {
 
    if(showTooltip){
      if(ss){
        setServicesValid(2)
      } else {
        setServicesValid(1)
      }
      
  
    }

  }, [ ss && showTooltip])

  React.useEffect(() => {
 
    if(showTooltip){
      if(tt){
        setTemplateValid(2)
      } else {
        setTemplateValid(1)
      }
  
    }

  }, [ tt && showTooltip])

  const ServiceValid = () => {
    if(!ss){
      setServicesValid(1)
    } else {
      setServicesValid(2)
    }

    if(!tt){
      setTemplateValid(1)
    } else {
      setTemplateValid(2)

    }

    if(!aa){
      setAgreeValid(1)

    } else {
      setAgreeValid(2)

    }
    setActiveStep(4)
  }

  const renderTooltipTitle = () => {

    if (!(disableSche && disableSchedule && disableSchedule2 && disableSchedule3 && agreementSelected && selectedTemplate?.length !== 0)) {
      return (
        <>
        {
          !isMobile &&
        <div  style={{width:"100%", textAlign: "right"}}>
        <CloseIcon sx={{cursor:"pointer"}} onClick={() => setShowTooltip(false)} />

        </div>
    }
           <Typography className="flash" variant="body1" sx={{ fontSize: "14px", fontWeight: '600 !important', marginBottom:"14px" }}>
          The following information is required for scheduling an inspection:
        </Typography>
        {
          !disableSche &&
          <Typography onClick={() => setActiveStep(0)} className="flash" variant="body1" sx={{ fontSize: "12px", marginBottom:"8px", display: "flex", alignItems: "center", cursor: "pointer" }}>
          <FiberManualRecordIcon sx={{backgroundColor: "#979797 !important", opacity: "0.5", mr:"8px !important", fontSize: "6px !important"}}/> <span>Basic Details </span> <span><ArrowForwardIosIcon sx={{ml:"14px !important",  fontSize: "12px !important"}}/></span>
           </Typography>
        }

      {
          !disableSchedule &&
          <Typography onClick={() => setActiveStep(1)} className="flash" variant="body1" sx={{ fontSize: "12px", marginBottom:"8px", display: "flex", alignItems: "center", cursor: "pointer" }}>
          <FiberManualRecordIcon sx={{backgroundColor: "#979797 !important", opacity: "0.5", mr:"8px !important", fontSize: "6px !important"}}/> <span>Property Details </span> <span><ArrowForwardIosIcon sx={{ml:"14px !important",  fontSize: "12px !important"}}/></span>
           </Typography>
        }

        {
          !disableSchedule2 &&
          <Typography onClick={() => setActiveStep(2)} className="flash" variant="body1" sx={{ fontSize: "12px", marginBottom:"8px", display: "flex", alignItems: "center", cursor: "pointer" }}>
          <FiberManualRecordIcon sx={{backgroundColor: "#979797 !important", opacity: "0.5", mr:"8px !important", fontSize: "6px !important"}}/> <span>Client Details </span> <span><ArrowForwardIosIcon sx={{ml:"14px !important",  fontSize: "12px !important"}}/></span>
           </Typography>
        }

        {
          !(disableSchedule3 && agreementSelected && selectedTemplate?.length !== 0) &&
          <Typography onClick={() => ServiceValid()} className="flash" variant="body1" sx={{ fontSize: "12px", marginBottom:"8px", display: "flex", alignItems: "center", cursor: "pointer" }}>
          <FiberManualRecordIcon sx={{backgroundColor: "#979797 !important", opacity: "0.5", mr:"8px !important", fontSize: "6px !important"}}/> <span>Services</span> <span><ArrowForwardIosIcon sx={{ml:"14px !important",  fontSize: "12px !important"}}/></span>
           </Typography>
        }
           
        <Typography className="flash" variant="body1" sx={{ fontSize: "12px", marginTop:"12px" }}>
        Without this information, you may save the inspection as a draft. 
        </Typography>
        {
          !isMobile &&
          <div onClick={() => setShowTooltip(false)} style={{width:"100%", textAlign: "right", textDecoration:"underline", cursor:"pointer"}}>
          Close
  
          </div>
        }
   
        </>
  
      );
    } else {
      return null;
    }
  };


  const HandleSaveDraft = () => {
    setShowTooltip(false)
    handleDraft()
  }

  const ShowValidations = () => {
    if (document.getElementById("PropertyAddressId").value === "") {
      setAddressValid("Address cannot be blank");
    } else {
      setAddressValid(undefined);
    }
    if (document.getElementById("PropertyCityId").value === "") {
      setCityValid("city cannot be blank");
    } else {
      setCityValid(undefined);
    }
    if (document.getElementById("PropertyStateId").value === "") {
      setStateValid("state cannot be blank");
    } else {
      setStateValid(undefined);
    }

    if (document.getElementById("PropertyZipId").value === "") {
      setZipValid("zip cannot be blank");
    } else {
      setZipValid(undefined);
    }

    if(!ss){
      setServicesValid(1)
    } else {
      setServicesValid(2)
    }

    if(!tt){
      setTemplateValid(1)
    } else {
      setTemplateValid(2)

    }

    if(!aa){
      setAgreeValid(1)

    } else {
      setAgreeValid(2)

    }
    setShowTooltip(true)
  }

  return (
    <>
      {!isMobile ? (
        <Grid container className="button-section-light">
          <Grid item xs={12} sx={{ marginBottom: "25px" }}>
            {activeStep === ScheduleInspectionSteps?.length - 1 ? (
              <>

                       <BootstrapTooltip
              title={renderTooltipTitle()}
              // onOpen={() => setShowTooltip(!showTooltip)}
              enterTouchDelay={0}
              // onClose={() => setShowTooltip(!showTooltip)}
              onClick={ShowValidations}
              onBlur={() => setShowTooltip(false)}
              open={showTooltip}
              // disableFocusListener
              // disableHoverListener
              // disableTouchListener
              >
                <span className="disable-btn-wrapper">

              <Button
              disabled={!(disableSche && disableSchedule && disableSchedule2 && disableSchedule3 && agreementSelected && selectedTemplate?.length !== 0) || (ChargeValid === true && true)}
              sx={{":hover": {
                backgroundColor: "#1E8970 !important",
              }}}
                variant="contained"
                className={
                  (disableSche && disableSchedule && disableSchedule2 && disableSchedule3 && agreementSelected && selectedTemplate?.length !== 0 ) ? "default-btn-light"
                   : ChargeValid ? "disabled-btn-light"
                   : "disabled-btn-light"
                }
                onClick={handleNext}
              >
                Schedule
              </Button>
              </span>

              </BootstrapTooltip>
              </>
            ) : (
              ""
            )}
            {activeStep === 0 ? (
              <Button
                variant="contained"
                sx={{":hover": {
                  backgroundColor: "#1E8970 !important",
                }}}
                className="default-btn-light"
                onClick={handleNext}
              >
                Next
              </Button>
            ) : (
              ""
            )}
          </Grid>

          {activeStep !== ScheduleInspectionSteps?.length - 1 ? (
            activeStep === 0 ? (
              ""
            ) : (
              <>
                <Grid item xs={6}>
                  <Button
                    className={
                      activeStep !== 0
                        ? "back-btn xs-btn"
                        : "back-btn-disabled xs-btn"
                    }
                    variant="outlined"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    <Typography className="back-btn-content">
                      <ArrowBackIosIcon fontSize="small" /> Back
                    </Typography>{" "}
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                   sx={{":hover": {
                    backgroundColor: "#1E8970 !important",
                  }}}
                    variant="contained"
                    className="default-btn xs-btn"
                    onClick={handleNext}
                  >
                    Next
                  </Button>
                </Grid>
              </>
            )
          ) : (
            <>
              <Grid item xs={6}>
                <Button
                  className={
                    activeStep !== 0
                      ? "back-btn xs-btn"
                      : "back-btn-disabled xs-btn"
                  }
                  variant="outlined"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  <Typography className="back-btn-content">
                    <ArrowBackIosIcon fontSize="small" /> Back
                  </Typography>{" "}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{textTransform: "none !important", 
                  pointerEvents : Type === "edit" && editValues?.status === "In-Progress" || editValues?.status === "Complete" || editValues?.status === "Published" && "none",
                  ":hover": { backgroundColor: "#1E8970 !important",}}}
                  className="default-btn draft-btn xs-btn"
                  variant="contained"
                  onClick={handleDraft}
                >
                  Save as draft{" "}
                </Button>
              </Grid>
            </>
          )}

          <Grid item xs={12} className="cancel-btn-wrapper">
            <Button
              variant="text"
              className="cancel-btn xs-btn"
              onClick={() => location.state?.calendar ? navigate("/calendar") : navigate("/inspections")}
            >
              <Typography>Cancel</Typography>{" "}
            </Button>
          </Grid>
        </Grid>
      ) : (
        // desktop screen button
        <Grid className="button-section">
          <Button
            className={activeStep !== 0 ? "back-btn" : "back-btn-disabled"}
            variant="outlined"
            onClick={handleBack}
            disabled={activeStep === 0}
          >
            <Typography className="back-btn-content">
              <ArrowBackIosIcon fontSize="small" /> Back
            </Typography>{" "}
          </Button>
          <div>
            <Button
              variant="text"
              className="cancel-btn"
              onClick={() => location.state?.calendar ? navigate("/calendar") : navigate("/inspections")}
            >
              <Typography>Cancel</Typography>{" "}
            </Button>
            {activeStep === ScheduleInspectionSteps?.length - 1 ? (
              <Button
              sx={{textTransform: "none !important", 
              pointerEvents : Type === "edit" && editValues?.status === "In-Progress" || editValues?.status === "Complete" || editValues?.status === "Published" && "none",
               ":hover": { backgroundColor: "#1E8970 !important",}}}
              // disabled={!(disableSchedule && disableSchedule2 && disableSchedule3)}
              className={"default-btn draft-btn"}
                variant="contained"
                onClick={HandleSaveDraft}
                disabled={Type === "edit" && editValues?.status === "In-Progress" || editValues?.status === "Complete" || editValues?.status === "Published"}
              >
                Save as draft{" "}
              </Button>
            ) : (
              ""
            )}

            {activeStep === ScheduleInspectionSteps?.length - 1 ? (
              <>
              <BootstrapTooltip
              title={renderTooltipTitle()}
              // onOpen={() => setShowTooltip(!showTooltip)}
              enterTouchDelay={0}
              // onClose={() => setShowTooltip(!showTooltip)}
              onClick={ShowValidations}
              onBlur={() => setShowTooltip(false)}
              open={showTooltip}
              // disableFocusListener
              // disableHoverListener
              // disableTouchListener
              >

                <span className="disable-btn-wrapper">
                  <Button
                    disabled={!(disableSche && disableSchedule && disableSchedule2 && disableSchedule3 && agreementSelected && selectedTemplate?.length !== 0)}
                    variant="contained"
                    className= "default-btn-schedule"
                    onClick={handleSubmit}
                    type="submit"
                    sx={{":hover": {
                      backgroundColor: "#1E8970 !important",
                    }}}
                  >
                    Schedule
                  </Button>
                </span>
              </BootstrapTooltip>

                </>
            ) : (
              <Button
              sx={{boxShadow:"none",":hover": {
                backgroundColor: "#1E8970 !important",
              },}}
                variant="contained"
                className="default-btn"
                onClick={handleNext}
              >
                Next
              </Button>
            )}
          </div>
        </Grid>
      )}
    </>
  );
};

export default ScheduleButtons;
