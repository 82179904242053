import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { Button, Grid, IconButton, Typography, Stack, Switch, ButtonGroup, FormControlLabel } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';

function CustomToolbar(props) {
    const { label, date, view, views, onView, onNavigate, handleFilter, filterToggle, setView } = props;
    const [month, setMonth] = useState('January');
    const mMonth = moment(date).format('MMMM');
    const months = moment.months();
    const AntSwitch = styled(Switch)(({ theme }) => ({
        'width': 28,
        'height': 16,
        'padding': 0,
        'display': 'flex',
        '&:active': {
            '& .MuiSwitch-thumb': {
                width: 15,
            },
            '& .MuiSwitch-switchBase.Mui-checked': {
                transform: 'translateX(9px)',
            },
        },
        '& .MuiSwitch-switchBase': {
            'padding': 2,
            '&.Mui-checked': {
                'transform': 'translateX(12px)',
                'color': '#fff',
                '& + .MuiSwitch-track': {
                    opacity: 1,
                    backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#239D80',
                },
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: theme.transitions.create(['width'], {
                duration: 200,
            }),
        },
        '& .MuiSwitch-track': {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
            boxSizing: 'border-box',
        },
    }));
    useEffect(() => {
        setMonth(mMonth);
    }, [mMonth]);

    // const onChange = (event) => {
    //     const current = event.target.value;
    //     onNavigate('DATE', moment().month(current).toDate());
    //     setMonth(current);
    // };

    const goToMonthView = () => {
        onView('month');
        setView('month');
    };

    const goToWeekView = () => {
        onView('week');
        setView('week');
    };
    const goToDayView = () => {
        onView('day');
        setView('day');
    };

    const goToBack = () => {
        onNavigate('PREV');
    };
    const goToNext = () => {
        onNavigate('NEXT');
    };

    const goToToday = () => {
        onNavigate('TODAY');
    };

    return (
        <Grid container className="calendar-toolbar-grid">
            <Grid item xs={6}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={view === 'week' ? 6 : 5} lg={view === 'week' ? 5 : 4} xl={view === 'week' ? 4 : 3}>
                        <Typography className="calendar-toolbar-date">{label}</Typography>
                    </Grid>
                    <Grid item xs={12} md={view === 'week' ? 6 : 7} lg={view === 'week' ? 7 : 8} xl={view === 'week' ? 8 : 9} sx={{display: "flex",flexDirection: 'row'}}>
                        <Button variant="none" className="calendar-toolbar-today-button" onClick={goToToday}>
                            Today
                        </Button>
                        <IconButton className="calendar-toolbar-back-button" onClick={goToBack}>
                            <ChevronLeftIcon />
                        </IconButton>
                        <IconButton className="calendar-toolbar-back-button" sx={{marginLeft: "-7px"}} onClick={goToNext}>
                            <ChevronRightIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid container className="calendar-toolbar-grid-right" spacing={1}>
                    <Grid item xs={12} md={6} lg={7} xl={7.5} sx={{ pb: '8px' }} className="calendar-toolbar-grid-right">
                        <FormControlLabel control={<AntSwitch inputProps={{ 'aria-label': 'ant design' }} onClick={handleFilter} checked={filterToggle} />} label={<Typography sx={{display:"flex"}}className="calendar-toolbar-filter-title">View only&nbsp;<p>my</p>&nbsp;events</Typography>} />
                    </Grid>
                    <Grid item xs={12} md={6} lg={5} xl={4.5}>
                        <ButtonGroup variant="none" aria-label="outlined button group" className="calendar-toolbar-group-button">
                            <Button className="calendar-toolbar-button-group-month" sx={{ backgroundColor: view === 'month' ? 'rgba(66,130,226,0.12)' : '#FFFFFF' }} onClick={goToMonthView}>
                                Month
                            </Button>
                            <Button className="calendar-toolbar-button-group-week" sx={{ backgroundColor: view === 'week' ? 'rgba(66,130,226,0.12)' : '#FFFFFF' }} onClick={goToWeekView}>
                                Week
                            </Button>
                            <Button className="calendar-toolbar-button-group-day" sx={{ backgroundColor: view === 'day' ? 'rgba(66,130,226,0.12)' : '#FFFFFF' }} onClick={goToDayView}>
                                Day
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CustomToolbar;
