import React from "react";
import PropTypes from "prop-types";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { GetCurrentResolution } from "../../utils/services";
import { tableHead, UpcommingtableHead } from "../../utils/mockdata";
import Sortswitch from "../../../assets/images/Sortswitch.png";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";


const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort, type } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const tableData = type === "dashboard" ? UpcommingtableHead : tableHead

  return (
    <TableHead className="inspection-list-header">
      <TableRow>
        { tableData.map((headCell) => (
          <td
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            className="inspection-table-head"
            style={{ justifyContent: (type === "dashboard" && headCell.label === 'INSPECTOR') ? 'left'  : 'center'}}
          >
            <TableSortLabel
              IconComponent={() =>
                headCell.label !== "" && headCell.label !== "ACTIONS" && headCell.label !== "CANCEL" ? (
                  <img
                    src={Sortswitch}
                    alt="sort-icon"
                    className="sort-switch"
                  />
                ) : (
                  ""
                )
              }
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{
                marginTop: GetCurrentResolution() === "mobile" && "21px",
                marginBottom: "10.5px",
                flexDirection: "row-reverse",
                fontSize: "12px",
               
              }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </td>
        ))}
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default EnhancedTableHead;
