import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { onKeyPressed } from '../../utils/services';

const SelectedService = (props) => {
    const { selectedServices, addInput, addPrice, editingData, handleformSubmit, errors, setAddedPrice, setAddedInput, showCustomAddInput, addCustomDiscount, showDiscountInput, addCustomCharge, rows, deleteService, clearInputField, editService, addChargeOrDiscount } = props;
    return (
        <>
            {selectedServices?.length !== 0 ? (
                <>
                    <Grid item xs={12} sm={6} className="modal-inner-sub-title">
                        <Typography>Charges</Typography>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableBody>
                                    {rows.map((row, key) => (
                                        <TableRow
                                            key={key}
                                            sx={{
                                                '&:last-child td, &:last-child th': {
                                                    border: 1,
                                                },
                                            }}>
                                            <TableCell key={key} sx={{ border: 1 }} align="left">
                                                {row.type === 'discount' || row.type === 'charge' ? (
                                                    <>
                                                        {row.title}
                                                        <div className="services-action-wrapper">
                                                            <span
                                                                className="edit-service"
                                                                style={{ pointerEvents: ((showDiscountInput && showCustomAddInput) || showCustomAddInput || showDiscountInput) && 'none', opacity: ((showDiscountInput && showCustomAddInput) || showCustomAddInput || showDiscountInput) && '0.25' }}
                                                                onClick={() => {
                                                                    editService(key, row.type);
                                                                    setAddedInput(row.title);
                                                                    setAddedPrice(row.rate);
                                                                }}>
                                                                <EditIcon />
                                                                <span>Edit</span>{' '}
                                                            </span>
                                                            <span
                                                                className="delete-service"
                                                                style={{ pointerEvents: ((showDiscountInput && showCustomAddInput) || showCustomAddInput || showDiscountInput) && 'none', opacity: ((showDiscountInput && showCustomAddInput) || showCustomAddInput || showDiscountInput) && '0.25' }}
                                                                onClick={() => {
                                                                    deleteService(key, row.type);
                                                                }}>
                                                                <DeleteIcon />
                                                                <span>Delete</span>
                                                            </span>
                                                        </div>
                                                    </>
                                                ) : (
                                                    row.title
                                                )}
                                            </TableCell>
                                            <TableCell sx={{ border: 1 }} align="left">
                                                {/* {Math.sign(row.rate) !== -1 ?"":"-"}${Math.abs(row.rate)} */}${row.rate?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item xs={12} md={6}></Grid>
                    <Grid item xs={12} md={12} className="add-action pl-15">
                        <Typography style={{ opacity: !showDiscountInput ? '1.00' : '0.25', pointerEvents: showDiscountInput && 'none' }} onClick={addCustomCharge}>
                            Add custom charge {showCustomAddInput ? <RemoveCircleIcon style={{ color: '#E30303' }} /> : <AddCircleIcon />}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} className="add-action pl-15">
                        <Typography style={{ opacity: !showCustomAddInput ? '1.00' : '0.25', pointerEvents: showCustomAddInput && 'none' }} onClick={addCustomDiscount}>
                            Add discount {showDiscountInput ? <RemoveCircleIcon style={{ color: '#E30303' }} /> : <AddCircleIcon />}
                        </Typography>
                    </Grid>

                    {showDiscountInput || showCustomAddInput ? (
                        <>
                            <Grid container sx={{  marginBottom: '50px',minHeight:'100px' }}>
                                <Grid item xs={4} md={4} className="pl-15" style={{ marginRight: '10px' }}>
                                    <FormControl variant="standard" style={{ width: '100%' }}>
                                        <label className="input-form-label" htmlFor=" Inspection ID" id="demo-simple-select-label">
                                            Description
                                        </label>
                                        <TextField className="input-textfield input-value" id="desc" error={errors.descError!==undefined}  size="small" variant="outlined" placeholder="Type here" defaultValue={editingData?.title} type="text" onChange={addInput} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2} md={2} style={{ marginRight: '10px' }}>
                                    <FormControl variant="standard" style={{ width: '100%' }}>
                                        <label className="input-form-label" htmlFor=" Inspection ID" id="demo-simple-select-label">
                                            {showDiscountInput ? 'Discount' : 'Charge'}
                                        </label>
                                        <TextField
                                            className="input-textfield input-price"
                                            size="small"
                                            variant="outlined"
                                            placeholder=""
                                            id="price"
                                            onKeyDown={onKeyPressed}
                                            error={errors.priceError!==undefined}
                                            type="number"
                                            onInput = {(e) =>{
                                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,5)
                                            }}
                                            // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            onChange={addPrice}
                                            defaultValue={editingData?.rate}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">{
                                                    // showDiscountInput ? '$ ' :
                                                     '$ '}</InputAdornment>,
                                              
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={5} md={5} style={{ marginBottom: '2px',display:'flex',alignItems:'center' }}>
                                    <Button className="add-btn" variant="contained" onClick={(event) => handleformSubmit(event, 'ADD')}>
                                        <Typography sx={{textTransform: "none"}}>
                                            {editingData.length !== 0 ? (showDiscountInput ? `Update discount` : 'Update price') : showDiscountInput ? 'Add discount' : 'Add charge'}
                                            {/* {showDiscountInput ? "Add discount" : "Add Price"} */}
                                        </Typography>
                                    </Button>
                                    {/* <Button className="clear-btn" variant="contained" color="success" onClick={clearInputField}>
                                        <Typography>Clear</Typography>
                                    </Button> */}
                                </Grid>
                            </Grid>
                        </>
                    ) : (
                        ''
                    )}
                </>
            ) : (
                ''
            )}
        </>
    );
};

export default SelectedService;
