import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import { apiCall } from "../Services/action";
import CloseIcon from "@mui/icons-material/Close";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button, Divider, Modal, Box, Grid } from "@mui/material";
const Checkout = ({ proratedCheckoutAmount, upgradePlanModal, closeUpgradeModal, details, setRefresh, refresh, additionalLicense, setUpgradePlanModal }) => {
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const closeConfirmModal = () => setConfirmModal(false);
  var discountPercentage = (parseInt(details?.discountPrice) / parseInt(details?.subscriptionPrice)) * 100;
  var Total = (parseFloat(details?.subscriptionPrice) + parseFloat(details?.additionalLicensePrice) * parseFloat(additionalLicense) + parseFloat(details?.estimatedTaxes) - parseFloat(details?.discountPrice)).toFixed(2);

  //add license api
  const upgradePlan = () => {
    setLoading(true);
    setUpgradePlanModal(false);
    const formdata = { company_id: sessionStorage.getItem("company_id"), user_id: sessionStorage.getItem("user_id"), role_id: sessionStorage.getItem("role_id"), product_id: details?.subscriptionID };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          setLoading(false);
          setRefresh(!refresh);
          setUpgradePlanModal(false);
          toast.success(message);
          setConfirmModal(true);
        } else {
          setLoading(false);
          toast.error(message);
          console.log("else ", message);
        }
      },
      formdata,
      "UPGRADE_PLAN"
    );
  };

  return (
    <>
      <Modal open={upgradePlanModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className="inspection-filter-modal" sx={{ maxWidth: "788px !important", minHeight: "435px !important", top: "30% !important" }}>
          <Grid container>
            <Grid item xs={6}>
              <p className="upgrade-modal-title">Checkout</p>
            </Grid>
            <Grid item xs={6}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={closeUpgradeModal} />
            </Grid>
            <Grid item xs={12} className="license-modal-container">
              <div className="license-modal-content">
                <div style={{ flex: 5 }}>
                  <p className="licenses-addtional-details">Type of Subscription for Palm Tech Inspector (1 user)</p>
                  <p className="licenses-addtional-details">Price per additional license per {details?.subscriptionTitle === 'Yearly plan' || details?.subscriptionTitle === 'Yearly' ? "year" : "month"}</p>
                  <p className="licenses-addtional-details">Additional licenses</p>
                  {discountPercentage !== 0 && !isNaN(discountPercentage) ? <p className="licenses-addtional-details">Discount</p> : ""}
                  <p className="licenses-addtional-details">Estimated taxes</p>
                </div>
                <div style={{ flex: 1 }}>
                  <p className="licenses-addtional-details">{details?.subscriptionTitle}</p>
                  <p className="licenses-addtional-details">${details?.additionalLicensePrice}</p>
                  <p className="licenses-addtional-details">{additionalLicense}</p>
                  {discountPercentage !== 0 && !isNaN(discountPercentage) ? <p className="discount_value">{discountPercentage?.toFixed(0)}% OFF</p> : ""}
                  <p className="licenses-addtional-details">${details?.estimatedTaxes}</p>
                </div>
              </div>
              <Divider className="license-modal-divider" />
              <div className="total-container">
                <div style={{ display: "flex", justfyContent: "space-between" }}>
                  <div style={{ flex: 5 }}>
                    <div>
                      <p style={{ display: "inline", margin: "5px 0px 5px 0px" }} className="plan-details-card-container2-datas-bold">
                        Total due today{" "}
                      </p>
                      {/* <p style={{ display: "inline" }} className="plan-details-card-addtional">
                    (new licences added)
                  </p> */}
                    </div>
                    <p className="billingnews" style={{ marginTop: "5px", marginBottom: "10px", color: "#888888" }}>
                      The total charge for additional licenses is prorated based on the current plan's remaining time.
                    </p>
                  </div>
                  <div style={{ flex: 1 }}>
                    <p className="license-amount">${proratedCheckoutAmount?.toFixed(2)}</p>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "7px" }}>
                <p style={{ display: "inline" }} className="billingnews">
                  ${details?.subscriptionPrice} base subscription + ${details?.additionalLicensePrice} x {additionalLicense} licences + ${details?.estimatedTaxes} taxes = <p style={{ display: "inline", fontWeight: 700 }}>${parseInt(details?.subscriptionPrice) + parseInt(details?.additionalLicensePrice) * parseInt(additionalLicense) + parseInt(details?.estimatedTaxes)}</p> will be charged on the next billing period.
                </p>
              </div>
              <Grid item xs={12} sx={{ mt: "30px" }}>
                <LoadingButton loading={loading} onClick={upgradePlan} className="upgrade-confirm-checkout">
                  Confirm
                </LoadingButton>
                <Button className="upgrade-plans-button-cancel" onClick={closeUpgradeModal}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={confirmModal} onClose={closeConfirmModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className="inspection-filter-modal" sx={{ maxWidth: "655px !important", minHeight: "435px !important", top: "30% !important" }}>
          <Grid container sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
            <CheckCircleIcon className="ic-check-circle" />
            <Grid item xs={12}>
              <p className="purchase-complete">Purchase complete!</p>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
              <p className="your-purchase-of-2-a">
                Your purchase of Palmtech {details?.subscriptionTitle} + {additionalLicense} additional licenses is complete. Thank you for using Palmtech!
              </p>{" "}
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
              <Button sx={{ mt: 5 }} className="upgrade-confirm-checkout" onClick={closeConfirmModal}>
                Finish
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={loading} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className="inspection-filter-modal" sx={{ maxWidth: "655px !important", minHeight: "402px !important", top: "35% !important" }}>
          <Grid container sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
            <AutorenewIcon className="ic_auto_renew" />
            <Grid item xs={12}>
              <p className="purchase-complete">Processing order</p>
            </Grid>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
              <p className="your-purchase-of-2-a">We are processing your payment, please wait until order is confirmed. Do not close the page or remove access from internet.</p>{" "}
            </Grid>
            {/* <Grid item xs={12} sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
              <Button sx={{ mt: 5 }} className="upgrade-confirm-checkout" onClick={closeConfirmModal}>
                Finish
              </Button>
            </Grid> */}
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default Checkout;