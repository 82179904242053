import React, { useEffect, useState } from "react";
// import { useFormContext, Controller } from "react-hook-form";
// mui imports
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import { onKeyPressed } from "../../utils/services";

const PropertyDetails = (props) => {
  const {
    address,
    handleAddress,
    addressValid,
    cityValid,
    stateValid,
    zipValid,
  } = props;

  let is_from_us = sessionStorage.getItem("is_from_us");

  const AddressFields = [
    {
      label: "Address*",
      id: "PropertyAddressId",
      value: address.location_address,
      name: "location_address",
      type: "text",
      width: 6,
      error:
        addressValid === null
          ? false
          : addressValid === undefined
          ? false
          : true,
      errorText: addressValid,
    },
    {
      label: "Address Line Two",
      id: "PropertyAddressId1",
      value: address.location_address_2,
      name: "location_address_2",
      type: "text",
      width: 6,
      error: false,
      errorText: "",
    },
    {
      label: "City*",
      id: "PropertyCityId",
      value: address.location_city,
      name: "location_city",
      type: "text",
      width: 4,
      error:
        cityValid === null ? false : cityValid === undefined ? false : true,
      errorText: cityValid,
    },
    {
      label: Number(is_from_us) === 1 ? "State*" : "Province*",
      id: "PropertyStateId",
      value: address.location_state,
      name: "location_state",
      type: "text",
      width: 4,
      error:
        stateValid === null ? false : stateValid === undefined ? false : true,
      errorText: stateValid,
    },
    {
      label: Number(is_from_us) === 1 ? "Zip*" : "Postal code*",
      id: "PropertyZipId",
      value: address.location_zip,
      name: "location_zip",
      type: "text,number",
      width: 4,
      error: zipValid === null ? false : zipValid === undefined ? false : true,
      errorText: zipValid,
    },
  ];

  return (
    <>
      <Grid container className="input-controll">
        {AddressFields.map((fields, i) => {
          return (
            <Grid item xs={12} sm={fields.width}>
              <FormControl variant="standard" className="forms-control">
                <label
                  className="input-form-label"
                  // error
                  id="demo-simple-select-label"
                >
                  {fields.label}
                </label>
                <TextField
                  id={fields.id}
                  className="input-textfield"
                  size="small"
                  variant="outlined"
                  inputProps={{
                    maxLength:
                      fields.name === "location_state"
                        ? 2
                        : fields.name === "location_zip" && 7,
                  }}
                  // placeholder="Address"
                  onKeyDown={fields.type === "number" && onKeyPressed}
                  type={fields.type}
                  onChange={handleAddress}
                  value={
                    fields.name === "location_state"
                      ? (fields.value !== null
                          ? fields.value
                          : ""
                        ).toUpperCase()
                      : fields.value
                  }
                  name={fields.name}
                  // {...field}
                  error={fields.error}
                  helperText={fields.errorText}
                />
              </FormControl>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default PropertyDetails;
