import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AntSwitch from "../../common/antSwitch/AntSwitch";
import Preloader from "../../helpers/Preloader";
import { apiCall } from "../../utils/action";

function ISGSettings() {
  const [state, setState] = useState({
    username: null,
    password: null,
    showPassword: false,
    loading: false,
    isgEnable: false,
  });

  const { username, password, showPassword, loading, isgEnable } = state;

  const uuId = sessionStorage.getItem("user_id");
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");

  const getISGInfo = () => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setState((prevState) => ({
            ...prevState,
            loading: false,
            username: data?.isg_credentials?.username || null,
            password: data?.isg_credentials?.password || null,
            isgEnable:
              data?.isg_credentials?.username &&
              data?.isg_credentials?.send_signal_to_isg === 1
                ? true
                : data?.isg_credentials?.username &&
                  (data?.isg_credentials?.send_signal_to_isg === 0 ||
                    data?.isg_credentials?.send_signal_to_isg === null)
                ? false
                : data?.isg_credentials?.send_signal_to_isg,
          }));
        } else {
          setState((prevState) => ({ ...prevState, loading: false }));
          toast.error(message);
        }
      },
      formdata,
      "GET_COMPANY_INFO"
    );
  };

  const handleDone = async () => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      const formdata = {
        company_id: companyId,
        user_id: uuId,
        role_id: roleId,
        username: username,
        password: password,
      };
      apiCall(
        (response) => {
          const { data, message, success } = response.data;
          if (success) {
            setState((prevState) => ({
              ...prevState,
              loading: false,
              username: data?.username || null,
              password: data?.password || null,
              isgEnable: false,
            }));
            toast.success(message);
          } else {
            setState((prevState) => ({ ...prevState, loading: false }));
            toast.error(message);
          }
        },
        formdata,
        isgEnable === undefined
          ? "STORE_ISG_CREDENTIALS"
          : "EDIT_ISG_CREDENTIALS"
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getISGInfo();
  }, [isgEnable]);

  const defaultSwitchValue = (toggleStatus) => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
        isgEnable: toggleStatus,
      }));
      let status;
      if (toggleStatus === null) status = isgEnable;
      else status = toggleStatus;
      const formdata = {
        company_id: companyId,
        user_id: uuId,
        role_id: roleId,
        send_signal_to_isg: status,
      };
      apiCall(
        (response) => {
          const { data, message, success } = response.data;
          if (success) {
            setState((prevState) => ({
              ...prevState,
              loading: false,
              isgEnable: data?.send_signal_to_isg,
            }));
            toast.success(message);
          } else {
            setState((prevState) => ({ ...prevState, loading: false }));
            toast.error(message);
          }
        },
        formdata,
        "EDIT_ISG_CREDENTIALS"
      );
    } catch (error) {
      console.log(error);
    }
  };

  console.log("username", username, password);

  return (
    <>
      <Preloader showPreloader={loading} />
      {isgEnable !== undefined ? (
        <Grid
          container
          display={"flex"}
          justifyContent={"flex-start"}
          mt={1}
          mb={4}
          sx={{ pl: { xs: "20px", sm: "20px", md: "80px", lg: "80px" } }}
        >
          <Grid
            item
            xs={12}
            display={"flex"}
            mb={1}
            gap={1}
            alignItems={"center"}
          >
            <AntSwitch
              defaultValue={isgEnable}
              id="payment_switch_btn"
              defaultSwitchValue={defaultSwitchValue}
            />
            <Typography className="payment__toggle__status">
              {`Integration is ${isgEnable ? "enabled" : "disabled"}`}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      <div style={{ width: "100%", display: "flex", justifyContent: "start" }}>
        <Card
          elevation={1}
          sx={{
            width: "610px",
            ml: { xs: "0px", sm: "20px", md: "80px", lg: "80px" },
            mr: { xs: "0px", sm: "20px", md: "80px", lg: "80px" },
          }}
        >
          <Grid container display={"flex"} justifyContent={"center"} p={4}>
            <Grid item xs={12} mb={1}>
              <Typography fontSize={20} fontWeight={500}>
                {"Inspector Services Group Credentials"}
              </Typography>
            </Grid>
            <Grid item xs={12} mb={3}>
              <Typography fontSize={14}>
                {
                  "Set up your integration with Inspector Services Group by entering the username and password provided by ISG."
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                className="forms-control"
              >
                <label className="input-form-label" htmlFor="Username">
                  USERNAME
                </label>
                <TextField
                  className="input-password"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    autocomplete: "new-username",
                    form: { autocomplete: "off" },
                  }}
                  disabled={isgEnable === false || isgEnable === 0}
                  value={username || ""}
                  placeholder="Enter Username"
                  onChange={(event) =>
                    setState((prevState) => ({
                      ...prevState,
                      username: event.target.value,
                    }))
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                className="forms-control"
              >
                <label className="input-form-label" htmlFor="Password">
                  PASSWORD
                </label>
                <TextField
                  type={showPassword ? "text" : "password"}
                  className="input-password"
                  variant="outlined"
                  size="small"
                  inputProps={{
                    autocomplete: "new-password",
                    form: { autocomplete: "off" },
                  }}
                  disabled={isgEnable === false || isgEnable === 0}
                  value={password || ""}
                  placeholder="Enter Password"
                  onChange={(event) =>
                    setState((prevState) => ({
                      ...prevState,
                      password: event.target.value,
                    }))
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          disabled={isgEnable === false || isgEnable === 0}
                          sx={{ mr: "-9px" }}
                          onClick={() =>
                            setState((prevState) => ({
                              ...prevState,
                              showPassword: !showPassword,
                            }))
                          }
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"center"}>
              <LoadingButton
                className="login_button"
                sx={{
                  height: { xs: 42, md: 34, lg: 34 },
                  width: { xs: 180, md: 119, lg: 119 },
                  fontSize: { xs: 16, md: 13, lg: 13 },
                }}
                size="small"
                variant="contained"
                loading={loading}
                disabled={isgEnable === false || isgEnable === 0}
                onClick={() => handleDone()}
              >
                Done
              </LoadingButton>
            </Grid>
          </Grid>
        </Card>
      </div>
    </>
  );
}

export default ISGSettings;
