import useMediaQuery from "@mui/material/useMediaQuery";
import { colors } from "../config/app-constants";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CheckIcon from "@mui/icons-material/Check";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import moment from "moment";
import Compressor from "compressorjs";

export function GetCurrentResolution() {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isTablet = useMediaQuery("(max-width:900px)");
  const isLaptop = useMediaQuery("(max-width:1200px)");
  const isDesktop = useMediaQuery("(max-width:1536px)");

  if (isMobile) {
    return "mobile";
  } else if (isTablet) {
    return "tablet";
  } else if (isLaptop) {
    return "laptop";
  } else if (isDesktop) {
    return "desktop";
  }
}

// for table component

export function descendingComparator(a, b, orderBy) {
  if (
    typeof a[orderBy] === "string" ||
    (typeof b[orderBy] === "string" && orderBy !== "phone")
  ) {
    if (a[orderBy] === null) return 1;
    if (b[orderBy] === null) return -1;
    if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
      return -1;
    }
    if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
      return 1;
    }
    return 0;
  } else {
    if (a[orderBy] === null) return 1;
    if (b[orderBy] === null) return -1;
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    // console.log("comp",order ,a[0],b[0])
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export function customSort(data, order, orderBy) {
  return [...data].sort((a, b) => {
    const textA = a[orderBy];
    const textB = b[orderBy];
    const isNumericA = !isNaN(textA);
    const isNumericB = !isNaN(textB);
    if (isNumericA && isNumericB) {
      const numA = parseInt(textA);
      const numB = parseInt(textB);
      return order === "asc" ? numA - numB : numB - numA;
    }
    if (isNumericA && !isNumericB) {
      return order === "asc" ? -1 : 1;
    } else if (!isNumericA && isNumericB) {
      return order === "asc" ? 1 : -1;
    }
    return order === "asc"
      ? textA?.localeCompare(textB)
      : textB?.localeCompare(textA);
  });
}

export function splitDate(date) {
  var result = [];
  var counter = 0;
  for (var i = date.length - 1; i >= 0; i -= 1) {
    result[counter] = date[i];
    counter += 1;
  }
  var date = result[1].replace(/-/g, "/");
  const tConvert = (time) => {
    // Check correct time format and split into components
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
  };
  var time = tConvert(result[0].slice(0, -3));
  return { date, time };
}

export function GetSumArray(ary) {
  return ary.reduce(function (sum, value) {
    return sum + value;
  }, 0);
}

// array sort
export function compareStrings(a, b) {
  // Assuming you want case-insensitive comparison
  // a = a.toLowerCase();
  // b = b.toLowerCase();

  // return a < b ? -1 : a > b ? 1 : 0;
  if (a?.toLowerCase() !== b?.toLowerCase()) {
    a = a?.toLowerCase();
    b = b?.toLowerCase();
  }
  return a > b ? 1 : a < b ? -1 : 0;
}

export function onKeyPressed(evt) {
  if (
    evt.key === "e" ||
    evt.key === "E" ||
    evt.key === "." ||
    evt.key === "+" ||
    evt.key === "-"
  ) {
    evt.preventDefault();
  }
}

export function formatPhoneNumber(value) {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}

// modifiled phonenumber values formater only with dashes and avoiding brackets
export function formatPhoneNumberDashes(value) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }
  return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}

export function formatZip(value) {
  const newValue = value.toUpperCase().replace(/[^\d]/g, "");
  const length = value.length;
  // if (!value) return value;
  // const zip = value.replace(/[^\d]/g, '');
  // const zipLength = zip.length;
  // if (zipLength < 7) {
  //     return zip.slice(0, 6);
  // }
  // return zip.slice(0, 6);
  return newValue.slice(0, 6);
}
// check for special characters
function containsSpecialChars(str) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}
//   check for white spaces
function containsWhitespace(str) {
  return /\s/.test(str);
}

export function formatState(value) {
  const newValue = value.toUpperCase();
  const length = value.length;
  if (
    newValue === "1" ||
    newValue === "2" ||
    newValue === "3" ||
    newValue === "4" ||
    newValue === "5" ||
    newValue === "6" ||
    newValue === "7" ||
    newValue === "8" ||
    newValue === "9" ||
    newValue === "0" ||
    containsSpecialChars(value) ||
    containsWhitespace(value)
  ) {
    newValue.preventDefault();
  } else if (length <= 2) {
    return newValue;
  } else value.preventDefault();
}

export function GetTabWidth(length) {
  if (length === 1) {
    return "100% !important";
  } else if (length === 2) {
    return "50% !important";
  } else if (length === 3) {
    return "33.33%";
  } else if (length >= 4) {
    return "25%";
  }
}

export function InspStatusBg(status) {
  if (status === "Scheduled") {
    return colors["SCHEDULED_BG"];
  }
  if (status === "In-Progress") {
    return colors["IN_PROGRESS_BG"];
  }
  if (status === "Published") {
    return colors["PUBLISHED_BG"];
  }
  if (status === "Cancelled") {
    return colors["CANCELLED_BG"];
  }
  if (status === "Complete") {
    return colors["COMPLETE_BG"];
  }
  if (status === "Draft") {
    return colors["DRAFT_BG"];
  }
  if (status === null) {
    return colors["SCHEDULED_BG"];
  }
}

export function InspStatusColor(status) {
  if (status === "Scheduled") {
    return colors["SCHEDULED"];
  }
  if (status === "Published") {
    return colors["PUBLISHED"];
  }
  if (status === "In-Progress") {
    return colors["IN_PROGRESS"];
  }
  if (status === "Cancelled") {
    return colors["CANCELLED"];
  }
  if (status === "Complete") {
    return colors["COMPLETE"];
  }
  if (status === "Draft") {
    return colors["DRAFT"];
  }
  if (status === null) {
    return colors["SCHEDULED"];
  }
}

export const summaryData = [
  {
    id: 0,
    title: "inspections scheduled",
    icon: <CalendarMonthIcon className="icon" />,
    color: colors["INSPECTION_SCHEDULED"],
  },
  {
    id: 1,
    title: "inspections completed",
    icon: <CheckIcon className="icon" />,
    color: colors["INSPECTION_COMPLETED"],
  },
  {
    id: 2,
    title: "collected payments",
    icon: <AttachMoneyRoundedIcon className="icon" />,
    color: colors["COLLECTED_PAYMENTS"],
  },
  {
    id: 3,
    title: "fees owed",
    icon: <AttachMoneyRoundedIcon className="icon" />,
    color: colors["FEES_OWED"],
  },
];

export const ConvertDate = (date) => {
  return moment(date).format("YYYY/MM/DD");
};

export function formatCost(value) {
  let result;
  let parts = value.toString().split(".");
  let whole_num = parts[0].replace(/\D/g, ""),
    dec = parts[1];
  // use the below commented number for numeric calculations(num without commas)
  let calc_num = Number(dec !== undefined ? whole_num + "." + dec : whole_num);
  result = new Intl.NumberFormat("en-EN").format(whole_num);
  result = dec !== undefined ? result + "." + dec : result;
  return { formatted: result, calculation: calc_num };
}

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
export const checkForInvalidImages = (htmlContent) => {
  const imgRegex = /<img.*?src=['"](.*?)['"].*?>/g;
  const imgSrcMatches = htmlContent.match(imgRegex) || [];
  let hasUnsupportedImages = false;
  let hasCloudImages = false;
  imgSrcMatches.forEach((imgSrcMatch) => {
    const srcMatch = /src=['"](.*?)['"]/g.exec(imgSrcMatch);
    if (srcMatch && srcMatch[1]) {
      const imgSrc = srcMatch[1];
      const isBase64 = imgSrc.startsWith('data:image/'); 

      if (isBase64) {
        const extension = imgSrc.split(';')[0].split('/')[1].toLowerCase();
        if (!['jpg', 'jpeg', 'png'].includes(extension)) {
          hasUnsupportedImages = true;
        }
      } else {
        hasCloudImages = true;
      }
    }
  });

  return { hasUnsupportedImages, hasCloudImages };
};
export const timestamp = () => {
  const now = new Date();
      const formattedTimestamp = now
        .toISOString()
        .replace('T', ' ')
        .replace(/\.\d+Z$/, '');
    return formattedTimestamp
};

export const timestampWithMinuteChange = () => {
  const currentTimestamp = timestamp();
  const currentTimestampDate = new Date(currentTimestamp); 
  currentTimestampDate.setMinutes(currentTimestampDate.getMinutes() - 5); 
  const result = currentTimestampDate.toISOString().replace('T', ' ').replace(/\.\d+Z$/, '');
  return result;
};

export const getImageDimensions = (file) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const imgWidth = img.width;
      const imgHeight = img.height;
      resolve({ imgWidth, imgHeight });
    };
    img.src = URL.createObjectURL(file);
  });
};

export const resizeImage = (file, width, height, quality) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      width,
      height,
      quality, // Set the image compression quality
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
};