import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import SelectedService from "./SelectedService";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";

const Services = (props) => {
  const {
    servicesValid,
    templateValid,
    servicesLists,
    selectedTemplate,
    editValues,
    Type,
    editRefresh,
    IsCustomCharges,
    ServicesChange,
    selectedServices,
    agreeValid,
    agreementChange,
    isagreementEnabled,
    TemplateChange,
    errors,
    setErrors,
    dataIsCorrect,
    setDataIsCorrect,
    showCustomAddInput,
    showDiscountInput,
    setShowCustomAddInput,
    setShowDiscountInput,
    handleTotalPrice,
    handleOrders,
    allTemplateList,
    agreementsList,
    agreementChangeValue,
  } = props;

  const [addedServices, setAddedServices] = useState(
    []
  ); /*selected charges* ed[{}]*/
  const [editingData, setEditingData] = useState([]); /*selected charges* ed{}*/
  const [addedInput, setAddedInput] = useState("");
  const [addedPrice, setAddedPrice] = useState(0);
  const [editingIndex, setEditingIndex] = useState("");
  const [isDesc, setIsDesc] = useState(false);
  const [isPrice, setIsPrice] = useState(false);
  const [render, setRender] = useState(0);

  const addInput = (evt) => {
    setIsDesc(true);
    setAddedInput(evt.target.value);
  };

  const addPrice = (evt) => {
    setIsPrice(true);
    setAddedPrice(parseInt(evt.target.value));
  };
  IsCustomCharges(
    (showCustomAddInput === true && showCustomAddInput) ||
      (showDiscountInput === true && showDiscountInput)
  );

  //validations function
  const validation = () => {
    let errors = {};
    if (showCustomAddInput || showDiscountInput) {
      if (!addedInput) {
        errors.descError = "please enter";
      }
      if (!addedPrice) {
        errors.priceError = "please enter";
      }
      if (addedInput.trim().length === 0) {
        errors.descError = "please enter";
      }
    }
    return errors;
  };

  let updatedServices = [...selectedServices, ...addedServices];
  let rows = updatedServices?.map((service) => service);
  let totalRate = rows?.reduce(
    (previousVal, currentVal) => previousVal + currentVal.rate,
    0
  );

  const resultCharges = [];
  const resultDiscount = [];

  addedServices.forEach((elm) => {
    if (elm.type === "charge") {
      resultCharges.push({
        id: elm?.id,
        description: elm.title ?? "",
        price: elm.rate,
      });
    } else if (elm.type === "discount") {
      resultDiscount.push({
        id: elm?.id,
        description: elm.title ?? "",
        price: elm.rate,
      });
    }
  });

  const handleformSubmit = (event, type) => {
    event.preventDefault();
    setErrors(validation());
    setDataIsCorrect(true);
    validation();
  };
  useEffect(() => {
    if (Object.keys(errors).length === 0 && dataIsCorrect) {
      if (showDiscountInput || showCustomAddInput) {
        if (addedPrice != 0 && addedPrice != "") {
          addChargeOrDiscount(showCustomAddInput, showDiscountInput);
        }
      }
    }
  }, [errors]);
  rows.push({
    title: "Total",
    rate: totalRate.toLocaleString(),
    type: "total",
  });
  handleTotalPrice(totalRate);

  const addCustomCharge = (index, edit) => {
    errors.priceError = undefined;
    errors.descError = undefined;
    if (!edit) setEditingData([]);
    setShowCustomAddInput(!showCustomAddInput);
    setShowDiscountInput(false);
    setAddedInput("");
    setAddedPrice("");
  };
  const addCustomDiscount = (index, edit) => {
    errors.priceError = undefined;
    errors.descError = undefined;
    if (!edit) setEditingData([]);
    setShowDiscountInput(!showDiscountInput);
    setShowCustomAddInput(false);
    setAddedInput("");
    setAddedPrice("");
  };

  const editService = (index, type) => {
    setEditingData(addedServices[index - selectedServices.length]);
    setEditingIndex(index);
    addedServices.splice(index - selectedServices.length, 1);
    const resultChargesOnEdit = [];
    const resultDiscountOnEdit = [];

    addedServices.forEach((elm) => {
      if (elm.type === "charge") {
        resultChargesOnEdit.push({
          id: elm?.id,
          description: elm.title ?? "",
          price: elm.rate,
        });
      } else if (elm.type === "discount") {
        resultDiscountOnEdit.push({
          id: elm?.id,
          description: elm.title ?? "",
          price: elm.rate,
        });
      }
    });
    handleOrders(resultChargesOnEdit, resultDiscountOnEdit);
    if (type === "discount") addCustomDiscount(index, true);
    else addCustomCharge(index, true);
  };
  const deleteService = (index) => {
    setEditingData(addedServices[index - selectedServices.length]);
    addedServices.splice(index - selectedServices.length, 1);
    setRender(render + 1);
  };

  // clear input filed function
  const clearInputField = () => {
    document.querySelector(".input-value input").value = "";
    document.querySelector(".input-price input").value = "";
    setEditingData([]);
    setIsPrice(true);
    setIsDesc(true);
    setAddedInput("");
    setAddedPrice("");
  };

  let addedTempData = [];
  let concatedData = [];
  const addChargeOrDiscount = (showCustomAddInput, showDiscountInput) => {
    handleOrders(resultCharges, resultDiscount);
    setShowCustomAddInput(false);
    setShowDiscountInput(false);
    let price;
    let type;
    if (showDiscountInput) {
      price = Math.sign(addedPrice) !== -1 ? addedPrice * -1 : addedPrice;
      type = "discount";
    } else if (showCustomAddInput) {
      price = addedPrice;
      type = "charge";
    }
    addedTempData.push({
      id: editingData?.id !== undefined ? editingData?.id : null,
      title: !isDesc ? editingData?.title : addedInput,
      rate: !isPrice ? editingData?.rate : price,
      type,
    });
    if (editingData.length === 0)
      concatedData = [...addedServices, ...addedTempData];
    else {
      concatedData = [...addedServices];
      concatedData.splice(
        editingIndex - selectedServices.length,
        0,
        ...addedTempData
      );
    }

    setAddedServices(concatedData);
    setIsDesc(false);
    setIsPrice(false);
    setAddedInput("");
    setAddedPrice("");
    setRender(render + 1);
  };
  useEffect(() => {
    if (render !== 0) {
      handleOrders(resultCharges, resultDiscount);
    }
  }, [render]);

  // console.log(editValues)
  const chargesTotal =
    editValues?.ordercharge.length > 0
      ? editValues?.ordercharge.map((charge) => {
          return {
            id: charge.id,
            title: charge.description,
            rate: charge.price,
            type: "charge",
          };
        })
      : [];

  const chargesDiscount =
    editValues?.orderdiscount.length > 0
      ? editValues?.orderdiscount?.map((discount) => {
          return {
            id: discount.id,
            title: discount.description,
            rate: discount.discount * -1,
            type: "discount",
          };
        })
      : [];

  const TotalChargeseAdded = [...chargesTotal, ...chargesDiscount];
  useEffect(() => {
    if (Type === "edit") {
      setAddedServices(TotalChargeseAdded);
      const resultChargesTemp = [];
      const resultDiscountTemp = [];

      TotalChargeseAdded.forEach((elm) => {
        if (elm.type === "charge") {
          resultChargesTemp.push({
            id: elm.id,
            description: elm.title ?? "",
            price: elm.rate,
          });
        } else if (elm.type === "discount") {
          resultDiscountTemp.push({
            id: elm.id,
            description: elm.title ?? "",
            price: elm.rate,
          });
        }
      });

      handleOrders(resultChargesTemp, resultDiscountTemp);
    }
  }, [editRefresh]);

  return (
    <>
      <Grid container className="input-controll">
        <Grid item xs={12} sm={12}>
          <label
            className="input-form-label-select pl-15"
            htmlFor="add-services"
            id="demo-simple-select-label"
          >
            Add Services
          </label>

          <Autocomplete
            className="forms-control custom-color"
            multiple
            size="small"
            id="tags-outlined"
            disabled={
              (Type === "edit" && editValues?.status === "In-Progress") ||
              editValues?.status === "Complete" ||
              editValues?.status === "Published"
            }
            options={servicesLists}
            value={selectedServices}
            getOptionLabel={(option) => option.title}
            filterSelectedOptions
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  deleteIcon={<CloseIcon />}
                  label={option.title}
                  {...getTagProps({ index })}
                />
              ))
            }
            onChange={(e, value) => {
              ServicesChange(value);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                // onChange={({ target }) => setSelectedServices(target.value)}
                // placeholder="Select Service"
                placeholder={selectedServices <= 0 ? "Select Service" : ""}
                error={servicesValid === 1 && true}
                helperText={servicesValid === 1 && "Please select the field"}
              />
            )}
          />
        </Grid>
        
        <Grid item xs={12} sm={12}>
          <label
            className="input-form-label-select pl-15"
            htmlFor="add-templates"
            id="demo-simple-select-label"
          >
            Add Templates
          </label>
          <Autocomplete
            className="forms-control custom-color"
            multiple
            size="small"
            id="tags-outlined"
            options={allTemplateList?.filter((listEl) => {
              return (
                selectedTemplate.filter((selectedEl) => {
                  return selectedEl.id == listEl.id;
                }).length == 0
              );
            })}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option?.is_pdf_template === 1 ? (
                  <PictureAsPdfOutlinedIcon
                    sx={{
                      marginRight: "6px",
                      color: "#3b3b3b",
                      opacity: "0.8",
                    }}
                  />
                ) : null}
                {option.name}
              </Box>
            )}
            value={selectedTemplate}
            filterSelectedOptions
            disabled={
              (Type === "edit" && editValues?.status === "In-Progress") ||
              editValues?.status === "Complete" ||
              editValues?.status === "Published"
            }
            onChange={(e, value) => TemplateChange(value)}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  icon={
                    option?.is_pdf_template === 1 ? (
                      <PictureAsPdfOutlinedIcon
                        sx={{
                          marginRight: "6px",
                          color: "#3b3b3b",
                          opacity: "0.8",
                        }}
                      />
                    ) : null
                  }
                  variant="outlined"
                  deleteIcon={<CloseIcon />}
                  label={option.name}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={
                  params && selectedTemplate <= 0 ? "Select Template" : ""
                }
                error={templateValid === 1 && true}
                helperText={templateValid === 1 && "Please select the field"}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <label
            className="input-form-label-select pl-15"
            htmlFor="add-templates"
            id="demo-simple-select-label"
          >
            Agreements associated with service
          </label>
          <Autocomplete
            disabled={
              isagreementEnabled === false ||
              (Type === "edit" && editValues?.status === "In-Progress") ||
              editValues?.status === "Complete" ||
              editValues?.status === "Published"
            }
            className="forms-control custom-color"
            multiple
            size="small"
            id="tags-outlined"
            filterSelectedOptions
            options={agreementsList?.filter((listEl) => {
              return (
                agreementChangeValue.filter((selectedEl) => {
                  return selectedEl.id == listEl.id;
                }).length == 0
              );
            })}
            value={agreementChangeValue}
            getOptionLabel={(option) => option.title}
            onChange={(e, value) => {
              agreementChange(value);
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  deleteIcon={<CloseIcon />}
                  label={option.title}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                disabled={agreeValid === 1}
                error={agreeValid === 1}
                helperText={agreeValid === 1 && "Please select the field"}
                placeholder={
                  params && agreementChangeValue <= 0
                    ? "Agreements associated with service"
                    : ""
                }
                {...params}
              />
            )}
          />
          {/* <Autocomplete
                        disabled
                        className="forms-control custom-color"
                        multiple
                        size="small"
                        id="tags-outlined"
                        options={agreementsList}
                        value={selectedAgreementListValue}
                        getOptionLabel={(option) => option.title}
                        // defaultValue={selectedAgreementListValue.map((value)=>value.title)}
                        filterSelectedOptions
                        renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" deleteIcon={<CloseIcon />} label={option.title} {...getTagProps({ index })} />)}
                        renderInput={(params) => <TextField disabled {...params}  placeholder={params ? 'Agreements associated with service' : ''} />}
                    /> */}
        </Grid>
        <SelectedService
          SelectedService={SelectedService}
          rows={rows}
          addInput={addInput}
          addPrice={addPrice}
          showDiscountInput={showDiscountInput}
          showCustomAddInput={showCustomAddInput}
          editService={(index, type) => editService(index, type)}
          addChargeOrDiscount={(showCustomAddInput, showDiscountInput) =>
            addChargeOrDiscount(showCustomAddInput, showDiscountInput)
          }
          setAddedInput={setAddedInput}
          clearInputField={clearInputField}
          errors={errors}
          handleformSubmit={handleformSubmit}
          setAddedPrice={setAddedPrice}
          editingData={editingData}
          deleteService={deleteService}
          addCustomCharge={(index, edit) => addCustomCharge(index, edit)}
          addCustomDiscount={(index, edit) => addCustomDiscount(index, edit)}
        />
      </Grid>
    </>
  );
};

export default Services;
