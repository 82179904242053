import React from "react";
import { Box } from "@mui/system";
import Header from "../../common/header/Header";
import Breadcrumb from "../../common/header/Breadcrumb";
import TemplateListView from "../../components/templateListView/TemplateListView";

const Templates = () => {
  return (
    <>
      {window.scrollTo(0, 0)}
      <Box sx={{ flexGrow: 1, backgroundColor: "#f7f9fa", minHeight: "100vh" }}>
        <Header />
        <Breadcrumb />
        <TemplateListView />
      </Box>
    </>
  );
};

export default Templates;
