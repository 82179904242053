import { useState, useContext } from "react";
// dialog imports
import Dialog from "@mui/material/Dialog";
// PALM 10151
// import { Userpilot } from "userpilot";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";
import { Button, Typography, FormControlLabel, Checkbox, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import agreementImg from "../../../assets/images/default_agreement.png";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { BootstrapTooltip } from "../../utils/services";
import { AppContext } from "../../App";
import { apiCall } from "../../utils/action";
import { toast } from "react-hot-toast";
import { LoadingButton } from "@mui/lab";

const IncludedAgreementModal = ({ showIncludedAgrmntModal, setShowIncludedAgrmntModal, setShowInspAgrmntModal, setShowCreateOwnAgrmntModal, isAgreementPageModal = false, setStateChanged, isSettingPageModal = false }) => {
  const uuId = sessionStorage.getItem("user_id");
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  const navigate = useNavigate();

  const { setReload, companyInfo } = useContext(AppContext);

  const [agreeTerms, setAgreeTerms] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingDoNotUseBtn, setLoadingDoNotUseBtn] = useState(false);
  const handleCloseIncludedAgremntModal = () => {
    setShowIncludedAgrmntModal(false);
    setAgreeTerms(0);
  };
  const handleBack = () => {
    setShowIncludedAgrmntModal(false);
    setShowInspAgrmntModal(true);
    setAgreeTerms(0);
  };
  const includedAgreementHandle = () => {
    setLoading(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId, agreement_status: 1, agreed_to_terms_for_agreement: 1 };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          // PALM 10151
          // Userpilot.track("Add agreement");
          setShowIncludedAgrmntModal(false);
          setReload((prev) => !prev);
          if (!isAgreementPageModal && !isSettingPageModal) navigate("/schedule-inspection");
          else if (isSettingPageModal) navigate("/settings/agreements");
          else {
            setShowIncludedAgrmntModal(false);
            setStateChanged((prev) => !prev);
          }
          setLoading(false);
        } else {
          toast.error(message);
          setLoading(false);
        }
      },
      formdata,
      "UPDATE_AGREEMENT_INFO"
    );
  };
  const disabledHandle = () => {
    // empty function
  };
  const donotUseIncludedHandle = () => {
    setLoadingDoNotUseBtn(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId, agreement_status: 1, agreed_to_terms_for_agreement: 0, delete_default_agreement: 1 };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setReload((prev) => !prev);
          setShowIncludedAgrmntModal(false);
          setShowCreateOwnAgrmntModal(true);
          setLoadingDoNotUseBtn(false);
        } else {
          toast.error(message);
          setLoadingDoNotUseBtn(false);
        }
      },
      formdata,
      "UPDATE_AGREEMENT_INFO"
    );
  };
  return (
    <Dialog open={showIncludedAgrmntModal} onClose={handleCloseIncludedAgremntModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agreement-terms-modal included-agreement-terms-modal">
      <DialogTitle id="alert-dialog-title">
        {"Would you like to use the included agreement?"}
        <CloseIcon className="close-icon" onClick={handleCloseIncludedAgremntModal} />
      </DialogTitle>
      <DialogContent>
        <DialogContentText className="agreement-terms-text" id="alert-dialog-description">
          <p>Palmtech provides a free Home Inspection agreement. Would you like to add it to your account to use? You can always edit and/or add new agreements later from the agreements page. </p>
          <div className="included_agreement_img">
            <img src={agreementImg} alt="agreement-image" />
          </div>
        </DialogContentText>
        <p className="terms">
          <span>TERMS AND CONDITIONS:</span> This Home Inspection Agreement conforms to the American Society of Home Inspectors' Standards of Practice. Inspectors are advised to consult with an attorney to verify that this agreement complies with Inspector’s jurisdiction's current regulations and standards of practice and make any updates as necessary.
        </p>
        <Grid className="agreement_terms_and_condition_checkbox">
          <FormControlLabel control={<Checkbox onChange={(e) => setAgreeTerms(e.target.checked ? 1 : 0)} id="agree_terms" />} label={<div className="terms_and_condition">I agree to the TERMS AND CONDITIONS shown above.</div>} labelPlacement="end" />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className="back-btn" variant="outlined" onClick={handleBack}>
          <span className="back-btn-content">
            <ArrowBackIosIcon fontSize="small" /> Back
          </span>{" "}
        </Button>
        <div className="included-buttons">
          <LoadingButton loading={loadingDoNotUseBtn} className="default-btn-agreement-terms" variant="contained" onClick={donotUseIncludedHandle}>
            Do not use
          </LoadingButton>

          {/* <BootstrapTooltip
        title={
            agreeTerms === 1? '' : <h1>Agree to the terms to proceed</h1>
        }
      > */}
          <LoadingButton
            loading={loading}
            className={`default-btn-agreement-terms ml-17 ${agreeTerms === 1 ? "" : "btn-disabled"}`}
            variant="contained"
            onClick={() => {
              agreeTerms === 1 ? includedAgreementHandle() : disabledHandle();
            }}
          >
            Use included agreement
          </LoadingButton>
          {/* </BootstrapTooltip> */}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default IncludedAgreementModal;
