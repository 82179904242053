import { Box } from "@mui/system";
import toast from "react-hot-toast";
import DashboardLayout from "./Layout";
import React, { useEffect } from "react";
import NoInspection from "./NoInspection";
import Header from "../../common/header/Header";
import Breadcrumb from "../../common/header/Breadcrumb";
import "../../../assets/styles/containers/_dashboard.scss";
import DownloadLink from "../../components/Download/DownloadLink";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

const Dashboard = (props) => {
  let navigate = useNavigate();
  const location = useLocation();
  const NoInsp = location.state?.noInsp;
  const [searchParams, setSearchParams] = useSearchParams();
  const handleClose = () => {
    searchParams.delete("download");
    setSearchParams(searchParams);
  };

  // PALM 11757
  // const isBanner = sessionStorage.getItem("show_banner");

  useEffect(() => {
    if (sessionStorage.getItem("token") === null || sessionStorage.getItem("token") === undefined) {
      navigate("/");
      toast.error("sign in to see the contents");
    }
  });
  return (
    <>
      {/* PALM 11757 */}
      {/* {isBanner === "1" ? <Banner /> : ""} */}
      {NoInsp === 1 ? <NoInspection /> : ""}
      {searchParams.has("download") ? <DownloadLink handleClose={handleClose} /> : ""}
      {window.scrollTo(0, 0)}
      <Box sx={{ flexGrow: 1 }} className="page-background">
        <Header />
        <Breadcrumb />
        <DashboardLayout />
      </Box>
    </>
  );
};

export default Dashboard;
