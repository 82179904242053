import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const NotificationCenterCard = ({title,label1,label2,label3,navigationPath,marginVal,
  currentStatus,checkedStatus,storeNotificationSettings,keyVal}) => {
  const navigate = useNavigate();
  const handleCheck =(value,labelCode)=>{
    let currentStatusArray = currentStatus !== undefined && currentStatus !== null ? [...currentStatus]:[]
    if(currentStatusArray.length === 0 || currentStatusArray === undefined ){
      if(value){
         checkedStatus([labelCode])
         currentStatusArray.push(labelCode)
      }
    }
    else{
      let index = currentStatusArray.findIndex(e=> e === labelCode) 
      if(index !== -1){
        if(value === false){
           currentStatusArray.splice(index,1)
           checkedStatus(currentStatusArray)
          }
      }
      else{
        if(value) {
          currentStatusArray.push(labelCode)
          checkedStatus(currentStatusArray)
      }
    }
  }
  storeNotificationSettings(currentStatusArray,keyVal)
}
 
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Card className="notifications-centre-card" variant="outlined">
          {title !== '' &&
          <CardHeader
            className="cardHeader"
            action={
              <IconButton  aria-label="settings" disableRipple onClick={() => navigate(`/notifications/${navigationPath}`)}>
                <span className="edit-action" id={`edit_${keyVal}`} name={`edit_${keyVal}`} >
                  Edit template
                  <KeyboardArrowRightIcon />
                </span>
              </IconButton>
            }
            title={title}
          />}
          <Grid container className="details-card">
            <FormControlLabel style={{marginBottom:`${marginVal}px`,display:'flex',alignItems:'flex-start',width:'fit-content'}}
              control={<Checkbox id={`${keyVal}_${1}`} disableRipple checked={currentStatus!== undefined && currentStatus?.includes(8)?true:false} onClick={(e)=>handleCheck(e.target.checked,8)} sx={{ color: "#000", "&.Mui-checked": { color: "#000" },paddingTop:'0px' }}/>}
              label={label1}/>
            <FormControlLabel style={{marginBottom:`${marginVal}px`,display:'flex',alignItems:'flex-start',width:'fit-content'}}
              control={<Checkbox id={`${keyVal}_${2}`} disableRipple checked={currentStatus!== undefined && currentStatus?.includes(9)?true:false} onClick={(e)=>handleCheck(e.target.checked,9)} sx={{ color: "#000", "&.Mui-checked": { color: "#000" },paddingTop:'0px' }}/>}
              label={label2}/>
            {label3 !=='' && <FormControlLabel  style={{display:'flex',alignItems:'flex-start',width:'fit-content'}}
            control={<Checkbox id={`${keyVal}_${3}`} disableRipple checked={currentStatus!== undefined && currentStatus?.includes(7)?true:false} onClick={(e)=>handleCheck(e.target.checked,7)} sx={{ color: "#000", "&.Mui-checked": { color: "#000" },paddingTop:'0px' }}/>}
            label={label3}/>}
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default NotificationCenterCard;
