import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import Button from "@mui/material/Button";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { onKeyPressed } from "../../utils/services";

const ClientDetails = (props) => {
  const { clientList, HandleClose, HandleClose2, handleChangeClient2, clientName2, clientName, handleChangeClient, clientValid, handleNewClient, isNewClient, clientFName, clientPhone, clientEmail, clientLName, handleNewClient2, isNewClient2, clientFName2, clientPhone2, clientEmail2, clientLName2, user, handleUser, user2, handleUser2 } = props;

  const [addClient2, setAddClient2] = useState(false);

  const handleNewClient2Open = () => {
    setAddClient2(true);
  };
  const handleNewClient2Close = () => {
    handleNewClient2();
    setAddClient2(false);
  };

  const useStyles = makeStyles((theme) => ({
    inputRoot: {
      "& .MuiOutlinedInput-notchedOutline": {
        height: clientName.length === 0 && "45px",
        paddingTop: clientName.length === 0 && "10px",
      },
    },
  }));

  const PaperComponentCustom = (options) => {
    const classes = useStyles();
    const { containerProps, children } = options;

    return (
      <Paper className={classes.paper} {...containerProps}>
        {children}
        <Divider />
        {clientName.length <= 1 ? (
          <Button fullWidth className="add-select" onMouseDown={handleNewClient} id="add_new_client">
            + Add New
          </Button>
        ) : (
          ""
        )}
      </Paper>
    );
  };

  const PaperComponentCustom2 = (options) => {
    const classes = useStyles();
    const { containerProps, children } = options;

    return (
      <Paper className={classes.paper} {...containerProps}>
        {children}
        <Divider />
        {clientName.length <= 1 ? (
          <Button
            fullWidth
            className="add-select"
            onMouseDown={handleNewClient2}
            id="add_new_client"
            // value={"+addagent"}
          >
            + Add New
          </Button>
        ) : (
          ""
        )}
      </Paper>
    );
  };

  const classes = useStyles();

  return (
    <>
      <Grid container>
        {/* client 1 */}
        <Grid item xs={12} sm={12} className="modal-sub-title">
          <Typography>Client 1</Typography>
          <Typography>At least one client is required to schedule the inspection. Without this information, you may save as a draft. </Typography>
        </Grid>

        {/* Multipleselect */}
        <Grid item xs={12} sm={12}>
          <FormControl fullWidth className="forms-control multi-select">
            <label className="input-form-label-select" htmlFor="client" id="demo-simple-select-label">
              Client Name
            </label>
            {/* for multiple agent selection in step 4 */}
            <Autocomplete
              className="forms-control1 custom-color"
              id="tags-outlined"
              classes={classes}
              filterSelectedOptions
              disabled={isNewClient}
              multiple
              size="small"
              options={clientName.length <= 1 ? clientList : []}
              value={clientName}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.name}
                </li>
              )}
              getOptionLabel={(option) => option.name}
              PaperComponent={PaperComponentCustom}
              onChange={(e, value) => handleChangeClient(value)}
              renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" deleteIcon={<CloseIcon />} label={option.name} {...getTagProps({ index })} />)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // stop typing after 2 client is select
                  inputProps={{
                    ...params.inputProps,
                    onKeyPress: (e) => {
                      if (clientName.length > 1) {
                        e.preventDefault();
                      } else {
                        return true;
                      }
                    },
                  }}
                  // onChange={({ target }) => InspectorChange(target.value)}
                  placeholder="Select Client"
                  error={clientValid === 1 && true}
                  helperText={clientValid === 1 && "Please select the field"}
                />
              )}
              // error={clientValid === 1 && true}
            />
            {/* {clientList.map((client, index) => (
                    <MenuItem
                      key={index}
                      // onClick={handleAgent}
                      value={client.name}
                    >
                      {client.name}
                    </MenuItem>
                  ))}
                  <Divider />
                  <MenuItem
                    className="add-select"
                    onClick={handleNewClient}
                    value={"+ Add new"}
                  >
                    + Add new
                  </MenuItem>
                </Select> */}
            {/* {clientValid === 1 && <FormHelperText style={{color:'#d32f2f'}}>please select the field</FormHelperText>} */}
          </FormControl>
        </Grid>
        {/* multipleselect */}
        {isNewClient ? (
          <>
            <Grid container className="clients-controll input-controll">
              <Grid item xs={12} className="client-title" sx={{ display: "flex" }}>
                Add a new client below
                {/* close icon for the cancel the add client form (anil) */}
                <CloseIcon
                  onClick={HandleClose}
                  sx={{
                    display: "flex",
                    marginLeft: "525px",
                    cursor: "pointer",
                  }}
                  id="client_close_button"
                  // className={"inspection-filter-modal-close-icon"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" className="forms-control">
                  <label className="input-form-label" htmlFor="first-name" id="demo-simple-select-label">
                    First Name*
                  </label>
                  <TextField className="input-textfield" size="small" id="clientFirstId" variant="outlined" placeholder="First Name" value={user.first_name} onChange={handleUser} type="text" name="first_name" error={clientFName === null ? false : clientFName === undefined ? false : true} helperText={clientFName} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" className="forms-control">
                  <label className="input-form-label" htmlFor="last-name" id="demo-simple-select-label">
                    Last Name*
                  </label>
                  <TextField className="input-textfield" size="small" id="clientLastId" variant="outlined" value={user.last_name} onChange={handleUser} placeholder="Last Name" type="text" name="last_name" error={clientLName === null ? false : clientLName === undefined ? false : true} helperText={clientLName} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" className="forms-control">
                  <label className="input-form-label" htmlFor="email" id="demo-simple-select-label">
                    Email*
                  </label>
                  <TextField className="input-textfield" size="small" id="clientEmailId" variant="outlined" placeholder="Enter Email" type="email" value={user.email} onChange={handleUser} name="email" error={!clientEmail ? false : true} helperText={clientEmail} />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl variant="standard" className="forms-control">
                  <label className="input-form-label" htmlFor="phone" id="demo-simple-select-label">
                    Phone*
                  </label>
                  <TextField
                    className="input-textfield"
                    size="small"
                    // id="clientphoneNumber"
                    variant="outlined"
                    placeholder="Phone"
                    // type="number"
                    id="clientPhoneId"
                    value={user.phone}
                    onChange={handleUser}
                    name="phone"
                    error={clientPhone === null ? false : clientPhone === undefined ? false : true}
                    helperText={clientPhone}
                    // error={clientPhone === null ? false : clientPhone === undefined ? false : true}
                    // helperText={clientPhone}
                  />
                </FormControl>
              </Grid>
              {clientName.length >= 1 ? (
                ""
              ) : (
                <Grid item xs={12} md={12} className="add-client pl-15 ml-0">
                  <Typography id="add_another_client" style={{ opacity: addClient2 && "0.5" }} onClick={handleNewClient2Open}>
                    <AddCircleIcon />
                    &nbsp; Add another client
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        ) : (
          ""
        )}
        {/* Client 2 */}
        {addClient2 ? (
          <>
            <Grid item xs={12} sm={12} className="modal-sub-title2">
              <Typography>
                Client 2{" "}
                <span onClick={handleNewClient2Close} id="remove_client2">
                  <IndeterminateCheckBoxIcon /> Remove
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth className="forms-control multi-select">
                <label className="input-form-label-select" htmlFor="client" id="demo-simple-select-label">
                  Client Name
                </label>

                <Autocomplete
                  className="forms-control1 custom-color"
                  id="tags-outlined"
                  classes={classes}
                  filterSelectedOptions
                  disabled={isNewClient2}
                  multiple
                  size="small"
                  options={clientName2.length <= 1 ? clientList : []}
                  value={clientName2}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  getOptionLabel={(option) => option.name}
                  PaperComponent={PaperComponentCustom2}
                  onChange={(e, value) => handleChangeClient2(value)}
                  renderTags={(value, getTagProps) => value.map((option, index) => <Chip variant="outlined" deleteIcon={<CloseIcon />} label={option.name} {...getTagProps({ index })} />)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // stop typing after 2 client is select
                      inputProps={{
                        ...params.inputProps,
                        onKeyPress: (e) => {
                          if (clientName2.length > 1) {
                            e.preventDefault();
                          } else {
                            return true;
                          }
                        },
                      }}
                      // onChange={({ target }) => InspectorChange(target.value)}
                      placeholder="Select Client"
                      error={clientValid === 1 && true}
                      helperText={clientValid === 1 && "Please select the field"}
                    />
                  )}
                  // error={clientValid === 1 && true}
                />
              </FormControl>
            </Grid>
            {isNewClient2 ? (
              <>
                <Grid container className="clients-controll input-controll">
                  <Grid item xs={12} className="client-title" sx={{ display: "flex" }}>
                    Add a new client below
                    <CloseIcon
                      onClick={HandleClose2}
                      id="close_sec_client"
                      sx={{
                        display: "flex",
                        marginLeft: "525px",
                        cursor: "pointer",
                      }}
                      // className={"inspection-filter-modal-close-icon"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" className="forms-control">
                      <label className="input-form-label" htmlFor="first-name" id="demo-simple-select-label">
                        First Name*
                      </label>
                      <TextField className="input-textfield" size="small" id="clientFirstId2" variant="outlined" name="first_name2" placeholder="First Name" type="text" value={user2.first_name2} onChange={handleUser2} error={clientFName2 === null ? false : clientFName2 === undefined ? false : true} helperText={clientFName2} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" className="forms-control">
                      <label className="input-form-label" htmlFor="last-name" id="demo-simple-select-label">
                        Last Name*
                      </label>
                      <TextField className="input-textfield" size="small" id="clientLastId2" variant="outlined" name="last_name2" value={user2.last_name2} onChange={handleUser2} placeholder="Last Name" type="text" error={clientLName2 === null ? false : clientLName2 === undefined ? false : true} helperText={clientLName2} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" className="forms-control">
                      <label className="input-form-label" htmlFor="email" id="demo-simple-select-label">
                        Email*
                      </label>

                      <TextField className="input-textfield" size="small" variant="outlined" id="clientEmailId2" placeholder="Enter Email" type="email" name="email2" value={user2.email2} onChange={handleUser2} error={!clientEmail2 ? false : true} helperText={clientEmail2} />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl variant="standard" className="forms-control">
                      <label className="input-form-label" htmlFor="phone" id="demo-simple-select-label">
                        Phone*
                      </label>
                      <TextField
                        className="input-textfield"
                        size="small"
                        // id="phone-number"
                        variant="outlined"
                        placeholder="Phone"
                        // type="number"
                        id="clientPhoneId2"
                        value={user2.phone2}
                        onChange={handleUser2}
                        onKeyDown={onKeyPressed}
                        name="phone2"
                        error={clientPhone2 === null ? false : clientPhone2 === undefined ? false : true}
                        helperText={clientPhone2}
                        // type='number'
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default ClientDetails;
