import React from "react";
import { useState, useEffect } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const HtmlCommentEditor = (props) => {
  const { editorContent, setEditorContent, update } = props;

  const [isEdit , setEdit]  = useState(false)

  const handleChange = (content) => {
    setEdit(true)
    setEditorContent(content);
  };

  useEffect(() => {
    setEdit(false)
  }, [])


  const auto = () => {
    update("noToast")
    setEdit(false)

  }
  
  return (
    <ClickAwayListener  mouseEvent="onMouseDown"
    touchEvent="onTouchStart" onClickAway={() => isEdit && auto()}>
<div className="editorfix" style={{ cursor: "text" }}>
  <SunEditor
    setDefaultStyle="font-size: 14px"
    contenteditable={true}
    setOptions={{
      buttonList: [["bold", "italic", "underline", "list", "fontColor", "fontSize"]],
    }}
    onChange={handleChange}
    defaultValue={editorContent}
  />
</div>
    </ClickAwayListener>
  );
};

export default HtmlCommentEditor;
