import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { apiCall } from "../../utils/action";
import { useLocation } from "react-router-dom";
import Preloader from "../../helpers/Preloader";
import Header from "../../common/header/Header";
import Breadcrumb from "../../common/header/Breadcrumb";
import ErrorDetailedLayout from "./ErrorDetailedLayout";

const ErrorDetailedView = () => {
  const location = useLocation();
  const errorID = location.state?.id;
  const [errorData, setErrorData] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const getErrorDetails = () => {
    setShowLoader(true);
    const formdata = { log_id: errorID, user_id: sessionStorage.getItem("user_id"), role_id: sessionStorage.getItem("role_id") };
    apiCall(
      (response) => {
        const { success, data } = response.data;
        if (success) {
          setErrorData(data);
          setShowLoader(false);
        } else {
          console.log("else", response.data.message);
        }
      },
      formdata,
      "GET_ERROR_DETAIL"
    );
  };

  useEffect(() => {
    getErrorDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ flexGrow: 1, backgroundColor: "#f7f9fa", minHeight: "100vh" }}>
      {window.scroll(0, 0)}
      <Header />
      <Breadcrumb />
      {showLoader ? <Preloader showPreloader /> : ""}
      <ErrorDetailedLayout data={errorData} />
    </Box>
  );
};

export default ErrorDetailedView;
