import { useState } from "react";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import imageCompression from "browser-image-compression";
import { apiCall } from "../../../components/agentListView/action";
import { Grid, FormControl, Typography, Button } from "@material-ui/core";
import { FormControlLabel, FormGroup, Checkbox, Dialog, DialogContent, DialogActions } from "@mui/material";

const ReportEditLayout = (props) => {
  //dialog box
  const handleOpen = () => setOpen(true);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [loader, setLoader] = useState(false);
  const {  colorPalette, defaultLogo, setDefaultLogo, setIsChanged, fileInput, image, setImage, selectedColor, setSelectedColor, useCompanyName, setUseCompanyName } = props;

  const deleteProfilePicture = () => {
    setLoader(true);
    const formdata = { company_key: sessionStorage.getItem("company_id") };
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setOpen(false);
          setLoader(false);
          setImage("");
          setDefaultLogo("");
          fileInput.current.value = "";
        } else {
          console.log("else ", message);
          setLoader(false);
        }
      },
      formdata,
      "LOGO_DELETE_RS"
    );
  };


  const handleImageUpload = async (event) => {
    setLoader(true)
    const imageFile = event.target.files[0];

    if (!imageFile) {
      return;
    }

    // Compression options
    const options = {
      maxSizeMB: 1,            // Max size in MB (Adjust as needed)
      maxWidthOrHeight: 800,   // Max width/height (Adjust as needed)
      useWebWorker: true,      // Enables web workers for better performance
    };

    try {
      // Compress the image file
      const compressedFile = await imageCompression(imageFile, options);

      // Set the compressed image in the state to preview
      setImage(compressedFile);
      setIsChanged(true);
      setUseCompanyName(false);
      setLoader(false);

    } catch (compressionError) {
      console.error("Compression Error:", compressionError);
      setLoader(false);
    }
  };


  return (
    <>
      <Grid container>
        <Grid item xs={6}>
          <Typography className="report-preview-title">Logo</Typography>
        </Grid>
        <Grid item xs={6}>
          <Button id="edit_report_logo" variant="none" onClick={() => fileInput.current.click()} className="report-logo-edit-button" startIcon={<EditIcon className="details__icon" />}>
            Edit
          </Button>
        </Grid>
        <Grid item xs={12}>
          <div className="selected-image-conatainer-report">
            <Grid container>
              <Grid item xs={6}>
                <div className="selected-image-top-left"></div>
              </Grid>
              <Grid item xs={6}>
                <div className="selected-image-top-right"></div>
              </Grid>
              <Grid item xs={12}>
                <input
                  ref={fileInput}
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    handleImageUpload(e);
                  }}
                  style={{ display: "none" }}
                />
                {image && <img className="image-setting" src={typeof image === "string" ? image : URL.createObjectURL(image)} alt="Thumb" />}
                {!image && defaultLogo ? <img src={defaultLogo} className="image-setting" alt="dp" /> : <div className="image-setting"></div>}
              </Grid>
              <Grid item xs={6}>
                <div className="selected-image-bottom-left" style={{ marginTop: image ? "-75px" : "-10px" }}></div>
              </Grid>
              <Grid item xs={6}>
                <div className="selected-image-bottom-right" style={{ marginTop: image ? "-75px" : "-10px" }}></div>
              </Grid>
            </Grid>
          </div>
          <Typography className="desc">For best visual results we suggest a logo that reaches the indicators on all four sides.</Typography>
        </Grid>
        <Grid item xs={12}>
          <p style={{ paddingTop: "10px", fontSize: "12px", lineHeight: "20px", letterSpacing: 0, fontWeight: "600", color: " #E30303", cursor: "pointer", opacity: defaultLogo ? "1.00" : "0.25", pointerEvents: !defaultLogo && "none", userSelect: "none" }} onClick={() => handleOpen()}>
            <DeleteIcon sx={{ height: "10.5px !important", width: "10.5px" }} /> Delete photo
          </p>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "30px" }}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  style={{ top: "-10px" }}
                  checked={useCompanyName}
                  id="use_company_name"
                  onClick={() => {
                    setUseCompanyName(!useCompanyName);
                    setIsChanged(true);
                  }}
                />
              }
              label={
                <>
                  <Typography className="checkbox">Use Company Name</Typography> <Typography style={{ marginLeft: "5px", userSelect: "none" }}>Use your company’s name instead of a logo in the header of the PDF and HTML report.</Typography>
                </>
              }
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <Typography className="report-preview-title" style={{ marginTop: "76px" }}>
            Select color scheme
          </Typography>
        </Grid>
        <Grid item={12} style={{ marginLeft: "-15px" }}>
          <FormControl variant="standard" className="forms-control" style={{ padding: "0px !important" }}>
            <div className="color-pallets" style={{ left: "-10px !important" }}>
              {colorPalette.map((value, index) => {
                return (
                  <div className="pallet-wrapper" style={{ border: selectedColor === value ? "1px solid #979797" : "" }}>
                    <span
                      className="pallet"
                      style={{ background: value }}
                      id={`color_palette_${index + 1}`}
                      onClick={() => {
                        setSelectedColor(value);
                        setIsChanged(true);
                      }}
                    ></span>
                  </div>
                );
              })}
            </div>
          </FormControl>
        </Grid>
      </Grid>
      <Dialog fullWidth={true} className="dialog-container-details report_settings_modal" open={open} onClose={handleClose}>
        <div className="close-wrapper">
          <CloseIcon onClick={handleClose} />
        </div>
        <DialogContent className="dialog-content">
          <Grid container className="permission_change_modal report_setting_content">
            <Typography>Delete confirmation</Typography>
            <Typography>Are you sure you want to delete the company logo?</Typography>
          </Grid>
        </DialogContent>
        <DialogActions className="dialog-action report_settings_action">
          <Button className="back-btn" onClick={handleClose}>
            Cancel
          </Button>
          <LoadingButton className="default-btn" onClick={() => deleteProfilePicture()} loading={loader}>
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReportEditLayout;
