import {  FormControl, Grid, TextField,Menu, MenuItem, } from "@mui/material"
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from "react";
import InputField from "./InputField";
import MediaUpload from "./MediaUpload";
import { Collapse } from "@material-ui/core";
import AddElementButton from "./AddElementButton";
import Fade from '@mui/material/Fade';
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import GroupElement from "./GroupElement";
import CheckBoxElement from "./CheckBoxElement";
import InformationalText from "./InformationalText";
import Signature from "./Signature";
import DividerElement from "./DividerElement";
import RadioElement from "./RadioElement";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { innerElCount, deleteElement, updateElementJsonByUUID, updateJSON, findParentUUID, findIndexByUUID } from "../PtTemplateHelperFunctions";
import { debounce } from "lodash";
import { useEffect } from "react";
import TableElement from "./TableElement";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const ExpandingCheckbox = ({ nestingLevel = 0,uuid,parentType,elemParentType,elements,catuuid,templateState,setTemplateState,updatePtTempateData,label,merge_id,isDragging,catType,setDragDisable}) => {
    const [expanded, setExpanded] = useState(true);
    const [expCheckBoxName,setExpCheckBoxName] = useState('')
    const [expCkeckBoxTag,setExpCheckBoxTag] = useState('');
    const [expEdited,setExpEdited] = useState('')
    const [editedInpMerge,setEditedInpMerge] = useState(false);
    const [mergeIdError,setMergeIdError] = useState(false);
    const handleExpanded = () => setExpanded(!expanded);
    const [active,setActive] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [reorderedExpEL, setReorderedExpEL] = useState(false);
    const open = Boolean(anchorEl);
    const handleClickMore = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

  // Create a debounced function to update the state
  const debouncedUpdateData = debounce((prevData) => {   
    setTemplateState(prev=>({...prev,templateData:prevData}))
  }, 2000); // Delay state update by 2 seconds
  const handleChangeExpCheckBoxName=(e,changeType)=>{
    e.persist();
    let updValue= e.target.value;
    let updatedData; 
    let prevData = JSON.parse(JSON.stringify(templateState?.templateDataCopy));
    if(changeType === "label"){      
      setExpEdited(true) 
      setExpCheckBoxName(updValue); 
      updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue,'label');
    }
    else if(changeType === 'tag'){
      setExpCheckBoxTag(updValue)
      setEditedInpMerge(true);
      if(templateState?.mergeIdArray.includes(updValue)) {       
        setMergeIdError(true);
        updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue,'tag');
      }
      else{
        setMergeIdError(false);
        updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue,'tag');
      }     
    }
    if (updatedData) {
      prevData.data = updatedData;
      debouncedUpdateData(prevData);
    }
  }

  const clickAwaySaveptData=()=>{
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
    updateJSON(prevData, uuid, expCheckBoxName,"label");
    let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
    let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
    newUpdatedSpecificData.data.template.pdf_categories = editedCategory
    setTemplateState(prev=>({...prev,templateData:prevData}))
    updatePtTempateData(newUpdatedSpecificData?.data?.template)       
    setExpEdited(false)
  }

  const clickAwaySaveptMergeData=()=>{ 
    if(mergeIdError) { 
      setExpCheckBoxTag('')
      let updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, "",'tag');
      let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
      prevData.data = updatedData;
      setTemplateState(prev=>({...prev,templateData:prevData}))
      setMergeIdError(false)
      setEditedInpMerge(false)
    }
    else{
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
    updateJSON(prevData, uuid, expCkeckBoxTag,"tag");
    let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
    let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
    newUpdatedSpecificData.data.template.pdf_categories = editedCategory
    setTemplateState(prev=>({...prev,templateData:prevData}))
    updatePtTempateData(newUpdatedSpecificData?.data?.template)       
    setEditedInpMerge(false)
    }  
  }
  const onDragEndHandleExp = (result,expUuid,nestingLevel) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    const newState = { ...templateState };
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(
      (el) => el.uuid === catuuid
    );
    // if (catIdx !== -1) {
    //   const uuidObject = newState.templateData.data.template.pdf_categories[
    //     catIdx
    //   ].elements.find((element) => element.uuid === expUuid);
    //   const elemIdx = newState.templateData.data.template.pdf_categories[
    //     catIdx
    //   ].elements.findIndex((element) => element.uuid === expUuid);

    //   if (!uuidObject) {
    //     return;
    //   }
    //   const elementsArray = Array.from(uuidObject.elements);
    //   elementsArray.splice(source.index, 1);
    //   elementsArray.splice(
    //     destination.index,
    //     0,
    //     uuidObject.elements[source.index]
    //   );
    //   uuidObject.elements = elementsArray;
    //   newState.templateData.data.template.pdf_categories[catIdx].elements[
    //     elemIdx
    //   ].elements = elementsArray;
    //   setTemplateState(newState);
    //   setReorderedExpEL(true);
    // } 
// =====================================2nd
    if (catIdx !== -1) {
      let uuidObject;
      let elemIdx;
      if(nestingLevel > 0){
        const parentUUID = findParentUUID(templateState?.templateDataCopy.data.template, expUuid);
        const parentIndex = newState.templateData.data.template.pdf_categories[
            catIdx
          ].elements.findIndex((element) => element.uuid === parentUUID);
        uuidObject = newState.templateData.data.template.pdf_categories[
          catIdx
        ].elements[parentIndex].elements.find((element) => element.uuid === expUuid);
         elemIdx = newState.templateData.data.template.pdf_categories[
          catIdx
        ].elements[parentIndex].elements.findIndex((element) => element.uuid === expUuid);
      }else{
        uuidObject = newState.templateData.data.template.pdf_categories[
          catIdx
        ].elements.find((element) => element.uuid === expUuid);
        elemIdx = newState.templateData.data.template.pdf_categories[
          catIdx
        ].elements.findIndex((element) => element.uuid === expUuid);
      }
      

      if (!uuidObject) {
        return;
      }
      const elementsArray = Array.from(uuidObject.elements);
      elementsArray.splice(source.index, 1);
      elementsArray.splice(
        destination.index,
        0,
        uuidObject.elements[source.index]
      );
      uuidObject.elements = elementsArray;
      if(nestingLevel > 0){
        const parentUUID = findParentUUID(templateState?.templateDataCopy.data.template, expUuid);
        const parentIndex = newState.templateData.data.template.pdf_categories[
            catIdx
          ].elements.findIndex((element) => element.uuid === parentUUID);
        newState.templateData.data.template.pdf_categories[catIdx].elements[
          parentIndex
        ].elements[elemIdx].elements = elementsArray;
        setTemplateState(newState);
        setReorderedExpEL(true);
      }else{
      newState.templateData.data.template.pdf_categories[catIdx].elements[
            elemIdx
          ].elements = elementsArray;
          setTemplateState(newState);
          setReorderedExpEL(true);
      }
   
    } 
  //  ================================2nd 
    
   
  };
  const handleMouseOutExpEl=()=>{
    reorderedExpEL && updatePtTempateData(templateState?.templateData?.data?.template);
    setReorderedExpEL(false)
  }
  const activateDragIn=()=>{
    setDragDisable(true);
    if(nestingLevel > 1){
       sessionStorage.setItem("firstLevelDrag", true);
    }
  }
  const activateDragOut=()=>{
    setDragDisable(false);
    if(nestingLevel > 1){
       sessionStorage.setItem("firstLevelDrag", false);
    }
  }

  useEffect(() => {
    setExpCheckBoxName(label)
    setExpCheckBoxTag(merge_id)
  }, [templateState])
  return (<ClickAwayListener  mouseEvent="onMouseDown"
  touchEvent="onTouchStart" onClickAway={() => active && setActive(false)}>
    <Grid container className="pt_card_border pt_inputfield" style={{flexWrap:'nowrap'}} key={uuid} onMouseEnter={()=>nestingLevel >0 ? activateDragIn():null} onMouseLeave={()=>nestingLevel >0 ?activateDragOut():null} onClick={()=>setActive(true)}>
        <div className={`${isDragging || active?'pt_ip_drag_section pt_elem_dragging':"pt_ip_drag_section"} `}><DragIndicatorIcon className="pt_icon_color"/></div>
        <div className="pt_ip_content">
            <div className="pt_ip_data">
                <div className="pt_ip_data_row1 pt_is_flex_align_center pt_space_between pointer"  onClick={handleExpanded}>
                    <div className="pt_is_flex_align_center gap5">
                        <LibraryAddCheckOutlinedIcon/>
                        <span className="pt_element_name pointer">Expanding Checkbox</span>
                    </div> 
                    <span className="pt_elem_count_span">
                    <span>{innerElCount(elements)} {innerElCount(elements) !== 1?'Form Elements':'Form Element'}</span>
                    <ExpandMoreIcon className={`pt_ta_exp_checkbox_expand_icon ${expanded?'accord_active':'accord_inactive'} `}/>  
                    </span> 
                </div>
                <Grid container style={{marginBottom:'14px'}}>                
                <Grid item xs={12} className="pt_merge_field">
                  <label className="pt_form_label" htmlFor="pt_exp_checkbox_label_merge" id="pt_exp_checkbox_label">
                  merge field id
                  </label>
                  <ClickAwayListener  mouseEvent="onMouseDown"
                        touchEvent="onTouchStart" onClickAway={() => editedInpMerge && clickAwaySaveptMergeData()}>
                  <TextField
                    style={{width:'50%',display:'block',background:'#fff',borderColor: mergeIdError ? '#d32f2f' : '#ced4da'}}
                    type="text"
                    id="pt_exp_checkbox_label_merge"
                    size="small"
                    name="pt_exp_checkbox_label_merge"
                    variant="outlined"
                    disabled={templateState.roleId !== 2 && templateState.roleId !== 3}
                    value={expCkeckBoxTag}
                    error={mergeIdError}
                    helperText={`${mergeIdError ? "This merge ID is in use."  : ""}`}
                    onChange={(e)=>handleChangeExpCheckBoxName(e,'tag')}
                    placeholder="ID tag"
                    className={mergeIdError?`input-textfield pt_focussed`:"input-textfield"}/>
                  </ClickAwayListener>
                </Grid>               
                <Grid item xs={12}>
                      <FormControl variant="standard" className="pt_w_100">
                        <label className="pt_form_label" htmlFor="label" id="demo-simple-select-label">
                          Label
                        </label>
                        <ClickAwayListener  mouseEvent="onMouseDown"
                          touchEvent="onTouchStart" onClickAway={() => expEdited && clickAwaySaveptData()}>
                        <TextField
                          fullWidth
                          type="text"
                          id="label"
                          size="small"
                          name="city"
                          variant="outlined"
                          disabled={templateState.roleId !== 2 && templateState.roleId !== 3}
                          value={expCheckBoxName === null?'':expCheckBoxName}
                          onChange={(e)=>handleChangeExpCheckBoxName(e,'label')}
                          placeholder="Input title"
                          className="input-textfield"
                        />
                         </ClickAwayListener>
                      </FormControl>
                </Grid>
                </Grid>
                <div onMouseLeave={handleMouseOutExpEl}>
                    <Collapse in={expanded }>
                    <DragDropContext  onDragEnd={(result) => {onDragEndHandleExp(result,uuid,nestingLevel)}} >
                  <Droppable droppableId="expElementDropableArea">
                    {(provided) => (
                      <div  ref={provided.innerRef}>
                        {expanded && nestingLevel <30 && 
                        elements?.map((innerEl,index)=>
                        !innerEl.is_deleted?
                        ( 
                          <Draggable draggableId={String(innerEl.uuid)} key={innerEl.uuid} index={index} 
                          isDragDisabled={templateState.roleId !== 2 && templateState.roleId !== 3 || nestingLevel >  1 || (nestingLevel === 0 && sessionStorage.getItem("firstLevelDrag") === 'true') || (sessionStorage.getItem("radioOptionHover") === 'true')}
                          // isDragDisabled={true}
                          >
                          {(provided,snapshot) => (
                          <div {...provided.dragHandleProps}  ref={provided.innerRef} {...provided.draggableProps}>
                            
                          {innerEl.type === "Layout/ExpandingCheckBox"?
                            <div key={index}>
                             
                              <ExpandingCheckbox {...innerEl} 
                              parentType="expandingCheckBox" 
                              catuuid={catuuid} templateState={templateState} setTemplateState={setTemplateState} 
                              updatePtTempateData={updatePtTempateData}  elemuuid={uuid} catType={catType} nestingLevel={nestingLevel+1} setDragDisable={setDragDisable} isDragging={snapshot.isDragging}
                              />
                              </div>              
                            :
                            innerEl.type.includes('Input')?
                            <div key={index}>
                              <InputField {...innerEl} parentType="expandingCheckBox"  catuuid={catuuid} templateState={templateState} setTemplateState={setTemplateState} 
                                updatePtTempateData={updatePtTempateData} setDragDisable={setDragDisable} isDragging={snapshot.isDragging} nestingLevel={nestingLevel}/>
                            </div>             
                            :innerEl.type.includes('InformationText')?
                            <div key={index}>
                              <InformationalText {...innerEl} parentType="expandingCheckBox" catuuid={catuuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} setDragDisable={setDragDisable} isDragging={snapshot.isDragging} nestingLevel={nestingLevel}/>
                            </div>             
                            :innerEl.type ==="Layout/Group"?                       
                              <GroupElement {...innerEl} 
                              parentType="expandingCheckBox" 
                              catuuid={catuuid} templateState={templateState} setTemplateState={setTemplateState} 
                              updatePtTempateData={updatePtTempateData} nestingLevel={nestingLevel+1}  elemuuid={uuid} catType={catType} setDragDisable={setDragDisable} isDragging={snapshot.isDragging}/>
                            :innerEl.type === "CheckBox"?
                            <div key={index}>
                              <CheckBoxElement {...innerEl} parentType="expandingCheckBox" catuuid={catuuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} setDragDisable={setDragDisable} isDragging={snapshot.isDragging} nestingLevel={nestingLevel}/>
                            </div>              
                            :innerEl.type === "Signature/Any"?
                            <Signature {...innerEl} parentType="expandingCheckBox" catuuid={catuuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} setDragDisable={setDragDisable} isDragging={snapshot.isDragging} nestingLevel={nestingLevel}/>
                            :innerEl.type === "MediaUploader"?
                            <MediaUpload {...innerEl} parentType="expandingCheckBox" catuuid={catuuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} setDragDisable={setDragDisable} isDragging={snapshot.isDragging} nestingLevel={nestingLevel}/>
                            :innerEl.type === "Divider"?
                            <DividerElement {...innerEl} parentType="expandingCheckBox" catuuid={catuuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} setDragDisable={setDragDisable} isDragging={snapshot.isDragging} nestingLevel={nestingLevel}/>
                            :innerEl.type === "Radio"?
                            <RadioElement {...innerEl} parentType="expandingCheckBox" catuuid={catuuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} setDragDisable={setDragDisable} isDragging={snapshot.isDragging} nestingLevel={nestingLevel}/>
                            :innerEl.type === "Layout/Table"?
                            <TableElement {...innerEl} parentType="expandingCheckBox" catuuid={catuuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} setDragDisable={setDragDisable} isDragging={snapshot.isDragging} nestingLevel={nestingLevel}/>
                          
                            :
                            '' }                 
                        </div>
                          )}
                        </Draggable>                    
                        )
                        :''
                        )                                        
                        }
                        {provided.placeholder}  
                        </div>
                      )}
                   </Droppable>
                   </DragDropContext>
                    </Collapse>
                </div>       
                    
                   <AddElementButton setActive={setActive} elemParentType={elemParentType} catuuid={catuuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} elemuuid={uuid} catType={catType} parentType={parentType}/>
                  
            </div>
            <div className={`${templateState.roleId !== 2 && templateState.roleId !== 3?'pt_ip_action pt_ip_disabled':'pt_ip_action pt_ip_enabled'}`} ><MoreHorizIcon style={{marginTop:'5px'}} className="pt_icon_color" 
            onClick={templateState.roleId !== 2 && templateState.roleId !== 3? null : handleClickMore}/>
            <Menu
              id="fade-menu"
              MenuListProps={{'aria-labelledby': 'fade-button',}}
              anchorOrigin={{vertical: 'top',horizontal: 'right',}}
              transformOrigin={{vertical: 'top',horizontal: 'right',}}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              className="pt_more_menu"
              >
                <span><CloseIcon onClick={handleClose}/></span>
        <MenuItem onClick={()=>deleteElement(templateState, setTemplateState, parentType, uuid, catuuid, updatePtTempateData,handleClose)}><DeleteIcon/>Delete</MenuItem>
            </Menu>
            </div>          
        </div>
       
        
    </Grid>
    </ClickAwayListener>
  )
}

export default ExpandingCheckbox