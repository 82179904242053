import Box from "@mui/material/Box";
// common components imports
import Header from "../../../common/header/Header";
import Breadcrumb from "../../../common/header/Breadcrumb";
import ImportSettings from "../../../components/Import/ImportSettings";

const Import = () => {
  return (
    <>    
    {window.scrollTo(0, 0)}    
      <Box className="page-background">
       <Header/>
       <Breadcrumb/>
         <ImportSettings/>
      </Box>
    </>
  )
}

export default Import