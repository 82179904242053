import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import OptionDialog from "./OptionDialog";
import SpectoraImportDialog from "./SpectoraImportDialog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function CommonOptionDialog(props) {
  const { openOptionDialog, handleOpenOption } = props;

  const renderDonePage = () => (
    <Grid container justifyContent={"center"} display={"flex"}>
      <Grid item xs={12} my={4} display={"flex"} justifyContent={"center"}>
        <CheckCircleIcon className="ptDoneIcon" />
      </Grid>
      <Grid item xs={12} justifyContent={"center"} display={"flex"}>
        <Typography className="importDialogTitle">{`Your file has been sent`}</Typography>
      </Grid>
      <Grid item xs={12} my={2} mx={6}>
        <Typography className="optionDialogTypo">
          {`Thanks! We will be working to import your template and will get back to you within two business days.`}
        </Typography>
      </Grid>
      <Grid item my={2}>
        <Button className="ptDialogButton" onClick={() => handleOpenOption("")}>
          Done
        </Button>
      </Grid>
    </Grid>
  );

  const renderDialogContent = (openOptionDialog) => {
    switch (openOptionDialog) {
      case "options":
        return <OptionDialog handleOpenOption={handleOpenOption} />;
      case "spectora":
        return <SpectoraImportDialog handleOpenOption={handleOpenOption}  />;
      case "donePage":
        return renderDonePage();
      default:
        return (
          <Grid
            container
            display={"flex"}
            justifyContent={"center"}
            my={10}
            py={5}
            px={8}
          >
            {/* <Typography
              fontSize={22}
              fontWeight={400}
            >
              {`No information available for this option.`}
              </Typography> */}
          </Grid>
        );
    }
  };

  return (
    <Dialog
      open={openOptionDialog !== ""}
      onClose={() => handleOpenOption("")}
      maxWidth={openOptionDialog === "options" ? "md" : "sm"}
    >
      <DialogTitle>
        <Grid container display={"flex"} justifyContent={"flex-end"}>
          <IconButton
            onClick={() => handleOpenOption("")}
            className="closeButton"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>{renderDialogContent(openOptionDialog)}</DialogContent>
    </Dialog>
  );
}

export default CommonOptionDialog;
