import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Modal, Grid, Button } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useEffect } from "react";

const NoInspection = ({ showNoInspModal, setShowOnInspModal }) => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const handleClose = () => {
    setShowOnInspModal(false);
  };
  //   useEffect(() => {
  //     console.log(sessionStorage.getItem("no_insp"))
  //     if (sessionStorage.getItem("no_insp") === 1) {
  //       setOpen(true);
  //     }
  //   }, []);
  return (
    <Modal open={showNoInspModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
      <Box className="inspection-filter-modal" sx={{ maxWidth: "655px !important", minHeight: "402px !important", top: "30% !important" }}>
        <Grid container sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
          <Grid item xs={12}>
            <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={handleClose} />
          </Grid>
          <ErrorOutlineIcon className="caution-check-circle" />
          <Grid item xs={12}>
            <p className="no-inspection">No inspections left</p>
          </Grid>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center !important", textAlign: "center", alignItems: "center", justifyItems: "center" }}>
            <p className="no-insp-desc" style={{ display: "inline" }}>
              Your free trial has ended and you have no more free inspections.{" "}
              <p style={{ display: "inline" }} className="link_signup" onClick={() => navigate("/settings/billing-plan")}>
                Subscribe to a plan
              </p>{" "}
              to start a new inspection.{" "}
            </p>{" "}
          </Grid>
          <Grid container sx={{ display: "flex", justifyContent: "right", mt: 5, gap: "10px", paddingRight: "20px" }}>
            <Button className="upgrade-plans-button-cancel" onClick={handleClose} style={{ marginRight: "0px !important" }}>
              Cancel
            </Button>
            <Button className="upgrade-confirm-checkout" onClick={handleClose}>
              Done
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default NoInspection;
