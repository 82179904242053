//file imports-------------------------------------------------------------------------------------------------------------------/
import { useState, useContext } from "react";
import { LoadingButton } from "@mui/lab";
import moment from "moment";
import { Userpilot } from "userpilot";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import validator from "validator";
import logo from "../../../assets/images/logo.png"; //Palm-tech logo
import "../../../assets/styles/containers/_login.scss"; //SCSS import
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"; //back button
import Visibility from "@mui/icons-material/Visibility"; //Icon
import VisibilityOff from "@mui/icons-material/VisibilityOff"; //Icon
import { Grid, Paper, Typography, useMediaQuery, FormControl, FormControlLabel, Checkbox, InputLabel, TextField, InputAdornment, IconButton } from "@mui/material";
import { apiCall } from "../../utils/action";
import axios from "axios";
import { AppContext } from "../../App";

const Signup = () => {
  let navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [password, setPassword] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [firstNameErrorText, setFirstNameErrorText] = useState("");
  const [lastName, setLastName] = useState("");
  const [agree, setAgree] = useState(0);
  const [lastNameError, setLastNameError] = useState(false);
  const [lastNameErrorText, setLastNameErrorText] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [company, setCompany] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passworderror, setpasswordError] = useState(false);
  const [passworderrorText, setpasswordErrorText] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [timeZone, setTimeZone] = useState("");
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  // const [companyError,setCompanyError] = useState(false);
  // const [companyErrorText,setCompanyErrorText] = useState('');
  const matches = useMediaQuery("(min-width:900px)");
  const { setReload } = useContext(AppContext);
  const routeChange = (roleId) => {
    navigate(`/dashboard`);
    setReload((prev) => !prev);
  };

  axios
    .get("https://ipapi.co/json/?key=EJ1FHhnqyB6gRoAik4SRHnb8peb3wBAapbY93Q7TZkYZ0Tsu5R")
    .then((response) => {
      setCountryCode(response.data.country_code);
      setTimeZone(response.data.timezone);
    })
    .catch((error) => {
      // console.log("error",error)
    });

  const getCompanyInfo = (company_id, user_id, role_id, route, role) => {
    const formdata = { company_id: company_id, user_id: user_id, role_id: role_id };
    apiCall(
      (response) => {
        // console.log("GET_COMPANY_INFO",response)
        if (response.data.success) {
          sessionStorage.setItem("company_name", response.data.data.company_details.name);
          sessionStorage.setItem("show_banner", response.data.data.company_details.show_banner);
          routeChange(route);
          toast.success(`Logged in as ${role}`);
        } else {
          // console.log("else", response.data.message);
        }
      },
      formdata,
      "GET_COMPANY_INFO"
    );
  };

  // signup api call
  const signup = () => {
    toast.dismiss();
    setLoading(true);
    const formdata = { first_name: firstName, is_from_us: sessionStorage.getItem("is_from_us") === "CA" ? 2 : 1, last_name: lastName, email, company_name: company, password, countryCode, timeZone, is_agree: agree };
    apiCall(
      (response) => {
        const { status_code, message, success } = response.data;
        if (success) {
          // toast.success(message);
          // setSuccess(true);
          sessionStorage.setItem("token", response.data.data.token);
          sessionStorage.setItem("user_id", response.data.data.user_id);
          sessionStorage.setItem("user_name", response.data.data.user_name);
          sessionStorage.setItem("last_name", response.data.data.last_name);
          sessionStorage.setItem("first_name", response.data.data.first_name);
          sessionStorage.setItem("company_id", response.data.data.company_id);
          sessionStorage.setItem("role_id", response.data.data.role_data.role_id);
          sessionStorage.setItem("is_trial_company", response.data.data.is_trial_company);
          sessionStorage.setItem("total_insp", response.data.data.total_inspections);
          sessionStorage.setItem("is_from_us", response.data.data.is_from_us);
          sessionStorage.setItem("is_trial", response.data.data.is_trial);
          sessionStorage.setItem("can_create_inspection", response.data.data.can_create_inspection);
          Userpilot.identify(response.data.data.user_id, {
            name: response.data.data.user_name,
            email: email,
            created_at: new Date(),
            palm_role_id: response.data.data.role_data.role_id,
            show_user_pilot: response.data.data.show_user_pilot
          });
          window.pendo.initialize({
            visitor: {
              id: response.data.data.user_id,
              Name: response.data.data.user_name,
              Address: "",
              Email: email,
              Role: response.data.data.role_data.role_id !== null && response.data.data.role_data.role_id !== undefined ? (response.data.data.role_data.role_id === 5 ? "Owner" : response.data.data.role_data.role_id === 6 ? "Admin" : response.data.data.role_data.role_id === 7 ? "Inspector" : "") : "",
            },
            account: {
              id: response.data.data.company_id,
              ParentCompany: "PalmTech",
              BillingStartDate: response.data?.data?.pendo?.subscription_start_date ? moment(moment.utc(response.data?.data?.pendo?.subscription_start_date).local()).format("YYYY-MM-DD HH:mm:ss") : "",
              SubscriptionType: response.data?.data?.pendo?.subscription_type,
              TrialStartDate: response.data?.data?.pendo?.profile_creation_date ? moment(moment.utc(response.data?.data?.pendo?.profile_creation_date).local()).format("YYYY-MM-DD HH:mm:ss") : "",
            },
          });
          getCompanyInfo(response.data.data.company_id, response.data.data.user_id, response.data.data.role_data.role_id, response?.data?.data.role_data.role_id, response.data.data.role_data.role_display_name);
          setLoading(false);
        } else {
          // console.log("else ", status_code);
          setLoading(false);
          toast.error(message);
        }
      },
      formdata,
      "SIGNUP"
    );
  };

  // validation
  const validate = () => {
    let checkboxSpan = document.getElementById("agreement-box-error");
    checkboxSpan.innerHTML = "";
    if (firstName === "") {
      setFirstNameError(true);
      setFirstNameErrorText("First name is required");
    } else {
      setFirstNameError(false);
      setFirstNameErrorText("");
    }
    if (lastName === "") {
      setLastNameError(true);
      setLastNameErrorText("Last name is required");
    } else {
      setLastNameError(false);
      setLastNameErrorText("");
    }
    if (email === "") {
      setEmailError(true);
      setEmailErrorText("Email is required");
    } else if (!validator.isEmail(email)) {
      setEmailError(true);
      setEmailErrorText("Enter correct email format");
    } else {
      setEmailError(false);
      setEmailErrorText("");
    }
    if (password === "") {
      setpasswordError(true);
      setpasswordErrorText("Password is required");
    } else if (!validator.isStrongPassword(password)) {
      setpasswordError(true);
      setpasswordErrorText("Error: Please make your password stronger. It must be at least 8 characters long, contain at least one special character, a number and have both uppercase and lowercase letters.");
    } else {
      setpasswordError(false);
    }
    if (firstName !== "" && lastName !== "" && email !== "" && password !== "" && validator.isStrongPassword(password) && agree === 0) checkboxSpan.innerHTML = "Please agree to the Terms of Use and Privacy Policy to continue.";
    if (firstName !== "" && lastName !== "" && email !== "" && validator.isEmail(email) && validator.isStrongPassword(password) && agree === 1) {
      signup();
    }
  };
  return (
    <>
      <div className={`${matches ? "background" : "background-mobile"}`}>
        <Grid container direction="column" alignItems="center" justifyContent="center">
          {/* palm tech logo*************************************************** */}
          <img className={`${matches ? "logo" : "logo-mobile"}`} src={logo} alt="logo"></img>
          {/* signup component starts from here********************************* */}
          <Grid item xs={3}>
            {/* else condition below is not using now as per the new desgn and logic change but not removing the code as the flow is not finalised */}
            {!success ? (
              <Paper className="paper-normal" sx={{ minWidth: { xs: 370, md: 754, lg: 754 }, minHeight: { xs: 441, md: 441, lg: 441 }, boxShadow: "0 30px 30px 0 rgba(40, 40, 40, 0.4)" }}>
                {/* back button *********************************************** */}
                <ArrowBackOutlinedIcon className="back_signup_button" onClick={() => navigate("/")} />
                {/* Heading *************************************************** */}
                <Grid className="center">
                  <Typography className="header" sx={{ mt: "17px", fontSize: { xs: "24px", md: "22px", lg: "22px" } }}>
                    Create your free account
                  </Typography>
                </Grid>
                {/* Disclaimer ************************************************ */}
                <Grid className="center">
                  <Typography className="header" sx={{ mt: "27px", fontSize: { xs: "16px", md: "14px", lg: "14px" }, width: { xs: "340px", md: "616px", lg: "616px" }, textAlign: "center" }}>
                    Try for free by creating and publishing five inspections. No credit card needed to start!
                  </Typography>
                </Grid>
                <Grid container direction="row" className="form-container" sx={{ pl: 2, pr: 2 }}>
                  <Grid item xs={12} md={6} lg={6} className="center">
                    <FormControl variant="standard">
                      <InputLabel htmlFor="first_name" className="input_lable" sx={{ top: { xs: "18px", md: "17px", lg: "17px" }, fontSize: { xs: "14px", md: "12px", lg: "12px" } }}>
                        FIRST NAME*
                      </InputLabel>
                      <TextField
                        type="text"
                        id="first_name"
                        name="first_name"
                        className="input_field"
                        variant="outlined"
                        placeholder="First name"
                        onChange={(e) => setFirstName(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter" && !loading) validate();
                        }}
                        error={firstNameError}
                        helperText={firstNameErrorText}
                        size={`${matches ? "small" : "medium"}`}
                        sx={{ marginTop: "63px", width: { xs: "340px", md: "317px", lg: "317px" } }}
                        InputProps={{ className: "input_props_field", sx: { height: { xs: "55px", md: "40px", lg: "40px" }, fontSize: { xs: "18px", md: "14px", lg: "14px" } } }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} className="center">
                    <FormControl variant="standard">
                      <InputLabel htmlFor="last_name" className="input_lable" sx={{ top: { xs: "18px", md: "17px", lg: "17px" }, fontSize: { xs: "14px", md: "12px", lg: "12px" } }}>
                        LAST NAME*
                      </InputLabel>
                      <TextField
                        type="text"
                        id="last_name"
                        name="last_name"
                        className="input_field"
                        variant="outlined"
                        placeholder="Last name"
                        onChange={(e) => {
                          setLastName(e.target.value);
                        }}
                        size={`${matches ? "small" : "medium"}`}
                        onKeyPress={(e) => {
                          if (e.key === "Enter" && !loading) validate();
                        }}
                        error={lastNameError}
                        helperText={lastNameErrorText}
                        sx={{ marginTop: "63px", width: { xs: "340px", md: "317px", lg: "317px" } }}
                        InputProps={{ className: "input_props_field", sx: { height: { xs: "55px", md: "40px", lg: "40px" }, fontSize: { xs: "18px", md: "14px", lg: "14px" } } }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" className="form-container" sx={{ pl: 2, pr: 2 }}>
                  <Grid item xs={12} md={6} lg={6} className="center">
                    <FormControl variant="standard">
                      <InputLabel htmlFor="email" className="input_lable" sx={{ top: { xs: "18px", md: "17px", lg: "17px" }, fontSize: { xs: "14px", md: "12px", lg: "12px" } }}>
                        EMAIL ADDRESS*
                      </InputLabel>
                      <TextField
                        type="email"
                        id="email"
                        name="email"
                        className="input_field"
                        variant="outlined"
                        placeholder="Enter email"
                        onChange={(e) => setEmail(e.target.value)}
                        size={`${matches ? "small" : "medium"}`}
                        onKeyPress={(e) => {
                          if (e.key === "Enter" && !loading) validate();
                        }}
                        error={emailError}
                        helperText={emailErrorText}
                        sx={{ marginTop: "63px", width: { xs: "340px", md: "317px", lg: "317px" } }}
                        InputProps={{ className: "input_props_field", sx: { height: { xs: "55px", md: "40px", lg: "40px" }, fontSize: { xs: "18px", md: "14px", lg: "14px" } } }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} className="center">
                    <FormControl variant="standard">
                      <InputLabel htmlFor="password" className="input_lable" sx={{ top: { xs: "18px", md: "17px", lg: "17px" }, fontSize: { xs: "14px", md: "12px", lg: "12px" } }}>
                        PASSWORD*
                      </InputLabel>
                      <TextField
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        className="input_field"
                        variant="outlined"
                        placeholder="Enter password"
                        size={`${matches ? "small" : "medium"}`}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter" && !loading) validate();
                        }}
                        error={passworderror}
                        helperText={passworderror ? passworderrorText : ""}
                        sx={{ marginTop: "63px", width: { xs: "340px", md: "317px", lg: "317px" } }}
                        InputProps={{
                          className: "input_props_field",
                          sx: { height: { xs: "55px", md: "40px", lg: "40px" }, fontSize: { xs: "18px", md: "14px", lg: "14px" } },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton sx={{ mr: "-9px" }} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {/*design change password field added above and company input moved to bottom */}
                <Grid container direction="row" className="form-container" sx={{ pl: 2, pr: 2 }}>
                  <Grid item xs={12} className={matches ? "full__width" : "center"}>
                    <FormControl variant="standard">
                      <InputLabel htmlFor="company_name" className="input_lable" sx={{ top: { xs: "18px", md: "17px", lg: "17px" }, fontSize: { xs: "14px", md: "12px", lg: "12px" } }}>
                        COMPANY NAME
                      </InputLabel>
                      <TextField
                        type="text"
                        className="input_field"
                        id="company_name"
                        name="company_name"
                        variant="outlined"
                        placeholder="Enter company name"
                        size={`${matches ? "small" : "medium"}`}
                        onChange={(e) => setCompany(e.target.value)}
                        onKeyPress={(e) => {
                          if (e.key === "Enter" && !loading) validate();
                        }}
                        // error={companyError}
                        // helperText={companyErrorText}
                        sx={{ marginTop: "63px", width: { xs: "340px", md: "317px", lg: "317px" } }}
                        InputProps={{ className: "input_props_field", sx: { height: { xs: "55px", md: "40px", lg: "40px" }, fontSize: { xs: "18px", md: "14px", lg: "14px" } } }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                {/* terms and conditions********************************************* */}
                <Grid className="center_terms_and_condition mt-20">
                  <FormControlLabel
                    control={<Checkbox onChange={(e) => setAgree(e.target.checked ? 1 : 0)} id="agree_terms" />}
                    label={
                      <div className="terms_and_condition">
                        I agree to the{" "}
                        <a className="terms_and_condition_hyperlink" href="https://www.palmtech.com/terms" rel="noreferrer" target="_blank">
                          Terms of Use
                        </a>{" "}
                        and{" "}
                        <a className="terms_and_condition_hyperlink" href="https://www.palmtech.com/privacy" rel="noreferrer" target="_blank">
                          Privacy Policy.
                        </a>
                      </div>
                    }
                    labelPlacement="end"
                  />
                  <div id="agreement-box-error"></div>
                </Grid>
                {/* signup in button ************************************************* */}
                <Grid className="center">
                  <LoadingButton
                    className="login_button mt-20"
                    sx={{
                      height: { xs: 42, md: 34, lg: 34 },
                      width: { xs: 180, md: 119, lg: 137 },
                      fontSize: { xs: 16, md: 13, lg: 13 },
                    }}
                    size="small"
                    variant="contained"
                    loading={loading}
                    onClick={validate}
                    id="add_company"
                    name="add_new_company"
                  >
                    Create account
                  </LoadingButton>
                </Grid>
                <Grid className="center" sx={{ mt: 1 }}>
                  <Typography className="link_nologin" fontSize={{ xs: "16px", md: "12px", lg: "12px", xl: "12px" }}>
                    Already have a login?&nbsp;
                  </Typography>
                  <a onClick={() => navigate("/")}>
                    <Typography
                      className="link_signup"
                      fontSize={{
                        xs: "16px",
                        md: "12px",
                        lg: "12px",
                        xl: "12px",
                      }}
                      sx={{ color: "#4282E2 !important" }}
                    >
                      Go back.
                    </Typography>
                  </a>
                </Grid>
              </Paper>
            ) : (
              <>
                <Paper
                  className="paper-normal"
                  sx={{
                    minWidth: { xs: 370, md: 360, lg: 494 },
                    minHeight: { xs: 441, md: 387, lg: 359 },
                    boxShadow: "0 30px 30px 0 rgba(40, 40, 40, 0.4)",
                  }}
                >
                  {" "}
                  <Grid className="center">
                    <Typography
                      className="header"
                      sx={{
                        mt: "27px",
                        fontSize: { xs: "24px", md: "22px", lg: "22px" },
                      }}
                    >
                      Sent!
                    </Typography>
                  </Grid>
                  <Grid className="center">
                    <Typography
                      className="header"
                      sx={{
                        mt: "27px",
                        fontSize: { xs: "24px", md: "22px", lg: "14px" },
                        width: "372px",
                        textAlign: "center",
                      }}
                    >
                      Account request received. Check your email for next steps in order to finish creating your account.
                    </Typography>
                  </Grid>
                  <Grid className="center" sx={{ mt: 2 }}>
                    <DraftsOutlinedIcon sx={{ width: "62px", height: "68px", color: "#4282e2" }} />
                  </Grid>
                  <Grid className="center" sx={{ mt: 4 }}>
                    <LoadingButton
                      className="login_button"
                      sx={{
                        height: { xs: 42, md: 34, lg: 34 },
                        width: { xs: 180, md: 119, lg: 119 },
                        fontSize: { xs: 16, md: 13, lg: 13 },
                      }}
                      size="small"
                      variant="contained"
                      loading={false}
                      onClick={() => navigate("/")}
                    >
                      Done
                    </LoadingButton>
                  </Grid>
                </Paper>
              </>
            )}
            {/* Footer starts from here****************************************************************************************************************** */}
            <Grid
              className="center"
              sx={{
                marginTop: { xs: "123px", md: "41px", lg: "41px" },
                marginBottom: { xs: "0px", md: "25px", lg: "25px" },
              }}
            >
              <div className="footer">
                <p>© 2023 Palmtech&nbsp;</p>
                <a href="https://www.palm-tech.com/" rel="noreferrer" target="_blank" className="footer">
                  www.palmtech.com
                </a>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default Signup;