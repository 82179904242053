import { Box, Button, Grid, Paper } from "@mui/material";
import Heading from "../../../common/cardHeading/Heading";
// import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CreditCardIcon from "@mui/icons-material/CreditCard";
const PaymentMethod = (props) => {
  const { data } = props;
  var last = data?.card_number?.substring(data?.card_number?.lastIndexOf("-") + 1, data?.card_number?.length);
  // const openUrl = () => {
  //   window.open(props.billingURL, "_blank");
  // };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Paper elevation={0} className="payment-method-card">
          {/* <Heading CancelledStatus={false} title="Payment Method" icon={<OpenInNewIcon onClick={openUrl} className="edit-card-button" />} iconDesc={<p onClick={openUrl}>Edit card details</p>} /> */}
          <Heading CancelledStatus={false} title="Payment Method" icon={""} iconDesc={""} />
          <div style={{ padding: "21px 18px 27px 18px" }}>
            <div className="payment-method-card-container">
              <Grid container>
                <Grid item xs={0.5} className="credit-card-icon-container">
                  <CreditCardIcon />
                </Grid>
                <Grid item xs={5.5}>
                  <p className="visa-details">
                    {data?.card_type} ending in {last}
                  </p>
                  <p className="visa-expire-details">Expires {data?.card_expiry_date}</p>
                </Grid>
                <Grid item xs={6} className="credit-card-icon-container">
                  {data?.is_plan_active ? <Button className="subscription-button">active subscription</Button> : ""}
                </Grid>
              </Grid>
            </div>
          </div>
        </Paper>
      </Grid>
    </Box>
  );
};

export default PaymentMethod;
