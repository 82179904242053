import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// import { Userpilot } from "userpilot";
// material ui imports
import { Grid, Button, MenuItem, Select } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import TextField from "@mui/material/TextField";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Alert from "@mui/material/Alert";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
// dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DialogContentText from "@mui/material/DialogContentText";

// styles imports
import "../../../assets/styles/containers/_companyProfile.scss";

// component imports
import Preloader from "../../helpers/Preloader";
import {
  onKeyPressed,
  formatZip,
  BootstrapTooltip,
  formatState,
} from "../../utils/services";
import { apiCall } from "../../utils/action";
import validator from "validator";
import Header from "../../common/header/Header";
import Breadcrumb from "../../common/header/Breadcrumb";
import { getTimezoneList } from "country-timezone-list";
var moment = require("moment-timezone");
var timeZones = moment.tz.names();
var offsetTmz = [];

for (var i in timeZones) {
  offsetTmz.push(
    "(GMT" + moment.tz(timeZones[i]).format("Z") + ") " + timeZones[i]
  );
}

const CompanyProfileView = () => {
  const tz = getTimezoneList();
  const [country, setCountry] = useState([]);
  const [timezone, setTimezone] = useState([]);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [showProfRemoveDialog, setShowProfRemoveDialog] = useState(false);
  const [company, setCompany] = useState("");
  const [companyError, setCompanyError] = useState(false);
  const [companyErrorText, setCompanyErrorText] = useState("");
  const [website, setWebsite] = useState("");
  // const [websiteError, setWebsiteError] = useState(false);
  // const [websiteErrorText, setWebsiteErrorText] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [contact, setContact] = useState("");
  // const [contactError, setContactError] = useState(false);
  // const [contactErrorText, setContactErrorText] = useState('');
  const [address, setAddress] = useState("");
  // const [addressError, setAddressError] = useState(false);
  // const [addressErrorText, setAddressErrorText] = useState('');
  const [city, setCity] = useState("");
  // const [cityError, setCityError] = useState(false);
  // const [cityErrorText, setCityErrorText] = useState('');
  const [state, setState] = useState("");
  // const [stateError, setStateError] = useState(false);
  // const [stateErrorText, setStateErrorText] = useState('');
  const [zip, setZip] = useState("");
  // const [zipError, setZipError] = useState(false);
  // const [zipErrorText, setZipErrorText] = useState('');
  const [statement, setStatement] = useState("");
  // const [statementError, setStatementError] = useState(false);
  // const [statementErrorText, setStatementErrorText] = useState('');
  const [billingPlanName, setBillingPlanName] = useState("Standard Plan");
  const [billingRenewalDueDate, setBillingRenewalDueDate] = useState();
  const [teamData, setTeamData] = useState([]);
  const [image, setImage] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteControl, setDeleteControl] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [inputChanged, setInputChanged] = useState(false);
  const fileInput = React.useRef();
  // session datas
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  const uuId = sessionStorage.getItem("user_id");
  let permission = roleId === "7" ? false : true;
  const navigate = useNavigate();

  let is_from_us = sessionStorage.getItem("is_from_us");

  // delete profile image
  const deleteProfileImage = () => {
    setShowLoader(true);
    toast.dismiss();
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      // member_id,
    };

    apiCall(
      (response) => {
        const { message, success } = response.data ? response.data : "";
        if (success) {
          toast.success(message);
          setShowLoader(false);
          setShowProfRemoveDialog(false);
        } else {
          toast.error(message);
          setShowLoader(false);
          setShowProfRemoveDialog(false);
        }
      },
      formdata,
      "DELETE_PT_LOGO"
    );
  };

  const dateFormat = (date) => {
    if (date === null) setBillingRenewalDueDate(date);
    else {
      let date_availiable = new Date(date);
      let date_month = date_availiable.getMonth() + 1;
      let date_day_without_0 = date_availiable.getDate();
      let date_day = date_day_without_0.toString().padStart(2, "0");
      let date_year = date_availiable.getFullYear().toString().substr(-2);
      setBillingRenewalDueDate(`${date_month}/${date_day}/${date_year}`);
    }
  };

  // get companyy data from api
  const getCompanyProfile = () => {
    setShowLoader(true);
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          setCompanyDetails(data.company_details);
          setTeamData(data.team_members);
          setCompany(data.company_details.name);
          setWebsite(data.company_details.website);
          setEmail(data.company_details.email);
          setContact(
            data.company_details.phone === "--"
              ? null
              : data.company_details.phone
          );
          setAddress(data.company_details.address_1);
          setCity(data.company_details.city);
          setState(data.company_details.state);
          setZip(data.company_details.zip);
          setStatement(data.company_details.company_statement);
          setImage(data.company_details.avatar);
          setBillingPlanName(data?.company_details?.billing_plan);
          dateFormat(data?.company_details?.billing_renewal_due_date);
          setCountry(data?.company_details?.is_from_us);
          setTimezone(data?.company_details?.timezone);
          sessionStorage.setItem(
            "is_from_us",
            data?.company_details?.is_from_us
          );
          setShowLoader(false);
        } else {
          console.log("else ", message);
          toast.error(message);
        }
      },

      formdata,
      "GET_COMPANY_PROFILE"
    );
  };

  // get comapny info (anil)*****************************************
  const getCompanyInfo = (company_id, user_id, role_id) => {
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    apiCall(
      (response) => {
        if (response.data.success) {
          sessionStorage.setItem(
            "company_name",
            response.data.data.company_details.name
          );
          sessionStorage.setItem("is_trial", response.data.is_trial);
          setDeleteControl(!deleteControl);
        } else {
          console.log("else", response.data.message);
        }
      },
      formdata,
      "GET_COMPANY_INFO"
    );
  };

  // edit company data
  const editCompanyProfile = (img, status) => {
    // setShowLoader(true);
    toast.dismiss();
    let formData = new FormData();
    const params = {
      country: country,
      timezone: timezone,
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      avatar: status
        ? typeof img === "string" || img === null
          ? ""
          : img
        : typeof image === "string" || image === null
        ? ""
        : image,
      name: company,
      website: website === null ? "" : website,
      email,
      phone: contact === null ? "" : contact === "--" ? "" : contact,
      address_1: address === null ? "" : address,
      city: city === null ? "" : city,
      state: state === null ? "" : state,
      zip: zip === null ? "" : zip,
      company_statement: statement === null ? "" : statement,
    };

    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    apiCall(
      (response) => {
        const { message, success, status_code } = response.data;
        if (success) {
          toast.dismiss();
          toast.success(message);
          setShowLoader(false);
          getCompanyInfo();
        } else {
          toast.dismiss();
          toast.error(message);
          setShowLoader(false);
          if (
            status_code === "422" &&
            message === "Logo must not be greater then 2 MB."
          ) {
            setImage("");
          }
          console.log("else ", message);
        }
      },
      formData,
      "EDIT_COMPANY_DETAILS"
    );
  };

  // get role list function and API call
  const getRoleListsAndEdit = (id, name) => {
    setShowLoader(true);
    const formdata = { user_id: uuId, role_id: roleId, company_id: companyId };
    apiCall(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          // toast.success(message);
          // setShowLoader(false);
          navigate(`/settings/team/${name}`, {
            state: { id, edit: true, roleList: data },
          });
        } else {
          console.log("else ", message);
        }
      },
      formdata,
      "GET_ROLE_LIST"
    );
  };

  // delete member function and API call
  const deleteTeamMember = (id) => {
    setShowLoader(true);
    const formdata = {
      company_id: companyId,
      user_id: uuId,
      role_id: roleId,
      member_id: id,
    };
    handleCloseAlert();
    apiCall(
      (response) => {
        const { message, success } = response.data;
        if (success) {
          toast.success(message);
          setDeleteControl(!deleteControl);
          setShowLoader(false);
        } else {
          console.log("else ", message);
        }
      },
      formdata,
      "REMOVE_MEMBER"
    );
  };

  const validate = () => {
    if (company === "" || company === null) {
      setCompanyError(true);
      setCompanyErrorText("Company name is required");
    } else setCompanyError(false);
    if (email === "" || email === null) {
      setEmailError(true);
      setEmailErrorText("Email is required");
    } else if (!validator.isEmail(email)) {
      setEmailError(true);
      setEmailErrorText("Enter correct email format");
    } else if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      setEmailError(true);
      setEmailErrorText("Enter correct email format");
    } else setEmailError(false);
    // if (!/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i.test(website)) {
    //   setWebsiteError(true);
    //   setWebsiteErrorText("Enter correct url format");
    // }else
    // setWebsiteError(false);
    if (
      company !== "" &&
      company !== null &&
      email !== "" &&
      email !== null &&
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      ) &&
      // && (/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g).test(website)
      validator.isEmail(email)
    )
      editCompanyProfile("", false);
  };

  const handleZipChange = (e) => {
    const formattedZip = e.target.value;
    setZip(formattedZip);
    setInputChanged(true);
  };
  const handleContactChange = (e) => {
    setContact(e.target.value);
    setInputChanged(true);
  };
  const handleClickOpenAlert = (id) => {
    setDeleteId(id);
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  console.log(image);

  const card = (
    <>
      <div className="profile-header">
        <span>Logo</span>
        {permission && (
          <span
            className="edit-section"
            onClick={() => fileInput.current.click()}
          >
            <EditIcon />
            <span>Edit</span>
          </span>
        )}
      </div>
      {/* <CardHeader
        action={
          <EditIcon onClick={() => fileInput.current.click()}>Edit</EditIcon>
        }
        title="Logo"
        className="team-profile-header"
      /> */}
      <input
        ref={fileInput}
        type="file"
        accept="image/*"
        onChange={(e) => {
          setImage(e.target.files[0]);
          editCompanyProfile(e.target.files[0], true);
        }}
        style={{ display: "none" }}
      />
      {image && (
        <div className="prof-img-wrapper">
          <img
            className="team-prof-img"
            style={{ maxHeight: "320px", maxWidth: "100%" }}
            src={typeof image === "string" ? image : URL.createObjectURL(image)}
            alt="Thumb"
          />
          <p
            onClick={() => {
              setShowProfRemoveDialog(true);
            }}
          >
            <DeleteIcon style={{ color: "#E30303" }} />
            <span>Delete photo</span>
          </p>
          {/* <span onClick={()=>setImage()} style={{ cursor: "pointer", padding: 5, background: "red", color: "white", border: "none" }}>
                Remove Image
              </span> */}
        </div>
      )}
      {!image && (
        <CardMedia
          component="img"
          className="team-prof-img"
          alt="Company Profile"
          src={
            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
          }
        />
      )}
    </>
  );
  const permissionCard = (
    <>
      <Grid container className="company-permission-card">
        <Grid item xs={12}>
          Billing plan
        </Grid>
        <p>{billingPlanName}</p>
        <Grid item xs={12} sx={{ marginTop: "7px" }}>
          <span className="team-permision-text">
            {billingRenewalDueDate !== null ? (
              <>
                Will renew on <br />{" "}
                <span className="date-placeholder">
                  {billingRenewalDueDate}
                </span>
              </>
            ) : (
              "No expiry date."
            )}
          </span>{" "}
        </Grid>
        <Button
          className="default-btn teams-detail-btn"
          sx={{
            textTransform: "none !important",
            ":hover": { backgroundColor: "#1E8970 !important" },
          }}
        >
          {" "}
          Change plan
        </Button>
      </Grid>
    </>
  );
  const detailsCard = (
    <>
      <Grid container className="team-details-card">
        <Grid
          item
          xs={12}
          className="team-details-cardTitle"
          style={{ marginBottom: "16px" }}
        >
          <span>Contact Info</span>
        </Grid>
        <Grid container>
          {/* commented timezone as per latest discussion */}
          {/* <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="Company"
              >
               Time Zone
              </label>
                 <Select
                   disabled={!permission}
               className="input-textfield"
               size="small"
               variant="outlined"
               value={timezone}
               onChange={(e) => {setTimezone(e.target.value);setInputChanged(true)}}
               placeholder="TimeZone"
               singleValue
              >
                  
									<MenuItem value="Pacific/Midway" >(UTC-11:00) Midway Island</MenuItem>
									<MenuItem value="Pacific/Samoa" >(UTC-11:00) Samoa</MenuItem>
									<MenuItem value="Pacific/Honolulu">(UTC-10:00) Hawaii</MenuItem>
									<MenuItem value="US/Alaska">(UTC-09:00) Alaska</MenuItem>
									<MenuItem value="America/Los_Angeles">(UTC-08:00) Pacific Time (US &amp; Canada)</MenuItem>
									<MenuItem value="America/Tijuana" >(UTC-08:00) Tijuana</MenuItem>
									<MenuItem value="US/Arizona">(UTC-07:00) Arizona</MenuItem>
									<MenuItem value="America/Chihuahua">(UTC-07:00) Chihuahua</MenuItem>
									<MenuItem value="America/Chihuahua">(UTC-07:00) La Paz</MenuItem>
									<MenuItem value="America/Mazatlan">(UTC-07:00) Mazatlan</MenuItem>
									<MenuItem value="US/Mountain">(UTC-07:00) Mountain Time (US &amp; Canada)</MenuItem>
									<MenuItem value="America/Managua">(UTC-06:00) Central America</MenuItem>
									<MenuItem value="US/Central">(UTC-06:00) Central Time (US &amp; Canada)</MenuItem>
									<MenuItem value="America/Mexico_City">(UTC-06:00) Guadalajara</MenuItem>
									<MenuItem value="America/Mexico_City">(UTC-06:00) Mexico City</MenuItem>
									<MenuItem value="America/Monterrey">(UTC-06:00) Monterrey</MenuItem>
									<MenuItem value="Canada/Saskatchewan">(UTC-06:00) Saskatchewan</MenuItem>
									<MenuItem value="America/Bogota">(UTC-05:00) Bogota</MenuItem>
									<MenuItem value="US/Eastern">(UTC-05:00) Eastern Time (US &amp; Canada)</MenuItem>
									<MenuItem value="US/East-Indiana">(UTC-05:00) Indiana (East)</MenuItem>
									<MenuItem value="America/Lima">(UTC-05:00) Lima</MenuItem>
									<MenuItem value="America/Bogota">(UTC-05:00) Quito</MenuItem>
									<MenuItem value="Canada/Atlantic">(UTC-04:00) Atlantic Time (Canada)</MenuItem>
									<MenuItem value="America/Caracas">(UTC-04:30) Caracas</MenuItem>
									<MenuItem value="America/La_Paz">(UTC-04:00) La Paz</MenuItem>
									<MenuItem value="America/Santiago">(UTC-04:00) Santiago</MenuItem>
									<MenuItem value="Canada/Newfoundland">(UTC-03:30) Newfoundland</MenuItem>
									<MenuItem value="America/Sao_Paulo">(UTC-03:00) Brasilia</MenuItem>
									<MenuItem value="America/Argentina/Buenos_Aires">(UTC-03:00) Buenos Aires</MenuItem>
									<MenuItem value="America/Argentina/Buenos_Aires">(UTC-03:00) Georgetown</MenuItem>
									<MenuItem value="America/Godthab">(UTC-03:00) Greenland</MenuItem>
									<MenuItem value="America/Noronha">(UTC-02:00) Mid-Atlantic</MenuItem>
									<MenuItem value="Atlantic/Azores">(UTC-01:00) Azores</MenuItem>
									<MenuItem value="Atlantic/Cape_Verde">(UTC-01:00) Cape Verde Is.</MenuItem>
									<MenuItem value="Africa/Casablanca">(UTC+00:00) Casablanca</MenuItem>
									<MenuItem value="Europe/London">(UTC+00:00) Edinburgh</MenuItem>
									<MenuItem value="Etc/Greenwich">(UTC+00:00) Greenwich Mean Time : Dublin</MenuItem>
									<MenuItem value="Europe/Lisbon">(UTC+00:00) Lisbon</MenuItem>
									<MenuItem value="Europe/London">(UTC+00:00) London</MenuItem>
									<MenuItem value="Africa/Monrovia">(UTC+00:00) Monrovia</MenuItem>
									<MenuItem value="UTC">(UTC+00:00) UTC</MenuItem>
									<MenuItem value="Europe/Amsterdam">(UTC+01:00) Amsterdam</MenuItem>
									<MenuItem value="Europe/Belgrade">(UTC+01:00) Belgrade</MenuItem>
									<MenuItem value="Europe/Berlin">(UTC+01:00) Berlin</MenuItem>
									<MenuItem value="Europe/Berlin">(UTC+01:00) Bern</MenuItem>
									<MenuItem value="Europe/Bratislava">(UTC+01:00) Bratislava</MenuItem>
									<MenuItem value="Europe/Brussels">(UTC+01:00) Brussels</MenuItem>
									<MenuItem value="Europe/Budapest">(UTC+01:00) Budapest</MenuItem>
									<MenuItem value="Europe/Copenhagen">(UTC+01:00) Copenhagen</MenuItem>
									<MenuItem value="Europe/Ljubljana">(UTC+01:00) Ljubljana</MenuItem>
									<MenuItem value="Europe/Madrid">(UTC+01:00) Madrid</MenuItem>
									<MenuItem value="Europe/Paris">(UTC+01:00) Paris</MenuItem>
									<MenuItem value="Europe/Prague">(UTC+01:00) Prague</MenuItem>
									<MenuItem value="Europe/Rome">(UTC+01:00) Rome</MenuItem>
									<MenuItem value="Europe/Sarajevo">(UTC+01:00) Sarajevo</MenuItem>
									<MenuItem value="Europe/Skopje">(UTC+01:00) Skopje</MenuItem>
									<MenuItem value="Europe/Stockholm">(UTC+01:00) Stockholm</MenuItem>
									<MenuItem value="Europe/Vienna">(UTC+01:00) Vienna</MenuItem>
									<MenuItem value="Europe/Warsaw">(UTC+01:00) Warsaw</MenuItem>
									<MenuItem value="Africa/Lagos">(UTC+01:00) West Central Africa</MenuItem>
									<MenuItem value="Europe/Zagreb">(UTC+01:00) Zagreb</MenuItem>
									<MenuItem value="Europe/Athens">(UTC+02:00) Athens</MenuItem>
									<MenuItem value="Europe/Bucharest">(UTC+02:00) Bucharest</MenuItem>
									<MenuItem value="Africa/Cairo">(UTC+02:00) Cairo</MenuItem>
									<MenuItem value="Africa/Harare">(UTC+02:00) Harare</MenuItem>
									<MenuItem value="Europe/Helsinki">(UTC+02:00) Helsinki</MenuItem>
									<MenuItem value="Europe/Istanbul">(UTC+02:00) Istanbul</MenuItem>
									<MenuItem value="Asia/Jerusalem">(UTC+02:00) Jerusalem</MenuItem>
									<MenuItem value="Europe/Helsinki">(UTC+02:00) Kyiv</MenuItem>
									<MenuItem value="Africa/Johannesburg">(UTC+02:00) Pretoria</MenuItem>
									<MenuItem value="Europe/Riga">(UTC+02:00) Riga</MenuItem>
									<MenuItem value="Europe/Sofia">(UTC+02:00) Sofia</MenuItem>
									<MenuItem value="Europe/Tallinn">(UTC+02:00) Tallinn</MenuItem>
									<MenuItem value="Europe/Vilnius">(UTC+02:00) Vilnius</MenuItem>
									<MenuItem value="Asia/Baghdad">(UTC+03:00) Baghdad</MenuItem>
									<MenuItem value="Asia/Kuwait">(UTC+03:00) Kuwait</MenuItem>
									<MenuItem value="Europe/Minsk">(UTC+03:00) Minsk</MenuItem>
									<MenuItem value="Africa/Nairobi">(UTC+03:00) Nairobi</MenuItem>
									<MenuItem value="Asia/Riyadh">(UTC+03:00) Riyadh</MenuItem>
									<MenuItem value="Europe/Volgograd">(UTC+03:00) Volgograd</MenuItem>
									<MenuItem value="Asia/Tehran">(UTC+03:30) Tehran</MenuItem>
									<MenuItem value="Asia/Muscat">(UTC+04:00) Abu Dhabi</MenuItem>
									<MenuItem value="Asia/Baku">(UTC+04:00) Baku</MenuItem>
									<MenuItem value="Europe/Moscow">(UTC+04:00) Moscow</MenuItem>
									<MenuItem value="Asia/Muscat">(UTC+04:00) Muscat</MenuItem>
									<MenuItem value="Europe/Moscow">(UTC+04:00) St. Petersburg</MenuItem>
									<MenuItem value="Asia/Tbilisi">(UTC+04:00) Tbilisi</MenuItem>
									<MenuItem value="Asia/Yerevan">(UTC+04:00) Yerevan</MenuItem>
									<MenuItem value="Asia/Kabul">(UTC+04:30) Kabul</MenuItem>
									<MenuItem value="Asia/Karachi">(UTC+05:00) Islamabad</MenuItem>
									<MenuItem value="Asia/Karachi">(UTC+05:00) Karachi</MenuItem>
									<MenuItem value="Asia/Tashkent">(UTC+05:00) Tashkent</MenuItem>
									<MenuItem value="Asia/Calcutta" selected="">(UTC+05:30) Chennai</MenuItem>
									<MenuItem value="Asia/Kolkata">(UTC+05:30) Kolkata</MenuItem>
									<MenuItem value="Asia/Calcutta" selected="">(UTC+05:30) Mumbai</MenuItem>
									<MenuItem value="Asia/Calcutta" selected="">(UTC+05:30) New Delhi</MenuItem>
									<MenuItem value="Asia/Calcutta" selected="">(UTC+05:30) Sri Jayawardenepura</MenuItem>
									<MenuItem value="Asia/Katmandu">(UTC+05:45) Kathmandu</MenuItem>
									<MenuItem value="Asia/Almaty">(UTC+06:00) Almaty</MenuItem>
									<MenuItem value="Asia/Dhaka">(UTC+06:00) Astana</MenuItem>
									<MenuItem value="Asia/Dhaka">(UTC+06:00) Dhaka</MenuItem>
									<MenuItem value="Asia/Yekaterinburg">(UTC+06:00) Ekaterinburg</MenuItem>
									<MenuItem value="Asia/Rangoon">(UTC+06:30) Rangoon</MenuItem>
									<MenuItem value="Asia/Bangkok">(UTC+07:00) Bangkok</MenuItem>
									<MenuItem value="Asia/Bangkok">(UTC+07:00) Hanoi</MenuItem>
									<MenuItem value="Asia/Jakarta">(UTC+07:00) Jakarta</MenuItem>
									<MenuItem value="Asia/Novosibirsk">(UTC+07:00) Novosibirsk</MenuItem>
									<MenuItem value="Asia/Hong_Kong">(UTC+08:00) Beijing</MenuItem>
									<MenuItem value="Asia/Chongqing">(UTC+08:00) Chongqing</MenuItem>
									<MenuItem value="Asia/Hong_Kong">(UTC+08:00) Hong Kong</MenuItem>
									<MenuItem value="Asia/Krasnoyarsk">(UTC+08:00) Krasnoyarsk</MenuItem>
									<MenuItem value="Asia/Kuala_Lumpur">(UTC+08:00) Kuala Lumpur</MenuItem>
									<MenuItem value="Australia/Perth">(UTC+08:00) Perth</MenuItem>
									<MenuItem value="Asia/Singapore">(UTC+08:00) Singapore</MenuItem>
									<MenuItem value="Asia/Taipei">(UTC+08:00) Taipei</MenuItem>
									<MenuItem value="Asia/Ulan_Bator">(UTC+08:00) Ulaan Bataar</MenuItem>
									<MenuItem value="Asia/Urumqi">(UTC+08:00) Urumqi</MenuItem>
									<MenuItem value="Asia/Irkutsk">(UTC+09:00) Irkutsk</MenuItem>
									<MenuItem value="Asia/Tokyo">(UTC+09:00) Osaka</MenuItem>
									<MenuItem value="Asia/Tokyo">(UTC+09:00) Sapporo</MenuItem>
									<MenuItem value="Asia/Seoul">(UTC+09:00) Seoul</MenuItem>
									<MenuItem value="Asia/Tokyo">(UTC+09:00) Tokyo</MenuItem>
									<MenuItem value="Australia/Adelaide">(UTC+09:30) Adelaide</MenuItem>
									<MenuItem value="Australia/Darwin">(UTC+09:30) Darwin</MenuItem>
									<MenuItem value="Australia/Brisbane">(UTC+10:00) Brisbane</MenuItem>
									<MenuItem value="Australia/Canberra">(UTC+10:00) Canberra</MenuItem>
									<MenuItem value="Pacific/Guam">(UTC+10:00) Guam</MenuItem>
									<MenuItem value="Australia/Hobart">(UTC+10:00) Hobart</MenuItem>
									<MenuItem value="Australia/Melbourne">(UTC+10:00) Melbourne</MenuItem>
									<MenuItem value="Pacific/Port_Moresby">(UTC+10:00) Port Moresby</MenuItem>
									<MenuItem value="Australia/Sydney">(UTC+10:00) Sydney</MenuItem>
									<MenuItem value="Asia/Yakutsk">(UTC+10:00) Yakutsk</MenuItem>
									<MenuItem value="Asia/Vladivostok">(UTC+11:00) Vladivostok</MenuItem>
									<MenuItem value="Pacific/Auckland">(UTC+12:00) Auckland</MenuItem>
									<MenuItem value="Pacific/Fiji">(UTC+12:00) Fiji</MenuItem>
									<MenuItem value="Pacific/Kwajalein">(UTC+12:00) International Date Line West</MenuItem>
									<MenuItem value="Asia/Kamchatka">(UTC+12:00) Kamchatka</MenuItem>
									<MenuItem value="Asia/Magadan">(UTC+12:00) Magadan</MenuItem>
									<MenuItem value="Pacific/Fiji">(UTC+12:00) Marshall Is.</MenuItem>
									<MenuItem value="Asia/Magadan">(UTC+12:00) New Caledonia</MenuItem>
									<MenuItem value="Asia/Magadan">(UTC+12:00) Solomon Is.</MenuItem>
									<MenuItem value="Pacific/Auckland">(UTC+12:00) Wellington</MenuItem>
									<MenuItem value="Pacific/Tongatapu">(UTC+13:00) Nuku'alofa</MenuItem>
              </Select>
            </FormControl>
        </Grid> 
        */}
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label className="input-form-label" htmlFor="Company">
                Country
              </label>
              <Select
                disabled={!permission}
                className="input-textfield"
                size="small"
                variant="outlined"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  setInputChanged(true);
                }}
                //  displayEmpty
                // error={usePorchViaISNError}
                placeholder="Country"
              >
                <MenuItem key="us" value="1">
                  US
                </MenuItem>
                <MenuItem key="Canada" value="2">
                  Canada
                </MenuItem>
                <MenuItem key="other" value="0">
                  Others
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label className="input-form-label" htmlFor="Company">
                Company*
              </label>
              <TextField
                disabled={!permission}
                id="Company"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Company"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                  setInputChanged(true);
                }}
                error={companyError}
                helperText={`${companyError ? companyErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="website"
                id="demo-simple-select-label"
              >
                Website
              </label>
              <TextField
                disabled={!permission}
                id="website"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Website"
                value={website === "null" ? "" : website}
                onChange={(e) => {
                  setWebsite(e.target.value);
                  setInputChanged(true);
                }}
                error={
                  website
                    ? !/^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/.test(
                        website
                      )
                    : false
                }
                helperText={
                  website
                    ? !/^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/.test(
                        website
                      )
                      ? "Enter correct website url"
                      : ""
                    : ""
                }
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="standard"
              className="forms-control field-reset"
            >
              <label
                className="input-form-label"
                htmlFor="email"
                id="demo-simple-select-label"
              >
                Email Address*
              </label>
              <TextField
                disabled={!permission}
                id="email"
                className="input-textfield "
                size="small"
                variant="outlined"
                placeholder="Email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setInputChanged(true);
                }}
                required
                // error={emailError}
                // helperText={`${emailError ? emailErrorText : ""}`}
                error={emailError}
                helperText={`${emailError ? emailErrorText : ""}`}
                type="email"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="phone"
                id="demo-simple-select-label"
              >
                Phone
              </label>
              <TextField
                disabled={!permission}
                id="phone"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Phone"
                value={contact}
                onChange={(e) => {
                  handleContactChange(e);
                  setInputChanged(true);
                }}
                // onChange={(e) => setContact(e.target.value)}
                // error={contactError}
                // helperText={`${contactError ? contactErrorText : ""}`}

                helperText={
                  contact &&
                  !/^(?:\+91|0|1)?[ -]?(?:(?:\()?[0-9]{3}(?:\))?[ -]?[0-9]{3}[ -]?[0-9]{4}|[0-9]{10}|(?:[24-9][0-9]{2}|3[01][0-9]{2}|5[56][0-9]{2}|6[0-9]{3})[ -]?[0-9]{4}|(?:8(?:00|55|66|77|88))[ -]?[0-9]{3}[ -]?[0-9]{4})$/.test(
                    contact
                  )
                    ? "Please enter a valid phone number"
                    : ""
                }
                error={
                  contact
                    ? !/^(?:\+91|0|1)?[ -]?(?:(?:\()?[0-9]{3}(?:\))?[ -]?[0-9]{3}[ -]?[0-9]{4}|[0-9]{10}|(?:[24-9][0-9]{2}|3[01][0-9]{2}|5[56][0-9]{2}|6[0-9]{3})[ -]?[0-9]{4}|(?:8(?:00|55|66|77|88))[ -]?[0-9]{3}[ -]?[0-9]{4})$/.test(
                        contact
                      )
                    : false
                }
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="Address1"
                id="demo-simple-select-label"
              >
                Address
              </label>
              <TextField
                disabled={!permission}
                id="Address1"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Address"
                value={address}
                onChange={(e) => {
                  setAddress(e.target.value);
                  setInputChanged(true);
                }}
                // error={addressError}
                // helperText={`${addressError ? addressErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="city"
                id="demo-simple-select-label"
              >
                City
              </label>
              <TextField
                disabled={!permission}
                id="city"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="City"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                  setInputChanged(true);
                }}
                inputProps={{ maxLength: 25 }}
                // error={cityError}
                // helperText={`${cityError ? cityErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="forms-control">
              {Number(is_from_us) === 1 ? (
                <label
                  className="input-form-label"
                  htmlFor="state"
                  id="demo-simple-select-label"
                >
                  State
                </label>
              ) : (
                <label
                  className="input-form-label"
                  htmlFor="state"
                  id="demo-simple-select-label"
                >
                  Province
                </label>
              )}
              <TextField
                disabled={!permission}
                id="state"
                className="input-textfield"
                size="small"
                variant="outlined"
                inputProps={{ maxLength: 2 }}
                placeholder={Number(is_from_us) === 1 ? "State" : "Province"}
                value={state}
                onChange={(e) => {
                  setState(formatState(e.target.value.toUpperCase()));
                  setInputChanged(true);
                }}
                // error={stateError}
                // helperText={`${stateError ? stateErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" className="forms-control">
              {Number(is_from_us) === 1 ? (
                <label
                  className="input-form-label"
                  htmlFor="zip"
                  id="demo-simple-select-label"
                >
                  Zip Code
                </label>
              ) : (
                <label
                  className="input-form-label"
                  htmlFor="zip"
                  id="demo-simple-select-label"
                >
                  Postal code
                </label>
              )}
              <TextField
                disabled={!permission}
                id="zip"
                className="input-textfield"
                size="small"
                variant="outlined"
                value={zip}
                inputProps={{ maxLength: 6 }}
                placeholder={Number(is_from_us) === 1 ? "Zip" : "Postal"}
                onKeyDown={onKeyPressed}
                // onChange={(e) => setZip(e.target.value)}
                onChange={(e) => {
                  handleZipChange(e);
                  setInputChanged(true);
                }}
                // error={zipError}
                // helperText={`${zipError ? zipErrorText : ""}`}
                type="tel"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormControl variant="standard" className="forms-control">
              <label
                className="input-form-label"
                htmlFor="statement"
                id="demo-simple-select-label"
              >
                Our company statement
              </label>
              <TextareaAutosize
                disabled={!permission}
                className="textArea"
                id="statement"
                aria-label="minimum height"
                minRows={3}
                placeholder=""
                style={{ width: "100%", resize: "none" }}
                onChange={(e) => {
                  setStatement(e.target.value);
                  setInputChanged(true);
                }}
                // error={statementError}
                // helperText={statementError ? statementErrorText : ""}
                value={statement}
                InputProps={{
                  inputComponent: TextareaAutosize,
                }}
              />
            </FormControl>
          </Grid>
          {permission && (
            <Grid item xs={12} className="team-detail-cta">
              <Button
                className="back-btn"
                style={{ visibility: "hidden" }}
                id="cancel_company"
                //   onClick={() => navigate("/settings/team")}
              >
                Cancel
              </Button>
              <Button
                disabled={!inputChanged}
                onClick={validate}
                className="default-btn teams-detail-btn"
                id="update_company"
              >
                {/* {editTeam ? "Update" : "Finish"} */} Update
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
  const teamsCard = (
    <>
      <Grid
        container
        //   className="team-details-card"
      >
        <Grid
          item
          xs={12}
          className="profile-details-cardTitle"
          style={{ marginBottom: "16px" }}
        >
          <span>Team</span>
        </Grid>
        <hr className="bottom-border" />
        {teamData.length !== 0 ? (
          teamData.map((teamlist, key) => {
            let currentUser = teamlist.id === Number(uuId) ? true : false;
            return (
              <>
                <Grid
                  key={key}
                  container
                  className="profile-details-cardDetail p-15"
                >
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={1}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Avatar
                      alt="Remy Sharp"
                      className="teamlist-avatar"
                      src={teamlist.avatar}
                    />
                  </Grid>
                  <Grid item xs={10} sm={10} md={11} className="team-avatar">
                    <Grid container>
                      <Grid item xs={12} className="member-row">
                        <div className="member-name flex-center team-role">
                          {`${teamlist.first_name} ${
                            teamlist.last_name === null
                              ? ""
                              : teamlist.last_name
                          }`}
                          {teamlist.role_name.includes("Owner") ||
                          teamlist.role_name.includes("Admin") ? (
                            <VerifiedUserIcon
                              className="owner-icon"
                              style={{ marginLeft: "10px" }}
                            />
                          ) : (
                            <BusinessCenterIcon
                              className="insp-icon"
                              style={{ marginLeft: "10px" }}
                            />
                          )}
                        </div>
                        <div className="member-action flex-center">
                          {roleId === "6" && teamlist.role_name === "Owner" ? (
                            <>
                              <BootstrapTooltip
                                title={
                                  <h1 className="font-13">
                                    You cannot edit this record
                                  </h1>
                                }
                              >
                                <EditIcon
                                  sx={{
                                    opacity: "0.25",
                                    fontSize: 18,
                                    cursor: "pointer",
                                  }}
                                />
                              </BootstrapTooltip>
                              <BootstrapTooltip
                                title={<h1>You cannot delete this record</h1>}
                              >
                                <DeleteIcon
                                  sx={{
                                    opacity: "0.25",
                                    color: "red",
                                    fontSize: 18,
                                    cursor: "pointer",
                                  }}
                                />
                              </BootstrapTooltip>
                            </>
                          ) : (
                            <>
                              <BootstrapTooltip title={<h1>Edit details</h1>}>
                                <EditIcon
                                  className="edit-icon"
                                  onClick={() =>
                                    getRoleListsAndEdit(
                                      teamlist.id,
                                      teamlist.first_name
                                    )
                                  }
                                />
                              </BootstrapTooltip>
                              <BootstrapTooltip
                                title={
                                  <h1>
                                    {!currentUser
                                      ? "Delete record"
                                      : "You cannot delete yourself"}
                                  </h1>
                                }
                              >
                                <DeleteIcon
                                  className={!currentUser && "delete-icon"}
                                  sx={{
                                    opacity: "0.25",
                                    color: "red",
                                    fontSize: 18,
                                    cursor: "pointer",
                                  }}
                                  onClick={
                                    !currentUser
                                      ? () => handleClickOpenAlert(teamlist.id)
                                      : ""
                                  }
                                />
                              </BootstrapTooltip>
                            </>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <span className="member-detail">{teamlist.email}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <hr className="bottom-border" />
              </>
            );
          })
        ) : (
          <Grid container className="profile-details-cardDetail p-15">
            <Alert
              style={{ alignItems: "center", width: "100%" }}
              severity="error"
            >
              No team members
            </Alert>
          </Grid>
        )}
      </Grid>
    </>
  );

  useEffect(() => {
    getCompanyProfile();
    // PALM 10151
    // Userpilot.track("Open Company Profile");
  }, [deleteControl]);

  return (
    <>
      <Header />
      <Breadcrumb />
      <Preloader showPreloader={showLoader} />
      {showLoader ? (
        ""
      ) : (
        <Grid container className="teams-container">
          <Grid item xs={12} className="details-title">
            <span className="team-user-name">Company Profile</span>
            <p>
              Keep your company information up-to-date. Your clients will use
              this to get in touch with you.{" "}
            </p>
          </Grid>
          <Box>
            {/* ------------------delete profile image alert dialog------------------------ */}
            <Dialog
              open={showProfRemoveDialog}
              onClose={() => setShowProfRemoveDialog(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="delete-alert-modal"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete confirmation"}
                <CloseIcon
                  className="close-icon"
                  onClick={() => setShowProfRemoveDialog(false)}
                />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure to delete the profile image?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className="delete-modal-cancel-btn"
                  onClick={() => setShowProfRemoveDialog(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="default-btn"
                  variant="contained"
                  color="error"
                  onClick={() => {
                    deleteProfileImage();
                    setImage("");
                  }}
                  autoFocus
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>

            {/* ------------------delete alert dialog------------------------ */}
            <Dialog
              open={openAlert}
              onClose={handleCloseAlert}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="delete-alert-modal"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete team member?"}
                <CloseIcon className="close-icon" onClick={handleCloseAlert} />
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This action cannot be undone.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  className="delete-modal-cancel-btn"
                  onClick={handleCloseAlert}
                >
                  Cancel
                </Button>
                <Button
                  className="default-btn"
                  variant="contained"
                  color="error"
                  onClick={() => {
                    deleteTeamMember(deleteId);
                  }}
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={3}>
                <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
                  <Card variant="outlined">{card}</Card>
                </Box>
                <Box sx={{ minWidth: 145 }}>
                  <Card variant="outlined">{permissionCard}</Card>
                </Box>
              </Grid>
              <Grid item xs={12} sm={9}>
                <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
                  <Card variant="outlined">{detailsCard}</Card>
                </Box>
                {permission && (
                  <Box sx={{ minWidth: 145, marginBottom: "28px" }}>
                    <Card variant="outlined">{teamsCard}</Card>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default CompanyProfileView;
