import {  Button, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AlertImage from "../../../../../assets/images/alert.png";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { updateElementJsonByUUID } from "../PtTemplateHelperFunctions";

const InformationTypeChangeModal = ({openInfoModal,handleCloseInfoModal,setInfoType,setEditorContent,catuuid,templateState,setTemplateState,updatePtTempateData,uuid}) => {
    const handleInfoChangeConfirm=()=>{
        setEditorContent('')
        setInfoType("InformationText/Plain")
        let prevData = JSON.parse(JSON.stringify(templateState?.templateDataCopy));
        setTemplateState(prev=>({...prev,updatingTemplate:true}))
        const updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, "InformationText/Plain",'infoType')
        if (updatedData) {
          prevData.data = updatedData;
          setTemplateState(prev=>({...prev,templateData:prevData}))
          let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
          let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
          let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
          newUpdatedSpecificData.data.template.pdf_categories = editedCategory
          updatePtTempateData(newUpdatedSpecificData?.data?.template)
    }
        handleCloseInfoModal();
    }
  return (
      <Dialog
      open={openInfoModal}
      onClose={handleCloseInfoModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="pt-info-template-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <CloseIcon className="close-icon" onClick={handleCloseInfoModal} />
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} sx={{ textAlign: "center",mt:'20px' }}>
          <img
            src={AlertImage}
            alt="alert"
            style={{ width: "51px", height: "49px" }}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography className="template_delete_modal_text">
            {`Change to Plain Text`}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              color: "#333333",
              fontFamily: "open sans",
              fontSize: "14px",
              mt: "15px",
              letterSpacing: 0,
            }}
          >
           Changing from HTML to plain text will remove all styling for this section. This cannot be undone. Do you want to continue?
          </Typography>
        </Grid>
        <DialogContentText id="alert-dialog-description">
          {/* This action cannot be undone. */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ ":hover": { backgroundColor: "transparent !important" } }}
          className="template-delete-modal-cancel-btn"
          onClick={handleCloseInfoModal}
        >
          Cancel
        </Button>
        <Button
          sx={{ ":hover": { backgroundColor: "#1E8970 !important" } }}
          className="default-btn-template-delete-modal"
          variant="contained"
          color="error"
          onClick={handleInfoChangeConfirm}
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InformationTypeChangeModal