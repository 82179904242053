//file imports *********************************************************************

import { Grid, Modal, Paper, Button, TextField, InputLabel, Typography, FormControl } from "@mui/material";
import { useState } from "react";
import Avatar from "react-avatar";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import Heading from "../../common/cardHeading/Heading"; //reusable component

//render function *****************************************************************

export const Inspector = (props) => {
  //MOCK DATA *********************************************************************

  const info = [
    {
      name: "Tim Kiwi",
      email: "kiwi@gmail.com",
      phone: 9928712345,
    },
    {
      name: "Kathy Artichoke",
      email: "artichoke@gmail.com",
      phone: 9928799287,
    },
    {
      name: "Kathy Artichoke",
      email: "artichoke@gmail.com",
      phone: 9928754321,
    },
  ];

  //modal open and close funtionality***********************************************

  const handleOpen = () => setOpen(true); //open the modal**************************
  const [open, setOpen] = useState(false); //flag for the modal*********************
  const handleClose = () => setOpen(false); //close the modal***********************

  // return function****************************************************************

  return (
    <>
      {/* Inspector screen in inspection details******************************** */}
      <Grid container>
        <Paper
          elevation={0}
          sx={{
            minHeight: "146px",
            border: "1px solid #E7E7E9",
            width: { xs: "100%", md: "65.46%", lg: "65.46%" },
          }}
        >
          <Heading
            CancelledStatus={props.CancelledStatus}
            title={"Inspectors"}
            icon={
              <EditIcon className="details__icon" sx={{width:"22px !important"}} onClick={handleOpen}>
                {" "}
              </EditIcon>
            }
            iconDesc={<span className="edit_button">Edit</span>}
          />
          <Grid
            container
            spacing={0}
            sx={{
              mt: "14px",
              pl: { xs: "0px", lg: "25.5px" },
            }}
          >
            {props.inspector.orderinspector.length !== 0 ? (
              props.inspector.orderinspector.map((value) => {
                return (
                  <>
                    <Grid
                      container
                      spacing={0}
                      xs={12}
                      md={6}
                      lg={6}
                      direction={{
                        xs: "column",
                        md: "row",
                        lg: "row",
                      }}
                      sx={{ pl: { lg: "0px", xs: "25px" } }}
                    >
                      <Grid item xs={4} lg={1} sx={{ mb: { xs: 2, lg: 0 } }}>
                        <Avatar className="avatar_name"  size="60px" round={true} maxInitials={1} color={"#BDBDBD"} name={value.inspector.first_name} src={value?.inspector?.profile_pic}></Avatar>
                      </Grid>
                      <Grid
                        item
                        lg={3}
                        xs={2}
                        sx={{
                          mt: { xs: 0, lg: 1.1 },
                          pl: { xs: 0, lg: 0.5 },
                          paddingLeft:"10.5px !important"
                        }}
                      >
                        <Typography
                          component={"span"}
                          sx={{
                            fontFamily: "Open sans",
                            fontSize: { xs: "16px", md: "14px", lg: "14px" },
                            color: "#333333",
                          }}
                        >
                          {value.inspector.first_name.length > 15 ? `${value.inspector.first_name.substring(15, 0)}... ` : value.inspector.first_name}
                        </Typography>
                        <br />
                        <Typography
                          sx={{
                            mb: "9px",
                            fontFamily: "Open sans",
                            fontSize: { xs: "16px", md: "14px", lg: "14px" },
                            color: "#333333",
                          }}
                        >
                          {value.inspector.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                );
              })
            ) : (
              <>
                <Typography
                  sx={{
                    fontFamily: "Open sans",
                    fontSize: { xs: "16px", md: "14px", lg: "14px" },
                    color: "#333333",
                    pl: { xs: 2, md: 2, lg: 0 },
                    width: "100%",
                    mb: 1,
                  }}
                >
                  No inspector added.
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Open sans",
                    fontSize: { xs: "16px", md: "14px", lg: "14px" },
                    color: "#4282E2",
                    pl: { xs: 2, md: 2, lg: 0 },
                    cursor: "pointer",
                  }}
                >
                  Add inspector
                </Typography>
              </>
            )}
          </Grid>
        </Paper>
      </Grid>

      <Modal open={open} onClose={handleClose} className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"}>
          <Grid sx={{ p: { xs: "20px", md: "30px", lg: "30px" } }} container spacing={2}>
            {" "}
            <Grid item xs={8} sx={{ mt: -1 }}>
              <Typography
                sx={{
                  height: "30px",
                  width: "350px",
                  color: "#333333",
                  fontSize: "22px",
                  letterSpacing: 0,
                  lineHeight: "30px",
                  fontFamily: "Open Sans",
                }}
              >
                INSPECTORS
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <CloseIcon onClick={handleClose} className={"inspection-filter-modal-close-icon"} />{" "}
            </Grid>
            {/* mapping for the edit screen ************************************ */}
            {props.inspector.orderinspector.map((value, index) => {
              return (
                <>
                  <Grid item xs={8} sx={{ marginTop: 0 }}>
                    <Typography
                      sx={{
                        height: "30px",
                        width: "350px",
                        color: "#333333",
                        fontSize: "18px",
                        letterSpacing: 0,
                        lineHeight: "30px",
                        fontFamily: "Open Sans",
                      }}
                    >
                      {`Inspector ${index + 1}`}
                    </Typography>
                  </Grid>
                  <Grid item lg={6} xs={12} sx={{ marginTop: -5 }}>
                    <FormControl variant="standard">
                      <InputLabel
                        htmlFor="component-simple"
                        sx={{
                          color: "#777777",
                          letterSpacing: 0,
                          lineHeight: "21px",
                          fontFamily: "Open Sans",
                          top: { xs: "18px", md: "17px", lg: "17px" },
                          fontSize: { xs: "14px", md: "12px", lg: "12px" },
                        }}
                      >
                        FIRST NAME
                      </InputLabel>
                      <TextField
                        margin="none"
                        size="small"
                        value={value.inspector.first_name}
                        sx={{
                          letterSpacing: 0,
                          marginTop: "63px",
                          fontColor: "#3b3b3b",
                          fontFamily: "Open Sans",
                          width: { xs: "340px", md: "317px", lg: "400px" },
                        }}
                      ></TextField>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} xs={12} sx={{ marginTop: -5 }}>
                    <FormControl variant="standard">
                      <InputLabel
                        htmlFor="component-simple"
                        sx={{
                          letterSpacing: 0,
                          color: "#777777",
                          lineHeight: "21px",
                          fontFamily: "Open Sans",
                          top: { xs: "18px", md: "17px", lg: "17px" },
                          fontSize: { xs: "14px", md: "12px", lg: "12px" },
                        }}
                      >
                        LAST NAME
                      </InputLabel>
                      <TextField
                        size="small"
                        value={value.inspector.last_name}
                        sx={{
                          letterSpacing: 0,
                          marginTop: "63px",
                          fontColor: "#3b3b3b",
                          fontFamily: "Open Sans",
                          width: { xs: "340px", md: "317px", lg: "400px" },
                        }}
                      ></TextField>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} xs={12} sx={{ marginTop: -5 }}>
                    <FormControl variant="standard">
                      <InputLabel
                        htmlFor="component-simple"
                        sx={{
                          letterSpacing: 0,
                          color: "#777777",
                          lineHeight: "21px",
                          fontFamily: "Open Sans",
                          top: { xs: "18px", md: "17px", lg: "17px" },
                          fontSize: { xs: "14px", md: "12px", lg: "12px" },
                        }}
                      >
                        EMAIL
                      </InputLabel>
                      <TextField
                        size="small"
                        value={value.inspector.email}
                        sx={{
                          letterSpacing: 0,
                          marginTop: "63px",
                          fontColor: "#3b3b3b",
                          fontFamily: "Open Sans",
                          width: { xs: "340px", md: "317px", lg: "400px" },
                        }}
                      ></TextField>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} xs={12} sx={{ marginTop: -5 }}>
                    <FormControl variant="standard">
                      <InputLabel
                        htmlFor="component-simple"
                        sx={{
                          letterSpacing: 0,
                          color: "#777777",
                          lineHeight: "21px",
                          fontFamily: "Open Sans",
                          top: { xs: "18px", md: "17px", lg: "17px" },
                          fontSize: { xs: "14px", md: "12px", lg: "12px" },
                        }}
                      >
                        PHONE NUMBER
                      </InputLabel>
                      <TextField
                        size="small"
                        value={value.inspector.phone}
                        sx={{
                          letterSpacing: 0,
                          marginTop: "63px",
                          fontColor: "#3b3b3b",
                          fontFamily: "Open Sans",
                          width: { xs: "340px", md: "317px", lg: "400px" },
                        }}
                      ></TextField>
                    </FormControl>
                  </Grid>
                </>
              );
            })}
            {/* buttons for the edit screen********************************** */}
            <Grid container spacing={2} className="inspection-filter-modal-buttons">
              <Button variant="outlined" sx={{":hover":{backgroundColor:"transparent"}}}  className="inspection-filter-modal-buttons-cancel" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" className="inspection-filter-modal-buttons-apply">
                Apply
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
