import { Autocomplete, Button,Checkbox,FormControl,Grid, TextField,TextareaAutosize} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
// dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import DialogContent from "@mui/material/DialogContent";
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { apiCall, apiCallGet } from "../../../../utils/action";
import { toast } from "react-hot-toast";
import { useState,useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";

const PtCreateNewTemplateModal = ({
  openNewTempModal,
  handleCloseNewTempModal,
  editTemplate,templateState,guid,getTemplateData,setOpenDeleteModal
}) => {
  const [selectedType, setSelectedType] = useState();
  const [inspTypes, setInspTypes] = useState([]);
  const [newTemplateName,setNewTemplateName] = useState('')
  const [reportTitle,setReportTitle] = useState('')
  const [description,setDescription] = useState('')
  const [pdfFile,setPdfFile] = useState('')
  const [isPdfTemplateCheck,setIsPdfTemplateCheck] = useState(false)
  const [loading,setLoading] = useState(false)

  const navigate = useNavigate();
  const fileInput = useRef();
  const handleChange = (event) => {
    setSelectedType(event.target.value);
  };
 
  const handleCheck=(value)=>{
    if(!value){
      setPdfFile('')
    }
    setIsPdfTemplateCheck(value)
  }

  const handleSelectFile=(e)=>{
    let fileSizeStatus = [];
        let file = e.target.files[0]
        const fileSize = file.size
        const sizeInMB = Math.round((fileSize / (1024*1024)));
        if(sizeInMB >= 100) {
          toast.error("File too Big, please select a file less than 100mb")
          fileSizeStatus.push('false')
        }
        else fileSizeStatus.push('true')
        if(fileSizeStatus.every((el) => el === "true")){
          let file = e.target.files[0]
              if (file.type === "application/pdf") {
                setPdfFile(e.target.files[0])
              } else {
                toast.error("Unsupported file format.")
              }
            }     
  }

  const closeNewTempModal=()=>{
    setSelectedType()
    setNewTemplateName('')
    setReportTitle('')
    setDescription('')
    setIsPdfTemplateCheck(false)
    setPdfFile('')
    handleCloseNewTempModal()
  }

  const handleDownload = () => {
    if(typeof pdfFile === "object") {
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(pdfFile);
      downloadLink.download = pdfFile.name;
      downloadLink.click();
    }
    else if(editTemplate && pdfFile !== undefined){
    let baseUrl = templateState?.templateData?.data?.bucket_url
    let url2 = templateState?.templateData?.data?.template?.pdf_report !== null?templateState?.templateData?.data?.template?.pdf_report:'' 
    let pdfName = getPdfName(url2)
    const anchor = document.createElement('a');
    anchor.href = baseUrl+url2;
    anchor.target = '_blank';
    anchor.download = pdfName; 
    url2 !== '' && anchor.click();
    document.body.removeChild(anchor);
    }
  };

  const getNewTemplateDetails=()=>{
    toast.dismiss();
    apiCallGet(
      (response) => {
        const { data, message, success } = response.data;
        if (success) {
          setInspTypes(data.inspection_types)
        } else {
          toast.error(message)
        }
      },
      "",
      "PT_GET_NEW_TEMPLATE_DETAILS"
    );
  }

  const getPdfName=(s3Name)=>{
    const url = s3Name
    const parts = url?.split("files/");
    const parts2 = parts[1] !== undefined?parts[1]?.split("/"):'';
    const desiredPart = parts2 !== ''?parts2[1]:"";
    return desiredPart;
  }

  const handleTemplateName=(e)=>{
    const inputValue = e.target.value;
    const trimmedValue = inputValue.replace(/^\s+/g, '');
    setNewTemplateName(trimmedValue);
  }
  useEffect(() => {
    if(editTemplate){
      setSelectedType(inspTypes?.find(el=>el.id ===templateState?.templateData?.data?.template?.inspection_type_id))
      setNewTemplateName(templateState?.templateData?.data?.template?.name)
      setDescription(templateState?.templateData?.data?.template?.description)
      setReportTitle(templateState?.templateData?.data?.template?.report_title)
      setIsPdfTemplateCheck(templateState?.templateData?.data?.template?.is_pdf_template === 1?true:false)
      let url2 = templateState?.templateData?.data?.template?.pdf_report !== null?templateState?.templateData?.data?.template?.pdf_report:'' 
      let pdfName = getPdfName(url2)
      setPdfFile(pdfName)
    } 
  }, [templateState,openNewTempModal])
  
  
  const createNewTemplate=()=>{
    setLoading(true)
    let formData = new FormData();   
    toast.dismiss();
    const params = { name:newTemplateName ,inspection_type_id:selectedType?.id, is_pdf_template: 1,description:description,report_title:reportTitle,file:pdfFile };
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    
      apiCall(
      (response) => {
        const {  data,message, success } = response.data;
        if (success) {
          toast.success(message); 
          setLoading(false);
          closeNewTempModal();
          navigate(
            `/template/${
              data.name?.charAt(0) === "#" ||
              data.name?.charAt(0) === "/"
                ? response.data.data.name
                    ?.substring(1)
                    .replace(new RegExp("/", "g"), "")
                : data.name.replace(new RegExp("/", "g"), "")
            }`,
            {
              state: {
                guid: data.guid,
                name: data.name,
                isPdfTemplate:data.is_pdf_template,
                edit: true,
              },
            }
          );

        } else {
          setLoading(false);
          closeNewTempModal();
          toast.error(message);
        }
      },
      formData,
      "PT_ADD_TEMPLATE"
    );
  }

  const editTemplateData=()=>{
    setLoading(true)
    let formData = new FormData();   
    toast.dismiss();
    const params = { template_guid:guid,name:newTemplateName ,inspection_type_id:selectedType?.id, is_pdf_template: 1,description:description  === null?'':description,report_title:reportTitle === null?'':reportTitle,file:typeof pdfFile === "object"?pdfFile:'' };
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    
      apiCall(
      (response) => {
        const {  data,message, success } = response.data;
        if (success) {
          toast.success(message); 
          getTemplateData(guid)
          setLoading(false);
          closeNewTempModal();
          navigate(
            `/template/${
              data.name?.charAt(0) === "#" ||
              data.name?.charAt(0) === "/"
                ? response.data.data.name
                    ?.substring(1)
                    .replace(new RegExp("/", "g"), "")
                : data.name.replace(new RegExp("/", "g"), "")
            }`,
            {
              state: {
                guid: data.guid,
                name: data.name,
                isPdfTemplate:data.is_pdf_template,
                edit: true,
              },
            }
          );         

        } else {
          setLoading(false);
          closeNewTempModal();
          toast.error(message);
        }
      },
      formData,
      "PT_UPDATE_TEMPLATE_DETAILS"
    );
    
  }

  useEffect(() => {
    getNewTemplateDetails()    
  }, [])
  
  return (
    <Dialog
      open={openNewTempModal}
      onClose={closeNewTempModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="pt-add-template-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <CloseIcon className="close-icon" onClick={closeNewTempModal} />
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} className="pt_new_temp_modal_heading">
            <span>{editTemplate?"Edit":"New blank"} template</span>
          </Grid>
          <Grid container className="pt_new_temp_modal_inner_container">
            <Grid item xs={12} className="mb-34">
              <label className="pt_input-form-label mb-5" htmlFor="pt_type_insp">
                Type of inspection*
              </label>
              <FormControl fullWidth>
                <Autocomplete
                  className={
                    "custom-color arrow-style"
                  }
                  id="pt_type_insp"
                  options={inspTypes}
                  filterSelectedOptions
                  size="small"
                  value={selectedType}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, newValue) => {
                    setSelectedType(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Select type" />
                  )}
                />
              </FormControl>
            </Grid>
            <div style={{display:'flex',gap:'16px',width:'100%'}} className="mb-34">
              <Grid item xs={6}>
                <FormControl variant="standard" className="pt_w_100">
                  <label
                    className="pt_input-form-label mb-5"
                    htmlFor="pt_new_temp_name"
                    id="demo-simple-select-label"
                  >
                    Name*
                  </label>
                  <TextField
                    fullWidth
                    type="text"
                    id="pt_new_temp_name"
                    size="small"
                    name="pt_new_temp_name"
                    variant="outlined"
                      value={newTemplateName}
                      onChange={handleTemplateName}
                    //   error={errors.city}
                    //   helperText={errors.city}
                    placeholder="Name to help inspector identify template"
                    className="input-textfield"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
              <FormControl variant="standard" className="pt_w_100">
                  <label
                    className="pt_input-form-label mb-5"
                    htmlFor="pt_new_temp_title"
                    id="demo-simple-select-label"
                  >
                    Report Title
                  </label>
                  <TextField
                    fullWidth
                    type="text"
                    id="pt_new_temp_title"
                    size="small"
                    name="pt_new_temp_title"
                    variant="outlined"
                      value={reportTitle}
                      onChange={(e)=>setReportTitle(e.target.value)}
                    //   error={errors.city}
                    //   helperText={errors.city}
                    placeholder="Name that will appear on report"
                    className="input-textfield"
                  />
                </FormControl>
              </Grid>
            </div>
            <Grid item xs={12} className="mb-34">
              <FormControl variant="standard" className="pt_w_100">
                        <label className="pt_input-form-label mb-5" htmlFor="infor_text" id="infor_text">
                        Description
                        </label>
                        <TextareaAutosize
                          className="textArea"
                          id="infor_text"
                          aria-label="minimum height"
                          minRows={3}
                          placeholder="Internal description for your template."
                          style={{ width: "100%", resize: "none",padding:"15px" }}
                           onChange={(e) => {
                             setDescription(e.target.value);
                           }}
                          // error={statementError}
                          // helperText={statementError ? statementErrorText : ""}
                           value={description}
                          InputProps={{
                            inputComponent: TextareaAutosize,
                          }}
                        />
                      </FormControl> 
            </Grid>
            <Grid>
            <FormControlLabel style={{display:'flex',alignItems:'flex-start',width:'fit-content'}}
              control={<Checkbox disableRipple disabled={editTemplate} value={isPdfTemplateCheck} checked={isPdfTemplateCheck} onClick={(e)=>handleCheck(e.target.checked)} sx={{paddingTop:'0px' }}/>}
              label="PDF template"/>
              {isPdfTemplateCheck && pdfFile === '' &&
              <div onClick={() => fileInput.current.click()} className="pt_add_pdf_file"><p><AddCircleOutlineIcon/><span>Choose Blank PDF file</span></p>
               <input
                ref={fileInput}
                type="file"
                accept="image/*,.pdf"
                onChange={(e)=>handleSelectFile(e)}
                style={{ display: "none" }}
            />
              </div>
            }
            {!editTemplate?
            pdfFile !== '' && pdfFile !== undefined?<p className="pt_pdf_items"><span  onClick={handleDownload}><DownloadIcon className="pt_downlaod-icon"/>{pdfFile.name?.length >50? `${pdfFile.name?.substring(50,0)}...`:pdfFile.name}</span><DeleteIcon className="delete-icon" onClick={()=>setPdfFile('')}/></p>:''
          :
          pdfFile !== '' && pdfFile !== undefined?<p className="pt_pdf_items"><span  onClick={handleDownload}><DownloadIcon className="pt_downlaod-icon"/>{typeof pdfFile === "string"? pdfFile !== ''?pdfFile?.length >50? `${pdfFile?.substring(50,0)}...`:pdfFile: templateState?.templateData?.data?.template?.pdf_report !== null?  getPdfName(templateState?.templateData?.data?.template?.pdf_report)?.length >18? `${getPdfName(templateState?.templateData?.data?.template?.pdf_report)?.substring(18,0)}...`:getPdfName(templateState?.templateData?.data?.template?.pdf_report):'':pdfFile.name?.length >50? `${pdfFile.name?.substring(50,0)}...`:pdfFile.name}</span><DeleteIcon className="delete-icon" onClick={()=>setPdfFile('')}/></p>:''
          }
            </Grid>
            
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={editTemplate?'pt_edit_te_action':"pt_non_edit_te_action"}>

      {editTemplate &&<Button disabled={templateState.roleId !== 2 }
        onClick={() => setOpenDeleteModal(true)}
        id="delete_template_btn"
        className={`pt_delete_template_btn ${templateState.roleId !== 2?'pt__disabled_btn':''}`}
        startIcon={<DeleteIcon />}>
        Delete template
        </Button>}
     <div>
        <Button
          sx={{ ":hover": { backgroundColor: "transparent !important" } }}
          className="template-delete-modal-cancel-btn"
          onClick={closeNewTempModal}
        >
          Cancel
        </Button>
        <LoadingButton
            className={`${((selectedType=== null || selectedType===undefined) || newTemplateName === '' || loading)?'pt_disabled_btn' :'default-btn-template-delete-modal'}`}
            sx={{
              height: { xs: 42, md: 34, lg: 34 },
              width: { xs: 180, md: 119, lg: 119 },
              fontSize: { xs: 16, md: 13, lg: 13 },
              ":hover": { backgroundColor: "#1E8970 !important" }
            }}
            disabled={((selectedType=== null || selectedType===undefined) || newTemplateName === '' || loading) }
            size="small"
            variant="contained"
            loading={loading}
            onClick={editTemplate?editTemplateData:createNewTemplate}
          >
           <span>{loading?"":editTemplate?'Save':'Create'}</span>
        </LoadingButton>
        </div>
     
      </DialogActions>
    </Dialog>
  );
};

export default PtCreateNewTemplateModal;
