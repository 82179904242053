import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid, Button } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import validator from "validator";
import axios from "axios";

import toast from "react-hot-toast";
import { apiCall } from "../../utils/action";

import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import Preloader from "../../helpers/Preloader";

// pdf converts
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
// pdf converts

const { detect } = require("detect-browser");
const browser = detect();

const AgreementWebViewNSignature = () => {
  const [showLoader, setShowLoader] = useState();
  const [date1, setDate1] = useState(moment());
  const [ipAddress, setIPAdress] = useState("");
  const [date1Error, setDate1Error] = useState(false);
  const [date1ErrorText, setDate1ErrorText] = useState("");
  const [date2, setDate2] = useState(moment());
  const [clientNameOne, setClientNameOne] = useState("");
  const [clientNameOneError, setClientNameOneError] = useState("");
  const [clientNameOneErrorText, setClientNameOneErrorText] = useState("");
  const [clientNameTwo, setClientNameTwo] = useState("");
  const [clientNameTwoError, setClientNameTwoError] = useState("");
  // const [clientNameTwoErrorText,setClientNameTwoErrorText] = useState('');
  const [agreementContent, setAgreementContent] = useState("");
  const [agreementName, setAgreementName] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  // const [file,setFile] = useState();
  const [pdfFile, setPdfFile] = useState();
  // const [base64Pdf,setBase64Pdf] = useState('');
  let navigate = useNavigate();
  // Get user-agent (browser) information from the request headers
  const userAgent = window.navigator.userAgent;
  // url params
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let agreementIdParam = Number(searchParams.get("aid"));
  let companyIdParam = searchParams.get("cid");
  let orderIdParam = searchParams.get("oid");
  let userIdParam = searchParams.get("uid");
  let uniqueIdParam = searchParams.get("uniq");
  let emailParamEncoded = encodeURIComponent(searchParams.get("email"));
  let emailParam = decodeURIComponent(emailParamEncoded.replace(/\%20/g, "+"));
  const removeNumericRegex = /\d+/g;

  // Function to remove numeric characters
  function removeNumeric(inputString) {
    return inputString.replace(removeNumericRegex, "").trim();
  }

  //get agreement details
  const GetAgreementDetails = () => {
    setShowLoader(true);
    // const formdata = { company_id: 11, agreement_id: 39,order_id:317 };
    const formdata = { user_id: userIdParam, company_id: companyIdParam, agreement_id: [agreementIdParam], order_id: orderIdParam, email: emailParam, unique_id: uniqueIdParam };
    apiCall(
      (response) => {
        console.log("GET_AGREEMENT_CONTENT", response);
        const { data, message, success, status_code } = response.data;
        if (success) {
          let modifiedHtml = convertFontTagsToInlineStyles(data[0]?.agreement_body);
          const regexPattern = /in\\|in;|in(?=")/g;
          const replacedString = modifiedHtml.replace(regexPattern, "px");
          let regexPattern2 = /\"=\"\"/g;
          const replacedString2 = replacedString.replace(regexPattern2, "");
          setAgreementContent(replacedString2);
          let agreeName = `${data[0]?.name}.pdf`;
          setAgreementName(agreeName);
          // window.history.pushState('', '', '/#/view-agreement/agreement');
          setShowLoader(false);
        } else {
          // navigateing to agreement already signed page if the agreement is already signed
          if (status_code === 400) {
            if (message === "Invalid link.") navigate("/agreement-invalid");
            else navigate("/agreement-signed");
          } else toast.error(message);
          setShowLoader(false);
        }
      },
      formdata,
      "GET_AGREEMENT_CONTENT"
    );
  };

  const getIP = async () => {
    try {
      // Make a request to ipinfo.io to get IP and browser information
      const response = await axios.get("https://ipv4.jsonip.com/");
      setIPAdress(response.data.ip);
      console.log(response.data.ip)
    } catch (error) {
      console.error("Error capturing IP and browser info:", error);
    }
  };

  useEffect(() => {
    getIP();
  }, []);

  const sendSignedAgreement = (convertedPdf) => {
    setShowLoader(true);
    toast.dismiss();
    // const formdata = new FormData();
    const formdata = { user_id: userIdParam, ip_address: ipAddress, browser_info: JSON.stringify({ name: browser.name, browser_version: browser.version, os: removeNumeric(browser.os) }), company_id: companyIdParam, agreement_id: agreementIdParam, inspection_id: orderIdParam, agreement_file: convertedPdf, user_name: `${clientNameOne?.trim()}${clientNameTwo !== "" ? `,${clientNameTwo?.trim()}` : ""}`, agreement_signed_date: `${date1},${clientNameTwo !== "" && date2 !== "" ? `,${date2}` : ""}`, agreement_file_name: agreementName, email: emailParam, unique_id: uniqueIdParam };
    apiCall(
      (response) => {
        console.log("UPLOAD_SIGNED_AGREEMENT", response);
        const { message, success } = response.data;
        if (success) {
          setShowLoader(false);
          navigate("/accept-agreement", { state: { emailParam } });
        } else {
          toast.error(message);
          console.log("else ", message);
          setShowLoader(false);
        }
      },
      formdata,
      "UPLOAD_SIGNED_AGREEMENT"
    );
  };

  const timestramp1 = moment(date1).format("YYYY-MM-DD");
  const timestramp2 = moment(date2).format("YYYY-MM-DD");




  // signed HTML to PDF conversion
  const HTMLtoPDF = (data) => {
    var html = htmlToPdfmake(data?.replace(/^(?!<[^>]+>)(.*)$/gm, '<p>$1</p>'));
    // const documentDefinition = { content: html };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    pdfMake.fonts = {
      ComicSansMs: {
        normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
      Arial: {
        normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
      Roboto: {
        normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
      Tahoma: {
        normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
      CourierNew: {
        normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
      Impact: {
        normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
      Georgia: {
        normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
      TrebuchetMs: {
        normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
      Verdana: {
        normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
      OpenSans: {
        normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
        italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
        bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
      },
    };
    var documentDefinition = {
      content: html,
      defaultStyle: { font: "Roboto" },
    };
    pdfMake.createPdf(documentDefinition);
    // const pdfDocGenerator = pdfMake.createPdf(documentDefinition);

    // base 64 conversion

    setPdfFile(pdfMake.createPdf(documentDefinition));

    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getBase64((data) => sendSignedAgreement(data));
  };

  const addSignatureNameNDate = () => {
    let signedHTML = `${agreementContent}<p>CLIENT 1</p><p>${clientNameOne} <span>&emsp;&emsp;&emsp;${timestramp1}</span></p>${clientNameTwo !== "" ? `<p>CLIENT 2</p>` : ""}<p>${clientNameTwo} <span>&emsp;&emsp;&emsp;${clientNameTwo !== "" ? timestramp2 : ""}</span></p>`;
    HTMLtoPDF(signedHTML);
  };
  const validate = () => {
    let checkboxSpan = document.getElementById("agreeTermsID");
    checkboxSpan.innerHTML = "";
    if (clientNameOne?.trim() === "" || clientNameOne?.trim() === null) {
      setClientNameOneError(true);
      setClientNameOneErrorText("Client name is required");
    } else if (!validator.isLength(clientNameOne?.trim(), { min: 3, max: undefined })) {
      setClientNameOneError(true);
      setClientNameOneErrorText("Minimum 3 characters required");
    } else setClientNameOneError(false);
    if (date1 === null) {
      setDate1Error(true);
      setDate1ErrorText("Date is required");
    } else setDate1Error(false);

    // if(clientNameTwo === '') setDate2(null);
    if (clientNameOne?.trim() !== "" && clientNameOne.trim() !== null && validator.isLength(clientNameOne?.trim(), { min: 3, max: undefined }) && date1 !== null && agreeTerms === false) checkboxSpan.innerHTML = "Please check the terms and conditions";
    if (clientNameOne !== "" && clientNameOne !== null && validator.isLength(clientNameOne, { min: 3, max: undefined }) && date1 !== null && agreeTerms === true) {
      addSignatureNameNDate();
    }
  };

  const rejectAgreement = () => {
    setShowLoader(true);
    const formdata = { company_id: companyIdParam, ip_address: ipAddress, browser_info: JSON.stringify({ name: browser.name, browser_version: browser.version, os: removeNumeric(browser.os) }), order_id: orderIdParam, agreement_id: agreementIdParam, email: emailParam };
    apiCall(
      (response) => {
        console.log("REJECT_AGREEMENT", response);
        const { data, message, success } = response.data;
        if (success) {
          setShowLoader(false);
          navigate("/reject-agreement");
        } else {
          console.log("else ", message);
          setShowLoader(false);
        }
      },
      formdata,
      "REJECT_AGREEMENT"
    );
  };


  function convertPtToPx(htmlString) {
    // Regex to match any value with 'pt' and replace with 'px'
    const ptToPxRegex = /(\d+(?:\.\d+)?)pt/g;

    // Replace 'pt' with 'px' while keeping the numeric value intact
    const convertedHtml = htmlString.replace(ptToPxRegex, (match, p1) => {
      // Convert pt to px by multiplying by a factor (1pt ≈ 1.333px)
      const pxValue = (parseFloat(p1) * 1.333).toFixed(2);
      return `${pxValue}px`;
    });

    // Regex to replace negative margins (e.g., -0.75pt) with a fixed value
    const negativeMarginRegex = /margin-left:\s*-?(\d+(?:\.\d+)?)px/g;

    // Replace invalid margin-left values with 1px
    const finalHtml = convertedHtml.replace(negativeMarginRegex, (match, p1) => {
      return `margin-left: 1px`; // Change to 1px as requested
    });

    return finalHtml;
  }

  function convertFontTagsToInlineStyles(htmlString) {
    let regex = /style="([^"]*)font-family:([^"]*)"/g;
    const regexhtml = /<br\s*\/?>|&nbsp;/g;
    // Replace <font> tags with inline styles
    var convertedString = htmlString
      ?.replace(/<font\s+color="([^"]*)"\s*face="([^"]*)">(.*?)<\/font>/g, "<span style=\"color: $1; font-family: 'Open Sans';\">$3</span>")
      ?.replace(regex, 'style="$1"')
      ?.replace(/<font([^>]*)>/gi, function (match, p1) {
        var attributes = p1?.split(/\s+/);
        var styles = "";
        // Convert font attributes to inline styles
        for (var i = 0; i < attributes?.length; i++) {
          var attribute = attributes[i]?.trim();
          var parts = attribute?.split("=");
          if (parts.length === 2) {
            var name = parts[0]?.toLowerCase();
            var value = parts[1]?.replace(/['"]/g, "");
            // eslint-disable-next-line
            switch (name) {
              case "color":
                styles += "color: " + value + "; ";
                break;
              case "size":
                styles += "font-size: " + fontSizeConverter(value) + "px !important; ";
                break;
            }
          }
        }
        return "<span style='" + styles + "'>";
      });
    convertedString = convertedString?.replace(/<\/font>/gi, "</span>");

    return convertPtToPx(convertedString);
  }

  const fontSizeConverter = (val) => {
    if (val === "1") {
      return 10;
    } else if (val === "2") {
      return 13;
    } else if (val === "3") {
      return 16;
    } else if (val === "4") {
      return 18;
    } else if (val === "5") {
      return 24;
    } else if (val === "6") {
      return 32;
    } else if (val === "7") {
      return 48;
    } else return val;
  };
  // const uploadFile=(e)=>{
  //    setFile(e.target.files[0])
  // }
  useEffect(() => {
    if (sessionStorage.getItem("timezone") === null) {
      axios
        .get("https://ipapi.co/json/?key=EJ1FHhnqyB6gRoAik4SRHnb8peb3wBAapbY93Q7TZkYZ0Tsu5R")
        .then((response) => {
          sessionStorage.setItem("timezone", response?.data?.timezone);
          GetAgreementDetails();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    else GetAgreementDetails();

  }, []);

  return (
    <Box className="web-view-wrapper">
      <Preloader showPreloader={showLoader} />
      <Grid container spacing={2} className="web-view-container">
        <Grid container className="web-view-content">
          <h3>Review and sign the Agreement</h3>
          <div className="agreement-view" dangerouslySetInnerHTML={{ __html: agreementContent }} />
          {/* below commented code might be required later */}
          {/* {agreementContent!=='' && agreementContent} */}
          <Grid item xs={12} className="agreement_consent">
            <FormControlLabel
              control={
                <Checkbox
                  color="default"
                  onChange={(e) => {
                    setAgreeTerms(e.target.checked);
                  }}
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              }
              label="* I have carefully read this agreement and agree to the terms and conditions above."
            />
          </Grid>
          <Grid item xs={12} id="agreeTermsID"></Grid>
          <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                Client 1*
              </label>
              <TextField
                id="firstName"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Full Name"
                // value={firstName}
                onChange={(e) => setClientNameOne(e.target.value)}
                error={clientNameOneError}
                helperText={`${clientNameOneError ? clientNameOneErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                Date*
              </label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={date1}
                  format="DD-MM-YYYY"
                  minDate={new Date()}
                  className={"inspection-filter-modal-select"}
                  onChange={(newValue) => {
                    setDate1(newValue);
                  }}
                  error={date1Error}
                  helperText={`${date1Error ? date1ErrorText : ""}`}
                  renderInput={(params) => <TextField {...params} size="small" />}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          {/* ============================================================================================== */}
          <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                client 2
              </label>
              <TextField
                id="firstName"
                className="input-textfield"
                size="small"
                variant="outlined"
                placeholder="Full Name"
                onChange={(e) => setClientNameTwo(e.target.value)}
                error={clientNameTwoError}
                helperText={`${clientNameTwoError ? clientNameOneErrorText : ""}`}
                type="text"
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                Date
              </label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={date2}
                  format="DD-MM-YYYY"
                  minDate={new Date()}
                  className={"inspection-filter-modal-select"}
                  onChange={(newValue) => {
                    setDate2(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "hh:mm am/pm",
                      }}
                      size="small"
                    />
                  )}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          {/* below code might be required later */}
          {/* ================================================================================================= */}
          {/* test input */}
          {/* <Grid item xs={6} sm={3}>
            <FormControl variant="standard" className="forms-control-webview">
              <label
                className="input-form-label"
                htmlFor="Address"
                id="demo-simple-select-label"
              >
                pdf
              </label>
              <input id='inputPDF'
                  // ref={fileInput}
                  accept=".pdf" type="file" onChange={uploadFile}
      />           
            </FormControl>
          </Grid> */}
          {/* test input */}
          <Grid item xs={12} className="agreement-action">
            <div>
            </div>
            <div className="right-btn-group">
              <Button onClick={validate} className="agree-btn">
                Agree
              </Button>
              <Button className="reject-btn" onClick={rejectAgreement}>
                <span className="reject-text">Reject</span>
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AgreementWebViewNSignature;
