import { FormControl, Grid, TextField,Menu, MenuItem } from "@mui/material"
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import Fade from '@mui/material/Fade';
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, useEffect, useState } from "react";
import { updateElementJsonByUUID, updateJSON , deleteElement, hasDuplicateEntries, hasEmptyStrings} from "../PtTemplateHelperFunctions";
import { debounce } from "lodash";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RadioEditButton from "./RadioEditButton";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import DraggableChoice from "../DraggableChoice";
import { BootstrapTooltip } from "../../../../utils/services";

const RadioElement = ({catuuid,templateState,setTemplateState,updatePtTempateData,label,uuid,parentType,merge_id,isDragging,setDragDisable,nestingLevel,elements}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [radioName,setRadioName] = useState('');
  const [radioOptions,setRadioOptions] = useState([]);
  const [isEdit, setIsEdit] =  useState(false);
  const [editedInpData,setEditedInpData] = useState(false);
  const [radioTag,setRadioTag] = useState('');
  const [editedInpMerge,setEditedInpMerge] = useState(false);
  const [mergeIdError,setMergeIdError] = useState(false);
  const [radioError,setRadioError] = useState(false)
  const [radioErrorMessage,setRadioErrorMessage] = useState('')
  const [active,setActive] = useState(false);
  const open = Boolean(anchorEl);
  const moveChoice = (fromIndex, toIndex) => {
    const updatedChoices = [...radioOptions];
    const [movedChoice] = updatedChoices.splice(fromIndex, 1);
    updatedChoices.splice(toIndex, 0, movedChoice);
    setRadioOptions(updatedChoices);
  };
  const handleClickMore = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const clickAwaySaveptData=()=>{
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
    updateJSON(prevData, uuid, radioName,"label");
    let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
    let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
    newUpdatedSpecificData.data.template.pdf_categories = editedCategory
    setTemplateState(prev=>({...prev,templateData:prevData}))
    updatePtTempateData(newUpdatedSpecificData?.data?.template)       
    setEditedInpData(false)
  }

  const clickAwaySaveptMergeData=()=>{ 
    if(mergeIdError) { 
      setRadioTag('')
      let updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, "",'tag');
      let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
      prevData.data = updatedData;
      setTemplateState(prev=>({...prev,templateData:prevData}))
      setMergeIdError(false)
      setEditedInpMerge(false)
    }
    else{
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
    updateJSON(prevData, uuid, radioTag,"tag");
    let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
    let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
    newUpdatedSpecificData.data.template.pdf_categories = editedCategory
    setTemplateState(prev=>({...prev,templateData:prevData}))
    updatePtTempateData(newUpdatedSpecificData?.data?.template)       
    setEditedInpMerge(false)
    }  
  }

   // Create a debounced function to update the state
   const debouncedUpdateData = debounce((prevData) => {   
    setTemplateState(prev=>({...prev,templateData:prevData}))
  }, 2000); // Delay state update by 2 seconds
  const handleRadioNameChange = useCallback((e,changeType) => {
    e.persist();
    let updValue= e.target.value
    let updatedData;
    let prevData = JSON.parse(JSON.stringify(templateState?.templateDataCopy));
    if(changeType === "label"){
      setEditedInpData(true) 
      setRadioName(updValue);  
      updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue,'label');
    }
    else if(changeType === 'tag'){
      setRadioTag(updValue)
      setEditedInpMerge(true);
      if(templateState?.mergeIdArray.includes(updValue)) {       
        setMergeIdError(true);
        updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue,'tag');
      }
      else{
        setMergeIdError(false);
        updatedData = updateElementJsonByUUID(templateState?.templateDataCopy.data, uuid, updValue,'tag');
      }     
    }
    if (updatedData) {
      prevData.data = updatedData;
      debouncedUpdateData(prevData);
    }
  }, []);

  const EditList=()=>{
    if(!isEdit) setIsEdit(true)
    else{
      let hasduplicate = hasDuplicateEntries(radioOptions);
      let isEmpty = hasEmptyStrings(radioOptions)
    if(hasduplicate){
      setRadioError(true)
      setRadioErrorMessage('Duplicate choices not allowed.')
    }else if(isEmpty){
      setRadioError(true)
      setRadioErrorMessage('Choices cannot be empty.')
    }else{
      let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
      let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===catuuid)
      updateJSON(prevData, uuid, radioOptions,"radioElement");
      let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
      let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
      newUpdatedSpecificData.data.template.pdf_categories = editedCategory
      setTemplateState(prev=>({...prev,templateData:prevData}))
      updatePtTempateData(newUpdatedSpecificData?.data?.template)       
      setEditedInpData(false)
      setRadioError(false)
      setRadioErrorMessage('')
      setIsEdit(false)
    }    
  }
  }

  const activateDragIn=()=>{
    setDragDisable(true);
    if(nestingLevel > 0){
       sessionStorage.setItem("firstLevelDrag", true);
    }
  }
  const activateDragOut=()=>{
    setDragDisable(false);
    if(nestingLevel > 0){
       sessionStorage.setItem("firstLevelDrag", false);
    }
  }
  const radioOptionAreaIn=()=>{
    if(isEdit){
    setDragDisable(true);
    sessionStorage.setItem("radioOptionHover", true);
    }
  }
  const radioOptionAreaOut=()=>{
    if(isEdit){
      setDragDisable(false);
      sessionStorage.setItem("radioOptionHover", false);
    }   
  }
  const ptRadioEdithandle=(newVal,index)=>{
    const updatedDemoEl = [...radioOptions];
    updatedDemoEl[index] = newVal;
    setRadioOptions(updatedDemoEl);
  }

  const ptDeleteRadio = (index) => {
    const updatedDemoEl = [...radioOptions];
    updatedDemoEl.splice(index, 1);
    setRadioOptions(updatedDemoEl);
  };

  const addNewRadioOption=()=>{
    let hasduplicate = hasDuplicateEntries(radioOptions);
    let isEmpty = hasEmptyStrings(radioOptions)
    if(hasduplicate){
      setRadioError(true)
      setRadioErrorMessage('Duplicate choices not allowed.')
    }else if(isEmpty){
      setRadioError(true)
      setRadioErrorMessage('Choices cannot be empty.')
    }
    else{
      let optionLength = radioOptions?.length
      let newOption = `Choice ${optionLength+1}` 
      const updatedDemoEl = [...radioOptions,newOption];
      setRadioOptions(updatedDemoEl);
      setRadioError(false)
      setRadioErrorMessage('')
    }
   
  }

  useEffect(() => {
    setRadioName(label)
    setRadioTag(merge_id)
    !isEdit && setRadioOptions(elements)
  }, [templateState])
  
  return (
    <ClickAwayListener  mouseEvent="onMouseDown"
                        touchEvent="onTouchStart" onClickAway={() => active && setActive(false)}>
    <Grid container className="pt_card_border pt_inputfield" onClick={()=>setActive(true)}
     onMouseEnter={()=>parentType === "expandingCheckBox" || parentType==="group"?activateDragIn():null} onMouseLeave={()=>parentType === "expandingCheckBox" || parentType==="group"?activateDragOut():null} 
     >
        <div className={`${isDragging || active?'pt_ip_drag_section pt_elem_dragging':"pt_ip_drag_section"} `}><DragIndicatorIcon className="pt_icon_color"/></div>
        <div className="pt_ip_content" style={{maxWidth:'94%'}}>
            <div className="pt_ip_data">
                <div className="pt_ip_data_row1 pt_is_flex_align_center pt_space_between">
                    <div className="pt_is_flex_align_center gap5">
                        <RadioButtonCheckedIcon/>
                        <span className="pt_element_name">Radio</span>
                    </div>
                </div>
                <Grid container>
                  <Grid item xs={12} className="pt_merge_field">
                    <label className="pt_form_label" htmlFor="pt_radio_label_merge" id="pt_input_label">
                    merge field id
                    </label>
                    <ClickAwayListener  mouseEvent="onMouseDown"
                        touchEvent="onTouchStart" onClickAway={() => editedInpMerge && clickAwaySaveptMergeData()}>
                    <TextField
                       style={{width:'50%',display:'block',background:'#fff',borderColor: mergeIdError ? '#d32f2f' : '#ced4da'}}
                      type="text"
                      id="pt_radio_label_merge"
                      size="small"
                      name="pt_radio_label_merge"
                      variant="outlined"
                      disabled={templateState.roleId !== 2 && templateState.roleId !== 3}
                      value={radioTag}
                      error={mergeIdError}
                      helperText={`${mergeIdError ? "This merge ID is in use."  : ""}`}
                      onChange={(e)=>handleRadioNameChange(e,'tag')}
                      placeholder="ID tag"
                      className={mergeIdError?`input-textfield pt_focussed`:"input-textfield"}/>
                       </ClickAwayListener>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="standard" className="pt_w_100">
                          <label className="pt_form_label" htmlFor="pt_radio" id="pt_radio" >
                            Label
                          </label>
                          <ClickAwayListener  mouseEvent="onMouseDown"
                            touchEvent="onTouchStart" onClickAway={() => editedInpData && clickAwaySaveptData()}>
                          <TextField fullWidth type="text" id="pt_radio" size="small" name="pt_radio"
                            variant="outlined"
                            disabled={templateState.roleId !== 2 && templateState.roleId !== 3}
                            value={radioName}
                            onChange={(e)=>handleRadioNameChange(e,'label')}
                            placeholder="Input title"
                            className="input-textfield"
                          />
                            </ClickAwayListener>
                        </FormControl>
                  </Grid>     
                  <DndProvider backend={HTML5Backend}>   
                  <Grid item xs={12} className="pt_radio_labels_container" onMouseEnter={radioOptionAreaIn} onMouseLeave={radioOptionAreaOut}>
                        {!isEdit?
                        radioOptions?.length !== 0 ?
                        radioOptions?.map((option,index)=>(
                          option.length > 15?
                          <BootstrapTooltip title={<h1 className="font-13">{option}</h1>}>
                          <div style={{backgroundColor: index % 6 < 3 &&  "#F5F6F8"}} className="pt_radio_label"><RadioButtonUncheckedIcon/>  <span>{`${option.substring(11, 0)}...`}</span></div> 
                        </BootstrapTooltip>
                          :
                          <div style={{backgroundColor: index % 6 < 3 &&  "#F5F6F8"}} className="pt_radio_label"><RadioButtonUncheckedIcon/>  <span>{option}</span></div> 
                        )):'No radio options present'
                        :
                        <>
                        
                        {radioOptions?.length !== 0 ?
                        radioOptions?.map((option,index)=>(
                          <DraggableChoice
                                key={index}
                                index={index}
                                moveChoice={moveChoice}
                                option={option}
                                ptRadioEdithandle={ptRadioEdithandle}
                                ptDeleteRadio={ptDeleteRadio}
                              />
                       
                      ))
                      :'No radio options present'}
                      </>
                      }
                      
                  </Grid>
                  </DndProvider> 
                  {isEdit && <div className="isFlex add-cta pt_ad_choice">
                      <span onClick={() => addNewRadioOption()}>
                        <AddCircleOutlineIcon /> Add choice
                      </span>
                  </div>}
                </Grid>
                <RadioEditButton EditList={() => EditList()} isEdit={isEdit}/>
                {radioError && <div className="pt_radio_error" style={{marginTop:'5px'}}>{radioErrorMessage}</div>}
            </div>
            <div className={`${templateState.roleId !== 2 && templateState.roleId !== 3?'pt_ip_action pt_ip_disabled':'pt_ip_action pt_ip_enabled'}`} ><MoreHorizIcon className="pt_icon_color" 
            onClick={templateState.roleId !== 2 && templateState.roleId !== 3? null : handleClickMore}/>
            <Menu
              id="fade-menu"
              MenuListProps={{'aria-labelledby': 'fade-button',}}
              anchorOrigin={{vertical: 'top',horizontal: 'right',}}
              transformOrigin={{vertical: 'top',horizontal: 'right',}}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              className="pt_more_menu"
              >
                <span><CloseIcon onClick={handleClose}/></span>
        <MenuItem onClick={()=>deleteElement(templateState, setTemplateState, parentType, uuid, catuuid, updatePtTempateData,handleClose)}><DeleteIcon/>Delete</MenuItem>
            </Menu>
            </div>
        </div>
    </Grid>
    </ClickAwayListener>
  )
}

export default RadioElement