import { Box } from "@mui/system";
import Header from "../../../common/header/Header";
import ReportSettingsLayout from "./ReportSettingsLayout";
import Breadcrumb from "../../../common/header/Breadcrumb";

const ReportSettings = () => {
  return (
    <Box sx={{ flexGrow: 1 }} className="page-background">
      <Header />
      <Breadcrumb />
      <ReportSettingsLayout />
      {window.scroll(0, 0)}
    </Box>
  );
};

export default ReportSettings;
