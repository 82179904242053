import React, { useRef, useEffect, useState } from "react";
import { apiCall } from "../../utils/action";
import { decode } from "js-base64";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
const MapFullView = () => {
  const map = useRef(null);
  const [zoom] = useState(3);
  const mapContainer = useRef(null);
  const [key, setKey] = useState();
  const Pointers = localStorage.getItem("marker");
  const [center] = useState(JSON?.parse(Pointers));
  mapboxgl.accessToken = key;
  const getKey = () => {
    const formdata = { company_id: sessionStorage.getItem("company_id"), user_id: sessionStorage.getItem("user_id"), role_id: sessionStorage.getItem("role_id") };
    apiCall(
      (response) => {
        const { success, data } = response.data;
        if (success) {
          setKey(decode(data));
        } else {
          console.log("else", response.data.message);
        }
      },
      formdata,
      "GET_KEY"
    );
  };

  useEffect(() => {
    getKey();
  }, []);
  // initialize map only once
  useEffect(() => {
    if (key) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: center,
        attributionControl: false,
        zoom: zoom,
      });
      //marker
      new mapboxgl.Marker({ color: "Red", display: "block" }).setLngLat(center).addTo(map.current);
      //controls for map
      map.current.addControl(new mapboxgl.NavigationControl(), "bottom-right");
      //move to marker
      map.current.flyTo({
        speed: 3,
        center: center,
        zoom: 15,
        essential: false,
      });
    }
  }, [key]);

  //render
  return <>{key ? <div ref={mapContainer} className="map-container" style={{ height: "100vh", width: "100%" }} /> : ""}</>;
};
export default MapFullView;
