import CancelIcon from '@mui/icons-material/Cancel';
import AgreementActionCard from "./AgreementActionCard";

const AgreementInvalid = () => {
  return (
    <>
      <AgreementActionCard
        icon={<CancelIcon style={{ color: "#E30303" }} />}
        title={"This Agreement link is Invalid."}
        content={""}
        email={""}
      />
    </>
  );
};

export default AgreementInvalid;