import React, { useEffect, useState, useContext } from "react";
// import { tableData } from "../../utils/mockdata";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import FilterModal from "../../common/filter/FilterModal";
import { Grid } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import EnhancedTableToolbar from "../../common/toolbar/EnhancedTableToolbar";

import TableLayout from "../../common/table/TableLayout";
import { NewFilterInspectionDetails } from "../scheduleInspection/action";
import { compareStrings } from "../../utils/services";
import { GetFilteredInspections } from "../inspection/action";
import { apiCall } from "../settings/Services/action";
import UseInspectionAgreementModal from "../../common/modal/UseInspectionAgreementModal";
import IncludedAgreementModal from "../../common/modal/IncludedAgreementModal";
import CreateOwnAgreementModal from "../../common/modal/CreateOwnAgreementModal";
import { AppContext } from "../../App";
import NoInspection from "../dashboard/NoInspection";

export default function Inspections(props) {
  const { setPage, handleSearchFilter, searchIcon, companyInformation, payment, agreement, handlePaid, handleAgreement, table_data, allInspectors, allAgents, excelData, status, handleStatus, handleChangeInspectors, handleChangeAgents, RemoveAgentsChange, clearInspections, FilterTypeHandler, FilterType, specificDate, fromDate, toDate, handleSpecificDate, handleFromDate, handleToDate, TotalFilter, BackFilterdata, RemoveInspectorChange, RemoveStatusChange, removeDate, loade, page, GetInspectionLists, rowsPerPage, GetTotalList, handleChangePage, handleChangeRowsPerPage, restricter, restrict, totalList, reLoader, paymentStatus, agreementStatus, chargifyURL, dateRange, inspListApiResponseMessage } = props;
  const [open, setOpen] = React.useState(false);
  const [displayFilters, setDisplayFilters] = React.useState(false);
  const navigate = useNavigate();
  const { companyInfo, setReload, reload } = useContext(AppContext);

  const [inspectorList, setInspectorList] = useState([]);
  const [agentsList, setAgentsList] = useState([]);
  const [totalStatus, setTotalStatus] = useState([]);
  const [totalPaid, setTotalPaid] = useState([]);
  const [totalAgreement, setTotalAgreement] = useState([]);
  const [totalInspectors, setTotalInspectors] = useState([]);
  const [totalAgents, setTotalAgents] = useState([]);
  const [dates, setDate] = useState();
  const [excelinspectionData, setExcelInspectionData] = useState([]);
  const [cantCreateInsp, setCantCreateInsp] = useState(false);
  const [totalSelected, setTotalSelected] = useState([]);

  const uuId = sessionStorage.getItem("user_id");
  const roleId = sessionStorage.getItem("role_id");
  const companyId = sessionStorage.getItem("company_id");
  const ownerPermission = roleId === "5" ? true : false;

  // agreement terms modal states
  const [showInspAgrmntModal, setShowInspAgrmntModal] = useState(false);
  const [showIncludedAgrmntModal, setShowIncludedAgrmntModal] = useState(false);
  const [showCreateOwnAgrmntModal, setShowCreateOwnAgrmntModal] = useState(false);

  var agentsLists = [];
  const agent =
    agentsList.length > 0
      ? agentsList.map((agents) =>
          agentsLists.push({
            id: agents.id,
            name: agents.last_name + " " + agents.first_name,
          })
        )
      : [];
  agentsLists.sort(function (a, b) {
    return compareStrings(a.name, b.name);
  });

  var inspList = [];

  const inspectors =
    inspectorList.length > 0
      ? inspectorList.map((inspectors) =>
          inspList.push({
            id: inspectors.id,
            name: inspectors.last_name + " " + inspectors.first_name,
          })
        )
      : [];

  inspList.sort(function (a, b) {
    return compareStrings(a.name, b.name);
  });

  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });
  const isMobileMedia = useMediaQuery({ query: "(max-width: 600px)" });

  const ApplyFunction = Object.values(TotalFilter).every((value) => {
    if (value === null) {
      return true;
    }
    return false;
  });

  const handleApplyFilters = () => {
    if (ApplyFunction === false) {
      setTotalStatus(TotalFilter?.status);
      setTotalPaid(TotalFilter?.paid);
      setTotalAgreement(TotalFilter?.agreement);
      setTotalInspectors(TotalFilter?.inspectors);
      setTotalAgents(TotalFilter?.agents);
      setDate(TotalFilter?.dates);
      setTotalSelected(TotalFilter);
      setDisplayFilters(true);
      props.filterHandler();
      setOpen(false);
    } else {
      setOpen(false);
      clearInspections();
    }
  };

  const handleCancelFilters = () => {
    setDisplayFilters(false);
    setOpen(false);
    handleChipReset();
    GetInspectionLists();
    // window.location.reload();
  };

  const handleChipClick = () => {
    // console.info("You clicked the Chip.");
  };

  const handleChipDelete = () => {
    // console.info("You clicked the delete icon.");
  };

  const handleChipReset = () => {
    window.location.reload();
    setDisplayFilters(false);
    clearInspections();
  };

  const replacePaid = totalPaid !== null && totalPaid.map((data) => (data === "Paid" ? 1 : data === "Unpaid" ? 0 : data === "Waived" && 2));
  const replaceAgree = totalAgreement !== null && totalAgreement.map((data) => (data === "Signed" ? 1 : data === "Unsigned" ? 0 : data === "Waived" && 2));

  const InspID = [];
  totalInspectors !== null &&
    totalInspectors.map((ins) =>
      inspList.map((ins1) => {
        if (ins === ins1.name) {
          return InspID.push(ins1.id);
        }
      })
    );
  const AgentID = [];

  totalAgents !== null &&
    totalAgents.map((age) =>
      agentsLists.map((age1) => {
        if (age === age1.name) {
          return AgentID.push(age1.id);
        }
      })
    );

  const defaultApi = {
    company_id: companyId,
    user_id: uuId,
    role_id: roleId,
    page_count: rowsPerPage,
  };
  const backFilter = {
    agent_id: AgentID.length === 0 ? null : AgentID,
    inspector_id: InspID.length === 0 ? null : InspID,
    status: totalStatus,
    paid: replacePaid.length === 0 && !replaceAgree ? null : replacePaid,
    agreement: replaceAgree.length === 0 && !replaceAgree ? null : replaceAgree,
    from_date: FilterType === 2 ? dateRange.from_date : "",
    to_date: FilterType === 2 ? dateRange.to_date : "",
  };

  const CondCheck = (datas) => {
    if (datas === null || datas.length === 0 || !datas) {
      return true;
    }
  };

  // const ApplyCloseFunction = () => {
  //   Object.values(backFilter).every(value => {

  //     if (value === null || value.length === 0) {
  //       console.log('true')
  //       return handleChipReset();
  //     }
  //     return console.log('false')
  //     ;
  //   });
  // }
  const checkAgreementAcceptStatus = () => {
    if (sessionStorage.getItem("is_trial") === "1" && sessionStorage.getItem("can_create_inspection") !== 'true' && sessionStorage.getItem("can_create_inspection") !== 'undefined') {
      setCantCreateInsp(true);
    } else if (companyInformation.agreement?.agreement_status === 0 || companyInformation.agreement?.agreed_to_terms_for_agreement === 1 || companyInformation?.agreement?.is_default_agreement_deleted === 1) navigate("/schedule-inspection");
    else if (companyInformation?.agreement?.agreed_to_terms_for_agreement === 0) {
      setShowInspAgrmntModal(true);
    }
  };
  const FilterInspections = () => {
    // setShowLoader(true);
    const queryParams = `?page=${page + 1}`;
    if (CondCheck(backFilter?.agent_id) && CondCheck(backFilter?.agreement) && CondCheck(backFilter?.inspector_id) && CondCheck(backFilter?.paid) && CondCheck(backFilter?.status) && CondCheck(backFilter?.from_date) && CondCheck(backFilter?.to_date)) {
      handleChipReset();
    }
    const formdata = { local: { ...defaultApi, ...backFilter }, query: queryParams };

    GetFilteredInspections((response) => {
      const { data, message, success } = response.data;
      if (success) {
        BackFilterdata(data.data);
        GetTotalList(data.total);
        props.pgType();
      } else {
        BackFilterdata([]);
      }
    }, formdata);
  };

  const removeStatus = (item) => {
    RemoveStatusChange(item);
    let filteredArr = totalStatus.filter((el) => el !== item);
    setTotalStatus(filteredArr);
    restricter();
  };

  const removePaid = (item) => {
    props.RemovePaidChange(item);
    let filteredArr = totalPaid.filter((el) => el !== item);
    setTotalPaid(filteredArr);
    setPage(0);
    restricter();
  };

  const removeAgreement = (item) => {
    props.RemoveAgreeChange(item);
    let filteredArr = totalAgreement.filter((el) => el !== item);
    setTotalAgreement(filteredArr);
    setPage(0);
    restricter();
  };

  const removeInspector = (item) => {
    let filteredArr = totalInspectors.filter((el) => el !== item);
    setTotalInspectors(filteredArr);
    RemoveInspectorChange(filteredArr);
    setPage(0);
    restricter();
  };

  const removeAgents = (item) => {
    let filteredArr = totalAgents.filter((el) => el !== item);
    setTotalAgents(filteredArr);
    RemoveAgentsChange(filteredArr);
    setPage(0);
    restricter();
  };

  const removeDates = (item) => {
    let filteredArr = dates !== item;
    // setDate(filteredArr)
    setDate();
    removeDate();
    restricter();
    setPage(0);
  };

  const GetInspectionDetailsList = () => {
    const formdata = { company_id: companyId, user_id: uuId, role_id: roleId };
    NewFilterInspectionDetails((response) => {
      const { data, message, success } = response.data;
      if (success) {
        setInspectorList(data?.inspectors);
        setAgentsList(data?.agents);
      } else {
        // console.log("else ", message);
      }
    }, formdata);
  };

  const inspectionListExcel = () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      timezone: timeZone,
    };
    apiCall(
      (response) => {
        if (response.data.success) {
          setExcelInspectionData(response.data.data);
        } else {
          // console.log("else", response.data.message);
        }
      },
      formdata,
      "GET_INSPECTION_LIST_EXCEL"
    );
  };

  const handleOpen = () => {
    setOpen(true);
    GetInspectionDetailsList();
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (restrict !== 0) {
      FilterInspections();
    }
    inspectionListExcel();

    // return () => {
    //   setRestrict(0)
    // }
  }, [restrict]);

  // Avoid a layout jump when reaching the last page with empty rows.

  // console.log(table_data)

  return (
    <>
      <NoInspection showNoInspModal={cantCreateInsp} setShowOnInspModal={setCantCreateInsp} />
      <UseInspectionAgreementModal showInspAgrmntModal={showInspAgrmntModal} setShowInspAgrmntModal={setShowInspAgrmntModal} setShowIncludedAgrmntModal={setShowIncludedAgrmntModal} />
      <IncludedAgreementModal showIncludedAgrmntModal={showIncludedAgrmntModal} setShowIncludedAgrmntModal={setShowIncludedAgrmntModal} setShowInspAgrmntModal={setShowInspAgrmntModal} setShowCreateOwnAgrmntModal={setShowCreateOwnAgrmntModal} />
      <CreateOwnAgreementModal showCreateOwnAgrmntModal={showCreateOwnAgrmntModal} setShowCreateOwnAgrmntModal={setShowCreateOwnAgrmntModal} />
      <Box
        sx={{
          width: "100%",
          px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },
        }}
        className="inspections-main"
      >
        {/* <Grid container spacing={2} className="inspections-head"> */}
        <div className="inspection-header">
          <Grid sx={{ width: "50%" }}>
            <h3 className="inspection-title">Inspections</h3>
            <p className="inspections-subheader">All inspections in your history. Select one to see more.</p>
          </Grid>
          {!isMobileMedia && props.inspCountData.length !== 0 && props.inspCountData.is_trial_company === 1 && (
            <Alert variant="filled" severity="error" className={`subscription__alert ${ownerPermission ? "owner" : "normal"}`}>
              <p>Scheduled {props.inspCountData?.total_inspections} out of 5 free inspections </p>
              {props.inspCountData.length !== 0 && props.loggedUserData.length !== "" && ownerPermission && (
                // <a
                //   className="navlink subscribe"
                //   target="_blank"
                //   // href={`${chargifyURL}?email=${props.loggedUserData.email}&first_name=${props.loggedUserData.first_name}&last_name=${props.loggedUserData.last_name}`}
                //   style={{ textDecoration: "none" }}
                // >
                //   <span>Subscribe now</span>
                // </a>
                <span className="navlink subscribe" onClick={() => navigate("/settings/billing-plan", { state: { fromInspectionSubscribe: true } })}>
                  {" "}
                  <span>Subscribe now</span>
                </span>
              )}
            </Alert>
          )}
          {!isMobile ? (
            <button
              className="inspections-new-button"
              // variant="contained"
              sx={{ textTransform: "none" }}
              onClick={checkAgreementAcceptStatus}
            >
              Add new &nbsp;&nbsp;+{" "}
            </button>
          ) : (
            <button
              className="inspections-new-button2"
              // variant="contained"
              sx={{ textTransform: "none" }}
              onClick={checkAgreementAcceptStatus}
            >
              Add new &nbsp;&nbsp;+{" "}
            </button>
          )}
        </div>
        {isMobileMedia && (
          <div style={{ padding: "0px 23px 0px 16px", marginBottom: "20px" }}>
            {props.inspCountData.length !== 0 && props.inspCountData.is_trial_company === 1 && (
              <Alert
                variant="filled"
                severity="error"
                style={{
                  height: "50px",
                  alignItems: "center",
                  position: "relative",
                  top: "10px",
                  background: "#FFF2E0",
                  color: "#FF9800",
                  lineHeight: "22px",
                  justifyContent: "center",
                }}
              >
                <p>Scheduled {props.inspCountData?.total_inspections} out of 5 free inspections </p>
                {props.inspCountData.length !== 0 && props.loggedUserData.length !== "" && ownerPermission && (
                  // <a
                  //   className="navlink subscribe"
                  //   target="_blank"
                  //   href={`${chargifyURL}?email=${props.loggedUserData.email}&first_name=${props.loggedUserData.first_name}&last_name=${props.loggedUserData.last_name}`}
                  //   style={{ textDecoration: "none" }}
                  // >
                  //   <span>Subscribe now</span>
                  // </a>
                  <span className="navlink subscribe" onClick={() => navigate("/settings/billing-plan", { state: { fromInspectionSubscribe: true } })}>
                    {" "}
                    <span>Subscribe now</span>
                  </span>
                )}
              </Alert>
            )}
          </div>
        )}
        {/* </Grid> */}
        <Paper sx={{ width: "100%", sm: 8, mb: 4, lg: 2 }} className={"inspection-table-main"}>
          <EnhancedTableToolbar TotalFilter={TotalFilter} searchIcon={searchIcon} InspectionList={table_data} excelData={excelData} excelinspectionData={excelinspectionData} isMobile={isMobile} handleOpen={handleOpen} displayFilters={displayFilters} handleChipReset={handleChipReset} handleChipClick={handleChipClick} handleChipDelete={handleChipDelete} totalStatus={totalStatus} totalPaid={totalPaid} totalAgreement={totalAgreement} totalInspectors={totalInspectors} totalAgents={totalAgents} dates={dates} removeDates={(item) => removeDates(item)} removePaid={(item) => removePaid(item)} removeAgreement={(item) => removeAgreement(item)} removeInspector={(item) => removeInspector(item)} removeAgents={(item) => removeAgents(item)} removeStatus={(status) => removeStatus(status)} handleSearchFilter={(event) => handleSearchFilter(event)} />
          <TableLayout
            // tableData={tableData}
            in_trial={props.inspCountData}
            type={"inspections"}
            page={page}
            totalList={totalList}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPage={rowsPerPage}
            loade={loade}
            reLoader={reLoader}
            InspectionList={table_data}
            paymentStatus={paymentStatus}
            agreementStatus={agreementStatus}
            inspListApiResponseMessage={inspListApiResponseMessage}
          />
        </Paper>
      </Box>
      <FilterModal handleClose={handleClose} agentsLists={agentsLists} inspList={inspList} open={open} FilterType={FilterType} FilterTypeHandler={FilterTypeHandler} handleApplyFilters={handleApplyFilters} specificDate={specificDate} fromDate={fromDate} toDate={toDate} handleStatus={handleStatus} totalSelected={totalSelected} status={status} payment={payment} agreement={agreement} handlePaid={handlePaid} handleAgreement={handleAgreement} handleSpecificDate={(newValue) => handleSpecificDate(newValue)} handleFromDate={(newValue) => handleFromDate(newValue)} handleToDate={(newValue) => handleToDate(newValue)} allInspectors={allInspectors} allAgents={allAgents} handleChangeInspectors={handleChangeInspectors} handleChangeAgents={handleChangeAgents} filterHandler={props.filterHandler} handleCancelFilters={handleCancelFilters} />
    </>
  );
}
