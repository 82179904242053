//file imports****************************************************************************************

import moment from "moment";
import PropTypes from "prop-types";
import toast from "react-hot-toast";
import { visuallyHidden } from "@mui/utils";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useMediaQuery } from "react-responsive";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import "../../../assets/styles/containers/_agent.scss";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Preloader from "../../helpers/Preloader";
// import LoadingSkelton from "../../helpers/LoadingSkeleton";
import { descendingComparator, GetCurrentResolution } from "../../utils/services";
import Sortswitch from "../../../assets/images/Sortswitch.png";
import { apiCall } from "../../pages/settings/Services/action";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Alert, Grid, Modal, Table, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Box, Paper, Avatar, styled, TableRow, TablePagination, InputBase, IconButton, TableHead, TableSortLabel, TableContainer, Typography } from "@mui/material";

var currentdate = new Date();
const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, `AgentList ${currentdate.toDateString()}` + fileExtension);
};

//Styled component for action column*****************************************************************

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
//     return -1;
//   }
//   if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
//     return 1;
//   }
//   return 0;
// }

//table row heading *************************************************************************************

const agenttablehead = [
  { id: "avatar", numeric: false, disablePadding: false, label: "" },
  { id: "first_name", numeric: false, disablePadding: false, label: "FIRST NAME" },
  { id: "last_name", numeric: false, disablePadding: false, label: "LAST NAME" },
  { id: "agency_name", numeric: false, disablePadding: false, label: "AGENCY" },
  { id: "phone", numeric: true, disablePadding: false, label: "PHONE" },
  { id: "email", numeric: false, disablePadding: false, label: "EMAIL" },
  { id: "total_inspection", numeric: true, disablePadding: false, label: "INSPECTIONS" },
  { id: "date_updated", numeric: true, disablePadding: true, label: "MOST RECENT" },
  { id: "actions", numeric: false, disablePadding: true, label: "ACTIONS" },
];

// acsc / desc function***********************************************************************************************

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

//sort function*****************************************************************************************

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//map funtion of table heading**************************************************************************

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {agenttablehead.map((headCell) => (
          <td key={headCell.id} align={"left"} padding={headCell.disablePadding ? "none" : "normal"} className="agent-table-head" sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel IconComponent={headCell.id === "avatar" || headCell.id === "Actions" ? "" : () => <img alt="icon" src={Sortswitch} style={{ marginRight: "6px" }} className="sort-switch" />} active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)} sx={{ marginTop: GetCurrentResolution() === "mobile" && "21px", marginBottom: "10.5px", flexDirection: "row-reverse", fontSize: "12px" }}>
              {headCell.label} {/* table heading label */}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </td>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });
  return (
    <Grid sx={{ flexGrow: 1 }} container spacing={2}>
      <Grid item xs={12} sx={{ width: { xs: "371.5px", sm: "100%", md: "100%", lg: "100%" } }} className="inspection-toolbar-main">
        <Paper
          component="form"
          className={"inspection-search-field"}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: { xs: "241px", sm: "29.64%", md: "29.64%", lg: "29.64%" },
          }}
        >
          <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon className="inspection-search-icon" />
          </IconButton>
          <InputBase sx={{ ml: 1, flex: 1, fontSize: "16px" }} placeholder="Search" inputProps={{ "aria-label": "search" }} onChange={props.handleSearchFilter} />
        </Paper>
        {isMobile ? (
          <p
            className="inspections-export-content"
            // onClick={() => window.print()}
            onClick={(e) => exportToCSV(props.excelData, "AgentListhello")}
          >
            <ExitToAppIcon sx={{ fontSize: "38px" }} className="inspections-export-icon" /> {isMobile ? "" : "Export"}
          </p>
        ) : (
          <p
            sx={{ width: "7.6%" }}
            className="inspections-export-content"
            // onClick={() => window.print()}
            onClick={(e) => exportToCSV(props.excelData, "AgentListhello")}
          >
            <ExitToAppIcon className="inspections-export-icon" />
            {isMobile ? "" : "Export"}
          </p>
        )}
      </Grid>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};

//render function ***************************************************************************************************

export default function AgentListView() {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const handleOpen = () => setOpen(true);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [order, setOrder] = useState("asc");
  const [message, setMessage] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [selected, setSelected] = useState([]);
  const [showLoader, setShowLoader] = useState();
  const [table_data, setTableData] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("first_name");
  const handleChipReset = () => setDisplayFilters(false);
  const [deleteControl, setDeleteControl] = useState(false);
  const [ displayFilters, setDisplayFilters] = useState(false);
  const handleChipClick = () => console.info("You clicked the Chip.");
  const handleChipDelete = () => console.info("You clicked the delete icon.");
  let result = JSON.parse(JSON.stringify(excelData));
  result.filter((data) => (delete data.profile_pic, delete data.avatar, delete data.company_id, delete data.id));

  //Get agent Api call*************************************************************

  const GetAgentLists = () => {
    setShowLoader(true);
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
    };
    apiCall(
      (response) => {
        // console.log("GET AGENT LIST", response);
        if (response.data.success) {
          setTableData(response.data.data);
          setDefaultData(response.data.data);
          setExcelData(response?.data?.data);
          setShowLoader(false);
        } else {
          // console.log("else", response.data.message);
          setMessage(response.data.message);
          // eslint-disable-next-line no-unused-expressions
          if (response.data.status_code === 404) setTableData([]);
          setShowLoader(false);
        }
      },
      formdata,
      "GET_AGENT_LIST"
    );
  };
  const handleClickOpenAlert = (id) => {
    setDeleteId(id);
    setOpen(true);
  };
  // delete agent api call**********************************************************

  const deleteAgent = (id) => {
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      agent_id: id,
    };
    apiCall(
      (response) => {
        // console.log("REMOVE AGENT RESPONSE", response);
        if (response.data.success) {
          toast.success(response.data.message);
          setOpen(false);
          setDeleteControl(!deleteControl);
        } else {
          // console.log("else", response.data.message);
        }
      },
      formdata,
      "REMOVE_AGENT"
    );
  };

  useEffect(() => {
    GetAgentLists();
  }, [deleteControl]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = table_data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchFilter = (event) => {
    setPage(0);
    let searchQuery = event.target.value?.toLowerCase();
    let searchData = table_data.filter((obj) =>
      Object.values(obj)
        ?.flat()
        ?.some((v) => `${v}`?.toLowerCase()?.includes(searchQuery))
    );
    if (searchQuery !== "") {
      if (searchData.length !== 0) {
        setTableData(searchData);
      }
    }
    if (searchQuery === "") {
      setTableData(defaultData);
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - table_data.length) : 0;

  const isMobile = useMediaQuery({ query: "(max-width: 550px)" });

  return (
    <>
      {showLoader ? (
        <Preloader showPreloader={true} />
      ) : (
        <Box
          className="inspections-main"
          sx={{
            width: "100%",
            px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },
          }}
        >
          <div className="inspection-header">
            <Grid sx={{ width: "50%" }}>
              <h3 className="inspection-title">Agents</h3>
              <p className="inspections-subheader">All agents in your history. Select one to see details.</p>
            </Grid>
            {!isMobile ? (
              <button className="inspections-new-button" sx={{ textTransform: "none" }} id="add" onClick={() => navigate(`/agents/new`, { state: { id: "", edit: false } })}>
                Add new &nbsp;&nbsp;+{" "}
              </button>
            ) : (
              <button className="inspections-new-button2" sx={{ textTransform: "none" }} id="add" onClick={() => navigate(`/agents/new`, { state: { id: "", edit: false } })}>
                Add new &nbsp;&nbsp;+{" "}
              </button>
            )}
          </div>
          {table_data?.length !== 0 ? (
            <Paper sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 5 }} className={"inspection-table-main"}>
              <EnhancedTableToolbar handleOpen={handleOpen} displayFilters={displayFilters} handleChipReset={handleChipReset} handleChipClick={handleChipClick} handleChipDelete={handleChipDelete} handleSearchFilter={handleSearchFilter} table_data={table_data} excelData={result} />
              <TableContainer className={isMobile && "mobileScroller"} sx={{ overflowX: { sm: "auto", lg: "unset", md: "scroll" } }}>
                <Table
                  aria-labelledby="tableTitle"
                  size={"small"}
                  style={{
                    transform: isMobile && "rotate(180deg)",
                    overflowX: "unset",
                    width: "100",
                  }}
                >
                  <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={table_data.length} />
                  <tbody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with: rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(table_data, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.first_name);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <tr onClick={(event) => handleClick(event, row.name)} role="checkbox" aria-checked={isItemSelected} key={row.name} className="inspections-data-table">
                            <td style={{ paddingLeft: "10px", width: "40px", height: "40px" }}>
                              <Avatar src={row.avatar}></Avatar>
                            </td>
                            <td className="insp-address" component="th" style={{ height: "60px", paddingLeft: "15px" }} id={labelId} padding="none">
                              <span
                                className="table-body-row"
                                id={`agent_${index}`}
                                onClick={() =>
                                  navigate(`/agents/${row.first_name.replace(new RegExp("/", "g"), "")}`, {
                                    state: { id: row.id, edit: true },
                                  })
                                }
                              >
                                {row.first_name.length > 10 ? (
                                  <BootstrapTooltip title={<h1 className="font-13">{row.first_name}</h1>}>
                                    <p>{`${row.first_name.substring(11, 0)}...`}</p>
                                  </BootstrapTooltip>
                                ) : (
                                  row.first_name
                                )}
                                {/* First name column */}
                              </span>
                            </td>
                            <td className="insp-status" align="left" style={{ paddingLeft: "15px" }}>
                              {row.last_name?.length > 10 ? (
                                <BootstrapTooltip title={<h1 className="font-13">{row?.last_name}</h1>}>
                                  <p>{`${row.last_name?.substring(10, 0)}...`}</p>
                                </BootstrapTooltip>
                              ) : (
                                row.last_name
                              )}
                              {/* Last name column */}
                            </td>
                            <td className="agent-row-data-font" align="left" style={{ paddingLeft: "15px" }}>
                              <span>
                                {row.agency_name?.length > 10 ? (
                                  <BootstrapTooltip title={<h1 className="font-13">{row.agency_name}</h1>}>
                                    <p>{`${row.agency_name.substring(10, 0)}...`}</p>
                                  </BootstrapTooltip>
                                ) : (
                                  row.agency_name
                                )}
                              </span>
                            </td>
                            <td align="left">
                              <span className="agent-row-data-font" style={{ paddingLeft: "15px" }}>
                                {row.phone}
                                {/* phone number */}
                              </span>
                            </td>
                            <td
                              align="left"
                              style={{
                                paddingLeft: "15px",
                                paddingRight: "10px",
                              }}
                            >
                              <span className="agent-row-data-email">{row.email.replace(/(.{28})/g, `$1\n`)}</span>
                              {/* email address column */}
                            </td>
                            <td align="left" style={{ paddingLeft: "17px" }}>
                              {row.total_inspection}
                              {/* inspection number column */}
                            </td>
                            <td align="left" style={{ paddingLeft: "17px" }}>
                              <div style={{ textAlign: "left" }}>
                                <span className="date-placeholder">{moment(moment.utc(row?.date_updated).local()).format("L")}</span>
                                <span className="time-modified-placeholder" style={{ textTransform: "uppercase" }}>
                                  {moment(moment.utc(row?.date_updated).local()).fromNow()}
                                </span>
                              </div>
                              {/* date column */}
                            </td>
                            <td className="insp-actions" align="left" style={{ paddingLeft: "17px" }}>
                              <span>
                                <BootstrapTooltip title={<h1 className="font-13">Edit details</h1>}>
                                  <EditIcon
                                    className="edit-icon"
                                    id={`agent_edit_${index}`}
                                    onClick={() =>
                                      navigate(`/agents/${row.first_name?.charAt(0) === "#" ? row.first_name?.substring(1).replace(new RegExp("/", "g"), "") : row.first_name.replace(new RegExp("/", "g"), "")}`, {
                                        state: { id: row.id, edit: true },
                                      })
                                    }
                                  />
                                </BootstrapTooltip>
                                <BootstrapTooltip title={<h1>Delete record</h1>}>
                                  <DeleteIcon id={`agent_delete_${index}`} className="delete-icon" onClick={() => handleClickOpenAlert(row.id)} />
                                </BootstrapTooltip>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    {emptyRows > 0 && (
                      <tr style={{ height: 33 * emptyRows }}>
                        <td colSpan={6} />
                      </tr>
                    )}
                  </tbody>
                </Table>
              </TableContainer>
              {/* table pagination component***************************************************** */}
              <TablePagination rowsPerPageOptions={[10, 25]} component="div" count={table_data.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
            </Paper>
          ) : (
            <Paper sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 10 }} elevation={0} className={"inspection-table-main"}>
              <Alert severity="error">{message}</Alert>
            </Paper>
          )}
        </Box>
      )}
      {/* edit screen ************************************************* */}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "480px !important", height: "231px !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon sx={{ float: "right", cursor: "pointer" }} onClick={handleClose} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "22px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                Delete agent?
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography
                sx={{
                  color: "#333333",
                  fontFamily: "open sans",
                  fontSize: "14px",
                  mt: "15px",
                  letterSpacing: 0,
                }}
              >
                This action cannot be undone.
              </Typography>
            </Grid>
            <Grid container sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <Button
                variant="outlined"
                className="inspection-filter-modal-buttons-cancel"
                sx={{
                  width: "119px",
                  ":hover": {
                    backgroundColor: "transparent !important",
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="inspection-filter-modal-buttons-apply"
                sx={{
                  width: "119px",
                  ml: 3,
                  ":hover": {
                    backgroundColor: "#1E8970 !important",
                  },
                }}
                onClick={() => {
                  deleteAgent(deleteId);
                }}
              >
                Delete agent
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* alert dialog */}
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm delete agent?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete <h1>{AgentName}</h1> from agents?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={{color:"red", fontWeight:600}} onClick={handleClose}>CANCEL</Button>
          <Button autoFocus sx={{fontWeight:600}} onClick={() => {deleteAgent(deleteId)}} >
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
}
