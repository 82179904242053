import React, { useState } from "react";
import { toast } from "react-hot-toast";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileUpload from "../../../common/fileUpload/FileUpload";
import { apiCall } from "../../../utils/action";
import { PtImportUpload } from "./PtUploadFile";
import Preloader from "../../../helpers/Preloader";

function AddImportDialog(props) {
  const { setState, state, inspTypes } = props;
  const {
    moveToCustomer,
    file,
    progressPercent,
    addDialog,
    selectedCompany,
    fileImportError,
    apiCallInProgress,
  } = state;
  const [isTexasTemplate, setIsTexasTemplate] = useState(false);

  const [selectedType, setSelectedType] = useState();

  const handleCloseDialog = (closeDialog = false, closeFrom = "button") => {
    if (closeFrom !== "file") setSelectedType();
    if (!moveToCustomer && file && !fileImportError) {
      setState((prevState) => ({
        ...prevState,
        apiCallInProgress: true,
      }));
      let formData = new FormData();
      const params = {
        user_id: sessionStorage.getItem("user_id"),
        role_id: sessionStorage.getItem("role_id"),
        company_id: selectedCompany?.id || null,
        template_guid: selectedCompany?.template_guid || null,
      };
      Object.keys(params).forEach((key) => formData.append(key, params[key]));
      apiCall(
        (response) => {
          if (response.data.success) {
            setState((prevState) => ({
              ...prevState,
              file: null,
              selectedCompany: {
                ...selectedCompany,
                downloadable_error_file_link: null,
                template_guid: null,
              },
              addDialog: closeDialog ? true : false,
              apiCallInProgress: false,
              fileImportError: false,
            }));
          }
        },
        formData,
        "CANCEL_IMPORT_FILE"
      );
    } else {
      setState((prevState) => ({
        ...prevState,
        file: null,
        selectedCompany: {
          ...selectedCompany,
          downloadable_error_file_link: null,
          template_guid: null,
        },
        addDialog: closeDialog ? true : false,
        apiCallInProgress: false,
        fileImportError: false,
      }));
    }
    setIsTexasTemplate(false);
  };

  const handleSubmit = () => {
    setState((prevState) => ({ ...prevState, apiCallInProgress: true }));
    let formData = new FormData();
    const params = {
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      company_id: selectedCompany?.id || null,
      inspection_type_id: selectedType?.id || "",
      template_guid: selectedCompany?.template_guid || null,
      is_texas: isTexasTemplate ? 1 : 0,
    };
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    apiCall(
      (response) => {
        if (response.data.success) {
          setState((prevState) => ({
            ...prevState,
            file: null,
            selectedCompany: null,
            moveToCustomer: true,
            apiCallInProgress: false,
          }));
          setSelectedType();
        }
      },
      formData,
      "MOVE_TO_CUSTOMER_IMPORT"
    );
  };

  const handleDone = () => {
    setState((prevState) => ({
      ...prevState,
      addDialog: false,
      moveToCustomer: false,
    }));
    setIsTexasTemplate(false);
  };

  const handleUploadFile = async (file) => {
    setState((prevState) => ({
      ...prevState,
      file: file,
      selectedCompany: {
        ...selectedCompany,
        downloadable_error_file_link: null,
        template_guid: null,
      },
    }));
    let apiRes = false;
    let formData = new FormData();
    const params = {
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      company_id: selectedCompany?.id || null,
      file: file || null,
      is_texas: isTexasTemplate ? 1 : 0,
      inspection_type_id: selectedType?.id || "",
    };
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    try {
      const response = await PtImportUpload(formData, setState);
      if (response.data.success) {
        apiRes = true;
        setState((prevState) => ({
          ...prevState,
          file: file,
          fileImportError: undefined,
          selectedCompany: {
            ...selectedCompany,
            template_guid: response?.data?.data?.template_guid,
          },
        }));
      }
    } catch (error) {
      if (error?.response?.data?.data?.downloadable_error_file_link) {
        setState((prevState) => ({
          ...prevState,
          file: file,
          progressPercent: 0,
          fileImportError: true,
          selectedCompany: {
            ...selectedCompany,
            downloadable_error_file_link:
              error?.response?.data?.data?.downloadable_error_file_link,
          },
        }));
        apiRes = false;
      } else {
        toast.error(error?.response?.data?.message);
        setState((prevState) => ({
          ...prevState,
          file: null,
          progressPercent: 0,
        }));
        apiRes = false;
      }
    }
    return apiRes;
  };

  const moveToCustomerValidation = () => {
    if (!file && !selectedCompany?.template_guid) return true;
    else if (
      fileImportError &&
      !selectedCompany?.template_guid &&
      progressPercent !== 100
    )
      return true;
    else if (
      fileImportError &&
      file &&
      !selectedCompany?.template_guid &&
      progressPercent !== 100
    )
      return true;
    else if (!selectedCompany?.template_guid && progressPercent !== 100)
      return true;
    else if (progressPercent !== 100) return true;
    else if (!selectedCompany?.template_guid) return true;
    else if (apiCallInProgress) return true;
    else if (!selectedType) return true;
    else return false;
  };

  const handleDownloadErrorFile = () => {
    const fileUrl = selectedCompany?.downloadable_error_file_link;
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.setAttribute("download", "abc.xls");
    downloadLink.click();
  };

  const sendEmailToDevTeam = () => {
    let formData = new FormData();
    const params = {
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      company_id: selectedCompany?.id || null,
      error_fileurl: selectedCompany?.downloadable_error_file_link || null,
    };
    Object.keys(params).forEach((key) => formData.append(key, params[key]));
    apiCall(
      (response) => {
        if (response.data.success) {
          toast.success("Email has sent successfully.");
        }
      },
      formData,
      "SEND_TO_DEV_TEAM"
    );
  };

  const cancelDisabled = () => {
    if (!file) return false;
    else if (apiCallInProgress) return true;
    else if (!selectedCompany?.template_guid && !fileImportError) return true;
    else return false;
  };

  return (
    <>
      <Preloader showPreloader={apiCallInProgress} />
      <Dialog open={addDialog}>
        <DialogTitle>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              {!moveToCustomer ? (
                <Typography className="importHeading">Upload Files</Typography>
              ) : null}
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={() => handleCloseDialog()}
                className="closeButton"
                disabled={cancelDisabled()}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        {!moveToCustomer ? (
          <DialogContent sx={{ padding: "0px 36px" }}>
            <Grid container>
              <Grid item xs={0.8}>
                <Checkbox
                  size="small"
                  id="isTexasTemplate"
                  variant="outlined"
                  value={isTexasTemplate}
                  checked={isTexasTemplate}
                  className="input-textfield"
                  onChange={(e) => setIsTexasTemplate(e.target.checked)}
                />
              </Grid>
              <Grid item xs={5} mt={0.9}>
                <label className="input-form-label" htmlFor="payment">
                  TEXAS TREC 7-6
                </label>
              </Grid>
              <Grid item xs={6} className="mb-34">
                <label
                  className="pt_input-form-label mb-5"
                  htmlFor="pt_type_insp"
                >
                  Type of inspection*
                </label>
                <FormControl fullWidth>
                  <Autocomplete
                    className={"custom-color arrow-style"}
                    id="pt_type_insp"
                    options={inspTypes}
                    filterSelectedOptions
                    size="small"
                    err
                    value={selectedType}
                    isOptionEqualToValue={(option, value) =>
                      option?.id === value?.id
                    }
                    getOptionLabel={(option) => option.name}
                    onChange={(e, newValue) => {
                      setSelectedType(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select type" />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Typography>{`Drag the .xls file here. Once complete, the template will appear in the client's list of templates. `}</Typography>
            <FileUpload
              progressPercent={progressPercent}
              setState={setState}
              state={state}
              file={file}
              handleUploadFile={handleUploadFile}
              handleDownloadErrorFile={handleDownloadErrorFile}
              sendEmailToDevTeam={sendEmailToDevTeam}
              cancelDisabled={cancelDisabled}
              handleCloseDialog={handleCloseDialog}
            />
          </DialogContent>
        ) : (
          <DialogContent>
            <Grid container display={"flex"} justifyContent={"center"}>
              <Grid
                item
                xs={12}
                my={4}
                display={"flex"}
                justifyContent={"center"}
              >
                <CheckCircleIcon className="ptDoneIcon" />
              </Grid>
              <Grid
                item
                xs={12}
                my={1}
                display={"flex"}
                justifyContent={"center"}
              >
                <Typography className="ptSuccessTitle">Success</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                my={1}
                display={"flex"}
                justifyContent={"center"}
              >
                <Typography className="ptUploadTitle">{`The template has been uploaded to the customer's portal.`}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                mt={4}
                mb={8}
                display={"flex"}
                justifyContent={"center"}
              >
                <Button onClick={() => handleDone()} className="ptDialogButton">
                  Done
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        {!moveToCustomer ? (
          <DialogActions>
            <Grid
              container
              display={"flex"}
              justifyContent={"flex-end"}
              gap={2}
              mr={2}
              mb={2}
            >
              <Button
                className="ptCancelButton"
                onClick={() => handleCloseDialog()}
                variant={"outlined"}
                disabled={cancelDisabled()}
              >
                Cancel
              </Button>
              <Button
                className={
                  moveToCustomerValidation() ? "ptDisableBtn" : "ptDialogButton"
                }
                disabled={moveToCustomerValidation()}
                onClick={() => handleSubmit()}
              >
                Move to Customer
              </Button>
            </Grid>
          </DialogActions>
        ) : null}
      </Dialog>
    </>
  );
}

export default AddImportDialog;
