import { Box } from "@mui/system";
import ErrorListView from "./ErrorListView";
import Header from "../../common/header/Header";
import Breadcrumb from "../../common/header/Breadcrumb";
const ErrorLogs = () => {
  return (
    <>
      {window.scrollTo(0, 0)}
      <Box sx={{ flexGrow: 1, backgroundColor: "#f7f9fa", minHeight: "100vh" }}>
        <Header />
        <Breadcrumb />
        <ErrorListView />
      </Box>
    </>
  );
};
export default ErrorLogs;
