import { useState } from "react";
import PropTypes from "prop-types";
import Preloader from "../../../helpers/Preloader";
import { Alert, Box, Grid, Paper, Table, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import { useMediaQuery } from "react-responsive";
// import EnhancedTableToolbar from "../../../common/toolbar/EnhancedTableToolbar";
import Sortswitch from "../../../../assets/images/Sortswitch.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import '../../../../assets/styles/containers/_ptTemplate.scss';
// import EnhancedTableHead from "../../../common/table/EnhancedTableHead";
import { BootstrapTooltip, GetCurrentResolution, getComparator, stableSort } from "../../../utils/services";
import moment from "moment";
import { visuallyHidden } from "@mui/utils";
import EnhancedTableToolbar from "../../../common/toolbar/EnhancedTableToolbar";
import { useNavigate } from "react-router-dom";
import PtDeleteTemplateModal from "./Modals/PtDeleteTemplateModal";
import PtEditTemplateModal from "./Modals/PtEditTemplateModal";
import { apiCall, apiCallGet } from "../../../utils/action";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import PtCreateNewTemplateModal from "./Modals/PtCreateNewTemplateModal";

  const Templatetablehead = [
    { id: "name", numeric: false, disablePadding: false, label: "TEMPLATE NAME" },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "DESCRIPTION",
    },
    {
      id: "created_at",
      numeric: true,
      disablePadding: false,
      label: "LAST EDITED",
    },
    {
      id: "published",
      numeric: false,
      disablePadding: false,
      label: "PUBLISHED",
    },
    {
      id: "report_count",
      numeric: true,
      disablePadding: false,
      label: "REPORTS",
    },
    { id: "show_in_library", numeric: true, disablePadding: false, label: "SHOW IN LIBRARY" },
    { id: "Actions", numeric: true, disablePadding: true, label: "ACTIONS" },
  ];
//map funtion of table heading
function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {Templatetablehead.map((headCell) => (
            <td
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              style={{ paddingLeft: "17px",fontWeight:headCell.id === "name"?'600':'400' }}
              className={`pt_template-table-head ${headCell.id === 'show_in_library'?'show_in_library_header' :""}`}
            >
              <TableSortLabel
                IconComponent={
                  headCell.id === "avatar" ||
                  headCell.id === "Actions" ||
                  headCell.id === "active"
                    ? ""
                    : ""
                    // () => (
                    //     <img
                    //       alt="icon"
                    //       src={Sortswitch}
                    //       style={{ marginRight: "6px" }}
                    //       className="sort-switch"
                    //     />
                    //   )
                }
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{
                  marginTop: GetCurrentResolution() === "mobile" && "21px",
                  marginBottom: "10.5px",
                  flexDirection: "row-reverse",
                  fontSize: "12px",
                  textTransform: "capitalize",
                }}
              >
                {headCell.label} {/* table heading label */}
                {headCell.label === "SHOW IN LIBRARY" ? (
                  <BootstrapTooltip title={<h1>Toggle this option to show or hide the report in the Palmtech template library. </h1>}>
                    <InfoOutlinedIcon className="template_info_icon" />
                  </BootstrapTooltip>
                ) : (
                  ""
                )}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </td>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  // props
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

const PtTemplateListView = () => {
    const [showLoader,setShowLoader] = useState(false)
    const [templateData,setTemplateData] = useState([]);
    const [defaultData,setDefaultdata] = useState([]);
    const [message,setMessage] = useState('');
    const [order, setOrder] = useState("asc");
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState("");    
    const [selected, setSelected] = useState([]);    
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openDeleteModal,setOpenDeleteModal] = useState(false);
    const [openEditModal,setOpenEditModal] = useState(false);
    const [openNewTempModal,setOpenNewTempModal] = useState(false);
    const [selectedTemplateName,setSelectedTemplateName] = useState('');
    const [selectedTemplate,setSelectedTemplate] = useState('');
    const [isPdfTemplateDelete,setIsPdfTemplateDelete] = useState(0);
    const [deleteTemplateGuid,setDeleteTemplateGuid] = useState('');
    const [refresh, setRefresh] = useState(false)
    const [noLoader,setNoloader] = useState(false)
    const [clickCount,setClickCount] = useState(0)
    const isMobile = useMediaQuery({ query: "(max-width: 550px)" });

    // authentication
    const roleId = sessionStorage.getItem("role_id");
    let ptTemplatePermission = roleId === "2" ? true : false;

    let navigate = useNavigate();

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        };

    const isSelected = (name) => selected.indexOf(name) !== -1;

      // Avoid a layout jump when reaching the last page with empty rows.
    
    const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - templateData.length) : 0;

    const handleChangePage = (event, newPage) => {
    setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    };

    const handleSearchFilter = (event) => {
    setPage(0);
    let searchQuery = event.target.value.toLowerCase();
    let searchData = templateData.filter((ele) => {
        return ele.name.toLowerCase().includes(searchQuery);
    });
    if (searchQuery !== "") {
        if (searchData.length !== 0) {
        setTemplateData(searchData);
        }
        else {
          setTemplateData([])
          setMessage('No matches found')
        }
    } else {
        setOrderBy("");
        setTemplateData(defaultData);
    }
    };

    const handleCloseDeleteModal =()=>{
      setOpenDeleteModal(false)
    }
    const handleCloseEditModal =()=>{
      setOpenEditModal(false)
    }
    const handleCloseNewTempModal =()=>{
      setOpenNewTempModal(false)
    }
    const handleOpenNewTempModal =()=>{
      setOpenNewTempModal(true)
    }
    
    const handleClickOpenDeleteModal =(templateGuid,templateName,pdfTemplate)=>{
      setOpenDeleteModal(true);
      setSelectedTemplateName(templateName)
      setIsPdfTemplateDelete(pdfTemplate)
      setDeleteTemplateGuid(templateGuid)
    }
    const handleClickOpenEditModal =(template,templateName)=>{
      setOpenEditModal(true);
      setSelectedTemplateName(templateName)
      setSelectedTemplate(template)
    }
    const TogglerSwitch =(guid,curentStatus,isPdfTemplate)=>{
      setClickCount(prev=>prev+1)
      if(isPdfTemplate !==1){
        toast.dismiss();
        toast.error("The status of a non-PDF template cannot be changed.")
        setClickCount(100)
      }
      else{
          if(clickCount<1 || clickCount === 100){
        setNoloader(true)
        toast.dismiss();
      const formdata = { template_guid:guid , is_pdf_template: isPdfTemplate, show_in_library:curentStatus === 0?1:0  };
        apiCall(
        (response) => {
          const {  message, success } = response.data;
          if (success) {
            toast.success(message);           
            setRefresh(prev=>!prev)         
            // setShowLoader(false);
          } else {
            handleCloseDeleteModal(true)
            toast.error(message);
          }
        },
        formdata,
        "PT_UPDATE_TEMPLATE_DETAILS"
      );
      }
      }   
      
    }

    const getPtTemplateList=(type)=>{
      if(type !=="noLoader")setShowLoader(true);
      apiCallGet(
        (response) => {
          const { data, message, success } = response.data;
          if (response.data.success) {
            
            setTemplateData(data)
            setDefaultdata(data)
            setMessage(message)
            setShowLoader(false)
            setNoloader(false)
            setClickCount(0)
          } else {
            toast.error(message)
            setShowLoader(false)
            setNoloader(false)
            setClickCount(0)
          }
        },
        "",
        "PT_TEMPLATE_LIST"
      );
    }

    const handleTestCompanyPreview =(row)=>{
        const formdata = { template_guid:row.guid , is_pdf_template: row.is_pdf_template };
        apiCall(
        (response) => {
          const {  message, success } = response.data;
          if (success) {
            let templateData = response.data;
            let prevData = JSON.parse(JSON.stringify(templateData));
             prevData.data.template.publish_to_preview = 0;
            const formdata2 =  prevData.data.template;
            apiCall(
            (response) => {
              const { message, success } = response.data;
              if (success) {
                navigate(
                  `/template/${
                    row.name?.charAt(0) === "#" ||
                    row.name?.charAt(0) === "/"
                      ? row.name
                          ?.substring(1)
                          .replace(
                            new RegExp("/", "g"),
                            ""
                          )
                      : row.name.replace(
                          new RegExp("/", "g"),
                          ""
                        )
                  }`,
                  {
                    state: {
                      guid: row.guid,
                      name: row?.name,
                      version: row.version,
                      isPdfTemplate:row.is_pdf_template,
                      edit: true,
                    },
                  }
                )
              } else {                
                toast.error(message);
              }
            },
            formdata2,
            "PT_UPDATE_TEMPLATE_DATA"
          );
          } else {
            toast.error(message);
          }
        },
        formdata,
        "PT_GET_TEMPLATE_DATA"
      );
    }

    useEffect(() => {
      if(noLoader) getPtTemplateList("noLoader");
      else getPtTemplateList("normalLoad")
    }, [refresh])
    
  return (
    <>
    <PtCreateNewTemplateModal openNewTempModal={openNewTempModal} handleCloseNewTempModal={handleCloseNewTempModal}/>
    <PtDeleteTemplateModal handleCloseDeleteModal={handleCloseDeleteModal} openDeleteModal={openDeleteModal} 
    selectedTemplateName={selectedTemplateName} isPdfTemplateDelete={isPdfTemplateDelete}
    deleteTemplateGuid={deleteTemplateGuid} setRefresh={setRefresh} setNoloader={setNoloader}/>
    <PtEditTemplateModal handleCloseEditModal={handleCloseEditModal} openEditModal={openEditModal} selectedTemplateName={selectedTemplateName}
     setSelectedTemplateName={setSelectedTemplateName} selectedTemplate={selectedTemplate}
     setSelectedTemplate={setSelectedTemplate} />   
        {showLoader ? (
        <Preloader showPreloader={true} />
      ) : (
        <Box
          className="inspections-main"
          sx={{
            width: "100%",
            px: { xs: "0", sm: "20px", md: "80px", lg: "80px" },
          }}
        >
          <div className="inspection-header">
            <Grid sx={{ width: "50%" }}>
              <h3 className="inspection-title">Palmtech Templates</h3>
              <p className="inspections-subheader">
              All templates in the Palmtech library. Select one to see details. 
              </p>
            </Grid>
            {!isMobile ? (
              <button
                className="inspections-new-button"
                sx={{ textTransform: "none" }}
                onClick={() => handleOpenNewTempModal()}
              >
                Add new &nbsp;&nbsp;+
              </button>
            ) : (
              <button
                className="inspections-new-button2"
                sx={{ textTransform: "none" }}
                onClick={() => handleOpenNewTempModal()}
              >
                Add new &nbsp;&nbsp;+
              </button>
            )}
          </div>
          {templateData?.length !== 0 ? (
            <Paper
              sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 5 }}
              className={"inspection-table-main"}
            >
              <EnhancedTableToolbar
                // handleOpen={handleOpen}
                templateData={templateData}
                excelinspectionData={JSON.parse(JSON.stringify(templateData))}
                excelName="PT Template List"
                // displayFilters={displayFilters}
                // handleChipReset={handleChipReset}
                handleSearchFilter={handleSearchFilter}
                searchIcon={true}
              />
              <TableContainer
                className={isMobile && "mobileScroller"}
                sx={{ overflowX: { sm: "auto", lg: "unset", md: "scroll" } }}
              >
                <Table
                  aria-labelledby="tableTitle"
                  size={"small"}
                  style={{
                    transform: isMobile && "rotate(180deg)",
                    overflowX: "unset",
                    width: "100",
                  }}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    // onSelectAllClick={handleSelectAllClick}
                    // onRequestSort={handleRequestSort}
                    rowCount={templateData?.length}
                  />
                  <tbody>
                    {/* if you don't need to support IE11, you can replace the `stableSort` call with: rows.slice().sort(getComparator(order, orderBy)) */}
                    {stableSort(templateData, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        let updtime = moment(
                          moment.utc(row?.publish_date).local()
                        );
                        let lastusedtime = moment(
                          moment.utc(row?.last_edited).local()
                        );
                        let timeStramp = moment(updtime).fromNow();
                        let lastusedtimeStramp = moment(lastusedtime).fromNow();
                        const isItemSelected = isSelected(row.name);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (                       
                          <tr
                            key={row.id}
                            // onClick={(event) => handleClick(event, row.name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            className="inspections-data-table"
                          >
                            {/* <td style={{paddingLeft:"10px"}}><Avatar src={row.avatar}></Avatar></td> */}
                            <td
                              className="insp-address"
                              component="th"
                              style={{
                                height: "60px",
                                paddingLeft: "30px",
                                // borderLeft:
                                //   row?.default === 1 && "3px solid #239d80",
                              }}
                              id={labelId}
                              padding="none"
                            >
                              <div
                                style={{
                                  width: "220px",
                                  display: "flex",
                                  alignItems: "center",
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                }}
                                onClick={() =>
                                  row?.is_published === 1
                                    ?  handleClickOpenEditModal(row,row.name) 
                                    :row?.publish_to_preview === 1?
                                    handleTestCompanyPreview(row)                                      
                                    : navigate(
                                        `/template/${
                                          row.name?.charAt(0) === "#" ||
                                          row.name?.charAt(0) === "/"
                                            ? row.name
                                                ?.substring(1)
                                                .replace(
                                                  new RegExp("/", "g"),
                                                  ""
                                                )
                                            : row.name.replace(
                                                new RegExp("/", "g"),
                                                ""
                                              )
                                        }`,
                                        {
                                          state: {
                                            guid: row.guid,
                                            name: row?.name,
                                            version: row.version,
                                            isPdfTemplate:row.is_pdf_template,
                                            edit: true,
                                          },
                                        }
                                      )
                                }
                              >
                                <span
                                  style={{
                                    fontWeight: "900",
                                    // color: row?.default === 1 && "#239d80",
                                  }}
                                  className="table-body-row"                                  
                                  // onClick={() =>
                                  //   row?.is_pdf_template !== 1 || row.is_published === 1?"":
                                  //   // row?.is_published === 1
                                  //   //   ? handleOpenEdits(row.id, row.name)
                                  //   //   : row?.is_published === 2
                                  //   //   ? handleOpenEdits(row.id, row.name)
                                  //   //   :
                                  //      navigate(
                                  //         `/template/${
                                  //           row.name?.charAt(0) === "#" ||
                                  //           row.name?.charAt(0) === "/"
                                  //             ? row.name
                                  //                 ?.substring(1)
                                  //                 .replace(
                                  //                   new RegExp("/", "g"),
                                  //                   ""
                                  //                 )
                                  //             : row.name.replace(
                                  //                 new RegExp("/", "g"),
                                  //                 ""
                                  //               )
                                  //         }`,
                                  //         {
                                  //           state: {
                                  //             guid: row.guid,
                                  //             name: row?.name,
                                  //             isPdfTemplate:row.is_pdf_template,
                                  //             // version: row.version,
                                  //             edit: true,
                                  //           },
                                  //         }
                                  //       )
                                  // }
                                >
                                  <span className="template_name_container">{row?.is_pdf_template?<PictureAsPdfOutlinedIcon className="pdf_icon"/>:''}
                                  {row?.name?.length > 50 ? (
                                    <BootstrapTooltip
                                      title={
                                        <h1 className="font-13">{row?.name}</h1>
                                      }
                                    >
                                      <p
                                        style={{
                                          color:
                                            row?.default === 1 && "#239d80",
                                        }}
                                      >{`${row.name.substring(11, 0)}...`}</p>
                                    </BootstrapTooltip>
                                  ) : (
                                    row.name
                                  )}</span>
                                </span>
                                {/* {row?.default === 1 && (
                                  <h6
                                    style={{
                                      color: "#9B9B9B",
                                      fontSize: "10px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    &nbsp;(Default)
                                  </h6>
                                )} */}
                              </div>
                              
                              {row.is_published === 0 && row.publish_to_preview === 1 &&(
                                <span className="changes_status_preview">
                                  Published to test companies
                                </span>
                              )}
                              {row.is_published === 0 && row.publish_to_preview === 0 &&(
                                <span className="changes_status">
                                  Unpublished changes
                                </span>
                              )}
                               {/* {row.is_published === 0 && (
                                <span className="changes_status">
                                  Unpublished changes
                                </span>
                              )} */}
                              {row.is_published === 2 && (
                                <span className="changes_status">
                                  Publish In-progress
                                </span>
                              )}
                            </td>
                            <td
                              className="insp-status"
                              align="left"
                              style={{ paddingLeft: "26px" }}
                            >
                              <div
                                style={{
                                  width: "220px",
                                  wordWrap: "break-word",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {row?.description?.length > 35 ? (
                                  <BootstrapTooltip
                                    title={
                                      <h1 className="font-13">
                                        {row?.description}
                                      </h1>
                                    }
                                  >
                                    <p>{`${row.description.substring(
                                      30,
                                      0
                                    )}...`}</p>
                                  </BootstrapTooltip>
                                ) : (
                                  row.description
                                )}
                              </div>
                            </td>
                            <td align="left" style={{ paddingLeft: "26px" }}>
                              <div style={{ textAlign: "left" }}>
                                <span className="date-placeholder">
                                  {moment(row.last_edited).format("MM/DD/yy")}
                                </span>
                                <span
                                  className="time-modified-placeholder"
                                  style={{ textTransform: "initial" }}
                                >
                                  {lastusedtimeStramp}
                                </span>
                              </div>
                            </td>
                            <td align="left" style={{ paddingLeft: "26px" }}>
                              <div style={{ textAlign: "left" }}>
                                <span className="date-placeholder">
                                  {row.publish_date === null
                                    ? " "
                                    : moment(row.publish_date).format("MM/DD/yy")}
                                </span>
                                <span
                                  className="time-modified-placeholder"
                                  style={{ textTransform: "initial" }}
                                >
                                  {row.publish_date === null
                                    ? ""
                                    : timeStramp}
                                </span>
                              </div>
                            </td>
                            <td
                              align="left"
                              style={{
                                paddingLeft: "26px",
                                paddingRight: "10px",
                              }}
                            >
                              <span className="agent-row-data-email">
                                {row.report_count ?? 0}
                              </span>
                            </td>
                            <td
                              onClick={() => {ptTemplatePermission && row.is_published === 1 && TogglerSwitch(row.guid,row.show_in_library,row.is_pdf_template)}}
                              align="left"
                              style={{ paddingLeft: "25px",cursor:'pointer' }}
                            >
                              {!ptTemplatePermission?
                              <BootstrapTooltip
                              title={<h1 className="font-13">You cannot perform this action.</h1>}
                            >
                             {row.show_in_library === 0 ? (
                                <ToggleOffIcon
                                  id={`toggleOff${index + 1}`}
                                  style={{ fontSize: "38px", color: "#BEBEBE" }}
                                />
                              ) : (
                                <ToggleOnIcon
                                  id={`toggleOn${index + 1}`}
                                  style={{ fontSize: "38px", color: "#239D80" }}
                                />
                              )}
                            </BootstrapTooltip>
                              :row.is_published === 0 ?
                              <BootstrapTooltip
                              title={<h1 className="font-13">Changes must be published to show a template in the library</h1>}
                            >
                             <ToggleOffIcon
                                  id={`toggleOff${index + 1}`}
                                  style={{ fontSize: "38px", color: "#BEBEBE",opacity: '0.25' }}
                                />
                            </BootstrapTooltip>
                              :
                              row.show_in_library === 0 ? (
                                <ToggleOffIcon
                                  id={`toggleOff${index + 1}`}
                                  style={{ fontSize: "38px", color: "#BEBEBE" }}
                                />
                              ) : (
                                <ToggleOnIcon
                                  id={`toggleOn${index + 1}`}
                                  style={{ fontSize: "38px", color: "#239D80" }}
                                />
                              )}
                            </td>
                            <td
                              className="insp-actions"
                              align="left"
                              style={{ paddingLeft: "26px" }}
                            >
                              <span>
                                {
                                  row.is_pdf_template?
                                  <>
                                   <BootstrapTooltip
                                  title={<h1 className="font-13">{row.is_pdf_template !== 1?"Editing a Non-PDF template is not supported now.":'Edit'}</h1>}
                                >
                                  <EditIcon
                                    className="edit-icon"
                                    id={`edit${index + 1}`}
                                    // onClick={()=>handleClickOpenEditModal(row.id,row.name)}
                                    onClick={() =>
                                      row?.is_published === 1
                                        ?  handleClickOpenEditModal(row,row.name)                                        
                                        : navigate(
                                            `/template/${
                                              row.name?.charAt(0) === "#" ||
                                              row.name?.charAt(0) === "/"
                                                ? row.name
                                                    ?.substring(1)
                                                    .replace(
                                                      new RegExp("/", "g"),
                                                      ""
                                                    )
                                                : row.name.replace(
                                                    new RegExp("/", "g"),
                                                    ""
                                                  )
                                            }`,
                                            {
                                              state: {
                                                guid: row.guid,
                                                name: row?.name,
                                                version: row.version,
                                                isPdfTemplate:row.is_pdf_template,
                                                edit: true,
                                              },
                                            }
                                          )
                                    }                                   
                                  />
                                </BootstrapTooltip>
                                <BootstrapTooltip title={<h1>{ptTemplatePermission?'Delete':'You cannot delete this record.'}</h1>}>
                                  <DeleteIcon
                                    className="delete-icon"
                                    id={`delete${index + 1}`}
                                    onClick={() => {
                                      if (ptTemplatePermission) {
                                        handleClickOpenDeleteModal(row.guid, row.name, row.is_pdf_template);
                                      }
                                    }
                                  }
                                  />
                                </BootstrapTooltip></>
                                  :
                                  <>
                                   <BootstrapTooltip
                                  title={<h1 className="font-13">Editing non-PDF template is not supported.</h1>}
                                >
                                  <EditIcon
                                    className="edit-icon"
                                    id={`edit${index + 1}`}
                                    // onClick={()=>handleClickOpenEditModal(row.id,row.name)}
                                    // onClick={() =>
                                    //   row?.is_published === 1
                                    //     ? handleOpenEdits(row.id, row.name)
                                    //     : row?.is_published === 2
                                    //     ? handleOpenEdits(row.id, row.name)
                                    //     : navigate(
                                    //         `/templates/${
                                    //           row.name?.charAt(0) === "#" ||
                                    //           row.name?.charAt(0) === "/"
                                    //             ? row.name
                                    //                 ?.substring(1)
                                    //                 .replace(
                                    //                   new RegExp("/", "g"),
                                    //                   ""
                                    //                 )
                                    //             : row.name.replace(
                                    //                 new RegExp("/", "g"),
                                    //                 ""
                                    //               )
                                    //         }`,
                                    //         {
                                    //           state: {
                                    //             id: row.id,
                                    //             name: row?.name,
                                    //             version: row.version,
                                    //             edit: true,
                                    //           },
                                    //         }
                                    //       )
                                    // }
                                  />
                                </BootstrapTooltip>
                                <BootstrapTooltip title={<h1>Deleting non-PDF template is not supported.</h1>}>
                                  <DeleteIcon 
                                    className="delete-icon"
                                    id={`delete${index + 1}`}
                                    
                                  />
                                </BootstrapTooltip>
                                  </>

                                }
                               
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    {emptyRows > 0 && (
                      <tr style={{ height: 33 * emptyRows }}>
                        <td colSpan={6} />
                      </tr>
                    )}
                  </tbody>
                </Table>
              </TableContainer>
              {/* table pagination component */}
              <TablePagination
                rowsPerPageOptions={[10, 25]}
                component="div"
                count={templateData.length}
                rowsPerPage={rowsPerPage}
                page={emptyRows === rowsPerPage ? ()=>setPage(0) : page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          ) : (
            <Paper
              sx={{ width: "100%", sm: 8, mb: 4, lg: 2, mt: 5 }}
              elevation={0}
              className={"inspection-table-main"}
            >
               <EnhancedTableToolbar
                // handleOpen={handleOpen}
                templateData={templateData}
                excelinspectionData={JSON.parse(JSON.stringify(templateData))}
                excelName="PT Template List"
                // displayFilters={displayFilters}
                // handleChipReset={handleChipReset}
                handleSearchFilter={handleSearchFilter}
                searchIcon={true}
              />
              <Alert severity="error">{message}</Alert>
            </Paper>
          )}
        </Box>
      )}
    </>
  )
}

export default PtTemplateListView