//FILE IMPORTS *************************************************************************

import axios from "axios";
import { useState } from "react";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import Heading from "../../common/cardHeading/Heading"; //reusable component
import { API_CONSTANTS } from "../../config/api-constants";
import Preloader from "../../helpers/Preloader";
import toast from "react-hot-toast";
import { apiCall } from "../../utils/action";
import { Grid, Modal, Paper, Button, Dialog, Typography, DialogTitle, DialogActions, FormControl, Autocomplete, Chip, TextField } from "@mui/material";

//render function **********************************************************************

export const Agreements = (props) => {
  // modal open and close funtionality *************************************************
  // console.log(props, "props");
  const handleOpen = () => setOpen(true); //open the modal******************************
  const [open, setOpen] = useState(false); //flag for modal*****************************
  const handleClose = () => setOpen(false); //close the modal***************************
  const [showLoader, setShowLoader] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);

  //MOCK DATA **************************************************************************

  // const pdfs = [
  //   {
  //     name: "Agreementname0.pdf",
  //     src: "../../abc.com",
  //   },
  //   {
  //     name: "Agreementname1.pdf",
  //     src: "../../abc.com",
  //   },
  //   {
  //     name: "Agreementname2.pdf",
  //     src: "../../abc.com",
  //   },
  //   {
  //     name: "Agreementname3.pdf",
  //     src: "../../abc.com",
  //   },
  // ];
  const pdfs = [
    {
      title: "Agreementname0.pdf",
      id: 1,
    },
    {
      title: "Agreementname1.pdf",
      id: 2,
    },
    {
      title: "Agreementname2.pdf",
      id: 33,
    },
    {
      title: "Agreementname3.pdf",
      id: 4,
    },
  ];

  //warning popup design******************************************************************

  function ChildDialog(props) {
    //waring popup open/close functionality***********************************************

    const handleOpen = () => setOpen(true); //popup open
    const [open, setOpen] = useState(false); //flag for the popup
    const handleClose = () => setOpen(false); //popup close
    const [inputChanged, setInputChanged] = useState(false);

    //return function
    return (
      <>
        <DeleteIcon onClick={handleOpen} sx={{ cursor: "pointer", alignContent: "center" }} />
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{`Are you sure you want to delete "${props.title}"?`}</DialogTitle>
          <DialogActions>
            <DialogActions>
              <Button onClick={handleClose}>YES</Button>
              <Button onClick={handleClose}>NO</Button>
            </DialogActions>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  //API CALL

  const sendtoclient = () => {
    setShowLoader(true);
    const formdata = {
      company_id: sessionStorage.getItem("company_id"),
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      inspection_id: props.inspectionId,
    };
    apiCall(
      (response) => {
        console.log("SENT_TO_CLIENT", response);
        if (response.data.success) {
          toast.success(response.data.message);
          setShowLoader(false);
        } else {
          console.log("else", response.data.message);
          toast.error(response.data.message);
          setShowLoader(false);
        }
      },
      formdata,
      "SEND_TO_CLIENT"
    );
    // axios
    //     .post(`${API_CONSTANTS.SEND_TO_CLIENT}`, formdata)
    //     .then((response) => {
    //         setShowLoader(false);
    //         // Request with success code true
    //         console.log(response, 'SEND TO CLIENT');
    //         toast.success(response.data.message);
    //     })
    //     .catch(function (error) {
    //         if (error.response) {
    //             // Request made and server responded
    //             if (error.response.data.success === false) {
    //             }
    //             console.log(error.response.headers);
    //         } else if (error.request) {
    //             // The request was made but no response was received
    //             console.log('no response', error.request);
    //         } else {
    //             // Something happened in setting up the request that triggered an Error
    //             console.log('Error', error.message);
    //         }
    //     });
  };
  //return function for the agreement screen*********************************************
  return (
    <>
      <Preloader showPreloader={showLoader} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container>
          <Paper
            elevation={0}
            sx={{
              minHeight: "169px",
              border: "1px solid #E7E7E9",
              width: { xs: "100%", md: "65.46%", lg: "65.46%" },
            }}
          >
            <Heading
              CancelledStatus={props.CancelledStatus}
              title={"Agreements"}
              icon={props.status?.status === "Published" || props.status?.status === "Complete" || props.status?.status === "In-Progress" ? "" : <EditIcon className="details__icon" onClick={handleOpen} />}
              // iconDesc={""}
              iconDesc={
                props.status?.status === "Published" || props.status?.status === "Complete" || props.status?.status === "In-Progress" ? (
                  ""
                ) : (
                  <span className="edit_button" onClick={handleOpen}>
                    Edit
                  </span>
                )
              }
            />
            <Grid container spacing={0} lg={8} sx={{ mt: "14px", pl: "25px" }}>
              {props?.agreements.length === 0 ? (
                <p style={{ fontSize: "14px", lineHeight: "20px" }}>No agreements added.</p>
              ) : (
                <>
                  {props?.agreements?.map((value) => {
                    return (
                      <>
                        {props.agreementSigned ? (
                          <Grid item xs={4}>
                            {value?.signed_agreement_name ? (
                              <p onClick={() => window.open(value.signed_agreement_path, "_blank")} className="agreement-link">
                                {/* {value?.signed_agreement_name} */}
                                {value?.signed_agreement_name.length > 15 ? `${value?.signed_agreement_name.substring(15, 0)}... ` : value?.signed_agreement_name}
                              </p>
                            ) : (
                              ""
                            )}
                          </Grid>
                        ) : (
                          <Grid item xs={4}>
                            {value?.unsigned_agreement_name ? (
                              <p onClick={() => window.open(value.unsigned_agreement_path, "_blank")} className="agreement-link">
                                {/* {value?.unsigned_agreement_name}.pdf */}
                                {value?.unsigned_agreement_name.length > 10 ? `${value?.unsigned_agreement_name.substring(15, 0)}... ` : value?.unsigned_agreement_name}.pdf
                              </p>
                            ) : (
                              ""
                            )}
                          </Grid>
                        )}
                      </>
                    );
                  })}
                </>
              )}
            </Grid>
            {/* send to client button ******************************** */}
            {props?.agreements.length === 0 ? (
              ""
            ) : (
              <Grid item xs={12} sx={{ mt: "8px", pl: "25px" }}>
                <Button disabled={props.agreementSigned} onClick={() => sendtoclient()} className="send-to-client" sx={{ height: { xs: 42, md: 34, lg: 34 }, width: { xs: 180, md: 119, lg: 119 }, display: props.CancelledStatus && "none" }}>
                  Send to client
                </Button>
              </Grid>
            )}
          </Paper>
        </Grid>
      </Box>
      {/* edit screen starts from here ********************************************* */}
      <Modal open={open} onClose={handleClose} className={"inspection-filter-modal-main"} sx={{ backgroundColor: "#D8D8D8 !important", opacity: "1.0 !important", overflow: "hidden !important" }}>
        <Box className={"inspection-filter-modal"} sx={{ top: "35% !important", minHeight: "300px !important", maxWidth: "800px" }}>
          <Grid
            sx={{
              p: {
                xs: "20px",
                md: "30px",
                lg: "30px",
              },
            }}
            container
            spacing={2}
          >
            {" "}
            <Grid item xs={8}>
              <Typography
                sx={{
                  height: "30px",
                  width: "350px",
                  fontSize: "22px",
                  letterSpacing: 0,
                  color: "#333333",
                  lineHeight: "30px",
                  fontFamily: "Open Sans",
                }}
              >
                Edit Agreements
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <CloseIcon onClick={handleClose} className={"inspection-filter-modal-close-icon"} />
            </Grid>
            {/* <Grid item xs={6}>
              <Typography
                sx={{
                  height: "30px",
                  width: "350px",
                  color: "#333333",
                  fontSize: "14px",
                  letterSpacing: 0,
                  lineHeight: "30px",
                  fontFamily: "Open Sans",
                }}
              >
                NAME
              </Typography>
              {props.agreements.map((value) => {
                return (
                  <>
                    <Typography
                      sx={{
                        height: "30px",
                        width: "350px",
                        color: "#333333",
                        fontSize: "14px",
                        letterSpacing: 0,
                        color: "#4282E2",
                        cursor: "pointer",
                        lineHeight: "30px",
                        fontFamily: "Open Sans",
                      }}
                    >
                      {value.name}
                    </Typography>
                  </>
                );
              })}
            </Grid> */}
            {/* <Grid item xs={4}>
              <Typography
                sx={{
                  height: "30px",
                  width: "350px",
                  color: "#333333",
                  fontSize: "14px",
                  letterSpacing: 0,
                  lineHeight: "30px",
                  fontFamily: "Open Sans",
                }}
              >
                SOURCE
              </Typography>
              {props.agreements.map((value) => {
                return (
                  <>
                    <Typography
                      sx={{
                        height: "30px",
                        width: "350px",
                        color: "#333333",
                        fontSize: "14px",
                        letterSpacing: 0,
                        lineHeight: "30px",
                        alignItems: "center",
                        fontFamily: "Open Sans",
                      }}
                    >
                      {value.url.substring(0, 13)}
                    </Typography>
                  </>
                );
              })}
            </Grid> */}
            <Grid item xs={12}>
              <FormControl fullWidth margin="none">
                <label
                  style={{
                    fontSize: "12px",
                    fontFamily: "Open Sans",
                    color: "#777777",
                    marginBottom: "7px",
                    lineHeight: "21px",
                  }}
                >
                  ADD AGREEMENTS
                </label>
                <Autocomplete
                  multiple
                  sx={{ p: "0px !important" }}
                  className="forms-control custom-color"
                  id="tags-outlined"
                  size="small"
                  options={pdfs}
                  getOptionLabel={(option) => option.title}
                  // onChange={(e, value) => setSelectedServices(value)}
                  // defaultValue={props.services.map((option)=> option.service.name)}
                  // defaultValue={props.defaultserviceArrary.map((value) => props.serviceArray[value])}
                  defaultValue={[pdfs[0]]}
                  filterSelectedOptions
                  renderTags={(value, getTagProps) => value.map((option, index) => <Chip size="small" deleteIcon={<CloseIcon />} variant="outlined" label={option.title} {...getTagProps({ index })} />)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Agreements"
                      fullWidth
                      // error={errors.selectedServices}
                      // helperText={errors.selectedServices && errors.selectedServices}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          {/* buttons for the edit screen******************************************** */}
          <Grid container spacing={2} className="inspection-filter-modal-buttons" sx={{ pb: 2, mt: "30px !important" }}>
            <Button variant="outlined" className="inspection-filter-modal-buttons-cancel" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" className="inspection-filter-modal-buttons-apply">
              Done
            </Button>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};