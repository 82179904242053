//file import
import moment from "moment";
import { encode } from "js-base64";
import toast from "react-hot-toast";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import config_settings from "../../config/config";
import CloseIcon from "@mui/icons-material/Close";
import Stepper from "../../common/stepper/Stepper";
import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import { colors } from "../../config/app-constants";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ScheduleIcon from "@mui/icons-material/Schedule";
import DateRangeIcon from "@mui/icons-material/DateRange";
import "../../../assets/styles/containers/_inspDetails.scss";
import { apiCall } from "../../pages/settings/Services/action";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Autocomplete, Box, Button, FormControl, Grid, Modal, Typography } from "@mui/material";
import { BootstrapTooltip } from "../../utils/services";

const Status = (props) => {
  let navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [deleteId, setDeleteId] = useState();
  const [loading, setLoading] = useState(false);
  const [rescheduleId, setRescheduleId] = useState();
  const [cancelopen, setCancelOpen] = useState(false);
  const [deleteCancelId, setCancelDeleteId] = useState();
  const [timeError, setTimeError] = useState(false);
  const [rescheduleopen, setRescheduleOpen] = useState(false);
  const [dateopen, setDateOpen] = React.useState(false);
  const [timeopen, setTimeOpen] = React.useState(false);
  const [rescheduledate, setRescheduleDate] = useState(new Date(moment.utc(props.status.startTime).local()));

  let RW_URL;

  const origin = () => {
    let origin = window.location.origin.toLowerCase();
    if (origin.includes("https://")) origin = origin.replace("https://", "");
    else origin = origin.replace("http://", "");
    RW_URL = config_settings[origin]?.RW_URL;
    // return origin;
    console.log(RW_URL, "RW URL");
  };
  origin();

  const cancelinspection = {
    fontSize: "12px",
    display: "flex",
    fontWeight: 600,
    mr: 2,
    "&:hover": {
      backgroundColor: "transparent",
    },
  };
  const deleteinspection = {
    fontSize: "12px",
    display: "flex",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  //handle rescheduled close
  const handlerescheduleClose = () => {
    setRescheduleOpen(false);
    setRescheduleDate(new Date(moment.utc(props.status.startTime).local()));
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  //handleclose
  const handleCancelClose = () => {
    setCancelOpen(false);
  };

  //handle open
  const handleClickOpenAlert = (id) => {
    setDeleteId(id);
    setOpen(true);
  };
  // handle cancel
  const handleCancelClickOpenAlert = (id) => {
    setCancelDeleteId(id);
    setCancelOpen(true);
  };
  // handle reSchedule
  const handleRescheduleAlert = (id) => {
    setRescheduleId(id);
    setRescheduleOpen(true);
  };
  useEffect(() => {
    setRescheduleDate(new Date(moment.utc(props.status.startTime).local()));
  }, [props.status.startTime]);
  // cancel inspection
  const cancelInspection = (orderId) => {
    setLoading(true);
    const formdata = {
      order_id: orderId,
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      company_id: sessionStorage.getItem("company_id"),
    };
    apiCall(
      (response) => {
        if (response.data.success) {
          setLoading(false);
          setCancelOpen(false);
          props.setRefresh((prev) => !prev);
          toast.success(response.data.message);
        } else {
          setLoading(false);
          console.log("else", response.data.message);
        }
      },
      formdata,
      "CANCEL_INSPECTION"
    );
  };

  const validate = (orderId) => {
    if (rescheduledate < new Date()) {
      setTimeError(true);
    } else {
      setTimeError(false);
      updateInspection(orderId);
    }
  };
  // reschedule
  const updateInspection = (orderId) => {
    setLoading(true);
    const formdata = {
      reschedule: 1,
      inspection_id: orderId,
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      company_id: sessionStorage.getItem("company_id"),
      local_starttime: moment(rescheduledate).format("YYYY-MM-DD HH:mm:ss"),
      start_time: moment.utc(new Date(rescheduledate).toUTCString()).format("YYYY-MM-DD HH:mm:ss"),
      inspection_datetime: moment.utc(new Date(rescheduledate).toUTCString()).format("YYYY-MM-DD HH:mm:ss"),
    };
    apiCall(
      (response) => {
        console.log("UPDATE_INSPECTION_DETAILS", response);
        if (response.data.success) {
          setLoading(false);
          setRescheduleOpen(false);
          props.setRefresh(!props.refresh);
          toast.success(response.data.message);
        } else {
          setLoading(false);
          toast.error(response.data.message);
          console.log("else", response.data.message);
        }
      },
      formdata,
      "UPDATE_INSPECTION_DETAILS"
    );
  };

  // delete inspection
  const deleteInspection = (orderId) => {
    setLoading(true);
    const formdata = {
      order_id: orderId,
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      company_id: sessionStorage.getItem("company_id"),
    };
    apiCall(
      (response) => {
        if (response.data.success) {
          setLoading(false);
          toast.success(response.data.message);
          props.setRefresh((prev) => !prev);
          navigate("/inspections");
        } else {
          setLoading(false);
          console.log("else", response.data.message);
        }
      },
      formdata,
      "DELETE_INSPECTION"
    );
  };
  const changeStatus = () => {
    setLoading(true);
    const formdata = {
      guid: props.inspGuid,
      user_id: sessionStorage.getItem("user_id"),
      role_id: sessionStorage.getItem("role_id"),
      company_id: sessionStorage.getItem("company_id"),
      status_date: moment.utc(new Date().toUTCString()).format("YYYY-MM-DD"),
      status: "In-Progress",
    };
    apiCall(
      (response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          props.setRefresh(!props.refresh);
        } else {
          toast.error(response.data.message);
        }
      },
      formdata,
      "CHANGE_STATUS"
    );
  };

  //render
  return (
    <div className="status">
      <div className="status__layout">
        <Typography className="heading">Status</Typography>
        <Chip sx={{ backgroundColor: colors[`${props.status.status.toUpperCase()}_BG`], color: colors[`${props.status.status.toUpperCase()}`] }} label={<h3 className="chip-label">{props.status.status}</h3>} />
      </div>
      {/* paid and signed  */}
      <div className="action">
        <Typography className="heading">Action items</Typography>
      </div>
      <div className="items">
        {props.companyPaymentStatus?.is_payment_enable === 2 ? (
          <div style={{ display: "flex" }}>
            {props.status.paid === 1 ? (
              <>
                <CheckCircleIcon className="check-icon"></CheckCircleIcon> <Typography className="description">Paid</Typography>
              </>
            ) : props.status.paid === 2 ? (
              <>
                <CancelIcon className="cancel-icon"></CancelIcon> <Typography className="description">Unpaid (Waived)</Typography>
              </>
            ) : (
              <>
                <CancelIcon className="cancel-icon"></CancelIcon> <Typography className="description">Unpaid</Typography>
              </>
            )}
          </div>
        ) : (
          ""
          // <>
          //   <CheckCircleIcon className="cancel-icon" sx={{ color: "grey" }}></CheckCircleIcon> <Typography className="description">Payment not enabled</Typography>
          // </>
        )}

        <div style={{ display: "flex", paddingLeft: props.companyPaymentStatus?.is_payment_enable !== 2 ? "0px" : "23px" }}>
          {props.companyPaymentStatus?.is_agreement_enable === 1 ?props.statusValue.agreementLength !==0? (
            props.status.agreementsigned === 1 ? (
              <>
                <CheckCircleIcon className="check-icon"></CheckCircleIcon>
                <Typography className="description">Agreement signed</Typography>
              </>
            ) : props.status.agreementsigned === 2 ? (
              <>
                <CancelIcon className="cancel-icon"></CancelIcon>
                <Typography className="description">Agreement not signed (Waived)</Typography>
              </>
            ) : (
              <>
                <CancelIcon className="cancel-icon"></CancelIcon>
                <Typography className="description">Agreement not signed</Typography>
              </>
            )
          ):"" : (
            ""
            // <>
            //   <CancelIcon className="cancel-icon" sx={{ color: "grey" }}></CancelIcon>
            //   <Typography className="description">Agreement not enabled</Typography>
            // </>
          )}
        </div>
      </div>
      <div className="action">
        <Typography className="heading">Service date and time</Typography>
        <div className="details__text" style={{ display: (props.CancelledStatus || props.PublishedStatus || props.CompleteStatus || props.status.status === "In-Progress") && "none" }} onClick={() => handleRescheduleAlert(props.orderId)}>
          Reschedule
        </div>
      </div>
      <div style={{ marginBottom: "-25px", marginTop: "-5px" }} className="date">
        <Grid container>
          <Grid item xs={6} md={6} lg={6} sx={{ mb: 5, wordWrap: "break-word !important" }}>
            <div style={{ display: "flex" }}>
              <DateRangeIcon className="calender-icon"></DateRangeIcon>
              <div style={{ marginLeft: "0px", marginTop: "-5px", whiteSpace: "normal !important", paddingRight: "15px" }} className="details__title">
                {`${moment(props.status.startTime).format("dddd")}`}
                <br />
                {`${moment(props.status.startTime).format("ll")}`}
              </div>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <div style={{ display: "flex" }}>
              <ScheduleIcon className="calender-icon"></ScheduleIcon>
              <div style={{ marginLeft: "0px", whiteSpace: "pre" }} className="details__title">
                {moment(props.status.startTime).format("LT")}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* Timeline */}
      {props.status.status === "Scheduled" || props.status.status === "Draft" ? (
        <div style={{ marginTop: "10px" }}>
          <Typography className="heading">Timeline</Typography>
          <div className="timeline">
            <section class="bubbles">
              <div class="bubble small black arrow bl">
                <div class="content">{props.status.status === "Draft" ? "Draft" : "InspectionScheduled"}</div>
              </div>
            </section>
            <Stepper status={props.status.status} />
            <div style={{ paddingTop: "21.33px" }}>
              <Typography className="description">You have {props.status.status === "Draft" ? "draft" : "scheduled"} the inspection and an email confirmation has been sent to your client.</Typography>
            </div>
          </div>
        </div>
      ) : props.status.status === "In-Progress" ? (
        <div style={{ marginTop: "10px" }}>
          <Typography className="heading">Timeline</Typography>
          <div className="timeline">
            <section class="bubbles_progress">
              <div class="bubble_progress small black arrow bl">
                <div class="content">Inspection In-progress</div>
              </div>
            </section>
            <Stepper status={props.status.status} />
            <div style={{ paddingTop: "21.33px" }}>
              <Typography className="description">Inspection is in-progress.</Typography>
            </div>
          </div>
        </div>
      ) : props.status.status === "Complete" || props.status.status === "Cancelled" ? (
        <div style={{ marginTop: "10px" }}>
          <Typography className="heading">Timeline</Typography>
          <div className="timeline">
            <section class="bubbles_complete">
              <div class="bubble_complete small black arrow bl">
                <div class="content">Inspection {props.status.status === "Complete" ? "complete" : "cancelled"}</div>
              </div>
            </section>
            <Stepper status={props.status.status} />
            <div style={{ paddingTop: "21.33px" }}>
              <Typography className="description">Inspection is {props.status.status === "Complete" ? "complete" : "cancelled"} and an email confirmation has been sent to your client.</Typography>
            </div>
          </div>
        </div>
      ) : props.status.status === "Published" ? (
        <div style={{ marginTop: "10px" }}>
          <Typography className="heading">Timeline</Typography>
          <div className="timeline">
            <section className="bubbles_published" style={{ float: "right" }}>
              <div class="bubble_published small black arrow bl">
                <div class="content">Inspection published</div>
              </div>
            </section>
            <Stepper status={props.status.status} />
            <div style={{ paddingTop: "21.33px" }}>
              <Typography className="description">You have published the inspection and an email confirmation has been sent to your client.</Typography>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* Delete Inspection modal */}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "480px !important", height: "231px !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon id="inspectiondetails-deleteinspclose" sx={{ float: "right", cursor: "pointer" }} onClick={handleClose} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography sx={{ color: "#333333", fontFamily: "open sans", fontSize: "22px", mt: "15px", letterSpacing: 0 }}>Delete inspection?</Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography sx={{ color: "#333333", fontFamily: "open sans", fontSize: "14px", mt: "15px", letterSpacing: 0 }}>This action cannot be undone.</Typography>
            </Grid>
            <Grid container sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <Button variant="outlined" id="inspectiondetails-canceldeteleinsp" className="inspection-filter-modal-buttons-cancel" sx={{ width: "119px", ":hover": { backgroundColor: "transparent !important" } }} onClick={handleClose}>
                Cancel
              </Button>
              <LoadingButton loading={loading} id="inspectiondetails-confirmdeteleinsp" variant="contained" className="inspection-filter-modal-buttons-apply" sx={{ width: "119px", ml: 3 }} onClick={() => deleteInspection(deleteId)}>
                Apply
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* Reschedule Modal */}
      <Modal open={rescheduleopen}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container className={"schedule-inspection-container"}>
            <Grid item xs={12} md={12} className="modal-container">
              <Typography align="right" className="close-icon">
                <CloseIcon id="reschedule-closebutton" onClick={handlerescheduleClose} />
              </Typography>
              <Typography sx={{ fontSize: "22px", padding: "11px" }} className={"global-font"}>
                Reschedule an Inspection
              </Typography>
              <Typography sx={{ fontSize: "18px", padding: "15px" }} className={"global-font"}>
                Basic Details
              </Typography>
              <Grid container className="input-controll">
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" className="forms-control">
                    <label className="input-form-label" htmlFor="Inspection ID">
                      Inspection ID
                    </label>
                    <TextField className="input-textfield disabled-input" size="small" variant="outlined" value={"#" + props.orderId} type="text" inputProps={{ readOnly: true }} />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <label className="input-form-label pl-15" htmlFor="Inspectors" id="inspector-field" style={{ display: "block" }}>
                    Inspectors
                  </label>
                  <Autocomplete multiple disabled size="small" id="reschedule-inspectors" options={props.reschInspector} className="forms-control custom-color" getOptionLabel={(option) => `${option.name}`} value={props.reschInspector.map((option) => option.name)} renderInput={(params) => <TextField {...params} />} renderTags={(value, getTagProps) => props.reschInspector.map((option, index) => <Chip variant="outlined" deleteIcon={<CloseIcon />} label={option.name} {...getTagProps({ index })} />)} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" className="forms-control">
                    <label className="input-form-label" htmlFor="date" id="demo-simple-select-label">
                      Date*
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        PopperProps={{
                          placement: "top-end",
                        }}
                        className="digit-size"
                        open={dateopen}
                        id="reschedule-date"
                        onOpen={() => setDateOpen(true)}
                        onClose={() => setDateOpen(false)}
                        disablePast
                        value={rescheduledate}
                        format="DD-MM-YYYY"
                        onChange={(newValue) => setRescheduleDate(newValue)}
                        renderInput={(params) => (
                          <TextField
                            onKeyDown={onKeyDown}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onClick={(e) => setDateOpen(true)}
                            {...params}
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl variant="standard" className="forms-control">
                    <label className="input-form-label" htmlFor="time" id="demo-simple-select-label">
                      Time*
                    </label>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        open={timeopen}
                        onOpen={() => setTimeOpen(true)}
                        onClose={() => setTimeOpen(false)}
                        id="reschedule-time"
                        PopperProps={{
                          placement: "top-end",
                        }}
                        shouldDisableTime={(timeValue, clockType) => {
                          // if (clockType === "minutes") return timeValue <= new Date().getMinutes();
                          if (clockType === "hours" && rescheduledate < new Date()) return timeValue <= new Date().getHours();
                        }}
                        value={rescheduledate}
                        onChange={(newValue) => setRescheduleDate(newValue)}
                        renderInput={(params) => (
                          <TextField
                            onClick={(e) => setTimeOpen(true)}
                            onKeyDown={onKeyDown}
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "hh:mm am/pm",
                            }}
                            size="small"
                          />
                        )}
                        error={Boolean(timeError)}
                      />
                    </LocalizationProvider>
                    {timeError && <span id="rescheduleError">Time already past</span>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ margin: "14px", display: "flex", justifyContent: "flex-end" }} className="cancel-btn-wrapper">
                  <Button id="reschedule-cancel" variant="text" className="cancel-btn" onClick={handlerescheduleClose}>
                    <Typography>Cancel</Typography>
                  </Button>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    className="default-btn"
                    type="submit"
                    id="reschedule-confirm"
                    // onClick={() => updateInspection(rescheduleId)}
                    onClick={() => validate(rescheduleId)}
                  >
                    Reschedule
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {/* cancel inspection model */}
      <Modal open={cancelopen} onClose={handleCancelClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
        <Box className={"inspection-filter-modal"} sx={{ maxWidth: "480px !important", height: "231px !important" }}>
          <Grid container>
            <Grid item xs={12}>
              <CloseIcon id="inspectiondetails-closecancelinsp" sx={{ float: "right", cursor: "pointer" }} onClick={handleCancelClose} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography sx={{ color: "#333333", fontFamily: "open sans", fontSize: "22px", mt: "15px", letterSpacing: 0 }}>Cancel inspection?</Typography>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Typography sx={{ color: "#333333", fontFamily: "open sans", fontSize: "14px", mt: "15px", letterSpacing: 0 }}>This action cannot be undone.</Typography>
            </Grid>
            <Grid container sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
              <Button variant="outlined" id="inspectiondetails-cancelinsp" className="inspection-filter-modal-buttons-cancel" sx={{ width: "119px", ":hover": { backgroundColor: "transparent !important" } }} onClick={handleCancelClose}>
                Cancel
              </Button>
              <LoadingButton loading={loading} id="inspectiondetails-confirmcancelinsp" variant="contained" className="inspection-filter-modal-buttons-apply" sx={{ width: "119px", ml: 3, ":hover": { backgroundColor: "#1E8970 !important" } }} onClick={() => cancelInspection(deleteCancelId)}>
                Apply
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      {console.log("props33",props.status.status)}
      {RW_URL && props?.status?.status==="Scheduled" ? (
        <Button
          endIcon={<OpenInNewIcon />}
          className="login_button"
          sx={{ height: "47px", width: "100%", fontSize: 13 }}
          size="small"
          variant="contained"
          onClick={() => {
            window.open(`${RW_URL}?q=${encode(`guid=${props.inspGuid}&company_key=${sessionStorage.getItem("company_id")}&associate_company=Palmtech&secret_key=${props.srcKey}&user_id=${sessionStorage.getItem("user_id")}`)}`, "_blank");
            changeStatus();
          }}
        >
          Open report writer
        </Button>
      ) : (
        <Button
          endIcon={<OpenInNewIcon />}
          className="login_button"
          sx={{ height: "47px", width: "100%", fontSize: 13 }}
          size="small"
          variant="contained"
          onClick={() => {
            window.open(`${RW_URL}?q=${encode(`guid=${props.inspGuid}&company_key=${sessionStorage.getItem("company_id")}&associate_company=Palmtech&secret_key=${props.srcKey}&user_id=${sessionStorage.getItem("user_id")}`)}`, "_blank");
            // changeStatus();
          }}
        >
          Open report writer
        </Button>
      )}
      {/* cancel & delete inspection button  */}
      <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
        {props.status.status !== "Cancelled" && props.status.status !== "Complete" && props.status.status !== "Published" ? (
          <Button sx={cancelinspection} startIcon={<RemoveCircleOutlineIcon sx={{ color: "#4282E2", fontSize: "17px" }} />} onClick={() => handleCancelClickOpenAlert(props.orderId)}>
            cancel inspection
          </Button>
        ) : (
          ""
        )}
        {props.in_trial?.is_trial_company === 1 ? (
          <BootstrapTooltip title={<h1 className="font-13">You cannot delete this record</h1>}>
            <Button sx={deleteinspection} startIcon={<DeleteIcon sx={{ color: "#4282E2", fontSize: "17px" }} />}>
              Delete inspection
            </Button>
          </BootstrapTooltip>
        ) : (
          <Button sx={deleteinspection} onClick={() => handleClickOpenAlert(props.orderId)} startIcon={<DeleteIcon sx={{ color: "#4282E2", fontSize: "17px" }} />}>
            Delete inspection
          </Button>
        )}
      </Box>
    </div>
  );
};

export default Status;