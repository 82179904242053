import { useState, useContext } from "react";
// dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
// PALM 10151
// import { Userpilot } from "userpilot";
import DialogContentText from "@mui/material/DialogContentText";
import { Button } from "@mui/material";
import { apiCall } from "../../utils/action";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import { LoadingButton } from "@mui/lab";

const UseInspectionAgreementModal = ({ showInspAgrmntModal, setShowInspAgrmntModal, setShowIncludedAgrmntModal, isAgreementPageModal = false, isSettingPageModal = false }) => {
  const { setReload, companyInfo } = useContext(AppContext);
  const uuId = sessionStorage.getItem("user_id");
  const companyId = sessionStorage.getItem("company_id");
  const roleId = sessionStorage.getItem("role_id");
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);

  const handleCloseInspAgremntModal = () => {
    setShowInspAgrmntModal(false);
  };

  const handleDisableAgremntNModal = () => {
    if (companyInfo.agreement?.agreement_status === 1) {
      setShowLoader(true);
      toast.dismiss();
      const formdata = { company_id: companyId, user_id: uuId, role_id: roleId, agreement_status: 0 };
      apiCall(
        (response) => {
          const { message, success } = response.data;
          if (success) {
            setShowLoader(false);
            toast.success(message);
            setShowInspAgrmntModal(false);
            setReload((prev) => !prev);
            if (!isAgreementPageModal && !isSettingPageModal) navigate("/schedule-inspection");
            else if (isSettingPageModal) navigate("/settings/agreements");
          } else {
            toast.error(message);
            setShowLoader(false);
          }
        },
        formdata,
        "UPDATE_AGREEMENT_INFO"
      );
    } else {
      if (!isAgreementPageModal && !isSettingPageModal) navigate("/schedule-inspection");
      else if (isSettingPageModal) {
        navigate("/settings/agreements");
        setShowInspAgrmntModal(false);
      } else setShowInspAgrmntModal(false);
    }
  };
  const useAgreementHandle = () => {
    // PALM 10151
    // Userpilot.track("Add agreement");
    setShowInspAgrmntModal(false);
    setShowIncludedAgrmntModal(true);
  };
  return (
    <>
      <Dialog open={showInspAgrmntModal} onClose={handleCloseInspAgremntModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" className="agreement-terms-modal">
        <DialogTitle id="alert-dialog-title">
          {"Would you like to use inspection agreements?"}
          <CloseIcon className="close-icon" onClick={handleCloseInspAgremntModal} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="agreement-terms-text" id="alert-dialog-description">
            <p> Before creating an inspection, you need to set your agreement settings. </p>
            <p>If you turn agreements on, your client will be sent your default agreement (customizable from the agreements page) to review and sign as soon as you schedule an inspection.</p>
            <p>If you prefer to handle agreements another way, you can disable agreements.</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={showLoader} className="default-btn-agreement-terms" variant="contained" onClick={handleDisableAgremntNModal}>
            Disable agreements
          </LoadingButton>
          <Button className="default-btn-agreement-terms ml-17" variant="contained" color="error" onClick={useAgreementHandle}>
            Use agreements
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default UseInspectionAgreementModal;
