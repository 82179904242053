import { FormControl, Grid, Menu, MenuItem, TextField,Select   } from '@mui/material'
import { Paper, Typography, Collapse, Divider, TextareaAutosize } from "@material-ui/core";
import { useState,useEffect } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import InputField from './Elements/InputField';
import MediaUpload from './Elements/MediaUpload';
import ExpandingCheckbox from './Elements/ExpandingCheckbox';
import InformationalText from './Elements/InformationalText';
import GroupElement from './Elements/GroupElement';
import CheckBoxElement from './Elements/CheckBoxElement';
import Signature from './Elements/Signature';
import DividerElement from './Elements/DividerElement';
import RadioElement from './Elements/RadioElement';
import AddElementButton from './Elements/AddElementButton';
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import Fade from '@mui/material/Fade';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import TableElement from './Elements/TableElement';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { BootstrapTooltip} from "../../../utils/services";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

const PdfFormFill = ({prompt,elements,catIndex,uuid,templateState,setTemplateState,updatePtTempateData,information_text,type}) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpanded = () => setExpanded(!expanded)
  const [anchorEl, setAnchorEl] = useState(null);
  const [categoryName,setCategoryName] = useState('');
  const [catType,setCatType] = useState(0);
  const [editedCatName,setEditedCatName]= useState(false)
  const [editedExplanatoryText,setEditedExplanatoryText]= useState(false)
  const [explanatoryText,setExplanatoryText] = useState('');
  const [reorderedEl,setReorderedEL] = useState(false);
  const [dragDisable,setDragDisable] = useState(false);
  const open = Boolean(anchorEl);
  let inactivityTimer;
  const handleClickMore = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation()
    if (open) {
      setAnchorEl(null);
    }
  };
  // Create a debounced function to update the state
  const debouncedUpdateData = debounce((prevData) => {   
    setTemplateState(prev=>({...prev,templateDataCopy:prevData}))
  }, 2000); // Delay state update by 2 seconds
  const handleCategoryNameChange = useCallback((e) => {
    setEditedCatName(true)
    setCategoryName(e.target.value);
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===uuid)
    if(catIdx!== -1){
       prevData.data.template.pdf_categories[catIdx].prompt = e.target.value
    }
    debouncedUpdateData(prevData);
  }, []);
  const handleExplanatoryTextChange = useCallback((e) => {
    setEditedExplanatoryText(true)
    setExplanatoryText(e.target.value);
    let prevData = JSON.parse(JSON.stringify(templateState?.templateDataCopy));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===uuid)
    if(catIdx!== -1){
       prevData.data.template.pdf_categories[catIdx].information_text = e.target.value
    }
    debouncedUpdateData(prevData);
  }, []);

  const clickAwaySaveptData=()=>{
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===uuid)
    if(catIdx!== -1){
        prevData.data.template.pdf_categories[catIdx].prompt = categoryName;
        prevData.data.template.pdf_categories[catIdx].information_text = explanatoryText;
        let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
        let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
        newUpdatedSpecificData.data.template.pdf_categories = editedCategory
        setTemplateState(prev=>({...prev,templateData:prevData}))
        updatePtTempateData(newUpdatedSpecificData?.data?.template)       
        setEditedCatName(false)
        setEditedExplanatoryText(false)
    }    
  }

  const handleCategoryType=(e)=>{
    setCatType(e.target.value)
    setTemplateState(prev=>({...prev,updatingTemplate:true}))
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===uuid)
    if(catIdx!== -1){
      prevData.data.template.pdf_categories[catIdx].type = e.target.value
      let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
      let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
      newUpdatedSpecificData.data.template.pdf_categories = editedCategory
      setTemplateState(prev=>({...prev,templateData:prevData}))
      updatePtTempateData(newUpdatedSpecificData?.data?.template)       
    }
  }

  const deleteCategory=(e,uuid)=>{
    handleClose(e)
    let prevData = JSON.parse(JSON.stringify(templateState?.templateDataCopy));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===uuid)
    if(catIdx!== -1){
       prevData.data.template.pdf_categories[catIdx].is_deleted = true;
       let editedCategory = [prevData.data.template.pdf_categories[catIdx]];
       let newUpdatedSpecificData = JSON.parse(JSON.stringify(prevData));
       newUpdatedSpecificData.data.template.pdf_categories = editedCategory
       setTemplateState(prev=>({...prev,templateData:prevData}))
       updatePtTempateData(newUpdatedSpecificData?.data?.template)     
    }    
  }

  const onDragEndHandle = (result) => {
    if (!result.destination) {
      return;
    }    
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
  
    if (sourceIndex === destinationIndex) {
      return;
    }  
    const newState = { ...templateState };
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let catIdx = prevData?.data?.template?.pdf_categories.findIndex(el=>el.uuid===uuid)
    if(catIdx!== -1){
      const elements = [...newState.templateData.data.template.pdf_categories[catIdx].elements];
      const [movedCategory] = elements.splice(sourceIndex, 1);
      elements.splice(destinationIndex, 0, movedCategory);
      elements.forEach((element, index) => {
        element.order = index + 1;
      });
      newState.templateData.data.template.pdf_categories[catIdx].elements = elements;
      setTemplateState(newState);
      setReorderedEL(true);
    }    
  };
  const handleMouseOutEl = () => {
    reorderedEl && updatePtTempateData(templateState?.templateData?.data?.template);
    setReorderedEL(false)
  };

  useEffect(() => {
    setCategoryName(prompt)
    setExplanatoryText(information_text)
    setCatType(type)
  }, [])
  
  return (
    <Draggable draggableId={String(uuid)} key={uuid} index={catIndex} isDragDisabled={templateState.roleId !== 2 && templateState.roleId !== 3}>
    {(provided) => (
    <Paper  ref={provided.innerRef} {...provided.draggableProps} className="pt_template__accordion">     
    <Grid container >    
    <Grid {...provided.dragHandleProps}  container className={`pt_template__accordion_content ${expanded?'pt_border_bottom':''}`} onClick={handleExpanded}>
      <div className='pt_ta_name'>
        <DragIndicatorIcon className={`${catType === 0 || expanded?'pt_drag_disabled':''} `}/>
        <Grid item xs={11} onClick={handleExpanded} >
          <Typography className={`${catType === 0?'pt_ta_cat_title pt_cat_disabled':catType === 3?'pt_ta_cat_title pt_cat_italic':'pt_ta_cat_title'} `}>
            {/* {(prompt !== null && prompt !== ""?prompt:"Untitled")} */}
            {prompt} <span className={catType === 2?"pt_cat_type_text_reduced":catType === 3?"pt_cat_type_text_info":''}>{catType === 2?"Reduced":catType === 3?"Informational":''}</span>
            </Typography>
        </Grid>
      </div>      
      <Grid item xs={1} className='pt_ta_actions'>       
        <ExpandMoreIcon className={`pt_ta_dropdown ${expanded?'accord_active':'accord_inactive'} `} onClick={handleExpanded}/>
        <MoreHorizIcon onClick={templateState.roleId !== 2 && templateState.roleId !== 3? null : handleClickMore} className="pt_pointer"/>
        <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              className="pt_more_menu"
              >
                <span><CloseIcon onClick={handleClose}/></span>
              <MenuItem  
              onClick={(e)=>deleteCategory(e,uuid)}
              ><DeleteIcon/>Delete</MenuItem>
            </Menu>
      </Grid>
      
      </Grid>
      <Grid item xs={12} className='pt_ta_active_border' onMouseLeave={handleMouseOutEl}>
        <Collapse in={expanded}>
          <div className='pt_ta_content_padding'>  
         <Grid container>
          <Grid item xs={12} sm={8}>
          <FormControl variant="standard" className="pt_w_100" style={{marginBottom:'12px'}}>
            <label className="pt_form_label" htmlFor={`category_name_${catIndex+1}`} id={`category_name_${catIndex+1}`}>
              CATEGORY NAME
            </label>
            <ClickAwayListener  mouseEvent="onMouseDown"
              touchEvent="onTouchStart" onClickAway={() => editedCatName && clickAwaySaveptData()}>
            <TextField
              fullWidth
              type="text"
              id={`category_name_${catIndex+1}`}
              size="small"
              name="pt_cat_name"
              variant="outlined"
              disabled={templateState.roleId !== 2 && templateState.roleId !== 3 }
              value={categoryName}
              onChange={handleCategoryNameChange}
              placeholder="Category name"
              className="input-textfield"
            />
             </ClickAwayListener>
            </FormControl>
          </Grid>   
          <Grid item xs={12} sm={4}>
          <FormControl fullWidth className='pt_pl_10'>
          <label className="pt_form_label_cat_type">
              CATEGORY TYPE  <BootstrapTooltip 
              title={<h1>Select the functionality of this category and how it appears in the app.
                <br/>
            <ul style={{ listStyleType: 'disc', marginLeft: '1.5em' }}>
            <li>Default: Allows all input types</li>
            <li>Reduced: Can be used for inspection details or other sections with basic input fields</li>
            <li>Informational: For displaying large amounts of text with no inputs</li>
            </ul>    
             </h1>}>
                    <InfoOutlinedIcon className="template_info_icon_cat_type" />
                  </BootstrapTooltip>
            </label>
            <Select labelId="input_type" id="cat_type" disabled={templateState.roleId !== 2 && templateState.roleId !== 3 } size="small" value={catType}
                onChange={(e)=>handleCategoryType(e)}
                >
                <MenuItem disabled value={0}>Select</MenuItem>
                <MenuItem  value={1}> Default</MenuItem>
                <MenuItem  value={2}>Reduced</MenuItem>
                <MenuItem  value={3}>Informational</MenuItem>
            </Select> 
            </FormControl>
          </Grid>  
          </Grid>

            {catType === 1 && <FormControl variant="standard" className="pt_w_100">
              <label className="input-form-label" htmlFor={`explanatory_text_${catIndex+1}`} id={`explanatory_text_${catIndex+1}`}>
              Explanatory text
              </label>
              <ClickAwayListener  mouseEvent="onMouseDown"
              touchEvent="onTouchStart" onClickAway={() => editedExplanatoryText && clickAwaySaveptData()}>
              <TextareaAutosize
                className="textArea"
                id={`explanatory_text_${catIndex+1}`}
                aria-label="minimum height"
                minRows={3}
                placeholder="Add explanatory text here"
                style={{ width: "100%", resize: "none",padding:"15px" }}
                disabled={templateState.roleId !== 2 && templateState.roleId !== 3 }
                value={explanatoryText}
                onChange={handleExplanatoryTextChange}
                InputProps={{
                  inputComponent: TextareaAutosize,
                }}
              />
              </ClickAwayListener>
            </FormControl> }

            {catType !== 0 && <Divider style={{marginBottom:'24px',marginTop:'24px'}}/>}
            <DragDropContext onDragEnd={(result) => {onDragEndHandle(result)}} type="ELEMENT">
              <Droppable droppableId="elementDropArea" type="ELEMENT">
              {(provided) => (
                <div className='pt_element_droparea' ref={provided.innerRef}>
                {elements?.map((element,index)=>!element.is_deleted?( 
                   <Draggable draggableId={String(element.uuid)} key={element.uuid} index={index} 
                   isDragDisabled={(templateState.roleId !== 2 && templateState.roleId !== 3) || dragDisable}
                  //  isDragDisabled={true}
                   >  
                   {(provided,snapshot) => (
                   <div {...provided.dragHandleProps}  ref={provided.innerRef} {...provided.draggableProps}>
                  {element.type === "Layout/ExpandingCheckBox"?
                  // <div key={index}>
                    <ExpandingCheckbox {...element} elemParentType="expandingCheckBox" parentType="category"
                    catuuid={uuid} templateState={templateState} setTemplateState={setTemplateState} 
                    updatePtTempateData={updatePtTempateData} isDragging={snapshot.isDragging} catType={catType} setDragDisable={setDragDisable}/>
                  // </div>              
                  :
                  element.type.includes('Input')?
                  <div key={index}>
                    <InputField {...element} setDragDisable={setDragDisable} parentType="category" catuuid={uuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} isDragging={snapshot.isDragging}/>
                  </div>             
                  :element.type.includes('InformationText')?
                  <div key={index}>
                    <InformationalText {...element} setDragDisable={setDragDisable} parentType="category" catuuid={uuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} isDragging={snapshot.isDragging}/>
                  </div>             
                  :element.type ==="Layout/Group"?
                    <GroupElement {...element} setDragDisable={setDragDisable} elemParentType="group" parentType="category"
                    catuuid={uuid} templateState={templateState} setTemplateState={setTemplateState} 
                    updatePtTempateData={updatePtTempateData} isDragging={snapshot.isDragging} catType={catType}/>
                  :element.type === "CheckBox"?
                  <div key={index}>
                    <CheckBoxElement {...element} setDragDisable={setDragDisable} parentType="category" catuuid={uuid} templateState={templateState} setTemplateState={setTemplateState} 
                    updatePtTempateData={updatePtTempateData} isDragging={snapshot.isDragging}/>
                  </div>              
                  :element.type.includes("Signature")?
                  <Signature {...element} setDragDisable={setDragDisable} parentType="category" catuuid={uuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} isDragging={snapshot.isDragging}/>
                  :element.type === "MediaUploader"?
                  <MediaUpload {...element} setDragDisable={setDragDisable} parentType="category" catuuid={uuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} isDragging={snapshot.isDragging}/>
                  :element.type === "Divider"?
                  <DividerElement {...element} setDragDisable={setDragDisable} parentType="category" catuuid={uuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} isDragging={snapshot.isDragging}/>
                  :element.type === "Radio"?
                  <RadioElement {...element} setDragDisable={setDragDisable} parentType="category" catuuid={uuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} isDragging={snapshot.isDragging}/>             
                  :element.type === "Layout/Table"?
                  <TableElement {...element} setDragDisable={setDragDisable} parentType="category" catuuid={uuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} isDragging={snapshot.isDragging}/>             
                  :
                  ''}
                  </div>   )}
                  </Draggable>
                ):'')}
                 {provided.placeholder} 
                </div>
                )}
              </Droppable>    
            </DragDropContext> 
            {catType != '0' &&<AddElementButton elemParentType="category" parentType="category" 
          catuuid={uuid} templateState={templateState} setTemplateState={setTemplateState} updatePtTempateData={updatePtTempateData} catType={catType} setActive={undefined}/>  }
          </div>
        </Collapse>
      </Grid>
    </Grid>
      
  </Paper> )}
       </Draggable>
  )
}

export default PdfFormFill