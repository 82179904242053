import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Banner2 from "../../assets/images/welcome/second.png";
import Banner1 from "../../assets/images/welcome/house.png";
import Banner3 from "../../assets/images/welcome/third.png";
import Banner4 from "../../assets/images/welcome/forth.png";
import "../../assets/styles/containers/_Inspections.scss";
import Img from "../../assets/images/property-img-1.jpg";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import { colors } from "../config/app-constants";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";

export const WelcomeBanners = [
  {
    title: "Welcome to Palmtech",
    description: "Palmtech is the complete home inspection software that’s quick to set up and easy to use. Spend less time writing reports and  more time booking inspections. Get started now in a few quick steps.",
    banner: Banner1,
  },
  {
    title: "Get up and running on your own",
    description: "There’s no complex or time-consuming setup and no computer expertise is required. Need training or additional onboarding assistance? No problem! Just get in touch and we’ll make sure you’re set up and ready to go in no time.",
    banner: Banner2,
  },
  {
    title: "More than just a report writer",
    description: "With Palmtech, you can go beyond writing and publishing interactive reports. We also provide a comprehensive and seamless set of features for all stages of the inspection process: manage your calendar, automate communication with your customers, collect payments, manage agreements, and more. ",
    banner: Banner3,
  },
  {
    title: "Grow your business",
    description: "Delight agents and homebuyers with polished reports that lead to more referrals and repeat business. Plus, you’ll have the tools and information you need to gain valuable insights and make more informed business decisions. ",
    banner: Banner4,
  },
];

export const tableHead = [
  {
    id: "Image",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "Address",
    numeric: false,
    disablePadding: true,
    label: "ADDRESS",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
  },
  {
    id: "Date/Time",
    numeric: true,
    disablePadding: false,
    label: "DATE/TIME",
  },
  {
    id: "Client",
    numeric: true,
    disablePadding: false,
    label: "CLIENT",
  },
  {
    id: "Id#",
    numeric: true,
    disablePadding: false,
    label: "ID#",
  },
  {
    id: "Agent",
    numeric: true,
    disablePadding: false,
    label: "AGENT",
  },
  {
    id: "Inspector",
    numeric: true,
    disablePadding: false,
    label: "INSPECTOR",
  },
  {
    id: "Fee",
    numeric: true,
    disablePadding: false,
    label: "FEE",
  },
  {
    id: "$",
    numeric: true,
    disablePadding: false,
    label: <AttachMoneyIcon sx={{ fontSize: "17px" }} />,
  },
  {
    id: "fileIcon",
    numeric: true,
    disablePadding: false,
    label: <InsertDriveFileIcon sx={{ fontSize: "17px" }} />,
  },
  {
    id: "Updated",
    numeric: true,
    disablePadding: false,
    label: "UPDATED",
  },
  {
    id: "Actions",
    numeric: true,
    disablePadding: false,
    label: "ACTIONS",
  },
  {
    id: "Cancel",
    numeric: true,
    disablePadding: false,
    label: "CANCEL",
  },
];

export const UpcommingtableHead = [
  {
    id: "Image",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "Address",
    numeric: false,
    disablePadding: true,
    label: "ADDRESS",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "STATUS",
  },
  {
    id: "Date/Time",
    numeric: true,
    disablePadding: false,
    label: "DATE/TIME",
  },
  {
    id: "Client",
    numeric: true,
    disablePadding: false,
    label: "CLIENT",
  },
  {
    id: "Id#",
    numeric: true,
    disablePadding: false,
    label: "ID#",
  },
  {
    id: "Agent",
    numeric: true,
    disablePadding: false,
    label: "AGENT",
  },
  {
    id: "Inspector",
    numeric: true,
    disablePadding: false,
    label: "INSPECTOR",
  },
];

const dd = {
  success: true,
  status_code: "200",
  message: "Inspection List Details Data fetched successfully",
  data: [
    {
      id: 1,
      location_address: "5958 Property Street",
      start_time: "2017-07-18 09:00:00",
      fee: 275,
      paid: 0,
      updated_at: "2022-03-24T10:21:15.000000Z",
      is_inspection_complete: 0,
      orderclient: [
        {
          id: 1,
          order_id: 1,
          client_id: 1,
          client: {
            id: 1,
            first_name: "Alfred",
            last_name: "Anderson",
          },
        },
        {
          id: 2,
          order_id: 1,
          client_id: 2,
          client: {
            id: 2,
            first_name: "John",
            last_name: "Client",
          },
        },
      ],
      orderinspector: [
        {
          id: 1,
          order_id: 1,
          inspector_id: 3,
          inspector: {
            id: 3,
            first_name: "Will",
            last_name: "Singer",
          },
        },
      ],
      orderagent: [
        {
          id: 1,
          order_id: 1,
          agent_id: 5,
          agent: {
            id: 5,
            first_name: "Ann",
            last_name: "Referrer",
          },
        },
        {
          id: 2,
          order_id: 1,
          agent_id: 6,
          agent: {
            id: 6,
            first_name: "Sally",
            last_name: "Referrer",
          },
        },
      ],
    },
    {
      id: 2,
      location_address: "16032 Property Street",
      start_time: "2017-07-18 10:00:00",
      fee: 275,
      paid: 0,
      updated_at: "2022-03-24T10:21:15.000000Z",
      is_inspection_complete: 0,
      orderclient: [],
      orderinspector: [
        {
          id: 2,
          order_id: 2,
          inspector_id: 4,
          inspector: {
            id: 4,
            first_name: "Perry",
            last_name: "Hickman",
          },
        },
      ],
      orderagent: [],
    },
  ],
};

const BootstrapTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

var id1 = `?id=${0}`;
var id2 = `?id=${1}`;
const ShowDetails = `/#/inspections/inspection${id1}`;
const ShowDetails2 = `/#/inspections/inspection${id2}`;
var tableLength = [];
dd.data.forEach((data) =>
  tableLength.push({
    Address: (
      <div className="table-body-row" onClick={() => (window.location.href = ShowDetails)}>
        <img src={Img} className="table-body-row-img" alt="property-thumbnail" />
        <p>
          5958 <br /> Property Street,
          <br />
        </p>
      </div>
    ),
    Status: <Chip sx={{ backgroundColor: colors["SCHEDULED_BG"], color: colors["SCHEDULED"] }} label={<h3 style={{ fontSize: "9px", fontWeight: "bold" }}>SCHEDULED</h3>} />,
    "Date/Time": (
      <div>
        <span className="date-placeholder">2017/07/18</span>
        <span className="time-placeholder">09:00 pm</span>
      </div>
    ),
    Client: "Super Admin Sanju Chabungbam",
    "Id#": data.id,
    Agent: "John Client",
    Inspector: "Savith Lal",
    Fee: <div>${data.fee}</div>,
    $: (
      <div>
        <BootstrapTooltip title={<h1>Unpaid</h1>}>
          <CancelIcon sx={{ color: "#E30303", fontSize: 18 }} />
        </BootstrapTooltip>
      </div>
    ),
    fileIcon: (
      <div>
        <BootstrapTooltip title={<h1>Signed</h1>}>
          <CheckCircleIcon sx={{ color: "#4282E2", fontSize: 18 }} />
        </BootstrapTooltip>
      </div>
    ),
    Updated: (
      <div style={{ textAlign: "left" }}>
        <span className="date-placeholder">02/08/22</span>
        <span className="time-modified-placeholder">Today</span>
      </div>
    ),
    Actions: (
      <span>
        <BootstrapTooltip title={<h1>Edit details</h1>}>
          <EditIcon sx={{ opacity: "0.25" }} />
        </BootstrapTooltip>{" "}
        <BootstrapTooltip title={<h1>Delete record</h1>}>
          <DeleteIcon sx={{ color: "#E30303", opacity: "0.25" }} />
        </BootstrapTooltip>
      </span>
    ),
  })
);

export const tableData = tableLength;
