import { Button} from '@mui/material';
import { Grid } from '@mui/material';
import { useRef, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import uuid from "react-uuid";

const AddCategoryButton = ({setTemplateState,templateState,updatePtTempateData}) => {
   
const handleAddCategory=()=>{
  setTemplateState(prev=>({...prev,updatingTemplate:true}))
    let prevData = JSON.parse(JSON.stringify(templateState?.templateData));
    let newCatObj = {
        uuid: uuid(),
        prompt: "Category Name",
        information_text: null,
        type:0,
        order:prevData?.data?.template?.pdf_categories?.length + 1,
    };
    prevData?.data?.template?.pdf_categories?.push(newCatObj);
    updatePtTempateData(prevData?.data?.template)
    setTemplateState(prev=>({...prev,templateData:prevData}))
}
    
  return (
    <Grid className={`pt_add_elem_btn ${templateState.roleId !== 2 && templateState.roleId !== 3?'pt_elem_disabled':''}`} style={{background:'#fff'}}>
      <Button aria-controls="grouped-menu" disabled={templateState?.updatingTemplate || templateState.roleId !== 2 && templateState.roleId !== 3} variant="outlined" aria-haspopup="true"
       startIcon={<AddCircleOutlineIcon />} 
       onClick={handleAddCategory}
       >
       Add Category
      </Button>    
    </Grid>
  )
}

export default AddCategoryButton