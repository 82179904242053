import { QRCodeCanvas } from "qrcode.react";
import { useEffect, useState } from "react";
import { apiCallGet } from "../../utils/action";
import { Modal, Box, Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../../../assets/styles/containers/_downloadLink.scss";
import { GooglePlaySvg, AppleStoreSvg } from "../../../assets/svgIcons/Svg";
import AppStoreLogo from "../../../assets/images/AppStore.png";
import config_settings from "../../config/config";

const DownloadLink = ({ handleClose }) => {
  const [appLink, setAppLink] = useState();
  const [appStoreLink, setAppStoreLink] = useState();
  const [playStoreLink, setPlayStoreLink] = useState();
  const [baseURL, setBaseURL] = useState();

  const origin = () => {
    let origin = window.location.origin.toLowerCase();
    if (origin.includes("https://")) origin = origin.replace("https://", "");
    else origin = origin.replace("http://", "");
    setBaseURL(config_settings[origin].SERVER_URL);
    // return origin;
  };

  const GetDownloadLink = () => {
    apiCallGet(
      (response) => {
        // console.log("GET AGENT LIST", response);
        if (response.data.success) {
          // success
          setAppLink(response.data.data);
          setPlayStoreLink(response.data.android_app_link);
          setAppStoreLink(response.data.ios_app_link);
        } else {
          // console.log("else", response.data.message);
        }
      },
      "",
      "DOWNLOAD_LINK"
    );
  };

  useEffect(() => {
    GetDownloadLink();
    origin();
  }, []);

  const openLink = () => {
    window.open(appLink, "_blank");
  };

  const openPlayStore = () => {
    window.open(playStoreLink, "_blank");
  };

  const openAppStore = () => {
    window.open(appStoreLink, "_blank");
  };

  return (
    <Modal open onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={"inspection-filter-modal-main"}>
      <Box className={"download-link-modal"}>
        <Grid className={"inspection-filter-modal-grid-main"} container spacing={2}>
          <Grid item xs={8}></Grid>
          <Grid item xs={4}>
            <CloseIcon onClick={handleClose} className={"inspection-filter-modal-close-icon"} />
          </Grid>
          <Grid item xs={12} className="center" sx={{ mt: -3 }}>
            <div style={{ textAlign: "center", maxWidth: "569px" }}>
              <h1 className="download-the-app">Start creating your reports</h1>
              <p className="subHead-download">Palmtech works great on a phone or tablet. Easily install the mobile app using the instructions below.</p>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div style={{ maxWidth: "700px", padding: "15px" }}>
              <Grid container>
                <Grid item xs={12} md={6} lg={8} sx={{ mt: 3 }}>
                  <p className="download-the-app-heading">1. Install the mobile app.</p>
                  <p className="download-the-app-sub-heading">Scan the QR code to download the app on your iPhone, iPad or Android device. You can also find it by searching for “Palmtech Complete” in the App or Play Store on your device.</p>
                  <Grid sx={{ display: "flex", ml: { xs: "0px", md: "12px", lg: "14px" }, marginTop: "20px", gap: { xs: "0px", md: "16px", lg: "16px" } }}>
                    <div onClick={openPlayStore} style={{ cursor: "pointer" }}>
                      <GooglePlaySvg />
                    </div>
                    <div onClick={openAppStore} style={{ zIndex: "99" }}>
                      <img className="app-store-image" src={AppStoreLogo} style={{ cursor: "pointer", zIndex: "99" }} alt="applogo" />
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={4} className="left" sx={{ mt: 5 }}>
                  {appLink && baseURL ? <QRCodeCanvas value={`${baseURL}/generate-mobile-app-link`} /> : ""}
                </Grid>
                <Grid item xs={12} sx={{ mt: 11 }}>
                  <p className="download-the-app-heading">2. No mobile device? Use the web app.</p>
                  <div className="web-app-link-sub-container">
                    <p className="web-app-link-sub-first">
                      Open an inspection and follow the prompts to use the web app right in your browser. The web app works on tablets, desktop, and mobile.
                      {/* <p className="web-app-link-sub-second" onClick={openLink}>
                        {" "}
                        {appLink}
                      </p> */}
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}></Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadLink;
