import { Box } from '@mui/system';
import Header from '../../../common/header/Header';
import Breadcrumb from '../../../common/header/Breadcrumb';
import BillingPlanLayout from './BillingPlanLayout';
import { useState } from 'react';
const BillingPlan = () => {
    const [tabValue, setTabValue] = useState(0);
    
    return (
        <Box sx={{ flexGrow: 1 }} className="page-background">
            {window.scroll(0, 0)}
            <Header />
            <Breadcrumb />
            <div className="inspection-details">
                <BillingPlanLayout tabValue={tabValue} setTabValue={setTabValue} />
            </div>
        </Box>
    );
};
export default BillingPlan;
