//File Imports***********************************************************************************************
import toast from 'react-hot-toast';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Header from '../../../common/header/Header';
import React, { useState, useEffect } from 'react';
import Preloader from '../../../helpers/Preloader';
import DeleteIcon from '@mui/icons-material/Delete';
import Visibility from '@mui/icons-material/Visibility'; //Icon
import Breadcrumb from '../../../common/header/Breadcrumb';
import { apiCall, apiCallGet } from '../../../utils/action';
import { LoadingButton } from '@mui/lab';
import VisibilityOff from '@mui/icons-material/VisibilityOff'; //Icon
import blankLogo from '../../../../assets/images/blank_logo.png'; //Icon png
import { onKeyPressed, formatPhoneNumberDashes, formatZip, formatState } from '../../../utils/services';
import { Grid, TextField, Typography, Card, Button, Dialog, CardMedia, DialogActions, DialogContent, CardHeader, IconButton, FormControl, InputAdornment } from '@mui/material';
//export function
const MyProfile = () => {
    //navigate
    const navigate = useNavigate();
    //profile pic
    const fileInput = React.useRef();
    //preloader toggle
    const [showLoader, setShowLoader] = useState(false);
    const [image, setImage] = useState('');
    const [defaultImage, setDefaultImage] = useState('');
    //change toggle
    const [isChanged, setIsChanged] = useState(false);
    //dialog box
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    //validation check
    const [errors, setErrors] = useState({});
    const [dataIsCorrect, setDataIsCorrect] = useState(false);
    //password visiblity toggle
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    //details fields
    const [details, setDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        homeAddress: '',
        city: '',
        state: '',
        zip: '',
        plain: '',
    });
    // handle change function
    const handleChange = (event) => {
        setDetails({
            ...details,
            [event.target.name]: event.target.value,
        });
        setIsChanged(true);
    };
    //handle phone change function
    const handlePhoneChange = (event) => {
        const formattedPhoneNumber = formatPhoneNumberDashes(event.target.value);
        setDetails({
            ...details,
            [event.target.name]: formattedPhoneNumber,
        });
        setIsChanged(true);
    };
    //handle state change function
    const handleStateChange = (event) => {
        const formattedState = formatState(event.target.value);
        setDetails({
            ...details,
            [event.target.name]: formattedState,
        });
        setIsChanged(true);
    };
    //handle zip change function
    const handleZipChange = (event) => {
        const formattedZip = formatZip(event.target.value);
        setDetails({
            ...details,
            [event.target.name]: formattedZip,
        });
        setIsChanged(true);
    };
    // handle submit function
    const handleformSubmit = (event) => {
        event.preventDefault();
        setErrors(validation(details));
        setDataIsCorrect(true);
    };
    // validation function*************************************************************************************************
    const validation = (values) => {
        let errors = {};
        if (!details.firstName?.trim()) {
            errors.firstName = 'First name is required.';
        }
        // if (!details.lastName) {
        //     errors.lastName = 'Last name is required.';
        // }
        if (!details.phone) {
        } else if (values.phone.length < 12) {
            errors.phone = 'Phone number is invalid';
        }
        return errors;
    };
    //Get Inspector Details API call*************************************************************************************
    const GetInspectorDetails = () => {
        setShowLoader(true);
        const formdata = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_id: sessionStorage.getItem('role_id'),
        };
        apiCall(
            (response) => {
                console.log('GET_INSPECTOR_DETAILS', response);
                const { data, message, success } = response.data;
                if (success) {
                    setImage(data.profile_pic);
                    setDefaultImage(data.profile_pic);
                    setDetails({
                        ...details,
                        firstName: data.first_name,
                        lastName: data.last_name === null ? '' : data.last_name,
                        email: data.email,
                        phone: data.phone === null ? '' : data.phone,
                        homeAddress: data.address_1 === null ? '' : data.address_1,
                        city: data.city === null ? '' : data.city,
                        state: data.state === null ? '' : data.state,
                        zip: data.zip === null ? '' : data.zip,
                        plain: data.plain_text === null ? '' : data.plain_text,
                    });
                    setShowLoader(false);
                } else {
                    console.log('else ', message);
                    toast.error(message);
                    setShowLoader(false);
                }
            },
            formdata,
            'GET_INSPECTOR_DETAILS',
        );
    };
    //delete profile picture API call*************************************************************************************
    const deleteProfilePicture = () => {
        setShowLoader(true);
        const formdata = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_id: sessionStorage.getItem('role_id'),
            member_id: sessionStorage.getItem('user_id'),
        };
        apiCall(
            (response) => {
                console.log('DELETE_PROFILE', response);
                const { message, success } = response.data;
                if (success) {
                    toast.success(message);
                    setOpen(false);
                    setShowLoader(false);
                    setImage('');
                    setDefaultImage('');
                    setIsChanged(true)
                    fileInput.current.value = "";
                } else {
                    console.log('else ', message);
                }
            },
            formdata,
            'DELETE_PROFILE',
        );
    };
    //edit Inspector Details API call and validation*************************************************************************************

    const editInspectorDetails = () => {
        setShowLoader(true);
        let formData = new FormData();
        const params = {
            company_id: sessionStorage.getItem('company_id'),
            user_id: sessionStorage.getItem('user_id'),
            role_name_id: sessionStorage.getItem('role_id'),
            first_name: details.firstName,
            last_name: details.lastName,
            phone: details.phone === null ? '' : details.phone,
            address: details.homeAddress === null ? '' : details.homeAddress,
            city: details.city,
            state: details.state,
            zip: details.zip,
            profile_image: typeof image === 'string' || image === null || image === undefined ? '' : image,
        };
        Object.keys(params).forEach((key) => formData.append(key, params[key]));
        apiCall(
            (response) => {
                console.log('UPDATE_INSPECTOR_DETAILS', response);
                const { message, success } = response.data ? response.data : '';
                if (success) {
                    toast.success(message);
                    console.log(response, 'response');
                    sessionStorage.setItem("user_name" , `${details.firstName} ${details.lastName}`);
                    setShowLoader(false);
                    navigate('/dashboard');
                } else {
                    console.log('else ', message);
                    toast.error(message);
                    setShowLoader(false);
                }
            },
            formData,
            'UPDATE_INSPECTOR_DETAILS',
        );
    };
    //intitalization
    useEffect(() => {
        GetInspectorDetails();
        window.scroll(0, 0);
    }, []);
    // validation check***********************************************************************************************
    useEffect(() => {
        if (Object.keys(errors).length === 0 && dataIsCorrect && isChanged) {
            editInspectorDetails();
        }
    }, [errors]);
    //default image check*********************************************************************************************
    useEffect(() => {
        if (image === undefined) {
            setImage(defaultImage);
        }
    }, [image]);
    // Profile pic card component***************************************************************************
    const card = (
        <>
            <CardHeader
                action={
                    <p style={{ paddingTop: '3px', fontSize: '12px', lineHeight: '20px', letterSpacing: 0, fontWeight: '600', color: ' #4282E2', cursor: 'pointer' }} onClick={() => fileInput.current.click()}>
                        <EditIcon sx={{ height: '10.5px !important', width: '10.5px', color: '#4282E2 !important' }} /> Edit
                    </p>
                }
                title="Profile"
                className="team-profile-header"
            />
            <input
                ref={fileInput}
                type="file"
                accept="image/*"
                onChange={(e) => {
                    setImage(e.target.files[0]);
                    setIsChanged(true);
                }}
                style={{ display: 'none' }}
            />
            {image && (
                <div className="prof-img-wrapper">
                    <img className="team-prof-img" draggable={false} style={{ maxHeight: '320px', maxWidth: '100%' }} src={typeof image === 'string' ? image : URL.createObjectURL(image)} alt="Thumb" />
                    {/* <span onClick={()=>setImage()} style={{ cursor: "pointer", padding: 5, background: "red", color: "white", border: "none" }}>
            Remove Image
          </span> */}
                </div>
            )}
            {!image && <CardMedia draggable={false} component="img" style={{ maxHeight: '230px', maxWidth: '100%', objectFit: 'contain', padding: '10px', draggable: false }} alt="/broken-image.jpg" src={blankLogo} />}

            <p style={{ paddingTop: '3px', paddingLeft: '17.5px', fontSize: '12px', lineHeight: '20px', letterSpacing: 0, fontWeight: '600', color: ' #E30303', cursor: 'pointer', opacity: image ? '1.00' : '0.25', pointerEvents: !image && 'none', userSelect: 'none' }} onClick={() => handleOpen()}>
                <DeleteIcon sx={{ height: '10.5px !important', width: '10.5px' }} /> Delete photo
            </p>
        </>
    );
    //Details Card***************************************************************************************
    const detailsCard = (
        <>
            <Grid container className="team-details-card">
                <Grid item xs={12} className="team-details-cardTitle">
                    <span>Contact Info</span>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="standard" className="forms-control">
                            <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                                First Name*
                            </label>
                            <TextField type="text" size="small" name="firstName" variant="outlined" onChange={handleChange} placeholder="First Name" value={details.firstName} className="input-textfield" error={errors.firstName} helperText={errors.firstName} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="standard" className="forms-control">
                            <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                                Last Name
                            </label>
                            <TextField type="text" size="small" name="lastName" variant="outlined" onChange={handleChange} placeholder="Last Name" value={details.lastName} className="input-textfield" error={errors.lastName} helperText={errors.lastName} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="standard" className="forms-control field-reset">
                            <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                                Email*
                            </label>
                            <TextField
                                disabled
                                type="email"
                                size="small"
                                variant="outlined"
                                placeholder="Email"
                                value={details.email}
                                className="input-textfield"
                                InputProps={{
                                    className: 'input_props_field',
                                    sx: {
                                        height: { xs: '40px', md: '40px', lg: '40px' },
                                        fontSize: { xs: '14px', md: '14px', lg: '14px' },
                                        background: 'rgba(239, 239, 239, 0.8)',
                                        color: 'text.primary !important',
                                        userSelect: 'none',
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl variant="standard" className="forms-control">
                            <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                                Phone
                            </label>
                            <TextField size="small" variant="outlined" placeholder="Phone" name="phone" className="input-textfield" value={details.phone} onChange={handlePhoneChange} error={errors.phone} helperText={errors.phone} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="standard" className="forms-control">
                            <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                                Home Address
                            </label>
                            <TextField className="input-textfield" name="homeAddress" size="small" variant="outlined" placeholder="Home address" type="text" value={details.homeAddress} onChange={handleChange} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl variant="standard" className="forms-control">
                            <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                                City
                            </label>
                            <TextField name="city" className="input-textfield" size="small" variant="outlined" placeholder="City" type="text" value={details.city} onChange={handleChange} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControl variant="standard" className="forms-control">
                            <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                                State
                            </label>
                            <TextField name="state" className="input-textfield" size="small" variant="outlined" placeholder="State" type="text" value={details.state} onChange={handleStateChange} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4} className="zip-field">
                        <FormControl variant="standard" className="forms-control">
                            <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                                Zip
                            </label>
                            <TextField size="small" name="zip" type="number" placeholder="Zip" variant="outlined" value={details.zip} className="input-textfield" onChange={handleZipChange} />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ marginTop: '6px' }}>
                        <label className="input-form-label pl-15 mb-5" htmlFor="Role" id="demo-simple-select-label">
                            Role*
                        </label>
                        <FormControl fullWidth className="forms-control field-reset">
                            <TextField
                                disabled
                                id="PropertyAddressId"
                                className="input-textfield"
                                size="small"
                                variant="outlined"
                                placeholder="Inspector"
                                value={'Inspector'}
                                onKeyDown={onKeyPressed}
                                InputProps={{
                                    className: 'input_props_field',
                                    sx: {
                                        height: { xs: '40px', md: '40px', lg: '40px' },
                                        fontSize: { xs: '14px', md: '14px', lg: '14px' },
                                        background: 'rgba(239, 239, 239, 0.8)',
                                        color: 'text.primary !important',
                                        userSelect: 'none',
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} style={{ marginTop: '6px' }}>
                        <FormControl variant="standard" className="forms-control">
                            <label className="input-form-label" htmlFor="Address" id="demo-simple-select-label">
                                Password
                            </label>
                            <TextField
                                disabled
                                autoComplete="false"
                                id="PropertyAddressId"
                                className="input-textfield"
                                size="small"
                                variant="outlined"
                                placeholder="Password"
                                type={showPassword ? 'text' : 'password'}
                                value={details.plain}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton sx={{ mr: '-9px' }} onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <p style={{ paddingTop: '12px', fontSize: '12px', lineHeight: '20px', letterSpacing: 0, fontWeight: '600', color: ' #4282E2', cursor: 'pointer' }} onClick={() => navigate('/change-password', { state: { email: details.email } })}>
                                <EditIcon sx={{ height: '10.5px', width: '10.5px' }} /> Change password
                            </p>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={5} md={6}></Grid>
                </Grid>
            </Grid>
        </>
    );
    return (
        <>
            <Header />
            <Box className="page-background">
                <Breadcrumb />
                <Preloader showPreloader={showLoader} />
                {/* ------------------------Change role dialog------------------ */}
                <Dialog fullWidth={true} className="dialog-container-details" open={open} onClose={handleClose}>
                    <div className="close-wrapper">
                        <CloseIcon onClick={handleClose} sx={{pointerEvents: showLoader && 'none'}}/>
                    </div>

                    <DialogContent className="dialog-content">
                        <Grid container className="permission_change_modal">
                            <Typography>Delete confirmation</Typography>
                            <Typography>Are you sure you want to delete the profile picture?</Typography>
                        </Grid>
                    </DialogContent>
                    <DialogActions className="dialog-action">
                        <Button className="back-btn" onClick={handleClose}  sx={{pointerEvents: showLoader && 'none'}}>
                            Cancel
                        </Button>
                        <LoadingButton className="default-btn" onClick={() => deleteProfilePicture()} loading={showLoader}>
                            Confirm
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
                {/* ----------------------------profile section-------------------------------- */}
                <Grid container className="teams-container">
                    {/* <Grid item xs={12} className="details-title">
                        <span className="team-user-name">{`${details.firstName} ${details.lastName}`}</span>
                        <br />
                        <span className="team-user-name">
                            <BusinessCenterIcon />
                        </span>
                        
                    </Grid> */}
                    <br />
                    <br />
                    <Box sx={{ pl: '30px' }}>
                        <Grid container spacing={4} sx={{ backgroundColor: 'white', borderRadius: '4px !important', border: '1px solid #E7E7E9' }}>
                            <Grid item xs={12} sm={3} sx={{ paddingTop: '0px !important', paddingLeft: '0px !important' }}>
                                <Box>
                                    <Card sx={{ pl: 1 }} elevation={0}>
                                        {card}
                                    </Card>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={9} sx={{ paddingTop: '0px !important', paddingLeft: '0px !important' }}>
                                {' '}
                                <Card elevation={0}>{detailsCard}</Card>
                            </Grid>
                        </Grid>
                    </Box>
                    <Grid container>
                        <Grid item xs={12} className="team-detail-cta">
                            <Button
                                className="back-btn"
                                onClick={() => {
                                    navigate('/dashboard');
                                    setIsChanged(false);
                                }}>
                                Cancel
                            </Button>
                            <Button className="default-btn teams-detail-btn" disabled={!isChanged} onClick={handleformSubmit}>
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
export default MyProfile;
