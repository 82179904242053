import {  Button, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AlertImage from "../../../../../assets/images/alert.png";
// dialog imports
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import toast from "react-hot-toast";
import { apiCall } from "../../../../utils/action";
import { useNavigate } from "react-router-dom";

const PtDiscardDraftModal = ({openDraftModal,handleCloseDraftModal,selectedTempGuid,isPdfTemplate,templateName}) => {
    const navigate = useNavigate();
    const discardTemplate=()=>{
    const formdata = { template_guid:selectedTempGuid , is_pdf_template: isPdfTemplate };
    apiCall(
    (response) => {
      const {  message, success } = response.data;
      if (success) {
        handleCloseDraftModal(true)
        navigate(`/template/`)
        toast.success(message);       
      } else {
        handleCloseDraftModal(true)
        toast.error(message);
      }
    },
    formdata,
    "PT_DISCARD_TEMPLATE"
  );
  }
  return (
    <Dialog
      open={openDraftModal}
      onClose={handleCloseDraftModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="pt-delete-template-modal"
    >
      <DialogTitle id="alert-dialog-title">
        <CloseIcon className="close-icon" onClick={handleCloseDraftModal} />
      </DialogTitle>
      <DialogContent>
        <Grid item xs={12} sx={{ textAlign: "center",mt:'20px' }}>
          <img
            src={AlertImage}
            alt="alert"
            style={{ width: "51px", height: "49px" }}
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography className="template_delete_modal_text">
            {`Discard draft ${templateName}? `}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              color: "#333333",
              fontFamily: "open sans",
              fontSize: "14px",
              mt: "15px",
              letterSpacing: 0,
            }}
          >
           Discarding draft will revert the template to the last published version. This cannot be undone.
          </Typography>
        </Grid>
        <DialogContentText id="alert-dialog-description">
          {/* This action cannot be undone. */}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ ":hover": { backgroundColor: "transparent !important" } }}
          className="template-delete-modal-cancel-btn"
          onClick={handleCloseDraftModal}
        >
          Cancel
        </Button>
        <Button
          sx={{ ":hover": { backgroundColor: "#1E8970 !important" } }}
          className="default-btn-template-delete-modal"
          variant="contained"
          color="error"
            onClick={() => {discardTemplate()}}
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PtDiscardDraftModal;
